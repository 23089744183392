/* eslint-disable */
// noinspection RegExpRedundantEscape,RegExpDuplicateCharacterInClass

import React, {Fragment, useEffect, useState} from 'react';
import {BasicModal, CustomCheckbox, CustomRadioButton, CustomSelectBox, CustomTextField, ImgUpload} from 'components/molecules';
import {useHistory, withRouter} from 'react-router-dom';
import store from 'scripts/store';
import Net from 'scripts/net';
import {toJS} from "mobx";

const UserRegist = ({match}) => {

    const cloneUser = toJS(store.user)

    const [form, setForm] = useState({
        username: '',
        name: '',
        email: '',
        nickname: '',
        contact: '',
        phoneNumber: '',
        password: '',
        status: '',
        customer : cloneUser.menuGroup === 'ADMIN' ? {code: '',name: '',} : cloneUser.customer,
        site: {
            code: '',
            name: '',
        },
        place: {
            code: '',
            name: '',
        },
        menuGroup: '',
        authList: [],
        roleList: [],
        picture: '',
        imageDataUrl: '',
        enabled: true,
        temporaryPassword: false,

    });
    const [registPage, setRegistPage] = useState(true);
    const [duplicateChk, setDuplicateChk] = useState({
        username: false,
        nickname: false,
        phoneNumber: false,
    });
    const [customerList, setCustomerList] = useState([{
        code: '',
        name: '고객사명 선택'
    }]);
    const [siteList, setSiteList] = useState([{
        code: '',
        name: '현장명 선택'
    }]);

    const [searchMenuGroup, setSearchMenuGroup] = useState([
        [
            {code: '', name: '메뉴그룹 선택'}
        ]
    ]);
    const [roleList, setRoleList] = useState([
        {code: 'USER', name: '사용자 롤'},
        {code: 'MANAGER', name: '관리자 롤'},
        {code: 'SYSTEM', name: '시스템 롤'},
    ])
    const [searchAuthGroup, setSearchAuthGroup] = useState([]);

    const [modalState, setModalState] = useState({
        open: false,
        data: [],
        clickedBtnName: ''
    });

    useEffect(() => {
        store.openedPage = '사용자 등록/수정';

        // 상세 조회(수정 목적으로 진입시)
        if (match.params.username !== undefined) {
            getUserOne(match.params, (response) => {
                if (response.status === 200) {
                    if (!response.data.customer) {
                        response.data.customer = {code: "", name: ""};
                    }
                    if (!response.data.site) {
                        response.data.site = {code: "", name: ""};
                    }
                    if (!response.data.enabled) {
                        response.data.enabled = false;
                    }
                    if (!response.data.roleList) {
                        response.data.roleList = [];
                    }
                    if (!response.data.authList) {
                        response.data.authList = [];
                    }
                    setForm({
                        ...form,
                        ...response.data,
                    });

                    setDuplicateChk({
                        username: true,
                        nickname: true,
                        phoneNumber: true,
                    });
                    // 등록페이지 여부 확인
                    setRegistPage(false);
                }
            });
        }
        // 고객사 전체 목록
        getCustomerList((response) => {
            setCustomerList(response);
        });
        getSearchAuthGroup();
        getSearchMenuGroup();
    }, []);

    useEffect(() => {
        if (form.customer.code !== '') {
            getSiteList(form.customer.code, (response) => {
                setSiteList(response);
            })
        } else {
            // 빈값 선택시 장비모델명 selectbox 초기화
            setSiteList([
                {
                    code: '',
                    name: '현장명 선택'
                }
            ])
        }
    }, [form.customer.code]);

    useEffect(()=>{
        if(form.menuGroup !== ''){
            if(form.menuGroup === "OPERATOR" || form.menuGroup === "OPERATOR"){
                setForm( {
                    ...form,
                    temporaryPassword : true
                })
            }
        }
    },[form.menuGroup])

    const getCustomerList = (callback) => {
        Net.getCustomerList({currentPage: '', rowPerPage: '', searchText: ''}, (response) => {
            if (response.status === 200) {
                let newArray = customerList;
                response.data.content.forEach(items => {
                    newArray = [
                        ...newArray,
                        {
                            code: items.code,
                            name: items.name
                        }
                    ]
                });
                callback(newArray);
            }
        });
    }

    const getSiteList = (code, callback) => {
        Net.getSitesList(
            {
                currentPage: '',
                rowPerPage: '',
                searchText: '',
                customerCode: code,
            }, (response) => {
                if (response.status === 200) {
                    let newArray = [
                        {
                            code: '',
                            name: '현장명 선택'
                        }
                    ];
                    response.data.content.forEach(items => {
                        newArray = [
                            ...newArray,
                            {
                                code: items.code,
                                name: items.name
                            }
                        ]
                    });
                    callback(newArray);
                } else if (response.status === 204) {
                    let newArray = [
                        {
                            code: '',
                            name: '현장명 선택'
                        }
                    ];
                    callback(newArray);
                }
            });
    }

    const getSearchAuthGroup = () => {
        Net.getCodeList(
            "AUTH_GROUP", (response) => {
                if (response.status === 200) {
                    let newArray = [];
                    response.data.forEach(items => {
                        newArray = [
                            ...newArray,
                            {
                                code: items.code,
                                name: items.name
                            }
                        ]

                    })
                    setSearchAuthGroup(newArray);

                }
            }
        )
    }

    const getSearchMenuGroup = () => {
        Net.getCodeList(
            "MENU_GROUP", (response) => {
                if (response.status === 200) {
                    let newArray = searchMenuGroup[0];
                    response.data.forEach(items => {
                        newArray = [
                            ...newArray,
                            {
                                code: items.code,
                                name: items.name
                            }
                        ]

                    })
                    setSearchMenuGroup(newArray);

                }
            }
        )
    }

    const history = useHistory();

    const getUserOne = (params, callback) => {
        Net.getUserOne(params.username, (response) => {
            callback(response);
        });
    }

    const textFieldHandler = (name, value) => {
        if (name === 'username' || name === 'nickname' || name === 'phoneNumber') {
            const dupl = {
                ...duplicateChk,
                [name]: false,
            };
            setDuplicateChk(dupl);
        }

        if (name === 'place.name') {
            form.place.name = value;
        }

        const nextForm = {
            ...form,
            [name]: value
        }
        console.log(nextForm);
        setForm(nextForm);
    }

    const radioBtnHandler = (name, value) => {
        let nextForm;
        if (name === "authList") {
            nextForm = {
                ...form,
                [name]: [value],
            }
        } else {
            nextForm = {
                ...form,
                [name]: value === 'true',
            }
        }

        setForm(nextForm);
    }

    const selectBoxHandler = (name, value) => {
        switch (name) {
            case 'customer.code':
                setForm({
                    ...form,
                    customer: {
                        code: value.code,
                        name: value.name
                    },
                    site: {
                        code: "",
                        name: ""
                    }
                });
                break;
            case 'site.code':
                setForm({
                    ...form,
                    site: {
                        code: value.code,
                        name: value.name
                    }
                });
                break;
            case 'menuGroup':
                setForm({
                    ...form,
                    menuGroup: value
                });
                break;
            default:
                break;
        }
    }

    const onDuplicateHandler = (checkType) => {
        let typeName = "";
        let formData = "";
        switch (checkType) {
            case "username":
                typeName = "사용자 ID";
                formData = form.username;
                break;
            case "phoneNumber":
                typeName = "핸드폰번호";
                formData = form.phoneNumber;
                break;
            case "nickname":
                typeName = "별칭";
                formData = form.nickname;
                break;
            default:
                break;
        }
        if (formData) {
            Net.checkUserOne(checkType, form, (response) => {

                if (response.status === 200) {
                    alert('사용 가능한 ' + typeName + '입니다.');
                    const dupl = {
                        ...duplicateChk,
                        [checkType]: true,
                    };
                    setDuplicateChk(dupl);
                } else if (response.status === 409) {
                    alert('이미 등록된 ' + typeName + '입니다.');
                }

            });
        } else {
            alert(typeName + " 텍스트를 입력해 주세요.")
        }
    }


    const onRemoveHandler = () => {
        const result = window.confirm('삭제하시겠습니까?');
        if (result) {
            Net.removeUserList([{username: form.username}], (response) => {
                if (response) {
                    history.push('/member/user/list');
                }
            });
        }
    }

    const onSubmitHandler = () => {
        const pwPattern = /(?=.*\d)(?=.*[~`!@#$%\\^&*()-])(?=.*[a-zA-Z]).{8,50}$/;

        if (!form.username) {
            alert('사용자ID를 입력해주세요.');
            document.getElementsByName('username')[0].focus();
            return false;
        }
        if (!form.nickname) {
            alert('별칭을 입력해주세요.');
            document.getElementsByName('nickname')[0].focus();
            return false;
        }
        if (!form.phoneNumber) {
            alert('핸드폰번호를 입력해주세요.');
            document.getElementsByName('phoneNumber')[0].focus();
            return false;
        }
        if ((!registPage && form.password) || registPage) {
            if (!pwPattern.test(form.password)) {
                alert('비밀번호는 영문, 숫자, 특수문자 포함 8글자 이상이어야 합니다.');
                document.getElementsByName('password')[0].focus();
                return false;
            }
        }
        // if( !form.customer.code ) {
        //     alert('고객사를 선택해주세요.');
        //     document.getElementsByName('customer.code')[0].focus();
        //     return false;
        // }
        // if( !form.site.code ) {
        //     alert('현장명을 선택해주세요.');
        //     document.getElementsByName('site.code')[0].focus();
        //     return false;
        // }

        if (!duplicateChk.username) {
            alert('사용자ID 중복체크를 해주세요.');
            return false;
        }
        if (!duplicateChk.nickname) {
            alert('별칭 중복체크를 해주세요.');
            return false;
        }
        if (!duplicateChk.phoneNumber) {
            alert('핸드폰번호 중복체크를 해주세요.');
            return false;
        }
        if (cloneUser.menuGroup === 'MANAGER') {
            if(form.customer === '') alert('잘못된 접근입니다. 관리자에게 문의.')
        }

        var result = window.confirm('저장하시겠습니까?');
        if (result) {
            if (registPage) {
                Net.addUser(form, (response) => {
                    if (response.status === 200 || response.status === 201 || response.status === 202) {
                        history.push('/member/user/list');
                    }
                });
            } else {
                Net.updateUser(form, (response) => {
                    if (response.status === 200) {
                        history.push('/member/user/list');
                    }
                });
            }
        }
    }

    const onCancelHandler = () => {
        history.push('/member/user/list');
    }

    // Open Modal and setClickedBtnName
    const onClickModalOpen = () => {
        setModalState({
            ...modalState,
            open: !modalState.open,
        });
    }

    const sendData = (e) => {
        setForm({
            ...form,
            password: e,
        })
    }

    const getImgFile = (dataUrl) => {
        setForm({
            ...form,
            imageDataUrl: dataUrl,
        });
    }

    const handleCheckbox = (id, checked) => {
        const role = id.substring(id.indexOf("_") + 1);
        if (checked) {
            setForm({
                ...form,
                roleList: form.roleList.concat(role)
            });
        } else {
            setForm({
                ...form,
                roleList: form.roleList.filter(items => items !== role)
            });
        }
    }

    // const handleCheckboxAuth = (id, checked) => {
    //
    //     const auth = id.substring(id.indexOf("_") + 1);
    //
    //     if (checked) {
    //         setForm({
    //             ...form,
    //             authList: form.authList.concat(auth)
    //         });
    //     } else {
    //         setForm({
    //             ...form,
    //             authList: form.authList.filter(items => items !== auth)
    //         });
    //     }
    // }

    return (
        <div className="w-100 h-100">
            <BasicModal
                className="w-55 h-55 overFlowYscrollView"
                title={`비밀번호 변경`}
                content={
                    <ModalContent
                        username={form.username}
                        modalClose={(e) => setModalState({
                            ...modalState,
                            open: e
                        })
                        }
                    />
                }
                modalOpen={modalState.open}
                modalClose={(e) => setModalState({
                    ...modalState,
                    open: e
                })}
                sendData={modalState.data}
                //rcvData={rcvData}
            />
            <div className="w-100 h-7 display_table"/>
            <div className="w-100 h-1px bgC-efefef"/>
            <div className="h-93">
                <div className="flex h-90 overFlowYscrollView">
                    <div className="w-60 h-100">
                        <div className="ml-55 mt-12">
                            <div className="flex mb-12 w-90">
                                <CustomTextField
                                    className="w-65 h-26px"
                                    title="사용자 ID"
                                    name="username"
                                    required={true}
                                    disabled={false}
                                    placeholder=""
                                    customOnChange={textFieldHandler}
                                    value={form}
                                />
                                <div className="mt-auto mb-auto">
                                    <button
                                        className="w-78px h-22px duplicateBtn"
                                        onClick={() => onDuplicateHandler("username")}
                                    >중복체크
                                    </button>
                                </div>

                            </div>
                            <div className="flex mb-12 w-90">
                                <CustomTextField
                                    className="w-65 h-26px"
                                    title="사용자명"
                                    name="name"
                                    required={false}
                                    disabled={false}
                                    validLen="15"
                                    placeholder=""
                                    customOnChange={textFieldHandler}
                                    value={form}
                                />
                            </div>
                            <div className="flex mb-12 w-90">
                                <CustomTextField
                                    className="w-65 h-26px"
                                    title="별칭"
                                    name="nickname"
                                    required={true}
                                    disabled={false}
                                    validLen="15"
                                    placeholder=""
                                    customOnChange={textFieldHandler}
                                    value={form}
                                />
                                <div className="mt-auto mb-auto">
                                    <button
                                        className="w-78px h-22px duplicateBtn"
                                        onClick={() => onDuplicateHandler("nickname")}
                                    >중복체크
                                    </button>
                                </div>
                            </div>
                            <div className="flex mb-12 w-90">
                                <CustomTextField
                                    className="w-65 h-26px"
                                    title="이메일"
                                    name="email"
                                    required={false}
                                    disabled={false}
                                    placeholder=""
                                    customOnChange={textFieldHandler}
                                    value={form}
                                />
                            </div>
                            <div className="flex mb-12 w-90">
                                <CustomTextField
                                    className="w-65 h-26px"
                                    title="핸드폰번호"
                                    name="phoneNumber"
                                    required={true}
                                    disabled={false}
                                    validLen="15"
                                    validRegExp={/[ㄱ-ㅎ|ㅏ-ㅣ|가-힣|a-zA-Z\{\}\[\]\/?.,;:|\)*~`!^\-+<>@\#$%&\\\=\(\'\"]/g}
                                    placeholder=""
                                    customOnChange={textFieldHandler}
                                    value={form}
                                />
                                <div className="mt-auto mb-auto">
                                    <button
                                        className="w-78px h-22px duplicateBtn"
                                        onClick={() => onDuplicateHandler("phoneNumber")}
                                    >중복체크
                                    </button>
                                </div>
                            </div>
                            <div className="flex mb-12 w-90">
                                <CustomTextField
                                    className="w-65 h-26px"
                                    title="연락처"
                                    name="contact"
                                    required={false}
                                    disabled={false}
                                    validLen="15"
                                    validRegExp={/[ㄱ-ㅎ|ㅏ-ㅣ|가-힣|a-zA-Z\{\}\[\]\/?.,;:|\)*~`!^\-+<>@\#$%&\\\=\(\'\"]/g}
                                    placeholder=""
                                    customOnChange={textFieldHandler}
                                    value={form}
                                />
                            </div>

                            {
                                registPage || form.password ?
                                    <Fragment>
                                        <div className="flex mb-4 w-90">
                                            <CustomTextField
                                                className="w-65 h-26px"
                                                type="password"
                                                title="비밀번호"
                                                name="password"
                                                required={true}
                                                disabled={false}
                                                placeholder=""
                                                customOnChange={textFieldHandler}
                                                value={form}
                                            />
                                        </div>
                                        <div className="ml-100 mb-12"><label className="label_title w-100">※ 비밀번호는 영문, 숫자, 특수문자 포함 8글자 이상이어야 합니다.</label></div>
                                    </Fragment>
                                    :
                                    <div className="flex mb-12 w-90">
                                        <label className="label_title">비밀번호 재설정</label>
                                        <button
                                            className="w-90px h-22px duplicateBtn"
                                            onClick={onClickModalOpen}
                                        >재설정
                                        </button>
                                    </div>
                            }
                            {
                                cloneUser.menuGroup === 'ADMIN' ?
                                    <div className="flex mb-12 w-90">
                                        <label className="label_title">고객사</label>
                                        <CustomSelectBox
                                            name="customer.code"
                                            categoryList={customerList}
                                            value={form.customer.code}
                                            onChangeHandler={(value, name) => selectBoxHandler('customer.code', {
                                                code: value,
                                                name: name
                                            })}
                                            callbackCodeName="Y"
                                        />
                                    </div> : <></>
                            }
                            <div className="flex mb-12 w-90">
                                <label className="label_title">현장</label>
                                <CustomSelectBox
                                    name="site.code"
                                    categoryList={siteList}
                                    value={form.site.code}
                                    onChangeHandler={(value, name) => selectBoxHandler('site.code', {code: value, name: name})}
                                    callbackCodeName="Y"
                                />
                            </div>
                            <div className="flex mb-12 w-90">
                                <CustomTextField
                                    className="w-65 h-26px"
                                    title="장소"
                                    name="place.name"
                                    required={false}
                                    disabled={match.params.username && form.place.name || form.menuGroup}
                                    customOnChange={textFieldHandler}
                                    value={form}
                                />
                            </div>
                            <div className="flex mb-12 w-90">
                                <label className="label_title">메뉴 그룹</label>
                                <CustomSelectBox
                                    name="menuGroup"
                                    categoryList={searchMenuGroup}
                                    value={form.menuGroup || ''}
                                    onChangeHandler={(value) => selectBoxHandler('menuGroup', value)}
                                />
                            </div>
                            <div className="flex w-90 mb-8">
                                <label className="label_title">권한 설정</label>
                                {
                                    searchAuthGroup.map((item, index) => {
                                        return (
                                            // <CustomCheckbox
                                            //     key={index}
                                            //     id={`auth_${item.code}`}
                                            //     className="j-c-c h-16px mr-50"
                                            //     title={item.name}
                                            //     checked={form.authList.includes(item.code)}
                                            //     readOnly={false}
                                            //     disabled={false}
                                            //     onChange={handleCheckboxAuth}
                                            //  />
                                            <CustomRadioButton
                                                key={index}
                                                className="mr-60"
                                                title={item.name}
                                                id={`authList_${index}`}
                                                name="authList"
                                                value={item.code}
                                                checked={form.authList[0] === item.code ? true : false}
                                                customOnChange={radioBtnHandler}
                                            />
                                        )
                                    })
                                }
                            </div>
                            <div className="flex w-90 mb-8">
                                <label className="label_title">역할 설정</label>
                                <table className="mainTable">
                                    <thead className="tableHead">
                                    <tr>
                                        <th className="v-middle"></th>
                                        <th className="v-middle">역할코드</th>
                                        <th className="v-middle">역할명</th>
                                    </tr>
                                    </thead>
                                    <tbody className="tableBody">
                                    {

                                        roleList.length > 0
                                            ?
                                            roleList.map((item, i) => {
                                                return (
                                                    <tr key={i}>

                                                        <td>
                                                            <CustomCheckbox
                                                                id={`role_${item.code}`}
                                                                className="j-c-c h-16px"
                                                                title=""
                                                                checked={form.roleList.includes(item.code)}
                                                                readOnly={false}
                                                                disabled={false}
                                                                onChange={handleCheckbox}
                                                            />
                                                        </td>
                                                        <td>{item.code}</td>
                                                        <td>
                                                            {item.name}
                                                        </td>

                                                    </tr>

                                                )

                                            })
                                            :
                                            <td colSpan="2">조회 된 데이타가 없습니다.</td>
                                    }

                                    </tbody>
                                </table>
                            </div>
                            <div className="flex w-90">
                                <label className="label_title">사용유무</label>
                                <CustomRadioButton
                                    className="mr-60"
                                    title="사용"
                                    id="radioBtn1"
                                    name="enabled"
                                    value={true}
                                    checked={form.enabled}
                                    customOnChange={radioBtnHandler}
                                />
                                <CustomRadioButton
                                    title="사용안함"
                                    id="radioBtn2"
                                    name="enabled"
                                    value={false}
                                    checked={!form.enabled}
                                    customOnChange={radioBtnHandler}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="w-40 h-100 ">
                        <div className="w-100 mt-12" style={{display: "block"}}>
                            <div className="floatC mr-2p">
                                <ImgUpload
                                    title={"사진"}
                                    imgUpload={getImgFile}
                                    imgFile={form.imageDataUrl}
                                />
                            </div>

                        </div>
                    </div>
                </div>
                <div className="footer_btn h-10">
                    <button className="w-160px h-30px addBtn mr-10" onClick={onSubmitHandler}>저장</button>
                    {registPage ? "" : <button className="w-160px h-30px removeBtn mr-10" onClick={onRemoveHandler}>삭제</button>}
                    <button className="w-160px h-30px cancelBtn" onClick={onCancelHandler}>취소</button>
                </div>
            </div>
        </div>
    )
}

export default withRouter(UserRegist);

export const ModalContent = (props) => {
    const {username, modalClose} = props;
    const [newPassword, setNewPassword] = useState("");

    const textFieldHandler = (e) => {
        if (e.target.name === "newPassword") {
            setNewPassword(e.target.value);
        }
    }

    const chkPassword = () => {
        const pwPattern = /(?=.*\d)(?=.*[~`!@#$%\\^&*()-])(?=.*[a-zA-Z]).{8,50}$/;

        if (!newPassword) {
            alert("새 비밀번호를 입력해 주세요.");
        } else if (!pwPattern.test(newPassword)) {
            alert("비밀번호는 영문, 숫자, 특수문자 포함 8글자 이상이어야 합니다.");
        } else {
            Net.changePassword({username: username, resetPassword: newPassword}, response => {
                console.log(response);
                handleClose();
            })
        }
    }
    const handleClose = () => {
        modalClose(false);
    };

    return (
        <div>
            <div className="flex w-90">
                <label className="label_title">새 비밀번호*</label>
                <input
                    className="w-65 h-26px textField"
                    type="password"
                    name="newPassword"
                    onChange={(e) => textFieldHandler(e)}
                    value={newPassword}
                />
            </div>
            <div><label className="label_title w-100">※ 비밀번호는 영문, 숫자, 특수문자 포함 8글자 이상이어야 합니다.</label></div>
            <div className="footer_btn h-10 mt-10">

                <div className="">
                    <button className="w-80px h-30px addBtn mr-10" onClick={chkPassword}>확인</button>
                    <button className="w-80px h-30px cancelBtn" onClick={handleClose}>취소</button>
                </div>


            </div>

        </div>
    )

}
