import React, {useEffect, useState} from 'react';
import * as XLSX from "xlsx";
import store from 'scripts/store';
import Net from 'scripts/net';
import Common from 'scripts/common';
export const AutoExcelModify = () => {

    const [file, setFile] = useState(null);
    const [fileData, setFileData] = useState(null);
    const [workbook, setWorkbook] = useState(null);
    const [excelData, setExcelData] = useState([]);


    useEffect(() => {
        store.openedPage = '자동화 테스트';

    }, []);

    useEffect(()=>{
        console.log(excelData)
    },[excelData])


    const handleDrop = (event) => {
        event.preventDefault();
        const file = event.dataTransfer.files[0];
        readFile(file);
    };

    const handleDragOver = (event) => {
        event.preventDefault();
    };

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        readFile(file);
    };

    const handleDownload = () => {
        const updatedData = { ...excelData };
        const wb = XLSX.utils.book_new();
        Object.keys(updatedData).forEach((sheet) => {
            const ws = XLSX.utils.json_to_sheet(updatedData[sheet], {
                skipHeader: true,
            });
            ws["!cols"] = sheet['!cols']; // preserve column widths
            ws["!merges"] = sheet['!merges']; // preserve merged cells
             ws['!rows'] = sheet['!rows'];
            // ws['!ref'] = sheet['!ref'];
            // ws['!autofilter'] = sheet['!autofilter'];
             ws['!protect'] = sheet['!protect'];
             ws['!sheetFormat'] = sheet['!sheetFormat'];


            const range = XLSX.utils.decode_range(ws['!ref']);
            for (let row = range.s.r; row <= range.e.r; row++) {
                for (let col = range.s.c; col <= range.e.c; col++) {
                    const cell = ws[XLSX.utils.encode_cell({ r: row, c: col })];
                    const cellStyle = ws[XLSX.utils.encode_cell({ r: row, c: col }) + '.s'];
                    if (cellStyle) {
                        cell.s = cellStyle;
                    }
                }
            }
            XLSX.utils.book_append_sheet(wb, ws, sheet);
        });

        XLSX.writeFile(wb, "updated_file.xlsx");
    };

    const readFile = (file) => {
        const reader = new FileReader();
        reader.readAsBinaryString(file);

        reader.onload = (e) => {
            const data = e.target.result;
            const workbook = XLSX.read(data, {type: 'binary', cellStyles: true});

            // 서식 적용을 위한 새로운 workbook 생성
            const newWorkbook = XLSX.utils.book_new();

            // 모든 시트의 내용과 서식을 가져와서 새로운 서식 적용
            workbook.SheetNames.forEach(function (sheetName) {
                const sheet = workbook.Sheets[sheetName];
                const newSheet = {};

                // 시트의 내용 복사
                Object.keys(sheet).forEach(function (cell) {
                    if (cell[0] === '!') {
                        newSheet[cell] = sheet[cell];
                    } else {
                        const cellRef = XLSX.utils.decode_cell(cell);
                        const newCell = Object.assign({}, sheet[cell]);

                        // 새로운 서식 적용
                        newCell.s = {
                            fill: {
                                fgColor: {rgb: 'FF0000FF'} // 예시: 파란색으로 색상 변경
                            }
                        };

                        newSheet[cell] = newCell;
                    }
                });

                // 새로운 시트를 새로운 workbook에 추가
                XLSX.utils.book_append_sheet(newWorkbook, newSheet, sheetName);
            });
            XLSX.writeFile(newWorkbook, 'output.xlsx');
        }
    }

    const styles = {
        container: {
            width: '50%',
            height: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'stretch',
            border: '2px dashed gray',
            borderRadius: '10px',
            margin: '10px',
            padding: '20px',
            fontSize: '18px',
            fontWeight: 'bold',
            flexDirection : 'column'
        },
    };

    function saveAs(blob, fileName) {
        const url = window.URL.createObjectURL(blob);
        const anchorElem = document.createElement('a');
        anchorElem.style.display = 'none';
        anchorElem.href = url;
        anchorElem.download = fileName;
        document.body.appendChild(anchorElem);
        anchorElem.click();
        document.body.removeChild(anchorElem);
        window.URL.revokeObjectURL(url);
    }

    function s2ab(s) {
        const buf = new ArrayBuffer(s.length);
        const view = new Uint8Array(buf);
        for (let i = 0; i !== s.length; ++i) view[i] = s.charCodeAt(i) & 0xff;
        return buf;
    }

    return (
        <div className="w-100 h-100">
            <div className="w-100 h-1px bgC-efefef"/>
            <div style={styles.container}>
                <div
                    onDrop={handleDrop}
                    onDragOver={handleDragOver}
                    style={{ width: '100%', height: '100%', textAlign: 'center' }}
                >
                    <p>Drag and Drop Excel file here</p>
                </div>
                <div style={{ marginBottom: '1rem', textAlign : 'center', marginTop : '1rem' }}>
                    {workbook && <button onClick={handleDownload}>Download</button>}
                    <input type="file" onChange={handleFileChange} />
                </div>


            </div>
        </div>
    );
}
