import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom'
import { CustomTable, SearchInputBar, CustomTextField, CustomRadioButton, BasicModal } from 'components/molecules';
import store from 'scripts/store';
import Net from 'scripts/net';

const columnList = [
    {
        code: 'code',
        title: '유형코드',
        width: '30',
    },
    {
        code: 'name',
        title: '유형명',
        width: '30',
    },
    {
        code: 'usable',
        title: '사용여부',
        width: '30',
    },
];

export const CodeTypeList = (props) => {
    // useHistory 선언
    const history = useHistory();

    const [programAuth, setProgramAuth] = useState({});
    const [contentData, setContentData] = useState([]);
    const [checkedList, setCheckedList] = useState([]);

    const [codeTypeDetail, setCodeTypeDetail] = useState([]);

    const [pageState, setPageState] = useState({
        currentPage: 0,
        rowPerPage: 13,
        totalElementsCnt: 0,
        drawElementsCnt: 0,
        searchText: '',
    });

    const [modalState, setModalState] = useState({
        open: false,
        data: [],
        code: '',
    });

    useEffect(() => {
        store.openedPage = '유형코드관리';
    }, [])

    useEffect(() => {
        loadData();
        pageAuthInfo();
    }, [pageState.currentPage,
        pageState.searchText,
        modalState.open,
    ]);

    const pageAuthInfo = () => {
        const authGroupCode = props.userInfo.authList[0];
        const menuId = sessionStorage.getItem('menuId');

        Net.getAuthGroupMenuList(menuId, (response) => {
            if(response.status === 200) {
                response.data.forEach(item => {
                    if(item.authGroup === authGroupCode && item.authGroupMenu) {
                        setProgramAuth({
                            searchAuth: Boolean(item.authGroupMenu.searchAuth),
                            saveAuth: Boolean(item.authGroupMenu.saveAuth),
                            excelAuth: Boolean(item.authGroupMenu.excelAuth),
                            deleteAuth: Boolean(item.authGroupMenu.deleteAuth),
                        });
                    }
                });
            }
        });
    }

    const loadData = () => {
        Net.getCommunityCodeTypeList(
            {
                currentPage: pageState.currentPage,
                rowPerPage: pageState.rowPerPage,
                searchText: pageState.searchText
            }, (response) => {
            if(response.status === 200) {
                setContentData(response.data.content);
                setPageState({
                    ...pageState,
                    totalElementsCnt: response.data.totalElements,
                    drawElementsCnt: response.data.numberOfElements,
                });
            } else {
                setContentData([]);
                setPageState({
                    ...pageState,
                    totalElementsCnt: 0,
                    drawElementsCnt: 0,
                });
            }
       });
    }

    const onClickRow = (e) => {
        onClickModalOpen(e.code);
    }

    const onMovePage = (e) => {
        setPageState({
            ...pageState,
            currentPage: e-1
        });
    }

    const removeList = () => {
        const result = window.confirm('삭제하시겠습니까?');
        const removeList = [];
        checkedList.forEach((id) => {
            const node = document.querySelector(`#${id}`);
            const nodeTr = node.closest('tr');
            const code = nodeTr.querySelector('td[name=code]').textContent;
            removeList.push({code: code});
        });

        if(result) {
            Net.getCommunityCodeList(removeList, (response) => {
                const list = response.filter( item => item.data.length > 0);
                console.log(list)
                if( list !== undefined && list.length > 0){
                    const msg = "유형코드 중 세부 코드가 남아있는 유형은 삭제 할 수 없습니다." + "\n" + list.map( item => item.data[0].codeType+" ");
                    window.alert(msg);
                }else{
                    Net.removeCommunityCodeType(removeList, (response) => {
                        if(response) {
                            loadData();
                        }
                    });
                }
            })
        }
    }

    const onSubmitHandler = (e) => {
        setPageState({
            ...pageState,
            searchText: e.searchText,
        });
    }

    const addList = () => {
        onClickModalOpen();
    }

    const goModifypage = () => {
        history.push(`/community/faq/regist/${codeTypeDetail.code}`);
    }

    const onClickModalOpen = (code) => {
        setModalState({
            ...modalState,
            open: !modalState.open,
            code: code
        });
    }

    const sendData = (e) => {
        setModalState({
            ...modalState,
            data: e,
        })
    }

    const rcvData = (e) => {

    }

    return (
        <div className="w-100 h-100">
            <BasicModal
                className=""
                title={`유형코드 등록/수정`}
                content={<ModalContent
                            sendData={sendData}
                            modalClose={(e) => setModalState({
                                                    ...modalState,
                                                    open: e
                                                })
                                        }
                            code={modalState.code}
                        />}

                modalOpen={modalState.open}
                modalClose={(e) => setModalState({
                    ...modalState,
                    open: e
                })}
                sendData={modalState.data}
                //rcvData={rcvData}
            />
            <div className="w-100 h-7 display_table">
                <SearchInputBar
                    className="w-100"
                    searchCategory={false}
                    searchText={true}
                    searchPlaceHolder="유형명을 입력하세요"
                    searchBtn={true}
                    searchBtnTitle="검색"
                    dynamicSearch={false}
                    onSubmit={onSubmitHandler}
                />
                {
                    programAuth.saveAuth &&
                    <div className="display_table_cell v-middle">
                        <button
                            className="addBtn w-90px h-30px floatR mr-20"
                            onClick={addList}
                        >등록</button>
                    </div>
                }
            </div>
            <div className="w-100 h-1px bgC-efefef" >
                <div className="flex h-93">
                    <div className="w-100 h-100">
                        <p className="totalCnt">Total {pageState.totalElementsCnt}</p>
                        {
                            programAuth.deleteAuth && checkedList.length > 0 &&
                            <button
                                className="removeBtn w-90px h-30px floatR mt-7 mr-15"
                                onClick={removeList}
                            >삭제</button>
                        }
                        <CustomTable
                            columnList = {columnList}
                            contentData = {contentData}
                            paging = {true}
                            checkbox = {programAuth.deleteAuth}
                            checkedList = {(checkedList) => {setCheckedList(checkedList)} }
                            totalElementsCnt = {pageState.totalElementsCnt}
                            rowPerPage = {pageState.rowPerPage}
                            currentPage = {pageState.currentPage}
                            drawElementsCnt = {pageState.drawElementsCnt}
                            pagination = {onMovePage}
                            rowClickEvt = {programAuth.saveAuth}
                            onClickRow = {onClickRow}
                            // sort = {true}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

export const ModalContent = (props) => {
    const history = useHistory();
    const [propertyList, setpropertyList] = useState([]);
    //const [duplicateChk, setDuplicateChk] = useState(false);
    const [propertyCheckedList, setPropertyCheckedList] = useState([]);

    const [form, setForm] = useState({
        code: '',
        name: '',
        usable: true,
    });
    const [registPage, setRegistPage] = useState(true);
    const textFieldHandler = (name, value) => {
        //if(name === 'code') setDuplicateChk(false);
        const nextForm = {
            ...form,
            [name]: value
        }
        setForm(nextForm);
    }
    useEffect(() => {
        if(props.code) {
            Net.getCommunityCodeTypeOne(props.code, (response) => {

                if(response.status === 200) {
                    setForm({
                        ...form,
                        ...response.data,
                    });

                    // 등록페이지 여부 확인
                    setRegistPage(false);
                }
            });
        }
    }, [])

    const radioBtnHandler = (name, value) => {
        const nextForm = {
            ...form,
            [name]: value === 'true' ? true : false,
        }
        setForm(nextForm);
    }

    const onRemoveHandler = () => {
        const result = window.confirm('삭제하시겠습니까?');
        if(result) {
            Net.getCommunityCodeList([{code : form.code}], (response) => {
                const list = response.filter(item => item.data.length > 0);
                if (list != undefined && list.length > 0) {
                    const msg = "유형코드 중 세부 코드가 남아있는 유형은 삭제 할 수 없습니다." + "\n";
                    window.alert(msg);
                } else {
                    Net.removeCommunityCodeType([{code: form.code}], (response) => {
                        if (response) {
                            props.modalClose(false);
                        }
                    });
                }
            })
        }
    }

    const onSubmitHandler = () => {

        var result = window.confirm('저장하시겠습니까?');
        if(result) {
            if(registPage) {
                Net.getCommunityCodeTypeOne( form.code, (response) => {
                    if(response.data) {
                        alert('이미 등록된 유형코드 입니다.');
                    } else if(response.data.length === 0|| (response.status === 404 && response.data.cause.includes('does not exist'))) {
                        Net.addCommunityCodeType(form, (response) => {
                            if(response.status === 200) {
                                props.modalClose(false);
                            }
                        });
                    }
                });

            } else {
                Net.updateCommunityCodeType(form, (response) => {
                    if(response.status === 200) {
                        props.modalClose(false);
                    }
                });
            }
        }
    };

    const handleClose = () => {
        props.modalClose(false);
    };

    return (
        <div className="h-100">
            <div className="h-90">
                <div className="flex mb-12 w-100">
                    <CustomTextField
                        className="w-65 h-26px"
                        title="유형코드"
                        name="code"
                        required={true}
                        disabled={registPage ? false : true}
                        placeholder=""
                        customOnChange={textFieldHandler}
                        value={form}
                        readOnly={registPage ? false : true}
                    />
                </div>
                <div className="flex mb-12 w-100">
                    <CustomTextField
                        className="w-65 h-26px"
                        title="유형명"
                        name="name"
                        required={true}
                        disabled={false}
                        placeholder=""
                        customOnChange={textFieldHandler}
                        value={form}
                    />
                </div>
                <div className="flex w-100">
                    <label className="label_title">사용유무*</label>
                    <CustomRadioButton
                        className="mr-60"
                        title="사용"
                        id="radioBtn1"
                        name="usable"
                        value={true}
                        checked={form.usable}
                        customOnChange={radioBtnHandler}
                    />
                    <CustomRadioButton
                        title="사용안함"
                        id="radioBtn2"
                        name="usable"
                        value={false}
                        checked={!form.usable}
                        customOnChange={radioBtnHandler}
                    />
                </div>
            </div>

            <div className="footer_btn h-10 mt-50 mb-30">
                <div className="">
                    <button className="w-80px h-30px addBtn mr-10" onClick={onSubmitHandler}>저장</button>
                    { registPage ? "" : <button className="w-80px h-30px removeBtn mr-10" onClick={onRemoveHandler}>삭제</button>}
                    <button className="w-80px h-30px cancelBtn" onClick={handleClose}>취소</button>
                </div>
            </div>
        </div>
    )
}
