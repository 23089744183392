import React, {useEffect, useState} from "react";
import {CustomSelectBox} from 'components/molecules';
import Net from 'scripts/net';
import Common from 'scripts/common';


export const SiteRegistModal = (props) => {

    const [customerList, setCustomerList] = useState([{
        code: '',
        name: '고객사명 선택'
    }]);
    const [siteList, setSiteList] = useState([{
        code: '',
        name: '현장명 선택'
    }]);

    const [form, setForm] = useState({
        customer : {
            code : '',
            name : '',
        },
        site: {
            code : '',
            name : '',
        },
        currentPage: 1,
        rowPerPage: 99,
    });

    useEffect(()=>{
        //고객사 목록 조회
        getCustomerList();
    },[]);

    useEffect(()=>{
        if(form.customer.code !== '') {
            Common.getSitesList(form.customer.code, (response) => {
                setSiteList(response);
            })
        } else {
            setSiteList([
                {
                    code: '',
                    name: '현장명 선택'
                }
            ])
        }
    },[form.customer])


    const getCustomerList = (callback) => {
        Common.getCustomerList( (response) => {
            setCustomerList(response);
        })
    }

    const selectBoxHandler = (name, value) => {
        switch (name) {
            case 'customer.code':
                setForm({
                    ...form,
                    customer: {
                        code: value.code,
                        name: value.name
                    },
                    site: {
                        code: "",
                        name: ""
                    }
                });
                break;
            case 'site.code':
                setForm({
                    ...form,
                    site: {
                        code: value.code,
                        name: value.name
                    }
                });
                break;
            default:
                break;
        }
    }

    const onSubmitHandler = () => {
        if (!form.customer.code) {
            alert('고객사를 선택해 주세요.');
            document.getElementsByName('customer.code')[0].focus();
            return false;
        }
        if (!form.site.code) {
            alert('현장명을 선택해 주세요.');
            document.getElementsByName('site.code')[0].focus();
            return false;
        }

        // Check Already Exist Site Data on DB
        Net.getAppServiceList(form, (response) =>{
            console.log(response);
            if(response.status === 200){
                // Already Exist
                alert('이미 존재하는 현장입니다.');
            } else if (response.status === 204){
                // Save Site
                Net.addAppService(form, (response)=>{
                    props.modalClose(false);
                    props.reload('reloadSite');
                });
            } else {
                // Error
                alert('잠시후 다시 시도해 주세요.');
            }
        });

    }

    const onCancelHandler = () => {
        //모달 닫기
        props.modalClose(false);
        props.reload('reloadSite');
    }

    return (
        <div className="overflow-hidden">
            <h5 className="m-3 ml-50"> 현장 등록 | 앱 서비스 관리</h5>
            <div className="row">
                <div className="col-12">
                    <div className="flex mt-4 mb-1 w-90">
                        <div
                            className="">
                            <label className="label_title">고객사 *</label></div>
                        <CustomSelectBox
                            name="customer.code"
                            className="d-block"
                            categoryList={customerList}
                            value={form.customer.code}
                            onChangeHandler={(value, name) => selectBoxHandler('customer.code', {code: value, name: name})}
                            callbackCodeName="Y"
                            disabled={false}
                        />
                    </div>

                    <div className="flex mb-3 mt-3 w-90">
                        <label className="label_title">현장 *</label>
                        <CustomSelectBox
                            name="site.code"
                            categoryList={siteList}
                            value={form.site.code}
                            onChangeHandler={(value, name) => selectBoxHandler('site.code', {code: value, name: name})}
                            callbackCodeName="Y"
                            disabled={false}
                        />
                    </div>

                    <div className="footer_btn h-10 mt-4">
                        <div className="">
                            <button className="w-160px h-30px addBtn mr-10" onClick={onSubmitHandler}>저장</button>
                            <button className="w-160px h-30px cancelBtn" onClick={onCancelHandler}>취소</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
