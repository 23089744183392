/* eslint-disable */
// noinspection RegExpRedundantEscape,RegExpRedundantEscape,RegExpDuplicateCharacterInClass

import React, {useEffect, useState} from 'react';
import {CustomTextField, CustomSelectBox, CustomRadioButton, BasicModal, CustomTable,} from 'components/molecules';
import {useHistory, withRouter} from 'react-router-dom';
import store from 'scripts/store';
import Net from 'scripts/net';

const HomeNetRegist = ({match}) => {

    const history = useHistory();

    const [form, setForm] = useState({
        code: '',
        name: '',
        englishName: '',
        customer: {
            code: '',
            name: '',
        },
        postalCode: '',
        address: '',
        detailAddress: '',
        region: {
            code: '',
            name: '',
        },
        builtDate: '',
        numberOfHouseHold: '',
        cctvManufacturer: '',
        cctvUrl: '',
        onepassManufacturer: '',
        onepassAndroidUrl: '',
        onepassIosUrl: '',
        ebookCompanyName: '',
        ebookUrl: '',
        parkingControlCompanyName: '',
        parkingControlUrl: '',
        parkingPositionCompanyName: '',
        parkingPositionUrl: '',
        siteImageId: '',
        imageDataUrl: '',
        etc: '',
        manufacturerCode: '',
        authorizationUrl: '',
        controlUrl: '',
        clientId: '',
        clientSecret: '',
        clientCredentialsId : '',
        clientCredentialsSecret : '',
        deviceModelCodes: [],
        noticeConnection: false,
        isHomenetConnect: false

    });
    const [registPage, setRegistPage] = useState(true);
    const [duplicateChk, setDuplicateChk] = useState(false);
    const [customerList, setCustomerList] = useState([{
        code: '',
        name: '고객사명 선택'
    }]);
    const [manufacturerList, setManufacturerList] = useState([{
        code: '',
        name: '홈넷사 선택'
    }]);
    const [modalState, setModalState] = useState({
        open: false,
        data: [],
    });

    useEffect(() => {
        store.openedPage = '현장별 홈넷사 등록/수정';

        // 상세 조회
        if (match.params.code !== undefined) {
            Net.getSiteOne(match.params.code, (response) => {
                if (response.status === 200) {
                    if(response.data.manufacturerCode !== undefined) {
                        Net.getHomenetOne(match.params.code, (response2) => {
                            if (response2.status === 200) {
                                setForm({
                                    ...form,
                                    ...response.data,
                                    controlUrl: response2.data.controlUrl,
                                    authorizationUrl: response2.data.authorizationUrl,
                                    clientId: response2.data.clientId,
                                    clientSecret: response2.data.clientSecret,
                                    clientCredentialsId : response2.data.clientCredentialsId,
                                    clientCredentialsSecret : response2.data.clientCredentialsSecret,
                                    deviceModelCodes: response2.data.deviceModelCodes,
                                    noticeConnection : response2.data.noticeConnection,
                                    isHomenetConnect: true
                                });
                            } else {
                                setForm({
                                    ...form,
                                    ...response.data,
                                    isHomenetConnect: false
                                });
                            }
                        });
                    } else{
                        setForm({
                            ...form,
                            ...response.data,
                            isHomenetConnect: false
                        });
                    }
                    // 장비타입코드 중복체크 true
                    setDuplicateChk(true);
                    // 등록페이지 여부 확인
                    setRegistPage(false);
                }
            });
        }

        // 고객사 전체 목록
        getCustomerList((response) => {
            setCustomerList(response);
        });

        // 제조사(홈넷사) 전체 목록
        getManufacturerList((response) => {
            setManufacturerList(response);
        })
    }, []);

    const getCustomerList = (callback) => {
        Net.getCustomerList({currentPage: '', rowPerPage: '', searchText: ''}, (response) => {
            if (response.status === 200) {
                let newArray = customerList;
                response.data.content.map(items => {
                    newArray = [
                        ...newArray,
                        {
                            code: items.code,
                            name: items.name
                        }
                    ]
                });
                callback(newArray);
            }
        });
    }

    const getManufacturerList = (callback) => {
        Net.getToolsManufacturerList({currentPage: '', rowPerPage: ''}, (response) => {
            if (response.status === 200) {
                let newArray = manufacturerList;
                response.data.content.map(items => {
                    newArray = [
                        ...newArray,
                        {
                            code: items.code,
                            name: items.name
                        }
                    ]
                });
                callback(newArray);
            }
        });
    }

    const textFieldHandler = (name, value) => {
        // 현장코드 중복체크 후 수정 시 중복체크 재확인 요망을 위한 값 셋팅
        if (name === 'code') setDuplicateChk(false);
        const nextForm = {
            ...form,
            [name]: value
        }
        setForm(nextForm);
    }

    const selectBoxHandler = (name, value) => {
        if (name === 'customerCode') {
            setForm({
                ...form,
                customer: {
                    code: value.code,
                    name: value.name,
                }
            });

        } else if (name === 'manufacturerCode') {
            setForm({
                ...form,
                manufacturerCode: value,
            });
        }
    }
    const onDuplicateHandler = () => {
        if (form.code === '') {
            alert('현장코드를 입력해주세요.');
            return false;
        }

        Net.chkDuplicateSite(form.code, (response) => {
            if (response.status === 200) {
                alert('이미 등록된 현장코드입니다.');
            } else if (response.status === 404) {
                alert('등록 가능한 현장코드입니다.');
                setDuplicateChk(true);
            }
        });
    }

    const onSubmitHandler = () => {
        if (form.code === '') {
            alert('현장코드는 필수값입니다.');
            document.getElementsByName('code')[0].focus();
            return false;
        }
        var result = window.confirm('저장하시겠습니까?');
        if (result) {
            Net.updateSite(form, (response) => {
                if(response.status === 200) {
                    history.push('/thirdparty/homenet/list');
                }
            });
        }
    }

    const onCancelHandler = () => {
        history.push('/thirdparty/homenet/list');
    }

    const onRemoveHandler = async () => {
        const result = window.confirm('삭제하시겠습니까?');
        const newArray =
            {
                ...form,
                manufacturerCode: '',
            }
        await setForm(newArray);
        if (result) {
             await Net.updateSite(newArray, async (response) => {
                if(response.status === 200) {
                   await Net.removeHomenetList([match.params.code], async (response1) => {
                        if (response1) {
                            await history.push('/thirdparty/homenet/list');
                        }
                    });
                }
            });
        }
    }

    const modelChoiceHandler = () => {
        onClickModalOpen();
    }

    const onClickModalOpen = () => {
        setModalState({
            ...modalState,
            open: !modalState.open,
            data :form.manufacturerCode
        });
    }

    const sendData = (e) => {
        setModalState({
            ...modalState,
            data: e,
        })
    }

    const rcvData = (e) => {
        setForm({
            ...form,
            deviceModelCodes: e.map(data => data.code)
        });
    }

    const radioBtnHandler = (name, value) => {
        let nextForm;
        if (name === "authGroup") {
            nextForm = {
                ...form,
                [name]: value,
            }
        } else {
            nextForm = {
                ...form,
                [name]: value === 'true' ? true : false,
            }
        }
        setForm(nextForm);
    }

    return (
        <div className="w-100 h-100">
            <BasicModal
                className="w-70 h-67"
                title="모델코드"
                content={<ModalContent sendData={sendData} formData={form}/>}
                modalOpen={modalState.open}
                modalClose={(e) => setModalState({
                    ...modalState,
                    open: e
                })}
                sendData={modalState.data}
                rcvData={rcvData}
                activeCloseBtn={true}
            />
            <div className="w-100 h-7 display_table"/>
            <div className="w-100 h-1px bgC-efefef"/>
            <div className="h-93">
                <div className="flex h-90">
                    <div className="w-60 h-100">
                        <div className="ml-55 mt-12">
                            <div className="flex mb-20 w-91">
                                <CustomTextField
                                    className="w-70 h-26px"
                                    title="현장코드"
                                    name="code"
                                    required={true}
                                    validRegExp={/[ㄱ-ㅎ|ㅏ-ㅣ|가-힣|\{\}\[\]\/?.,;:|\)*~`!^+<>@\#$%&\\\=\(\'\"]/g}
                                    placeholder="코드는 영문 및 숫자 조합이며, 단어와 단어 사이는 언더바 및 하이픈(-) 사용"
                                    customOnChange={textFieldHandler}
                                    value={form}
                                    disabled={match.params.code !== undefined}
                                />
                                <div className="mt-auto mb-auto">
                                    <button
                                        className="w-80px h-25px duplicateBtn"
                                        onClick={onDuplicateHandler}
                                    >중복체크
                                    </button>
                                </div>
                            </div>
                            <div className="flex mb-20 w-90">
                                <CustomTextField
                                    className="w-30 h-26px"
                                    title="현장명"
                                    name="name"
                                    required={true}
                                    disabled={false}
                                    validLen="15"
                                    validRegExp={/[\{\}\[\]\/?.,;:|\)*~`!^\-_+<>@\#$%&\\\=\(\'\"]/gi}
                                    placeholder="현장명은 특수문자 제외 최대 15자"
                                    customOnChange={textFieldHandler}
                                    value={form}
                                    disabled={match.params.code !== undefined}

                                />
                                <div className="w-10"/>
                                <CustomTextField
                                    className="w-30 h-26px"
                                    title="현장 영문명"
                                    name="englishName"
                                    required={false}
                                    disabled={false}
                                    customOnChange={textFieldHandler}
                                    value={form}
                                    disabled={match.params.code !== undefined}

                                />
                            </div>
                            <div className="flex mb-50 w-90">
                                <label className="label_title">고객사명 *</label>
                                <CustomSelectBox
                                    name="customer.code"
                                    categoryList={customerList}
                                    onChangeHandler={(value, name) => selectBoxHandler('customerCode', {
                                        code: value,
                                        name: name
                                    })}
                                    callbackCodeName="Y"
                                    value={form.customer.code}
                                    disabled={match.params.code !== undefined}
                                />
                            </div>


                            <div className="flex mb-20 w-90">
                                <label className="label_title">홈넷사</label>
                                <CustomSelectBox
                                    name="manufacturerCode"
                                    categoryList={manufacturerList}
                                    onChangeHandler={(value) => selectBoxHandler('manufacturerCode', value)}
                                    value={form.manufacturerCode}
                                />
                            </div>
                            <div className="flex mb-20 w-90">
                                <CustomTextField
                                    className="w-30 h-26px"
                                    title="홈넷사인증 URL"
                                    name="authorizationUrl"
                                    required={false}
                                    disabled={form.manufacturerCode === ''}
                                    customOnChange={textFieldHandler}
                                    value={form}
                                />
                                <div className="w-10"/>
                                <CustomTextField
                                    className="w-30 h-26px"
                                    title="홈넷사제어URL"
                                    name="controlUrl"
                                    required={false}
                                    disabled={form.manufacturerCode === ''}
                                    customOnChange={textFieldHandler}
                                    value={form}
                                />
                            </div>
                            <div className="flex mb-20 w-90">
                                <CustomTextField
                                    className="w-30 h-26px"
                                    title="홈넷 clientId"
                                    name="clientId"
                                    required={false}
                                    disabled={form.manufacturerCode === ''}
                                    customOnChange={textFieldHandler}
                                    value={form}
                                />
                                <div className="w-10"/>
                                <CustomTextField
                                    className="w-30 h-26px"
                                    title="홈넷 clientSecret"
                                    name="clientSecret"
                                    required={false}
                                    disabled={form.manufacturerCode === ''}
                                    customOnChange={textFieldHandler}
                                    value={form}
                                />
                            </div>

                            <div className="flex mb-20 w-90">
                                <CustomTextField
                                    className="w-30 h-26px"
                                    title="사용자 clientId"
                                    name="clientCredentialsId"
                                    required={false}
                                    disabled={form.manufacturerCode === ''}
                                    customOnChange={textFieldHandler}
                                    value={form}
                                />
                                <div className="w-10"/>
                                <CustomTextField
                                    className="w-30 h-26px"
                                    title="사용자 clientSecret"
                                    name="clientCredentialsSecret"
                                    required={false}
                                    disabled={form.manufacturerCode === ''}
                                    customOnChange={textFieldHandler}
                                    value={form}
                                />
                            </div>

                            <div className="flex mb-20 w-91">
                                <CustomTextField
                                    className="w-70 h-26px"
                                    title="즐겨찾기기준"
                                    name="deviceModelCodes"
                                    required={false}
                                    disabled={form.manufacturerCode === ''}
                                    readOnly={true}
                                    customOnClick={modelChoiceHandler}
                                    value={form}
                                />
                                <button
                                    className="w-80px h-25px duplicateBtn"
                                    onClick={modelChoiceHandler}
                                >모델 선택
                                </button>
                            </div>


                            <div className="flex w-90">
                                <label className="label_title">공지사항 연동</label>
                                <CustomRadioButton
                                    className="mr-60"
                                    title="사용"
                                    id="radioBtn1"
                                    name="noticeConnection"
                                    value={true}
                                    checked={form.noticeConnection}
                                    customOnChange={radioBtnHandler}
                                />
                                <CustomRadioButton
                                    title="사용안함"
                                    id="radioBtn2"
                                    name="noticeConnection"
                                    value={false}
                                    checked={!form.noticeConnection}
                                    customOnChange={radioBtnHandler}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer_btn h-10">
                    <button className="w-160px h-30px addBtn mr-10" onClick={onSubmitHandler}>저장</button>
                    {registPage ? "" :
                        <button className="w-160px h-30px removeBtn mr-10" onClick={onRemoveHandler}>삭제</button>}
                    <button className="w-160px h-30px cancelBtn" onClick={onCancelHandler}>취소</button>
                </div>
            </div>
        </div>
    )
}

export default withRouter(HomeNetRegist);

const modalColumnList = [
    {
        code: 'modelId',
        title: '',
        width: '',
        hidden: true,
    },
    {
        code: 'code',
        title: '모델코드',
        width: '38',
    },
    {
        code: 'name',
        title: '모델명',
        width: '20',
    },
    {
        code: 'type.code',
        title: '장비타입',
        width: '20',
    },
    {
        code: 'manufacturer.code',
        title: '제조사',
        width: '20',
    },
]

export const ModalContent = (props) => {

    const [firstCheckedData, setFirstCheckedData] = useState([]);
    const [modelList, setModelList] = useState([]);
    const [modelCheckedItems, setModelCheckedItems] = useState([]);

    useEffect(() => {
        Net.getToolsModelList(
            {
                currentPage: '0',
                rowPerPage: '99999',
                searchText: '',
                manufacturer: {
                    code: props.formData.manufacturerCode ? props.formData.manufacturerCode : '',
                },
                type: {
                    code: '',
                },
            }, (response) => {
                if (response.status === 200) {
                    const deviceModelCodeList = props.formData.deviceModelCodes && props.formData.deviceModelCodes.map(code => {
                        return code || [];
                    });

                    const rowIdxList = [];
                    response.data.content.map((data, index) => {
                        const code = data.code;
                        if (deviceModelCodeList.indexOf(code) !== -1) {
                            rowIdxList.push('row_' + index);
                        }
                    });
                    setModelList(response.data.content);
                    setFirstCheckedData(rowIdxList);
                }
            });
    }, [])

    useEffect(() => {
        if (modelCheckedItems.length >= 0) {
            const sendData = [];

            modelCheckedItems.forEach((item, i) => {
                const node = document.querySelector(`#${modelCheckedItems[i]}`);
                const nodeTr = node.closest('tr');
                const modelIdValue = nodeTr.querySelector('td[name=modelId]').textContent;
                modelList.forEach(data => {
                    if (data.modelId === modelIdValue) {
                        sendData.push(data);
                    }
                });
            });
            props.sendData(sendData);
        }
    }, [modelCheckedItems])

    return (
        <CustomTable
            className="w-100 h-100 overflowYscroll"
            columnList={modalColumnList}
            contentData={modelList}
            checkbox={true}
            checkedList={(checkedList) => {
                setModelCheckedItems(checkedList)
            }}
            firstCheckedData={firstCheckedData}
            rowClickEvt={false}
            paging={false}
        />
    )
}
