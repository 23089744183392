import React, {Fragment, useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {CustomTable, SearchInputBar} from 'components/molecules';
import store from 'scripts/store';
import Net from 'scripts/net';

const columnList = [
    {
        code: 'code',
        title: '모델코드',
        width: '38',
    },
    {
        code: 'name',
        title: '모델명',
        width: '20',
    },
    {
        code: 'type.name',
        title: '장비타입',
        width: '20',
    },
    {
        code: 'manufacturer.name',
        title: '제조사',
        width: '20',
    },
    {
        code: 'manufacturer.code',
        title: '',
        width: '',
        hidden: true,
    },
];

export const ToolsModelList = (props) => {

    // useHistory 선언
    const history = useHistory();

    const [contentData, setContentData] = useState([]);
    const [checkedList, setCheckedList] = useState([]);
    const [searchCategoryGroup, setSearchCategoryGroup] = useState([
        [
            [
                {code: '', name: '제조사 선택'}
            ]
        ],
        [
            [
                {code: '', name: '장비타입 선택'}
            ]
        ],
    ]);
    const [pageState, setPageState] = useState({
        totalElementsCnt : 0,
        drawElementsCnt : 0,
    })

    const [searchState, setSearchState] = useState({
        currentPage: 0,
        rowPerPage: 13,
        searchText: '',
        manufacturer: {
            code: '',
        },
        type: {
            code: '',
        },
    });

    const [propertyValue, setPropertyValue] = useState(
        {
            attributes: [],
            operations: [],
            automationOperations: [],
            automationAttributes: []
        }
    );

    const [clickedRowData, setClickedRowData] = useState({
        manufacturerCode: '',
        code: '',
    });

    const [propertyList, setPropertyList] = useState([]);
    const [programAuth, setProgramAuth] = useState({});
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        store.openedPage = '모델 목록';
        getToolsManufacturerList((response1) => {
            getToolsTypeList((response2) => {
                setSearchCategoryGroup([response1, response2]);
            });
        });
        pageAuthInfo();
    }, [])

    useEffect(() => {
        loadData();
    }, [searchState.currentPage,
        searchState.searchText,
        searchState.manufacturer.code,
        searchState.type.code
    ]);

    const pageAuthInfo = () => {
        const authGroupCode = props.userInfo.authList[0];
        const menuId = sessionStorage.getItem('menuId');

        Net.getAuthGroupMenuList(menuId, (response) => {
            if (response.status === 200) {
                response.data.forEach(item => {
                    if (item.authGroup === authGroupCode && item.authGroupMenu) {
                        setProgramAuth({
                            searchAuth: Boolean(item.authGroupMenu.searchAuth),
                            saveAuth: Boolean(item.authGroupMenu.saveAuth),
                            excelAuth: Boolean(item.authGroupMenu.excelAuth),
                            deleteAuth: Boolean(item.authGroupMenu.deleteAuth),
                        });
                    }
                });
            }
        });
    }

    const loadData = () => {
        setLoading(true);
        Net.getToolsModelList(searchState, (response) => {
            setLoading(false);
            if (response.status === 200) {
                setContentData(response.data.content);
                setPageState({
                    totalElementsCnt: response.data.totalElements,
                    drawElementsCnt: response.data.numberOfElements,
                });
                setPropertyValue({
                    attributes: [],
                    operations: [],
                    automationOperations: [],
                    automationAttributes: []

                });
            } else if (response.status === 404) {
                setContentData([]);
                setPageState({
                    totalElementsCnt: 0,
                    drawElementsCnt: 0,
                });
                setPropertyValue({
                    attributes: [],
                    operations: [],
                    automationOperations: [],
                    automationAttributes: []
                });
            } else if( response.status === 204){
                setContentData([]);
                setPageState({
                    totalElementsCnt: 0,
                    drawElementsCnt: 0,
                });
                setPropertyValue({
                    attributes: [],
                    operations: [],
                    automationOperations: [],
                    automationAttributes: []
                });
            }
        });
    }

    const getToolsManufacturerList = (callback) => {
        Net.getToolsManufacturerList(
            {currentPage: '', rowPerPage: '', searchText: ''}, (response) => {
                if (response.status === 200) {
                    let newArray = searchCategoryGroup[0][0];
                    response.data.content.forEach(items => {
                        newArray = [
                            ...newArray,
                            {
                                code: items.code,
                                name: items.name
                            }
                        ]
                    });
                    callback(newArray);
                }
            });
    }

    const getToolsTypeList = (callback) => {
        Net.getToolsTypeList(
            {currentPage: '', rowPerPage: '', searchText: ''}, (response) => {
                if (response.status === 200) {
                    let newArray = searchCategoryGroup[1][0];
                    response.data.content.forEach(items => {
                        newArray = [
                            ...newArray,
                            {
                                code: items.code,
                                name: items.name
                            }
                        ]
                    });
                    callback(newArray);
                }
            });
    }

    const onMovePage = (e) => {
        setSearchState({
            ...searchState,
            currentPage: e - 1
        });
    }

    const onSubmitHandler = (e) => {
        setSearchState({
            ...searchState,
            currentPage: 0,
            searchText: e.searchText,
            manufacturer: {
                code: e.selectedCategory[0],
            },
            type: {
                code: e.selectedCategory[1],
            },
        });
    }

    const addList = () => {
        history.push('/tools/model/regist');
    }

    const updateList = () => {
        if (clickedRowData.manufacturerCode !== '' && clickedRowData.code !== '') {
            history.push(`/tools/model/regist/${clickedRowData.manufacturerCode}/${clickedRowData.code}`);
        } else {
            alert('모델코드를 선택해주세요.');
        }

    }

    const onClickRow = (e) => {
        const manufacturerCode = e.manufacturer.code;
        const code = e.code;
        Net.getToolsModelOne({manufacturerCode: manufacturerCode, code: code}, (response) => {
            if (response.status === 200) {
                setPropertyValue(
                    {
                        attributes: response.data.attributes ? response.data.attributes : [],
                        operations: response.data.operations ? response.data.operations : [],
                        automationOperations: response.data.automationOperations ? response.data.automationOperations : [],
                        automationAttributes: response.data.automationAttributes? response.data.automationAttributes : []
                    }
                );
                setClickedRowData({
                    manufacturerCode: manufacturerCode,
                    code: code,
                })
                setPropertyList(response.data);
            }
        });
    }

    const removeList = () => {
        const result = window.confirm('삭제하시겠습니까?');
        const removeCodeList = [];
        checkedList.forEach((id) => {
            const node = document.querySelector(`#${id}`);
            const nodeTr = node.closest('tr');
            const codeValue = nodeTr.querySelector('td[name=code]').textContent;
            const manufacturerCodeValue = nodeTr.querySelector('td[name=manufacturer\\.code]').textContent;
            removeCodeList.push({code: codeValue, manufacturerCode: manufacturerCodeValue});
        });

        if (result) {
            Net.removeToolsModelList(removeCodeList, (response) => {
                if (response) {
                    setClickedRowData({
                        manufacturerCode: '',
                        code: '',
                    })
                    loadData();
                }
            });
        }
    }

    const makeListToDom = (list, autoList) =>{
        const dom = <Fragment>
            {
                list.map((data, index1) => {
                        return (
                            <tr key={index1}>
                                <td>{index1 + 1}</td>
                                <td>{data.code}</td>
                                <td>{data.name}</td>
                                <td>
                                    {
                                        data.valueType === 'ENUMERATION' ?
                                            (data.availableValues !== undefined && data.availableValues.map((value, index) => {
                                                if (data.availableValues.length - 1 === index ) {
                                                    return value;
                                                } else {
                                                    return value += ', ';
                                                }
                                            })) :
                                            (data.bottomValue + ' ~ ' + data.topValue)
                                    }
                                </td>
                                <td>
                                    {
                                        autoList.findIndex( i => i.id === data.id) >= 0 ?
                                        <p>활용</p>
                                        :<p>
                                        미활용
                                        </p>
                                    }
                                </td>
                            </tr>
                        )
                    })
            }
        </Fragment>

        return dom;
    }
    return (
        <div className="w-100 h-100">
            <div className="w-100 h-7 display_table">
                <SearchInputBar
                    className="w-100"
                    searchCategory={true}
                    searchCategoryGroup={searchCategoryGroup}
                    searchText={true}
                    searchPlaceHolder="모델명을 입력해 주세요."
                    searchBtn={true}
                    searchBtnTitle="검색"
                    dynamicSearch={false}
                    onSubmit={onSubmitHandler}
                    refreshBtn={true}
                    refreshHandler={loadData}
                />
                {
                    programAuth.saveAuth &&
                    <div className="display_table_cell v-middle">
                        <button
                            className="addBtn w-90px h-30px floatR mr-20"
                            onClick={addList}
                        >등록
                        </button>
                    </div>
                }
            </div>
            <div className="w-100 h-1px bgC-efefef"/>
            <div className="flex h-93">
                <div className="w-60 h-100">
                    <p className="totalCnt">Total {pageState.totalElementsCnt}</p>
                    {
                        programAuth.deleteAuth && checkedList.length > 0 &&
                        <button
                            className="removeBtn w-90px h-30px floatR mt-7 mr-15"
                            onClick={removeList}
                        >삭제</button>
                    }
                    <CustomTable
                        columnList={columnList}
                        contentData={contentData}
                        checkbox={programAuth.deleteAuth}
                        checkedList={(checkedList) => {
                            setCheckedList(checkedList)
                        }}
                        paging={true}
                        totalElementsCnt={pageState.totalElementsCnt}
                        rowPerPage={searchState.rowPerPage}
                        currentPage={searchState.currentPage}
                        drawElementsCnt={pageState.drawElementsCnt}
                        pagination={onMovePage}
                        rowClickEvt={true}
                        onClickRow={onClickRow}
                        loading={loading}
                        // sort = {true}
                    />
                </div>
                <div className="w-40 h-90">
                    <div className="flex">
                        <p className="body_content_subtitle">장비 속성 표시</p>
                        <button
                            className="defaultBtn bgC-eb878c borderC-eb878c w-90px h-30px mr-20 mt-auto mb-auto ml-auto"
                            onClick={updateList}
                        >수정
                        </button>
                    </div>
                    <div className="w-100 h-50 overFlowYscrollView">
                        <table className="mainTable">
                            <colgroup>
                                <col width="10%"/>
                                <col width="35%"/>
                                <col width="20%"/>
                                <col width="20%"/>
                                <col width="15%"/>

                            </colgroup>
                            <thead className="bgC-c5c5c5">
                            <tr>
                                <th colSpan="5" className="tableHead fixedHeader t-0px">장비행위</th>
                            </tr>
                            <tr>
                                <th className="v-middle tableHead fixedHeader t-36px"><span className="h-24px">No</span>
                                </th>
                                <th className="v-middle tableHead fixedHeader t-36px"><span
                                    className="h-24px">속성코드</span></th>
                                <th className="v-middle tableHead fixedHeader t-36px"><span
                                    className="h-24px">속성명</span></th>
                                <th className="v-middle tableHead fixedHeader t-36px"><span
                                    className="h-24px">속성값</span></th>
                                <th className="v-middle tableHead fixedHeader t-36px"><span
                                    className="h-24px">자동화 여부</span></th>
                            </tr>
                            </thead>
                            <tbody className="tableBody">
                            {
                                propertyValue.operations.length > 0 ?
                                    (
                                        makeListToDom(propertyValue.operations,propertyValue.automationOperations)
                                    )
                                    :
                                    (<tr>
                                        <td colSpan="5">조회 된 데이터가 없습니다.</td>
                                    </tr>)
                            }
                            </tbody>
                        </table>
                    </div>
                    <div className="w-100 h-45 overFlowYscrollView">
                        <table className="mainTable ">
                            <colgroup>
                                <col width="10%"/>
                                <col width="35%"/>
                                <col width="20%"/>
                                <col width="20%"/>
                                <col width="15%"/>
                            </colgroup>
                            <thead className="bgC-c5c5c5">
                            <tr>
                                <th colSpan="5" className="tableHead fixedHeader t-0px">장비정보</th>
                            </tr>
                            <tr>
                                <th className="v-middle tableHead fixedHeader t-36px"><span className="h-24px">No</span>
                                </th>
                                <th className="v-middle tableHead fixedHeader t-36px"><span
                                    className="h-24px">속성코드</span></th>
                                <th className="v-middle tableHead fixedHeader t-36px"><span
                                    className="h-24px">속성명</span></th>
                                <th className="v-middle tableHead fixedHeader t-36px"><span
                                    className="h-24px">속성값</span></th>
                                <th className="v-middle tableHead fixedHeader t-36px"><span
                                    className="h-24px">자동화 여부</span></th>

                            </tr>
                            </thead>
                            <tbody className="tableBody">
                            {
                                propertyValue.attributes.length > 0 ?
                                    (
                                        makeListToDom(propertyValue.attributes,propertyValue.automationAttributes)
                                    )
                                    :
                                    (<tr>
                                        <td colSpan="5">조회 된 데이터가 없습니다.</td>
                                    </tr>)
                            }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )
}
