import React, {Fragment, useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom'
import {CustomTable, SearchInputBar} from 'components/molecules';
import store from 'scripts/store';
import Net from 'scripts/net';
import Common from 'scripts/common';
import {toast} from "react-toastify";
import {usePageAuth} from "scripts/hooks";

const columnList = [
    {
        code: 'faqId',
        title: 'faqId',
        width: '8',
        hidden: true,
    },
    {
        code: 'classificationKOR',
        title: '구분',
        width: '8',
    },
    {
        code: 'title',
        title: '제목',
        width: '20',
    },
    {
        code: 'site.name',
        title: '현장명',
        width: '10',
    },
    {
        code: 'customer.name',
        title: '고객사',
        width: '8',
    },
    {
        code: 'publicYn',
        title: '사용여부',
        width: '5',
    },
];

export const FaqList = (props) => {
    // useHistory 선언
    const history = useHistory();
    const user = props.userInfo;
    const [contentData, setContentData] = useState([]);
    const [checkedList, setCheckedList] = useState([]);
    const programAuth = usePageAuth(props.userInfo.authList[0], sessionStorage.getItem('menuId'));
    const [loading, setLoading] = useState(false);

    const [searchCategory, setSearchCategory] = useState([
        [
            [
                {code: '', name: '고객사 선택'}
            ]
        ],
        [
            [
                {code: '', name: '현장명 선택'}
            ]
        ],
        [
            [
                {code: '', name: '구분 선택'}
            ]
        ],
    ]);

    const [faqDetail, setFaqDetail] = useState([]);

    const [pageState, setPageState] = useState({
        currentPage: 0,
        rowPerPage: 13,
        totalElementsCnt: 0,
        drawElementsCnt: 0,
        searchCategory1: user.menuGroup === 'ADMIN' ? '' : user.customer && user.customer.code,
        searchCategory2: user.menuGroup === 'OPERATOR' ? user.site && user.site.code: '',
        searchCategory3: '',
        searchText: '',
    });

    useEffect(() => {
        store.openedPage = '자주묻는 질문 목록';
        getCategoryList('');
    }, [])

    useEffect(() => {
        loadData()
    }, [pageState.currentPage,
        pageState.searchCategory1,
        pageState.searchCategory2,
        pageState.searchCategory3,
        pageState.searchText,
    ]);

    const loadData = () => {
        setLoading(true);
        Common.getCommunityClassificationList("FAQ_CLASSIFICATION",response2 => {
            const codeList = response2.data;
            Net.getFaqList(
                {
                    currentPage: pageState.currentPage,
                    rowPerPage: pageState.rowPerPage,
                    searchCategory1: pageState.searchCategory1,
                    searchCategory2: pageState.searchCategory2,
                    searchCategory3: pageState.searchCategory3,
                    searchText: pageState.searchText
                }, (response) => {
                    setLoading(false);
                    if (response.status === 200) {
                        response.data.content.forEach((item) => {

                            codeList.map(cItem => {
                                if (item.classification === cItem.code) {
                                    item.classificationKOR = cItem.name;
                                }
                            })
                        })
                        setContentData(response.data.content);
                        setPageState({
                            ...pageState,
                            totalElementsCnt: response.data.totalElements,
                            drawElementsCnt: response.data.numberOfElements,
                        });
                    } else {
                        setContentData([]);
                        setPageState({
                            ...pageState,
                            totalElementsCnt: 0,
                            drawElementsCnt: 0,
                        });
                    }
                    setFaqDetail([]);
                });
        });
    }

    const getCategoryList = () => {
        let response3 = [];
        Common.getCommunityClassificationList("FAQ_CLASSIFICATION", response => {
            if (response.status === 200) {
                response3 = response.data;
                if (user.menuGroup === 'ADMIN') {
                    Common.getCustomerList((response1) => {
                        Common.getSitesList(null, (response2) => {
                            setSearchCategory([response1, response2, response3]);
                        });
                    });
                } else if (user.menuGroup === 'MANAGER') {
                    Common.getSitesList(pageState.searchCategory1, (response2) => {
                        setSearchCategory([response2, response3]);
                    });
                } else {
                    setSearchCategory([response3]);
                }
            }
        });
    }


    const getRelateCategory = (selectedCategory) => {
        if (selectedCategory === "reset") selectedCategory = "";
        Common.getSitesList(selectedCategory, (response2) => {
            setSearchCategory([
                searchCategory[0],
                response2,
                searchCategory[2]
            ]);
        });
    }


    const onClickRow = (e) => {
        Net.getFaqOne(e.faqId, (response) => {
            if (response.status === 200) {
                setFaqDetail(response.data);
            } else {
                setFaqDetail([]);
            }
        });
    }

    const onMovePage = (e) => {
        setPageState({
            ...pageState,
            currentPage: e - 1
        });
    }

    const removeList = () => {
        const result = window.confirm('삭제하시겠습니까?');
        const removeList = [];
        checkedList.map((id) => {
            const node = document.querySelector(`#${id}`);
            const nodeTr = node.closest('tr');
            const faqId = nodeTr.querySelector('td[name=faqId]').textContent;
            removeList.push({faqId: faqId});
        });
        if (result) {
            Net.removeFaq(removeList, (response) => {
                if (response) {
                    toast('삭제 되었습니다.',{
                        autoClose : 1500,
                        pauseOnHover: false,
                        draggable : false,
                        position : "top-center"
                    });
                    loadData();
                }
            });
        }
    }

    const onSubmitHandler = (e) => {
        if (user.menuGroup === 'ADMIN') {
            setPageState({
                ...pageState,
                currentPage: 0,
                searchCategory1: e.selectedCategory[0],
                searchCategory2: e.selectedCategory[1],
                searchCategory3: e.selectedCategory[2],
                searchText: e.searchText,
            });
        } else if (user.menuGroup === 'MANAGER') {
            setPageState({
                ...pageState,
                currentPage: 0,
                searchCategory1: pageState.searchCategory1,
                searchCategory2: e.selectedCategory[0],
                searchCategory3: e.selectedCategory[1],
                searchText: e.searchText,
            });
        } else{
            setPageState({
                ...pageState,
                currentPage: 0,
                searchCategory1: pageState.searchCategory1,
                searchCategory2: pageState.searchCategory2,
                searchCategory3: e.selectedCategory[0],
                searchText: e.searchText,
            });
        }
    }

    const addList = () => {
        history.push('/community/faq/regist');
    }

    const goModifypage = () => {
        history.push(`/community/faq/regist/${faqDetail.faqId}`);
    }

    return (
        <div className="w-100 h-100">
            <div className="w-100 h-7 display_table">
                <SearchInputBar
                    role={user.menuGroup}
                    className="w-100"
                    searchCategory={user.menuGroup === 'ADMIN' || user.menuGroup === 'MANAGER'}
                    searchCategoryGroup={searchCategory}
                    searchText={true}
                    searchPlaceHolder="제목을 입력하세요."
                    searchBtn={true}
                    searchBtnTitle="검색"
                    dynamicSearch={false}
                    relateCategoryHandler={user.menuGroup === 'ADMIN' && getRelateCategory}
                    onSubmit={onSubmitHandler}
                    refreshBtn={true}
                    refreshHandler={loadData}
                />
                {
                    programAuth.saveAuth &&
                    <div className="display_table_cell v-middle">
                        <button
                            className="addBtn w-90px h-30px floatR mr-20"
                            onClick={addList}
                        >등록
                        </button>
                    </div>
                }
            </div>
            <div className="w-100 h-1px bgC-efefef">
                <div className="flex h-93">
                    <div className="w-70 h-100">
                        <p className="totalCnt">Total {pageState.totalElementsCnt}</p>
                        {
                            programAuth.deleteAuth && checkedList.length > 0 &&
                            <button
                                className="removeBtn w-90px h-30px floatR mt-7 mr-15"
                                onClick={removeList}
                            >삭제</button>
                        }
                        <CustomTable
                            columnList={columnList}
                            contentData={contentData}
                            paging={true}
                            checkbox={programAuth.deleteAuth}
                            checkedList={(checkedList) => {
                                setCheckedList(checkedList)
                            }}
                            totalElementsCnt={pageState.totalElementsCnt}
                            rowPerPage={pageState.rowPerPage}
                            currentPage={pageState.currentPage}
                            drawElementsCnt={pageState.drawElementsCnt}
                            pagination={onMovePage}
                            rowClickEvt={true}
                            onClickRow={onClickRow}
                            loading={loading}
                            // sort = {true}
                        />
                    </div>
                    <div className="w-30 h-100">
                        <div className="flex">
                            <p className="body_content_subtitle">내용</p>
                            {
                                faqDetail.contents ?
                                    (
                                        <button
                                            className="defaultBtn bgC-eb878c borderC-eb878c w-90px h-30px mr-20 mt-auto mb-auto ml-auto"
                                            onClick={goModifypage}
                                        >수정</button>
                                    ) : ""
                            }

                        </div>
                        <div className="w-100 h-100">
                            <table className="mainTable">
                                <thead className="tableHead">
                                <tr>
                                    <th>내용</th>
                                </tr>
                                </thead>
                                <tbody className="tableBody">
                                {
                                    faqDetail.contents ?
                                        (
                                            <Fragment>
                                                <tr style={{borderBottom: faqDetail.imageDataUrl && "none"}}>
                                                    <td style={{
                                                        padding: "10px",
                                                        overflow: "unset",
                                                        whiteSpace: "initial",
                                                        borderBottom: "none"
                                                    }}>
                                                        <div
                                                            dangerouslySetInnerHTML={{__html: faqDetail.contents}}></div>
                                                    </td>
                                                </tr>
                                                {
                                                    faqDetail.imageDataUrl &&
                                                    <tr style={{borderTop: "none"}}>
                                                        <td style={{borderTop: "none"}}>
                                                            <img src={faqDetail.imageDataUrl} alt="complex=Img"
                                                                 width="300px"/>
                                                        </td>
                                                    </tr>
                                                }

                                            </Fragment>


                                        ) : (
                                            <tr>
                                                <td>조회 된 데이터가 없습니다.</td>
                                            </tr>
                                        )
                                }

                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
