import React, { useEffect, useState } from 'react';
import { CustomTextField,CustomRadioButton, CustomSelectBox, ImgUpload, SearchAddress, BasicModal, CustomTable} from 'components/molecules';
import { useHistory, withRouter } from 'react-router-dom';
import store from 'scripts/store';
import Net from 'scripts/net';
import {toJS} from "mobx";
import TextareaAutosize from "react-textarea-autosize";

const SiteRegist = ({match}) => {

    const history = useHistory();
    const user = toJS(store.user)

    const [form, setForm] = useState({
        code: '',
        name: '',
        englishName: '',
        customer: {
            code: '',
            name: '',
        },
        postalCode: '',
        address: '',
        detailAddress: '',
        region: {
            code: '',
            name: '',
        },
        builtDate: '',
        numberOfHouseHold: '',
        cctvManufacturer: '',
        cctvUrl: '',
        usable: true,
        onepassUsable: false,
        onepassManufacturer: '',
        onepassAndroidUrl: '',
        onepassIosUrl: '',
        ebookCompanyName: '',
        ebookUrl: '',
        parkingControlCompanyName: '',
        parkingControlUrl: '',
        parkingPositionCompanyName: '',
        parkingPositionUrl: '',
        siteImageId: '',
        imageDataUrl: '',
        etc: '',
        manufacturerCode: '',
        authorizationUrl: '',
        controlUrl: '',
        clientId: '',
        clientSecret: '',
        deviceModelCodes: [],
        noticeConnection: false,
        isHomenetConnect: false,
        tempUserPrefix : '',
        emsRemoteReadingUsable : false,
        homenetModePasswordUsable : false
    });
    const [registPage, setRegistPage] = useState(true);
    const [duplicateChk, setDuplicateChk] = useState(false);
    const [customerList, setCustomerList] = useState([{
        code: '',
        name: '고객사명 선택'
    }]);
    const [regionList, setRegionList] = useState([{
        code: '',
        name: '지역명 선택'
    }]);
    const [manufacturerList, setManufacturerList] = useState([{
        code: '',
        name: '홈넷사 선택'
    }]);
    const [isDaumPost, setIsDaumPost] = useState(false);
    const [modalState, setModalState] = useState({
        open: false,
        data: [],
    });

    useEffect(() => {
        store.openedPage = '현장 등록/수정';

        // 상세 조회
        if(match.params.code !== undefined) {
            Net.getSiteOne(match.params.code, (response) => {
                if(response.status === 200) {
                    Net.getHomenetOne(match.params.code, (response2) => {
                        if(response2 !== undefined && response2.status === 200) {
                            setForm({
                                ...form,
                                ...response.data,
                                controlUrl: response2.data.controlUrl,
                                authorizationUrl: response2.data.authorizationUrl,
                                clientId: response2.data.clientId,
                                clientSecret: response2.data.clientSecret,
                                clientCredentialsId: response2.data.clientCredentialsId,
                                clientCredentialsSecret: response2.data.clientCredentialsSecret,
                                deviceModelCodes: response2.data.deviceModelCodes,
                                noticeConnection : response2.data.noticeConnection,
                                isHomenetConnect: true
                            });
                        } else {
                            setForm({
                                ...form,
                                ...response.data,
                                isHomenetConnect: false
                            });
                        }
                    });
                    // 장비타입코드 중복체크 true
                    setDuplicateChk(true);
                    // 등록페이지 여부 확인
                    setRegistPage(false);
                }
            });
        }

        // 고객사 전체 목록
        getCustomerList((response) => {
            setCustomerList(response);
        });

        // 지역 전체 목록
        getRegionList((response) => {
            setRegionList(response);
        })

        // 제조사(홈넷사) 전체 목록
        getManufacturerList((response) => {
            setManufacturerList(response);
        })
    }, []);

    const getCustomerList = (callback) => {
        Net.getCustomerList({currentPage: '', rowPerPage: '', searchText: ''}, (response) => {
            if(response.status === 200) {
                let newArray = customerList;
                response.data.content.map(items => {
                    newArray = [
                        ...newArray,
                        {
                            code: items.code,
                            name: items.name
                        }
                    ]
                });
                callback(newArray);
            }
        });
    }

    const getRegionList = (callback) => {
        Net.getRegionList((response) => {
            if(response.status === 200) {
                let newArray = regionList;
                response.data.contents.map(items => {
                    newArray = [
                        ...newArray,
                        {
                            code: items.regionCode,
                            name: items.regionName
                        }
                    ]
                });
                callback(newArray);
            }
        });
    }

    const getManufacturerList = (callback) => {
        Net.getToolsManufacturerList({currentPage: '', rowPerPage: '', searchText: ''}, (response) => {
            if(response.status === 200) {
                let newArray = manufacturerList;
                response.data.content.map(items => {
                    newArray = [
                        ...newArray,
                        {
                            code: items.code,
                            name: items.name
                        }
                    ]
                });
                callback(newArray);
            }
        });
    }

    const textFieldHandler = (name, value) => {
        // 현장코드 중복체크 후 수정 시 중복체크 재확인 요망을 위한 값 셋팅
        if(name === 'code') setDuplicateChk(false);
        const nextForm = {
            ...form,
            [name]: value
        }
        setForm(nextForm);
    }

    const selectBoxHandler = (name, value) => {
        if(name === 'customerCode') {
            setForm({
                ...form,
                customer: {
                    code: value.code,
                    name: value.name,
                }
            });

        } else if(name === 'regionCode') {
            setForm({
                ...form,
                region: {
                    code: value.code,
                    name: value.name,
                }
            });
        } else if(name === 'manufacturerCode') {
            setForm({
                ...form,
                manufacturerCode: value,
            });
        }
    }

    const onDuplicateHandler = () => {
        if(form.code === '') {
            alert('현장코드를 입력해주세요.');
            return false;
        }

        Net.chkDuplicateSite(form.code, (response) => {
            if(response.status === 200) {
                alert('이미 등록된 현장코드입니다.');
            } else if(response.status === 404) {
                alert('등록 가능한 현장코드입니다.');
                setDuplicateChk(true);
            }
        });
    }

    const openAddressPopUpHandler = () => {
        setIsDaumPost(true);
    }

    const closeAddressPopUpHandler = (e) => {
        setIsDaumPost(Boolean(e));
    }

    const addressData = (data) => {
        setForm({
            ...form,
            postalCode: data.zonecode,
            address: data.address,
        })
    }

    const getImgFile = (dataUrl) => {
        setForm({
            ...form,
            imageDataUrl: dataUrl,
        });
    }

    const onSubmitHandler = () => {
        if(form.code === '') {
            alert('현장코드는 필수값입니다.');
            document.getElementsByName('code')[0].focus();
            return false;
        }
        if(form.name === '') {
            alert('현장명은 필수값입니다.');
            document.getElementsByName('name')[0].focus();
            return false;
        }
        if(form.customer.code === '') {
            alert('고객사명은 필수값입니다.');
            document.getElementsByName('customer.code')[0].focus();
            return false;
        }
        if(form.postalCode === '' || form.address === '') {
            alert('주소는 필수값입니다.');
            document.getElementsByName('postalCode')[0].focus();
            return false;
        }
        if(form.detailAddress === '') {
            alert('주소상세는 필수값입니다.');
            document.getElementsByName('detailAddress')[0].focus();
            return false;
        }
        if(form.region.code === '') {
            alert('지역코드는 필수값입니다.');
            document.getElementsByName('region.regionCode')[0].focus();
            return false;
        }
        if(!duplicateChk) {
            alert('현장코드 중복체크를 해주세요.');
            return false;
        }

        var result = window.confirm('저장하시겠습니까?');
        if(result) {
            if(registPage) {
                Net.addSite(form, (response) => {
                    if(response.status === 201) {
                        history.push('/location/site/list');
                    }
                });
            } else {
                Net.updateSite(form, (response) => {
                    if(response.status === 200) {
                        history.push('/location/site/list');
                    }
                });
            }
        }
    }

    const onCancelHandler = () => {
        history.push('/location/site/list');
    }

    const onRemoveHandler = () => {
        const result = window.confirm('삭제하시겠습니까?');
        if(result) {
            Net.removeSitesList([form.code], (response) => {
                history.push('/location/site/list');
            });
        }
    }
    const radioBtnHandler = (name, value) => {
        let nextForm;
        if(name === "authGroup"){
            nextForm = {
                ...form,
                [name]: value,
            }
        } else {
            nextForm = {
                ...form,
                [name]: value === 'true',
            }
        }

        setForm(nextForm);
    }

    const onClickModalOpen = () => {
        setModalState({
            ...modalState,
            open: !modalState.open,
        });
    }

    const sendData = (e) => {
        setModalState({
            ...modalState,
            data: e,
        })
    }

    const rcvData = (e) => {
        setForm({
            ...form,
            deviceModelCodes: e.map(data => data.code)
        });
    }

    return (
        <div className="w-100 h-100">
            {
                isDaumPost ?
                    <SearchAddress className="w-40" isDaumPost={isDaumPost} closePopUp={closeAddressPopUpHandler} rtnData={addressData}/>
                    : null
            }
            <BasicModal
                className="w-70 h-67"
                title="모델코드"
                content={<ModalContent sendData={sendData} formData={form} />}
                modalOpen={modalState.open}
                modalClose={(e) => setModalState({
                    ...modalState,
                    open: e
                })}
                sendData={modalState.data}
                rcvData={rcvData}
                activeCloseBtn={true}
            />
            <div className="w-100 h-7 display_table"/>
            <div className="w-100 h-1px bgC-efefef" />
            <div className="h-93">
                <div className="flex h-90">
                    <div className="w-60 h-100">
                        <div className="ml-55 mt-12">
                            <div className="flex mb-12 w-90">
                                <CustomTextField
                                    className="w-70 h-26px"
                                    title="현장코드"
                                    name="code"
                                    required={true}
                                    validRegExp={/[ㄱ-ㅎ|ㅏ-ㅣ|가-힣|\{\}\[\]\/?.,;:|\)*~`!^+<>@\#$%&\\\=\(\'\"]/g}
                                    placeholder="코드는 영문 및 숫자 조합이며, 단어와 단어 사이는 언더바 및 하이픈(-) 사용"
                                    customOnChange={textFieldHandler}
                                    value={form}
                                    disabled={match.params.code !== undefined}
                                />
                                <div className="mt-auto mb-auto">
                                    <button
                                        className="w-78px h-22px duplicateBtn"
                                        onClick={onDuplicateHandler}
                                    >중복체크</button>
                                </div>
                            </div>
                            <div className="flex mb-12 w-90">
                                <CustomTextField
                                    className="w-30 h-26px"
                                    title="현장명"
                                    name="name"
                                    required={true}
                                    disabled={false}
                                    validLen="30"
                                    validRegExp={/[\{\}\[\]\/?.,;:|\)*~`!^\-_+<>@\#$%&\\\=\(\'\"]/gi}
                                    placeholder="현장명은 특수문자 제외 최대 30자"
                                    customOnChange={textFieldHandler}
                                    value={form}
                                />
                                <div className="w-10"/>
                                <CustomTextField
                                    className="w-30 h-26px"
                                    title="현장 영문명"
                                    name="englishName"
                                    required={false}
                                    disabled={false}
                                    customOnChange={textFieldHandler}
                                    value={form}
                                />
                            </div>
                            <div className="flex mb-12 w-90">
                                <label className="label_title">고객사명 *</label>
                                <CustomSelectBox
                                    name="customer.code"
                                    categoryList={customerList}
                                    onChangeHandler={(value, name) => selectBoxHandler('customerCode', {code: value, name: name})}
                                    callbackCodeName="Y"
                                    value={form.customer.code}
                                    disabled={!registPage}
                                />
                                { registPage ? (<></>) :<p className="label_summary ml-10">* 수정시 고객사를 변경할 수 없습니다. 현장 삭제 후 재생성 바랍니다</p>}
                            </div>
                            <div className="flex mb-12 w-90">
                                <CustomTextField
                                    className="w-15 h-26px"
                                    title="주소"
                                    name="postalCode"
                                    required={true}
                                    disabled={false}
                                    customOnChange={textFieldHandler}
                                    value={form}
                                />
                                <CustomTextField
                                    className="w-55 h-26px ml-10"
                                    name="address"
                                    required={false}
                                    disabled={true}
                                    customOnChange={textFieldHandler}
                                    value={form}
                                />
                                <div className="mt-auto mb-auto">
                                    <button
                                        className="w-78px h-22px duplicateBtn"
                                        onClick={openAddressPopUpHandler}
                                    >주소검색</button>
                                </div>
                            </div>
                            <div className="flex mb-12 w-90">
                                <CustomTextField
                                    className="w-70 h-26px"
                                    title="주소상세"
                                    name="detailAddress"
                                    required={true}
                                    disabled={false}
                                    customOnChange={textFieldHandler}
                                    value={form}
                                />
                            </div>
                            <div className="flex mb-12 w-90">
                                <label className="label_title">지역코드 *</label>
                                <CustomSelectBox
                                    name="region.code"
                                    categoryList={regionList}
                                    onChangeHandler={(value, name) => selectBoxHandler('regionCode', {code: value, name: name})}
                                    callbackCodeName="Y"
                                    value={form.region.code}
                                />
                            </div>
                            <div className="flex mb-12 w-90">
                                <CustomTextField
                                    className="w-30 h-26px"
                                    title="준공년월"
                                    name="builtDate"
                                    required={false}
                                    disabled={false}
                                    customOnChange={textFieldHandler}
                                    value={form}
                                />
                                <div className="w-10"/>
                                <CustomTextField
                                    className="w-30 h-26px"
                                    type="number"
                                    title="세대수"
                                    name="numberOfHouseHold"
                                    required={false}
                                    disabled={false}
                                    customOnChange={textFieldHandler}
                                    value={form}
                                />
                            </div>
                            <div className="flex w-90 mb-2">
                                <label className="label_title">사용유무</label>
                                <CustomRadioButton
                                    className="mr-70"
                                    title="사용"
                                    id="radioBtn1"
                                    name="usable"
                                    value={true}
                                    checked={form.usable}
                                    customOnChange={radioBtnHandler}
                                />
                                <CustomRadioButton
                                    title="사용안함"
                                    id="radioBtn2"
                                    name="usable"
                                    value={false}
                                    checked={!form.usable}
                                    customOnChange={radioBtnHandler}
                                />
                            </div>

                            <div className="flex w-90 mb-3">
                                <label className="label_title"> 원패스 사용유무</label>
                                <CustomRadioButton
                                    className="mr-70"
                                    title="사용"
                                    id="radioBtn3"
                                    name="onepassUsable"
                                    value={true}
                                    checked={form.onepassUsable}
                                    customOnChange={radioBtnHandler}
                                />
                                <CustomRadioButton
                                    title="사용안함"
                                    id="radioBtn4"
                                    name="onepassUsable"
                                    value={false}
                                    checked={!form.onepassUsable}
                                    customOnChange={radioBtnHandler}
                                />
                            </div>

                            <div className="flex w-90 mb-3">
                                <label className="label_title">EMS 사용유무</label>
                                <CustomRadioButton
                                    className="mr-70"
                                    title="사용"
                                    id="radioBtn5"
                                    name="emsRemoteReadingUsable"
                                    value={true}
                                    checked={form.emsRemoteReadingUsable}
                                    customOnChange={radioBtnHandler}
                                />
                                <CustomRadioButton
                                    title="사용안함"
                                    id="radioBtn6"
                                    name="emsRemoteReadingUsable"
                                    value={false}
                                    checked={!form.emsRemoteReadingUsable}
                                    customOnChange={radioBtnHandler}
                                />
                            </div>

                            <div className="flex w-90 mb-3">
                                <label className="label_title">방범모드 패스워드 유무</label>
                                <CustomRadioButton
                                    className="mr-70"
                                    title="사용"
                                    id="radioBtn7"
                                    name="homenetModePasswordUsable"
                                    value={true}
                                    checked={form.homenetModePasswordUsable}
                                    customOnChange={radioBtnHandler}
                                />
                                <CustomRadioButton
                                    title="사용안함"
                                    id="radioBtn8"
                                    name="homenetModePasswordUsable"
                                    value={false}
                                    checked={!form.homenetModePasswordUsable}
                                    customOnChange={radioBtnHandler}
                                />
                            </div>

                            <div className="row">
                                <div className="col-6">
                                    <p className="label_title">비고</p>
                                    <div className="w-100 m-1 ">
                                        <TextareaAutosize
                                            name="etc"
                                            className="board_content form-control col-12 h-25"
                                            onChange={(event)=>{
                                                setForm({...form,
                                                    etc: event.target.value});
                                            }}
                                            value={form.etc}
                                            minRows={1}
                                            type="text"
                                        />
                                    </div>
                                </div>
                                <div className="col-6">
                                    <p className="label_title">임시 사용자 Prefix</p>
                                    <div className="flex mb-10 mt-1 w-150">
                                        <CustomTextField
                                            className="w-50 h-26px"
                                            name="tempUserPrefix"
                                            required={false}
                                            disabled={false}
                                            customOnChange={textFieldHandler}
                                            value={form}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="w-40 h-100">
                        <div className="w-100 h-50 mt-12">
                            <div className="floatC mr-55">
                                <ImgUpload
                                    title={"사진"}
                                    imgUpload={getImgFile}
                                    imgFile={form.imageDataUrl}
                                    imgSize={"180"}
                                />
                            </div>
                        </div>
                        <div className="w-100 h-40 mt-12">
                            <div className="flex mb-12 w-90">
                                <CustomTextField
                                    className="w-30 h-26px"
                                    title="CCTV 제조사"
                                    name="cctvManufacturer"
                                    required={false}
                                    disabled={false}
                                    customOnChange={textFieldHandler}
                                    value={form}
                                />
                                <div className="w-10"/>
                                <CustomTextField
                                    className="w-30 h-26px"
                                    title="CCTV URL"
                                    name="cctvUrl"
                                    required={false}
                                    disabled={false}
                                    customOnChange={textFieldHandler}
                                    value={form}
                                />
                            </div>
                            <div className="flex mb-12 w-80">
                                <CustomTextField
                                    className="w-30 h-26px"
                                    title="원패스 제조사"
                                    name="onepassManufacturer"
                                    required={false}
                                    disabled={false}
                                    customOnChange={textFieldHandler}
                                    value={form}
                                />
                                <div className="w-40"/>

                            </div>
                            <div className="flex mb-12 w-90">
                                <CustomTextField
                                    className="w-30 h-26px"
                                    title="AOS 원패스 URL"
                                    name="onepassAndroidUrl"
                                    required={false}
                                    disabled={false}
                                    customOnChange={textFieldHandler}
                                    value={form}
                                />
                                <div className="w-10"/>

                                <CustomTextField
                                    className="w-30 h-26px"
                                    title="IOS 원패스 URL"
                                    name="onepassIosUrl"
                                    required={false}
                                    disabled={false}
                                    customOnChange={textFieldHandler}
                                    value={form}
                                />
                            </div>
                            <div className="flex mb-12 w-90">
                                <CustomTextField
                                    className="w-30 h-26px"
                                    title="전자책 회사명"
                                    name="ebookCompanyName"
                                    required={false}
                                    disabled={false}
                                    customOnChange={textFieldHandler}
                                    value={form}
                                />
                                <div className="w-10"/>
                                <CustomTextField
                                    className="w-30 h-26px"
                                    title="전자책 URL"
                                    name="ebookUrl"
                                    required={false}
                                    disabled={false}
                                    customOnChange={textFieldHandler}
                                    value={form}
                                />
                            </div>
                            <div className="flex mb-12 w-90">
                                <CustomTextField
                                    className="w-30 h-26px"
                                    title="주차관제회사명"
                                    name="parkingControlCompanyName"
                                    required={false}
                                    disabled={false}
                                    customOnChange={textFieldHandler}
                                    value={form}
                                />
                                <div className="w-10"/>
                                <CustomTextField
                                    className="w-30 h-26px"
                                    title="주차관제 URL"
                                    name="parkingControlUrl"
                                    required={false}
                                    disabled={false}
                                    customOnChange={textFieldHandler}
                                    value={form}
                                />
                            </div>
                            <div className="flex mb-12 w-90">
                                <CustomTextField
                                    className="w-30 h-26px"
                                    title="주차위치회사명"
                                    name="parkingPositionCompanyName"
                                    required={false}
                                    disabled={false}
                                    customOnChange={textFieldHandler}
                                    value={form}
                                />
                                <div className="w-10"/>
                                <CustomTextField
                                    className="w-30 h-26px"
                                    title="주차위치 URL"
                                    name="parkingPositionUrl"
                                    required={false}
                                    disabled={false}
                                    customOnChange={textFieldHandler}
                                    value={form}
                                />
                            </div>

                        </div>
                    </div>
                </div>
                <div className="footer_btn h-10">
                    {
                        user.menuGroup === "OPERATOR" ? '' : <>
                            <button className="w-160px h-30px addBtn mr-10" onClick={onSubmitHandler}>저장</button>
                             { registPage ? "" : <button className="w-160px h-30px removeBtn mr-10" onClick={onRemoveHandler}>삭제</button>}
                        </>
                    }
                    <button className="w-160px h-30px cancelBtn" onClick={onCancelHandler}>취소</button>
                </div>
            </div>
        </div>
    )
}

export default withRouter(SiteRegist);

const modalColumnList = [
    {
        code: 'modelId',
        title: '',
        width: '',
        hidden: true,
    },
    {
        code: 'code',
        title: '모델코드',
        width: '38',
    },
    {
        code: 'name',
        title: '모델명',
        width: '20',
    },
    {
        code: 'type.code',
        title: '장비타입',
        width: '20',
    },
    {
        code: 'manufacturer.code',
        title: '제조사',
        width: '20',
    },
]

export const ModalContent = (props) => {

    const [firstCheckedData, setFirstCheckedData] = useState([]);
    const [modelList, setModelList] = useState([]);
    const [modelCheckedItems, setModelCheckedItems] = useState([]);

    useEffect(() => {
        Net.getToolsModelList(
            {
                currentPage: '',
                rowPerPage: '',
                searchText: '',
                manufacturer: {
                    code: '',
                },
                type: {
                    code: '',
                },
            }, (response) => {
            if(response.status === 200) {
                const deviceModelCodeList = props.formData.deviceModelCodes && props.formData.deviceModelCodes.map(code => {
                    return code || [];
                });

                const rowIdxList = [];
                response.data.content.map((data, index) => {
                    const code = data.code;
                    if(deviceModelCodeList.indexOf(code) !== -1) {
                        rowIdxList.push('row_'+index);
                    }
                });
                setModelList(response.data.content);
                setFirstCheckedData(rowIdxList);
            }
        });
    }, [])

    useEffect(() => {
        if(modelCheckedItems.length > 0) {
            const sendData = [];

            modelCheckedItems.map((item, i) => {
                const node = document.querySelector(`#${modelCheckedItems[i]}`);
                const nodeTr = node.closest('tr');
                const modelIdValue = nodeTr.querySelector('td[name=modelId]').textContent;
                modelList.map(data => {
                    if(data.modelId === modelIdValue) {
                        sendData.push(data);
                    }
                });
            });

            props.sendData(sendData);
        }
    }, [modelCheckedItems])

    return (
        <CustomTable
            className="w-100 h-100 overflowYscroll"
            columnList = {modalColumnList}
            contentData = {modelList}
            checkbox = {true}
            checkedList = { (checkedList) => {setModelCheckedItems(checkedList)} }
            firstCheckedData = {firstCheckedData}
            rowClickEvt={false}
            paging = {false}
        />
    )
}
