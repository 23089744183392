import React, { useEffect, useState } from 'react';
import { CustomTextField, CustomRadioButton,CustomCheckbox, QuillEditor, CustomSelectBox, ImgUpload, SearchAddress, BasicModal, CustomTable} from 'components/molecules';
import { useHistory, withRouter } from 'react-router-dom';
import store from 'scripts/store';
import Net from 'scripts/net';
import {toJS} from "mobx";
import Common from 'scripts/common';

const SiteServiceRegist = ({match}) => {

    const history = useHistory();
    const user = toJS(store.user)

    const [form, setForm] = useState({
        code: '',
        site : {
            code: '',
            name: '',
        },
        customer: {
            code: '',
            name: '',
        },
        gas : false,
        valve : false,
        electricCooktop : false,
        hybrid : false,
        light : false,
        lightNormal : false,
        lightDimming : false,
        lightColor : false,
        standbyPower : false,
        allLightsOut : false,
        heating : false,
        systemAirConditioner : false,
        wallPadLinkage : false,
        wifiLinkage : false,
        ventilation : false,
        interlockingIndoorAirQualitySensorLinkage : false,
        interlockingIndoorAirQualitySensor : false,
        ventilationLinkage : false,
        electricCurtain : false,
        elevatorCall : false,
        householdVisitors : false,
        commonEntrance : false,
        blackbox : false,
        crimePreventionMode : false,
        emergency : false,
        wallpadEmergency : false,
        emergencyLadder : false,
        fire : false,
        restroom : false,
        crimePreventionEmergency : false,
        householdRegistrationEntryAndExit : false,
        householdVehicleParkingLocation : false,
        visitingVehicleRegistration : false,
        visitingVehicleEntryAndExit : false,
        visitingVehicleParkingLocation : false,
        unmannedDelivery : false,
        deliveryByCourier : false,
        householdDelivery : false,
        ems : false,
        emsRemoteMeterReading : false,
        emsTarget : false,
        emsElectricity : false,
        emsGas : false,
        emsWater : false,
        emsHeating : false,
        emsHotWater : false,

    });
    const [registPage, setRegistPage] = useState(true);
    const [duplicateChk, setDuplicateChk] = useState(false);

    useEffect(() => {
        store.openedPage = '현장 서비스 등록/수정';

        // 상세 조회
        if(match.params.code !== undefined) {
            Net.getSiteMngitemOne(match.params.code, (response) => {
                if(response.status === 200) {
                    setForm({
                        ...form,
                        ...response.data,
                    });
                    setDuplicateChk(true);
                    // 등록페이지 여부 확인
                    setRegistPage(false);
                }
            });
        }
    }, []);

    useEffect(()=>{
        let newForm = {
            ...form
        }
        if(!form.gas){
            newForm = {
                ...form,
                valve : false,
                electricCooktop: false,
                hybrid: false
            }
        }
        setForm(newForm);
    },[form.gas]);

    useEffect(()=>{
        let newForm = {
            ...form
        }
        if(!form.light){
            newForm = {
                ...form,
                lightNormal: false,
                lightDimming: false,
                lightColor: false
            }
        }
        setForm(newForm);
    },[form.light]);

    useEffect(()=>{
        let newForm = {
            ...form
        }
        if(!form.systemAirConditioner){
            newForm = {
                ...form,
                wallPadLinkage: false,
                wifiLinkage: false,
            }
        }
        setForm(newForm);
    },[form.systemAirConditioner]);

    useEffect(()=>{
        let newForm = {
            ...form
        }
        if(!form.ventilation){
            newForm = {
                ...form,
                interlockingIndoorAirQualitySensorLinkage: false,
            }
        }
        setForm(newForm);
    },[form.ventilation]);

    useEffect(()=>{
        let newForm = {
            ...form
        }
        if(!form.interlockingIndoorAirQualitySensor){
            newForm = {
                ...form,
                ventilationLinkage: false,
            }
        }
        setForm(newForm);
    },[form.interlockingIndoorAirQualitySensor]);

    useEffect(()=>{
        let newForm = {
            ...form
        }
        if(!form.householdVisitors){
            newForm = {
                ...form,
                commonEntrance: false,
                blackbox : false,
            }
        }
        setForm(newForm);
    },[form.householdVisitors]);

    useEffect(()=>{
        let newForm = {
            ...form
        }
        if(!form.emergency){
            newForm = {
                ...form,
                wallpadEmergency: false,
                emergencyLadder : false,
                fire : false,
                restroom : false,
                crimePreventionEmergency : false,
            }
        }
        setForm(newForm);
    },[form.emergency]);

    useEffect(()=>{
        let newForm = {
            ...form
        }
        if(!form.unmannedDelivery){
            newForm = {
                ...form,
                deliveryByCourier: false,
                householdDelivery : false,
            }
        }
        setForm(newForm);
    },[form.unmannedDelivery]);

    useEffect(()=>{
        let newForm = {
            ...form
        }
        if(!form.ems){
            newForm = {
                ...form,
                emsRemoteMeterReading: false,
                emsTarget : false,
                emsElectricity : false,
                emsGas : false,
                emsWater : false,
                emsHeating : false,
                emsHotWater : false
            }
        }
        setForm(newForm);
    },[form.ems]);    useEffect(()=>{
        let newForm = {
            ...form
        }
        if(!form.ems){
            newForm = {
                ...form,
                emsRemoteMeterReading: false,
                emsTarget : false,
                emsElectricity : false,
                emsGas : false,
                emsWater : false,
                emsHeating : false,
                emsHotWater : false
            }
        }
        setForm(newForm);
    },[form.ems]);


    const textFieldHandler = (name, value) => {
        // 현장코드 중복체크 후 수정 시 중복체크 재확인 요망을 위한 값 셋팅
        const nextForm = {
            ...form,
            [name]: value
        }
        setForm(nextForm);
    }

    const siteFieldHandler = (name, value) => {
        if(name === 'site.code') setDuplicateChk(false);
        const nextForm = {
            ...form,
            site :{
                code: value,
                name: '',
            }
        }
        setForm(nextForm)
    }

    const handleCheckbox = (id, checked) => {
        setForm({
            ...form,
            [id]: checked
        });
    }

    const onDuplicateHandler = () => {
        if(form.site.code === '') {
            alert('현장코드를 입력해주세요.');
            return false;
        }
        Net.chkDuplicateSite(form.site.code, (response) => {
            if(response.status === 200) {
                Net.getDuplicateMngitem(form.site.code, (response1) => {
                    if(response1.status === 200) {
                        alert('이미 현장별 서비스가 등록된 현장코드입니다.');
                    } else if(response1.status === 204) {
                        alert('등록 가능한 현장코드입니다.');
                        const nextForm = {
                            ...form,
                            site : {
                                code : response.data.code,
                                name : response.data.name
                            }
                        }
                        setForm(nextForm)
                        setDuplicateChk(true);
                    }
                });
            } else if(response.status === 404) {
                alert('등록 불가능한 현장코드입니다. 현장 코드를 확인해주세요');
            }
        });
    }

    const onSubmitHandler = () => {
        if(form.site.code === '') {
            alert('현장코드는 필수값입니다.');
            document.getElementsByName('code')[0].focus();
            return false;
        }
        if(!duplicateChk) {
            alert('현장코드 중복체크를 해주세요.');
            return false;
        }

        let result = window.confirm('저장하시겠습니까?');
        if(result) {
            if(registPage) {
                Net.addSiteMngitem(form, (response) => {
                    if(response.status === 201) {
                        history.push('/location/siteService/list');
                    }
                });
            } else {
                Net.updateSiteMngitem(form, (response) => {
                    if(response.status === 200) {
                        history.push('/location/siteService/list');
                    }
                });
            }
        }
    }

    const onCancelHandler = () => {
        history.push('/location/siteService/list');
    }

    const onRemoveHandler = () => {
        const result = window.confirm('삭제하시겠습니까?');
        if(result) {
            Net.removeSitesMngitem([form.code], (response) => {
                history.push('/location/siteService/list');
            });
        }
    }

    return (
        <div className="w-100 h-100">
            <div className="w-100 h-7 display_table"/>
            <div className="w-100 h-1px bgC-efefef" />
            <div className="h-93">
                <div className="flex h-90">
                    <div className="w-60 h-100">
                        <div className="ml-55 mt-12">
                            <div className="flex mb-12 w-90">
                                <CustomTextField
                                    className="w-70 h-26px"
                                    title="현장코드"
                                    name="site.code"
                                    validRegExp={/[ㄱ-ㅎ|ㅏ-ㅣ|가-힣|\{\}\[\]\/?.,;:|\)*~`!^+<>@\#$%&\\\=\(\'\"]/g}
                                    required={true}
                                    placeholder="현장 코드를 입력해주세요."
                                    customOnChange={siteFieldHandler}
                                    value={form}
                                    disabled={match.params.code}
                                />
                                <div className="mt-auto mb-auto">
                                    <button
                                        className="w-78px h-22px duplicateBtn"
                                        onClick={onDuplicateHandler}
                                    >코드 체크</button>
                                </div>
                            </div>
                            <div className="flex mb-12 w-90">
                                <CustomTextField
                                    className="w-30 h-26px"
                                    title="현장 명"
                                    name="site.name"
                                    disabled={true}
                                    customOnChange={textFieldHandler}
                                    value={form}
                                />
                            </div>

                            <div>
                                <h4 className="mb-10">공용부 서비스 </h4>
                                <div className="ml-20">
                                    <div className="flex w-90 mb-3">
                                        <label className="label_title">세대 등록 입출자</label>
                                        <CustomCheckbox
                                            id={`householdRegistrationEntryAndExit`}
                                            className="j-c-c"
                                            titleClassName="mt-1"
                                            title="적용"
                                            checked={form.householdRegistrationEntryAndExit}
                                            onChange={(id, checked) => handleCheckbox(id, checked, 'attributes')}
                                        />
                                    </div>
                                    <div className="flex w-90 mb-3">
                                        <label className="label_title">세대차량 주차위치</label>
                                        <CustomCheckbox
                                            id={`householdVehicleParkingLocation`}
                                            className="j-c-c"
                                            titleClassName="mt-1"
                                            title="적용"
                                            checked={form.householdVehicleParkingLocation}
                                            onChange={(id, checked) => handleCheckbox(id, checked, 'attributes')}
                                        />
                                    </div>
                                    <div className="flex w-90 mb-3">
                                        <label className="label_title">방문차량 등록</label>
                                        <CustomCheckbox
                                            id={`visitingVehicleRegistration`}
                                            className="j-c-c"
                                            titleClassName="mt-1"
                                            title="적용"
                                            checked={form.visitingVehicleRegistration}
                                            onChange={(id, checked) => handleCheckbox(id, checked, 'attributes')}
                                        />
                                    </div>
                                    <div className="flex w-90 mb-3">
                                        <label className="label_title">방문차량 입출차</label>
                                        <CustomCheckbox
                                            id={`visitingVehicleEntryAndExit`}
                                            className="j-c-c"
                                            titleClassName="mt-1"
                                            title="적용"
                                            checked={form.visitingVehicleEntryAndExit}
                                            onChange={(id, checked) => handleCheckbox(id, checked, 'attributes')}
                                        />
                                    </div>
                                    <div className="flex w-90 mb-3">
                                        <label className="label_title">방문차량 주차위치</label>
                                        <CustomCheckbox
                                            id={`visitingVehicleParkingLocation`}
                                            className="j-c-c"
                                            titleClassName="mt-1"
                                            title="적용"
                                            checked={form.visitingVehicleParkingLocation}
                                            onChange={(id, checked) => handleCheckbox(id, checked, 'attributes')}
                                        />
                                    </div>
                                    <div className="flex w-90 mb-3">
                                        <label className="label_title">무인택배</label>
                                        <CustomCheckbox
                                            id={`unmannedDelivery`}
                                            className="j-c-c"
                                            titleClassName="mt-1"
                                            title="적용"
                                            checked={form.unmannedDelivery}
                                            onChange={(id, checked) => handleCheckbox(id, checked, 'attributes')}
                                        />
                                        <CustomCheckbox
                                            id={`deliveryByCourier`}
                                            className="ml-60"
                                            titleClassName="mt-1"
                                            title="택배사발송"
                                            checked={form.deliveryByCourier}
                                            onChange={(id, checked) => handleCheckbox(id, checked, 'attributes')}
                                            disabled={!form.unmannedDelivery}
                                        />
                                        <CustomCheckbox
                                            id={`householdDelivery`}
                                            className="ml-30"
                                            titleClassName="mt-1"
                                            title="세대발송"
                                            checked={form.householdDelivery}
                                            onChange={(id, checked) => handleCheckbox(id, checked, 'attributes')}
                                            disabled={!form.unmannedDelivery}
                                        />
                                    </div>
                                    <div className="flex w-90 mb-3">
                                        <label className="label_title">EMS</label>
                                        <CustomCheckbox
                                            id={`ems`}
                                            className="j-c-c"
                                            titleClassName="mt-1"
                                            title="적용"
                                            checked={form.ems}
                                            onChange={(id, checked) => handleCheckbox(id, checked, 'attributes')}
                                        />
                                        <CustomCheckbox
                                            id={`emsRemoteMeterReading`}
                                            className="ml-60"
                                            titleClassName="mt-1"
                                            title="원격 검침"
                                            checked={form.emsRemoteMeterReading}
                                            onChange={(id, checked) => handleCheckbox(id, checked, 'attributes')}
                                            disabled={!form.ems}
                                        />
                                        <CustomCheckbox
                                            id={`emsTarget`}
                                            className="ml-60"
                                            titleClassName="mt-1"
                                            title="목표치"
                                            checked={form.emsTarget}
                                            onChange={(id, checked) => handleCheckbox(id, checked, 'attributes')}
                                            disabled={!form.ems}
                                        />
                                    </div>
                                    <div className="flex w-90 mb-3">
                                        <CustomCheckbox
                                            id={`emsElectricity`}
                                            className="ml-90"
                                            titleClassName=""
                                            title="전기"
                                            checked={form.emsElectricity}
                                            onChange={(id, checked) => handleCheckbox(id, checked, 'attributes')}
                                            disabled={!form.ems}
                                        />
                                        <CustomCheckbox
                                            id={`emsGas`}
                                            className="ml-60"
                                            titleClassName=""
                                            title="가스"
                                            checked={form.emsGas}
                                            onChange={(id, checked) => handleCheckbox(id, checked, 'attributes')}
                                            disabled={!form.ems}
                                        />
                                        <CustomCheckbox
                                            id={`emsWater`}
                                            className="ml-60"
                                            titleClassName=""
                                            title="수도"
                                            checked={form.emsWater}
                                            onChange={(id, checked) => handleCheckbox(id, checked, 'attributes')}
                                            disabled={!form.ems}
                                        />
                                        <CustomCheckbox
                                            id={`emsHeating`}
                                            className="ml-60"
                                            titleClassName=""
                                            title="난방"
                                            checked={form.emsHeating}
                                            onChange={(id, checked) => handleCheckbox(id, checked, 'attributes')}
                                            disabled={!form.ems}
                                        />
                                        <CustomCheckbox
                                            id={`emsHotWater`}
                                            className="ml-60"
                                            titleClassName=""
                                            title="온수"
                                            checked={form.emsHotWater}
                                            onChange={(id, checked) => handleCheckbox(id, checked, 'attributes')}
                                            disabled={!form.ems}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="w-40 h-100">
                        <div className="w-100 h-100 mt-12">
                                <h4 className="mb-10">세대 서비스 </h4>
                                <div className="ml-20">
                                    <div className="flex w-90 mb-2">
                                        <label className="label_title">가스</label>
                                        <CustomCheckbox
                                            id={`gas`}
                                            className="j-c-c"
                                            titleClassName="mt-1"
                                            title="적용"
                                            checked={form.gas}
                                            onChange={(id, checked) => handleCheckbox(id, checked, 'attributes')}
                                        />
                                        <CustomCheckbox
                                            id={`valve`}
                                            className="ml-25 "
                                            titleClassName="mt-1"
                                            title="밸브"
                                            checked={form.valve}
                                            onChange={(id, checked) => handleCheckbox(id, checked, 'attributes')}
                                            disabled={!form.gas}
                                        />
                                        <CustomCheckbox
                                            id={`electricCooktop`}
                                            className="ml-10"
                                            titleClassName="mt-1"
                                            title="전기 쿡탑"
                                            checked={form.electricCooktop}
                                            onChange={(id, checked) => handleCheckbox(id, checked, 'attributes')}
                                            disabled={!form.gas}
                                        />
                                        <CustomCheckbox
                                            id={`hybrid`}
                                            className="ml-10"
                                            titleClassName="mt-1"
                                            title="하이브리드"
                                            checked={form.hybrid}
                                            onChange={(id, checked) => handleCheckbox(id, checked, 'attributes')}
                                            disabled={!form.gas}
                                        />
                                    </div>
                                    <div className="flex w-90 mb-2">
                                        <label className="label_title">조명</label>
                                        <CustomCheckbox
                                            id={`light`}
                                            className="j-c-c"
                                            titleClassName="mt-1"
                                            title="적용"
                                            checked={form.light}
                                            onChange={(id, checked) => handleCheckbox(id, checked, 'attributes')}
                                        />
                                        <CustomCheckbox
                                            id={`lightNormal`}
                                            className="ml-60"
                                            titleClassName="mt-1"
                                            title="일반"
                                            checked={form.lightNormal}
                                            onChange={(id, checked) => handleCheckbox(id, checked, 'attributes')}
                                            disabled={!form.light}
                                        />
                                        <CustomCheckbox
                                            id={`lightDimming`}
                                            className="ml-30"
                                            titleClassName="mt-1"
                                            title="디밍"
                                            checked={form.lightDimming}
                                            onChange={(id, checked) => handleCheckbox(id, checked, 'attributes')}
                                            disabled={!form.light}
                                        />
                                        <CustomCheckbox
                                            id={`lightColor`}
                                            className="ml-30"
                                            titleClassName="mt-1"
                                            title="칼라"
                                            checked={form.lightColor}
                                            onChange={(id, checked) => handleCheckbox(id, checked, 'attributes')}
                                            disabled={!form.light}
                                        />
                                    </div>
                                    <div className="flex w-90 mb-2">
                                        <label className="label_title">대기전력</label>
                                        <CustomCheckbox
                                            id={`standbyPower`}
                                            className="j-c-c"
                                            titleClassName="mt-1"
                                            title="적용"
                                            checked={form.standbyPower}
                                            onChange={(id, checked) => handleCheckbox(id, checked, 'attributes')}
                                        />
                                    </div>
                                    <div className="flex w-90 mb-2">
                                        <label className="label_title">일괄소등</label>
                                        <CustomCheckbox
                                            id={`allLightsOut`}
                                            className="j-c-c"
                                            titleClassName="mt-1"
                                            title="적용"
                                            checked={form.allLightsOut}
                                            onChange={(id, checked) => handleCheckbox(id, checked, 'attributes')}
                                        />
                                    </div>
                                    <div className="flex w-90 mb-2">
                                        <label className="label_title">난방</label>
                                        <CustomCheckbox
                                            id={`heating`}
                                            className="j-c-c"
                                            titleClassName="mt-1"
                                            title="적용"
                                            checked={form.heating}
                                            onChange={(id, checked) => handleCheckbox(id, checked, 'attributes')}
                                        />
                                    </div>
                                    <div className="flex w-90 mb-2">
                                        <label className="label_title">시스템 에어컨</label>
                                        <CustomCheckbox
                                            id={`systemAirConditioner`}
                                            className="j-c-c"
                                            titleClassName="mt-1"
                                            title="적용"
                                            checked={form.systemAirConditioner}
                                            onChange={(id, checked) => handleCheckbox(id, checked, 'attributes')}
                                        />
                                        <CustomCheckbox
                                            id={`wifiLinkage`}
                                            className="ml-60"
                                            titleClassName="mt-1"
                                            title="WIFI"
                                            checked={form.wifiLinkage}
                                            onChange={(id, checked) => handleCheckbox(id, checked, 'attributes')}
                                            disabled={!form.systemAirConditioner}
                                        />
                                        <CustomCheckbox
                                            id={`wallPadLinkage`}
                                            className="ml-30"
                                            titleClassName="mt-1"
                                            title="월패드 연동"
                                            checked={form.wallPadLinkage}
                                            onChange={(id, checked) => handleCheckbox(id, checked, 'attributes')}
                                            disabled={!form.systemAirConditioner}
                                        />
                                    </div>
                                    <div className="flex w-90 mb-2">
                                        <label className="label_title">환기</label>
                                        <CustomCheckbox
                                            id={`ventilation`}
                                            className="j-c-c"
                                            titleClassName="mt-1"
                                            title="적용"
                                            checked={form.ventilation}
                                            onChange={(id, checked) => handleCheckbox(id, checked, 'attributes')}
                                        />
                                        <CustomCheckbox
                                            id={`interlockingIndoorAirQualitySensor`}
                                            className="ml-60"
                                            titleClassName="mt-1"
                                            title="실내 공기질센서 연동"
                                            checked={form.interlockingIndoorAirQualitySensor}
                                            onChange={(id, checked) => handleCheckbox(id, checked, 'attributes')}
                                            disabled={!form.ventilation}
                                        />
                                    </div>
                                    <div className="flex w-90 mb-2">
                                        <label className="label_title">실내 공기질센서</label>
                                        <CustomCheckbox
                                            id={`indoorAirQualitySensor`}
                                            className="j-c-c"
                                            titleClassName="mt-1"
                                            title="적용"
                                            checked={form.indoorAirQualitySensor}
                                            onChange={(id, checked) => handleCheckbox(id, checked, 'attributes')}
                                        />
                                        <CustomCheckbox
                                            id={`ventilationLinkage`}
                                            className="ml-60"
                                            titleClassName="mt-1"
                                            title="환기 연동"
                                            checked={form.ventilationLinkage}
                                            onChange={(id, checked) => handleCheckbox(id, checked, 'attributes')}
                                            disabled={!form.indoorAirQualitySensor}
                                        />
                                    </div>
                                    <div className="flex w-90 mb-2">
                                        <label className="label_title">전동 커튼</label>
                                        <CustomCheckbox
                                            id={`electricCurtain`}
                                            className="j-c-c"
                                            titleClassName="mt-1"
                                            title="적용"
                                            checked={form.electricCurtain}
                                            onChange={(id, checked) => handleCheckbox(id, checked, 'attributes')}
                                        />
                                    </div>
                                    <div className="flex w-90 mb-2">
                                        <label className="label_title">엘리베이터 콜</label>
                                        <CustomCheckbox
                                            id={`elevatorCall`}
                                            className="j-c-c"
                                            titleClassName="mt-1"
                                            title="적용"
                                            checked={form.elevatorCall}
                                            onChange={(id, checked) => handleCheckbox(id, checked, 'attributes')}
                                        />
                                    </div>
                                    <div className="flex w-90 mb-2">
                                        <label className="label_title">세대 방문자</label>
                                        <CustomCheckbox
                                            id={`householdVisitors`}
                                            className="j-c-c"
                                            titleClassName="mt-1"
                                            title="적용"
                                            checked={form.householdVisitors}
                                            onChange={(id, checked) => handleCheckbox(id, checked, 'attributes')}
                                        />
                                        <CustomCheckbox
                                            id={`commonEntrance`}
                                            className="ml-50"
                                            titleClassName="mt-1"
                                            title="공동현관"
                                            checked={form.commonEntrance}
                                            onChange={(id, checked) => handleCheckbox(id, checked, 'attributes')}
                                            disabled={!form.householdVisitors}
                                        />
                                        <CustomCheckbox
                                            id={`blackbox`}
                                            className="ml-30"
                                            titleClassName="mt-1"
                                            title="세대 블랙박스"
                                            checked={form.blackbox}
                                            onChange={(id, checked) => handleCheckbox(id, checked, 'attributes')}
                                            disabled={!form.householdVisitors}
                                        />
                                    </div>
                                    <div className="flex w-90 mb-2">
                                        <label className="label_title">방범 모드</label>
                                        <CustomCheckbox
                                            id={`crimePreventionMode`}
                                            className="j-c-c"
                                            titleClassName="mt-1"
                                            title="적용"
                                            checked={form.crimePreventionMode}
                                            onChange={(id, checked) => handleCheckbox(id, checked, 'attributes')}
                                        />
                                    </div>
                                    <div className="flex w-90 mb-2">
                                        <label className="label_title">비상</label>
                                        <CustomCheckbox
                                            id={`emergency`}
                                            className="j-c-c"
                                            titleClassName="mt-1"
                                            title="적용"
                                            checked={form.emergency}
                                            onChange={(id, checked) => handleCheckbox(id, checked, 'attributes')}
                                        />
                                        <CustomCheckbox
                                            id={`wallpadEmergency`}
                                            className="ml-50"
                                            titleClassName="mt-1"
                                            title="월패드 비상"
                                            checked={form.wallpadEmergency}
                                            onChange={(id, checked) => handleCheckbox(id, checked, 'attributes')}
                                            disabled={!form.emergency}
                                        />
                                        <CustomCheckbox
                                            id={`emergencyLadder`}
                                            className="ml-30"
                                            titleClassName="mt-1"
                                            title="비상 사다리"
                                            checked={form.emergencyLadder}
                                            onChange={(id, checked) => handleCheckbox(id, checked, 'attributes')}
                                            disabled={!form.emergency}
                                        />


                                    </div>
                                    <div className="flex w-90 mb-2">
                                        <CustomCheckbox
                                            id={`fire`}
                                            className="ml-90"
                                            titleClassName="mt-1"
                                            title="화재"
                                            checked={form.fire}
                                            onChange={(id, checked) => handleCheckbox(id, checked, 'attributes')}
                                            disabled={!form.emergency}
                                        />
                                        <CustomCheckbox
                                            id={`restroom`}
                                            className="ml-60"
                                            titleClassName="mt-1"
                                            title="화장실"
                                            checked={form.restroom}
                                            onChange={(id, checked) => handleCheckbox(id, checked, 'attributes')}
                                            disabled={!form.emergency}
                                        />
                                        <CustomCheckbox
                                            id={`crimePreventionEmergency`}
                                            className="ml-60"
                                            titleClassName="mt-1"
                                            title="방범비상"
                                            checked={form.crimePreventionEmergency}
                                            onChange={(id, checked) => handleCheckbox(id, checked, 'attributes')}
                                            disabled={!form.emergency}
                                        />
                                    </div>
                                </div>
                        </div>
                    </div>
                </div>
                <div className="footer_btn h-10">
                    {
                        user.menuGroup === "OPERATOR" ? '' : <>
                            <button className="w-160px h-30px addBtn mr-10" onClick={onSubmitHandler}>저장</button>
                             { registPage ? "" : <button className="w-160px h-30px removeBtn mr-10" onClick={onRemoveHandler}>삭제</button>}
                        </>
                    }
                    <button className="w-160px h-30px cancelBtn" onClick={onCancelHandler}>취소</button>
                </div>
            </div>
        </div>
    )
}

export default withRouter(SiteServiceRegist);
