import React, {useEffect, useState} from 'react'
import store from 'scripts/store';
import Net from 'scripts/net';
import classnames from 'classnames/bind';
import {Card, CardBody, CardText, CardTitle} from "../../../components/molecules/Card";
import {CustomTable} from "../../../components/molecules";
import 'pages/MonitoringPage/exhaustiveTest/exhuastiveTest.scss';

const cx = classnames.bind();


const columnList = [
    {
        code: 'no',
        title: 'No',
        width: '8',
    },
    {
        code: 'region.name',
        title: '지역',
        width: '10',
    },
    {
        code: 'name',
        title: '현장명',
        width: '10',
    },
    {
        code: 'numberOfHouseHold',
        title: '총 세대수',
        width: '10',
    },
    {
        code: 'homenetUsers',
        title: '연동 세대수',
        width: '10',
    },
    {
        code: 'signups',
        title: '연동 가입자 수',
        width: '8',
    },
];

export const Manager = (props) => {
    const user = props.userInfo;
    const customerCode = user.customer && user.customer.code;
    const [users, setUsers] = useState(0);
    const [homenetUsers, setHomenetUsers] = useState(0);
    const [homenetDevices, setHomenetDevices] = useState(0);
    const [households, setHouseholds] = useState({
        numberOfHouseHold: 0
    });
    const [siteState, setSiteState] = useState([]);
    const [pageState, setPageState] = useState({
        currentPage: 1,
        rowPerPage: 99999,
        totalElementsCnt: 0,
        drawElementsCnt: 0,
    });
    const [contentData, setContentData] = useState(new Map());


    useEffect(() => {
        init().then(() => {
            console.log("init complete");
        });
    }, [])

    useEffect(()=>{
       // console.log(households)
    },[households])

    useEffect(()=>{
       // console.log(homenetDevices)
    },[homenetDevices])

    useEffect(()=>{
        setPageState({
            ...pageState,
            totalElementsCnt: siteState.length,
            drawElementsCnt: siteState.length,
         })
    },[siteState]);

    useEffect(()=>{
        console.log(pageState);
    },[pageState])

    const init = async () => {
        store.openedPage = '';


        const sites = await Net.getSitesListAsync({currentPage: 0, rowPerPage: 99999, searchText: "", customerCode: customerCode});
        sites.data.content.map(async (item) => {
            const users = await Net.getUserListAsync({currentPage: 0, rowPerPage: 1000000000, searchCategory2: item.code})
            item.signups = users.data.totalElements;

            console.log(users);
            let total = new Map();

            users.data.content.forEach(user=>{
                if( !user.place || ((user.place.name === undefined) || (user.place.name===''))){
                    return null;
                }
                total.set(user.place.name,1);

            });

            console.log(total);
            item.homenetUsers = total.size


            setHouseholds( prevState => {
                return {
                    ...prevState,
                    numberOfHouseHold : prevState.numberOfHouseHold + item.numberOfHouseHold
                }
            });

            await setHomenetUsers(prevState => {
                return prevState +  item.homenetUsers;
            })

            await setSiteState(prevState => {
                return [
                    ...prevState,
                    item
                ]
            })

        });

        await Net.getUserList({currentPage: 0, rowPerPage: 1000000000, searchCategory1: customerCode}, response => {
            setUsers(response.data && response.data.totalElements);
        });

    }


    const gruopBy = ((list, keyGetter)=>{
        const map = new Map();
        list.forEach((item)=>{
            if(item.site){
                const key = keyGetter(item);
                const collection = map.get(key);
                if(!collection){
                    map.set(key, [item]);
                }else{
                    collection.push(item);
                }
            }
        });
        return map;
    })


    return (
        <div className={cx('container-fluid', 'pt-3')}>
            <h2 className={cx('mt-3', 'ml-2')}>세대 현황</h2>
            <div className={cx('row', 'mt-4')}>
                <div className={cx('col-3')}>
                    <Card>
                        <CardBody>
                            <CardTitle>총 세대수</CardTitle>
                            <CardText className={cx('text-end')}>{households.numberOfHouseHold}</CardText>
                        </CardBody>
                    </Card>
                </div>
                <div className={cx('col-3')}>
                    <Card>
                        <CardBody>
                            <CardTitle>연동 세대 수</CardTitle>
                            <CardText className={cx('text-end')}>{homenetUsers}</CardText>
                        </CardBody>
                    </Card>
                </div>
                <div className={cx('col-3')}>
                    <Card>
                        <CardBody>
                            <CardTitle>연동 가입자 수</CardTitle>
                            <CardText className={cx('text-end')}>{users}</CardText>
                        </CardBody>
                    </Card>
                </div>
            </div>
            <div className="w-100 h-60 mt-12 overflow-auto scroll-style" style={{display: "block"}}>
                <CustomTable
                    className="overflow-auto scroll-style"
                    wideTable={true}
                    columnList={columnList}
                    contentData={siteState}
                    checkbox={false}
                    rowClickEvt={false}
                    totalElementsCnt={pageState.totalElementsCnt}
                    rowPerPage={pageState.rowPerPage}
                    currentPage={pageState.currentPage - 1}
                    paging={false}
                />
            </div>
        </div>
    )
}
