import React, { useState, useEffect, Fragment } from 'react'
import { useSubscription } from 'mqtt-react-hooks';
import Net from 'scripts/net';
import {useDidMountEffect} from "scripts/hooks";
import {CircularSliderWithChildren} from "react-circular-slider-svg";
import './MqttButton.scss';

import { TiPowerOutline,
    IoMdFlashOff,
    FcFlashOn,
    TbPlugConnected,
    TbPlugConnectedX,
    RiLightbulbFlashFill,
    FaHotjar,
    TbWindOff,
    BiWind,
    TbWindmill,
    TbWindmillOff,
    FaRegLightbulb} from "react-icons/all";

export const MqttButton = (props) => {

    const {message, connectionStatus} = useSubscription([`things.${props.deviceId}.reported`]);

    const [boolPower, setBoolPower] = useState(false);
    const [boolValve, setBoolValve] = useState(false);
    const [boolTemp, setBoolTemp] = useState(false);
    const [boolAirCondition, setBoolAirCondition] = useState(false);
    const [boolDimming, setBoolDimming] = useState(false);

    const [onValue, setOnValue] = useState("off");
    const [onTempValue, setTempOnValue] = useState("0");
    const [onAirConditionTempValue, setAirConditionTempOnValue] = useState("0");
    const [brightValue, setBrightValue] = useState("0");

    const [boolLoading, setBoolLoading] = useState(false);

    const [valveText, setValveText] = useState("열림");

    useDidMountEffect(()=>{
    },[onValue])

    useEffect(() =>{
        async function syncFunction(){
            if(message) {
                if(message.message){
                    const jsonMessage = await JSON.parse(message.message);
                    if(await jsonMessage.hasOwnProperty("operations") && jsonMessage.operations !== null){
                        console.log(jsonMessage);
                        if( Array.isArray(jsonMessage.operations) && jsonMessage.operations.length > 1){
                            jsonMessage.operations.forEach( operationsItem => {
                                /*
                                     메시지 온것을 토대로 ContentData를 업데이트 쳐줘야함.
                                * */
                                //console.log(operationsItem, message);
                                if(operationsItem.code === 'brightness'){
                                    //console.log(operationsItem, 'brightness');
                                    const tempValue = operationsItem.value === '0' ? '0' : Number(operationsItem.value);
                                    setBrightValue(tempValue.toString());
                                    props.setStateValue(props.deviceId, tempValue.toString(), "brightness");
                                }
                                if(operationsItem.code === 'settingTemperature'){
                                    setTempOnValue(operationsItem.value);
                                    props.setStateValue(props.deviceId, operationsItem.value, "stateTemperature");
                                }
                                if(operationsItem.code === 'airConditionSettingTemperature'){
                                    setAirConditionTempOnValue(operationsItem.value);
                                    props.setStateValue(props.deviceId, operationsItem.value, "stateAirConditionTemperature");
                                }
                                if(operationsItem.code === 'power'){
                                    //console.log(operationsItem, 'power');
                                    setOnValue(operationsItem.value);
                                    setBoolLoading(false);
                                    props.setStateValue(props.deviceId, operationsItem.value, "stateValue");
                                }else if (operationsItem.code === 'valve'){
                                    setOnValue(operationsItem.value);
                                    setBoolLoading(false);
                                }
                            })
                        }else {

                            if((jsonMessage.operations.length !== 0 ) && jsonMessage.operations[0].hasOwnProperty("value")){
                                setOnValue(jsonMessage.operations[0].value);
                                setBoolLoading(false);
                                props.setStateValue(props.deviceId, jsonMessage.operations[0].value, "stateValue");
                            }
                        }
                    }
                }
            }
        }
        syncFunction().then(()=>{});
    },[message]);

    useEffect(() => {
        if (props.brightness !== undefined){
            setBoolDimming(true);
            setBrightValue(props.brightness);
        }
        if (props.stateTemperature !== undefined){
            setBoolTemp(true);
            setTempOnValue(props.stateTemperature);
        }
        if (props.stateAirConditionTemperature !== undefined){
            setBoolAirCondition(true);
            setAirConditionTempOnValue(props.stateAirConditionTemperature);
        }
        if(props.stateValue !== undefined){
            if(props.stateValue === "on"){
                setOnValue("on");
                setBoolPower(true);

            }else if(props.stateValue === "off"){
                setOnValue("off");
                setBoolPower(true);

            }else if(props.stateValue === "close"){
                setOnValue("close");
                setBoolValve(true);

            }else if(props.stateValue === "open"){
                setOnValue("open");
                setBoolValve(true);
            }
        }
    }, [props]);

    const clickTemperature = (code, actions, baseStateValue) => {
        const value = (Number(baseStateValue)+actions).toString();

        Net.deviceControl({
            deviceId : props.deviceId,
            operations : [{
                code : code,
                value : value
            }]
        })
    }

    const clickBtnBrightness = (brightnessValue) => {
        setBoolLoading(true);
        const value = (Math.round(brightnessValue.toFixed()/10)*10).toString();
        Net.deviceControl({
            deviceId : props.deviceId,
            operations : [{
                code : 'brightness',
                value: value,
            }]
        });
        setTimeout(()=>{
            setBoolLoading(false);
        },4000)
    }

    const clickPowerBtn = () => {
        setBoolLoading(true);
        if(onValue === "on"){
            Net.deviceControl({
                deviceId : props.deviceId,
                operations : [{
                    code : 'power',
                    value: 'off',
                }]
            });
        } else if(onValue === "off"){
            Net.deviceControl({
                deviceId : props.deviceId,
                operations : [{
                    code : 'power',
                    value: 'on',
                }]
            });
        } else if(onValue === "open"){
            Net.deviceControl({
                deviceId : props.deviceId,
                operations : [{
                    code : 'valve',
                    value: 'close',
                }]
            });
        }else if(onValue === "close"){
            Net.deviceControl({
                deviceId : props.deviceId,
                operations : [{
                    code : 'valve',
                    value: 'open',
                }]
            });
        }else{
            setBoolLoading(false);
        }
        setTimeout(()=>{
            setBoolLoading(false);
        },5000)
    };

    const commonOnBulbDom = () => {
        return (
            <>
                {
                    props.type === 'OUTLET' ? <>
                        <h1 onClick={()=> {clickPowerBtn()}} className="hoverbtn">
                            <TbPlugConnected messagesize="48" color="gold" className="hoverbtn"/>
                        </h1>
                    </> :(
                        (props.type === 'VENTILATION') || (props.type === 'VENTILATION_LITE') ?
                            <>
                                <h1 onClick={()=> {clickPowerBtn()}} className="hoverbtn">
                                    <TbWindmill messagesize="48" color="Blue" className="hoverbtn"/>
                                </h1>
                            </> :
                            <>
                                <h1 onClick={()=> {clickPowerBtn()}} className="hoverbtn">
                                    <RiLightbulbFlashFill messagesize="48" color="gold" className="hoverbtn"/>
                                </h1>
                            </>
                    )
                }

            </>
        )
    }

    const commonOffBulbDom = () => {
        return (
            <>
                {
                    props.type === 'OUTLET' ? <>
                        <h1 onClick={()=> {clickPowerBtn()}} className="hoverbtn">
                            <TbPlugConnectedX messagesize="48" color="black" className="hoverbtn"/>
                        </h1>
                    </> : (
                        (props.type === 'VENTILATION') || (props.type === 'VENTILATION_LITE') ? <>
                                <h1 onClick={()=> {clickPowerBtn()}} className="hoverbtn">
                                    <TbWindmillOff messagesize="48" color="black" className="hoverbtn"/>
                                </h1>
                            </>
                            :
                            <>
                                <h1 onClick={()=> {clickPowerBtn()}} className="hoverbtn">
                                    <FaRegLightbulb messagesize="36" color="grey" className="hoverbtn"/>
                                </h1>
                            </>
                    )
                }

            </>

        )
    }

    const heaterOnDom = () => {
        return (
            <div>
                <h2>
                    <FaHotjar messagesize="24" color="red" className="hoverbtn" onClick={() => {
                        clickPowerBtn()
                    }}/>
                </h2>
                <h4>
                    <button type="button" className="btn btn-outline-secondary btn-bsm mr-4" onClick={()=> clickTemperature('settingTemperature',-1,onTempValue)}>&ensp;&ensp;⇩&ensp;&ensp;
                    </button>
                    {onTempValue}
                    <button type="button" className="btn btn-outline-secondary btn-bsm ml-5" onClick={()=> clickTemperature('settingTemperature',1,onTempValue)}>&ensp;&ensp;⇧&ensp;&ensp;
                    </button>
                </h4>
            </div>
        )
    }

    const heaterOffDom = () => {
        return (
            <h2 className="hoverbtn" onClick={()=>{clickPowerBtn()}} ><FaHotjar color="black" messagesize="36" className="hoverbtn" /></h2>
        )
    }

    const airConditionOnDom = () => {
        return (
            <div>
                <h2>
                    <BiWind messagesize="32" color="DeepSkyBlue" className="hoverbtn" onClick={() => {
                        clickPowerBtn()
                    }}/>
                </h2>
                <h4>
                    <button type="button" className="btn btn-outline-secondary btn-bsm mr-4" onClick={()=> clickTemperature('airConditionSettingTemperature',-1,onAirConditionTempValue)}>&ensp;&ensp;⇩&ensp;&ensp;
                    </button>
                    {onAirConditionTempValue}
                    <button type="button" className="btn btn-outline-secondary btn-bsm ml-5" onClick={()=> clickTemperature('airConditionSettingTemperature',1,onAirConditionTempValue)}>&ensp;&ensp;⇧&ensp;&ensp;
                    </button>
                </h4>
            </div>
        )
    }

    const airConditionOffDom = () => {
        return (
            <h2 className="hoverbtn" onClick={()=>{clickPowerBtn()}} ><TbWindOff color="black" messagesize="36" className="hoverbtn" /></h2>
        )
    }

    const dimmingDom = () => {
        /*
        * 디밍 조명 UI
        * ON / OFF
        * */

        if(onValue === "on") {
            return (
                <div className="row">
                    <div className="flex j-c-c">
                        <DimmingBulb title={''}
                                     opts={{
                                         size : 100,
                                         startAngle : 40,
                                         endAngle : 320,
                                     }}
                                     brightness={brightValue}
                                     clickPowerBtn={()=>{clickPowerBtn()}}
                                     clickBtnBrightness={clickBtnBrightness}
                                     setBoolLoading={setBoolLoading}
                                     boolLoading={boolLoading}
                        >
                        </DimmingBulb>
                    </div>
                </div>
            )
        }
        else {return commonOffBulbDom();}
    }

    const createControlDom = () => {
        if(boolTemp){
            return ( boolPower ?
                    ( boolLoading ?
                        (<>
                            <p>
                                <img
                                    className="w-24px h-16px"
                                    src={'/icon/loading.gif'}
                                /></p>
                        </>)
                        :
                        /* 보일러 */
                        ( onValue === "on") ?  heaterOnDom() :
                            heaterOffDom())
                    : (
                        <> 새로 고침 부탁 드립니다.</>
                ))
        }else if(boolAirCondition){
            return ( boolPower ?
                ( boolLoading ?
                    (<>
                        <p>
                            <img
                                className="w-24px h-16px"
                                src={'/icon/loading.gif'}
                            /></p>
                    </>)
                    :
                    /* 에어컨 */
                    ( onValue === "on") ?  airConditionOnDom() :
                        airConditionOffDom())
                : (
                    <> 새로 고침 부탁 드립니다.</>
                ))
        }else{
            return (boolPower ?
                ( boolLoading ?
                        (<>
                            <p>
                                <img
                                    className="w-24px h-16px"
                                    src={'/icon/loading.gif'}
                                /></p>
                        </>)
                        /* 디밍 전구 */
                        :
                        (boolDimming) ? dimmingDom()

                            /* 일반 디바이스 */
                            : (( onValue === "on" )  ? (commonOnBulbDom()) :
                                (commonOffBulbDom()))
                ) : boolValve ? ( onValue === "close") ?

                        /* 가스 밸브 */
                        <button className="btn btn-dark btn-sm" disabled>닫힘</button> :
                        <>
                            <button
                                className="btn btn-outline-dark btn-sm"
                                onMouseOver={()=>setValveText('닫기')}
                                onMouseOut={()=>setValveText('열림')}
                                onClick={clickPowerBtn}> {valveText} </button>
                        </>
                    : (<> - </>))
        }

        // return (
        //     boolTemp ? ( boolPower ?
        //             ( boolLoading ?
        //                 (<>
        //                     <p>
        //                         <img
        //                             className="w-24px h-16px"
        //                             src={'/icon/loading.gif'}
        //                         /></p>
        //                 </>)
        //                 :
        //                 /* 보일러 */
        //                 ( onValue === "on") ?  commonTempDom() :
        //                     buttonOffDom())
        //             : (
        //                 <> 새로 고침 부탁 드립니다.</>
        //             )) :
        //         (boolPower ?
        //             ( boolLoading ?
        //                     (<>
        //                         <p>
        //                             <img
        //                                 className="w-24px h-16px"
        //                                 src={'/icon/loading.gif'}
        //                             /></p>
        //                     </>)
        //                     /* 디밍 전구 */
        //                     :
        //                     (boolDimming) ? dimmingDom()
        //
        //                         /* 일반 디바이스 */
        //                         : (( onValue === "on" )  ? (commonOnBulbDom()) :
        //                             (commonOffBulbDom()))
        //             ) : boolValve ? ( onValue === "close") ?
        //
        //                     /* 가스 밸브 */
        //                     <button className="btn btn-dark btn-sm" disabled>닫힘</button> :
        //                     <>
        //                         <button
        //                             className="btn btn-outline-dark btn-sm"
        //                             onMouseOver={()=>setValveText('닫기')}
        //                             onMouseOut={()=>setValveText('열림')}
        //                             onClick={clickPowerBtn}> {valveText} </button>
        //                     </>
        //                 : (<> - </>))
        // )
    }

    return (
        <Fragment>
            {
                createControlDom()
            }
        </Fragment>
    )
}

type SliderProps = React.ComponentProps<typeof CircularSliderWithChildren>;
type DimmingBulbProps = { opts ? : Partial<SliderProps>; title : string; onValue : string; brightness : string;
    clickPowerBtn : any; clickBtnBrightness : any; boolLoading : any; setBoolLoading : any};
const DimmingBulb : React.FunctionComponent<DimmingBulbProps> = props => {
    const { opts, title, onValue, brightness, clickPowerBtn, clickBtnBrightness, setBoolLoading} = props;
    const [value1, setValue1] = useState(1);
    const [value2, setValue2] = useState(60);
    const [showConfig, setShowConfig] = useState(false);
    const config = {
        arcColor : "#690",
        ...opts,
        handle1 : {
            value : value1,
            onChange : (v:number) => setValue1(v)
        },
        maxValue : 50,
        minValue : 10,

    };
    useEffect(()=>{
        //console.log(value1, 'component');
    },[value1])

    useEffect(()=>{
        setBoolLoading(false);
        setValue1(Number(brightness));
    },[brightness])

    return (
        <div
            className="col-12 j-c-c flex"
            style={{
                flexShrink : 0,
                flexDirection : "column",
                alignItems : "center",
                marginTop : "-10px",
                marginBottom : "-10px"
            }}
        >
            {title}
            <CircularSliderWithChildren {...config}>
                <h2 ><TiPowerOutline color="gold" className="hoverbtn" onClick={()=>{clickPowerBtn()}}/></h2>
                {Math.round(value1.toFixed()/10)*10}
                <button type="button" className="btn btn-success btn-bsm" onClick={()=>{clickBtnBrightness(value1)}} >적용</button>
            </CircularSliderWithChildren>
        </div>
    )
}
