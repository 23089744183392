import React, {Fragment, useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom'
import {CustomTable, SearchInputBar} from 'components/molecules';
import store from 'scripts/store';
import Net from 'scripts/net';
import 'pages/MonitoringPage/exhaustiveTest/exhuastiveTest.scss';

export const MonthlyUserMonitoring = (props) => {

    // useHistory 선언
    const searchTextPlaceholderBase = new Date().getFullYear().toString(10)+' (년도 입력)';
    const history = useHistory();
    const user = props.userInfo;
    const [programAuth, setProgramAuth] = useState({});
    const allMonth = ['01','02','03','04','05','06','07','08','09','10','11','12']
    const [contentData, setContentData] = useState(new Map());
    const [contentDataDetail, setContentDataDetail] = useState([]);
    const [searchCategory, setSearchCategory] = useState([
        [
            [
                {code: '', name: '고객사 선택'}
            ]
        ],
        [
            [
                {code: '', name: '현장명 선택'}
            ]
        ],
    ]);
    const [pageState, setPageState] = useState({
        totalElementsCnt: 0,
        drawElementsCnt: 0,
        searchText: new Date().getFullYear().toString(10),
        searchCategory1: user.menuGroup === 'ADMIN' ? '' : user.customer && user.customer.code,
        searchCategory2: user.menuGroup === 'OPERATOR' ? user.site && user.site.code: '',
    });


    const pageAuthInfo = () => {
        const authGroupCode = props.userInfo.authList[0];
        const menuId = sessionStorage.getItem('menuId');

        Net.getAuthGroupMenuList(menuId, (response) => {
            if (response.status === 200) {
                response.data.forEach(item => {
                    if (item.authGroup === authGroupCode && item.authGroupMenu) {
                        setProgramAuth({
                            searchAuth: Boolean(item.authGroupMenu.searchAuth),
                            saveAuth: Boolean(item.authGroupMenu.saveAuth),
                            excelAuth: Boolean(item.authGroupMenu.excelAuth),
                            deleteAuth: Boolean(item.authGroupMenu.deleteAuth),
                        });
                    }
                });
            }
        });
    }

    const gruopBy = ((list, keyGetter)=>{
       const map = new Map();
       list.forEach((item)=>{
           if(item.site){
               const key = keyGetter(item);
               const collection = map.get(key);
               if(!collection){
                   map.set(key, [item]);
               }else{
                   collection.push(item);
               }
           }
       });
       return map;
    })


    const loadData = () => {
        setContentDataDetail([])
        Net.getUserList(
            {
                currentPage: 0,
                rowPerPage: 999999,
                searchCategory1: pageState.searchCategory1,
                searchCategory2: pageState.searchCategory2,
            }, (response) => {
                if (response.status === 200) {
                    const grouped = gruopBy(response.data.content, data => data.site.code);
                    setContentData(grouped);
                    setPageState({
                        ...pageState,
                        totalElementsCnt: response.data.totalElements,
                        drawElementsCnt: response.data.numberOfElements,
                    });
                } else {
                    setContentData([]);
                    setPageState({
                        ...pageState,
                        totalElementsCnt: 0,
                        drawElementsCnt: 0,
                    });
                }
            });
    }

    const getCategoryList = () => {
        if (user.menuGroup === 'ADMIN') {
            getCustomerList((response1) => {
                getSitesList(null, (response2) => {
                    setSearchCategory([response1, response2]);
                });
            });
        } else if (user.menuGroup === 'MANAGER') {
            getSitesList(pageState.searchCategory1, (response2) => {
                setSearchCategory([response2]);
            });
        }
    }

    const getCustomerList = (callback) => {
        Net.getCustomerList(
            {currentPage: '', rowPerPage: '' ,searchText: ''}, (response) => {
                if (response.status === 200) {
                    let newArray = searchCategory[0][0];
                    response.data.content.forEach(items => {
                        newArray = [
                            ...newArray,
                            {
                                code: items.code,
                                name: items.name
                            }
                        ]
                    });
                    callback(newArray);
                }
            });
    }

    const getSitesList = (selectedCategory, callback) => {
        if (selectedCategory) {
            Net.getSitesList(
                {currentPage: '', rowPerPage: '', searchText: '', customerCode: selectedCategory ? selectedCategory : false}, (response) => {
                    if (response.status === 200) {
                        let newArray = [{code: '', name: "현장명 선택"}];
                        if (response.data.content.length > 0) {
                            response.data.content.forEach(items => {
                                newArray = [
                                    ...newArray,
                                    {
                                        code: items.code,
                                        name: items.name
                                    }
                                ]
                            })
                        }
                        callback(newArray);
                    } else {
                        let newArray = [{code: '', name: "현장명 선택"}];
                        callback(newArray);
                    }
                });
        } else {
            let newArray = [{code: '', name: "현장명 선택"}];
            callback(newArray);
        }
    }

    const getRelateCategory = (selectedCategory) => {
        if (selectedCategory === "reset") selectedCategory = "";
        getSitesList(selectedCategory, (response2) => {
            setSearchCategory([
                searchCategory[0], // 고객사
                response2,         // 현장
            ]);
        });
    }

    const onSubmitHandler = (e) => {
        var regex = /[^0-9]/g;
        var result = e.searchText.replace(regex,"");
        if(result === ''){
            result = new Date().getFullYear().toString(10);
        }

        if (user.menuGroup === 'ADMIN') {
            if(e.selectedCategory[0] === '') {
                alert('고객사명은 필수값입니다.');
                return false;
            }
            setPageState({
                ...pageState,
                currentPage: 1,
                searchCategory1: e.selectedCategory[0],
                searchCategory2: e.selectedCategory[1],
                searchText: result,
            });
        } else if (user.menuGroup === 'MANAGER') {
            setPageState({
                ...pageState,
                currentPage: 1,
                searchCategory1: pageState.searchCategory1,
                searchCategory2: e.selectedCategory[0],
                searchText: result,
            });
        }


    }

    const findInMap = (map, val) => {
        let count = 0;
        for (let [k, v] of map) {
            if (v === val) {
                count++;
            }
        }
        return count;
    }

    useEffect(() => {
        store.openedPage = '월별 사용자 가입 현황';
        pageAuthInfo();
        getCategoryList('');
    }, [])

    useEffect(()=>{
        if(pageState.searchCategory1 === ''){
            return;
        }
        loadData();
    },[pageState.searchCategory1, pageState.searchCategory2, pageState.searchText])

    useEffect(()=>{

        let tmpData = [];
        let totalData = {
            "01" : 0,
            "01House" : 0,
            "02" : 0,
            "02House" : 0,
            "03" : 0,
            "03House" : 0,
            "04" : 0,
            "04House" : 0,
            "05" : 0,
            "05House" : 0,
            "06" : 0,
            "06House" : 0,
            "07" : 0,
            "07House" : 0,
            "08" : 0,
            "08House" : 0,
            "09" : 0,
            "09House" : 0,
            "10" : 0,
            "10House" : 0,
            "11" : 0,
            "11House" : 0,
            "12" : 0,
            "12House" : 0,
            code : "",
            key : 0,
            household : 0,
            totalHouse: 0,
            totalHouseHold : 0,
            totalUser : 0,
            name : "전체 합계"
        };
        const now = pageState.searchText;
        contentData.forEach((value,key)=>{
            if(key==="")return null;
            let responseList = {code:key,key:value.length, '01':0, '02':0, '03':0, '04':0, '05':0, '06':0, '07':0, '08':0, '09':0, '10':0, '11':0, '12':0,
            '01House':0, '02House':0, '03House':0, '04House':0, '05House':0, '06House':0, '07House':0, '08House':0, '09House':0, '10House':0, '11House':0, '12House':0
            ,'totalHouse':0, 'totalUser':0,};
            let total = new Map();
            let allHouseholdTotal = new Map();
            value.forEach(item=>{
                const year = item.createdDatetime.substring(0,4);
                const month = item.createdDatetime.substring(5,7);
                if( !item.place || ((item.place.name === undefined) || (item.place.name===''))){
                    responseList.key -= 1;
                    return null;
                }
                if(year !== now){
                    allHouseholdTotal.set(item.place.name, 1);
                    total.set(item.place.name,0);
                    return null;
                }
                responseList[month] += 1;
                total.set(item.place.name, month);
                allHouseholdTotal.set(item.place.name, 1);
            })
            const tempList = allMonth.map(item => {
                return findInMap(total, item)
            })

            let totalHouse = 0;
            let totalUser = 0;
            allMonth.forEach((item,i) => {
                responseList[item+'House'] = tempList[i];
                totalHouse += tempList[i];
                totalUser += responseList[item];
            })
            responseList['totalHouse'] = totalHouse;
            responseList['totalUser'] = totalUser;
            responseList['totalHouseHold'] = allHouseholdTotal.size; // 총 가입 세대
            tmpData = [...tmpData, responseList];
        });
        console.log(tmpData);

        if (window.SITE_LIST) {
            // SITE_LIST에서 code를 기준으로 builtDateMap 생성
            const builtDateMap = Object.keys(window.SITE_LIST).reduce((map, code) => {
                map[code] = new Date(window.SITE_LIST[code].builtDate);
                return map;
            }, {});

            // tmpData를 builtDate 기준으로 정렬
            tmpData.sort((a, b) => {
                const dateA = builtDateMap[a.code] || new Date(0); // SITE_LIST에 없는 경우에는 epoch date 사용
                const dateB = builtDateMap[b.code] || new Date(0); // SITE_LIST에 없는 경우에는 epoch date 사용
                return dateA - dateB;
            });
        }

        setContentDataDetail(tmpData)

    },[contentData])

    return (
        <div className="w-100 h-100">
            <div className="w-100 h-1px bgC-efefef"/>
            <div className="h-93">
                <div className="row overFlowYscrollView scroll-style">
                    <div className="col pt-2">
                        <h5 className="floatL mb-1p mt-1 ml-2p">월별 사용자 가입 현황</h5>
                        <SearchInputBar
                            className="col-md-8 mt-2"
                            searchCategory={true}
                            searchCategoryGroup={searchCategory}
                            searchText={true}
                            searchPlaceHolder={searchTextPlaceholderBase}
                            searchBtn={true}
                            searchBtnTitle="검색"
                            dynamicSearch={false}
                            relateCategoryHandler={user.menuGroup === 'ADMIN' && getRelateCategory}
                            onSubmit={onSubmitHandler}
                        />
                        <div className="overflow-scroll m-2 w-200">
                            <table className="mainTable overflow-scroll">
                                <thead className="bgC-c5c5c5">
                                <tr>
                                    <th rowSpan="10" className="v-middle tableHead fixedHeader w-10"><span className="h-24px">현장</span>
                                    </th>
                                    <th rowSpan="10" className="v-middle tableHead fixedHeader "><span className="h-24px">총 세대 수</span></th>
                                    <th rowSpan="10" className="v-middle tableHead fixedHeader "><span className="h-24px">총 가입 세대</span></th>
                                    <th rowSpan="10" className="v-middle tableHead fixedHeader "><span className="h-24px">총 가입자 수</span></th>
                                    {
                                        allMonth.map((x)=>{
                                            return <th key={x} colSpan="2" className="v-middle tableHead fixedHeader "><span className="h-24px">{x}월</span></th>
                                        })
                                    }
                                    <th colSpan="2" className="v-middle tableHead fixedHeader "><span className="h-24px">합계</span></th>
                                </tr>
                                <tr>
                                {
                                    allMonth.map(x=>{
                                        return(
                                            <>
                                                <th key={x+'account'} className="v-middle tableHead fixedHeader "><span className="h-24px">가입자수</span></th>
                                                <th key={x+'household'}className="v-middle tableHead fixedHeader "><span className="h-24px">세대수</span></th>
                                            </>)
                                    })
                                }
                                    <th className="v-middle tableHead fixedHeader "><span className="h-24px">가입자수</span></th>
                                    <th className="v-middle tableHead fixedHeader "><span className="h-24px">세대수</span></th>
                                </tr>
                                </thead>
                                <tbody className="tableBody">
                                <>
                                {
                                    contentDataDetail.length > 0 ? (
                                        contentDataDetail.map((item, index)=>{
                                            item.name = window.SITE_LIST.hasOwnProperty(item.code) ? window.SITE_LIST[item.code].hasOwnProperty('name') ?  window.SITE_LIST[item.code].name : item.code : item.code;
                                            item.household = window.SITE_LIST.hasOwnProperty(item.code) ? window.SITE_LIST[item.code].hasOwnProperty('household') ? window.SITE_LIST[item.code].household : 0 : 0;
                                            return <tr key={index}><td>{item.name}</td><td>{item.household}</td><td>{item['totalHouseHold']}</td><td>{item.key}</td><td>{item['01']}</td>
                                                <td>{item['01House']}</td><td>{item['02']}</td><td>{item['02House']}</td><td>{item['03']}</td>
                                                <td>{item['03House']}</td><td>{item['04']}</td><td>{item['04House']}</td><td>{item['05']}</td>
                                                <td>{item['05House']}</td><td>{item['06']}</td><td>{item['06House']}</td><td>{item['07']}</td>
                                                <td>{item['07House']}</td><td>{item['08']}</td><td>{item['08House']}</td><td>{item['09']}</td>
                                                <td>{item['09House']}</td><td>{item['10']}</td><td>{item['10House']}</td><td>{item['11']}</td>
                                                <td>{item['11House']}</td><td>{item['12']}</td><td>{item['12House']}</td>
                                                <td>{item['totalUser']}</td><td>{item['totalHouse']}</td>
                                            </tr>
                                        })
                                        ):
                                        <tr>
                                            <td colSpan="28">조회 된 데이터가 없습니다.</td>
                                        </tr>
                                }
                                </>
                                </tbody>
                            </table>

                        </div>

                    </div>
                </div>
            </div>
        </div>
    );
}

