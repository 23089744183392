import React, { useEffect, useState } from 'react';
import { useHistory, withRouter } from 'react-router-dom';
import { CustomTextField, CustomRadioButton, QuillEditor, CustomSelectBox, ImgUpload, FileListWithDelete } from 'components/molecules';
import store from 'scripts/store';
import DatePicker from "react-datepicker";
import Net from 'scripts/net';
import Common from 'scripts/common';
import {toJS} from "mobx";
import {EditorState} from "draft-js";
import ReactTooltip from "react-tooltip";
import {useDidMountEffect} from "scripts/hooks";

const NoticeRegist = ({match}) => {

    const cloneUser = toJS(store.user)

    const [registPage, setRegistPage] = useState(true);
    const [duplicateChk, setDuplicateChk] = useState(false);
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [customerList, setCustomerList] = useState([{
        code: '',
        name: '고객사명 선택'
    }]);
    const [siteList, setSiteList] = useState([{
        code: '',
        name: '현장명 선택'
    }]);
    const [classificationList, setClassificationList] = useState([
        [
            {code: '', name: '구분 선택'}
        ]
    ]);

    const [checkSubmit, setCheckSubmit] = useState(false);
    const [form, setForm] = useState({
        noticeId: '',
        classification: '',
        mainNotice: false,
        title: '',
        contents: '',
        customer : cloneUser.menuGroup === 'ADMIN' ? {code: '',name: '',} : cloneUser.customer,
        site: cloneUser.menuGroup === 'OPERATOR' ? cloneUser.site : { code: '', name: '',},
        dong: '',
        ho: '',
        startDate: '',
        endDate: '',
        publicYn: true,
        image: '',
        imageDataUrl: '',
        file: '',
        fileData: '',
        fileInfo : [],
        filteredFiles : [],
        newFiles : [],
        fileDataUrl: '',
        isAppClose: false,
        appPushYn : false,
        appPopupYn : false,
        homenetNotiYn : false,
    });
    const [editorState, setEditorState] = useState(EditorState.createEmpty());

    useEffect(()=>{
       if(form.classification==='INCIDENT'){
           setForm({
               ...form,
               dong:'',
               ho:'',
               appPushYn : false,
               appPopupYn : false,
               homenetNotiYn : false,
               site : { code: '', name: '',}

           })
       }else{
           setForm({
               ...form,
               isAppClose: false
           })
       }
    },[form.classification])

    useDidMountEffect(()=>{
        if(match.params.noticeId === undefined) {
            setForm({
                ...form,
                homenetNotiYn : false
            })
        }
    },[form.site])


    useEffect( () => {
        store.openedPage = '공지사항 등록/수정';
        setTimeout(()=>{
            if(cloneUser.menuGroup === null){
                //history.push('');
            }
        },[5000])

        // 상세 조회(수정 목적으로 진입시)
        if (match.params.noticeId !== undefined) {
            async function mounted(){
                const response = await getNoticeOne(match.params);
                if (response.status === 200) {
                    if (!response.data.customer) {
                        response.data.customer = {code: "", name: ""};
                    }
                    if (!response.data.site) {
                        response.data.site = {code: "", name: ""};
                    }
                    if (response.data.dong === "ALL") {
                        response.data.dong = "";
                    }
                    if (response.data.ho === "ALL") {
                        response.data.ho = "";
                    }
                    setForm({
                        ...form,
                        ...response.data,
                    });
                    setEditorState(Common.htmlToDraftBlocks(response.data.contents));

                    // 등록페이지 여부 확인
                    setRegistPage(false);

                    setStartDate(new Date(response.data.startDate));
                    setEndDate(new Date(response.data.endDate));
                }
            }

            mounted().then(()=>console.log('updated notice'))
        }

        // 고객사 전체 목록
        getCustomerList((response) => {
            setCustomerList(response);
        });
        getClassificationList();
    }, []);

    useEffect(() => {
        dateChangeHandler();
    }, [ startDate, endDate ])

    useEffect(()=>{
        if(checkSubmit){
            saveLogic(form);
        }
    },[checkSubmit])

    useEffect(() => {
        if(form.customer.code !== '') {
            getSiteList(form.customer.code, (response) => {
                setSiteList(response);
            })
        } else {
            // 빈값 선택시 장비모델명 selectbox 초기화
            setSiteList([
                {
                    code: '',
                    name: '현장명 선택'
                }
            ])
        }
    }, [form.customer.code]);

    const updateFilesData = (filteredData , newData) => {
        console.log(filteredData, newData);
        setForm({
            ...form,
            filteredFiles: filteredData,
            newFiles: newData
            }
        )
    };
    const getCustomerList = (callback) => {
        Net.getCustomerList(
            {currentPage: '', rowPerPage: '', searchText: ''}, (response) => {
            if(response.status === 200) {
                let newArray = customerList;
                response.data.content.forEach(items => {
                    newArray = [
                        ...newArray,
                        {
                            code: items.code,
                            name: items.name
                        }
                    ]
                });
                callback(newArray);
            }
        });
    }

    const getSiteList = (code, callback) => {
        Net.getSitesList(
            {
                currentPage: '',
                rowPerPage: '',
                searchText: '',
                customerCode: code,
            }, (response) => {
            if(response.status === 200) {
                let newArray = [
                    {
                        code: '',
                        name: '현장명 선택'
                    }
                ];
                response.data.content.forEach(items => {
                    newArray = [
                        ...newArray,
                        {
                            code: items.code,
                            name: items.name
                        }
                    ]
                });
                callback(newArray);
            } else if(response.status === 204) {
                let newArray = [
                    {
                        code: '',
                        name: '현장명 선택'
                    }
                ];
                callback(newArray);
            }
        });
    }

    const getClassificationList = () => {
        Net.getCommunityCode(
            "NOTICE_CLASSIFICATION", (response) => {
                if(response.status === 200) {
                    let newArray = classificationList[0];

                    response.data.forEach(items => {
                        if( items.usable){
                            if(cloneUser.menuGroup === "OPERATOR"){
                                console.log(cloneUser)
                                if(items.code === "SITE" || items.code === "SYSTEM"){
                                    newArray = [
                                        ...newArray,
                                        {
                                            code: items.code,
                                            name: items.name
                                        }
                                    ]
                                }
                            }else{
                                console.log(cloneUser)

                                newArray = [
                                    ...newArray,
                                    {
                                        code: items.code,
                                        name: items.name
                                    }
                                ]
                            }
                        }
                    })
                    setClassificationList(newArray);
                }
            }
        )

    }

    const history = useHistory();

    const getNoticeOne = async (params) => {
        return await Net.getNoticeOneAsync(params.noticeId);

    }

    const textFieldHandler = (name, value) => {
        if(name === 'code') setDuplicateChk(false);
        const nextForm = {
            ...form,
            [name]: value
        }
        setForm(nextForm);
    }

    const radioBtnHandler = (name, value) => {
        let nextForm;
        if(name === "authGroup"){
            nextForm = {
                ...form,
                [name]: value,
            }
        } else {
            nextForm = {
                ...form,
                [name]: value === 'true',
            }
        }

        setForm(nextForm);
    }

    const selectBoxHandler = (name, value) => {
        switch (name) {
            case 'customer.code':
                setForm({
                    ...form,
                    customer: {
                        code: value.code,
                        name: value.name
                    },
                    site: {
                        code: "",
                        name: ""
                    }
                });
                break;
            case 'site.code':
                setForm({
                    ...form,
                    site: {
                        code: value.code,
                        name: value.name
                    }
                });
                break;
            case 'classification':
                setForm({
                    ...form,
                    classification: value
                });
                break;
            default:
                break;
        }
    }

    const onSubmitHandler = () => {

        if( !form.classification ) {
            alert('구분을 선택해 주세요.');
            document.getElementsByName('classification')[0].focus();
            return false;
        }
        if( !form.title ) {
            alert('제목을 입력해 주세요.');
            document.getElementsByName('title')[0].focus();
            return false;
        }
        if( !form.contents ) {
            alert('내용을 입력해 주세요.');
            return false;
        }
        if (cloneUser.menuGroup === 'OPERATOR') {
            setForm({
                ...form,
                customer: cloneUser.customer,
                site: cloneUser.site ,
            });

        } else if (cloneUser.menuGroup === 'MANAGER') {
                setForm({
                    ...form,
                    customer: cloneUser.customer,
                });

        } else {
            if(form.classification === "INCIDENT"){
            }else{
                if (!form.customer.code) {
                    alert('고객사를 선택해 주세요.');
                    document.getElementsByName('customer.code')[0].focus();
                    return false;
                }
            }
        }
        setCheckSubmit(true);

    }

    const saveLogic = async (saveform) => {

        let result = window.confirm('저장하시겠습니까?');

        console.log(saveform);

        if(result) {
            if(registPage) {
                const res = await Net.addNoticeAsync(saveform);
                if(res.status === 200 || res.status === 201){
                    history.push('/community/notice/list');

                }
            } else {
                const res = await Net.updateNoticeAsync(saveform);
                if(res.status === 200 ){
                    history.push('/community/notice/list');

                }
            }
        }
    }
    const onCancelHandler = () => {
        history.push('/community/notice/list');
    }

    const getImgFile = (dataUrl) => {
        setForm({
            ...form,
            imageDataUrl: dataUrl,
        });
    }

    const liftValue = (value) => {
        setForm({
            ...form,
            contents: value,
        });
    }

    const dateChangeHandler = () => {

        const startDateFormating = startDate.getFullYear() + '-' + ('0' + (Number(startDate.getMonth())+1)).slice(-2) + '-' + ('0' + startDate.getDate()).slice(-2);
        const endDateFormating = endDate.getFullYear() + '-' + ('0' + (Number(endDate.getMonth())+1)).slice(-2) + '-' + ('0' + endDate.getDate()).slice(-2);
        setForm({
            ...form,
            startDate: startDateFormating,
            endDate: endDateFormating
        });
    }

    const onRemoveHandler = () => {
        const result = window.confirm('삭제하시겠습니까?');
        if(result) {
            Net.removeNotice([{noticeId: form.noticeId}], (response) => {
                if(response) {
                    history.push('/community/notice/list');
                }
            });
        }
    }

    return (
        <div className="w-100 h-100">
            <div className="w-100 h-3 display_table"/>
            <div className="w-100 h-1px bgC-efefef" />
            <div className="h-95">
                <div className="flex h-90">
                    <div className="w-50 h-100 overFlowYscrollViewHidden">
                        <div className="ml-55 mt-12">
                            <div className="flex mb-12 w-90">
                                <label className="label_title">구분 (*)</label>
                                <CustomSelectBox
                                    name="classification"
                                    categoryList={classificationList}
                                    value={form.classification}
                                    onChangeHandler={(value) => selectBoxHandler('classification', value)}
                                    callbackCodeName="N"
                                    disabled={!registPage}
                                />
                            </div>

                            <div className="row">
                                <div className="flex w-90 mb-8" data-for="incidentLabel" data-tip>
                                    {
                                        cloneUser.menuGroup === 'ADMIN' ? (
                                            <>
                                                <label className="label_title">중요 공지 *</label>
                                                <CustomRadioButton
                                                    className="me-2 w-13"
                                                    title="중요"
                                                    id="mainNoticeY"
                                                    name="mainNotice"
                                                    value={true}
                                                    checked={form.mainNotice}
                                                    customOnChange={radioBtnHandler}
                                                />
                                                <CustomRadioButton
                                                    className="me-5 w-13"
                                                    title="일반"
                                                    id="mainNoticeN"
                                                    name="mainNotice"
                                                    value={false}
                                                    checked={!form.mainNotice}
                                                    customOnChange={radioBtnHandler}
                                                />
                                            </>
                                            ) :
                                        <></>
                                    }

                                    {
                                        form.classification==='INCIDENT'  &&
                                        <>
                                            <label className="label_title">앱 종료여부</label>
                                            <CustomRadioButton
                                                className="me-2 w-13"
                                                title="종료"
                                                id="isAppCloseY"
                                                name="isAppClose"
                                                value={true}
                                                checked={form.isAppClose}
                                                customOnChange={radioBtnHandler}
                                            />
                                            <CustomRadioButton
                                                title="미 종료"
                                                id="isAppCloseN"
                                                name="isAppClose"
                                                value={false}
                                                checked={!form.isAppClose}
                                                customOnChange={radioBtnHandler}
                                            />
                                            <ReactTooltip
                                                id="incidentLabel"
                                                place="bottom"
                                            >장애 공지사항의 경우 팝업,푸시,홈넷 전송 여부를 지정할 필요 없습니다.</ReactTooltip>

                                        </>
                                    }
                                </div>
                            </div>

                            {
                                form.classification==='INCIDENT'  ?
                                    <></>
                                    :
                                    <>
                                    <div className="row">
                                        <div className="flex w-90 mb-8">
                                            <div style={{display: 'contents'}}>
                                                <label className="label_title">팝업 여부 *</label>
                                                <CustomRadioButton
                                                    className="me-2 w-13"
                                                    title="Y"
                                                    id="appPopupY"
                                                    name="appPopupYn"
                                                    value={true}
                                                    checked={form.appPopupYn}
                                                    customOnChange={radioBtnHandler}
                                                />
                                                <CustomRadioButton
                                                    className="me-5 w-13"
                                                    title="N"
                                                    id="appPopupN"
                                                    name="appPopupYn"
                                                    value={false}
                                                    checked={!form.appPopupYn}
                                                    customOnChange={radioBtnHandler}
                                                />
                                            </div>
                                            <div style={{display: 'contents'}}
                                                data-for="pushLabel" data-tip>
                                                <label
                                                    className="label_title">알림 여부 *</label>
                                                <CustomRadioButton
                                                    className="me-2 w-13"
                                                    title="Y"
                                                    id="appPushY"
                                                    name="appPushYn"
                                                    value={true}
                                                    checked={form.appPushYn}
                                                    customOnChange={radioBtnHandler}
                                                    disabled={form.site.code === '' || !registPage}
                                                />
                                                <CustomRadioButton
                                                    className="w-13"
                                                    title="N"
                                                    id="appPushN"
                                                    name="appPushYn"
                                                    value={false}
                                                    checked={!form.appPushYn}
                                                    customOnChange={radioBtnHandler}
                                                    disabled={form.site.code === '' || !registPage}
                                                />
                                                <ReactTooltip
                                                    id="pushLabel"
                                                    place="bottom"
                                                    className="w-30"
                                                >푸시는 공개 일정에 관계없이 즉시 전송됩니다.
                                                 현장 지정시에만 전송 여부를 선택 할 수 있습니다.
                                                </ReactTooltip>
                                            </div>
                                        </div>
                                    </div>
                                        <div className="row">
                                            <div className="flex w-90 mb-8">
                                                <div style={{display: 'contents'}} data-for="homenetLabel" data-tip>
                                                    <span data-for="homenetLabel" data-tip className="label_title">홈넷 전송 여부</span>
                                                    <ReactTooltip
                                                        id="homenetLabel"
                                                        place="bottom"
                                                    >현장 지정시에만 전송 여부를 선택 할 수 있습니다.</ReactTooltip>
                                                    <CustomRadioButton
                                                        className="me-2 w-13"
                                                        title="Y"
                                                        id="homenetNotiY"
                                                        name="homenetNotiYn"
                                                        disabled={form.site.code === '' || !registPage}
                                                        value={true}
                                                        checked={form.homenetNotiYn}
                                                        customOnChange={radioBtnHandler}
                                                    />
                                                    <CustomRadioButton
                                                        className="me-5 w-13"
                                                        title="N"
                                                        disabled={form.site.code === '' || !registPage}
                                                        id="homenetNotiN"
                                                        name="homenetNotiYn"
                                                        value={false}
                                                        checked={!form.homenetNotiYn}
                                                        customOnChange={radioBtnHandler}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </>
                            }

                            <div className="flex mb-3 w-90">
                                <CustomTextField
                                    className="w-100 h-26px"
                                    title="제목"
                                    name="title"
                                    required={true}
                                    disabled={false}
                                    placeholder=""
                                    customOnChange={textFieldHandler}
                                    value={form}
                                />
                            </div>
                            <div className="flex mb-3 w-90">
                                <label className="label_title">내용 *</label>
                                <div className="w-100">
                                    <QuillEditor
                                        onChange={liftValue}
                                        value={editorState}
                                        setEditorState={setEditorState}
                                    />
                                </div>
                            </div>
                            {
                                cloneUser.menuGroup === 'ADMIN' ? (
                                    <div>
                                    <div className="flex mb-1 w-90">
                                        <div
                                            className="">
                                        <label className="label_title">고객사 {
                                             form.classification==='INCIDENT'  ? <></> : <>*</>
                                        }</label></div>
                                        <CustomSelectBox
                                            name="customer.code"
                                            className="d-block"
                                            categoryList={customerList}
                                            value={form.customer.code}
                                            onChangeHandler={(value, name) => selectBoxHandler('customer.code', {code: value, name: name})}
                                            callbackCodeName="Y"
                                            disabled={!registPage}
                                        />
                                    </div>
                                        {
                                            registPage ? <div/> :
                                                <div className="label_summary ml-3">※ 수정시 고객사 및 현장은 변경 불가능 합니다. </div>
                                        }
                                        {
                                             form.classification==='INCIDENT'  ?
                                             <div className="label_summary ml-3">※ 미 지정시 전체 시스템에 공지됩니다. </div>
                                             : <></>
                                        }
                                    </div>
                                ) : <></>
                            }
                            {
                                cloneUser.menuGroup === 'OPERATOR' ? <></> :(
                                    <div className="flex mb-3 mt-2 w-90">
                                        <label className="label_title">현장</label>
                                        {
                                            form.classification==='INCIDENT'  ?
                                                <div>
                                                    <div className="ml-10 label_summary w-100">※ 구분이 `장애`이면 현장 값을 지정 할 수 없습니다.</div>
                                                </div>
                                                :
                                                <CustomSelectBox
                                                    name="site.code"
                                                    categoryList={siteList}
                                                    value={form.site.code}
                                                    onChangeHandler={(value, name) => selectBoxHandler('site.code', {code: value, name: name})}
                                                    callbackCodeName="Y"
                                                    disabled={!registPage}
                                                />
                                        }
                                    </div>
                                )
                            }
                            <div className="flex mb-3 w-90">
                                <div className="w-45 h-26px">
                                    <CustomTextField
                                        className="w-40"
                                        title="장소(동)"
                                        name="dong"
                                        required={false}
                                        disabled={form.classification==='INCIDENT'}
                                        validLen="15"
                                        //validRegExp={/[\{\}\[\]\/?.,;:|\)*~`!^\-_+<>@\#$%&\\\=\(\'\"]/gi}
                                        placeholder=""
                                        customOnChange={textFieldHandler}
                                        value={form}
                                    />
                                </div>
                                <div className="w-45 h-26px">
                                    <CustomTextField
                                        className="w-40"
                                        title="장소(호)"
                                        name="ho"
                                        required={false}
                                        disabled={form.classification==='INCIDENT'}
                                        validLen="15"
                                        //validRegExp={/[\{\}\[\]\/?.,;:|\)*~`!^\-_+<>@\#$%&\\\=\(\'\"]/gi}
                                        placeholder=""
                                        customOnChange={textFieldHandler}
                                        value={form}
                                    />
                                </div>

                            </div>
                            <div className="flex mb-3 w-90">
                                <label className="label_title">공개기간 *</label>
                                <div className="w-20 mr-16">
                                    <DatePicker
                                        dateFormat="yyyy-MM-dd"
                                        selected={startDate}
                                        onChange={date => {
                                            setStartDate(date);
                                        }}
                                        className="w-100 textField"
                                    />
                                </div>
                                ~
                                <div className="w-20 ml-16">
                                    <DatePicker
                                        dateFormat="yyyy-MM-dd"
                                        selected={endDate}
                                        onChange={date => {
                                            setEndDate(date);
                                        }}
                                        className="w-100 textField"
                                    />
                                </div>
                            </div>
                            <div className="flex w-90 mb-3">
                                <label className="label_title">사용유무</label>
                                <CustomRadioButton
                                    className="mr-70"
                                    title="사용"
                                    id="radioBtn1"
                                    name="publicYn"
                                    value={true}
                                    checked={form.publicYn}
                                    customOnChange={radioBtnHandler}
                                />
                                <CustomRadioButton
                                    title="사용안함"
                                    id="radioBtn2"
                                    name="publicYn"
                                    value={false}
                                    checked={!form.publicYn}
                                    customOnChange={radioBtnHandler}
                                />
                            </div>
                        </div>
                    </div>
                            <div className="w-45 h-100">
                            <div className="flex w-100">
                                <div className="w-90 h-100 mt-12 ml-100" style={{display:"block"}} >
                                    <div className="floatC" >
                                        <ImgUpload
                                            title={"공지사항 내용 이미지"}
                                            imgUpload={getImgFile}
                                            imgSize={"300px"}
                                            imgFile={form.imageDataUrl}
                                        />
                                    </div>
                                    <FileListWithDelete
                                        onUpdateFiles={updateFilesData}
                                        fileData={form.fileInfo}
                                    />
                                </div>
                        </div>
                    </div>
                </div>
                <div className="footer_btn h-10 mt-2">
                    <div className="">
                        <button className="w-160px h-30px addBtn mr-10" onClick={onSubmitHandler}>저장</button>
                        { registPage ? "" : <button className="w-160px h-30px removeBtn mr-10" onClick={onRemoveHandler}>삭제</button>}
                        <button className="w-160px h-30px cancelBtn" onClick={onCancelHandler}>취소</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default withRouter(NoticeRegist);
