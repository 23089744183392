import React, {useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom'
import {CustomTable, SearchInputBar} from 'components/molecules';
import store from 'scripts/store';
import Net from 'scripts/net';
import Common from 'scripts/common';
import {usePageAuth} from "scripts/hooks";

import classNames from "classnames";
const columnList = [
    {
        code: 'id',
        title: 'id',
        width: '8',
        hidden: true,
    },
    {
        code: 'site.name',
        title: '현장명',
        width: '11',
    },
    {
        code: 'username',
        title: '사용자 아이디',
        width: '9',
    },
    {
        code: 'freePassKey',
        title: '프리패스 키',
        width: '9',
    },
    {
        code: 'place.name',
        title: '장소 정보',
        width: '6',
    },
    {
        code: 'freePassDong',
        title: '동(프리패스)',
        width: '5',
    },
    {
        code: 'freePassHo',
        title: '호(프리패스)',
        width: '5',
    },
    {
        code: 'homeNet',
        title: '홈넷 인증 여부',
        width: '5',
    },
    {
        code: 'preferredParking1',
        title: '1 선호주차구역',
        width: '5',
    },
    {
        code: 'preferredParking2',
        title: '2 선호주차구역',
        width: '5',
    },
    {
        code: 'preferredParking3',
        title: '3 선호주차구역',
        width: '5',
    },
];

export const FreepassUserList = (props) => {
    // useHistory 선언
    const programAuth = usePageAuth(props.userInfo.authList[0], sessionStorage.getItem('menuId'));
    const user = props.userInfo;
    const [contentData, setContentData] = useState([]);
    const [checkedList, setCheckedList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [searchCategory, setSearchCategory] = useState([
        [
            [
                {code: '', name: '고객사 선택'}
            ]
        ],
        [
            [
                {code: '', name: '현장명 선택'}
            ]
        ],
    ]);

    const [searchState, setSearchState] = useState({
        currentPage: 0,
        rowPerPage: 10,
        searchText: '',
        searchText2: '',
        searchText3: '',
        searchCategory1: user.menuGroup === 'ADMIN' ? '' : user.customer && user.customer.code,
        searchCategory2: user.menuGroup === 'OPERATOR' ? user.site && user.site.code : '',
    });

    const [pageState, setPageState] = useState({
        totalElementsCnt: 0,
        drawElementsCnt: 0,
    });

    const [selectUserName, setSelectUserName] = useState('');

    useEffect(() => {
        store.openedPage = 'FreePass 인증 사용자 목록';
        getCategoryList('');

    }, [])

    useEffect(() => {
        loadData();

    }, [searchState.currentPage,
        searchState.searchCategory1,
        searchState.searchCategory2,
        searchState.searchText,
        searchState.searchText2,
        searchState.searchText3,
        searchState.searchText4,
    ]);

    const loadData = () => {
        setLoading(true);
        Net.getFreepassUserList(
            {
                currentPage: searchState.currentPage,
                rowPerPage: searchState.rowPerPage,
                username: searchState.searchText,
                freePassKey: searchState.searchText4,
                freePassDong: searchState.searchText2,
                freePassHo: searchState.searchText3,
                searchCategory1: searchState.searchCategory1,
                searchCategory2: searchState.searchCategory2,
            }, (response) => {
                setLoading(false);
                if (response.status === 200) {
                    setContentData(response.data.content.map((items)=>{
                        if(items.parkingIndex){
                            items.parkingIndex.forEach((x,i)=>{
                                const tempIndex = i+1;
                                if(x.enable){
                                    items['preferredParking'+tempIndex] = 'O';
                                }else{
                                    items['preferredParking'+tempIndex] = 'X';
                                }
                            })
                        }else{
                            items.preferredParking1 = 'X';
                            items.preferredParking2 = 'X';
                            items.preferredParking3 = 'X';
                        }
                        return items;
                    }));

                    setPageState({
                        totalElementsCnt: response.data.totalElements,
                        drawElementsCnt: response.data.numberOfElements,
                    });

                } else {
                    setContentData([]);
                    setPageState({
                        totalElementsCnt: 0,
                        drawElementsCnt: 0,
                    });
                }
            });
    }


    const getCategoryList = () => {
        if (user.menuGroup === 'ADMIN') {
            Common.getCustomerList((response1) => {
                Common.getSitesList(null, (response2) => {
                    setSearchCategory([response1, response2]);
                });
            });
        } else if (user.menuGroup === 'MANAGER') {
            Common.getSitesList(searchState.searchCategory1, (response2) => {
                setSearchCategory([response2]);
            });
        }
    }

    const getRelateCategory = (selectedCategory) => {
        if (selectedCategory === "reset") selectedCategory = "";
        Common.getSitesList(selectedCategory, (response2) => {
            setSearchCategory([
                searchCategory[0], // 고객사
                response2,         // 현장
            ]);
        });
    }

    const onClickRow = (e) => {
        setSelectUserName(e.username);
    }

    const onMovePage = (e) => {
        setSearchState({
            ...searchState,
            currentPage: e-1
        });
    }

    const onSubmitHandler = (e) => {
        if (user.menuGroup === 'ADMIN') {
            setSearchState({
                ...searchState,
                currentPage: 0,
                searchCategory1: e.selectedCategory[0],
                searchCategory2: e.selectedCategory[1],
                searchText: e.searchText,
                searchText2: e.searchText2,
                searchText3: e.searchText3,
                searchText4: e.searchText4,
            });
        } else if (user.menuGroup === 'MANAGER') {
            setSearchState({
                ...searchState,
                currentPage: 0,
                searchCategory1: searchState.searchCategory1,
                searchCategory2: e.selectedCategory[0],
                searchText: e.searchText,
                searchText2: e.searchText2,
                searchText3: e.searchText3,
                searchText4: e.searchText4,
            });
        } else {
            setSearchState({
                ...searchState,
                currentPage: 0,
                searchCategory1: searchState.searchCategory1,
                searchCategory2: searchState.searchCategory2,
                searchText: e.searchText,
                searchText2: e.searchText2,
                searchText3: e.searchText3,
                searchText4: e.searchText4,
            });
        }
    }

    const removeList = () => {
        let result = window.confirm('FreePass 인증을 해지 하시겠습니까?');
        const removeList = [];
        checkedList.map((id) => {
            const node = document.querySelector(`#${id}`);
            const nodeTr = node.closest('tr');
            const username = nodeTr.querySelector('td[name=username]').textContent;
            removeList.push({username: username});
        });
        if(result){
            Net.logoutFreePassAuthList(removeList, response => {
                if(response){
                    loadData();
                }
            });
        }
    }

    return (
        <div className="w-100 h-100">
            <div className="w-100 h-7 display_table">
                <SearchInputBar
                    className="w-100"
                    role={user.menuGroup}
                    searchCategory={user.menuGroup === 'ADMIN' || user.menuGroup === 'MANAGER'}
                    searchCategoryGroup={searchCategory}
                    searchText={true}
                    searchText2={true}
                    searchText3={true}
                    searchText4={true}
                    searchPlaceHolder="사용자아이디 입력하세요."
                    searchPlaceHolder2="동 입력하세요."
                    searchPlaceHolder3="호 입력하세요."
                    searchPlaceHolder4="프리패스 키를 입력하세요."
                    searchBtn={true}
                    searchBtnTitle="검색"
                    dynamicSearch={false}
                    relateCategoryHandler={getRelateCategory}
                    onSubmit={onSubmitHandler}
                    refreshBtn={true}
                    refreshHandler={loadData}
                />
            </div>
            <div className="w-100 h-1px bgC-efefef"/>
            <div className="w-100 h-100">
                <div className="flex justify-content-between">
                    <p className={classNames("totalCnt")}>Total {pageState.totalElementsCnt}</p>
                    <button
                        className="cancelBtn w-150px h-30px floatR mt-15 mr-25"
                        onClick={removeList}
                        disabled={checkedList.length === 0}
                    >FreePass 인증해지</button>
                </div>

                <CustomTable
                    columnList={columnList}
                    contentData={contentData}
                    paging={true}
                    checkbox={programAuth.deleteAuth}
                    checkedList={(checkedList) => {
                        setCheckedList(checkedList)
                    }}
                    totalElementsCnt={pageState.totalElementsCnt}
                    rowPerPage={searchState.rowPerPage}
                    currentPage={searchState.currentPage}
                    drawElementsCnt={pageState.drawElementsCnt}
                    pagination={onMovePage}
                    rowClickEvt={true}
                    onClickRow={onClickRow}
                    loading={loading}
                    // sort = {true}
                />
            </div>
        </div>
    )
}
