import React, { useEffect, useState} from 'react';
import Net from 'scripts/Service/Guide/net';
import logo from 'pages/ServicePage/Visitor/image/logoHanulchaeWhite@3x.png';
import './GuideSite.scss';
import {Link} from "react-router-dom";
import { LazyImg} from 'components/molecules';

import {
    BiSearch, MdOutlineCancel,
} from "react-icons/all";
import {SearchSiteList} from "./Component/SearchSiteList";
export const GuideSite = () => {

    const [input, setInput] = useState('');
    const [siteList, setSiteList] = useState([]);

    useEffect(()=>{
        /*
            현장 조회
        * */
        async function mounted(){
            const response = await Net.getGuideSitesListAsync();
            setSiteList(response.data.content)
        }
        mounted();

    },[]);


    const nextPage = ()=>{
        //history.push('/service/guide/homenet');
    }

    const backPage = ()=>{
        //history.push('/service/guide/main');
    }

    const onChange = (e) =>{
        setInput(e.target.value);
    }

    const onReset = (e) => {
        setInput('');
    }

    return (
        <div className="page-guide">
            <div className="text-center guideSite">
                <div className="header-fixed">
                    <div className="d-table w-100 mt-5" >
                        <div className="col-left">
                            <img src={logo}
                                 className="hanulche"
                                 alt="logo"/>
                        </div>

                        <div className="text-header">
                            <a href={"http://m.site.naver.com/10ZKb"} target='_blank' aria-label={`앱 다운로드`}>
                                <button className="blue-button floatR">앱 다운로드</button>
                            </a>
                        </div>
                    </div>

                    <div className="main-area mt-5">
                        <div className="">
                            <p className="white-header"> 현장 선택</p>
                            <div className="mt-4 mb-4 text-area">
                                <div className="input ms-1 ">
                                    <BiSearch messagesize="48" color="White" className="hoverbtn"/>
                                    <input
                                        id="siteInput"
                                        onChange={onChange}
                                        value={input}
                                        className="input-field me-1 ms-2"
                                        placeholder="아파트 단지, 지역을 입력해주세요."
                                        aria-label="아파트 단지, 지역을 입력해주세요."
                                    />
                                    <MdOutlineCancel
                                        onClick={onReset}
                                        messagesize="48"
                                        color="White"
                                        className="hoverbtn"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {
                    input ? <div className="main-site-area-text">
                            {
                                siteList.map(site => {
                                    return <Link key={"link"+site.boardId}
                                                 to={{
                                                     pathname : '/service/guide/homenet',
                                                     state : {
                                                         site : site
                                                     }
                                                 }} >
                                        <SearchSiteList id={site.boardId} title={site.title} input={input}
                                                        url={site.attribute1}></SearchSiteList></Link>

                                })
                            }
                        </div> :
                        <div className="main-site-area pb-2 w-100 flex flex-wrap justify-content-center h-100">
                            {
                                siteList.map(site => {
                                    return (
                                        <div key={site.boardId}
                                             className="p-2 w-100 hoverbtn">
                                            <Link key={"link"+site.boardId}
                                                  to={{
                                                      pathname : '/service/guide/homenet',
                                                      state : {
                                                          site : site
                                                      }
                                                  }}>
                                                <LazyImg
                                                    src={site.image ? site.image[0] : 'img'}
                                                    alt="site.title"
                                                />
                                                <p className="mt-1 white-header">{site.title}</p>
                                            </Link>
                                        </div>
                                    )
                                })
                            }
                        </div>
                }
            </div>
        </div>
    )
}
