import React, {Fragment, useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom'
import {CustomTable, SearchInputBar, CustomCheckbox, BasicModal} from 'components/molecules';
import store from 'scripts/store';
import Net from 'scripts/net';
import Common from 'scripts/common';
import {usePageAuth, useDidMountEffect } from "scripts/hooks";
import {CustomSelectBox, TestExcelDownload} from "../../../components/molecules";
import {TestRecordList} from "../exhaustiveTest/modal/TestRecordList";

const excelColumnList = [
    {
        code: 'dong',
        title: '동',
        width: '3',
    },
    {
        code: 'line',
        title: '라인',
        width: '3',
    },
    {
        code: 'ho',
        title: '호',
        width: '3',
    },
    {
        code: 'checkName',
        title: '테스트 성공 여부',
        width: '6',
    },
    {
        code: 'lastTest',
        title: '비고',
        width: '6',
    },
    {
        code: 'performer',
        title: '테스트 수행자',
        width: '6',
    },
    {
        code: 'devices',
        title: '',
        width: '6',
    },
]

const columnList = [
    {
        code: 'dong',
        title: '동',
        width: '3',
    },
    {
        code: 'ho',
        title: '호',
        width: '3',
    },
    {
        code: 'check',
        title: '테스트 성공 여부',
        width: '6',
    },
    {
        code: 'performer',
        title: '수행자',
        width: '6',
    },
    {
        code: '',
        title: '테스트 기록 조회',
        width: '4',
        tdClickEvent: true,
    },
];

const deviceColumnList = [
    {
        code : 'deviceId',
        title: '디바이스ID',
        width: '10',
    },
    {
        code : 'name',
        title: '장비명',
        width: '6',
    },
    {
        code : 'note',
        title: '비고',
        width: '20',
    },
];

export const ExhaustiveTestMonitoring = (props) => {

    const history = useHistory();
    const user = props.userInfo;
    const testClassification = [
        {
            code : 'notTrue',
            name : '이슈 세대'
        },
        {
            code : 'total',
            name : '전체 세대'
        },
        {
            code : 'false',
            name : '실패 세대'
        },

        {
            code : 'true',
            name : '성공 세대'
        },
        {
            code : 'check',
            name : '보류 세대'
        },
        {
            code : 'etc',
            name : '기타 세대'
        },
        {
            code : 'notTested',
            name : '미 테스트'
        }
    ]

    const programAuth = usePageAuth(props.userInfo.authList[0], sessionStorage.getItem('menuId'));
    const [contentData, setContentData] = useState([]);
    const [notTestedData, setNotTestedData] = useState([]);
    const [notTrueData, setNotTrueData] = useState([]);
    const [trueData, setTrueData] = useState([]);
    const [falseData, setFalseData] = useState([]);
    const [checkData, setCheckData] = useState([]);
    const [etcData, setEtcData] = useState([]);

    const [displayData, setDisplayData] = useState([]);

    const [deviceList, setDeviceList] = useState([]);
    const [recordDetail, setRecordDetail] = useState('');

    const [selectedSiteName, setSelectedSiteName] = useState('');
    const [selectedDongName, setSelectedDongName] = useState('');
    const [numberOfHousehold, setNumberOfHousehole] = useState(0);
    const [displayTrueNum, setDisplayTrueNum] = useState(0);
    const [displayFalseNum, setDisplayFalseNum] = useState(0);
    const [displayCheckNum, setDisplayCheckNum] = useState(0);
    const [displayEtcNum, setDisplayEtcNum] = useState(0);

    const [contentData4Excel, setContentData4Excel] = useState([]);

    const [searchCategory, setSearchCategory] = useState([
        [
            {code: '', name: '고객사 선택'}
        ],
        [
            {code: '', name: '현장명 선택'}
        ],
        [
            {code: '', name: '동 선택'}
        ],
    ]);


    const [pageState, setPageState] = useState({
        searchCategory1: '',
        searchCategory2:  '',
        searchCategory3: '',
        searchText: '',
        searchText2: '',
    });

    const [modalState, setModalState] = useState({
        open: false,
        data: [],
        clickedBtnName: '',
    });

    const [form, setForm] = useState('');

    useDidMountEffect(async () => {
        await loadData();
    }, [pageState]);

    useDidMountEffect( () => {
        Net.getSiteOne(selectedSiteName, result => {
            if (result.status === 200) {
                setNumberOfHousehole(result.data.numberOfHouseHold)
            }
        })
    },[selectedSiteName])

    useEffect(() => {
        store.openedPage = '전수 테스트 현황';
        getCategoryList('');
    }, [])


    useEffect(()=>{
        if(selectedSiteName !== ''){
            const siteName = window.SITE_LIST ? (window.SITE_LIST[selectedSiteName].hasOwnProperty('name') ? window.SITE_LIST[selectedSiteName].name : '') :'';
            store.openedPage = '전수 테스트 현황_'+ siteName;
        }
    },[selectedSiteName])

    useEffect(()=>{
        const displayData_ = displayData.map(item => {
            let tempString = '';
            item.lineString = item.ho.slice(-2);
            item.line = item.lineString.replace(/(^0+)/,"")+"라인";

            if(item.check === 'true') tempString = '성공';
            else if(item.check ==='false') tempString ='실패';
            else if(item.check ==='check') tempString ='보류';
            else if(item.check ==='etc') tempString ='기타';

            item.checkName = tempString;
            if(item.hasOwnProperty('lastCheck') && item.lastCheck.hasOwnProperty('note')) item.lastTest = item.lastCheck.note.replace(/<[^>]+>/g, '');

            return item;
        });

        const sortData1 = displayData_.sort(function (a, b) {
            if(a.lineString > b.lineString ) return 1;
            if(a.lineString === b.lineString ) return 0;
            if(a.lineString < b.lineString ) return -1;
        });

        const sortData2 = sortData1.sort(function(a,b) {
            if(a.dong > b.dong) return 1;
            if(a.dong === b.dong) return 0;
            if(a.dong < b.dong) return -1;
        })

        setContentData4Excel(sortData2);
    },[displayData]);

    useEffect(()=>{
    },[contentData4Excel])

    useEffect(()=>{
        setDisplayData(contentData);
        selectBoxHandler('testClassification', '');
    },[contentData])

    useEffect(()=>{
        if(form === 'true'){
            setDisplayData(trueData);
        }
        else if(form === 'false'){
            setDisplayData(falseData);
        }
        else if(form === 'check'){
            setDisplayData(checkData);
        }else if(form === 'etc'){
            setDisplayData(etcData);
        }else if(form === 'notTested') {
            setDisplayData(notTestedData);
        } else if(form==='notTrue'){
            setDisplayData(notTrueData);
        } else{
            setDisplayData(contentData);
        }
    },[form])

    useEffect(()=>{
        setDisplayData(notTrueData);
    },[notTrueData])

    const onClickRow = (e) => {
        setDeviceList(e.devices);
        setRecordDetail(e.note);
    }

    const loadData = async () => {
        const response = await Net.getHoListForCheckListBySiteAndDongAsync(pageState.searchCategory2,
            pageState.searchCategory3,
            pageState.searchText,
            pageState.searchText2)

        let trueNum = 0;
        let falseNum = 0;
        let checkNum = 0;
        let etcNum = 0;
        let notTestedArray = [];
        let notTrueArray = [];
        let trueArray = [];
        let falseArray = [];
        let checkArray = [];
        let etcArray = [];

        let temp = response.data.sort(function(a ,b){
            return a.ho-b.ho;
        });

        temp = temp.sort(function(a,b){
            return a.dong < b.dong ? -1 : a.dong > b.dong ? 1 : 0;
        })

        if(temp.hasOwnProperty('lastCheck')){
            temp.check = temp.lastCheck.check;
            temp.description = temp.lastCheck.note;
        }

        await setContentData(temp.map(item =>{
            if(item.hasOwnProperty('lastCheck')){
                item.check = item.lastCheck.check;
                item.performer = item.lastCheck.performer;
                item.devices = item.lastCheck.devices;
                item.note = item.lastCheck.note;
                if(item.lastCheck.check === 'true'){
                    trueNum++;
                    trueArray.push(item);
                } else if(item.lastCheck.check === 'false'){
                    falseNum++;
                    falseArray.push(item);
                    notTrueArray.push(item);
                }else if (item.lastCheck.check === 'check'){
                    checkNum++;
                    checkArray.push(item);
                    notTrueArray.push(item);
                }else if (item.lastCheck.check ==='etc'){
                    etcNum++;
                    etcArray.push(item);
                    notTrueArray.push(item);
                }
            }else{
                item.check = ''
                item.performer = ''
                item.devices = []
                item.note = ''
                notTestedArray.push(item);
            }
            return item;
        }));

        await setNotTestedData(notTestedArray);
        await setTrueData(trueArray);
        await setFalseData(falseArray);
        await setCheckData(checkArray);
        await setEtcData(etcArray);
        await setNotTrueData(notTrueArray);

        setDisplayTrueNum(trueNum);
        setDisplayFalseNum(falseNum);
        setDisplayCheckNum(checkNum);
        setDisplayEtcNum(etcNum);
    }

    const getCategoryList = () => {
        Common.getCustomerList((response)=>{
            let responseData = [];
            if(user.menuGroup == 'OPERATOR'){
                responseData = response.filter(x => {
                    if((x.code === "") || (x.code === user.customer.code )){
                        return x;
                    }
                });
            }else if(user.menuGroup == 'OPERATOR_TEST') {
                responseData = response.filter(x => {
                    if((x.code === "") || (x.code === user.customer.code )){
                        return x;
                    }
                });
            }
            else {
                responseData = response;
            }
            setSearchCategory([responseData,searchCategory[1],searchCategory[2]]);
        });
    }

    const getRelateCategory = (selectedCategory) => {
        if (selectedCategory === "reset") selectedCategory = "";
        Common.getSitesList(selectedCategory, (response2) => {
            let responseData = [];
            if (user.menuGroup === 'OPERATOR') {
                responseData = response2.filter(x => {
                    if ((x.code === "") || (x.code === user.site.code)) {
                        return x;
                    }
                });
            } else if( user.menuGroup === 'OPERATOR_TEST'){
                responseData = response2.filter(x => {
                    if ((x.code === "") || (x.code === user.site.code)) {
                        return x;
                    }
                });
            }else {
                responseData = response2;
            }

            setSearchCategory([
                searchCategory[0],
                responseData,
                searchCategory[2]
            ]);
        });
    }

    const getRelateSiteCategory = (selectedCategory) => {
        if (selectedCategory === "reset") selectedCategory = "";
        Common.getDongListBySiteCode(selectedCategory, response => {
            setSearchCategory([
                searchCategory[0],
                searchCategory[1],
                response.data
            ])
        });
    }

    const onSubmitHandler = async (e) => {
        if(e.selectedCategory[0] === ''){
            alert("고객사를 선택해주세요.");
            return false;
        }
        else if(e.selectedCategory[1] === ''){
            alert("현장을 선택해주세요.");
            return false;
        }

        setSelectedSiteName(e.selectedCategory[1])
        setSelectedDongName(e.selectedCategory[2]);
        setPageState({
            searchCategory1 : e.selectedCategory[0],
            searchCategory2 : e.selectedCategory[1],
            searchCategory3 : e.selectedCategory[2],
            searchText : e.searchText,
            searchText2 : e.searchText2,
            onlyTemporary: pageState.onlyTemporary
        })
    }

    const selectBoxHandler = (name, value) => {
        switch (name) {
            case 'testClassification':
                setForm(value);
                break;
            default:
                break;
        }
    }

    const tdOnClickEvent = (title, e) => {
        setModalState({
            ...modalState,
            open: !modalState.open,
            propsData: {
                siteCode : selectedSiteName,
                dong : e.dong,
                ho : e.ho
            },
            clickedBtnName: title
        });
    }

    return (
        <div className="w-100 h-100">
            <BasicModal
                className="w-80 h-88"
                title={`${modalState.clickedBtnName} 목록`}
                content={
                    <ModalContent
                        modalType={modalState.clickedBtnName}
                        propsData={modalState.propsData}
                        modalClose={(e) => setModalState({
                            ...modalState,
                            open: e
                        })}
                    />
                }
                modalOpen={modalState.open}
                modalClose={() => setModalState({
                    ...modalState,
                    open: false
                })}
                sendData={modalState.data}
            />
            <div className="w-100 h-1px bgC-efefef"/>
            <div className="h-93 row">
                <h5 className="floatL mt-1p mb-1p ml-2p"></h5>
                <div className="row">
                <SearchInputBar
                    className="col-9 flex"
                    searchCategory={true}
                    searchCategoryGroup={searchCategory}
                    searchText={true}
                    searchPlaceHolder="호"
                    searchBtn={true}
                    searchBtnTitle="검색"
                    dynamicSearch={false}
                    relateCategoryHandler={getRelateCategory}
                    relateSiteCategoryHandler={getRelateSiteCategory}
                    onSubmit={onSubmitHandler}
                />
                <TestExcelDownload
                    classNameDiv="col-3 flex j-c-r"
                    classNameButton="downloadBtn w-120px h-30px mr-2"
                    sheetTitle="device_history_list"
                    columnList={excelColumnList}
                    contentData4Excel={contentData4Excel}
                    pageState={pageState}
                />
                </div>


                <div className="w-97 mt-2">
                    <div className="row">
                        <div className="col-4 d-inline-block">
                            <h5 className="ml-25 mt-10 "> 현장 전체 세대 : {numberOfHousehold}

                            </h5>

                        </div>
                        <div className="col-2 flex justify-content-end">
                            <h5>
                                <CustomSelectBox
                                    name="testClassification"
                                    className="mb-1 "
                                    categoryList={testClassification}
                                    value={form}
                                    onChangeHandler={(value) => selectBoxHandler('testClassification', value)}
                                />
                            </h5>
                        </div>
                        <div className="col-2 ml-30 mt-10">
                            <h5 className=""> 디바이스 목록 </h5>
                        </div>
                    </div>

                    <div className="flex justify-content-between">
                        <div className="col-6">
                            <CustomTable
                                columnList={columnList}
                                contentData={displayData}
                                totalElementsCnt={contentData.length}
                                rowPerPage={999999}
                                currentPage={0}
                                tdClickEvt={tdOnClickEvent}
                                rowClickEvt={true}
                                onClickRow={onClickRow}
                            />
                            <h6 className="mt-2 text-align-right mr-20">
                                <span style={{color: 'rgb(17,17,17)'}}> 전체 : </span>{ contentData.length } &ensp;
                                <span style={{color: 'rgb(85,139,220)'}}>{' '} 성공 : {displayTrueNum} &ensp;</span>
                                <span style={{color: 'rgb(235,135,140)'}}>{' '}실패 : {displayFalseNum} &ensp;</span>
                                <span style={{color: 'rgb(234,206,133)'}}>{' '}보류 : {displayCheckNum} &ensp;</span>
                                <span style={{color: 'rgb(50,50,50)'}}>{' '}기타 : {displayEtcNum} &ensp;</span>
                                <span style={{color: 'rgb(14,14,13)'}}>{' '}미 테스트 : {contentData.length - displayTrueNum - displayCheckNum - displayFalseNum - displayEtcNum} </span>
                            </h6>
                        </div>
                        <div className="col-6 ml-30">
                            <div className="overflow-hidden h-30vh">
                                <CustomTable
                                    className={"w-100 h-100 overFlowYscrollView"}
                                    columnList={deviceColumnList}
                                    contentData={deviceList}
                                    totalElementsCnt={deviceList.length}
                                    rowPerPage={999999}
                                    currentPage={0}
                                    />
                            </div>
                            <h5 className="m-2 ml-5"> 비고 상세내용</h5>
                            <table className="mainTable">
                                <thead className="tableHead">
                                <tr>
                                    <th>내용</th>
                                </tr>
                                </thead>
                                <tbody className="tableBody">
                                {
                                    recordDetail !== '' ?
                                        (
                                            <Fragment>
                                                <tr>
                                                    <td style={{padding: "10px", overflow: "unset", whiteSpace: "initial"}}>
                                                        <div className="overFlowYscrollView h-20vh w-90">
                                                            <div dangerouslySetInnerHTML={{__html: recordDetail}}/>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </Fragment>
                                        ) : (
                                            <tr>
                                                <td>조회 된 데이터가 없습니다.</td>
                                            </tr>
                                        )
                                }
                                </tbody>
                            </table>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}


export const ModalContent = (props) => {
    switch (props.modalType) {
        case "테스트 기록 조회":
            return <TestRecordList data={props.propsData} modalClose={props.modalClose}/>
            break;
        default:
            break;
    }
}
