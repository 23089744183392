import React, { useState } from 'react'
import { BasicModal } from 'components/molecules';
import queryString from 'query-string';




const ChangePassword = (props) => {

    // get query id
    const search = props.location.search;
    const query = queryString.parse(search);

    // useState 선언
    const [id, setid] = useState(query.id);
    const [pw, setpw] = useState('');
    const [repw, setrepw] = useState('');
    const [needChkInfo, setneedChkInfo] = useState(false);
    const [modalTitle, setmodalTitle] = useState('');
    const [modalContent, setmodalContent] = useState('');


    // changePassword Event
    const handleSignUp = () => {

        setmodalTitle('알림');
        if(pw === '') {
            setmodalContent('비밀번호를 입력해주세요');
            setneedChkInfo(true);
            return
        }
        if(!validPw(pw)) {
            setmodalContent('잘못된 비밀번호 형식입니다.');
            setneedChkInfo(true);
            return
        }

        if(repw === '') {
            setmodalContent('비밀번호를 한번 더 입력해주세요');
            setneedChkInfo(true);
            return
        }
        if(pw !== repw) {
            setmodalContent('비밀번호가 일치하지 않습니다.');
            setneedChkInfo(true);

        }

    }


    // Modal popup status lifting Event
    const handleClick = (popupStatus) => {
        setneedChkInfo(popupStatus);
    }

    return (
        // <Container component="main" maxWidth="sm">
        //     <CssBaseline />
        //     <div className={classes.paper}>
        //         <TableContainer>
        //             <Table>
        //                 <TableBody>
        //                     <TableRow >
        //                         <TableCell style={b_hidden}>아이디</TableCell>
        //                         <TableCell padding="none" style={b_hidden}>
        //                             <TextField
        //                                 variant="outlined"
        //                                 margin="normal"
        //                                 required
        //                                 fullWidth
        //                                 id="email"
        //                                 // label="Email Address"
        //                                 name="email"
        //                                 autoComplete="email"
        //                                 autoFocus
        //                                 defaultValue = {id}
        //                                 onChange={(e) => {setid(e.target.value)}}
        //                             />
        //                         </TableCell>
        //                     </TableRow>
        //                     <TableRow >
        //                         <TableCell style={b_hidden}>새 비밀번호</TableCell>
        //                         <TableCell padding="none" style={b_hidden}>
        //                             <TextField
        //                                 variant="outlined"
        //                                 margin="normal"
        //                                 required
        //                                 fullWidth
        //                                 name="password"
        //                                 label="영문, 숫자, 특수문자 조합(9~16자리)"
        //                                 type="password"
        //                                 id="password"
        //                                 onChange={(e) => {setpw(e.target.value)}}
        //                             />
        //                         </TableCell>
        //                     </TableRow>
        //                     <TableRow >
        //                         <TableCell style={b_hidden}>새 비밀번호(확인)</TableCell>
        //                         <TableCell padding="none" style={b_hidden}>
        //                             <TextField
        //                                 variant="outlined"
        //                                 margin="normal"
        //                                 required
        //                                 fullWidth
        //                                 name="chkedpassword"
        //                                 label="영문, 숫자, 특수문자 조합(9~16자리)"
        //                                 type="password"
        //                                 id="chkedpassword"
        //                                 onChange={(e) => {setrepw(e.target.value)}}
        //                             />
        //                         </TableCell>
        //                     </TableRow>
        //                 </TableBody>
        //             </Table>
        //         </TableContainer>
        //         <div style={alighFooterBtn}>
        //             <Button
        //                 type="submit"
        //                 size="large"
        //                 variant="contained"
        //                 color="primary"
        //                 onClick={handleSignUp}
        //             >확인</Button>
        //             <Link href={"/login"} >
        //                 <Button
        //                     type="button"
        //                     size="large"
        //                     variant="contained"
        //                     color="primary"
        //                 >나중에</Button>
        //             </Link>
        //         </div>
        //     </div>
        //     {
        //         needChkInfo &&
        //         <BasicModal
        //             title={modalTitle}
        //             content={modalContent}
        //             modalOpen={handleClick}
        //         />
        //     }
        // </Container>

        // <div className="login_bg">
        //     <p className="login_title">비밀번호 변경</p>
        //     <div className="login_content_area">
        //         <div className="login_content_wrapper">
        //             <p className="fs-14 fontC-555 fontW-500 mb-14">새 비밀번호</p>
        //             <input
        //                 type="password"
        //                 name="newPassword"
        //                 className="input_style mb-19"
        //                 value={newPwd}
        //                 onChange={(e)=> {
        //                     setNewPwd(e.target.value);
        //                     if(needChkInfo) setneedChkInfo(false);
        //                 }}
        //             />
        //             <p className="fs-14 fontC-555 fontW-500 mb-14">새 비밀번호(확인)</p>
        //             <input
        //                 type="password"
        //                 name="checkPassword"
        //                 className="input_style mb-10"
        //                 value={chkPwd}
        //                 onChange={(e)=> {
        //                     setChkPwd(e.target.value);
        //                     if(needChkInfo) setneedChkInfo(false);
        //                 }}
        //             />
        //             <div className="login_noti_area">
        //                 {needChkInfo &&
        //                     (<>
        //                         <p>아이디와 비밀번호가 일치하지 않습니다.</p>
        //                         <p>확인 후 다시 로그인해 주세요.  </p>
        //                     </>)
        //                 }
        //             </div>

        //             <button className="login_btn" onClick={handleLogin}>저장</button>
        //             <button className="login_btn" onClick={handleLogin}>취소</button>

        //         </div>
        //     </div>
        //     <Footer className="mr-3p"/>
        // </div>
        'changepassword'
    )
}

function validPw(value) {

    let check_len = false;
    if(value.length >= 9 && value.length <= 16) check_len = true;

    const check_num = /[0-9]/; // 숫자
    const check_eng = /[a-zA-Z]/; // 문자
    const check_spc = /[~!@#$%^&*()_+|<>?:{}]/; // 특수문자

    return check_len && check_num.test(value) && check_eng.test(value) && check_spc.test(value);

}


export default ChangePassword
