import React from 'react'
import { Switch, Route } from 'react-router-dom';
import {HomeNetList, HomeNetRegist} from "./HomeNet";
import NotFound from "../../components/templates/NotFound";
import {VoiceRecognitionSettingList} from "./VoiceRecognition";
import {ApplianceSettingList} from "./ApplianceSetting";

export const ThirdPartyManagePage = ({userInfo}) => {
    return (
        <Switch>
            <Route path={"/thirdparty/homenet/list"} exact children={<HomeNetList userInfo={userInfo}/>}/>
            <Route path={"/thirdparty/homenet/regist"} exact children={<HomeNetRegist />} />
            <Route path={"/thirdparty/homenet/regist/:code"} children={<HomeNetRegist />} />
            <Route path={"/thirdparty/voicesetting/list"} exact children={<VoiceRecognitionSettingList userInfo={userInfo}/>}/>
            <Route path={"/thirdparty/appliancesetting/list"} exact children={<ApplianceSettingList userInfo={userInfo}/>}/>
            <Route path={"*"}>
                <NotFound />
            </Route>
        </Switch>
    )
}
