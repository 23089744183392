import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { CustomTable, SearchInputBar } from 'components/molecules';
import store from 'scripts/store';
import Net from 'scripts/net';

const columnList = [
    {
        code: 'code',
        title: '',
        width: '',
        hidden: true,
    },
    {
        code: 'name',
        title: '프로그램명',
        width: '15',
    },
    {
        code: 'path',
        title: '경로',
        width: '20',
    },
    {
        code: 'authCheck',
        title: '권한체크 여부',
        width: '10',
    },
    {
        code: 'searchAuth',
        title: '조회',
        width: '10',
    },
    {
        code: 'saveAuth',
        title: '저장',
        width: '10',
    },
    {
        code: 'excelAuth',
        title: '엑셀',
        width: '10',
    },
    {
        code: 'deleteAuth',
        title: '삭제',
        width: '10',
    },
    {
        code: 'remark',
        title: '비고',
        width: '13',
    },
];

export const ProgramManageList = (props) => {

    // useHistory 선언
    const history = useHistory();

    const [contentData, setContentData] = useState([]);
    const [checkedList, setCheckedList] = useState([]);
    const [checkedList2, setCheckedList2] = useState([]);
    const [isEditMode, setIsEditMode] = useState(false);

    const [pageState, setPageState] = useState({
        currentPage: 0,
        rowPerPage: 9999,
        totalElementsCnt: 0,
        drawElementsCnt: 0,
        searchText: '',
    });

    const [programAuth, setProgramAuth] = useState({});
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        store.openedPage = '프로그램 관리';
        pageAuthInfo();
    }, []);

    useEffect(() => {
        loadData();
    }, [
        pageState.currentPage,
        pageState.searchText,
    ]);

    useEffect(() => {
        contentData.map((data, index) => {
            data.authCheck = false;
            data.searchAuth = false;
            data.saveAuth = false;
            data.excelAuth = false;
            data.deleteAuth = false;
            checkedList2.map(checkedId => {
                const code = checkedId.split('_')[0];
                const Idx = checkedId.split('_')[1];
                if(parseInt(Idx) === index) {
                    switch (code) {
                        case 'authCheck':
                        case 'searchAuth':
                        case 'saveAuth':
                        case 'excelAuth':
                        case 'deleteAuth':
                            data[code] = true;
                            break;

                        default:
                            break;
                    }
                }
            });
        });
    }, [checkedList2])

    const pageAuthInfo = () => {
        const authGroupCode = props.userInfo.authList[0];
        const menuId = sessionStorage.getItem('menuId');

        Net.getAuthGroupMenuList(menuId, (response) => {
            if(response.status === 200) {
                response.data.forEach(item => {
                    if(item.authGroup === authGroupCode && item.authGroupMenu) {
                        setProgramAuth({
                            searchAuth: Boolean(item.authGroupMenu.searchAuth),
                            saveAuth: Boolean(item.authGroupMenu.saveAuth),
                            excelAuth: Boolean(item.authGroupMenu.excelAuth),
                            deleteAuth: Boolean(item.authGroupMenu.deleteAuth),
                        });
                    }
                });
            }
        });
    }

    const loadData = () => {
        setLoading(true);
        Net.getProgramList(pageState, (response) => {
            setLoading(false);
            if(response.status === 200) {
                setContentData(response.data.content);
                setPageState({
                    ...pageState,
                    totalElementsCnt: response.data.totalElements,
                    drawElementsCnt: response.data.numberOfElements,
                });
            } else if(response.status === 204) {
                setContentData([]);
                setPageState({
                    ...pageState,
                    totalElementsCnt: 0,
                    drawElementsCnt: 0,
                });
            }
        });
    }

    const cancelEditHandler = () => {
        setIsEditMode(!isEditMode);
        loadData();
    }

    const editHandler = () => {
        if(isEditMode) {
            onSaveHandler();
        }
        setIsEditMode(!isEditMode);
    }

    const onSaveHandler = () => {

        const addTargetList = [];
        const updateTargetList = [];
        contentData.map((list, index) => {
            if(list.name === '') {
                alert('프로그램명을 입력하세요.')
                return false;
            }
            if(list.code !== '') {
                updateTargetList.push(list);
            } else {
                addTargetList.push(list);
            }
        });


        addProgram(addTargetList);
        updateProgram(updateTargetList);
    }

    const addProgram = (targetList) => {
        Net.addProgram(targetList, (response) => {
            if(response) {
                loadData();
            }
        });
    }

    const updateProgram = (targetList) => {
        Net.updateProgram(targetList, (response) => {
            if(response) {
                loadData();
            }
        });
    }

    const addRow = () => {
        setContentData(
            contentData.concat(
                {
                    code: "",
                    name: "",
                    path: "",
                    authCheck: false,
                    searchAuth: false,
                    saveAuth: false,
                    excelAuth: false,
                    deleteAuth: false,
                    remark:"",
                }
            )
        );
    }

    const removeRow = () => {

        if(isEditMode) {

            const removeTargetIdxList = [];
            checkedList.forEach(checkedItem => {
                const rowIdx = checkedItem.split('_')[1];
                removeTargetIdxList.push(rowIdx);
            });

            // contentData 복사
            const copiedArray = contentData.slice();

            // 선택한 순서가 불규칙하여 rowIdx에 맞춰 역정렬
            removeTargetIdxList.sort().reverse().map(rowIndex => {
                // contentData에서 삭제 할 row 삭제
                copiedArray.splice(rowIndex, 1);
            });
            setContentData([...copiedArray]);

        } else {
            const result = window.confirm('삭제하시겠습니까?');

            const removeCodeList = [];
            checkedList.forEach((id) => {
                const node = document.querySelector(`#${id}`);
                const nodeTr = node.closest('tr');
                const codeValue = nodeTr.querySelector('td[name=code]').textContent;
                if(codeValue !== "") {
                    removeCodeList.push(codeValue);
                }
            });

            if(result) {
                Net.removeProgramList(removeCodeList, (response) => {
                    if(response) {
                        loadData();
                    }
                });
            }
        }
    }

    const onSubmitHandler = (e) => {
        setPageState({
            ...pageState,
            currentPage: 0,
            searchText: e.searchText,
        });
    }

    return (
        <div className="w-100 h-100">
            <div className="w-100 h-7 display_table">
                <SearchInputBar
                    className="w-100"
                    searchCategory={false}
                    searchCategoryGroup=""
                    searchText={true}
                    searchPlaceHolder="프로그램명을 입력해 주세요."
                    searchBtn={true}
                    searchBtnTitle="검색"
                    dynamicSearch={false}
                    onSubmit={onSubmitHandler}
                    refreshBtn={true}
                    refreshHandler={loadData}
                />
                <div className="flex h-100">
                    { isEditMode &&
                        <button
                            className="cancelBtn w-90px h-30px floatR mt-auto mb-auto mr-10"
                            onClick={cancelEditHandler}
                        >편집취소</button>
                    }
                    {
                        programAuth.saveAuth &&
                        <button
                            className="addBtn w-90px h-30px floatR mt-auto mb-auto mr-20"
                            onClick={editHandler}
                        >{isEditMode ? "저장" : "편집"}</button>
                    }
                </div>
            </div>
            <div className="w-100 h-1px bgC-efefef" />
            <div className="flex">
                <div className="w-100 h-93">
                    <p className="totalCnt">Total {pageState.totalElementsCnt}</p>
                    {
                        isEditMode &&
                        <button
                            className="addBtn w-90px h-30px floatR mt-7 mr-20"
                            onClick={addRow}
                        >추가</button>
                    }
                    {
                        programAuth.deleteAuth && checkedList.length > 0 &&
                        <button
                            className="removeBtn w-90px h-30px floatR mt-7 mr-20"
                            onClick={removeRow}
                        >삭제</button>
                    }
                    <CustomTable
                        columnList = {columnList}
                        contentData = {contentData}
                        checkbox = {programAuth.deleteAuth}
                        checkedList = {(checkedList) => {setCheckedList(checkedList)} }
                        checkedList2 = {(checkedList2) => {setCheckedList2(checkedList2)} }
                        paging = {false}
                        isEditMode = {isEditMode}
                        loading={loading}
                    />
                </div>
            </div>
        </div>
    )
}
