import { observable, toJS } from 'mobx';
import config from 'config/config';

const host = config.api_host;


export const store = observable({
    api: host,

    openedPage: null,

    alert: msg => {
        alert(msg);
    }
});

export default store;
