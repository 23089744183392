import React from "react";
import {Copyright} from "components/molecules";

export const Footer = (props) => {

    return (
        <footer >
            <Copyright className="m-2"/>
        </footer>
    );
};
