import React, {Fragment, useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom'
import {CustomTable, SearchInputBar} from 'components/molecules';
import store from 'scripts/store';
import Net from 'scripts/net';
import DatePicker from "react-datepicker";

const columnList = [
    {
        code: 'number',
        title: 'No',
        width: '3',
    },
    {
        code: 'username',
        title: '사용자 명',
        width: '6',
    },
    {
        code: 'homenetUsername',
        title: '홈넷 계정',
        width: '6',
    },
    {
        code: 'siteName',
        title: '현장',
        width: '9',
    },
    {
        code: 'dong',
        title: '동',
        width: '4',
    },
    {
        code: 'ho',
        title: '호',
        width: '4',
    },
    {
        code: 'createdDatetime',
        title: '가입 일시',
        width: '12',
    },
    {
        code: 'updatedDatetime',
        title: '수정 일시',
        width: '12',
    },


];


export const DailyUserStatus = (props) => {

    // useHistory 선언
    const history = useHistory();
    const user = props.userInfo;
    const [programAuth, setProgramAuth] = useState({});
    const [contentData, setContentData] = useState({});
    const [selectDate, setSelectDate] = useState(new Date());
    const [totalElementsCnt, setTotalElementsCnt] = useState(0);

    const siteCode = user.site && user.site.code;


    useEffect(() => {
        store.openedPage = '일별 사용자 가입 현황';
        pageAuthInfo();
    }, [])

    useEffect(()=>{
        Net.getHomenetUserList({
            currentPage: 0,
            rowPerPage: 1000000000,
            searchCategory2: siteCode,
        }, response => {
            const yesterday = selectDate.getFullYear() + '-' + ('0' + (Number(selectDate.getMonth()) + 1)).slice(-2) + '-' + ('0' + selectDate.getDate()).slice(-2) + 'T00:00:00.000';
            const today = selectDate.getFullYear() + '-' + ('0' + (Number(selectDate.getMonth()) + 1)).slice(-2) + '-' + ('0' + selectDate.getDate()).slice(-2)+ 'T23:59:59.999';
            const newUserArray = response.data.content.filter((item)=>{
                return item.createdDatetime >= yesterday && item.createdDatetime <= today;
            }).map( (x,i) => {
                x.number = i+1;
                return x;
            })
            setTotalElementsCnt(newUserArray.length);
            setContentData(newUserArray);
            console.log(newUserArray);
        });
    },[selectDate])

    const pageAuthInfo = () => {
        const authGroupCode = props.userInfo.authList[0];
        const menuId = sessionStorage.getItem('menuId');

        Net.getAuthGroupMenuList(menuId, (response) => {
            if (response.status === 200) {
                response.data.forEach(item => {
                    if (item.authGroup === authGroupCode && item.authGroupMenu) {
                        setProgramAuth({
                            searchAuth: Boolean(item.authGroupMenu.searchAuth),
                            saveAuth: Boolean(item.authGroupMenu.saveAuth),
                            excelAuth: Boolean(item.authGroupMenu.excelAuth),
                            deleteAuth: Boolean(item.authGroupMenu.deleteAuth),
                        });
                    }
                });
            }
        });
    }

    return (
        <div className="w-100 h-100">

            <div className="w-100 h-1px bgC-efefef"/>
            <label className="mt-2 ml-2 mb-2">검색기간</label>

            <span>
                <DatePicker
                    dateFormat="yyyy-MM-dd"
                    selected={selectDate}
                    onChange={date => {
                        setSelectDate(date);
                    }}
                    className="w-100 textField"
                />
            </span>
            <div className="h-93">
                <div className="w-97 h-50">
                    <h5 className="floatL mt-1p mb-1p ml-2p">Total : {totalElementsCnt}</h5>
                    <CustomTable
                        columnList={columnList}
                        contentData={contentData}
                    />
                </div>
            </div>
        </div>
    );
}

