import React, { useState, useEffect } from 'react';
import store from 'scripts/store';
import ReactExport from 'react-export-excel';

export const CustomExcelDownload = (props) => {

    const ExcelFile = ReactExport.ExcelFile;
    const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
    const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

    const [excelDownloadContentData, setExcelDownloadContentData] = useState([]);

    useEffect(() => {
        setExcelDownloadContentData(props.contentData4Excel);
    }, [props.contentData4Excel])

    const convertArray = [];
    excelDownloadContentData && excelDownloadContentData.length > 0 && excelDownloadContentData.map((list, index) => {
        let tempObj = {};
        props.columnList.map((columnData, i) => {
            let resultData;

            const code = columnData.code;

            if(code.includes('.')) {
                let rowData = list;
                const codeArray = code.split('.');
                codeArray.map((data, index) => {
                    if(rowData !== undefined && rowData !== null) {
                        rowData = rowData[data];
                    } else {
                        rowData = null;
                    }
                });
                resultData = rowData;
            } else {
                resultData = list[code];
            }

            switch (code) {
                case 'no':
                    return(
                        tempObj = {
                            ...tempObj,
                            [code]: props.pageState.totalElementsCnt - index,
                        }
                    )
                case 'attributes':
                case 'operations':
                    if(resultData !== undefined && resultData.length > 0) {
                        const tempArray = [];
                        resultData.map(data => {
                            tempArray.push(data.code);
                        });
                        return (
                            tempObj = {
                                ...tempObj,
                                [code]: tempArray.join(),
                            }
                        )
                    }

                default:
                    return (
                        tempObj = {
                            ...tempObj,
                            [code]: String(resultData),
                        }
                    )
            }
        });
        convertArray.push(tempObj);
    });

    const excelSheetList =  props.columnList.map((columnData, i) => {
        return(<ExcelColumn key={i} label={columnData.title} value={columnData.code} />)
    });

    return(
        <div className={props.classNameDiv}>
            <ExcelFile filename={store.openedPage} element={<button className={props.className}>다운로드</button>}>
                <ExcelSheet data={convertArray} name={props.sheetTitle}>
                    {excelSheetList}
                </ExcelSheet>
            </ExcelFile>
        </div>

    )
}
