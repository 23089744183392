import React from 'react';

export const LoginAvatar = (props) =>{
    return(
          <div className="col-sm-6 col-lg-2 col-md-6">
              <img
                  className="img_mask hoverbtn"
                  src={props.imgUrl}
                  onClick={props.click}
              />
              <div className="">
                  <h5
                      className="card-title input_img_text hoverbtn"
                      onClick={props.click}
                  >{props.title}</h5>
              </div>
          </div>
    );
}
