import React, {useEffect, useState} from 'react';
import classNames from 'classnames';
import {useHistory, Link} from "react-router-dom";
import store from 'scripts/store';
import Net from 'scripts/net';
import './Header.scss';
import {BasicModal} from "../../molecules";
import {MyDetail} from "../../../pages/MyPage";
import {toJS} from "mobx";


export const Header = (props) => {
    const userInfo = props.userInfo
    const [logo, setLogo] = useState("");
    const history = useHistory();

    const [modalState, setModalState] = useState({
        open: false,
        data: [],
        username: '',
        clickedBtnName: '',
    });

    const [toggleUserInfo, setToggleUserInfo] = useState(false);

    useEffect(() => {
        if (props.open) {
            //alert("임시 비밀번호를 재설정하여 사용해 주세요.");
            //onClickModalOpen();
        }
    },[props.open])

    useEffect(() => {
        getCustomer(userInfo.customer.code, (data) => {
            setLogo(data.imageDataUrl);
        })
    }, [userInfo])

    const getCustomer = (code, callback) => {
        Net.getCustomerOne(code, (response) => {
            callback(response.data);
        })
    }

    const handleLogout = () => {
        Net.logout((response) => {
            if (response.status === 200) {
                history.replace("/login");
            }
        })
    };

    const toggleUserInfoBtn = () => {
        setToggleUserInfo(!toggleUserInfo);
    }

    const onClickModalOpen = () => {
        setModalState({
            ...modalState,
            open: !modalState.open
        });
    }

    return (
        <div className="header_bg">
            <BasicModal
                className="w-70 h-80"
                title={`마이페이지`}
                content={
                    <MyDetail modalClose={(e) => setModalState({
                        ...modalState,
                        open: e
                    })} />
                }
                modalOpen={modalState.open}
                modalClose={(e) => setModalState({
                    ...modalState,
                    open: e
                })}
                sendData={modalState.data}
            />
            <div className="m-auto w-210px">
                <Link to="/">
                <p className="fs-22 mt-0 mb-0 ml-80">
                    <img
                        className="imgFile"
                        src={logo === undefined || logo === "" || logo === "data:image/jpeg;base64," ? "/logo192.png" : logo}
                        alt="complex Img"
                        width={"50px"}
                    />
                </p>
                </Link>
            </div>
            <div
                className="flex w-100"
            >
                <div className="ml-20 header_title">
                    <p></p>
                </div>

                <div className="flex ml-auto mr-100">
                    <div className="m-auto">
                        <p className="fs-14 mt-0 mb-0 mr-13">한국어</p>
                    </div>
                    <div className="header_line"/>
                    <div className="flex mt-15 mr-15 mb-15 ml-13" onClick={() => toggleUserInfoBtn()}>
                        <img
                            className="header_mask mr-14"
                        />
                        <div className="header_user_info">
                            <p className="fs-14">{userInfo.username}</p>
                            <img
                                className="w-24px h-24px"
                                src="/icon/arrow-down-white.png"
                                alt="arrow-down"
                            />
                        </div>

                        <div className={classNames('header_user_info_pop',
                            {
                                'show': toggleUserInfo,
                                'hide': !toggleUserInfo
                            }
                        )}
                        >
                            <div className="info_pop_li mt-12" onClick={() => onClickModalOpen()}>
                                <p>마이페이지</p>
                            </div>
                            <div className="info_pop_li" onClick={() => handleLogout()}>
                                <p>로그아웃</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
};
