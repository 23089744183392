import React, { useState,useEffect } from 'react'
import './SearchSiteList.scss';
import {BiSearch} from "react-icons/all";

export const SearchSiteList = (props) => {

    const { id, title , input, url} = props;

    const [isDisplay, setIsDisplay] = useState(false);
    const [siteDom, setSiteDom] = useState(<></>);

    useEffect(()=>{
        setIsDisplay(false);
        setSiteDom(highlightIncludedText2(title, input));
    },[input])


    const highlightIncludedText2 = (text: string, value: string) => {
        const title = text.toLowerCase();
        const searchValue = value.toLowerCase();
        if (searchValue !== '' && title.includes(searchValue)) {
            const matchText = text.split(new RegExp(`(${searchValue})`, 'gi'));
            return (
                <>
                    {matchText.map((text, index) => {
                        if(text.toLowerCase() === searchValue.toLowerCase()){
                            setIsDisplay(true);
                            return <span key={index} style={{color : "#B76B34FF"}}>
                                {text}
                            </span>
                        } else {
                            return text
                        }
                    })}
                </>
            );
        }
        return text;
    };

    return (
        <>
            {
                isDisplay ?
                    <div
                        key={id}
                        className="mb-4 flex">
                        <BiSearch size="20" color="Grey" className="hoverbtn"/>
                        <a href={url} target='_blank' aria-label={`홈넷 인증 가이드 보기`}>
                            <p className="ms-2 site-grey-text"
                            >{siteDom}</p>
                        </a>
                    </div>
                    : <></>
            }
        </>
    )
}
