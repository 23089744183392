import store from './store';
import axios from 'axios';

const serviceAxios = axios.create({
    baseURL : store.api
});

serviceAxios.defaults.headers.common['Content-Type'] = 'application/json; charset=UTF-8';

serviceAxios.interceptors.request.use(
    function (config) {
        return config;
    },

    function (error) {
        return Promise.reject(error);
    }
);

serviceAxios.interceptors.response.use(
    function (response) {
        return response;
    },

    function (error) {
        console.log(error);
        if (error.response !== undefined) {
            if (error.response.status === 401) {
                if (window.location.href.indexOf('/login') < 0) {

                } else if (error.response.data.message === 'Unauthorized') {
                    console.log(error.response.data)
                    //window.location.replace('/login');
                    return error.response;
                }
            } else {
                //store.alert('서버 통신 오류, 잠시 후 다시 시도해주세요.');
                return error.response;
            }
        }

        if(error.config.timeout && error.config.retry < 2){
            //console.log("retry...", error.config)
            const config = {
                ...error.config,
                retry : error.config.retry+1,
                timeout : error.config.timeout+500
            }
            return axios.request(config);
        } else{
            return Promise.reject(error);
        }
    }
);

export default {

    //방문 차량 등록
    registerVisitorCar(state, callback) {
        const body = {
            carNumber : state.carNumber,
            visitorId : state.visitorId
        }
        serviceAxios
            .post(`/freepass/visitor/car`, body)
            .then((response) => {
                console.log(response);
                callback(response);
            })
            .catch((error) => {
                callback(error);
                console.log('error : ' + JSON.stringify(error));
            });
    },

    //방문 차량 상세 조회
    getVisitorCar(state, callback) {
        serviceAxios
            .get(`/freepass/visitor/car/${state.visitorId}`)
            .then((response) => {
                callback(response);
            })
            .catch((error) => {
                callback(error);
                console.log('error : ' + JSON.stringify(error));
            });
    },

    //사용자 방문 차량 삭제
    deleteVisitorCar(state, callback) {
        serviceAxios
            .delete(`/freepass/visitor/car/${state.visitorId}`)
            .then((response) => {
                callback(response);
            })
            .catch((error) => {
                console.log('error : ' + JSON.stringify(error));
                callback(error);
            });
    },


}


