import React, {Fragment, useEffect, useState} from 'react';
import {useHistory, withRouter} from 'react-router-dom'
import {CustomTextField, CustomTable, BasicModal, QuillEditor,CustomRadioButton} from 'components/molecules';
import store from 'scripts/store';
import Net from 'scripts/net';
import Common from 'scripts/common';
import {EditorState} from "draft-js";
import Zoom from "react-medium-image-zoom";

const columnList = [
    {
        code: 'answerId',
        title: 'answerId',
        width: '8',
        hidden: true,
    },
    {
        code: 'createdDatetime',
        title: '등록일자',
        width: '12',
    },
    {
        code: 'contents',
        title: '처리내용',
        width: '25',
        setInnerHtml: true,
    },
    {
        code: 'finished',
        title: '처리여부',
        width: '10',
    },
    {
        code: 'creator.username',
        title: '등록자',
        width: '15',
    },
];

const QnaReply = ({match}) => {

    // useHistory 선언
    const history = useHistory();

    const [checkedList, setCheckedList] = useState([]);

    const [qnaDetail, setQnaDetail] = useState([]);
    const [qnaAnswer, setQnaAnswer] = useState([]);
    const [modalState, setModalState] = useState({
        open: false,
        data: [],
        form: [],
    });

    useEffect(() => {
        store.openedPage = 'QnA 답변';

    }, [])

    useEffect(() => {
        getClassificationList(response => {
            response.status === 200 && loadData(response.data)
        });
    }, [modalState]);


    const loadData = (codeList) => {
        Net.getQnaOne(match.params.questionId, (response) => {
            if (response.status === 200) {
                codeList.classification.map(cItem => {
                    if (response.data.classification === cItem.code) {
                        response.data.classificationKOR = cItem.name;
                    }
                })
                codeList.category.map(cItem => {
                    if (response.data.category === cItem.code) {
                        response.data.categoryKOR = cItem.name;
                    }
                })

                setQnaDetail(response.data);

                Net.getQnaAnswerList(match.params.questionId, (response) => {
                    if (response.status === 200) {

                        setQnaAnswer(response.data);
                    }
                })
            }
        });
    }

    const getClassificationList = (callback) => {
        let classification = [];
        let category = [];
        Net.getCommunityCode(
            "QNA_CLASSIFICATION", (response) => {
                if (response.status === 200) {
                    classification = [];
                    response.data.map(items => {
                        if (items.usable) {
                            classification = [
                                ...classification,
                                {
                                    code: items.code,
                                    name: items.name
                                }
                            ]
                        }
                    })
                    Net.getCommunityCode(
                        "QNA_CATEGORY", (response) => {
                            if (response.status === 200) {
                                category = [];
                                response.data.map(items => {
                                    if (items.usable) {
                                        category = [
                                            ...category,
                                            {
                                                code: items.code,
                                                name: items.name
                                            }
                                        ]
                                    }
                                })
                                callback({status: 200, data: {classification: classification, category: category}});
                            }
                        }
                    )
                }
            }
        )

    }

    const onClickRow = (e) => {
        onClickModalOpen({questionId: match.params.questionId, answerId: e.answerId});
    }

    const removeList = () => {
        const result = window.confirm('삭제하시겠습니까?');
        const removeList = [];
        checkedList.map((id) => {
            const node = document.querySelector(`#${id}`);
            const nodeTr = node.closest('tr');
            const answerId = nodeTr.querySelector('td[name=answerId]').textContent;
            removeList.push({questionId: match.params.questionId, answerId: answerId});
        });
        if (result) {
            Net.removeQnaAnswer(removeList, (response) => {
                if (response) {
                    getClassificationList((response) => loadData(response.data));
                }
            });
        }
    }

    const addList = () => {
        onClickModalOpen({questionId: match.params.questionId, answerId: ''});
    }

    const sendData = (e) => {
        setModalState({
            ...modalState,
            data: e,
        })
    }

    const onClickModalOpen = (form) => {
        setModalState({
            ...modalState,
            open: !modalState.open,
            form: form
        });
    }

    const onCancelHandler = () => {
        history.push('/community/qna/list');
    }

    return (
        <div className="w-100 h-100">
            <BasicModal
                className="w-55 h-70 overFlowYscrollView"
                title={`QnA 처리 등록/수정`}
                content={<ModalContent
                    sendData={sendData}
                    modalClose={(e) => setModalState({
                        ...modalState,
                        open: e
                    })
                    }
                    form={modalState.form}
                />}

                modalOpen={modalState.open}
                modalClose={(e) => setModalState({
                    ...modalState,
                    open: e
                })}
                sendData={modalState.data}
                //rcvData={rcvData}
            />
            <div className="w-100 h-1px bgC-efefef"/>
            <div className="h-93">
                <div className="flex w-100 h-90">
                    <div className="flex w-55 h-100 flex-column">
                        <div className="w-100 h-100 mt-20">
                            <div className="contentsWrapperDiv">
                                <div className="contentsTitleDiv">
                                    QnA 정보
                                </div>
                                <div className="contentsDiv">
                                    <div className="overFlowYscrollView h-30vh">
                                        <div className="flex mb-12 w-100">
                                            <label className="label_title">QnA 구분</label>
                                            <div>{qnaDetail.classificationKOR}</div>
                                        </div>
                                        <div className="flex mb-12 w-100">
                                            <label className="label_title">QnA 내용</label>
                                            <div className="mb-12 w-80">
                                                {
                                                    qnaDetail.contents?
                                                        (<>
                                                                <div className="overFlowYscrollView h-60vh">
                                                                    <div dangerouslySetInnerHTML={{__html: qnaDetail.contents}}></div>
                                                                    {
                                                                        qnaDetail.imageDataUrl &&
                                                                        <div className="mt-10">
                                                                            <Zoom>
                                                                               <img src={qnaDetail.imageDataUrl} alt="complex=Img" width="300px"/>
                                                                            </Zoom>
                                                                        </div>
                                                                    }
                                                                </div>
                                                            </>
                                                        ) : (<>
                                                           질문 내용이 없습니다.
                                                        </>)
                                                }

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                            <div className="w-100 h-80 mt-20">
                                <div className="contentsWrapperDiv">
                                    <div className="contentsTitleDiv">
                                        등록자 정보
                                    </div>
                                    <div className="contentsDiv">
                                        <div className="overFlowYscrollView h-30vh">
                                            <div className="flex mb-12 w-80">
                                                <CustomTextField
                                                    className="w-60 h-26px"
                                                    title="닉네임"
                                                    name="creator.userFullName"
                                                    disabled={true}
                                                    value={qnaDetail}
                                                />
                                            </div>
                                            <div className="flex mb-12 w-80">
                                                <CustomTextField
                                                    className="w-60 h-26px"
                                                    title="계정"
                                                    name="creator.username"
                                                    disabled={true}
                                                    value={qnaDetail}
                                                />
                                            </div>
                                            <div className="flex mb-12 w-80">
                                                <CustomTextField
                                                    className="w-60 h-26px"
                                                    title="연락처"
                                                    name="creator.contact"
                                                    disabled={true}
                                                    value={qnaDetail}
                                                />
                                            </div>
                                            <div className="flex mb-12 w-80">
                                                <CustomTextField
                                                    className="w-60 h-26px"
                                                    title="이메일"
                                                    name="creator.email"
                                                    disabled={true}
                                                    value={qnaDetail}
                                                />
                                            </div>
                                            <div className="flex mb-12 w-80">
                                                <CustomTextField
                                                    className="w-60 h-26px"
                                                    title="고객사"
                                                    name="customer.name"
                                                    disabled={true}
                                                    value={qnaDetail}
                                                />
                                            </div>
                                            <div className="flex mb-12 w-80">
                                                <CustomTextField
                                                    className="w-60 h-26px"
                                                    title="현장명"
                                                    name="site.name"
                                                    disabled={true}
                                                    value={qnaDetail}
                                                />
                                            </div>
                                            <div className="flex mb-12 w-80">
                                                <CustomTextField
                                                    className="w-60 h-26px"
                                                    title="장소명"
                                                    name="place.name"
                                                    disabled={true}
                                                    value={qnaDetail}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                        </div>
                    </div>
                    <div className="w-100 h-75 mt-10 overFlowYscrollView">
                        <p className="body_content_subtitle">처리정보</p>
                        <div className="floatR mt-15 mr-25">
                            {
                                checkedList.length > 0 &&
                                <button
                                    className="removeBtn w-90px h-30px mr-5"
                                    onClick={removeList}
                                >삭제</button>
                            }

                            <button
                                className="addBtn w-90px h-30px"
                                onClick={addList}
                            >등록
                            </button>
                        </div>
                        <CustomTable
                            className="w-100"
                            columnList={columnList}
                            contentData={qnaAnswer}
                            checkbox={true}
                            checkedList={(checkedList) => {
                                setCheckedList(checkedList)
                            }}
                            rowClickEvt={true}
                            onClickRow={onClickRow}
                            // sort = {true}
                        />
                    </div>

                </div>
                    <div className="floatR mt-15 mr-25">
                        <button className="w-160px h-30px cancelBtn" onClick={onCancelHandler}>목록 돌아가기</button>
                    </div>
            </div>
        </div>
    );
}

export default withRouter(QnaReply);


export const ModalContent = (props) => {
    const history = useHistory();

    const userInfo = JSON.parse(sessionStorage.getItem('user'));

    const [form, setForm] = useState({
        questionId: props.form.questionId ? props.form.questionId : '',
        answerId: props.form.answerId ? props.form.answerId : '',
        contents: '',
        finished: false,
        userInfo: userInfo
    });
    const [registPage, setRegistPage] = useState(true);
    const [editorState, setEditorState] = useState(EditorState.createEmpty());
    useEffect(() => {
        if (form.answerId) {
            Net.getQnaAnswerOne(form, (response) => {

                if (response.status === 200) {
                    setForm({
                        ...form,
                        ...response.data,
                    });
                    setEditorState(Common.htmlToDraftBlocks(response.data.contents));

                    setRegistPage(false);
                }
            });
        }
    }, [])

    const radioBtnHandler = (name, value) => {
        const nextForm = {
            ...form,
            [name]: value === 'true' ? true : false,
        }
        setForm(nextForm);
    }

    const onRemoveHandler = () => {
        const result = window.confirm('삭제하시겠습니까?');
        if (result) {
            Net.removeQnaAnswer([{questionId: form.questionId, answerId: form.answerId}], (response) => {
                if (response) {
                    handleClose();
                }
            });
        }
    }

    const onSubmitHandler = () => {
        if (!form.contents) {
            alert("처리 내용을 입력해주세요.");
            return false;
        }
        var result = window.confirm('저장하시겠습니까?');
        if (result) {
            if (registPage) {
                Net.addQnaAnswer(form, (response) => {
                    if (response.status === 200 || response.status === 201) {
                        handleClose();
                    }
                });

            } else {
                Net.updateQnaAnswer(form, (response) => {
                    if (response.status === 200) {
                        handleClose();
                    }
                });
            }
        }
    }

    const liftValue = (value) => {
        setForm({
            ...form,
            contents: value,
        });
    }

    const handleClose = () => {
        props.modalClose(false);
    };

    return (
        <div className="w-100 h-100">
                <div className="flex w-100 mb-12">
                    <label className="label_title">처리여부*</label>
                    <CustomRadioButton
                        className="w-10 mr-20 ml-5"
                        title="완료"
                        id="radioBtn1"
                        name="finished"
                        value={true}
                        checked={form.finished}
                        customOnChange={radioBtnHandler}
                    />
                    <CustomRadioButton
                        className="w-10 mr-20"
                        title="진행"
                        id="radioBtn2"
                        name="finished"
                        value={false}
                        checked={!form.finished}
                        customOnChange={radioBtnHandler}
                    />
                </div>
                <div className="flex mb-10 w-100 h-70">
                    <label className="label_title">내용</label>
                    <div className="w-80">
                        <QuillEditor
                            onChange={liftValue}
                            value={editorState}
                            setEditorState={setEditorState}
                        />
                    </div>
                </div>
            <div className="footer_btn footer_modal">
                <div className="">
                    <button className="w-80px h-30px addBtn mr-10" onClick={onSubmitHandler}>저장</button>
                    {registPage ? "" :
                        <button className="w-80px h-30px removeBtn mr-10" onClick={onRemoveHandler}>삭제</button>}
                    <button className="w-80px h-30px cancelBtn" onClick={handleClose}>취소</button>
                </div>
            </div>
        </div>
    )
}
