import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { CustomTable, SearchInputBar } from 'components/molecules';
import store from 'scripts/store';
import Net from 'scripts/net';


export const SitePlaceGrid = (props) => {

    // useHistory 선언
    const history = useHistory();
    const user = props.userInfo;
    const [contentData, setContentData] = useState([]);
    const [aptJson, setAptJson] = useState([]);
    const [checkedList, setCheckedList] = useState([]);
    const [searchCategoryGroup, setSearchCategoryGroup] = useState([
        [
            [
                {code: '', name: '고객사명 선택'}
            ]
        ],
        [
            [
                {code: '', name: '현장명 선택'}
            ]
        ],
    ]);

    const [pageState, setPageState] = useState({
        currentPage: 1,
        rowPerPage: 13,
        totalElementsCnt: 0,
        drawElementsCnt: 0,
        customerCode: user.menuGroup === 'ADMIN' ? '' : user.customer && user.customer.code,
        siteCode: user.menuGroup === 'OPERATOR' ? user.site && user.site.code: '',
    });

    const [dongList, setDongList] = useState({});

    const [programAuth, setProgramAuth] = useState({});

    useEffect(() => {
        store.openedPage = '현장 장소 목록';
        getCategoryList();
        pageAuthInfo();
    }, []);

    useEffect(() => {
        if(pageState.siteCode !== '')
            loadDongData();
    }, [pageState.siteCode]);

    useEffect(()=>{
    },[dongList])

    useEffect(()=>{
        let minFlr = 10000;
        let maxFlr = 0;
        let minLine = 10000;
        let maxLine = 0;

        contentData.forEach(item => {
            minFlr = item.floor < minFlr ? item.floor : minFlr;
            maxFlr = item.floor > maxFlr ? item.floor : maxFlr;
            minLine = item.line < minLine ? item.line : minLine;
            maxLine = item.line > maxLine ? item.line : maxLine;
        });
        setAptJson([{'minFlr':minFlr,'maxFlr':maxFlr, 'minLine':minLine, 'maxLine':maxLine}]);
    },[contentData])

    const pageAuthInfo = () => {
        const authGroupCode = props.userInfo.authList[0];
        const menuId = sessionStorage.getItem('menuId');

        Net.getAuthGroupMenuList(menuId, (response) => {
            if(response.status === 200) {
                response.data.forEach(item => {
                    if(item.authGroup === authGroupCode && item.authGroupMenu) {
                        setProgramAuth({
                            searchAuth: Boolean(item.authGroupMenu.searchAuth),
                            saveAuth: Boolean(item.authGroupMenu.saveAuth),
                            excelAuth: Boolean(item.authGroupMenu.excelAuth),
                            deleteAuth: Boolean(item.authGroupMenu.deleteAuth),
                        });
                    }
                });
            }
        });
    }

    const loadDongData = () =>{
        getClassificationList((response)=>setDongList(response));

    }

    const loadData = (dong) => {
        Net.getSitePlaceListByDong(pageState,dong, (response) => {
            if(response.status === 200) {
                if(response.data.content) {
                    response.data.content.forEach((item) => {
                        const line = item.ho.slice(2);
                        const floor = item.ho.slice(0,-2);
                        item.line = line;
                        item.floor = floor;
                    })
                    setContentData(response.data.content);
                    setPageState({
                        ...pageState,
                        totalElementsCnt: response.data.totalElements,
                        drawElementsCnt: response.data.numberOfElements,
                    });
                }
            } else if(response.status === 204) {
                setContentData([]);
                setPageState({
                    ...pageState,
                    totalElementsCnt: 0,
                    drawElementsCnt: 0,
                });
            }
        });
    }

    const getClassificationList = (callback) => {
        Net.getDongList(pageState.siteCode, (response) => {
                if (response.status === 200) {
                    let newArray = [];
                        response.data.forEach(items => {
                                newArray = [
                                    ...newArray,
                                    {
                                        siteCode: items.site.code,
                                        dong: items.dong
                                    }
                                ]
                        })
                    callback(newArray);
                }
            }
        )
    }

    const getCategoryList = () => {
        if (user.menuGroup === 'ADMIN') {
            getCustomerList((response1) => {
                getSitesList(null, (response2) => {
                    setSearchCategoryGroup([response1, response2]);
                });
            });
        } else if (user.menuGroup === 'MANAGER') {
            getSitesList(pageState.customerCode, (response2) => {
                setSearchCategoryGroup([response2]);
            });
        }
    }

    const getRelateCategory = (selectedCategory) => {
        if (selectedCategory === "reset") selectedCategory = "";
        getSitesList(selectedCategory, (response2) => {
            setSearchCategoryGroup([
                searchCategoryGroup[0],
                response2
            ]);
        });
    }
    const getCustomerList = (callback) => {
        Net.getCustomerList({currentPage: '', rowPerPage: '', searchText: ''}, (response) => {
            if(response.status === 200) {
                let newArray = searchCategoryGroup[0][0];
                response.data.content.forEach(items => {
                    newArray = [
                        ...newArray,
                        {
                            code: items.code,
                            name: items.name
                        }
                    ]
                });
                callback(newArray);
            }
        });
    }

    const getSitesList = (selectedCategory, callback) => {
        if (selectedCategory) {
            Net.getSitesList(
                {currentPage: '', rowPerPage: '', searchText: '', customerCode: selectedCategory ? selectedCategory : false}, (response) => {
                    if (response.status === 200) {
                        let newArray = [{code: '', name: "현장명 선택"}];
                        if (response.data.content.length > 0) {
                            response.data.content.forEach(items => {
                                newArray = [
                                    ...newArray,
                                    {
                                        code: items.code,
                                        name: items.name
                                    }
                                ]

                            })
                        }
                        callback(newArray);
                    } else {
                        let newArray = [{code: '', name: "현장명 선택"}];
                        callback(newArray);
                    }
                });
        } else {
            let newArray = [{code: '', name: "현장명 선택"}];
            callback(newArray);
        }
    }

    const onSubmitHandler = (e) => {
        if (user.menuGroup === 'ADMIN') {
            setPageState({
                ...pageState,
                customerCode: e.selectedCategory[0],
                siteCode: e.selectedCategory[1],
            });

        } else if (user.menuGroup === 'MANAGER') {
            setPageState({
                ...pageState,
                customerCode: pageState.customerCode,
                siteCode: e.selectedCategory[0],
            });
        } else {
            setPageState({
                ...pageState,
                customerCode: pageState.customerCode,
                siteCode: pageState.siteCode,
            });
        }
    }
    const clickDong =  (e) => {
        loadData(e);
    }

    return (
        <div className="w-100 h-100">
            <div className="w-100 h-7 display_table">
                <SearchInputBar
                    className="w-100"
                    searchCategory={user.menuGroup === 'ADMIN' || user.menuGroup === 'MANAGER'}
                    searchCategoryGroup={searchCategoryGroup}
                    searchBtn={true}
                    searchBtnTitle="검색"
                    dynamicSearch={false}
                    relateCategoryHandler={user.menuGroup === 'ADMIN' && getRelateCategory}
                    onSubmit={onSubmitHandler}
                />
            </div>
            <div className="w-100 h-2px bgC-efefef" />
            <div className="row justify-content-center">
                <div className="col-md-3">
                    <div className="card_border bgC-operator box">
                        <button className="content btn_purple btn-lg ">추가</button>
                        <ul className="pd-2 bgC-operator card_border bgC-ffffff">
                            {
                               dongList.length > 0 ? dongList.map(x =>{
                                    return (
                                        <li className="d-block active hoverbtn m-2" key={x.dong} onClick={()=> clickDong(x.dong)}>
                                            <span className="card-title cardTitle">{x.dong} 동</span>
                                            <span className="text-muted ml-2 badge_count">{x.count}</span>
                                        </li>
                                    )
                               }) : <><h6>현장을 선택해 주시기 바랍니다.</h6></>
                            }
                        </ul>
                    </div>
                </div>
                <div className="col-md-9">
                    <div className="area_title">
                        <h2>호 평형보기 </h2>
                        <div className="floatR">
                            <button className="removeBtn" onClick={null} > 삭제 </button>
                            <button className="addBtn" onClick={null} > 일괄 등록 </button>
                            <button className="addBtn" onClick={null} > 라인별 등록 </button>
                            <button className="addBtn" onClick={null} > 개별 등록 </button>
                        </div>
                    </div>
                    <div>
                        <table className="aptArea">
                            <tbody>
                            {
                                contentData.map(item=>{

                                })
                            }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )
}
