import React, {Fragment, useEffect, useState} from "react";
import {CustomTable, CustomTextField,CustomSelectBox, BasicModal, CustomRadioButton} from 'components/molecules';
import Net from 'scripts/net';
import Common from 'scripts/common';


export const ServiceUpdateModal = (props) => {

    const [form, setForm] = useState({
        type : '',
        code : '',
        name : '',
        usable : true,
        homenetUsable : false,
        onepassUsable : false,
        sort : '',
        addProperty : [],
        attribute2 : '',
        attribute3 : '',
        attribute4 : '',
        attribute5 : '',
    })
    const [selectClassification , setSelectClassification] = useState();
    const [selectCode , setSelectCode] = useState();
    const [selectName , setSelectName] = useState();
    const [addProperty, setAddProperty] = useState(false);
    const [attribute2Code, setAttribute2Code] = useState('');
    const [attribute3Code, setAttribute3Code] = useState('');
    const [attribute4Code, setAttribute4Code] = useState('');
    const [attribute5Code, setAttribute5Code] = useState('');

    const attributeCodeList = ["attribute2Code", "attribute3Code", "attribute4Code", "attribute5Code"];
    const attributeStateList = [attribute2Code, attribute3Code, attribute4Code, attribute5Code];
    const attributeList = ["attribute2", "attribute3", "attribute4", "attribute5"];
    const attributeSetCodeList = [setAttribute2Code, setAttribute3Code, setAttribute4Code, setAttribute5Code];

    useEffect(()=>{
        setSelectClassification(props.clickData.type);
        setSelectCode(props.clickData.code);
        setSelectName(props.clickData.name);

        const temp = props.data.filter(x => x.code === props.clickData.code);

        for (let i=0; i<attributeCodeList.length; i++){
            const tempString = "attribute"+(Number(i)+2);
            if(temp[0].hasOwnProperty(tempString)){
                setAddProperty(true);
                attributeSetCodeList[i](temp[0][tempString]);
            }
        }

        let form_ = form;
        if(props.clickData.hasOwnProperty("addProperty") && props.clickData.addProperty !== null){
            for( let i in props.clickData.addProperty[0]) {
                let attributeString = "";
                for( let j in temp[0]){
                    if(temp[0][j] === i){
                        attributeString = j;
                        break;
                    }
                }
                form_ = {
                    ...form_,
                    [attributeString]: props.clickData.addProperty[0][i]
                }
            }
        }

        setForm({
            ...form_,
            usable: props.clickData.usable,
            homenetUsable: props.clickData.homenetUsable,
            onepassUsable: props.clickData.onepassUsable,
            sort : props.clickData.sort,
            addProperty : props.clickData.addProperty
        })
    },[props]);

    const textFieldHandler = (name, value) => {
        if(name==="sort"){
            const nextForm = {
                ...form,
                [name]: Number(value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1'))
            }
            setForm(nextForm);
        }else{
            const nextForm = {
                ...form,
                [name]: value
            }
            setForm(nextForm);
        }
    }


    const radioBtnHandler = (name, value) => {
        const nextForm = {
            ...form,
            [name]: value === 'true' ? true : false,
        }
        setForm(nextForm);
    }

    const onSubmitHandler = async () => {
        if(!form.sort){
            alert("정렬순서를 입력해주세요.");
            return false;
        }
        let result = window.confirm('저장하시겠습니까?');
        if (result) {
            let tempState = props.baseData;
            const temp = tempState[selectClassification].filter(x=> x.code !== selectCode);

            let tempAddProperty = {};

            for( let i =0; i<attributeList.length; i++){
                if(form[attributeList[i]]){
                    tempAddProperty = {
                        ...tempAddProperty,
                        [attributeStateList[i]] : form[attributeList[i]]
                    }
                }
            }
            switch (selectClassification){
                default :
                    if(Object.keys(tempAddProperty).length === 0){
                        tempState[selectClassification] = [
                            ...temp,
                            {
                                code:selectCode,
                                name:selectName,
                                usable : form.usable,
                                onepassUsable : form.onepassUsable,
                                homenetUsable : form.homenetUsable,
                                sort : form.sort
                            }
                        ]
                    }else {
                        tempState[selectClassification] = [
                            ...temp,
                            {
                                code:selectCode,
                                name:selectName,
                                usable : form.usable,
                                onepassUsable : form.onepassUsable,
                                homenetUsable : form.homenetUsable,
                                sort : form.sort,
                                addProperty : [tempAddProperty]
                            }
                        ]
                    }
            }

            Net.updateAppService(tempState, response => {
                if(response.status === 200){
                    props.reload('reloadService');
                    props.modalClose(false);
                } else{
                    alert('오류 발생');
                }
            })
        }
    }



    return (
        <div className="overflow-hidden">
            <h5 className="m-3 ml-50">서비스 수정 | 앱 서비스 관리</h5>
            <div className="flex">
            <div className="w-40">
                <div className="flex mb-12 w-100">
                    <label className="label_title">구분 :</label>
                    <label className="label_title">{selectClassification}</label>
                </div>
                <div className="flex mb-12 w-100">
                    <label className="label_title">코드 :</label>
                    <label className="label_title">{selectName}</label>
                </div>
                <div className="flex w-100 mb-12">
                    <label className="label_title">사용여부*</label>
                    <CustomRadioButton
                        className="w-10 mr-20 ml-5"
                        title="Y"
                        id="radioBtn1"
                        name="usable"
                        value={true}
                        checked={form.usable}
                        customOnChange={radioBtnHandler}
                    />
                    <CustomRadioButton
                        className="w-10 mr-20"
                        title="N"
                        id="radioBtn2"
                        name="usable"
                        value={false}
                        checked={!form.usable}
                        customOnChange={radioBtnHandler}
                    />
                </div>
                <div className="flex w-100 mb-12">
                    <label className="label_title">원패스 사용여부*</label>
                    <CustomRadioButton
                        className="w-10 mr-20 ml-5"
                        title="Y"
                        id="radioBtn3"
                        name="onepassUsable"
                        value={true}
                        checked={form.onepassUsable}
                        customOnChange={radioBtnHandler}
                    />
                    <CustomRadioButton
                        className="w-10 mr-20"
                        title="N"
                        id="radioBtn4"
                        name="onepassUsable"
                        value={false}
                        checked={!form.onepassUsable}
                        customOnChange={radioBtnHandler}
                    />
                </div>
                <div className="flex w-100 mb-12">
                    <label className="label_title">홈넷 사용여부*</label>
                    <CustomRadioButton
                        className="w-10 mr-20 ml-5"
                        title="Y"
                        id="radioBtn5"
                        name="homenetUsable"
                        value={true}
                        checked={form.homenetUsable}
                        customOnChange={radioBtnHandler}
                    />
                    <CustomRadioButton
                        className="w-10 mr-20"
                        title="N"
                        id="radioBtn6"
                        name="homenetUsable"
                        value={false}
                        checked={!form.homenetUsable}
                        customOnChange={radioBtnHandler}
                    />
                </div>
                <div className="flex w-90">
                    <CustomTextField
                        className="w-30 h-26px"
                        title="정렬 순서"
                        name="sort"
                        required={true}
                        disabled={false}
                        placeholder="숫자 입력 ex)1, 2, 3"
                        customOnChange={textFieldHandler}
                        value={form}
                        type={"number"}
                    />
                </div>
            </div>
                <div className="w-55">
                    {
                        addProperty ? <h5 className="mb-3">추가 속성</h5> : <></>
                    }

                    {
                        attribute2Code ? <>
                            <div className="flex w-100 mb-2">
                                <CustomTextField
                                    className="w-100 h-26px"
                                    title={attribute2Code}
                                    name="attribute2"
                                    disabled={false}
                                    customOnChange={textFieldHandler}
                                    value={form}
                                    type={"text"}
                                />
                            </div>
                        </> : <></>
                    }
                    {
                        attribute3Code ? <>
                            <div className="flex w-100 mb-2">
                                <CustomTextField
                                    className="w-100 h-26px"
                                    title={attribute3Code}
                                    name="attribute3"
                                    disabled={false}
                                    customOnChange={textFieldHandler}
                                    value={form}
                                    type={"text"}
                                />
                            </div>
                        </> : <></>
                    }
                    {
                        attribute4Code ? <>
                            <div className="flex w-100 mb-2">
                                <CustomTextField
                                    className="w-100 h-26px"
                                    title={attribute4Code}
                                    name="attribute4"
                                    disabled={false}
                                    customOnChange={textFieldHandler}
                                    value={form}
                                    type={"text"}
                                />
                            </div>
                        </> : <></>
                    }
                    {
                        attribute5Code ? <>
                            <div className="flex w-100 mb-2">
                                <CustomTextField
                                    className="w-100 h-26px"
                                    title={attribute5Code}
                                    name="attribute5"
                                    disabled={false}
                                    customOnChange={textFieldHandler}
                                    value={form}
                                    type={"text"}
                                />
                            </div>
                        </> : <></>
                    }
                </div>
            </div>
            <div className="footer_btn h-8 mt-15">
                    <button className="w-160px h-30px addBtn mr-10 ml-10" onClick={()=>onSubmitHandler()}>저장</button>
                    <button className="w-160px h-30px cancelBtn mr-10" onClick={()=>props.modalClose(false)}>취소</button>
            </div>
        </div>
    )
}
