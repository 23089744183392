import React from 'react'
import { Switch, Route } from 'react-router-dom'
import { UserList, UserRegist } from './UserManagePage';
import NotFound from "../../components/templates/NotFound";
import {HomenetUserList} from "./HomenetUserManagePage";
import {FreepassUserList} from "./FreePassUserManagePage";
import {TelecomUserList} from "./TelecomUserManagePage";
import {ApplianceUserList} from "./ApplianceUserManagePage";

export const MemberManagePage = ({userInfo}) => {

    return (
        <Switch>
            <Route path={"/member/user/list"} exact children={<UserList userInfo={userInfo}/>} />
            <Route path={"/member/user/regist"} exact children={<UserRegist />} />
            <Route path={"/member/user/regist/:username"} children={<UserRegist />} />
            <Route path={"/member/homenet/list"} exact children={<HomenetUserList userInfo={userInfo}/>} />
            <Route path={"/member/freepass/list"} exact children={<FreepassUserList userInfo={userInfo}/>} />
            <Route path={"/member/telecom/list"} exact children={<TelecomUserList userInfo={userInfo}/>} />
            <Route path={"/member/appliance/list"} exact children={<ApplianceUserList userInfo={userInfo}/>} />
            <Route path={"*"}>
                <NotFound />
            </Route>
        </Switch>
    )
}
