import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { CustomTable, SearchInputBar } from 'components/molecules';
import store from 'scripts/store';
import Net from 'scripts/net';

const columnList = [
    {
        code: 'code',
        title: '제조사코드',
        width: '50',
    },
    {
        code: 'name',
        title: '제조사명',
        width: '48',
    },
]

export const ToolsManufacturerList = (props) => {

    // useHistory 선언
    const history = useHistory();

    const [contentData, setContentData] = useState([]);
    const [checkedList, setCheckedList] = useState([]);

    const [pageState, setPageState] = useState({
        currentPage: 0,
        rowPerPage: 13,
        totalElementsCnt: 0,
        drawElementsCnt: 0,
        searchText: '',
    });

    const [programAuth, setProgramAuth] = useState({});
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        store.openedPage = '제조사 목록';
        pageAuthInfo();
    }, []);

    useEffect(() => {
        loadData();
    }, [pageState.currentPage,
        pageState.searchText,
    ]);

    const pageAuthInfo = () => {
        const authGroupCode = props.userInfo.authList[0];
        const menuId = sessionStorage.getItem('menuId');

        Net.getAuthGroupMenuList(menuId, (response) => {
            if(response.status === 200) {
                response.data.map(item => {
                    if(item.authGroup === authGroupCode && item.authGroupMenu) {
                        setProgramAuth({
                            searchAuth: Boolean(item.authGroupMenu.searchAuth),
                            saveAuth: Boolean(item.authGroupMenu.saveAuth),
                            excelAuth: Boolean(item.authGroupMenu.excelAuth),
                            deleteAuth: Boolean(item.authGroupMenu.deleteAuth),
                        });
                    }
                });
            }
        });
    }

    const loadData = () => {
        setLoading(true);
        Net.getToolsManufacturerList(pageState, (response) => {
            setLoading(false);
            if(response.status === 200) {
                setContentData(response.data.content);
                setPageState({
                    ...pageState,
                    totalElementsCnt: response.data.totalElements,
                    drawElementsCnt: response.data.numberOfElements,
                });
            } else if(response.status === 204) {
                setContentData([]);
                setPageState({
                    ...pageState,
                    totalElementsCnt: 0,
                    drawElementsCnt: 0,
                });
            }
        });
    }

    const onMovePage = (e) => {
        setPageState({
            ...pageState,
            currentPage: e-1
        });
    }

    const onSubmitHandler = (e) => {
        setPageState({
            ...pageState,
            currentPage: 0,
            searchText: e.searchText,
        });
    }

    const addList = () => {
        history.push('/tools/manufacturer/regist');
    }

    const onClickRow = (e) => {
        history.push(`/tools/manufacturer/regist/${e.code}`);
    }

    const removeList = () => {
        const result = window.confirm('삭제하시겠습니까?');
        const removeCodeList = [];
        checkedList.map((id) => {
            const node = document.querySelector(`#${id}`);
            const nodeTr = node.closest('tr');
            const codeValue = nodeTr.querySelector('td[name=code]').textContent;
            removeCodeList.push(codeValue);
        });

        if(result) {
            Net.removeToolsManufacturerList(removeCodeList, (response) => {
                if(response) {
                    loadData();
                }
            });
        }
    }

    return (
        <div className="w-100 h-100">
            <div className="w-100 h-7 display_table">
                <SearchInputBar
                    className="w-100"
                    searchCategory={false}
                    searchCategoryGroup=""
                    searchText={true}
                    searchPlaceHolder="제조사명을 입력해 주세요."
                    searchBtn={true}
                    searchBtnTitle="검색"
                    dynamicSearch={false}
                    onSubmit={onSubmitHandler}
                    refreshBtn={true}
                    refreshHandler={loadData}
                />
                {
                    programAuth.saveAuth &&
                    <div className="display_table_cell v-middle">
                        <button
                            className="addBtn w-90px h-30px floatR mr-20"
                            onClick={addList}
                        >등록</button>
                    </div>
                }
            </div>
            <div className="w-100 h-1px bgC-efefef" />
            <div className="flex">
                <div className="w-100 h-93 overflowYscroll">
                    <p className="totalCnt">Total {pageState.totalElementsCnt}</p>
                    {
                        programAuth.deleteAuth && checkedList.length > 0 &&
                        <button
                            className="removeBtn w-90px h-30px floatR mt-7 mr-20"
                            onClick={removeList}
                        >삭제</button>
                    }
                    <CustomTable
                        columnList = {columnList}
                        contentData = {contentData}
                        checkbox = {programAuth.deleteAuth}
                        checkedList = {(checkedList) => {setCheckedList(checkedList)} }
                        paging = {true}
                        totalElementsCnt = {pageState.totalElementsCnt}
                        rowPerPage = {pageState.rowPerPage}
                        currentPage = {pageState.currentPage}
                        drawElementsCnt = {pageState.drawElementsCnt}
                        pagination = {onMovePage}
                        rowClickEvt = {true}
                        onClickRow = {onClickRow}
                        loading = {loading}
                        // sort = {true}
                    />
                </div>
            </div>
        </div>
    )
}
