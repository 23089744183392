import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { CustomTable, SearchInputBar, BasicModal } from 'components/molecules';
import {usePageAuth, useDidMountEffect } from "scripts/hooks";
import store from 'scripts/store';
import Net from 'scripts/net';
import Common from 'scripts/common';
import {AppliancePropertyModal} from "./modal/AppliancePropertyModal";

const columnList = [
    {
        code: 'code',
        title: '',
        width: '',
        hidden: true,
    },
    {
        code: 'appliances',
        title: '가전사',
        width: '8',
    },
    {
        code: 'type',
        title: 'IoK 모델',
        width: '8',
    },
    {
        code: 'modelName',
        title: 'IoK 모델명',
        width: '8',
    },
    {
        code: 'appliancesModelCode',
        title: 'Third Party 모델코드',
        width: '8',
    },
    {
        code: 'modelCode',
        title: 'Third Party 데이터 타입',
        width: '8',
    },
    {
        code: 'locationName',
        title: '3rd Paryt Location',
        width: '5',
    },
];

export const ApplianceSettingList = (props) => {

    // useHistory 선언
    const history = useHistory();
    const user = props.userInfo;
    const programAuth = usePageAuth(props.userInfo.authList[0], sessionStorage.getItem('menuId'));
    const [contentData, setContentData] = useState([]);
    const [checkedList, setCheckedList] = useState([]);
    const [searchCategoryGroup, setSearchCategoryGroup] = useState([
        [
                {code: '', name: '음성서비스'},
                {code: 'SAMSUNG', name: '삼성전자'},
                {code: 'LG', name: 'LG전자'},
        ],
        [
            [
                {code: '', name: '장비 타입 선택'},
            ]
        ]
    ]);
    const [modalState, setModalState] = useState({
        open: false,
        data: [],
        clickedBtnName: '',
    });

    const [pageState, setPageState] = useState({
        totalElementsCnt: 0,
        drawElementsCnt: 0,
    });

    const [searchState, setSearchState] = useState({
        currentPage: 0,
        rowPerPage: 13,
        appliances : '',
        type : '',
    })

    useEffect(() => {
        store.openedPage = '가전사 장비 타입 설정 현황';
        getToolType();
    }, []);

    useEffect(()=>{
        loadData();
    },[searchState])

    const getToolType = () => {
        Common.getToolsTypeList((response)=>{
            setSearchCategoryGroup([searchCategoryGroup[0], response]);
        })
    }

    const loadData =  () => {
        Net.getApplianceSettingType(searchState,(response) => {
            if(response.status === 200 ) {
                console.log(response)
                setContentData(response.data.content)
                setPageState({
                    totalElementsCnt: response.data.totalElements,
                    drawElementsCnt: response.data.numberOfElements,
                })
            }
        });
    }

    const onMovePage = (e) => {
        setSearchState({
            ...searchState,
            currentPage: e-1
        });
    }

    const onSubmitHandler = (e) => {
        if (user.menuGroup === 'ADMIN') {
            setSearchState({
                ...searchState,
                appliances: e.selectedCategory[0] ? e.selectedCategory[0] : '',
                type: e.selectedCategory[1] ? e.selectedCategory[1] : '',
            });
        }
    }

    const reload = async (e) => {
        if(e === 'reload'){

        }
    }

    const sendData = (e) => {
        setModalState({
            ...modalState,
            data: e,
        })
    }

    const onClickRow = (e) => {
        /*
        *  팝업 창 띄우기
        * */
            setModalState({
                ...modalState,
                open: !modalState.open,

                propsData: {
                    userInfo : user,
                    data : {
                        appliances : e.appliances,
                        type : e.type
                    }
                },
                clickedBtnName: '타입별 장비 속성 맵핑',
            });
    }

    const tdOnClickEvent = (e, list) => {
        /*
        * 기타 속성 팝업 띄우기
        * */
        console.log(e, list);

    }

    return (
        <div className="w-100 h-100">
            <BasicModal
                className="w-80 h-88"
                title={`${modalState.clickedBtnName}`}
                content={
                    <ModalContent
                        sendData={sendData}
                        modalType={modalState.clickedBtnName}
                        propsData={modalState.propsData}
                        modalClose={(e) => setModalState({
                            ...modalState,
                            open: e
                        })}
                        reload={reload}
                        deleteAuth = {programAuth.deleteAuth}
                    />
                }
                modalOpen={modalState.open}
                modalClose={() => setModalState({
                    ...modalState,
                    open: false
                })}
                sendData={modalState.data}
            />
            <div className="w-100 h-7 display_table">
                <SearchInputBar
                    className="w-100"
                    searchCategory={user.menuGroup === 'ADMIN'}
                    searchCategoryGroup={searchCategoryGroup}
                    searchBtn={true}
                    searchBtnTitle="검색"
                    dynamicSearch={false}
                    onSubmit={onSubmitHandler}
                />
            </div>
            <div className="w-100 h-1px bgC-efefef" />
            <div className="flex h-93">
                <div className="w-100 h-100 overflowYscroll">
                    <p className="totalCnt">Total {pageState.totalElementsCnt}</p>

                    <CustomTable
                        columnList = {columnList}
                        contentData = {contentData}
                        paging = {true}
                        totalElementsCnt = {pageState.totalElementsCnt}
                        rowPerPage = {13}
                        currentPage = {searchState.currentPage}
                        drawElementsCnt = {pageState.drawElementsCnt}
                        tdClickEvt={tdOnClickEvent}
                        rowClickEvt={true}
                        pagination = {onMovePage}
                        rowClickEvt = {true}
                        onClickRow = {onClickRow}
                    />
                </div>
            </div>
        </div>
    )
}

export const ModalContent = (props) => {
    switch (props.modalType) {
        case "타입별 장비 속성 맵핑":
            return <AppliancePropertyModal data={props.propsData} modalClose={props.modalClose}/>
        default:
            break;
    }
}

