import React from 'react'
import { Switch, Route} from 'react-router-dom';
import {
    FreePassSiteList,
    FreePassSiteRegist
} from './FreePassSiteMaster';
import NotFound from "../../components/templates/NotFound";
import {FreePassVisitorList} from "./FreePassVisitorPage/FreePassVisitorList";

export const FreePassPage = ({userInfo}) => {
    return (
        <Switch>
            <Route path={"/freepass/site/list"} exact children={<FreePassSiteList userInfo={userInfo}/>}/>
            <Route path={"/freepass/site/regist"} exact children={<FreePassSiteRegist userInfo={userInfo}/>}/>
            <Route path={"/freepass/visitor/list"} exact children={<FreePassVisitorList userInfo={userInfo}/>}/>

            <Route path={"*"}>
                <NotFound />
            </Route>
        </Switch>
    )
}
