import React, { useState, useEffect } from 'react';
import store from 'scripts/store';
import ReactExport from 'react-export-excel';

export const TestExcelDownload = (props) => {

    const ExcelFile = ReactExport.ExcelFile;
    const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
    const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

    const [excelDownloadContentData, setExcelDownloadContentData] = useState([]);
    const [stateArray, setStateArray] = useState([]);

    useEffect(() => {
        setExcelDownloadContentData(props.contentData4Excel);
    }, [props.contentData4Excel])

    useEffect(()=>{
        const convertArray = [];
        excelDownloadContentData && excelDownloadContentData.length > 0 && excelDownloadContentData.map((list, index) => {
            let tempObj = {};
            props.columnList.map((columnData, i) => {
                let resultData;

                const code = columnData.code;

                if(code.includes('.')) {
                    let rowData = list;
                    const codeArray = code.split('.');
                    codeArray.map((data, index) => {
                        if(rowData !== undefined && rowData !== null) {
                            rowData = rowData[data];
                        } else {
                            rowData = null;
                        }
                    });
                    resultData = rowData;
                } else {
                    resultData = list[code];
                }

                switch (code) {
                    case 'no':
                        return(
                            tempObj = {
                                ...tempObj,
                                [code]: index,
                            }
                        )
                    case 'attributes':
                    case 'operations':
                        if(resultData !== undefined && resultData.length > 0) {
                            const tempArray = [];
                            resultData.map(data => {
                                tempArray.push(data.code);
                            });
                            return (
                                tempObj = {
                                    ...tempObj,
                                    [code]: tempArray.join(),
                                }
                            )
                        }
                    case 'devices':
                        if( !resultData || (resultData && resultData.length === 0)){
                        }else{
                            return tempObj = {
                                ...tempObj,
                                'device': resultData[0].hasOwnProperty('name') ? resultData[0].name : '',
                                'deviceNote': resultData[0].hasOwnProperty('note') ? resultData[0].note : ''
                            }
                        }
                    default:
                        return (

                            tempObj = {
                                ...tempObj,
                                [code]: String(resultData),
                                style : {
                                    alignment : { horizontal : "center" },
                                    fill: { patternType: "solid", fgColor: { rgb: "FFFF0000" } },
                                }
                            }
                        )
                }
            });
            convertArray.push(tempObj);

            tempObj = [];
            props.columnList.map((columnData, i) => {
                const code = columnData.code;
                let resultData = list[code];

                switch (code) {
                    case 'devices':
                        return tempObj = resultData.map((item,i)=>{
                            if(i==0) return;
                            convertArray.push({
                                'dong' : list.dong,
                                'ho' : list.ho,
                                'device' : item.name,
                                'deviceNote' : item.note
                            })
                        });
                }
            });
        });
        setStateArray(convertArray);
    },[excelDownloadContentData]);

    useState(()=>{

    },[stateArray]);


    const excelSheetList =  props.columnList.map((columnData, i) => {
        if(columnData.code === 'devices') return <></>
        return (<ExcelColumn key={columnData.code} label={columnData.title} value={columnData.code} widthPx={"100"}/>)
    });


    return(
        <div className={props.classNameDiv}>
            <ExcelFile filename={store.openedPage} element={<button className={props.classNameButton}>Excel 다운로드</button>}>
                <ExcelSheet data={stateArray} name={props.sheetTitle}>
                    <ExcelColumn key={'dong'} label={"동 "} value={'dong'} />
                    <ExcelColumn key={'ho'} label={"호 "} value={'ho'} />
                    <ExcelColumn key={'checkName'} label={"테스트 성공 여부"} value={'checkName'} widthPx={"150"} />
                    <ExcelColumn key={'lastTest'} label={"비고 "} value={'lastTest'} widthPx={"150"}/>
                    <ExcelColumn key={'device'} label={"디바이스"} value={'device'} />
                    <ExcelColumn key={'deviceNote'} label={"상세 비고"} value={'deviceNote'} />
                </ExcelSheet>
            </ExcelFile>
        </div>
    )
}
