import React, {useEffect, useState} from 'react';
import {BasicModal, CustomCheckbox, CustomSelectBox, CustomTextField} from 'components/molecules';
import {useHistory, withRouter} from 'react-router-dom';
import store from 'scripts/store';
import Net from 'scripts/net';

const UserRegist = ({match}) => {

    const [form, setForm] = useState({
        username: '',
        name: '',
        email: '',
        nickname: '',
        contact: '',
        phoneNumber: '',
        password: '',
        status: '',
        customer: {
            code: '',
            name: '',
        },
        site: {
            code: '',
            name: '',
        },
        place: {
            code: '',
            name: '',
        },
        menuGroup: '',
        authList: [],
        roleList: [],
        picture: '',
        imageDataUrl: '',
        enabled: true,
    });
    const [registPage, setRegistPage] = useState(true);
    const [duplicateChk, setDuplicateChk] = useState({
        username: false,
        nickname: false,
        phoneNumber: false,
    });
    const [oriPassword, setOriPassword] = useState('');

    const [customerList, setCustomerList] = useState([{
        code: '',
        name: '고객사명 선택'
    }]);
    const [siteList, setSiteList] = useState([{
        code: '',
        name: '현장명 선택'
    }]);
    const [sitePlaceList, setSitePlaceList] = useState([{
        code: '',
        name: '장소명 선택'
    }]);


    const [searchMenuGroup, setSearchMenuGroup] = useState([
        [
            {code: '', name: '메뉴그룹 선택'}
        ]
    ]);
    // const [roleList, setRoleList] = useState([
    //     {code: 'USER', name: '사용자 롤'},
    //     {code: 'MANAGER', name: '관리자 롤'},
    //     {code: 'SYSTEM', name: '시스템 롤'},
    // ])

    const [deviceList, setDeviceList] = useState([])
    const [userDeviceList, setUserDeviceList] = useState([])

    const [searchAuthGroup, setSearchAuthGroup] = useState([]);

    const [modalState, setModalState] = useState({
        open: false,
        data: [],
        clickedBtnName: ''
    });

    const [modalDeviceState, setModalDeviceState] = useState({
        open: false,
        data: [],
        clickedBtnName: ''
    });

    useEffect(() => {
        store.openedPage = '사용자 등록/수정';

        // 상세 조회(수정 목적으로 진입시)
        if (match.params.username !== undefined) {
            getUserOne(match.params, (response) => {
                if (response.status === 200) {
                    if (!response.data.customer) {
                        response.data.customer = {code: "", name: ""};
                    }
                    if (!response.data.site) {
                        response.data.site = {code: "", name: ""};
                    }
                    if (!response.data.enabled) {
                        response.data.enabled = false;
                    }
                    if (!response.data.roleList) {
                        response.data.roleList = [];
                    }
                    if (!response.data.authList) {
                        response.data.authList = [];
                    }
                    setForm({
                        ...form,
                        ...response.data,
                    });

                    setOriPassword(response.data.password);
                    setDuplicateChk({
                        username: true,
                        nickname: true,
                        phoneNumber: true,
                    });
                    // 등록페이지 여부 확인
                    setRegistPage(false);
                }
            });
        }
        // 고객사 전체 목록
        getCustomerList((response) => {
            setCustomerList(response);
        });
        getSearchAuthGroup();
        getSearchMenuGroup();
    }, []);

    useEffect(() => {
        if (form.customer.code !== '') {
            // 현장의 장소 목록
            getSitePlace();
        } else {
            // 빈값 선택시 장비모델명 selectbox 초기화
            setSiteList([
                {
                    code: '',
                    name: '현장명 선택'
                }
            ])
        }
    }, [form.site.code]);


    useEffect(() => {
        if (form.site.code !== '') {
            getSiteList(form.customer.code, (response) => {
                setSiteList(response);
            })
        } else {
            // 빈값 선택시 장비모델명 selectbox 초기화
            setSiteList([
                {
                    code: '',
                    name: '현장명 선택'
                }
            ])
        }
        getUserDevice();
        getPlace();
    }, [form.customer.code]);

    useEffect(() => {
        console.log("deviceList.length", deviceList.length);
        if (deviceList.length > 0) {
            onClickModalDeviceOpen();
        }
    }, [deviceList]);

    const getCustomerList = (callback) => {
        Net.getCustomerList({currentPage: '', rowPerPage: '', searchText: ''}, (response) => {
            if (response.status === 200) {
                let newArray = customerList;
                response.data.content.map(items => {
                    newArray = [
                        ...newArray,
                        {
                            code: items.code,
                            name: items.name
                        }
                    ]
                });
                callback(newArray);
            }
        });
    }

    const getSiteList = (code, callback) => {
        Net.getSitesList(
            {
                currentPage: '',
                rowPerPage: '',
                searchText: '',
                customerCode: code,
            }, (response) => {
                if (response.status === 200) {
                    let newArray = [
                        {
                            code: '',
                            name: '현장명 선택'
                        }
                    ];
                    response.data.content.forEach(items => {
                        newArray = [
                            ...newArray,
                            {
                                code: items.code,
                                name: items.name
                            }
                        ]
                    });
                    callback(newArray);
                } else if (response.status === 204) {
                    let newArray = [
                        {
                            code: '',
                            name: '현장명 선택'
                        }
                    ];
                    callback(newArray);
                }
            });
    }

    const getSearchAuthGroup = () => {
        Net.getCodeList(
            "AUTH_GROUP", (response) => {
                if (response.status === 200) {
                    let newArray = [];
                    response.data.map(items => {
                        newArray = [
                            ...newArray,
                            {
                                code: items.code,
                                name: items.name
                            }
                        ]

                    })
                    setSearchAuthGroup(newArray);

                }
            }
        )
    }

    const getSearchMenuGroup = () => {
        Net.getCodeList(
            "MENU_GROUP", (response) => {
                if (response.status === 200) {
                    let newArray = searchMenuGroup[0];
                    response.data.map(items => {
                        newArray = [
                            ...newArray,
                            {
                                code: items.code,
                                name: items.name
                            }
                        ]

                    })
                    setSearchMenuGroup(newArray);

                }
            }
        )
    }

    const getSitePlace = () => {
        Net.getSitePlaceList({
            currentPage: 0,
            siteCode: form.site.code,
            rowPerPage: 20
        }, (response) => {
            if (response.status === 200) {
                let newArray = [
                    {
                        code: '',
                        name: '장소명 선택'
                    }
                ];
                response.data.content.map(items => {
                    newArray = [
                        ...newArray,
                        {
                            code: items.dong + "-" + items.ho,
                            name: items.dong + "-" + items.ho
                        }
                    ]
                });
                setSitePlaceList(newArray);
            } else if (response.status === 204) {
                let newArray = [
                    {
                        code: '',
                        name: '장소명 선택'
                    }
                ];
                setSitePlaceList(newArray);
            }
        })
    }

    const getUserDevice = () => {
        if (match.params.username) {
            Net.getUserDeviceList({"username": match.params.username}, (response) => {
                setUserDeviceList(
                    response.data
                )
            });
        }
    };

    const getPlace = () => {
        Net.getPlace({"username": match.params.username}, (response) => {
            if (response.data && response.data.content.length > 0) {
                setForm(prevState => {
                    return {
                        ...prevState,
                        place: {
                            code: response.data.content[0].code,
                            name: response.data.content[0].name
                        }
                    }
                });
            }
        });
    }

    const history = useHistory();

    const getUserOne = (params, callback) => {
        Net.getUserOne(params.username, (response) => {
            callback(response);
        });
    }

    const selectBoxHandler = (name, value) => {
        switch (name) {
            case 'customer.code':
                setForm({
                    ...form,
                    customer: {
                        code: value.code,
                        name: value.name
                    },
                    site: {
                        code: "",
                        name: ""
                    }
                });
                break;
            case 'site.code':
                setForm({
                    ...form,
                    site: {
                        code: value.code,
                        name: value.name
                    }
                });
                break;
            case 'place.name':
                setForm({
                    ...form,
                    place: {
                        code: value.code,
                        name: value.name
                    }
                });
                break;
            case 'menuGroup':
                setForm({
                    ...form,
                    menuGroup: value
                });
                break;
            default:
                break;
        }
    }

    const onRemoveHandler = () => {
        const result = window.confirm('삭제하시겠습니까?');
        if (result) {
            Net.removeUserList([{username: form.username}], (response) => {
                if (response) {
                    history.push('/bridge/cloudn/list');
                }
            });
        }
    }

    const onSubmitHandler = () => {
        const pwPattern = /(?=.*\d)(?=.*[~`!@#$%\\^&*()-])(?=.*[a-zA-Z]).{8,50}$/;

        if (!form.username) {
            alert('사용자ID를 입력해주세요.');
            document.getElementsByName('username')[0].focus();
            return false;
        }
        if (!form.nickname) {
            alert('별칭을 입력해주세요.');
            document.getElementsByName('nickname')[0].focus();
            return false;
        }
        if (!form.email) {
            alert('이메일을 입력해주세요.');
            document.getElementsByName('email')[0].focus();
            return false;
        }
        if (!form.phoneNumber) {
            alert('핸드폰번호를 입력해주세요.');
            document.getElementsByName('phoneNumber')[0].focus();
            return false;
        }
        if ((!registPage && form.password) || registPage) {
            if (!pwPattern.test(form.password)) {
                alert('비밀번호는 영문, 숫자, 특수문자 포함 8글자 이상이어야 합니다.');
                document.getElementsByName('password')[0].focus();
                return false;
            }
        }
        // if( !form.customer.code ) {
        //     alert('고객사를 선택해주세요.');
        //     document.getElementsByName('customer.code')[0].focus();
        //     return false;
        // }
        // if( !form.site.code ) {
        //     alert('현장명을 선택해주세요.');
        //     document.getElementsByName('site.code')[0].focus();
        //     return false;
        // }

        if (!duplicateChk.username) {
            alert('사용자ID 중복체크를 해주세요.');
            return false;
        }
        if (!duplicateChk.nickname) {
            alert('별칭 중복체크를 해주세요.');
            return false;
        }
        if (!duplicateChk.phoneNumber) {
            alert('핸드폰번호 중복체크를 해주세요.');
            return false;
        }
        var result = window.confirm('저장하시겠습니까?');
        if (result) {
            if (registPage) {
                Net.addUser(form, (response) => {
                    if (response.status === 200 || response.status === 201) {
                        history.push('/bridge/cloudn/list');
                    }
                });
            } else {
                Net.updateUser(form, (response) => {
                    if (response.status === 200) {
                        history.push('/bridge/cloudn/list');
                    }
                });
            }
        }
    }

    const onCancelHandler = () => {
        history.push('/bridge/cloudn/list');
    }

    // Open Modal and setClickedBtnName
    const onClickModalOpen = (e) => {
        setModalState({
            ...modalState,
            open: !modalState.open,
        });
    }

    const onClickModalDeviceOpen = (e) => {
        setModalDeviceState({
            ...modalDeviceState,
            open: !modalDeviceState.open,
        });
    }

    const sendData = (e) => {
        Net.getCloudnDevices(e.data, (response) => {
            console.log(response)
            if (response.status === 200 && response.data.list) {
                console.log(">>>", response.data.list);
                setDeviceList(response.data.list);
            }
        });
    }

    const sendSaveDevice = (e) => {
        const state = {
            username: form.username,
            name: "공기질센서",
            model: {
                modelId: "5ffd3c5ebf247d63d6c5e17f" // AC-001
            },
            serialNumber: e[0],
            usable: true
        };

        Net.addToolsDevice(state, (device) => {

            if (form.place.code !== "") {

                const place = {
                    "name": form.place.name,
                    "dong": form.place.name.split("-")[0],
                    "ho": form.place.name.split("-")[1],
                    "username": form.username
                }

                Net.addPlace(place, (response) => {
                    const request = {
                        deviceId: device.data.deviceId,
                        name: device.data.name,
                        placeCode: response.data.code,
                        placeName: response.data.name
                    }
                    addUserDevice(request);
                })

            } else {

                const request = {
                    deviceId: device.data.deviceId,
                    name: device.data.name,
                }

                addUserDevice(request);

            }
        })

    }

    const addUserDevice = (state) => {
        const userDevice = {
            "deviceId": state.deviceId,
            "deviceNickname": state.name,
            "placeId": state.placeCode && state.placeCode,
            "username": form.username
        };

        Net.addUserDevice(userDevice, (response) => {

            const device = {
                "deviceId": state.deviceId,
                "site": {
                    "code": form.site.code,
                    "name": form.site.name
                },
                "place": {
                    "code": state.placeCode && state.placeCode,
                    "name": state.placeName && state.placeName
                },
                "username": form.username
            }

            Net.addDeviceByODP(device, (response) => {
                console.log('등록완료')
            })
        });

    }



    const handleCheckbox = (id, checked) => {
        const role = id.substring(id.indexOf("_") + 1);
        if (checked) {
            setForm({
                ...form,
                roleList: form.roleList.concat(role)
            });
        } else {
            setForm({
                ...form,
                roleList: form.roleList.filter(items => items !== role)
            });
        }
    }

    const handleCheckboxAuth = (id, checked) => {

        const auth = id.substring(id.indexOf("_") + 1);

        if (checked) {
            setForm({
                ...form,
                authList: form.authList.concat(auth)
            });
        } else {
            setForm({
                ...form,
                authList: form.authList.filter(items => items !== auth)
            });
        }
    }

    return (
        <div className="w-100 h-100">
            <BasicModal
                className="w-35 h-45 overFlowYscrollView"
                title={`클라우드앤 연동`}
                content={
                    <ModalContent
                        sendData={sendData}
                        oriPassword={oriPassword}
                        modalClose={(e) => setModalState({
                            ...modalState,
                            open: e
                        })
                        }
                    />
                }
                modalOpen={modalState.open}
                modalClose={(e) => setModalState({
                    ...modalState,
                    open: e
                })}
                sendData={modalState.data}
                //rcvData={rcvData}
            />
            <BasicModal
                className="w-35 h-45 overFlowYscrollView"
                title={`클라우드앤 연동`}
                content={
                    <ModalContentByDevice
                        sendData={sendSaveDevice}
                        devices={deviceList}
                        modalClose={(e) => setModalDeviceState({
                            ...modalDeviceState,
                            open: e
                        })
                        }
                    />
                }
                modalOpen={modalDeviceState.open}
                modalClose={(e) => setModalDeviceState({
                    ...modalDeviceState,
                    open: e
                })}
                sendData={modalDeviceState.data}
                //rcvData={rcvData}
            />
            <div className="w-100 h-7 display_table"></div>
            <div className="w-100 h-1px bgC-efefef"/>
            <div className="h-93">
                <div className="flex h-90 overFlowYscrollView">
                    <div className="w-60 h-100">
                        <div className="ml-55 mt-12">
                            <div className="flex mb-12 w-90">
                                <CustomTextField
                                    className="w-65 h-26px"
                                    title="사용자 ID"
                                    name="username"
                                    disabled={true}
                                    placeholder=""
                                    value={form}
                                />
                            </div>
                            <div className="flex mb-12 w-90">
                                <CustomTextField
                                    className="w-65 h-26px"
                                    title="별칭"
                                    name="nickname"
                                    disabled={true}
                                    validLen="15"
                                    placeholder=""
                                    value={form}
                                />
                            </div>
                            <div className="flex mb-12 w-90">
                                <CustomTextField
                                    className="w-65 h-26px"
                                    title="이메일"
                                    name="email"
                                    disabled={true}
                                    placeholder=""
                                    value={form}
                                />
                            </div>
                            <div className="flex mb-12 w-90">
                                <label className="label_title">클래우드앤 연동</label>
                                <button
                                    className="w-90px h-22px duplicateBtn"
                                    onClick={onClickModalOpen}
                                >로그인
                                </button>
                            </div>

                            <div className="flex mb-12 w-90">
                                <label className="label_title">현장</label>
                                <CustomSelectBox
                                    name="site.code"
                                    categoryList={siteList}
                                    value={form.site.code}
                                    onChangeHandler={(value, name) => selectBoxHandler('site.code', {code: value, name: name})}
                                    callbackCodeName="Y"
                                />
                            </div>
                            <div className="flex mb-12 w-90">
                                <label className="label_title">장소</label>
                                <CustomSelectBox
                                    name="place.name"
                                    categoryList={sitePlaceList}
                                    value={form.place.name}
                                    onChangeHandler={(value, name) => selectBoxHandler('place.name', {code: value, name: name})}
                                    callbackCodeName="Y"
                                />
                            </div>
                            <div className="flex mb-8 w-90">
                                <label className="label_title">기기 목록</label>
                                <table className="mainTable w-300px">
                                    <thead className="tableHead">
                                    <tr>
                                        <th className="v-middle w-30"></th>
                                        <th className="v-middle">기기 명</th>
                                    </tr>
                                    </thead>
                                    <tbody className="tableBody">
                                    {

                                        userDeviceList.length > 0
                                            ?
                                            userDeviceList.map((item, i) => {
                                                return (
                                                    <tr key={i}>

                                                        <td>
                                                            <CustomCheckbox
                                                                id={`role_${item.id}`}
                                                                className="j-c-c h-16px"
                                                                title=""
                                                                checked={userDeviceList.includes(item.id)}
                                                                readOnly={false}
                                                                disabled={false}
                                                                onChange={handleCheckbox}
                                                            />
                                                        </td>
                                                        <td>{item.name}</td>
                                                    </tr>
                                                )
                                            })
                                            :
                                            <tr>
                                                <td colSpan="2">조회 된 데이타가 없습니다.</td>
                                            </tr>
                                    }

                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer_btn h-10">
                    <button className="w-160px h-30px addBtn mr-10" onClick={onSubmitHandler}>저장</button>
                    {registPage ? "" : <button className="w-160px h-30px removeBtn mr-10" onClick={onRemoveHandler}>삭제</button>}
                    <button className="w-160px h-30px cancelBtn" onClick={onCancelHandler}>취소</button>
                </div>
            </div>
        </div>
    )
}

export default withRouter(UserRegist);

export const ModalContent = (props) => {
    const {sendData, oriPassword, modalClose} = props;
    const [phone, setPhone] = useState("");
    const [password, setPassword] = useState("");

    const textFieldHandler = (e) => {
        if (e.target.name === "phone") {
            setPhone(e.target.value);
        } else {
            setPassword(e.target.value);
        }
    }

    const login = () => {
        Net.cloudnLogin({phone: phone, pwd: password}, (response) => {
            sendData(response);
            handleClose();
        });
    }
    const handleClose = () => {
        modalClose(false);
    };

    return (
        <div>
            <div className="flex w-90 mb-12">
                <label className="label_title">아이디</label>
                <input
                    className="w-65 h-26px"
                    type="text"
                    name="phone"
                    onChange={(e) => textFieldHandler(e)}
                    value={phone}
                />
            </div>
            <div className="flex w-90">
                <label className="label_title">비밀번호</label>
                <input
                    className="w-65 h-26px"
                    type="password"
                    name="password"
                    onChange={(e) => textFieldHandler(e)}
                    value={password}
                />
            </div>
            <div className="footer_btn h-10 mt-10">
                <div className="">
                    <button className="w-80px h-30px addBtn mr-10" onClick={login}>로그인</button>
                </div>
            </div>
        </div>
    )

}

export const ModalContentByDevice = (props) => {
    const {sendData, devices, modalClose} = props;
    const [checkDevice, setCheckDevice] = useState([]);

    const handleClose = () => {
        modalClose(false);
    };

    const selectConfirm = () => {
        console.log(checkDevice);
        sendData(checkDevice);
        handleClose();
    }

    const handleCheckbox = (id, checked) => {
        const device = id.substring(id.indexOf("_") + 1);
        if (checked) {
            setCheckDevice([
                ...checkDevice.concat(device)
            ]);
        } else {
            setCheckDevice([
                ...checkDevice.filter(items => items !== device)
            ]);
        }
    }

    return (
        <div className="flex" style={{"flexDirection": "column"}}>
            <div className={"m-10"}>기기 목록</div>
            <table className="mainTable">
                <thead className="tableHead">
                <tr>
                    <th className="v-middle w-30"></th>
                    <th className="v-middle">시리얼넘버</th>
                </tr>
                </thead>
                <tbody className="tableBody">
                {

                    devices && devices.length > 0
                        ?
                        devices.map((item, i) => {
                            return (
                                <tr key={i}>

                                    <td>
                                        <CustomCheckbox
                                            id={`role_${item.id}`}
                                            className="j-c-c h-16px"
                                            title=""
                                            checked={false}
                                            readOnly={false}
                                            disabled={false}
                                            onChange={handleCheckbox}
                                        />
                                    </td>
                                    <td>{item.id}</td>
                                </tr>
                            )
                        })
                        :
                        <tr>
                            <td colSpan="2">조회 된 데이타가 없습니다.</td>
                        </tr>
                }

                </tbody>
            </table>
            <div className="footer_btn h-10 mt-10">
                <div className="">
                    <button className="w-80px h-30px addBtn mr-10" onClick={selectConfirm}>선택완료</button>
                </div>
            </div>
        </div>
    )

}
