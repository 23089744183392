import React from 'react';
import classnames from 'classnames/bind';


const cx = classnames.bind();

export default function CardLeft(props) {

    const {className} = props;

    return (
        <div className={cx("ml-10", className)}>
            <i className=""></i>
            <h5 className={cx("")}>{props.children}</h5>
        </div>
    );
}
