import Net from 'scripts/net';
import htmlToDraft from 'html-to-draftjs';
import { EditorState, convertToRaw, ContentState } from 'draft-js';


export default {
    getCustomerList(callback){
        Net.getCustomerList(
            {currentPage: '', rowPerPage: '', searchText:''}, (response) =>{
                if(response.status === 200) {
                    let newArray = [
                        {code: '', name: '고객사 선택'}
                    ];
                    response.data.content.forEach(items => {
                        newArray = [
                            ...newArray,
                            {
                                code: items.code,
                                name: items.name
                            }
                        ]
                    });
                    callback(newArray);
                }
            }
        )
    },

    getTotalSitesList(callback){
        Net.getSitesList(
            {
                currentPage: '',
                rowPerPage: '',
                searchText: '',
                customerCode: '',
            }, (response) => {
                if (response.status === 200) {
                    console.log(response)
                    let newArray = [{code: '', name: "현장명 선택"}];
                    if (response.data.content.length > 0) {
                        response.data.content.forEach(items => {
                            newArray = [
                                ...newArray,
                                {
                                    code: items.code,
                                    name: items.name
                                }
                            ]
                        })
                    }
                    callback(newArray);
                } else {
                    let newArray = [{code: '', name: "현장명 선택"}]
                    callback(newArray);
                }
            });
    },

    getSitesList(customerCode, callback){
        if( customerCode ) {
            Net.getSitesList(
                {
                    currentPage: '',
                    rowPerPage: '',
                    searchText: '',
                    customerCode: customerCode,
                }, (response) => {
                    if (response.status === 200) {
                        let newArray = [{code: '', name: "현장명 선택"}];
                        if (response.data.content.length > 0) {
                            response.data.content.forEach(items => {
                                newArray = [
                                    ...newArray,
                                    {
                                        code: items.code,
                                        name: items.name
                                    }
                                ]
                            })
                        }
                        callback(newArray);
                    } else {
                        let newArray = [{code: '', name: "현장명 선택"}]
                        callback(newArray);
                    }
                });
        } else {
            let newArray = [{code:'', name:"현장명 선택"}];
            callback(newArray);
        }
    },

    getCommunityClassificationList(codeType, callback){
        Net.getCommunityCode(
            codeType,(response) => {
                if(response.status === 200) {
                    let newArray = [{code:'', name: "구분 선택"}];
                    response.data.forEach(items => {
                        if(items.usable) {
                            newArray = [
                                ...newArray,
                                {
                                    code: items.code,
                                    name: items.name
                                }
                            ]
                        }
                    })
                    callback({status: 200, data: newArray});
                }
            }
        )
    },

    getDongListBySiteCode(siteCode, callback){
         Net.getDongsBySiteAsync(siteCode).then (response => {
             let newArray = [{code: '', name: "동 선택"}];
             if(response.status === 200) {
                 response.data.forEach(items => {
                     newArray = [
                         ...newArray,
                         {
                             code: items.dong,
                             name: items.dong + "동"
                         }
                     ]
                 })
                 callback({status: 200, data: newArray});
             }
         })
    },


    getRegionList(callback){
        Net.getRegionList((response) => {
            if(response.status === 200) {
                let newArray = [];
                response.data.contents.map(items => {
                    newArray = [
                        ...newArray,
                        {
                            code: items.regionCode,
                            name: items.regionName
                        }
                    ]
                });
                callback(newArray);
            }
        });
    },

    switchStringtoNumber(stringVar) {
        if(stringVar !== '' && !isNaN(Number(stringVar))) {
            const parts = stringVar.toString().split('.');
            parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
            return parts.join('.');
        }else{
            return stringVar;
        }
    },

    htmlToDraftBlocks(html){
        const blocksFromHtml = htmlToDraft(html);
        const { contentBlocks, entityMap } = blocksFromHtml;
        const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
        const editorState = EditorState.createWithContent(contentState);
        return editorState;
    },

    getToday(){
        const today = new Date();

        const year = today.getFullYear();
        const month = ('0' + (today.getMonth() + 1)).slice(-2);
        const day = ('0' + today.getDate()).slice(-2);

        let dateString = year + '-' + month  + '-' + day ;

        return dateString
    },

    getTodayTime(){
        const today = new Date();

        const year = today.getFullYear();
        const month = ('0' + (today.getMonth() + 1)).slice(-2);
        const day = ('0' + today.getDate()).slice(-2);
        const hours = ('0' + today.getHours()).slice(-2);
        const minutes = ('0' + today.getMinutes()).slice(-2);
        const seconds = ('0' + today.getSeconds()).slice(-2);

        let dateString = year + '-' + month  + '-' + day +' '+hours + ':' + minutes  + ':' + seconds;

        return dateString
    },

    gruopBy(list, keyGetter){
        const map = new Map();
        list.forEach((item)=>{
            if(item.site){
                const key = keyGetter(item);
                const collection = map.get(key);
                if(!collection){
                    map.set(key, [item]);
                }else{
                    collection.push(item);
                }
            }
        });
        return map;
    },

    getToolsTypeList(callback){
        Net.getToolsTypeList({
            currentPage : 0,
            rowPerPage : 99999,
            searchText : '',
        }, (response) => {
            if(response.status === 200) {
                let newArray = [{code:'', name: "장비 타입 선택"}];
                response.data.content.forEach(items => {
                    if(items.usable) {
                        newArray = [
                            ...newArray,
                            {
                                code: items.code,
                                name: items.name
                            }
                        ]
                    }
                })
                callback(newArray);
            }
        });
    }
}
