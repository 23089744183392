import React, {useEffect, useState} from 'react'
import {withRouter, useHistory} from 'react-router-dom';
import {CustomTextField, CustomRadioButton, CustomSelectBox, CustomCheckbox, BasicModal, CustomTable} from 'components/molecules';
import store from 'scripts/store';
import Net from 'scripts/net';
import {toJS} from "mobx";

const SitePlaceRegist = ({match}) => {

    const history = useHistory();
    const user = toJS(store.user)

    const [form, setForm] = useState({
        customer: {
            code: ''
        },
        site: {
            code: '',
            name: ''
        },
        type: '',
        dong: '',
        ho: '',
        usable: true,
    });
    const [registPage, setRegistPage] = useState(true);
    const [duplicateChk, setDuplicateChk] = useState(false);
    const [customerList, setCustomerList] = useState([{
        code: '',
        name: '고객사명 선택'
    }]);
    const [siteList, setSiteList] = useState([{
        code: '',
        name: '현장명 선택'
    }]);

    useEffect(() => {
        store.openedPage = '현장 장소 등록/수정';

        if (match.params.code !== undefined) {
            Net.getSitePlaceOne(match.params.code, (response) => {
                if (response.status === 200) {
                    getSiteOne(response.data.site.code, (response2) => {

                        response2.map(res => {
                            if (res.status === 200) {
                                setForm({
                                    ...form,
                                    ...response.data,
                                    customer: {
                                        code: res.data.customer.code,
                                    },
                                    site: {
                                        code: response.data.site.code,
                                        name: response.data.site.name
                                    }
                                })
                            }
                        })

                        // 장소명 중복체크 true
                        setDuplicateChk(true);
                        // 등록페이지 여부 확인
                        setRegistPage(false);
                    });


                }
            });
        }

        // 고객사 전체 목록
        getCustomerList((response) => {
            setCustomerList(response);
        });

    }, [])

    useEffect(() => {
        if (form.customer.code !== '') {
            getSiteList(form.customer.code, (response) => {
                setSiteList(response);
            })
        } else {
            // 빈값 선택시 장비모델명 selectbox 초기화
            setSiteList([
                {
                    code: '',
                    name: '현장명 선택'
                }
            ])
        }
    }, [form.customer.code]);

    const getCustomerList = (callback) => {
        Net.getCustomerList({currentPage: '', rowPerPage: '', searchText: ''}, (response) => {
            if (response.status === 200) {
                let newArray = customerList;
                response.data.content.map(items => {
                    newArray = [
                        ...newArray,
                        {
                            code: items.code,
                            name: items.name
                        }
                    ]
                });
                callback(newArray);
            }
        });
    }

    const getSiteList = (code, callback) => {
        Net.getSitesList(
            {
                currentPage: '',
                rowPerPage: '',
                searchText: '',
                customerCode: code,
            }, (response) => {
                if (response.status === 200) {
                    let newArray = [
                        {
                            code: '',
                            name: '현장명 선택'
                        }
                    ];
                    response.data.content.forEach(items => {
                        newArray = [
                            ...newArray,
                            {
                                code: items.code,
                                name: items.name
                            }
                        ]
                    });
                    callback(newArray);
                } else if (response.status === 204) {
                    let newArray = [
                        {
                            code: '',
                            name: '현장명 선택'
                        }
                    ];
                    callback(newArray);
                }
            });
    }

    const getSiteOne = (code, callback) => {
        Net.getSitesOne([code], (response) => {
            callback(response);
        });
    }

    const textFieldHandler = (name, value) => {
        // 장소명 중복체크 후 수정 시 중복체크 재확인 요망을 위한 값 셋팅
        if (name === 'dong' || name === 'ho') setDuplicateChk(false);
        const nextForm = {
            ...form,
            [name]: value
        }
        setForm(nextForm);
    }

    const radioBtnHandler = (name, value) => {
        const nextForm = {
            ...form,
            [name]: value === 'true' ? true : false,
        }

        console.log("radioBtnHandler", nextForm);
        setForm(nextForm);
    }

    const selectBoxHandler = (name, value, text) => {
        if (name === 'customer.code') {
            setForm({
                ...form,
                customer: {
                    code: value
                }
            });

        } else if (name === 'site.code') {
            setForm({
                ...form,
                site: {
                    code: value,
                    name: text
                }
            });
        }
    }

    const onDuplicateHandler = () => {
        if (form.customer.code === '') {
            alert('고객사명을 입력해주세요.');
            return false;
        }
        if (form.site.code === '') {
            alert('현장명을 입력해주세요.');
            return false;
        }
        if (form.dong === '') {
            alert('동을 입력해주세요.');
            return false;
        }
        if (form.ho === '') {
            alert('호를 입력해주세요.');
            return false;
        }

        Net.chkDuplicateSitePlace(form, (response) => {
            if (response.status === 200) {
                alert('이미 등록된 장소입니다.');
            } else if (response.status === 404) {
                alert('등록 가능한 장소입니다.');
                setDuplicateChk(true);
            }
        });
    }

    const onSubmitHandler = () => {
        if (form.customer.code === '') {
            alert('고객사명은 필수값입니다.');
            document.getElementsByName('customer.code')[0].focus();
            return false;
        }
        if (form.site.code === '') {
            alert('현장명은 필수값입니다.');
            document.getElementsByName('site.code')[0].focus();
            return false;
        }
        if (!duplicateChk) {
            alert('장소명 중복체크를 해주세요.');
            return false;
        }

        var result = window.confirm('저장하시겠습니까?');
        if (result) {
            console.log("site place", form);
            if (registPage) {
                Net.addSitePlace(form, (response) => {
                    if (response.status === 201) {
                        history.push('/location/siteplace/list');
                    }
                });
            } else {
                Net.updateSitePlace(form, (response) => {
                    if (response.status === 200) {
                        history.push('/location/siteplace/list');
                    }
                });
            }
        }
    }

    const onCancelHandler = () => {
        history.push('/location/siteplace/list');
    }

    const onRemoveHandler = () => {
        const result = window.confirm('삭제하시겠습니까?');
        if (result) {
            Net.removeSitePlaceList([form.code], (response) => {
                if (response) {
                    history.push('/location/siteplace/list');
                }
            });
        }
    }

    return (
        <div className="w-100 h-100">
            <div className="w-100 h-7 display_table"/>
            <div className="w-100 h-1px bgC-efefef"/>
            <div className="h-93">
                <div className="flex h-90">
                    <div className="w-100 h-100">
                        <div className="ml-85 mt-12">
                            <div className="flex mb-12 w-80">
                                <label className="label_title">고객사명 *</label>
                                <CustomSelectBox
                                    className="w-300px"
                                    name="customer.code"
                                    categoryList={customerList}
                                    onChangeHandler={(value) => selectBoxHandler('customer.code', value)}
                                    value={form.customer.code}
                                />
                            </div>
                            <div className="flex mb-12 w-80">
                                <label className="label_title">현장명 *</label>
                                <CustomSelectBox
                                    className="w-300px"
                                    name="site.code"
                                    categoryList={siteList}
                                    onChangeHandler={(value, name) => selectBoxHandler('site.code', value, name)}
                                    value={form.site.code}
                                    callbackCodeName={true}
                                />
                            </div>
                            <div className="flex mb-12 w-80">
                                <CustomTextField
                                    className="w-10 h-26px"
                                    title="장소명"
                                    name="dong"
                                    required={true}
                                    disabled={false}
                                    customOnChange={textFieldHandler}
                                    value={form}
                                />
                                <span className="mt-auto mb-auto ml-5">
                                    동
                                </span>
                                <CustomTextField
                                    className="w-10 h-26px ml-10"
                                    name="ho"
                                    required={false}
                                    disabled={false}
                                    customOnChange={textFieldHandler}
                                    value={form}
                                />
                                <span className="mt-auto mb-auto ml-5">
                                    호
                                </span>
                                <div className="mt-auto mb-auto">
                                    <button
                                        className="w-78px h-22px duplicateBtn"
                                        onClick={onDuplicateHandler}
                                    >중복체크
                                    </button>
                                </div>
                            </div>
                            <div className="flex mb-12 w-80">
                                <CustomTextField
                                    className="w-10 h-26px"
                                    title="유형"
                                    name="type"
                                    required={false}
                                    disabled={false}
                                    customOnChange={textFieldHandler}
                                    value={form}
                                />
                            </div>
                            <div className="flex w-80">
                                <label className="label_title">사용유무</label>
                                <CustomRadioButton
                                    className="mr-60"
                                    title="사용"
                                    id="radioBtn1"
                                    name="usable"
                                    value={true}
                                    checked={form.usable}
                                    customOnChange={radioBtnHandler}
                                />
                                <CustomRadioButton
                                    title="사용안함"
                                    id="radioBtn2"
                                    name="usable"
                                    value={false}
                                    checked={!form.usable}
                                    customOnChange={radioBtnHandler}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer_btn h-10">
                    {
                        user.menuGroup === "OPERATOR" ? '' : <>
                            <button className="w-160px h-30px addBtn mr-10" onClick={onSubmitHandler}>저장</button>
                            { registPage ? "" : <button className="w-160px h-30px removeBtn mr-10" onClick={onRemoveHandler}>삭제</button>}
                        </>
                    }
                    <button className="w-160px h-30px cancelBtn" onClick={onCancelHandler}>취소</button>
                </div>
            </div>
        </div>
    )
}

export default withRouter(SitePlaceRegist);
