import React, {Fragment, useEffect, useState} from 'react';
import {CustomTable, SearchInputBar, BasicModal} from 'components/molecules';
import store from 'scripts/store';
import Net from 'scripts/net';
import Common from 'scripts/common';
import {usePageAuth , useDidMountEffect} from "scripts/hooks";
import {toast} from "react-toastify";
import classNames from 'classnames';

const columnList = [
    {
        code: 'name',
        title: '장비명',
        width: '8',
    },
    {
        code: 'deviceId',
        title: 'DeviceID',
        width: '9',
    },
    {
        code: 'serialNumber',
        title: 'serialNumber',
        width: '6',
    },
    {
        code: '',
        title: '디바이스 상태',
        width: '6',
        tdClickEvent: true,
    },
];
export const DeviceStatusMonitoring = (props) => {

    // useHistory 선언
    const user = props.userInfo;
    const programAuth = usePageAuth(props.userInfo.authList[0], sessionStorage.getItem('menuId'));
    const [searchCategory, setSearchCategory] = useState([
        [
            {code: '', name: '고객사 선택'}
        ],
        [
            {code: '', name: '현장명 선택'}
        ],
        [
            {code: '', name: '동 선택'}
        ],
    ]);
    const [selectedSiteName, setSelectedSiteName] = useState('');
    const [selectedDongName, setSelectedDongName] = useState('');
    const [tempData, setTempData] = useState([]);
    const [contentData, setContentData] = useState([]);
    const [clickedRowIdx, setClickedRowIdx] = useState(null);
    const [modalState, setModalState] = useState({
        open: false,
        data: [],
        clickedBtnName: '',
    });

    useEffect(() => {
        store.openedPage = '현장별 장비 상태 현황';
        getCategoryList('');
    }, [])

    useEffect(()=>{
        setClickedRowIdx(null);
    },[selectedDongName])

    const getCategoryList = () => {
        Common.getCustomerList((response)=>{
           setSearchCategory([response,searchCategory[1],searchCategory[2]]);
        });
    }

    const tdOnClickEvent = (title, e) => {
        setModalState({
            ...modalState,
            open: !modalState.open,
            propsData: e.status.state,
            clickedBtnName: title
        });
    }

    const loadData =  (hoData) => {
        let curFloor = 0;
        let newArray = [];
        let maxFloor = 0;
        let maxLine = 0;
        let check = false;

        hoData.reverse().forEach(items=>{
            const floor = items.ho.slice(0, items.ho.length-2);
            const line = items.ho.slice(-2);
            if(curFloor !== floor){
                if(curFloor!==0){
                }else{
                    maxFloor = Number(floor);
                }
                maxLine = newArray.length > maxLine ? newArray.length : maxLine;
                curFloor = floor;

                newArray = [floor];
                newArray = [
                    ...newArray,
                    line
                ]
            }else{
                newArray = [
                    ...newArray,
                    line
                ]
            }
        });

        maxLine = newArray.length > maxLine ? newArray.length : maxLine
        let arr1 = Array.from(Array(maxFloor+1), () => Array(maxLine-1).fill(''));

        hoData.forEach(items=>{
            const floor = Number(items.ho.slice(0, items.ho.length-2));
            const line = Number(items.ho.slice(-2));
            if(arr1[floor] === undefined){
                check = true;
            }else{
                arr1[floor][line] = items.ho;
            }
        })

        const dom = <Fragment>
            <table className='mainTable mt-1 overflow-hidden'>
                <tbody>
            {
                arr1.reverse().map((list, index)=>{
                    if(index === maxFloor) return<></>
                    return(
                        <tr key={index}>
                            <th key={maxFloor-index} className="tableHead borderC-eb878c">{maxFloor-index}층</th>
                            <>
                                {arr1[index].map((list2, index2)=>{
                                    if(index2 === maxLine-1) return;
                                    const clicked = arr1[index][index2+1] === clickedRowIdx ?'clickedTd':'';
                                    return (<>
                                        {
                                            arr1[index][index2+1] === '' || arr1[index][index2+1] === undefined ? <td key={index+"F"+index2} className="text-center">{arr1[index][index2+1]}</td>
                                                : <td key={index+"F"+index2} className={classNames.bind()('text-center hoverbtn',clicked)} onClick={()=>{ClickRow(arr1[index][index2+1])} }>{arr1[index][index2+1]}</td>
                                        }
                                        </>
                                    )
                                })}
                            </>
                        </tr>
                    )
                })
            }
                </tbody>
            </table>
        </Fragment>
        if(!check)
            return dom;
        else
            return loadData(tempData)
    }

    const ClickRow = async (ho) => {
        setClickedRowIdx(ho);
        toast('데이터 로드 중입니다.',{
            autoClose : 1500,
            pauseOnHover: false,
            draggable : false,
            position : "top-center"
        });
        const response = await Net.getDeviceStatusByDongHoAsync(selectedSiteName, selectedDongName, ho);
        setContentData(response.data)
    }

    const onSubmitHandler = async (e) => {
        if(e.selectedCategory[0] === ''){
            alert("고객사를 선택해주세요.");
            return false;
        }
        else if(e.selectedCategory[1] === ''){
            alert("현장을 선택해주세요.");
            return false;
        }
        else if(e.selectedCategory[2] === ''){
            alert("동을 선택해주세요.");
            return false;
        }
        setSelectedSiteName(e.selectedCategory[1])
        setSelectedDongName(e.selectedCategory[2]);
        const temp = await Net.getHoListBySiteAndDongAsync(e.selectedCategory[1], e.selectedCategory[2]);
        await setTempData(temp.data)
    }

    const getRelateCategory = (selectedCategory) => {
        if (selectedCategory === "reset") selectedCategory = "";
        Common.getSitesList(selectedCategory, (response2) => {
            setSearchCategory([
                searchCategory[0],
                response2,
                searchCategory[2]
            ]);
        });
    }

    const getRelateSiteCategory = (selectedCategory) => {
        if (selectedCategory === "reset") selectedCategory = "";
        Common.getDongListBySiteCode(selectedCategory, response => {
            setSearchCategory([
                searchCategory[0],
                searchCategory[1],
                response.data
            ])
        });
    }

    const sendData = (e) => {
        setModalState({
            ...modalState,
            data: e,
        })
    }

    return (
        <div className="w-100 h-100">
            <BasicModal
                className="w-70 h-80"
                title={`사용자 ${modalState.clickedBtnName} 목록`}
                content={
                    <ModalContent
                        sendData={sendData}
                        modalType={modalState.clickedBtnName}
                        propsData={modalState.propsData}
                    />
                }
                modalOpen={modalState.open}
                modalClose={(e) => setModalState({
                    ...modalState,
                    open: e
                })}
                sendData={modalState.data}
            />
            <div className="w-100 h-1px bgC-efefef"/>
            <div className="h-93 row">
                    <h5 className="floatL mt-1p mb-1p ml-2p">동별 각 호장비 상태 현황</h5>
                    <SearchInputBar
                        role={user.menuGroup}
                        className=""
                        searchCategory={user.menuGroup === 'ADMIN' || user.menuGroup === 'MANAGER'}
                        searchCategoryGroup={searchCategory}
                        searchBtn={true}
                        searchBtnTitle="검색"
                        dynamicSearch={false}
                        relateCategoryHandler={user.menuGroup === 'ADMIN' && getRelateCategory}
                        relateSiteCategoryHandler={getRelateSiteCategory}
                        onSubmit={onSubmitHandler}
                    />
                <div className="w-40 h-93 mt-2">
                    {selectedDongName ? <h5 className="ml-5 mb-3 mt-1"> 동별 세대 정보 </h5> : <></>}
                    <div id="container" className="h-93 mt-2 overflowYscroll">
                        {
                            tempData.length > 0 ? loadData(tempData) : <></>
                        }
                    </div>
                </div>
                <div className="w-60 h-93 mt-1 overflowYscroll">
                    <h5 className="mt-1p mb-3 ml-5 row">{
                        selectedDongName ? <>
                            세대별 디바이스 상태
                        </> : <></>
                    }</h5>
                    {
                        selectedDongName ? <CustomTable
                            className="mt-2  h-93"
                            columnList={columnList}
                            contentData={contentData}
                            tdClickEvt={tdOnClickEvent}
                        /> : <></>
                    }

                </div>
            </div>
        </div>
    );
}

export const ModalContent = (props) => {
    switch(props.modalType) {
        case "디바이스 상태":
            return <AttributesContent data={props.propsData}/>
            break;
        default:
            break;
    }
}

export const AttributesContent = (props) => {
    const modalColumnList = [
        {
            code : 'code',
            title: '코드',
            width: '5',
        },
        {
            code : 'value',
            title: '값',
            width: '5',
        },
        {
            code : 'name',
            title: '이름',
            width: '3',
        }
    ];
    const [reportedOperation, setReportedOperation] = useState([]);
    const [reportedAttributes, setReportedAttributes] = useState([]);
    const [desiredOperation, setDesiredOperation] = useState([]);
    const [desiredAttributes, setDesiredAttributes] = useState([]);

    useEffect(()=>{
        if(props.data.reported !== null){
            setReportedOperation(props.data.reported.operations);
            setReportedAttributes(props.data.reported.attributes);
        }
        if(props.data.desired !== null){
            setDesiredOperation(props.data.desired.operations);
            setDesiredAttributes(props.data.desired.attributes);
        }

    },[])
    return (
        <div>
            <h2 className="m-1 mt-2"> Desired </h2>
            <h5 className="m-3 ml-50"> 장비 행위 상태</h5>
            <CustomTable
                className="h-50"
                columnList={modalColumnList}
                contentData={desiredOperation}
            />
            <h5 className="m-3 ml-50"> 장비 속성 상태</h5>
            <CustomTable
                className="h-50"
                columnList={modalColumnList}
                contentData={desiredAttributes}
            />
            <h2 className="m-1"> Reported </h2>
            <h5 className="m-3 ml-50"> 장비 행위 상태</h5>
            <CustomTable
                className="h-50"
                columnList={modalColumnList}
                contentData={reportedOperation}
            />
            <h5 className="m-3 ml-50"> 장비 속성 상태</h5>
            <CustomTable
                className="h-50"
                columnList={modalColumnList}
                contentData={reportedAttributes}
            />
        </div>
    )
}
