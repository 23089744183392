/* eslint-disable */
// noinspection RegExpRedundantEscape,RegExpDuplicateCharacterInClass

import React, {useEffect, useState} from 'react';
import {BasicModal, CustomTextField, ImgUpload} from 'components/molecules';
import {withRouter} from 'react-router-dom';
import Net from 'scripts/net';

const MyDetail = (props) => {
    const userInfo = JSON.parse(sessionStorage.getItem('user'));

    const [form, setForm] = useState({
        username: '',
        name: '',
        email: '',
        nickname: '',
        contact: '',
        phoneNumber: '',
        password: '',
        status: '',
        customer: {
            code: '',
            name: '',
        },
        site: {
            code: '',
            name: '',
        },
        place: {
            code: '',
            name: '',
        },
        menuGroup: '',
        authList: [],
        roleList: [],
        picture: '',
        imageDataUrl: '',
        enabled: true
    });
    const [duplicateChk, setDuplicateChk] = useState({
        username: false,
        nickname: false,
        phoneNumber: false,
    });

    const [customerList, setCustomerList] = useState([{
        code: '',
        name: '고객사명 선택'
    }]);

    const [modalState, setModalState] = useState({
        open: false,
        data: [],
        clickedBtnName: ''
    });

    useEffect(() => {
        Net.getUserOne(userInfo.username, async (response) => {
            if (response.status === 200) {
                if (!response.data.customer) {
                    response.data.customer = {code: "", name: ""};
                }
                if (!response.data.site) {
                    response.data.site = {code: "", name: ""};
                }
                if (!response.data.enabled) {
                    response.data.enabled = false;
                }
                if (!response.data.roleList) {
                    response.data.roleList = [];
                }
                if (!response.data.authList) {
                    response.data.authList = [];
                }


                const authGroups = await Net.getCodeListAsync("AUTH_GROUP");
                const menuGroups = await Net.getCodeListAsync("MENU_GROUP");

                const auth = authGroups.data.filter(items => {
                    return items.code === response.data.authList[0];
                })[0];

                const menu = menuGroups.data.filter(items => {
                    return items.code === response.data.menuGroup;
                })[0];

                setForm({
                    ...form,
                    ...response.data,
                    authName: auth.name,
                    menuGroupName: menu.name
                });

                setDuplicateChk({
                    username: true,
                    nickname: true,
                    phoneNumber: true,
                });
            }
        });

        // 고객사 전체 목록
        getCustomerList((response) => {
            setCustomerList(response);
        });
    }, []);

    const getCustomerList = (callback) => {
        Net.getCustomerList({currentPage: '', rowPerPage: '', searchText: ''}, (response) => {
            if (response.status === 200) {
                let newArray = customerList;
                response.data.content.forEach(items => {
                    newArray = [
                        ...newArray,
                        {
                            code: items.code,
                            name: items.name
                        }
                    ]
                });
                callback(newArray);
            }
        });
    }

    const textFieldHandler = (name, value) => {
        if (name === 'username' || name === 'nickname' || name === 'phoneNumber') {
            const dupl = {
                ...duplicateChk,
                [name]: false,
            };
            setDuplicateChk(dupl);
        }

        if (name === 'place.name') {
            form.place.name = value;
        }

        const nextForm = {
            ...form,
            [name]: value
        }
        setForm(nextForm);
    }

    const onDuplicateHandler = (checkType) => {
        let typeName = "";
        let formData = "";
        switch (checkType) {
            case "username":
                typeName = "사용자 ID";
                formData = form.username;
                break;
            case "phoneNumber":
                typeName = "핸드폰번호";
                formData = form.phoneNumber;
                break;
            case "nickname":
                typeName = "별칭";
                formData = form.nickname;
                break;
            default:
                break;
        }
        if (formData) {
            Net.checkUserOne(checkType, form, (response) => {

                if (response.status === 200) {
                    alert('사용 가능한 ' + typeName + '입니다.');
                    const dupl = {
                        ...duplicateChk,
                        [checkType]: true,
                    };
                    setDuplicateChk(dupl);
                } else if (response.status === 409) {
                    alert('이미 등록된 ' + typeName + '입니다.');
                }

            });
        } else {
            alert(typeName + " 텍스트를 입력해 주세요.")
        }
    }

    const onSubmitHandler = () => {
        if (!form.nickname) {
            alert('별칭을 입력해주세요.');
            document.getElementsByName('nickname')[0].focus();
            return false;
        }
        if (!form.phoneNumber) {
            alert('핸드폰번호를 입력해주세요.');
            document.getElementsByName('phoneNumber')[0].focus();
            return false;
        }
        if (!duplicateChk.nickname) {
            alert('별칭 중복체크를 해주세요.');
            return false;
        }
        if (!duplicateChk.phoneNumber) {
            alert('핸드폰번호 중복체크를 해주세요.');
            return false;
        }
        var result = window.confirm('저장하시겠습니까?');
        if (result) {
            Net.updateUser(form, (response) => {
                if (response.status === 200) {
                    props.modalClose(false);
                }
            });
        }
    }

    const onCancelHandler = () => {
        props.modalClose(false);
    }

    // Open Modal and setClickedBtnName
    const onClickModalOpen = () => {
        setModalState({
            ...modalState,
            open: !modalState.open,
        });
    }

    const getImgFile = (dataUrl) => {
        setForm({
            ...form,
            imageDataUrl: dataUrl,
        });
    }

    return (
        <div className="w-100 h-100">
            <BasicModal
                className="w-35 h-45 overFlowYscrollView"
                title={`비밀번호 변경`}
                content={
                    <ModalContent
                        username={form.username}
                        modalClose={(e) => setModalState({
                            ...modalState,
                            open: e
                        })
                        }
                    />
                }
                modalOpen={modalState.open}
                modalClose={(e) => setModalState({
                    ...modalState,
                    open: e
                })}
                sendData={modalState.data}
                //rcvData={rcvData}
            />
            <div className="h-100">
                <div className="flex h-90 overFlowYscrollView">
                    <div className="w-60 h-90">
                        <div className="ml-55 mt-12">
                            <div className="flex mb-12 w-90">
                                <CustomTextField
                                    className="w-65 h-26px"
                                    title="사용자 ID"
                                    name="username"
                                    required={true}
                                    disabled={true}
                                    placeholder=""
                                    customOnChange={textFieldHandler}
                                    value={form}
                                />
                            </div>
                            <div className="flex mb-12 w-90">
                                <CustomTextField
                                    className="w-65 h-26px"
                                    title="사용자명"
                                    name="name"
                                    required={false}
                                    disabled={false}
                                    validLen="15"
                                    placeholder=""
                                    customOnChange={textFieldHandler}
                                    value={form}
                                />
                            </div>
                            <div className="flex mb-12 w-90">
                                <CustomTextField
                                    className="w-65 h-26px"
                                    title="별칭"
                                    name="nickname"
                                    required={true}
                                    disabled={false}
                                    validLen="15"
                                    placeholder=""
                                    customOnChange={textFieldHandler}
                                    value={form}
                                />
                                <div className="mt-auto mb-auto">
                                    <button
                                        className="w-78px h-22px duplicateBtn"
                                        onClick={() => onDuplicateHandler("nickname")}
                                    >중복체크
                                    </button>
                                </div>
                            </div>
                            <div className="flex mb-12 w-90">
                                <CustomTextField
                                    className="w-65 h-26px"
                                    title="이메일"
                                    name="email"
                                    required={false}
                                    disabled={false}
                                    placeholder=""
                                    customOnChange={textFieldHandler}
                                    value={form}
                                />
                            </div>
                            <div className="flex mb-12 w-90">
                                <CustomTextField
                                    className="w-65 h-26px"
                                    title="핸드폰번호"
                                    name="phoneNumber"
                                    required={true}
                                    disabled={false}
                                    validLen="15"
                                    validRegExp={/[ㄱ-ㅎ|ㅏ-ㅣ|가-힣|a-zA-Z\{\}\[\]\/?.,;:|\)*~`!^\-+<>@\#$%&\\\=\(\'\"]/g}
                                    placeholder=""
                                    customOnChange={textFieldHandler}
                                    value={form}
                                />
                                <div className="mt-auto mb-auto">
                                    <button
                                        className="w-78px h-22px duplicateBtn"
                                        onClick={() => onDuplicateHandler("phoneNumber")}
                                    >중복체크
                                    </button>
                                </div>
                            </div>
                            <div className="flex mb-12 w-90">
                                <CustomTextField
                                    className="w-65 h-26px"
                                    title="연락처"
                                    name="contact"
                                    required={false}
                                    disabled={false}
                                    validLen="15"
                                    validRegExp={/[ㄱ-ㅎ|ㅏ-ㅣ|가-힣|a-zA-Z\{\}\[\]\/?.,;:|\)*~`!^\-+<>@\#$%&\\\=\(\'\"]/g}
                                    placeholder=""
                                    customOnChange={textFieldHandler}
                                    value={form}
                                />
                            </div>
                            <div className="flex mb-12 w-90">
                                <label className="label_title">비밀번호 재설정</label>
                                <button
                                    className="w-90px h-22px duplicateBtn"
                                    onClick={onClickModalOpen}
                                >재설정
                                </button>
                            </div>
                            <div className="flex mb-12 w-90">
                                <CustomTextField
                                    className="w-65 h-26px"
                                    title="고객사"
                                    name="customer.name"
                                    required={false}
                                    disabled={form.customer.name || form.menuGroup}
                                    customOnChange={textFieldHandler}
                                    value={form}
                                />
                            </div>
                            <div className="flex mb-12 w-90">
                                <CustomTextField
                                    className="w-65 h-26px"
                                    title="현장"
                                    name="site.name"
                                    required={false}
                                    disabled={form.site.name || form.menuGroup}
                                    customOnChange={textFieldHandler}
                                    value={form}
                                />
                            </div>
                            <div className="flex mb-12 w-90">
                                <CustomTextField
                                    className="w-65 h-26px"
                                    title="장소"
                                    name="place.name"
                                    required={false}
                                    disabled={form.place.name || form.menuGroup}
                                    customOnChange={textFieldHandler}
                                    value={form}
                                />
                            </div>
                            <div className="flex mb-12 w-90">
                                <CustomTextField
                                    className="w-65 h-26px"
                                    title="메뉴그룹"
                                    name="menuGroupName"
                                    required={false}
                                    disabled={form.menuGroup || form.menuGroup}
                                    customOnChange={textFieldHandler}
                                    value={form}
                                />
                            </div>
                            <div className="flex w-90 mb-8">
                                <CustomTextField
                                    className="w-65 h-26px"
                                    title="권한설정"
                                    name="authName"
                                    required={false}
                                    disabled={form.authList || form.menuGroup}
                                    customOnChange={textFieldHandler}
                                    value={form}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="w-40 h-90 ">
                        <div className="w-100 mt-12" style={{display: "block"}}>
                            <div className="floatC mr-2p">
                                <ImgUpload
                                    title={"사진"}
                                    imgUpload={getImgFile}
                                    imgSize={"300px"}
                                    imgFile={form.imageDataUrl}
                                />
                            </div>

                        </div>
                    </div>
                </div>
                <div className="footer_btn h-10">
                    <button className="w-160px h-30px addBtn mr-10" onClick={onSubmitHandler}>저장</button>
                    <button className="w-160px h-30px cancelBtn" onClick={onCancelHandler}>취소</button>
                </div>
            </div>
        </div>
    )
}

export default withRouter(MyDetail);

export const ModalContent = (props) => {
    const {username, modalClose} = props;
    const [newPassword, setNewPassword] = useState("");
    const userInfo = JSON.parse(sessionStorage.getItem('user'));

    const textFieldHandler = (e) => {
        if (e.target.name === "newPassword") {
            setNewPassword(e.target.value);
        }
    }

    const chkPassword = () => {
        const pwPattern = /(?=.*\d)(?=.*[~`!@#$%\\^&*()-])(?=.*[a-zA-Z]).{8,50}$/;

        if (!newPassword) {
            alert("새 비밀번호를 입력해 주세요.");
        } else if (!pwPattern.test(newPassword)) {
            alert("비밀번호는 영문, 숫자, 특수문자 포함 8글자 이상이어야 합니다.");
        } else {
            Net.changePassword({username: username, resetPassword: newPassword}, response => {
                if(userInfo.temporaryPassword){
                    alert("재 로그인 부탁드립니다.");
                    Net.logout((response) => {
                        if (response.status === 200) {
                            history.replace("/login");
                        }
                    })
                }
                handleClose();
            })
        }
    }
    const handleClose = () => {
        modalClose(false);
    };

    return (
        <div>
            <div className="flex w-90">
                <label className="label_title">새 비밀번호*</label>
                <input
                    className="w-65 h-26px textField"
                    type="password"
                    name="newPassword"
                    onChange={(e) => textFieldHandler(e)}
                    value={newPassword}
                />
            </div>
            <div><label className="label_title w-100">※ 비밀번호는 영문, 숫자, 특수문자 포함 8글자 이상이어야 합니다.</label></div>
            <div className="footer_btn h-10 mt-10">

                <div className="">
                    <button className="w-80px h-30px addBtn mr-10" onClick={chkPassword}>확인</button>
                    <button className="w-80px h-30px cancelBtn" onClick={handleClose}>취소</button>
                </div>


            </div>

        </div>
    )

}
