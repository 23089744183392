import React, { useEffect, useState } from 'react';
import { useHistory, withRouter } from 'react-router-dom';
import { CustomTextField, CustomRadioButton, QuillEditor, CustomSelectBox, ImgUpload } from 'components/molecules';
import store from 'scripts/store';
import Net from 'scripts/net';
import Common from 'scripts/common';
import {toJS} from "mobx";
import TextareaAutosize from "react-textarea-autosize"

const FeedRegist = ({match}) => {

    const cloneUser = toJS(store.user)

    const [registPage, setRegistPage] = useState(true);
    const [customerList, setCustomerList] = useState([{
        code: '',
        name: '고객사명 선택'
    }]);
    const [siteList, setSiteList] = useState([{
        code: '',
        name: '현장명 선택'
    }]);
    const [checkSubmit, setCheckSubmit] = useState(false);
    const [form, setForm] = useState({
        user: cloneUser,
        feedId: '',
        contents: '',
        customer : cloneUser.menuGroup === 'ADMIN' ? {code: '',name: '',} : cloneUser.customer,
        site: cloneUser.menuGroup === 'OPERATOR' ? cloneUser.site : { code: '', name: '',},
        place : {
            code:'',
            name:''
        },
        publicYn: true,
        reportedDeleteYn: false,
        image: '',
        imageDataUrl: '',
    });

    useEffect( () => {
        store.openedPage = 'Feed 등록/수정';

        // 상세 조회(수정 목적으로 진입시)
        if(match.params.feedId !== undefined) {
            getFeedOne(match.params, (response) => {
                if(response.status === 200) {
                    if(!response.data.customer){
                        response.data.customer = {code: "", name: ""};
                    }
                    if(!response.data.site){
                        response.data.site = {code: "", name: ""};
                    }
                    if(response.data.place === "ALL"){
                        response.data.site = {code: "", name: ""};
                    }
                    setForm({
                        ...form,
                        ...response.data,
                    });
                    // 등록페이지 여부 확인
                    setRegistPage(false);

                }
            });
        }
        // 고객사 전체 목록
        getCustomerList((response) => {
            setCustomerList(response);
        });
    }, []);

    useEffect(()=>{
        console.log(checkSubmit);
        if(checkSubmit){
            saveLogic(form);
        }
    },[checkSubmit])

    useEffect(() => {
        if(form.customer.code !== '') {
            getSiteList(form.customer.code, (response) => {
                setSiteList(response);
            })
        } else {
            setSiteList([
                {
                    code: '',
                    name: '현장명 선택'
                }
            ])
        }
    }, [form.customer.code]);

    const getCustomerList = (callback) => {
        Net.getCustomerList(
            {currentPage: '', rowPerPage: '', searchText: ''}, (response) => {
            if(response.status === 200) {
                let newArray = customerList;
                response.data.content.forEach(items => {
                    newArray = [
                        ...newArray,
                        {
                            code: items.code,
                            name: items.name
                        }
                    ]
                });
                callback(newArray);
            }
        });
    }

    const getSiteList = (code, callback) => {
        Net.getSitesList(
            {
                currentPage: '',
                rowPerPage: '',
                searchText: '',
                customerCode: code,
            }, (response) => {
            if(response.status === 200) {
                let newArray = [
                    {
                        code: '',
                        name: '현장명 선택'
                    }
                ];
                response.data.content.forEach(items => {
                    newArray = [
                        ...newArray,
                        {
                            code: items.code,
                            name: items.name
                        }
                    ]
                });
                callback(newArray);
            } else if(response.status === 204) {
                let newArray = [
                    {
                        code: '',
                        name: '현장명 선택'
                    }
                ];
                callback(newArray);
            }
        });
    }

    const history = useHistory();

    const getFeedOne = (params, callback) => {
        Net.getFeedOne(params.feedId, (response) => {
            callback(response);
        });
    }

    const textFieldHandler = (name, value) => {
        const nextForm = {
            ...form,
            [name]: value
        }
        setForm(nextForm);
    }

    const radioBtnHandler = (name, value) => {
        let nextForm;
        if(name === "authGroup"){
            nextForm = {
                ...form,
                [name]: value,
            }
        } else {
            nextForm = {
                ...form,
                [name]: value === 'true',
            }
        }
        setForm(nextForm);
    }

    const selectBoxHandler = (name, value) => {
        switch (name) {
            case 'customer.code':
                setForm({
                    ...form,
                    customer: {
                        code: value.code,
                        name: value.name
                    },
                    site: {
                        code: "",
                        name: ""
                    }
                });
                break;
            case 'site.code':
                setForm({
                    ...form,
                    site: {
                        code: value.code,
                        name: value.name
                    }
                });
                break;
            default:
                break;
        }
    }

    const onSubmitHandler = () => {

        if( !form.contents ) {
            alert('내용을 입력해 주세요.');
            return false;
        }

        console.log(cloneUser)

        if (cloneUser.menuGroup === 'OPERATOR') {
            setForm({
                ...form,
                customer: cloneUser.customer,
                site: cloneUser.site ,
            });
        } else if (cloneUser.menuGroup === 'MANAGER') {
                if (!form.site.code) {
                    alert('현장명을 선택해 주세요.');
                    document.getElementsByName('site.code')[0].focus();
                    return false;
                }
                setForm({
                    ...form,
                    customer: cloneUser.customer,
                });
        } else {
                if (!form.customer.code) {
                    alert('고객사를 선택해 주세요.');
                    document.getElementsByName('customer.code')[0].focus();
                    return false;
                }
                if (!form.site.code) {
                    alert('현장명을 선택해 주세요.');
                    document.getElementsByName('site.code')[0].focus();
                    return false;
                }
        }
        setCheckSubmit(true);
    }

    const saveLogic = (saveform) => {
        let result = window.confirm('저장하시겠습니까?');

        if(result) {
            if(registPage) {
                Net.addFeed(saveform, (response) => {
                    if(response.status === 200 || response.status === 201) {
                        history.push('/community/feed/list');
                    }
                });
            } else {
                Net.updateFeed(saveform, (response) => {
                    if(response.status === 200) {
                        history.push('/community/feed/list');
                    }
                });
            }
        }
    }

    const onCancelHandler = () => {
        history.push('/community/feed/list');
    }

    const getImgFile = (dataUrl) => {
        setForm({
            ...form,
            imageDataUrl: dataUrl,
        });
    }


    const onRemoveHandler = () => {
        const result = window.confirm('삭제하시겠습니까?');
        if(result) {
            Net.removeFeed([{itemId: form.feedId}], (response) => {
                if(response) {
                    history.push('/community/feed/list');
                }
            });
        }
    }

    return (
        <div className="w-100 h-100">
            <div className="w-100 h-3 display_table"/>
            <div className="w-100 h-1px bgC-efefef" />
            <div className="h-95">
                <div className="flex h-90">
                    <div className="w-50 h-100 overFlowYscrollView">
                        <div className="ml-55 mt-12">
                            <div className="flex mb-3 w-90">
                                <label className="label_title">내용 *</label>
                                <div className="w-100">
                                    <TextareaAutosize
                                        name="content"
                                        className="board_content form-control col-12 h-25"
                                        onChange={(event)=>{
                                            setForm({...form,
                                                contents: event.target.value});
                                        }}
                                        value={form.contents}
                                        minRows={3}
                                        type="text"
                                        />
                                </div>
                            </div>
                            {
                                cloneUser.menuGroup === 'ADMIN' ? (
                                    <div className="flex mb-3 w-90">
                                        <label className="label_title">고객사 {
                                             form.classification==='INCIDENT'  ? <></> : <>*</>
                                        }</label>
                                        <CustomSelectBox
                                            name="customer.code"
                                            categoryList={customerList}
                                            value={form.customer.code}
                                            onChangeHandler={(value, name) => selectBoxHandler('customer.code', {code: value, name: name})}
                                            callbackCodeName="Y"
                                            disabled={!registPage}
                                        />
                                        {
                                            registPage ? <div/> :
                                                <div className="label_summary ml-10">※ 수정시 고객사 및 현장은 변경 불가능 합니다. </div>
                                        }
                                    </div>
                                ) : <></>
                            }
                            {
                                cloneUser.menuGroup === 'OPERATOR' ? <></> :(
                                    <div className="flex mb-3 w-90">
                                        <label className="label_title">현장 *</label>
                                                <CustomSelectBox
                                                    name="site.code"
                                                    categoryList={siteList}
                                                    value={form.site.code}
                                                    onChangeHandler={(value, name) => selectBoxHandler('site.code', {code: value, name: name})}
                                                    callbackCodeName="Y"
                                                    disabled={!registPage}
                                                />
                                    </div>
                                )
                            }
                            {
                                cloneUser.menuGroup === 'OPERATOR' ? <></> : (<>
                                        <div className="flex mb-3 ">
                                            <div className="w-110 h-26px">
                                                <CustomTextField
                                                    className="w-65"
                                                    title="장소 코드"
                                                    name="place.code"
                                                    required={false}
                                                    disabled={false}
                                                    validLen="15"
                                                    //validRegExp={/[\{\}\[\]\/?.,;:|\)*~`!^\-_+<>@\#$%&\\\=\(\'\"]/gi}
                                                    placeholder="admin"
                                                    customOnChange={textFieldHandler}
                                                    value={form}
                                                    disable={true}
                                                />
                                            </div>
                                        </div>
                                        <div className="flex mb-3 col-12 ">
                                            <div className="w-110 h-26px">
                                                <CustomTextField
                                                    className="w-65"
                                                    title="장소 이름"
                                                    name="place.name"
                                                    required={false}
                                                    disabled={false}
                                                    validLen="15"
                                                    //validRegExp={/[\{\}\[\]\/?.,;:|\)*~`!^\-_+<>@\#$%&\\\=\(\'\"]/gi}
                                                    placeholder="admin"
                                                    customOnChange={textFieldHandler}
                                                    value={form}
                                                    disable={true}
                                                />
                                            </div>
                                        </div>
                                        <div className="flex w-90 mb-3">
                                            <label className="label_title">신고 차단 유무</label>
                                            <CustomRadioButton
                                                className="mr-70"
                                                title="사용"
                                                id="radioBtn3"
                                                name="reportedDeleteYn"
                                                value={true}
                                                checked={form.reportedDeleteYn}
                                                customOnChange={radioBtnHandler}
                                            />
                                            <CustomRadioButton
                                                title="사용안함"
                                                id="radioBtn4"
                                                name="reportedDeleteYn"
                                                value={false}
                                                checked={!form.reportedDeleteYn}
                                                customOnChange={radioBtnHandler}
                                            />
                                        </div>
                                    </>
                                )
                            }

                            <div className="flex w-90 mb-3">
                                <label className="label_title">사용유무</label>
                                <CustomRadioButton
                                    className="mr-70"
                                    title="사용"
                                    id="radioBtn1"
                                    name="publicYn"
                                    value={true}
                                    checked={form.publicYn}
                                    customOnChange={radioBtnHandler}
                                />
                                <CustomRadioButton
                                    title="사용안함"
                                    id="radioBtn2"
                                    name="publicYn"
                                    value={false}
                                    checked={!form.publicYn}
                                    customOnChange={radioBtnHandler}
                                />
                            </div>
                        </div>
                    </div>
                            <div className="w-45 h-100">
                            <div className="flex w-100">
                                <div className="w-90 h-100 mt-12 ml-100" style={{display:"block"}} >
                                    <div className="floatC" >
                                        <ImgUpload
                                            title={"사진"}
                                            imgUpload={getImgFile}
                                            imgSize={"300px"}
                                            imgFile={form.imageDataUrl}
                                        />
                                    </div>
                                </div>
                        </div>
                    </div>
                </div>
                <div className="footer_btn h-10 mt-2">
                    <div className="">
                        <button className="w-160px h-30px addBtn mr-10" onClick={onSubmitHandler}>저장</button>
                        { registPage ? "" : <button className="w-160px h-30px removeBtn mr-10" onClick={onRemoveHandler}>삭제</button>}
                        <button className="w-160px h-30px cancelBtn" onClick={onCancelHandler}>취소</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default withRouter(FeedRegist);
