import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { CustomTable, SearchInputBar, CustomTextField, CustomRadioButton, BasicModal } from 'components/molecules';
import store from 'scripts/store';
import Net from 'scripts/net';

const columnList = [
    {
        code: 'code',
        title: '유형코드',
        width: '30',
    },
    {
        code: 'name',
        title: '유형명',
        width: '30',
    },
    {
        code: 'usable',
        title: '사용 여부',
        width: '30',
    },
];

export const CodeTypeManageList = (props) => {

    // useHistory 선언
    const history = useHistory();

    const [contentData, setContentData] = useState([]);
    const [checkedList, setCheckedList] = useState([]);

    const [pageState, setPageState] = useState({
        currentPage: 0,
        rowPerPage: 13,
        totalElementsCnt: 0,
        drawElementsCnt: 0,
        searchText: '',
    });

    const [codeType, setCodeType] = useState('');

    const [modalState, setModalState] = useState({
        open: false,
        data: [],
    });

    const [programAuth, setProgramAuth] = useState({});

    useEffect(() => {
        store.openedPage = '유형코드 목록';
        pageAuthInfo();
    }, []);

    useEffect(() => {
        loadData();
    }, [pageState.currentPage, pageState.searchText]);

    const pageAuthInfo = () => {
        const authGroupCode = props.userInfo.authList[0];
        const menuId = sessionStorage.getItem('menuId');

        Net.getAuthGroupMenuList(menuId, (response) => {
            if(response.status === 200) {
                response.data.map(item => {
                    if(item.authGroup === authGroupCode && item.authGroupMenu) {
                        setProgramAuth({
                            searchAuth: Boolean(item.authGroupMenu.searchAuth),
                            saveAuth: Boolean(item.authGroupMenu.saveAuth),
                            excelAuth: Boolean(item.authGroupMenu.excelAuth),
                            deleteAuth: Boolean(item.authGroupMenu.deleteAuth),
                        });
                    }
                });
            }
        });
    }

    const loadData = () => {
        Net.getCodeTypeList(pageState, (response) => {
            if(response.status === 200) {
                setContentData(response.data.content);
                setPageState({
                    ...pageState,
                    totalElementsCnt: response.data.totalElements,
                    drawElementsCnt: response.data.numberOfElements,
                });
            } else if(response.status === 204) {
                setContentData([]);
                setPageState({
                    ...pageState,
                    totalElementsCnt: 0,
                    drawElementsCnt: 0,
                });
            }
        });
    }

    const onMovePage = (e) => {
        setPageState({
            ...pageState,
            currentPage: e-1
        });
    }

    const onSubmitHandler = (e) => {
        setPageState({
            ...pageState,
            currentPage: 0,
            searchText: e.searchText
        });
    }

    const addList = () => {
        setCodeType('');
        onClickModalOpen();
    }

    const onClickRow = (e) => {
        setCodeType(e.code);
        onClickModalOpen();
    }

    const onClickModalOpen = () => {
        setModalState({
            ...modalState,
            open: !modalState.open,
        });
    }

    const onClickModalClose = () => {
        setModalState({
            ...modalState,
            open: !modalState.open,
        });
        loadData();
    }

    const removeList = () => {
        const result = window.confirm('삭제하시겠습니까?');
        const removeCodeList = [];
        checkedList.map((id) => {
            const node = document.querySelector(`#${id}`);
            const nodeTr = node.closest('tr');
            const codeValue = nodeTr.querySelector('td[name=code]').textContent;
            removeCodeList.push(codeValue);
        });


        if(result) {
            Net.getCodeTypeDetailList(removeCodeList, (response) => {
                const list = response.filter( item => item.data.length > 0);
                if( list != undefined && list.length > 0){
                    const msg = "유형코드 중 세부 코드가 남아있는 유형은 삭제 할 수 없습니다." + "\n" + list.map( item => item.data[0].codeType+" ");
                    window.alert(msg);
                }else{
                    Net.removeCodeTypeList(removeCodeList, (response) => {
                        if(response) {
                            loadData();
                        }
                    });
                }
            })
        }
    }

    return (
        <div className="w-100 h-100">
            <BasicModal
                className=""
                title="유형코드 등록/수정"
                content={
                    <ModalContent
                        codeType={codeType}
                        modalClose={onClickModalClose}
                    />
                }
                modalOpen={modalState.open}
                modalClose={(e) => setModalState({
                    ...modalState,
                    open: e
                })}
            />
            <div className="w-100 h-7 display_table">
                <SearchInputBar
                    className="w-100"
                    searchCategory={false}
                    searchCategoryGroup=""
                    searchText={true}
                    searchPlaceHolder="유형명을 입력해 주세요."
                    searchBtn={true}
                    searchBtnTitle="검색"
                    dynamicSearch={false}
                    onSubmit={onSubmitHandler}
                />
                {
                    programAuth.saveAuth &&
                    <div className="display_table_cell v-middle">
                        <button
                            className="addBtn w-90px h-30px floatR mr-20"
                            onClick={addList}
                        >등록</button>
                    </div>
                }

            </div>
            <div className="w-100 h-1px bgC-efefef" />
            <div className="flex">
                <div className="w-100 h-93 overflowYscroll">
                    <p className="totalCnt">Total {pageState.totalElementsCnt}</p>
                    {
                        programAuth.deleteAuth && checkedList.length > 0 &&
                        <button
                            className="removeBtn w-90px h-30px floatR mt-7 mr-20"
                            onClick={removeList}
                        >삭제</button>
                    }
                    <CustomTable
                        columnList = {columnList}
                        contentData = {contentData}
                        checkbox = {programAuth.deleteAuth}
                        checkedList = {(checkedList) => {setCheckedList(checkedList)} }
                        paging = {true}
                        totalElementsCnt = {pageState.totalElementsCnt}
                        rowPerPage = {pageState.rowPerPage}
                        currentPage = {pageState.currentPage}
                        drawElementsCnt = {pageState.drawElementsCnt}
                        pagination = {onMovePage}
                        rowClickEvt = {true}
                        onClickRow = {onClickRow}
                        // sort = {true}
                    />
                </div>
            </div>
        </div>
    )
}

export const ModalContent = (props) => {

    const { codeType } = props;

    const [form, setForm] = useState({
        code: '',
        name: '',
        usable: true,
    });
    const [registPage, setRegistPage] = useState(true);

    useEffect(() => {
        if(codeType !== "") {
            Net.getCodeTypeOne(codeType, (response) => {
                if(response.status === 200) {
                    setForm({
                        ...form,
                        ...response.data,
                    });
                    // 등록페이지 여부 확인
                    setRegistPage(false);
                }
            });
        }
    }, [])

    const textFieldHandler = (name, value) => {
        const nextForm = {
            ...form,
            [name]: value
        }
        setForm(nextForm);
    }

    const radioBtnHandler = (name, value) => {
        const nextForm = {
            ...form,
            [name]: value === 'true' ? true : false,
        }
        setForm(nextForm);
    }

    const onSubmitHandler = () => {
        if(form.code === '') {
            alert('유형코드는 필수값입니다.');
            document.getElementsByName('code')[0].focus();
            return false;
        }
        if(form.name === '') {
            alert('유형명은 필수값입니다.');
            document.getElementsByName('name')[0].focus();
            return false;
        }

        var result = window.confirm('저장하시겠습니까?');
        if(result) {
            if(registPage) {
                Net.getCodeTypeOne( form.code, (response) => {
                    if(response.data){
                        alert('이미 등록된 유형코드 입니다.');
                    } else if(response.data.length === 0|| (response.status === 404 && response.data.cause.includes('does not exist'))){
                        Net.addCodeType(form, (response) => {
                            if(response.status === 200) {
                                onCancelHandler();
                            }
                        });
                    }
                })

            } else {
                Net.updateCodeType(form, (response) => {
                    if(response.status === 200) {
                        onCancelHandler();
                    }
                });
            }
        }
    }

    const onRemoveHandler = () => {
        const result = window.confirm('삭제하시겠습니까?');
        if(result) {
            Net.getCodeTypeDetailList([form.code], (response) => {
                const list = response.filter(item => item.data.length > 0);
                if (list != undefined && list.length > 0) {
                    const msg = "유형코드 중 세부 코드가 남아있는 유형은 삭제 할 수 없습니다." + "\n";
                    window.alert(msg);
                } else {
                    Net.removeCodeTypeList([form.code], (response) => {
                        if (response) {
                            onCancelHandler();
                        }
                    });
                }
            })
        }
    }

    const onCancelHandler = () => {
        props.modalClose();
    }

    return (
        <div className="w-100 h-100">
            <div className="h-90">
                <div className="flex mb-12 w-100">
                    <CustomTextField
                        className="w-65 h-26px"
                        title="유형코드"
                        name="code"
                        required={true}
                        disabled={false}
                        //validRegExp={/[ㄱ-ㅎ|ㅏ-ㅣ|가-힣|a-z0-9\{\}\[\]\/?.,;:|\)*~`!^\-+<>@\#$%&\\\=\(\'\"]/g}
                        //placeholder="코드는 대문자이며, 단어와 단어 사이는 언더바 사용"
                        customOnChange={textFieldHandler}
                        value={form}
                        disabled={codeType !== "" ? true : false}
                    />
                </div>
                <div className="flex mb-12 w-100">
                    <CustomTextField
                        className="w-65 h-26px"
                        title="유형명"
                        name="name"
                        required={true}
                        disabled={false}
                        validLen="15"
                        //validRegExp={/[\{\}\[\]\/?.,;:|\)*~`!^\-_+<>@\#$%&\\\=\(\'\"]/gi}
                        //placeholder="유형명은 특수문자 제외 최대 15자"
                        customOnChange={textFieldHandler}
                        value={form}
                    />
                </div>
                <div className="flex w-100">
                    <label className="label_title">사용유무</label>
                    <CustomRadioButton
                        className="mr-60"
                        title="사용"
                        id="radioBtn1"
                        name="usable"
                        value={true}
                        checked={form.usable}
                        customOnChange={radioBtnHandler}
                    />
                    <CustomRadioButton
                        title="사용안함"
                        id="radioBtn2"
                        name="usable"
                        value={false}
                        checked={!form.usable}
                        customOnChange={radioBtnHandler}
                    />
                </div>
            </div>
            <div className="footer_btn h-10 mt-50 mb-30">
                <div className="">
                    <button className="w-80px h-30px addBtn mr-10" onClick={onSubmitHandler}>저장</button>
                    { registPage ? "" : <button className="w-80px h-30px removeBtn mr-10" onClick={onRemoveHandler}>삭제</button>}
                    <button className="w-80px h-30px cancelBtn" onClick={onCancelHandler}>취소</button>
                </div>
            </div>
        </div>
    )
}
