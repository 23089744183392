import React from 'react'
import { Switch, Route } from 'react-router-dom';
import { ProgramManageList } from './ProgramManagePage';
import { MenuManageList } from './MenuManagePage';
import { CodeTypeManageList } from './CodeTypeManagePage';
import { CodeManageList } from './CodeManagePage';
import { PolicyManageList, PolicyManageRegist } from './PolicyManagePage';
import NotFound from "../../components/templates/NotFound";
import {VersionManageList} from "./VersionManagePage";

export const SettingPage = ({userInfo}) => {
    return (
        <Switch>
            <Route path={"/setting/program/list"} exact children={<ProgramManageList userInfo={userInfo}/>}/>
            <Route path={"/setting/menu/list"} exact children={<MenuManageList userInfo={userInfo}/>}/>
            <Route path={"/setting/codetype/list"} exact children={<CodeTypeManageList userInfo={userInfo}/>}/>
            <Route path={"/setting/code/list"} exact children={<CodeManageList userInfo={userInfo}/>}/>
            <Route path={"/setting/version/list"} exact children={<VersionManageList userInfo={userInfo}/>}/>
            <Route path={"/setting/policy/list"} exact children={<PolicyManageList userInfo={userInfo}/>}/>
            <Switch>
                <Route path={"/setting/policy/regist/:policyId"} children={<PolicyManageRegist/>} />
                <Route path={"/setting/policy/regist"} children={<PolicyManageRegist/>} />
            </Switch>
            <Route path={"*"}>
                <NotFound />
            </Route>
        </Switch>
    )
}
