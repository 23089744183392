/* eslint-disable no-lone-blocks */
import React, {useEffect, useState} from 'react';
import Net from 'scripts/net';
import Common from 'scripts/common';
import { CustomTextField, CustomRadioButton   } from 'components/molecules';
import {useDidMountEffect} from "scripts/hooks/useDidMountEffect";
import TextareaAutosize from "react-textarea-autosize";
export const FeedCommentModal = (props) => {

    const [registPage, setRegistPage] = useState(true);
    const [form, setForm] = useState({
        user : '',
        contents : '',
        parentId : '',
        commentsId : '',
        classification : 'feed',
        reportedDeleteYn : false
    });
    const [feed, setFeed] = useState({
        contents : '',
        place : '',
        site : {
            code : '',
            name : ''
        },
        username : ''
    })

    useEffect(() => {
        if(props.data.parentId !== undefined){
            Net.getFeedOne(props.data.parentId, (response) => {
                setFeed({
                    contents: response.data.contents,
                    place : response.data.place.name,
                    site : response.data.site,
                    username : response.data.user.username
                })
            });
        }

        if (props.data.commentsId !== undefined) {
            setForm({
                ...form,
                user: {
                    username: props.data.user.username,
                    userFullName: props.data.user.nickname,
                    name: props.data.user.name,
                    email: props.data.user.email,
                },
                parentId: props.data.parentId,
                commentsId: props.data.commentsId,
                contents: props.data.contents
            })
            setRegistPage(false);
        } else {
            setForm({
                ...form,
                user: {
                    username: props.data.user.username,
                    userFullName: props.data.user.nickname,
                    name: props.data.user.name,
                    email: props.data.user.email,
                },
                parentId: props.data.parentId,
            })
            setRegistPage(true);
        }
    },[]);


    const textFieldHandler = (name, value) => {
        const nextForm = {
            ...form,
            [name] : value
        }
        setForm(nextForm);
    }
    const radioBtnHandler = (name, value) => {
        let nextForm;
        {
            nextForm = {
                ...form,
                [name]: value === 'true',
            }
        }
        setForm(nextForm);
    }


    const onSubmitHandler = () => {

        let result = window.confirm('댓글을 등록 하시겠습니까?');
        if(result){
            if(registPage){
                Net.addFeedComment(form , callback => {
                    if(callback.status === 200 ){
                        props.modalClose(false);
                        props.reload('reload');
                    }
                })
            }else {
                Net.updateFeedComment(form , callback => {
                    if(callback.status === 200 ){
                        props.modalClose(false);
                        props.reload('reload');
                    }
                })
            }
        }
    }

    return (
        <div className="w-100 h-100">
            <div className='w-100 h-1px bgC-efefef'/>
            <div className='h-93'>
                <div className='row flex h-90 justify-content-center'>
                    <label className="label_title">내용 *</label>
                    <div className="flex col-10 mr-20">
                        <TextareaAutosize
                            name="content"
                            className="board_content form-control col-12 h-25"
                            onChange={(event)=>{
                                setForm({...form,
                                    contents: event.target.value});
                            }}
                            value={form.contents}
                            minRows={3}
                            type="text"
                        />
                    </div>

                    <div className="w-50">
                        <p className="mb-2 text-gray-900 max-w-none dark:text-gray-400 mb-3">
                            피드 정보
                        </p>
                        <div className='flex mb-12 col-11'>
                            <CustomTextField
                                className="h-30px w-200px"
                                title="피드 게시자"
                                name="username"
                                value={feed}
                                disabled={true}
                            />
                        </div>
                        <div className='flex mb-12 col-11'>
                            <CustomTextField
                                className="h-30px w-200px"
                                title="현장"
                                name="site.name"
                                value={feed}
                                disabled={true}
                            />
                        </div>
                        <div className='flex mb-12 col-11'>
                            <CustomTextField
                                className="h-30px w-200px"
                                title="장소"
                                name="place"
                                value={feed}
                                disabled={true}
                            />
                        </div>
                        <div className='flex mb-12 col-11'>
                            <div className="col-2">내용</div>
                            <div className="col-10">
                                <TextareaAutosize
                                    name="content"
                                    className="board_content form-control"
                                    value={feed.contents}
                                    minRows={4}
                                    disabled={true}
                                    type="text"
                                />
                            </div>

                        </div>
                    </div>
                    <div className="w-50">
                        <p className="mb-2 text-gray-900 max-w-none dark:text-gray-400 mb-3">
                            댓글 정보
                        </p>
                        <div className='flex mb-12 col-11'>
                            <CustomTextField
                                className="h-30px w-200px"
                                title="댓글 글쓴이"
                                name="user.userFullName"
                                customOnChange={textFieldHandler}
                                value={form}
                                disabled={true}
                            />
                        </div>
                        <div className='flex mb-12 col-11'>
                            <CustomTextField
                                className="h-30px w-200px"
                                title="Classification"
                                name="classification"
                                customOnChange={textFieldHandler}
                                value={form}
                                disabled={true}
                            />
                        </div>
                        <div className='flex mb-12 col-11'>
                            <CustomTextField
                                className="h-30px w-200px"
                                title="Feed ID"
                                name="parentId"
                                customOnChange={textFieldHandler}
                                value={form}
                                disabled={true}
                            />
                        </div>

                        {
                            form.commentsId !== '' ? (
                                <div className='flex mb-12 col-11'>
                                    <CustomTextField
                                        className="h-30px w-200px"
                                        title="Comment ID"
                                        name="commentsId"
                                        required={true}
                                        customOnChange={textFieldHandler}
                                        value={form}
                                        disabled={true}
                                    />
                                </div>
                            ) : <></>
                        }
                        <div className="flex w-90 mb-3">
                            <label className="label_title">신고 차단 유무</label>
                            <CustomRadioButton
                                className="mr-70"
                                title="사용"
                                id="radioBtn1"
                                name="reportedDeleteYn"
                                value={true}
                                checked={form.reportedDeleteYn}
                                customOnChange={radioBtnHandler}
                            />
                            <CustomRadioButton
                                title="사용안함"
                                id="radioBtn2"
                                name="reportedDeleteYn"
                                value={false}
                                checked={!form.reportedDeleteYn}
                                customOnChange={radioBtnHandler}
                            />
                        </div>
                    </div>



                </div>
                <div className="footer_btn h-10 mt-1">
                    <div className="">
                        <button className="w-160px h-30px addBtn mr-10 ml-10" onClick={onSubmitHandler}>등록</button>
                        <button className="w-160px h-30px cancelBtn mr-10" onClick={()=> {
                            props.modalClose(false)
                        }}>취소</button>
                    </div>
                </div>
            </div>
        </div>
    )

}
