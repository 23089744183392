import React, {useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom'
import {CustomTable, SearchInputBar} from 'components/molecules';
import store from 'scripts/store';
import Net from 'scripts/net';

const columnList = [
    {
        code: 'username',
        title: '사용자ID',
        width: '8',
    },
    {
        code: 'name',
        title: '사용자명',
        width: '8',
    },
    {
        code: 'nickname',
        title: '별칭',
        width: '10',
    },
    {
        code: 'site.name',
        title: '현장명',
        width: '10',
    },
    {
        code: 'place.name',
        title: '장소명',
        width: '8',
    }
];

export const UserList = (props) => {
    // useHistory 선언
    const history = useHistory();

    const [contentData, setContentData] = useState([]);
    const [checkedList, setCheckedList] = useState([]);
    const [searchCategory, setSearchCategory] = useState([
        [
            {code: '', name: '현장명 선택'}
        ]
    ]);

    const [pageState, setPageState] = useState({
        currentPage: 0,
        rowPerPage: 13,
        totalElementsCnt: 0,
        drawElementsCnt: 0,
        searchText: '',
        searchCategory1: '',
        searchCategory2: '',
    });

    const [modalState, setModalState] = useState({
        open: false,
        data: [],
        username: '',
        clickedBtnName: '',
    });

    const [programAuth, setProgramAuth] = useState({});

    useEffect(() => {
        store.openedPage = '사용자 목록';
        getCategoryList('');
        pageAuthInfo();
    }, [])

    useEffect(() => {
        loadData();
    }, [pageState.currentPage,
        pageState.searchCategory1,
        pageState.searchCategory2,
        pageState.searchText]);

    const pageAuthInfo = () => {
        const authGroupCode = props.userInfo.authList[0];
        const menuId = sessionStorage.getItem('menuId');

        Net.getAuthGroupMenuList(menuId, (response) => {
            if (response.status === 200) {
                response.data.forEach(item => {
                    if (item.authGroup === authGroupCode && item.authGroupMenu) {
                        setProgramAuth({
                            searchAuth: Boolean(item.authGroupMenu.searchAuth),
                            saveAuth: Boolean(item.authGroupMenu.saveAuth),
                            excelAuth: Boolean(item.authGroupMenu.excelAuth),
                            deleteAuth: Boolean(item.authGroupMenu.deleteAuth),
                        });
                    }
                });
            }
        });
    }

    const loadData = () => {
        Net.getUserList(
            {
                currentPage: pageState.currentPage,
                rowPerPage: pageState.rowPerPage,
                searchText: pageState.searchText,
                searchCategory1: pageState.searchCategory1,
                searchCategory2: pageState.searchCategory2
            }, (response) => {
                if (response.status === 200) {
                    setContentData(response.data.content);
                    setPageState({
                        ...pageState,
                        totalElementsCnt: response.data.totalElements,
                        drawElementsCnt: response.data.numberOfElements,
                    });
                }
            });
    }

    const getCategoryList = () => {
        getSitesList((response2) => {
            setSearchCategory([response2]);
        });
    }

    const getRelateCategory = (selectedCategory) => {
        if (selectedCategory === "reset") selectedCategory = "";
        getSitesList((response2) => {
            setSearchCategory([
                response2
            ]);
        });
    }

    const getSitesList = (callback) => {
        Net.getSitesList(
            {currentPage: '', rowPerPage: '', searchText: '', customerCode: 'C_GREENVILL'}, (response) => {
                if (response.status === 200) {
                    let newArray = [{code: '', name: "현장명 선택"}];
                    if (response.data.content.length > 0) {
                        response.data.content.map(items => {
                            newArray = [
                                ...newArray,
                                {
                                    code: items.code,
                                    name: items.name
                                }
                            ]

                        })
                    }
                    callback(newArray);
                } else {
                    let newArray = [{code: '', name: "현장명 선택"}];
                    callback(newArray);
                }
            });
    }

    const addList = () => {
        history.push('/member/user/regist');
    }

    const removeList = () => {
        const result = window.confirm('삭제하시겠습니까?');
        const removeUserList = [];
        checkedList.map((id) => {
            const node = document.querySelector(`#${id}`);
            const nodeTr = node.closest('tr');
            const username = nodeTr.querySelector('td[name=username]').textContent;
            removeUserList.push({username: username});
        });

        if (result) {
            Net.removeUserList(removeUserList, (response) => {
                if (response) {
                    loadData();
                }
            });
        }
    }

    const onClickRow = (e) => {
        history.push(`/bridge/cloudn/regist/${e.username}`);
    }

    const tdOnClickEvent = (title, e) => {
        onClickModalOpen(title, e);

    }

    const onMovePage = (e) => {
        setPageState({
            ...pageState,
            currentPage: e - 1
        });
    }

    const onSubmitHandler = (e) => {
        setPageState({
            ...pageState,
            currentPage: 0,
            searchText: e.searchText,
            searchCategory2: e.selectedCategory[0],
        });
    }

    // Open Modal and setClickedBtnName
    const onClickModalOpen = (type, e) => {
        setModalState({
            ...modalState,
            open: !modalState.open,
            username: e.username,
            clickedBtnName: type
        });
    }

    const sendData = (e) => {
        setModalState({
            ...modalState,
            data: e,
        })
    }

    return (
        <div className="w-100 h-100">
            <div className="w-100 h-7 display_table">
                <SearchInputBar
                    className="w-100"
                    searchCategory={true}
                    searchCategoryGroup={searchCategory}
                    searchText={true}
                    searchPlaceHolder="사용자아이디 입력하세요."
                    searchBtn={true}
                    searchBtnTitle="검색"
                    dynamicSearch={false}
                    relateCategoryHandler={getRelateCategory}
                    onSubmit={onSubmitHandler}
                />
                {
                    programAuth.saveAuth &&
                    <div className="display_table_cell v-middle">
                        <button
                            className="addBtn w-90px h-30px floatR mr-20"
                            onClick={addList}
                        >등록
                        </button>
                    </div>
                }
            </div>
            <div className="w-100 h-1px bgC-efefef"/>
            <div className="w-100 h-100">
                <p className="totalCnt">Total {pageState.totalElementsCnt}</p>
                {
                    programAuth.deleteAuth && checkedList.length > 0 &&
                    <button
                        className="removeBtn w-90px h-30px floatR mt-7 mr-15"
                        onClick={removeList}
                    >삭제</button>
                }
                <CustomTable
                    columnList={columnList}
                    contentData={contentData}
                    checkbox={programAuth.deleteAuth}
                    checkedList={(checkedList) => {
                        setCheckedList(checkedList)
                    }}
                    paging={true}
                    totalElementsCnt={pageState.totalElementsCnt}
                    rowPerPage={pageState.rowPerPage}
                    currentPage={pageState.currentPage}
                    drawElementsCnt={pageState.drawElementsCnt}
                    pagination={onMovePage}
                    rowClickEvt={true}
                    onClickRow={onClickRow}
                    tdClickEvt={tdOnClickEvent}
                    // sort = {true}
                />
            </div>
        </div>
    )
}
