import React, {Fragment, useEffect, useState} from 'react';
import {CustomPagination, CustomTable, SearchInputBar, BasicModal} from 'components/molecules';
import store from 'scripts/store';
import Net from 'scripts/net';
import {toast} from "react-toastify";
import {useHistory} from "react-router-dom";
import classNames from "classnames";
import {FeedCommentModal} from "./modal/FeedCommentModal";

const columnList = [
    {
        code: 'feedId',
        title: 'feedId',
        width: '8',
        hidden: true,
    },
    {
        code: 'createdDatetime',
        title: '일자',
        width: '15',
    },
    {
        code: 'user.userFullName',
        title: '사용자명',
        width: '10',
    },
    {
        code: 'place.name.dong',
        title: '동명',
        width: '6',
    },
    {
        code: 'place.name.ho',
        title: '호명',
        width: '6',
    },
    {
        code: 'site.name',
        title: '현장명',
        width: '10',
    },
    {
        code: 'customer.name',
        title: '고객사',
        width: '10',
    },
    {
        code: 'reportedDeleteYn',
        title: '차단여부',
        width: '7',
    },
];

export const FeedList = (props) => {
    // useHistory 선언
    const history = useHistory();
    const user = props.userInfo;
    const [contentData, setContentData] = useState([]);
    const [checkedList, setCheckedList] = useState([]);
    const [modalState, setModalState] = useState({
        open: false,
        data: [],
        clickedBtnName: '',
    });
    const [searchCategory, setSearchCategory] = useState([
        [
            [
                {code: '', name: '고객사 선택'}
            ]
        ],
        [
            [
                {code: '', name: '현장명 선택'}
            ]
        ],
    ]);

    const [feedDetail, setFeedDetail] = useState([]);
    const [commentDetail, setCommentDetail] = useState([]);
    const [commentData, setCommentData] = useState([]);
    const [programAuth, setProgramAuth] = useState({});
    const [clickedRowIdx, setClickedRowIdx] = useState(null);

    const [pageState, setPageState] = useState({
        currentPage: 0,
        rowPerPage: 13,
        totalElementsCnt: 0,
        drawElementsCnt: 0,
        searchText: '',
        searchCategory1: user.menuGroup === 'ADMIN' ? '' : user.customer && user.customer.code,
        searchCategory2: user.menuGroup === 'OPERATOR' ? user.site && user.site.code: '',
        searchStartDate: '',
        searchEndDate: '',
    });

    const [commentPageState, setCommentPageState] = useState({
        currentPage: 0,
        rowPerPage: 8,
        totalElementsCnt: 0,
        drawElementsCnt: 0,
    });

    const [loading, setLoading] = useState(false);

    useEffect(() => {
        store.openedPage = 'Feed 목록';
        getCategoryList('');
        pageAuthInfo();

    }, [])

    useEffect(() => {
        setContentData([]);
        setTimeout(() => {
            loadData();
        }, 10)
    }, [pageState.currentPage,
        pageState.searchCategory1,
        pageState.searchCategory2,
        pageState.searchStartDate,
        pageState.searchEndDate,
    ]);

    useEffect(() => {
        goSearchComment(null);
    }, [commentPageState.currentPage]);

    const pageAuthInfo = () => {
        const authGroupCode = props.userInfo.authList[0];
        const menuId = sessionStorage.getItem('menuId');

        Net.getAuthGroupMenuList(menuId, (response) => {
            if(response.status === 200) {
                response.data.forEach(item => {
                    if(item.authGroup === authGroupCode && item.authGroupMenu) {
                        setProgramAuth({
                            searchAuth: Boolean(item.authGroupMenu.searchAuth),
                            saveAuth: Boolean(item.authGroupMenu.saveAuth),
                            excelAuth: Boolean(item.authGroupMenu.excelAuth),
                            deleteAuth: Boolean(item.authGroupMenu.deleteAuth),
                        });
                    }
                });
            }
        });
    }

    const loadData = () => {
        setLoading(true);
        Net.getFeedList(
            {
                currentPage: pageState.currentPage,
                rowPerPage: pageState.rowPerPage,
                searchText: pageState.searchText,
                searchCategory1: pageState.searchCategory1,
                searchCategory2: pageState.searchCategory2,
                searchStartDate: pageState.searchStartDate,
                searchEndDate: pageState.searchEndDate
            }, (response) => {
                setLoading(false);
                if (response.status === 200) {
                    response.data.content.forEach((item) => {
                        item.place.name = (item.place.name.split('-'));
                        item.place.name.forEach((item2, index) => {
                            if (index === 0)
                                item.place.name.dong = item2.replace(/(^0+)/, "");
                            else if (index === 1)
                                item.place.name.ho = item2.replace(/(^0+)/, "");
                        })
                    })
                    setContentData(response.data.content);
                    setPageState({
                        ...pageState,
                        totalElementsCnt: response.data.totalElements,
                        drawElementsCnt: response.data.numberOfElements,
                    });
                } else {
                    setContentData([]);
                    setPageState({
                        ...pageState,
                        totalElementsCnt: 0,
                        drawElementsCnt: 0,
                    });

                    setFeedDetail([]);
                    setCommentData([]);
                    setCommentPageState({
                        ...commentPageState,
                        totalElementsCnt: 0,
                        drawElementsCnt: 0,
                    });
                }
            });
    }

    const getCategoryList = () => {
        if (user.menuGroup === 'ADMIN') {
            getCustomerList((response1) => {
                getSitesList(null, (response2) => {
                    setSearchCategory([response1, response2]);
                });
            });
        } else if (user.menuGroup === 'MANAGER') {
            getSitesList(pageState.searchCategory1, (response2) => {
                setSearchCategory([response2]);
            });
        }
    }

    const getRelateCategory = (selectedCategory) => {
        if (selectedCategory === "reset") selectedCategory = "";
        getSitesList(selectedCategory, (response2) => {
            setSearchCategory([
                searchCategory[0],
                response2
            ]);
        });
    }

    const getCustomerList = (callback) => {
        Net.getCustomerList(
            {currentPage: '', rowPerPage: '', searchText: ''}, (response) => {
                if (response.status === 200) {
                    let newArray = searchCategory[0][0];
                    response.data.content.forEach(items => {
                        newArray = [
                            ...newArray,
                            {
                                code: items.code,
                                name: items.name
                            }
                        ]
                    });
                    callback(newArray);
                }
            });
    }

    const getSitesList = (selectedCategory, callback) => {
        if (selectedCategory) {
            Net.getSitesList(
                {currentPage: '', rowPerPage: '', searchText: '', customerCode: selectedCategory ? selectedCategory : false}, (response) => {
                    if (response.status === 200) {
                        let newArray = [{code: '', name: "현장명 선택"}];
                        if (response.data.content.length > 0) {
                            response.data.content.forEach(items => {
                                newArray = [
                                    ...newArray,
                                    {
                                        code: items.code,
                                        name: items.name
                                    }
                                ]

                            })
                        }
                        callback(newArray);
                    } else {
                        let newArray = [{code: '', name: "현장명 선택"}];
                        callback(newArray);
                    }
                });
        } else {
            let newArray = [{code: '', name: "현장명 선택"}];
            callback(newArray);
        }
    }

    const onClickRow = (e) => {
        Net.getFeedOne(e.feedId, (response) => {
            if (response.status === 200) {
                setFeedDetail(response.data);
            } else {
                setFeedDetail([]);
            }
        });
        setClickedRowIdx(null);
        goSearchComment(e.feedId);
    }
    const goSearchComment = (feedId) => {
        if (!feedId) {
            feedId = feedDetail.feedId;
        }
        Net.getFeedCommentList({currentPage: commentPageState.currentPage, rowPerPage: commentPageState.rowPerPage, feedId: feedId}, (response) => {
            if (response.status === 200) {
                console.log(response.data.content)
                setCommentData(response.data.content);
                setCommentPageState({
                    ...commentPageState,
                    totalElementsCnt: response.data.totalElements,
                    drawElementsCnt: response.data.numberOfElements,
                });
            } else {
                setCommentData([]);
                setCommentPageState({
                    currentPage: 0,
                    rowPerPage: 8,
                    totalElementsCnt: 0,
                    drawElementsCnt: 0,
                });
            }
        });
    }

    const onMovePage = (e) => {
        setPageState({
            ...pageState,
            currentPage: e - 1
        });
    }

    const onMoveCommentPage = (e) => {
        setCommentPageState({
            ...commentPageState,
            currentPage: e - 1
        });
    }

    const onSubmitHandler = (e) => {
        if (user.menuGroup === 'ADMIN') {
            setPageState({
                ...pageState,
                currentPage: 0,
                searchCategory1: e.selectedCategory[0],
                searchCategory2: e.selectedCategory[1],
            });

        } else if (user.menuGroup === 'MANAGER') {
            setPageState({
                ...pageState,
                currentPage: 0,
                searchCategory1: pageState.searchCategory1,
                searchCategory2: e.selectedCategory[0],
            });
        } else{
            setPageState({
                ...pageState,
                currentPage: 0,
            });
        }
    }

    const onRemoveHandler = (id) => {
        const result = window.confirm('삭제하시겠습니까?');
        if (result) {
            Net.deleteFeedComment([feedDetail.feedId, id], (response) => {
                if (response) {
                    toast('삭제 되었습니다.',{
                        autoClose : 1500,
                        pauseOnHover: false,
                        draggable : false,
                        position : "top-center"
                    });
                    setClickedRowIdx(null);
                    goSearchComment(feedDetail.feedId);
                }
            });
        }
    }

    const removeList = () => {
        const result = window.confirm('삭제하시겠습니까?');
        const removeList = [];
        checkedList.forEach((id) => {
            const node = document.querySelector(`#${id}`);
            const nodeTr = node.closest('tr');
            const itemId = nodeTr.querySelector('td[name=feedId]').textContent;
            removeList.push({itemId: itemId});
        });
        if(result) {
            toast('삭제 되었습니다.',{
                autoClose : 1500,
                pauseOnHover: false,
                draggable : false,
                position : "top-center"
            });
            //피드 삭제
            Net.removeFeed(removeList, (response) => {
                if(response) {
                    loadData();
                    setFeedDetail([]);
                    setCommentData([]);
                    setClickedRowIdx(null);
                }
            });
        }
    }

    const addComment = () => {
        /*
        *  1. Commenter, ParentId
        *
        * */
        setModalState({
            ...modalState,
            open: !modalState.open,
            propsData: {
                user: user,
                parentId: feedDetail.feedId,
            },
            clickedBtnName: '댓글 등록',
        });
    }

    const updateComment = () => {
        setModalState({
            ...modalState,
            open: !modalState.open,
            propsData: {
                user : user,
                parentId : feedDetail.feedId,
                commentsId : commentDetail.commentsId,
                contents : commentDetail.contents
            },
            clickedBtnName: '댓글 수정',
        });
    }

    const searchDateHandler = (startDate, endDate) => {
        startDate = startDate.getFullYear() + '-' + ('0' + (Number(startDate.getMonth()) + 1)).slice(-2) + '-' + ('0' + startDate.getDate()).slice(-2) + ' 00:00';
        endDate = endDate.getFullYear() + '-' + ('0' + (Number(endDate.getMonth()) + 1)).slice(-2) + '-' + ('0' + endDate.getDate()).slice(-2)+ ' 23:59';

        if (!pageState.searchStartDate && !pageState.searchEndDate) {
            pageState.searchStartDate = startDate;
            pageState.searchEndDate = endDate;
        } else {
            setPageState({
                ...pageState,
                searchStartDate: startDate,
                searchEndDate: endDate,
            });
        }
    }

    const addList = () => {
        history.push('/community/feed/regist');
    }

    const goModifypage = () => {
        history.push(`/community/feed/regist/${feedDetail.feedId}`);
    }


    const reload = async (e) => {
        if(e === 'reload'){
            // 다시 상세 조회하기
            goSearchComment(feedDetail.feedId)
        }
    }

    const sendData = (e) => {
        setModalState({
            ...modalState,
            data: e,
        })
    }
    console.log(feedDetail)

    return (
        <div className="w-100 h-100">
            <BasicModal
                className="w-80 h-88"
                title={`${modalState.clickedBtnName} 목록`}
                content={
                    <ModalContent
                        sendData={sendData}
                        modalType={modalState.clickedBtnName}
                        propsData={modalState.propsData}
                        modalClose={(e) => setModalState({
                            ...modalState,
                            open: e
                        })}
                        reload={reload}
                        deleteAuth = {programAuth.deleteAuth}
                    />
                }
                modalOpen={modalState.open}
                modalClose={() => setModalState({
                    ...modalState,
                    open: false
                })}
                sendData={modalState.data}
            />
            <div className="row col-12 h-7 ">
                <div className="col-9 mt-2">
                    <SearchInputBar
                        className="w-100 searchInput-wrapper-nodp "
                        searchCategory={user.menuGroup === 'ADMIN' || user.menuGroup === 'MANAGER'}
                        searchCategoryGroup={searchCategory}
                        searchBtn={true}
                        searchBtnTitle="검색"
                        dynamicSearch={false}
                        relateCategoryHandler={user.menuGroup === 'ADMIN' && getRelateCategory}
                        searchDate={searchDateHandler}
                        onSubmit={onSubmitHandler}
                        refreshBtn={true}
                        refreshHandler={loadData}
                    />
                </div>
                <div className="flex col-3 mt-2 justify-content-end">
                    <button
                        className="addBtn w-90px h-30px mr-3"
                        onClick={addList}
                    >등록
                    </button>
                </div>
            </div>
            <div className="w-100 h-1px bgC-efefef"/>
            <div className="flex">
                <div className="w-40 h-93 overflowYscrollView">
                    <p className="totalCnt">Total {pageState.totalElementsCnt}</p>
                    {

                        user.menuGroup === 'ADMIN' ?
                            feedDetail.feedId ?
                                <>
                                    <button
                                        className="downloadBtn w-90px h-30px floatR mt-20 mr-15"
                                        onClick={goModifypage}
                                    >수정
                                    </button>
                                </>
                                : <></>
                            : feedDetail.feedId && feedDetail.place.name === '관리사무소' ?
                                <>
                                    <button
                                        className="downloadBtn w-90px h-30px floatR mt-20 mr-15"
                                        onClick={goModifypage}
                                    >수정
                                    </button>
                                </> :
                                <></>
                    }

                    {
                        programAuth.deleteAuth && checkedList.length > 0 ? <>
                            <button
                                className="cancelBtn w-90px h-30px floatR mt-20 mr-10"
                                onClick={removeList}
                            >삭제
                            </button>
                        </> :  <button
                            className="cancelBtn w-90px h-30px floatR mt-20 mr-10"
                            disabled={true}
                        >삭제
                        </button>
                    }

                    <CustomTable
                        columnList={columnList}
                        contentData={contentData}
                        paging={true}
                        checkbox={programAuth.deleteAuth}
                        checkedList={(checkedList) => {
                            setCheckedList(checkedList)
                        }}
                        totalElementsCnt={pageState.totalElementsCnt}
                        rowPerPage={pageState.rowPerPage}
                        currentPage={pageState.currentPage}
                        drawElementsCnt={pageState.drawElementsCnt}
                        pagination={onMovePage}
                        rowClickEvt={true}
                        onClickRow={onClickRow}
                        loading={loading}
                        // sort = {true}
                    />
                </div>
                <div className="w-35 h-93 " style={{display: "block"}}>
                    <div className="row">
                        <div className="col-6"></div>
                        <div className="col-3">
                            {
                                clickedRowIdx !== null ?
                                    <button
                                        className="downloadBtn h-30px mt-20 mr-10 col-12"
                                        onClick={updateComment}
                                    >댓글 수정
                                    </button>
                                    : <></>
                            }
                        </div>
                        <div className="col-3">
                            {
                                feedDetail.feedId ?
                                    <button
                                        className="addBtn h-30px  mt-20 mr-10 col-12 floatR"
                                        onClick={addComment}
                                    >댓글 등록
                                    </button> : <>
                                        <button
                                            className="removeBtn h-30px  mt-20 mr-10 col-12 floatR"
                                        >댓글 등록
                                        </button>
                                    </>
                            }

                        </div>
                    </div>

                    <div className="w-100 h-60vh">
                        {/* <div className="w-100 h-40 mt-12 overFlowYscrollView"> */}
                        <div className="w-100 h-60vh mt-12 overFlowYscrollView">
                            <table className="mainTable ">
                                <colgroup>
                                    <col width="20%"/>
                                    <col width="35%"/>
                                    <col width="20%"/>
                                    <col width="15%"/>
                                    <col width="10%"/>
                                </colgroup>
                                <thead>
                                <tr>
                                    <th className="tableHead fixedHeader t-0px" colSpan="5">댓글</th>
                                </tr>
                                <tr>
                                    <th className="v-middle tableHead fixedHeader t-36px"><span className="w-24px">사용자명</span></th>
                                    <th className="v-middle tableHead fixedHeader t-36px"><span className="w-48px">내용</span></th>
                                    <th className="v-middle tableHead fixedHeader t-36px"><span className="w-24px">생성일자</span></th>
                                    <th className="v-middle tableHead fixedHeader t-36px"><span className="w-12px">신고차단</span></th>
                                    <th className="v-middle tableHead fixedHeader t-36px"><span className="w-12px">삭제</span></th>

                                </tr>
                                </thead>
                                <tbody className="tableBody">
                                {
                                    commentData.length > 0 ?
                                        (
                                            <Fragment>
                                                {
                                                    commentData.map((list,index) => {
                                                        return (
                                                            <tr key={list.commentsId}
                                                                onClick={()=>{
                                                                    setClickedRowIdx(index);
                                                                    setCommentDetail(list);
                                                                }}
                                                                className={classNames({'clicked': index===clickedRowIdx})}
                                                            >
                                                                <td style={{whiteSpace: "initial", borderBottom: "none"}}>{list.user.userFullName}</td>
                                                                <td style={{whiteSpace: "initial", borderBottom: "none"}}>{list.contents}</td>
                                                                <td style={{whiteSpace: "initial", borderBottom: "none"}}>{list.createdDatetime}</td>
                                                                <td style={{whiteSpace: "initial", borderBottom: "none"}}>{list.reportedDeleteYn ? "O" : "X"}</td>
                                                                <td style={{whiteSpace: "initial", borderBottom: "none"}}>
                                                                    <button className="removeBtn smallfont" onClick={() => onRemoveHandler(list.commentsId)}> 삭제</button>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })
                                                }

                                            </Fragment>
                                        ) : (
                                            <tr>
                                                <td colSpan="5">조회 된 데이터가 없습니다.</td>
                                            </tr>
                                        )
                                }
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="pagination-wrappper">
                        {commentData.length > 0 &&
                        <CustomPagination
                            activePage={commentPageState.currentPage}
                            itemsCountPerPage={commentPageState.rowPerPage}
                            totalItemsCount={commentPageState.totalElementsCnt}
                            pageRange={5}
                            onChange={onMoveCommentPage}
                        />
                        }
                    </div>
                </div>
                <div className="w-25 h-93" style={{display: "block"}}>
                    <div className="w-100 h-60vh mt-60">
                        {/* <div className="w-100 h-45" style={{display: "table"}}> */}
                        <div className="w-100 h-100" style={{display: "table"}}>
                            <table className="mainTable">
                                <thead className="tableHead">
                                <tr>
                                    <th>Feed 상세 내용</th>
                                </tr>
                                </thead>
                                <tbody className="tableBody">
                                {
                                    feedDetail.contents ?
                                        (
                                            <Fragment>
                                                <tr>
                                                    <td style={{padding: "10px", overflow: "unset", whiteSpace: "initial"}}>
                                                        <div className="h-57vh overFlowYscrollView">
                                                            <div dangerouslySetInnerHTML={{__html: feedDetail.contents}}/>
                                                            {
                                                                feedDetail.imageDataUrl &&
                                                                <div className="mt-10"><img src={feedDetail.imageDataUrl} alt="complex=Img" width="300px"/></div>
                                                            }
                                                        </div>

                                                    </td>
                                                </tr>

                                            </Fragment>


                                        ) : (
                                            <tr>
                                                <td>
                                                    <div className="h-7vh">
                                                        조회 된 데이터가 없습니다.
                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                }

                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export const ModalContent = (props) => {
    switch (props.modalType) {
        case "댓글 등록":
            return <FeedCommentModal data={props.propsData}  modalClose={props.modalClose} reload={props.reload}/>
            break;
        case "댓글 수정":
            return <FeedCommentModal data={props.propsData} modalClose={props.modalClose} reload={props.reload}/>
            break;
        default:
            break;
    }
}

