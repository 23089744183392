import React, {useEffect, useState} from 'react';
import {CustomTextField, CustomRadioButton, CustomSelectBox, ImgUpload} from 'components/molecules';
import {useHistory, withRouter} from 'react-router-dom';
import store from 'scripts/store';
import Net from 'scripts/net';
import {useStateWithCallbackLazy} from "use-state-with-callback";
import {toJS} from "mobx";

const FloorPlanRegist = ({match}) => {

    const history = useHistory();

    const cloneUser = toJS(store.user)

    const [form, setForm] = useStateWithCallbackLazy({
        customerCode: cloneUser.menuGroup === 'ADMIN' ? '' : cloneUser.customer.code,
        site: cloneUser.menuGroup === 'OPERATOR' ? cloneUser.site : {code: '', name: '',},
        floorType: '',
        supplyArea: '',
        netArea: '',
        basicImageId: '',
        basicImageDataUrl: '',
        expandedImageId: '',
        expandedImageDataUrl: '',
        usable: true,
    });

    const [registPage, setRegistPage] = useState(true);
    const [customerList, setCustomerList] = useState([{
        code: '',
        name: '고객사명 선택'
    }]);
    const [siteList, setSiteList] = useState([{
        code: '',
        name: '현장명 선택'
    }]);

    useEffect(() => {
        store.openedPage = '평면도 장소 등록/수정';

        if (match.params.code !== undefined) {
            Net.getFloorPlanOne(match.params.code, (response) => {
                console.log("getFloorPlanOne", response);
                if (response.status === 200) {
                    setTimeout(()=>{
                        setForm({
                            ...form,
                            ...response.data,
                        });
                    },550)
                    // 등록페이지 여부 확인
                    setRegistPage(false);
                }
            });
        }

        // 고객사 전체 목록
        getCustomerList((response) => {
            setCustomerList(response);
        });

    }, [])

    useEffect(() => {
        if (form.site.code !== '') {
            getCustomerBySite(form.site.code, (response) => {
                setForm({
                    ...form,
                    customerCode: response.customer.code
                })
            });
        }

    }, [form.site.code]);

    useEffect(() => {
        if (form.customerCode !== '') {
            getSiteList(form.customerCode, (response) => {
                setSiteList(response);
            })
        } else {
            // 빈값 선택시 장비모델명 selectbox 초기화
            setSiteList([
                {
                    code: '',
                    name: '현장명 선택'
                }
            ])
        }
    }, [form.customerCode]);

    const getCustomerList = (callback) => {
        Net.getCustomerList({currentPage: '', rowPerPage: '', searchText: ''}, (response) => {
            if (response.status === 200) {
                let newArray = customerList;
                response.data.content.map(items => {
                    newArray = [
                        ...newArray,
                        {
                            code: items.code,
                            name: items.name
                        }
                    ]
                });
                callback(newArray);
            }
        });
    }

    const getSiteList = (code, callback) => {
        Net.getSitesList(
            {
                currentPage: '',
                rowPerPage: '',
                searchText: '',
                customerCode: code,
            }, (response) => {
                if (response.status === 200) {
                    let newArray = [
                        {
                            code: '',
                            name: '현장명 선택'
                        }
                    ];
                    response.data.content.forEach(items => {
                        newArray = [
                            ...newArray,
                            {
                                code: items.code,
                                name: items.name
                            }
                        ]
                    });
                    callback(newArray);
                } else if (response.status === 204) {
                    let newArray = [
                        {
                            code: '',
                            name: '현장명 선택'
                        }
                    ];
                    callback(newArray);
                }
            });
    }

    const getCustomerBySite = (siteCode, callback) => {
        Net.getSiteOne(siteCode, (response) => {
            callback(response.data);
        })
    }


    const textFieldHandler = (name, value) => {
        const nextForm = {
            ...form,
            [name]: value
        }
        setForm(nextForm);
    }

    const radioBtnHandler = (name, value) => {
        const nextForm = {
            ...form,
            [name]: value === 'true' ? true : false,
        }
        setForm(nextForm);
    }

    const selectBoxHandler = (name, value) => {
            switch (name) {
                case 'customerCode':
                    setForm({
                        ...form,
                        customerCode: value.code,
                        customerName: value.name,
                        site: {
                            code: "",
                            name: ""
                        }
                    });
                    break;
                case 'site.code':
                    setForm({
                        ...form,
                        site: {
                            code: value.code,
                            name: value.name
                        }
                    });
                    break;
            }
    }

    const getImgFile = (name, dataUrl) => {

        setForm({
            ...form,
            [name]: dataUrl,
        });
    }

    const onSubmitHandler = () => {
        if (form.floorType === '') {
            alert('타입은 필수값입니다.');
            document.getElementsByName('floorType')[0].focus();
            return false;
        }

        if (cloneUser.menuGroup === 'OPERATOR') {
            setForm({
                ...form,
                customerCode: cloneUser.customer.code,
                site: cloneUser.site,
            }, (current) => {
                saveLogic(current)
                return false;
            })
        } else if (cloneUser.menuGroup === 'MANAGER') {
            if (!form.site.code) {
                alert('현장명을 선택해 주세요.');
                document.getElementsByName('site.code')[0].focus();
                return false;
            }
            setTimeout(() => {
                setForm({
                    ...form,
                    customerCode: cloneUser.customer.code,
                }, (current) => {
                    saveLogic(current)
                    return false;
                })
            }, 500)
        } else {
            if (!form.customerCode) {
                alert('고객사를 선택해 주세요.');
                document.getElementsByName('customerCode')[0].focus();
                return false;
            }
            if (!form.site.code) {
                alert('현장명을 선택해 주세요.');
                document.getElementsByName('site.code')[0].focus();
                return false;
            }
            saveLogic(form);
        }
    }

    const saveLogic = (saveform) => {
        var result = window.confirm('저장하시겠습니까?');

        if (result) {
            if (registPage) {
                Net.addFloorPlan(saveform, (response) => {
                    console.log(response.status)
                    if (response.status === 200 || response.status === 201) {
                        history.push('/location/floorPlan/list');

                    }
                });
            } else {
                Net.updateFloorPlan(saveform, (response) => {
                    if (response.status === 200) {
                        history.push('/location/floorPlan/list');
                    }
                });
            }
        }
    }
    const onCancelHandler = () => {
        history.push('/location/floorPlan/list');
    }

    const onRemoveHandler = () => {
        const result = window.confirm('삭제하시겠습니까?');
        if (result) {
            Net.removeFloorPlanList([form.code], (response) => {
                if (response) {
                    history.push('/location/floorPlan/list');
                }
            });
        }
    }


    return (
        <div className="w-100 h-100">
            <div className="w-100 h-7 display_table"></div>
            <div className="w-100 h-1px bgC-efefef"/>
            <div className="h-93">
                <div className="flex h-90">
                    <div className="w-60 h-100">
                        <div className="ml-55 mt-12">
                            {
                                cloneUser.menuGroup === 'ADMIN' ? (
                                    <div className="flex mb-12 w-90">
                                        <label className="label_title">고객사명 *</label>
                                        <CustomSelectBox
                                            name="customerCode"
                                            categoryList={customerList}
                                            value={form.customerCode}
                                            onChangeHandler={(value, name) => selectBoxHandler('customerCode', {code: value, name: name})}
                                            callbackCodeName="Y"
                                            disabled={!registPage}
                                        />
                                        {
                                            registPage ? <div></div> :
                                                <div className="label_summary ml-10">※ 수정시 고객사 및 현장은 변경 불가능 합니다. </div>
                                        }
                                    </div>
                                ) : <></>
                            }
                            {
                                cloneUser.menuGroup === 'OPERATOR' ? <></> : (
                                    <div className="flex mb-12 w-90">
                                        <label className="label_title">현장 *</label>
                                        <CustomSelectBox
                                            name="site.code"
                                            categoryList={siteList}
                                            value={form.site.code}
                                            onChangeHandler={(value, name) => selectBoxHandler('site.code', {
                                                code: value,
                                                name: name
                                            })}
                                            callbackCodeName="Y"
                                            disabled={!registPage}
                                        />
                                    </div>
                                )
                            }
                            <div className="flex mb-12 w-90">
                                <CustomTextField
                                    className="w-70 h-26px"
                                    title="타입"
                                    name="floorType"
                                    required={true}
                                    disabled={false}
                                    customOnChange={textFieldHandler}
                                    value={form}
                                />
                            </div>
                            <div className="flex mb-12 w-90">
                                <CustomTextField
                                    className="w-70 h-26px"
                                    title="공급면적"
                                    name="supplyArea"
                                    disabled={false}
                                    customOnChange={textFieldHandler}
                                    value={form}
                                />
                            </div>
                            <div className="flex mb-12 w-90">
                                <CustomTextField
                                    className="w-70 h-26px"
                                    title="전용면적"
                                    name="netArea"
                                    disabled={false}
                                    customOnChange={textFieldHandler}
                                    value={form}
                                />
                            </div>
                            <div className="flex w-90">
                                <label className="label_title">사용유무</label>
                                <CustomRadioButton
                                    className="mr-60"
                                    title="사용"
                                    id="radioBtn1"
                                    name="usable"
                                    value={true}
                                    checked={form.usable}
                                    customOnChange={radioBtnHandler}
                                />
                                <CustomRadioButton
                                    title="사용안함"
                                    id="radioBtn2"
                                    name="usable"
                                    value={false}
                                    checked={!form.usable}
                                    customOnChange={radioBtnHandler}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="w-40 h-100">
                        <div className="w-100 h-50 mt-12">
                            <div className="floatC mr-55">
                                <ImgUpload
                                    title={"기본형"}
                                    imgUpload={(e) => getImgFile('basicImageDataUrl', e)}
                                    imgFile={form.basicImageDataUrl}
                                    inputName={"basicFloorPlane"}
                                />
                            </div>
                        </div>
                        <div className="w-100 h-50 mt-12">
                            <div className="floatC mr-55">
                                <ImgUpload
                                    title={"확장형"}
                                    imgUpload={(e) => getImgFile('expandedImageDataUrl', e)}
                                    imgFile={form.expandedImageDataUrl}
                                    inputName={"expandedFloorPlane"}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer_btn h-10">
                    <button className="w-160px h-30px addBtn mr-10" onClick={onSubmitHandler}>저장</button>
                    {registPage ? "" :
                        <button className="w-160px h-30px removeBtn mr-10" onClick={onRemoveHandler}>삭제</button>}
                    <button className="w-160px h-30px cancelBtn" onClick={onCancelHandler}>취소</button>
                </div>
            </div>
        </div>
    )
}

export default withRouter(FloorPlanRegist);
