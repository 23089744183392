/* eslint-disable no-lone-blocks */
import React, {useEffect, useState} from 'react';
import Net from 'scripts/net';
import Common from 'scripts/common';
import { CustomTextField, CustomSelectBox, QuillEditor,ImgUpload, FileUpload  } from 'components/molecules';
import {EditorState} from "draft-js";

export const BoardRegistModal = (props) => {
    const [boolCategory, setBoolCategory] = useState(false);
    const [form, setForm] = useState({
        boardId: '',
        category: '',
        title: '',
        content: '',
        customer : props.data.user.menuGroup === 'ADMIN' ? {code: '',name: '',} : props.data.user.customer,
        site: props.data.user.menuGroup === 'OPERATOR' ? props.data.user.site : { code: '', name: '',},
        user: props.data.user,
        attribute1: '',
        attribute: '',
        image: '',
        imageDataUrl: '',
        file: '',
        filename: '',
        fileDataUrl: '',
    });
    const [addProperty1Name, setAddProperty1Name] = useState('');
    const [addProperty2Name, setAddProperty2Name] = useState('');

    const [customerList, setCustomerList] = useState([{
        code: '',
        name: '고객사명 선택'
    }]);
    const [siteList, setSiteList] = useState([{
        code: '',
        name: '현장명 선택'
    }]);
    const [classificationList, setClassificationList] = useState([
        [
            {code: '', name: '구분 선택'}
        ]
    ]);
    const [editorState, setEditorState] = useState(EditorState.createEmpty());

    useEffect(()=>{
        console.log(form);
    },[form]);

    useEffect(() => {
        if(props.data.category){
            setForm({
                ...form,
                user: {
                    username: props.data.user.username,
                    userFullName: props.data.user.nickname,
                    name: props.data.user.name,
                    email: props.data.user.email,
                },
                category: props.data.category,
                parentId: props.data.parentId,
            })
            setBoolCategory(true);
        }else {
            setForm({
                ...form,
                user: {
                    username: props.data.user.username,
                    userFullName: props.data.user.nickname,
                    name: props.data.user.name,
                    email: props.data.user.email,
                },
                parentId: props.data.parentId,
            })
        }


        getCustomerList((response) => {
            setCustomerList(response);
        });
        getClassificationList();

    },[]);

    useEffect(() => {
        if(form.customer.code !== '') {
            getSiteList(form.customer.code, (response) => {
                setSiteList(response);
            })
        } else {
            // 빈값 선택시 장비모델명 selectbox 초기화
            setSiteList([
                {
                    code: '',
                    name: '현장명 선택'
                }
            ])
        }
    }, [form.customer]);

    useEffect(()=>{
        Net.getBoardMasterOne(form.category, response => {
           if(response.status === 200 ){
               if(response.data.addProperty1){
                   setAddProperty1Name(response.data.addProperty1);
               }
               if(response.data.addProperty2){
                   setAddProperty2Name(response.data.addProperty2);
               }
           } else {
               alert('데이터 오류, 관리자에게 문의해주세요.')
           }
        });
    },[form.category])


    const getCustomerList = (callback) => {
        Common.getCustomerList((response) => {
            callback(response);
        })
    }

    const getSiteList = (code, callback) => {
        Common.getSitesList(code, response => {
            callback(response);
        })
    }

    const getClassificationList = () => {
        Net.getBoardMasterList({
                currentPage : 0,
                rowPerPage : 999
            }, (response) => {
                if(response.status === 200) {
                    let newArray = classificationList[0];
                    response.data.content.forEach(items => {
                        newArray = [
                            ...newArray,
                            {
                                code: items.code,
                                name: items.name
                            }
                        ]
                    })
                    setClassificationList(newArray);
                }
            }
        )
    }

    const textFieldHandler = (name, value) => {
        const nextForm = {
            ...form,
            [name] : value
        }
        setForm(nextForm);
    }

    const selectBoxHandler = (name, value) => {
        switch (name) {
            case 'customer.code':
                setForm({
                    ...form,
                    customer: {
                        code: value.code,
                        name: value.name
                    },
                    site: {
                        code: "",
                        name: ""
                    }
                });
                break;
            case 'site.code':
                setForm({
                    ...form,
                    site: {
                        code: value.code,
                        name: value.name
                    }
                });
                break;
            case 'category':
                setForm({
                    ...form,
                    category: value
                });
                break;
            default:
                break;
        }
    }

    const onSubmitHandler = async () => {
        let result = window.confirm('게시글을 등록 하시겠습니까?');
        if(result){
            if( !form.category ) {
                alert('구분을 선택해 주세요.');
                document.getElementsByName('category')[0].focus();
                return false;
            }
            if( !form.title ) {
                alert('제목을 입력해 주세요.');
                document.getElementsByName('title')[0].focus();
                return false;
            }
            if( !form.content ) {
                alert('내용을 입력해 주세요.');
                return false;
            }
            const res = await Net.addBoardAsync(form);
            if (res.status === 200 || res.status === 201) {
                props.modalClose(false);
                props.reload('reload');
            }
        }
    }


    const getFileUrl = (dataUrl, filename) => {
        setForm({
            ...form,
            fileDataUrl: dataUrl,
            filename : filename
        });
    }

    const getImgFile = (dataUrl) => {
        setForm({
            ...form,
            imageDataUrl: dataUrl,
        });
    }

    const liftValue = (value) => {
        setForm({
            ...form,
            content: value,
        });
    }

    const onCancelHandler = () => {
        props.modalClose(false);
    }

    return (
        <div className="w-100 h-100">
            <div className="w-100 h-3"/>
            <div className="h-80 flex">
                <div className="w-60 h-100 overFlowYscrollView">
                        <div className="ms-5 mt-3">
                            {
                                boolCategory ? (
                                    <></>
                                ) : (
                                    <div className="flex mb-12 w-90">
                                        <label className="label_title">구분</label>
                                        <CustomSelectBox
                                            name="classification"
                                            categoryList={classificationList}
                                            value={form.category}
                                            onChangeHandler={(value) => selectBoxHandler('category', value)}
                                            callbackCodeName="N"
                                        />
                                    </div>
                                )
                            }

                            {
                                props.data.user.menuGroup === 'ADMIN' ? (
                                        <div className="flex mb-12 w-90">
                                            <>
                                                <label className="label_title">고객사</label></>
                                            <CustomSelectBox
                                                name="customer.code"
                                                className="d-block"
                                                categoryList={customerList}
                                                value={form.customer.code}
                                                onChangeHandler={(value, name) => selectBoxHandler('customer.code', {
                                                    code: value,
                                                    name: name
                                                })}
                                                callbackCodeName="Y"
                                            />
                                    </div>
                                ) : <></>
                            }
                            {
                                props.data.user.menuGroup === 'OPERATOR' ? <></> : (
                                    <div className="flex mb-3 mt-2 w-90">
                                        <label className="label_title">현장</label>
                                        {
                                            <CustomSelectBox
                                                name="site.code"
                                                categoryList={siteList}
                                                value={form.site.code}
                                                onChangeHandler={(value, name) => selectBoxHandler('site.code', {
                                                    code: value,
                                                    name: name
                                                })}
                                                callbackCodeName="Y"
                                            />
                                        }
                                    </div>
                                )
                            }
                            <div className="flex mb-3 w-90">
                                <CustomTextField
                                    className="w-100 h-26px"
                                    title="제목"
                                    name="title"
                                    required={true}
                                    disabled={false}
                                    placeholder=""
                                    customOnChange={textFieldHandler}
                                    value={form}
                                />
                            </div>
                            <div className="flex mb-3 w-90">
                                <label className="label_title">내용 *</label>
                                <div className="w-100">
                                    <QuillEditor
                                        onChange={liftValue}
                                        value={editorState}
                                        setEditorState={setEditorState}
                                    />
                                </div>
                            </div>

                            {
                                addProperty1Name !== '' ?
                                <div className="flex mb-3 w-90">
                                    <CustomTextField
                                        className="w-100 h-26px"
                                        title={addProperty1Name}
                                        name="attribute1"
                                        disabled={false}
                                        placeholder=""
                                        customOnChange={textFieldHandler}
                                        value={form}
                                    />
                                </div> : <></>
                            }
                            {
                                addProperty2Name !== '' ?
                                    <div className="flex mb-3 w-90">
                                        <CustomTextField
                                            className="w-100 h-26px"
                                            title={addProperty2Name}
                                            name="attribute2"
                                            disabled={false}
                                            placeholder=""
                                            customOnChange={textFieldHandler}
                                            value={form}
                                        />
                                    </div> : <></>
                            }

                        </div>
                </div>
                <div className="w-35 h-100">
                    <div className="flex w-100">
                        <div className="w-90 h-100 mt-3 m-3" style={{display: "block"}}>
                            <div className="">
                                <ImgUpload
                                    title={"사진"}
                                    imgUpload={getImgFile}
                                    imgSize={"200px"}
                                    imgFile={form.imageDataUrl}
                                />
                            </div>
                            <FileUpload
                                title={"파일"}
                                inputName={"file_board"}
                                fileUpload={getFileUrl}
                                fileUrl={form.fileDataUrl}
                            />
                        </div>

                        </div>
                </div>
            </div>
            <div className="footer_btn h-10 mt-2">
                <div className="">
                    <button className="w-160px h-30px addBtn mr-10" onClick={onSubmitHandler}>저장</button>
                    <button className="w-160px h-30px cancelBtn" onClick={onCancelHandler}>취소</button>
                </div>
            </div>
        </div>
)

}
