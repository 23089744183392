import React, {useEffect, useState} from 'react';
import Net from 'scripts/Service/Guide/net';
import {useIsImgLoaded } from 'scripts/hooks/useIsImgLoaded'
import {useDidMountEffect} from "scripts/hooks/useDidMountEffect";
import classNames from "classnames";
import './LazyImg.scss';

export const LazyImg = (props) => {
    const { src, alt, width = '100%', height = 'auto'} = props;

    const { elementRef, isLoaded } = useIsImgLoaded(true);


    const [imgSrc, setImgSrc] = useState('/image/Service/image/logoHanulchaeWhite@2x.png');
    const [imgLoaded, setImgLoaded] =useState(false);

    useEffect(()=>{
        if(isLoaded){
            loadImg().then();
        }
    },[isLoaded]);

    useDidMountEffect(()=>{
        setImgLoaded(true)
    },[imgSrc]);

    const loadImg = async () =>{
        if(src !== 'img'){
            const response = await Net.getBoardImg(src);
            setImgSrc(response);
        }
    }

    return (

        <div className={classNames({'fade-in-image': imgLoaded })}>
            { imgLoaded ? <>
                <img
                    id={"mainImg"}
                    style={{"width":"100%", "max-width" : "640px" }}
                    ref={elementRef}
                    alt={alt}
                    src={imgSrc}
                    style={{width, height}}
                />
            </> :  <><img
                id={"placeholderImg"}
                style={{"width":"100%", "max-width" : "640px" }}
                ref={elementRef}
                alt={alt}
                src={'/image/Service/image/logoHanulchaeWhite@2x.png'}
                style={{width, height}}
            /></>}

        </div>
    );
}
