import React from "react";

export const Copyright = () => {
    return (
        <div className="flex mr-20 mb-14" style={{float: 'right'}}>
            <p 
                className="fs-10" 
                style={{color: '#2a3344'}}
            >
                Copyrights 2020 KOLONBENIT. All right reserved
            </p>
            <img
                className="ml-7 w-101px h-10px"
                src="/icon/kolon_benit_logo.png"
                alt="kolon_benit_logo"
            />
        </div>
    )
};
