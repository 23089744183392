import React, {useLayoutEffect, useState} from 'react';
import {store} from 'scripts/store';
import {PageTemplate} from 'components/templates/PageTemplate/PageTemplate';
import {Footer, Header, Menu} from "components/organisms";
import {Redirect, Route, Switch, useHistory} from "react-router-dom";
import {DashBoardPage} from "pages/DashBoardPage/DashBoardPage";
import {MainDashBoardPage} from "pages/DashBoardPage/MainDashBoardPage";
import {MemberManagePage} from 'pages/MemberManagePage/MemberManagePage';
import {LocationPage} from 'pages/LocationPage/LocationPage';
import {ToolsPage} from 'pages/ToolsPage/ToolsPage';
import {SettingPage} from 'pages/SettingPage/SettingPage';
import {Community} from 'pages/Community/Community';
import {HistoryPage} from 'pages/HistoryPage/HistoryPage';
import {Bridge} from "pages/BridgePage/Bridge";
import {NotificationPage} from "pages/NotificationPage/NotificationPage";
import Net from 'scripts/net';
import {Monitoring} from "pages/MonitoringPage/Monitoring";
import {ThirdPartyManagePage} from "pages/ThirdPartyManagePage/ThirdPartyManagePage";
import {FreePassPage} from "pages/FreePass/FreePassPage";

const requestSearch = {currentPage: 0, rowPerPage: 0, searchText: ""};

export const HomePage = () => {
        const [open, setOpen] = React.useState(true);
        const [passOpen, setPassOpen] = useState(false);
        const history = useHistory();

        const leftMenuhandle = (e) => {
            setOpen(e);
        };

        let isAuthenticated = store.isAuthorized || sessionStorage.getItem('isAuthorized');
        const userInfo = JSON.parse(sessionStorage.getItem('user'));

        useLayoutEffect(() => {
            if (userInfo) {
                setPassOpen(userInfo.temporaryPassword)
            }
        }, [userInfo])
        useLayoutEffect(() => {
            if (userInfo) {
                if (userInfo.message && userInfo.message === "Unauthorized") {
                    isAuthenticated = false;
                    alert("비정상 접근으로 브라우저 종료 후 재 접속 부탁 드립니다.");
                    Net.logout();
                } else if (userInfo && !userInfo.hasOwnProperty("menuGroup")) {
                    alert("해당 계정은 IoK 관리자 홈페이지를 이용 하실 수 없습니다. 관리자 문의 부탁 드립니다.");
                    Net.logout();
                }
            }

            async function setUserMobx() {
                await store.setUser(userInfo)
            }

            if (isAuthenticated && userInfo) {
                setUserMobx().then(r => console.log("user saved"))
            }
            if (typeof window.REGION_CODE === "undefined" && isAuthenticated && userInfo) {
                let map = {};
                Net.getRegionList((response) => {
                    if(response.status === 200) {
                        response.data.contents.map(items => {
                            map[items.regionCode] = items.regionName;
                        });
                        window.REGION_CODE = map;
                    }
                });
            }
            init();
        }, [])

        const init = async () => {
            if (typeof window.CUSTOMER_LIST === "undefined" && isAuthenticated && userInfo) {
                await Net.getSitesListAsync(requestSearch)
                    .then(response => {
                        let map = {};
                        let siteMap = {};
                        response.data.content.map = async function () {
                            let i = this.length;
                            while (i--) {
                                map[this[i]["code"]] = this[i]["customer"]["name"];
                                siteMap[this[i]["code"]] = {
                                    name : this[i]["name"],
                                    household : this[i]["numberOfHouseHold"],
                                    builtDate : this[i]["builtDate"]
                                }
                            }
                            return map;
                        }.call(response.data.content);
                        window.CUSTOMER_LIST = map;
                        window.SITE_LIST = siteMap;
                    })
            }

            if (typeof window.COMMUNITY_CODE === "undefined" && isAuthenticated && userInfo) {
                const codeTypes = await Net.getCommunityCodeTypeListAsync({
                    ...requestSearch,
                    currentPage: "",
                    rowPerPage: ""
                })
                let map = {};
                await codeTypes.data.content.map(async item => {
                    map[item.code] = item.name;

                    let subMap = {};
                    const codes = await Net.getCommunityCodeAsync(item.code);
                    await codes.data.forEach(item => {
                        subMap[item.code] = item.name;
                    });

                    window[item.code] = subMap;
                    return map;
                })
                window.COMMUNITY_CODE = map;
            }


        }

        return (
            isAuthenticated ? (
                <PageTemplate
                    header={<Header userInfo={userInfo} open={passOpen}/>}
                    menu={<Menu onDrawer={leftMenuhandle} userInfo={userInfo}/>}
                    footer={<Footer/>}
                    notFound={false}
                    leftMenu={open}
                >
                    <Switch>
                        <Route path={"/"} exact children={<MainDashBoardPage userInfo={userInfo}/>}/>
                        <Route path={"/dashboard"} exact children={<DashBoardPage userInfo={userInfo}/>}/>
                        <Route path={"/member"} children={<MemberManagePage userInfo={userInfo}/>}/>
                        <Route path={"/location"} children={<LocationPage userInfo={userInfo}/>}/>
                        <Route path={"/tools"} children={<ToolsPage userInfo={userInfo}/>}/>
                        <Route path={"/setting"} children={<SettingPage userInfo={userInfo}/>}/>
                        <Route path={"/community"} children={<Community userInfo={userInfo}/>}/>
                        <Route path={"/history"} children={<HistoryPage userInfo={userInfo}/>}/>
                        <Route path={"/notification"} children={<NotificationPage userInfo={userInfo}/>}/>
                        <Route path={"/bridge"} children={<Bridge userInfo={userInfo}/>}/>
                        <Route path={"/monitoring"} children={<Monitoring userInfo={userInfo}/>}/>
                        <Route path={"/thirdparty"} children={<ThirdPartyManagePage userInfo={userInfo}/>}/>
                        <Route path={"/freepass"} children={<FreePassPage userInfo={userInfo}/>}/>
                        <Route path={"*"}>
                            <Redirect to={"/not-found"}/>
                        </Route>
                    </Switch>
                </PageTemplate>
            ) : (
                <Redirect to={"/login"}/>
            )
        );
    }
;
