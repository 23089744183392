/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-northeast-2",
    "aws_cognito_identity_pool_id": "ap-northeast-2:3d22385d-be71-40fd-ad5d-1e373b02e9a2",
    "aws_cognito_region": "ap-northeast-2",
    "aws_user_pools_id": "ap-northeast-2_WMSRzjjHD",
    "aws_user_pools_web_client_id": "2ahethrs6eg44i04vbrn9cvpa0",
    "oauth": {
        "domain": "auth.smartiok.com",
        "scope": [
            "openid",
            "aws.cognito.signin.user.admin",
            "com.smartiok.gateway/api.admin"
        ],
        "redirectSignIn": "https://homeiot.smartiok.com/login/check",
        "redirectSignOut": "https://homeiot.smartiok.com",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS"
};


export default awsmobile;
