import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import CheckboxTree from 'react-checkbox-tree';
import { CustomTable, SearchInputBar, CustomTextField, CustomSelectBox } from 'components/molecules';
import store from 'scripts/store';
import Net from 'scripts/net';
import { toast } from 'react-toastify';
toast.configure()

const columnList = [
    {
        code: 'authGroup',
        title: '권한그룹코드',
        width: '',
    },
    {
        code: 'authGroupName',
        title: '권한그룹명',
        width: '',
    },
    {
        code: 'authGroupMenu',
        title: '권한 적용',
        width: '',
    },
    {
        code: 'authGroupMenu.searchAuth',
        title: '조회',
        width: '',
    },
    {
        code: 'authGroupMenu.saveAuth',
        title: '저장',
        width: '',
    },
    {
        code: 'authGroupMenu.excelAuth',
        title: '엑셀',
        width: '',
    },
    {
        code: 'authGroupMenu.deleteAuth',
        title: '삭제',
        width: '',
    },
];

export const MenuManageList = (props) => {

    // useHistory 선언
    const history = useHistory();

    const [menuDataList, setMenuDataList] = useState([]);
    const [menuDataOne, setMenuDataOne] = useState({});
    const [authGroupSettingDataList, setAuthGroupSettingDataList] = useState([]);
    const [selectedMenuIdData, setSelectedMenuIdData] = useState({});
    const [isEditMode, setIsEditMode] = useState(false);
    const [searchCategoryGroup, setSearchCategoryGroup] = useState([
        [
            [
                {code: '', name: '메뉴 그룹 선택'}
            ]
        ],
    ]);
    const [programList, setProgramList] = useState([{
        code: '',
        name: '프로그램명 선택'
    }]);

    const [pageState, setPageState] = useState({
        codeType: '',
    });

    const [treeDataList, setTreeDataList] = useState([]);
    const [expanded, setExpanded] = useState([]);
    const [checkedList2, setCheckedList2] = useState([]);
    const [menuForm, setMenuForm] = useState({});

    const [programAuth, setProgramAuth] = useState({});

    useEffect(() => {
        store.openedPage = '메뉴 관리';

        Net.getCodeList('MENU_GROUP', (response) => {
            let newArray = searchCategoryGroup[0][0];
            response.data.map(items => {
                newArray = [
                    ...newArray,
                    {
                        code: items.code,
                        name: items.name,
                    }
                ]
            });
            setSearchCategoryGroup([newArray]);
        });

        // 프로그램 전체 목록
        Net.getProgramList({currentPage: 0, rowPerPage: 9999, searchText: ''}, (response) => {
            if(response.status === 200) {
                let newArray = programList;
                response.data.content.map(items => {
                    newArray = [
                        ...newArray,
                        {
                            code: items.code,
                            name: items.name
                        }
                    ]
                });
                setProgramList(newArray);
            }
        });

        pageAuthInfo();
    }, [])

    useEffect(() => {
        let rtnDataList = convertKeyName(menuDataList);
        rtnDataList = [
            {
                value: 'rootMenuId',
                label: 'root',
                showCheckbox: false,
                className: "fontC-555 fontW-500 fs-14",
                children: rtnDataList
            }
        ]

        let rtnMenuIdList = extractMenuId(menuDataList);
        rtnMenuIdList = ['rootMenuId', ...rtnMenuIdList];
        // 메뉴 목록 전체 오픈
        const tempArray = [];
        rtnMenuIdList.length > 0 && rtnMenuIdList.map(data => {
            tempArray.push(data);
        });

        setExpanded(tempArray);
        setTreeDataList(rtnDataList);

    }, [menuDataList]);

    useEffect(() => {
        const node = document.querySelectorAll('span.rct-node-clickable');
        let rtnMenuIdList = extractMenuId(menuDataList);
        rtnMenuIdList = ['rootMenuId', ...rtnMenuIdList];

        for(let i=0; i<node.length; i++) {
            node[i].setAttribute('id', rtnMenuIdList[i]);
        }

    }, [treeDataList[0]]);

    useEffect(() => {
        if(selectedMenuIdData.value && selectedMenuIdData.value !== '') {
            // loadMenuOne(selectedMenuIdData.value);
            loadMenuOne(selectedMenuIdData.value, (responseData) => {
                setMenuDataOne(responseData);
                loadAuthGroupMenuList(selectedMenuIdData.value, responseData);
            });
        }
    }, [selectedMenuIdData.value]);

    useEffect(() => {
        if(Object.keys(menuForm).length > 0) {
            Net.addMenu(menuForm, (response) => {
                if(response.status === 200) {
                    loadData();
                }
            });
        }
    }, [menuForm]);

    useEffect(() => {
        //메뉴 그룹 변경시 전체 초기화
        operationAreaClear();
        loadData();
        setMenuDataOne("");
        setAuthGroupSettingDataList([]);
        setSelectedMenuIdData({});
        setIsEditMode(false);
        setCheckedList2([]);
        setMenuForm({});
    }, [pageState.codeType,]);

    const pageAuthInfo = () => {
        const authGroupCode = props.userInfo.authList[0];
        const menuId = sessionStorage.getItem('menuId');

        Net.getAuthGroupMenuList(menuId, (response) => {
            if(response.status === 200) {
                response.data.map(item => {
                    if(item.authGroup === authGroupCode && item.authGroupMenu) {
                        setProgramAuth({
                            searchAuth: Boolean(item.authGroupMenu.searchAuth),
                            saveAuth: Boolean(item.authGroupMenu.saveAuth),
                            excelAuth: Boolean(item.authGroupMenu.excelAuth),
                            deleteAuth: Boolean(item.authGroupMenu.deleteAuth),
                        });
                    }
                });
            }
        });
    }

    const loadData = () => {
        Net.getMenuTreeList(pageState, (response) => {
            if(response.status === 200) {
                setMenuDataList(response.data);
                if(document.getElementById(selectedMenuIdData.value) !== null && selectedMenuIdData.value) {
                    document.getElementById(selectedMenuIdData.value).click();
                }
            }
        })
    }

    const loadMenuOne = (value, callback) => {
        Net.getMenuOne(value, (response) => {
            if(response.status === 200) {
                callback(response.data);
                // setMenuDataOne(response.data);
            }
        });
    }

    const loadAuthGroupMenuList = (value, menuDataOne) => {
        Net.getAuthGroupMenuList(value, (response) => {
            if(response.status === 200) {
                const keys = menuDataOne.program && Object.keys(menuDataOne.program);
                console.log(menuDataOne)
                response.data.map(data => {
                    // 프로그램의 권한 설정에 따라 메뉴관리에서 권한그룹설정의 체크리스트 활성화 여부 데이터 저장
                    let obj = {};
                    keys && keys.map(key => {
                        switch (key) {
                            case 'searchAuth':
                                return obj.searchAuth = menuDataOne.program[key];
                            case 'saveAuth':
                                return obj.saveAuth = menuDataOne.program[key];
                            case 'excelAuth':
                                return obj.excelAuth = menuDataOne.program[key];
                            case 'deleteAuth':
                                return obj.deleteAuth = menuDataOne.program[key];
                            default:
                                break;
                        }
                    });
                    for(const [key, value] of Object.entries(obj)) {
                        data[`authGroupMenu.${key}_visible`] = key ? value : false;
                    }
                });
                setAuthGroupSettingDataList(response.data);
                console.log(response.data)
            }
        });
    }

    const extractMenuId = (dataList) => {
        const array = [];
        dataList.length > 0 && dataList.map((data) => {
            array.push(data.menuId);

            if(data.children) {
                const childrenData = extractMenuId(data.children);
                childrenData.map(data2 => {
                    array.push(data2);
                });
            }
        });

        return array;
    }

    const convertKeyName = (dataList) => {
        const array = [];
        dataList.length > 0 && dataList.map((data, index) => {
            const value = data.menuId;
            const label = data.menuName;

            array.push({
                value: value,
                label: label,
                showCheckbox: false,
                className: "fontC-555 fontW-500 fs-14",
            });

            // 최상단 메뉴를 폴더 모양으로 하기 위해 추가
            if(!data.parentId) {
                array[index].children = [];
            }

            // 재귀호출을 통해 key 이름을 변경
            if(data.children) {
                const childrenData = convertKeyName(data.children);
                array[index].children = childrenData;
            }

        });

        return array;
    }

    const textFieldHandler = (name, value) => {
        const nextMenuDataOne = {
            ...menuDataOne,
            [name]: value
        }
        setMenuDataOne(nextMenuDataOne);
    }

    const selectBoxHandler = (name, value) => {
        if(name === 'menuDataOne.program.code') {
            setMenuDataOne({
                ...menuDataOne,
                programCode: value.code,
                program: {
                    ...menuDataOne.program,
                    code: value.code,
                    name: value.name,
                }
            });
        }
    }

    const checkboxHandler = (e) => {
        setCheckedList2(e);
    }

    const onSubmitHandler = (e) => {
        setPageState({
            ...pageState,
            codeType: e.selectedCategory[0],
        });
    }

    const operationAreaClear = () => {
        const operationAreaElement = document.querySelector('#operationArea');
        if(operationAreaElement !== null) {
            operationAreaElement.parentNode.removeChild(operationAreaElement);
        }
    }

    const onClickMenu = (e) => {
        setSelectedMenuIdData(e);
        operationAreaClear();
        const createOperationAreaTarget = document.getElementById(e.value);
        const spanTag = document.createElement('span');
        spanTag.setAttribute('id', 'operationArea');
        const removeBtnTag = document.createElement('button');
        const addBtnTag = document.createElement('button');
        removeBtnTag.setAttribute('class', 'removeMenuBtn');
        addBtnTag.setAttribute('class', 'addMenuBtn');
        // 버튼에 이벤트 부여
        removeBtnTag.addEventListener('click', () => {
            removeMenuList(e);
        }, false);
        addBtnTag.addEventListener('click', () => {
            addMenuList(e);
        }, false);

        if(e.treeDepth < 3) spanTag.appendChild(addBtnTag);
        if(e.value !== 'rootMenuId') spanTag.appendChild(removeBtnTag);
        createOperationAreaTarget.parentNode.appendChild(spanTag);
    }

    const cancelEditHandler = () => {
        setIsEditMode(!isEditMode);
        // loadMenuOne(selectedMenuIdData.value);
        loadMenuOne(selectedMenuIdData.value, (responseData) => {
            setMenuDataOne(responseData);
        });
        loadAuthGroupMenuList(selectedMenuIdData.value, menuDataOne);
    }

    const editHandler = () => {
        if(isEditMode) {
            onSaveHandler();
        }
        setIsEditMode(!isEditMode);
        loadMenuOne(selectedMenuIdData.value, (responseData) => {
            setMenuDataOne(responseData);
        });
        loadAuthGroupMenuList(selectedMenuIdData.value, menuDataOne);

        //저장 시 바로 반영안되는 명확한 이유를 찾지 못해 임시로 방어해둠.
        setTimeout(()=>{
            setIsEditMode(isEditMode);
            setIsEditMode(!isEditMode);
            loadMenuOne(selectedMenuIdData.value, (responseData) => {
                setMenuDataOne(responseData);
            });
            loadAuthGroupMenuList(selectedMenuIdData.value, menuDataOne);
            loadData();
        },200);
    }

    const onSaveHandler = () => {
        toast('잠시만 기다려주세요.',{
            autoClose : 3000,
            pauseOnHover: false,
            draggable : false
        });
        Net.updateMenu(menuDataOne, (response)=>{
            setMenuDataOne(response.data);
        });

        const tempArray = [];
        authGroupSettingDataList.map((data, idx) => {
            tempArray.push({});
        });

        checkedList2.map(data => {
            let authGroupMenu = data.split('_')[0];
            authGroupMenu = authGroupMenu.split('.')[1] && authGroupMenu.split('.')[1];
            const rowIdx = data.split('_')[1];

            if(authGroupMenu) {
                tempArray[rowIdx] = {
                    ...tempArray[rowIdx],
                    [authGroupMenu]: true,
                }
            }
        });

        authGroupSettingDataList.map((data, idx) => {
            if(data.authGroupMenu) {
                if(Object.keys(tempArray[idx]).length > 0) {
                    // updateAuthGroupMenu
                    const updateForm = {
                        authGroup: data.authGroup,
                        menuId: data.authGroupMenu.menuId,
                        ...tempArray[idx]
                    }
                    Net.updateAuthGroupMenu(data.authGroupMenu.id, updateForm);
                } else {
                    // removeAuthGroupMenu
                    Net.removeAuthGroupMenu(data.authGroupMenu.id);
                }
            } else {
                if(Object.keys(tempArray[idx]).length > 0) {
                    // addAuthGroupMenu
                    const addForm = {
                        authGroup: data.authGroup,
                        menuId: menuDataOne.menuId,
                        ...tempArray[idx]
                    }

                    Net.addAuthGroupMenu(addForm);
                }
            }
        });



    }

    const removeMenuList = (e) => {

        const result = window.confirm('삭제하시겠습니까?');

        if(result) {
            Net.removeMenu(e.value, (response) => {
                if(response.status === 200) {
                    loadData();
                }
            });
        }
    }

    const addMenuList = (e) => {
        const treeDepth = e.treeDepth;
        const currentIndex = e.index;
        const parentMenuId = e.parent.value;
        const value = e.value;
        const copiedArray = menuDataList.slice();

        if(pageState.codeType === '') {
            alert('메뉴 그룹을 선택해주세요.');
            document.querySelector('.searchCategory').focus();
        } else {

            let body = {};

            if(treeDepth === 0) {
                body = {
                    menuGroup: pageState.codeType,
                    menuName: `tempLabel_${treeDepth + 1}_${treeDataList[0].children.length}`,
                    level: 0,
                    sort: menuDataList.length+1,
                }
            } else if(treeDepth === 1) {
                body = {
                    level: copiedArray[currentIndex].level + 1,
                    menuGroup: pageState.codeType,
                    menuName: `tempLabel_${treeDepth + 1}_${copiedArray[currentIndex].children ? copiedArray[currentIndex].children.length : 0}`,
                    parentId: value,
                    programCode: "",
                    sort: !copiedArray[currentIndex].children ? 1 : (copiedArray[currentIndex].children.length+1),
                }
            } else if(treeDepth === 2) {

                let parentIndex;

                copiedArray.map((item, index) => {
                    if(item.menuId === parentMenuId) {
                        parentIndex = index;
                    }
                });

                if(copiedArray[parentIndex].children[currentIndex].children) {
                    body = {
                        level: copiedArray[parentIndex].children[currentIndex].level + 1,
                        menuGroup: pageState.codeType,
                        menuName: `tempLabel_${treeDepth + 1}_${copiedArray[parentIndex].children[currentIndex].children.length}`,
                        parentId: value,
                        programCode: "",
                        sort: copiedArray[parentIndex].children[currentIndex].children.length === 0 ? 1 : (copiedArray[parentIndex].children[currentIndex].children.length+1),
                    }
                } else {
                    body = {
                        level: copiedArray[parentIndex].children[currentIndex].level + 1,
                        menuGroup: pageState.codeType,
                        menuName: `tempLabel_${treeDepth + 1}_0`,
                        parentId: value,
                        programCode: "",
                        sort: 1,
                    }
                }
            }

            setMenuForm(body);
        }
    }

    return (
        <div className="w-100 h-100">
            <div className="w-100 h-7 display_table">
                <SearchInputBar
                    className="w-100"
                    searchCategory={true}
                    searchCategoryGroup={searchCategoryGroup}
                    searchText={false}
                    searchBtn={true}
                    searchBtnTitle="조회"
                    dynamicSearch={false}
                    onSubmit={onSubmitHandler}
                />
                {/* <div className="display_table_cell v-middle"> */}
                <div className="flex h-100">
                    { isEditMode &&
                        <button
                            className="cancelBtn w-90px h-30px floatR mt-auto mb-auto mr-10"
                            onClick={cancelEditHandler}
                        >편집취소</button>
                    }
                    {
                        programAuth.saveAuth && selectedMenuIdData.value && selectedMenuIdData.value !== 'rootMenuId' &&
                        <button
                            className="addBtn w-90px h-30px floatR mt-auto mb-auto mr-20"
                            onClick={editHandler}
                        >{isEditMode ? "저장" : "편집"}</button>
                    }
                </div>
            </div>
            <div className="w-100 h-1px bgC-efefef" />
            <div className="flex h-93">
                <div className="w-40 h-100">
                    <p className="body_content_subtitle">메뉴 목록</p>
                    <div className="w-90 h-65vh m-auto overflowYscroll borderC-eb878c">
                        <CheckboxTree
                            nodes={treeDataList}
                            expanded={expanded}
                            onExpand={expanded => setExpanded(expanded)}
                            expandOnClick={false}
                            onClick={onClickMenu}
                        />
                    </div>
                </div>
                <div className="w-60 h-100">
                    <div className="w-100 h-50">
                        <p className="body_content_subtitle">프로그램 설정</p>
                        <div className="w-90 h-30vh ml-2p pdl-2p overflowYscroll">
                            <div className="flex mb-12 w-80">
                                <CustomTextField
                                    className="w-300px h-26px"
                                    title="프로그램 코드"
                                    name="programCode"
                                    required={false}
                                    disabled={true}
                                    customOnChange={textFieldHandler}
                                    value={menuDataOne}
                                />
                            </div>
                            <div className="flex mb-12 w-80">
                                <CustomTextField
                                    className="w-300px h-26px"
                                    title="메뉴명"
                                    name="menuName"
                                    required={false}
                                    disabled={!isEditMode}
                                    customOnChange={textFieldHandler}
                                    value={menuDataOne}
                                />
                            </div>
                            <div className="flex mb-12 w-80">
                                <label className="label_title">프로그램명</label>
                                <CustomSelectBox
                                    className="w-300px h-26px"
                                    name="program.code"
                                    disabled={!isEditMode}
                                    categoryList={programList}
                                    onChangeHandler={(value, name) => selectBoxHandler('menuDataOne.program.code', {code: value, name: name})}
                                    callbackCodeName="Y"
                                    value={menuDataOne.program ? menuDataOne.program.code : ''}
                                />
                            </div>
                            <div className="flex mb-12 w-80">
                                <CustomTextField
                                    className="w-300px"
                                    title="메뉴 순서"
                                    name="sort"
                                    required={false}
                                    disabled={!isEditMode}
                                    customOnChange={textFieldHandler}
                                    value={menuDataOne}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="w-100 h-50">
                        <p className="body_content_subtitle">권한 그룹 설정</p>
                        <div className="w-90 h-30vh ml-2p overflowYscroll">
                            <CustomTable
                                columnList = {columnList}
                                contentData = {authGroupSettingDataList}
                                checkbox = {false}
                                checkedList2 = {(checkedList2) => {checkboxHandler(checkedList2)} }
                                paging = {false}
                                isEditMode = {isEditMode}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
