import React, {Fragment, useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom'
import {CustomTable, SearchInputBar} from 'components/molecules';
import store from 'scripts/store';
import Net from 'scripts/net';
import {toast} from "react-toastify";
import Zoom from "react-medium-image-zoom";

const columnList = [
    {
        code: 'noticeId',
        title: 'noticeId',
        width: '8',
        hidden: true,
    },
    {
        code: 'classificationKOR',
        title: '구분',
        width: '8',
    },
    {
        code: 'title',
        title: '제목',
        width: '20',
    },
    {
        code: 'viewDate',
        title: '공개기간',
        width: '18',
    },
    {
        code: 'mainNotice',
        title: '중요공지',
        width: '5',
    },
    {
        code: 'dongdetail',
        title: '동명',
        width: '6',
    },
    {
        code: 'hodetail',
        title: '호명',
        width: '6',
    },
    {
        code: 'site.name',
        title: '현장명',
        width: '10',
    },
    {
        code: 'customer.name',
        title: '고객사',
        width: '8',
    },
    {
        code: 'publicYn',
        title: '사용여부',
        width: '8',
    },
    {
        code: 'isAppClose',
        title: '앱 종료여부',
        width: '8',
    },
];

export const NoticeList = (props) => {
    // useHistory 선언
    const history = useHistory();
    const user = props.userInfo;
    const [programAuth, setProgramAuth] = useState({});
    const [contentData, setContentData] = useState([]);
    const [checkedList, setCheckedList] = useState([]);
    const [noticeDetail, setNoticeDetail] = useState([]);
    const [loading, setLoading] = useState(false);
    const [searchCategory, setSearchCategory] = useState([
        [
            [
                {code: '', name: '고객사 선택'}
            ]
        ],
        [
            [
                {code: '', name: '현장명 선택'}
            ]
        ],
        [
            [
                {code: '', name: '구분 선택'}
            ]
        ],
    ]);

    const [pageState, setPageState] = useState({
        currentPage: 0,
        rowPerPage: 13,
        totalElementsCnt: 0,
        drawElementsCnt: 0,
        searchText: '',
        searchCategory1: user.menuGroup === 'ADMIN' ? '' : user.customer && user.customer.code,
        searchCategory2: user.menuGroup === 'OPERATOR' ? user.site && user.site.code: '',
        searchCategory3: '',
    });

    useEffect(() => {
        store.openedPage = '공지사항 목록';
        getCategoryList('');
        pageAuthInfo();
    }, [])

    useEffect(() => {
        loadData();
    }, [pageState.currentPage,
        pageState.searchCategory1,
        pageState.searchCategory2,
        pageState.searchCategory3,
    ]);

    const pageAuthInfo = () => {
        const authGroupCode = props.userInfo.authList[0];
        const menuId = sessionStorage.getItem('menuId');

        Net.getAuthGroupMenuList(menuId, (response) => {
            if (response.status === 200) {
                response.data.forEach(item => {
                    if (item.authGroup === authGroupCode && item.authGroupMenu) {
                        setProgramAuth({
                            searchAuth: Boolean(item.authGroupMenu.searchAuth),
                            saveAuth: Boolean(item.authGroupMenu.saveAuth),
                            excelAuth: Boolean(item.authGroupMenu.excelAuth),
                            deleteAuth: Boolean(item.authGroupMenu.deleteAuth),
                        });
                    }
                });
            }
        });
    }

    const loadData = () => {
        setLoading(true);
        getClassificationList((response) => {
            const codeList = response.data;
            Net.getNoticeList(
                {
                    currentPage: pageState.currentPage,
                    rowPerPage: pageState.rowPerPage,
                    searchText: pageState.searchText,
                    searchCategory1: pageState.searchCategory1,
                    searchCategory2: pageState.searchCategory2,
                    searchCategory3: pageState.searchCategory3
                }, (response) => {
                    setLoading(false);
                    if (response.status === 200) {
                        setContentData(response.data.content);
                        response.data.content.forEach((item) => {
                            if (item.dong !== "ALL") {
                                item.dongdetail = item.dong.replace(/(^0+)/, "") + "동";
                            } else{
                                item.dongdetail = "전체";
                            }

                            if (item.ho !== "ALL") {
                                item.hodetail = item.ho.replace(/(^0+)/, "") + "호";
                            } else{
                                item.hodetail = "전체"
                            }


                            if (item.mainNotice) {
                                item.mainNotice = "중요";
                            } else {
                                item.mainNotice = "일반";
                            }


                            if (item.isAppClose) {
                                item.isAppClose = "종료";
                            } else if (item.isAppClose === undefined) {
                                item.isAppClose = "-";
                            } else {
                                item.isAppClose = "미종료";
                            }

                            item.viewDate = item.startDate + " ~ " + item.endDate;

                            codeList.forEach(cItem => {
                                if (item.classification === cItem.code) {
                                    item.classificationKOR = cItem.name;
                                }

                            })
                        })
                        setPageState({
                            ...pageState,
                            totalElementsCnt: response.data.totalElements,
                            drawElementsCnt: response.data.numberOfElements,
                        });
                    } else {
                        setContentData([]);
                        setPageState({
                            ...pageState,
                            totalElementsCnt: 0,
                        });
                    }
                });
        });

    }

    const getClassificationList = (callback) => {
        Net.getCommunityCode(
            "NOTICE_CLASSIFICATION", (response) => {
                if (response.status === 200) {
                    let newArray = [{code: '', name: "구분 선택"}];
                    if(user.menuGroup === "ADMIN"){
                        response.data.forEach(items => {
                            if (items.usable) {
                                newArray = [
                                    ...newArray,
                                    {
                                        code: items.code,
                                        name: items.name
                                    }
                                ]
                            }
                        })
                    }else{
                        response.data.forEach(items => {
                            if (items.usable && items.name !== "장애") {
                                newArray = [
                                    ...newArray,
                                    {
                                        code: items.code,
                                        name: items.name
                                    }
                                ]
                            }
                        })
                    }
                    callback({status: 200, data: newArray});
                }
            }
        )
    }

    const getCategoryList = () => {
        let response3 = [];
        getClassificationList(response => {
            if (response.status === 200) {
                response3 = response.data;
                if (user.menuGroup === 'ADMIN') {
                    getCustomerList((response1) => {
                        getSitesList(null, (response2) => {
                            setSearchCategory([response1, response2, response3]);
                        });
                    });
                } else if (user.menuGroup === 'MANAGER') {
                    getSitesList(pageState.searchCategory1, (response2) => {
                        setSearchCategory([response2, response3]);
                    });
                } else {
                    setSearchCategory([response3]);
                }
            }
        });
    }

    const getRelateCategory = (selectedCategory) => {
        if (selectedCategory === "reset") selectedCategory = "";
        getSitesList(selectedCategory, (response2) => {
            setSearchCategory([
                searchCategory[0],
                response2,
                searchCategory[2]
            ]);
        });
    }

    const getCustomerList = (callback) => {
        Net.getCustomerList(
            {currentPage: '', rowPerPage: '', searchText: ''}, (response) => {
                if (response.status === 200) {
                    let newArray = searchCategory[0][0];
                    response.data.content.forEach(items => {
                        newArray = [
                            ...newArray,
                            {
                                code: items.code,
                                name: items.name
                            }
                        ]
                    });
                    callback(newArray);
                }
            });
    }

    const getSitesList = (selectedCategory, callback) => {
        if (selectedCategory) {
            Net.getSitesList(
                {currentPage: '', rowPerPage: '', searchText: '', customerCode: selectedCategory ? selectedCategory : false}, (response) => {
                    if (response.status === 200) {
                        let newArray = [{code: '', name: "현장명 선택"}];
                        if (response.data.content.length > 0) {
                            response.data.content.forEach(items => {
                                newArray = [
                                    ...newArray,
                                    {
                                        code: items.code,
                                        name: items.name
                                    }
                                ]
                            })
                        }
                        callback(newArray);
                    } else {
                        let newArray = [{code: '', name: "현장명 선택"}];
                        callback(newArray);
                    }
                });
        } else {
            let newArray = [{code: '', name: "현장명 선택"}];
            callback(newArray);
        }
    }

    const onClickRow = async (e) => {
        const responseNoticeOne = await Net.getNoticeOneAsync(e.noticeId);
        if (responseNoticeOne.status === 200) {
            console.log(responseNoticeOne)
            setNoticeDetail(responseNoticeOne.data);
        } else{
            setNoticeDetail([]);
        }
    }

    const onMovePage = (e) => {
        setPageState({
            ...pageState,
            currentPage: e - 1
        });
    }

    const removeList = () => {
        const result = window.confirm('삭제하시겠습니까?');
        const removeList = [];
        checkedList.forEach((id) => {
            const node = document.querySelector(`#${id}`);
            const nodeTr = node.closest('tr');
            const noticeId = nodeTr.querySelector('td[name=noticeId]').textContent;
            removeList.push({noticeId: noticeId});
        });
        if (result) {
            Net.removeNotice(removeList, (response) => {
                if (response) {
                    toast('삭제 되었습니다.',{
                        autoClose : 1500,
                        pauseOnHover: false,
                        draggable : false,
                        position : "top-center"
                    });
                    loadData();
                }
            });
        }
    }

    const onSubmitHandler = (e) => {
        if (user.menuGroup === 'ADMIN') {
            setPageState({
                ...pageState,
                currentPage: 0,
                searchCategory1: e.selectedCategory[0],
                searchCategory2: e.selectedCategory[1],
                searchCategory3: e.selectedCategory[2],
            });

        } else if (user.menuGroup === 'MANAGER') {
            setPageState({
                ...pageState,
                currentPage: 0,
                searchCategory1: pageState.searchCategory1,
                searchCategory2: e.selectedCategory[0],
                searchCategory3: e.selectedCategory[1],
            });
        } else {
            setPageState({
                ...pageState,
                currentPage: 0,
                searchCategory1: pageState.searchCategory1,
                searchCategory2: pageState.searchCategory2,
                searchCategory3: e.selectedCategory[0],
            });
        }
    }

    const addList = () => {
        history.push('/community/notice/regist');
    }

    const goModifypage = () => {
        history.push(`/community/notice/regist/${noticeDetail.noticeId}`);
    }

    const fileDownload = async (item) => {
        const fileData = await Net.getFileData('notice',item.id);
        const fileName = fileData.data.filePath.split("/")[1];
        const res = await Net.downloadFile('notice', item.id);
        res.headers['content-disposition'] = 'attachment';
        const blob = new Blob([res.data],{
            type : res.headers["content-type"]});

        const fileUrl = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = fileUrl;
        link.style.display = 'none';
        link.download = item.name;

        document.body.appendChild(link);

        link.click();
        link.remove();
    }

    return (
        <div className="w-100 h-100">
            <div className="w-100 h-7 display_table">
                <SearchInputBar
                    role={user.menuGroup}
                    className="w-100"
                    searchCategory={true}
                    searchCategoryGroup={searchCategory}
                    searchBtn={true}
                    searchBtnTitle="검색"
                    dynamicSearch={false}
                    relateCategoryHandler={user.menuGroup === 'ADMIN' && getRelateCategory}
                    onSubmit={onSubmitHandler}
                    refreshBtn={true}
                    refreshHandler={loadData}
                />
                {
                    programAuth.saveAuth &&
                    <div className="display_table_cell v-middle">
                        <button
                            className="addBtn w-90px h-30px floatR mr-20"
                            onClick={addList}
                        >등록
                        </button>
                    </div>

                }

            </div>
            <div className="w-100 h-1px bgC-efefef"/>
            <div className="flex h-93">
                <div className="w-70 h-100">
                    <p className="totalCnt">Total {pageState.totalElementsCnt}</p>
                    {
                        programAuth.deleteAuth && checkedList.length > 0 &&
                        <button
                            className="removeBtn w-90px h-30px floatR mt-7 mr-15"
                            onClick={removeList}
                        >삭제</button>
                    }
                    <CustomTable
                        columnList={columnList}
                        contentData={contentData}
                        paging={true}
                        checkbox={programAuth.deleteAuth}
                        checkedList={(checkedList) => {
                            setCheckedList(checkedList)
                        }}
                        totalElementsCnt={pageState.totalElementsCnt}
                        rowPerPage={pageState.rowPerPage}
                        currentPage={pageState.currentPage}
                        drawElementsCnt={pageState.drawElementsCnt}
                        pagination={onMovePage}
                        rowClickEvt={true}
                        onClickRow={onClickRow}
                        loading={loading}
                        // sort = {true}
                    />
                </div>
                <div className="w-30 h-100">
                    <div className="flex">
                        <p className="body_content_subtitle">내용</p>
                        {
                            noticeDetail.contents ?
                                (
                                    <button
                                        className="defaultBtn bgC-eb878c borderC-eb878c w-90px h-30px mr-20 mt-auto mb-auto ml-auto"
                                        onClick={goModifypage}
                                    >수정</button>
                                ) : ""
                        }

                    </div>
                    <div className="w-100 h-50">
                        <table className="mainTable">
                            <thead className="tableHead">
                            <tr>
                                <th>내용</th>
                            </tr>
                            </thead>
                            <tbody className="tableBody">
                            {
                                noticeDetail.contents ?
                                    (
                                        <Fragment>
                                            <tr>
                                                <td style={{padding: "10px", overflow: "unset", whiteSpace: "initial"}}>
                                                    <div className="overFlowYscrollView h-46vh">
                                                        <div dangerouslySetInnerHTML={{__html: noticeDetail.contents}}/>
                                                        {
                                                            noticeDetail.imageDataUrl &&
                                                            <div className="mt-10 text-center">
                                                                <Zoom>

                                                                      <img src={noticeDetail.imageDataUrl} alt="complex=Img" width="300px"/>
                                                                </Zoom>

                                                            </div>
                                                        }
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr className="tableHead">
                                                <td> 파일 목록 </td>
                                            </tr>
                                            <tr className="">
                                                <td>

                                                {noticeDetail.fileInfo && noticeDetail.fileInfo.map((item) => (
                                                    <div key={item.id} style={{textAlign : 'left', marginLeft : '10px'}}>
                                                            <span
                                                                style={{maxWidth : '70%', display : 'inline-block',  overflow: 'hidden',
                                                                    textOverflow: 'ellipsis'}}
                                                               className="hoverbtn me-1 text-decoration-underline"
                                                             onClick={()=>fileDownload(item)}>
                                                            {item.name}
                                                        </span>
                                                           <button
                                                               style={{position : 'absolute', fontSize : '0.6rem'}}
                                                               className="downloadBtn"
                                                               onClick={()=>fileDownload(item)}
                                                           >다운로드</button>
                                                    </div>
                                                ))}
                                            </td>

                                            </tr>

                                        </Fragment>
                                    ) : (
                                        <tr>
                                            <td>조회 된 데이터가 없습니다.</td>
                                        </tr>
                                    )
                            }

                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
}
