import React, {useEffect, useState} from 'react';
import { Route, Switch} from "react-router-dom";
import { Admin } from "./Admin";
import { Manager } from "./Manager";
import { Operator } from "./Operator";
import NotFound from "../../components/templates/NotFound";
import {Etc} from "./Etc/Operator";


export const DashBoardPage = ({userInfo}) => {

    const [children, setChildren] = useState();

    useEffect(()=>{
        let isComponentMounted = true

        if (isComponentMounted) {
            setChildren(userInfo.menuGroup === 'ADMIN' ? <Admin userInfo={userInfo}/> :
                userInfo.menuGroup === 'MANAGER' ? <Manager userInfo={userInfo}/> : userInfo.menuGroup === 'OPERATOR' || userInfo.menuGroup === 'OPERATOR_MONITORING' ?  <Operator userInfo={userInfo}/> : <Etc userInfo={userInfo}/>)
        }

        return () => {
            isComponentMounted = false;
        }
    },[userInfo]);

    return (
        <Switch>
            <Route path={"/dashboard"} exact children={children} />
            <Route path={"*"}>
                <NotFound />
            </Route>
        </Switch>
    );
};
