import React from 'react'
import { Switch, Route } from 'react-router-dom'
import {NotificationHistoryList} from './NotificationHistoryPage';
import {NotificationManage} from "./NotificationManagePage";
import NotFound from "../../components/templates/NotFound";

export const NotificationPage = ({userInfo}) => {

    return (
        <Switch>
            <Route path={"/notification/history/list"} exact children={<NotificationHistoryList userInfo={userInfo}/>} />
            <Route path={"/notification/manage"} exact children={<NotificationManage userInfo={userInfo}/>} />
            <Route path={"*"}>
                <NotFound />
            </Route>
        </Switch>
    )
}
