import React, {Fragment, useEffect, useState} from "react";
import {CustomTable, SearchInputBar, BasicModal} from 'components/molecules';
import Net from 'scripts/net';
import Common from 'scripts/common';


export const AppliancePropertyModal = (props) => {
    const modalColumnList = [
        {
            code : 'no',
            title: 'No',
            width: '5',
        },
        {
            code : 'propertyCode',
            title: 'IoK 속성',
            width: '10',
        },
        {
            code : 'propertyType',
            title: 'IoK 속성 타입',
            width: '6',
        },
        {
            code : 'propertyValue',
            title: 'IoK 속성값',
            width: '20',
        },
        {
            code : 'applianceResource',
            title: '가전사 실행그룹',
            width: '7',
        },
        {
            code : 'appliancePropertyCode',
            title: '가전사 속성',
            width: '7',
        },
        {
            code : 'appliancePropertyValue',
            title: '가전사 속성값',
            width: '7',
        },
        {
            code : 'applianceRwMode',
            title: '가전사 명령여부',
            width: '7',
        },
        {
            code : 'appliancePropertyUnit',
            title: '가전사 단위',
            width: '7',
        },
    ];

    const [dvMappList, setDvMappList] = useState([]);

    useEffect( ()=>{
        {/*
          1. 목록 조회
        */}
        loadData();
    },[])

    const loadData = () => {
        Net.getApplianceDeviceMapping(props.data.data, response => {
            setDvMappList(response.data.content);
        });
    }



    const onClickRow = (e) => {

    }


    return (
        <div className="overflow-hidden">
            <h5 className="m-3 ml-50">타입별 장비 속성 맵핑( LG, 삼성전자 ) {props.data.data.type} , {props.data.data.appliances} </h5>
            <div className="row">
                <div className="col-4 ml-20">
                </div>
            </div>
            <div className="flex justify-content-between">
                <div className="col-12">
                    <CustomTable
                        columnList={modalColumnList}
                        contentData={dvMappList}
                        totalElementsCnt={dvMappList.length}
                        rowPerPage={999999}
                        currentPage={0}
                    />
                </div>
            </div>
        </div>
    )
}
