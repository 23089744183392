import React, {useEffect, useState} from "react";
import classNames from "classnames";
import Net from 'scripts/net';
import {CustomTable, SearchInputBar} from 'components/molecules';

export const TelecomUserDeviceModal = (props) => {
    const modalColumnList = [
        {
            code: 'extensionService',
            title: '음성서비스',
            width: '10',
        },
        {
            code: 'deviceType',
            title: 'DeviceType',
            width: '10',
        },
        {
            code: 'modelCode',
            title: '모델 코드',
            width: '10',
        },
        {
            code: 'deviceNickname',
            title: '디바이스명',
            width: '10',
        },
        {
            code: 'deviceId',
            title: '디바이스 ID',
            width: '20',
        },
    ];

    const [propertyList, setpropertyList] = useState([]);

    const [pageState, setPageState] = useState({
        currentPage: 1,
        rowPerPage: 10,
        totalElementsCnt: 0,
        drawElementsCnt: 0,
        extensionService : null,
    });

    const [searchCategory, setSearchCategory] = useState([
        [
            {code: '', name: '음성 서비스 선택'},
            {code: 'kt', name: 'KT'},
            {code: 'skt', name: 'SKT'},
            {code: 'lguplus', name: 'LGU+'},
            {code: 'samsung', name: '삼성전자'},
            {code: 'hyundai', name: '현대'},
        ],
    ]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        loadData();
    }, [pageState.currentPage,pageState.extensionService
    ]);

    const loadData = () => {
        setLoading(true);
        Net.getTelecomUserDeviceList(
            {
                currentPage: pageState.currentPage-1,
                rowPerPage: pageState.rowPerPage,
                username: props.propsData.username,
                extensionService : pageState.extensionService
            }, (response) => {
                setLoading(false);
                if (response.status === 200) {
                    setpropertyList(response.data.content);
                    setPageState({
                        ...pageState,
                        totalElementsCnt: response.data.totalElements,
                        drawElementsCnt: response.data.numberOfElements,
                    });
                } else {
                    setpropertyList([]);
                    setPageState({
                        ...pageState,
                        totalElementsCnt: 0,
                        drawElementsCnt: 0,
                    });
                }
            });
    }

    const onMovePage = (e) => {
        setPageState({
            ...pageState,
            currentPage: e
        });
    }

    const onSubmitHandler = (e) => {
            setPageState({
                ...pageState,
                currentPage: 1,
                extensionService: e.selectedCategory[0],
            })
    }


    return (
        <div>
            <SearchInputBar
                className="w-100 display_table mb-2"
                searchCategory={true}
                searchCategoryGroup={searchCategory}
                searchBtn={true}
                searchBtnTitle="검색"
                dynamicSearch={false}
                onSubmit={onSubmitHandler}
            />
            <p className={classNames("totalCnt")} style={{"marginTop": "0"}}>Total : {pageState.totalElementsCnt}</p>
            <CustomTable
                className={"w-100 h-57vh overflowYscroll"}
                columnList={modalColumnList}
                contentData={propertyList}
                checkbox={false}
                rowClickEvt={true}
                paging={true}
                pagination={onMovePage}
                totalElementsCnt={pageState.totalElementsCnt}
                rowPerPage={pageState.rowPerPage}
                currentPage={pageState.currentPage - 1}
                drawElementsCnt={pageState.drawElementsCnt}
                loading={loading}
            />
        </div>
    )
}
