import React, {useState, useEffect, useRef} from 'react'
import './FileListWithDelete.scss';
import 'react-medium-image-zoom/dist/styles.css';

export const FileListWithDelete = ({fileData, onUpdateFiles}) => {
    const [existingFiles, setExistingFiles] = useState(fileData); // 기존 등록된 파일 목록
    const [newFiles, setNewFiles] = useState([]); // 신규로 등록된 파일 목록
    const [selectedFiles, setSelectedFiles] = useState([]); // 선택된 파일 목록

    const fileInputRef = useRef(null);

    useEffect(() => {
        setExistingFiles(fileData);
    }, [fileData]);

    useEffect(()=>{
        console.log(newFiles)
    },[newFiles]);

    useEffect(()=>{
        console.log(existingFiles, newFiles);
        onUpdateFiles(existingFiles, newFiles);
    },[existingFiles, newFiles]);

    const handleFileChange = (event) => {
        const fileList = event.target.files;
        let selected = [];

        for (let i = 0; i < fileList.length; i++) {
            if(existingFiles.length + newFiles.length  + selected.length > 4) {
                alert('첨부파일은 최대 5개 까지 등록 가능합니다.');
                return;
            }
            const file = fileList[i];
            const fileSizeInMB = file.size / (1000 * 1024); // 파일 용량을 MB로 계산

            if (fileSizeInMB <= 10) {
                selected.push(file);
            } else {
                alert('10MB 이상의 파일은 업로드 제한됩니다.' ,file.name);
            }
        }


        setNewFiles([...newFiles, ...selected]);
    };

    const handleFileRemove = () => {
        const filteredExistingFiles = existingFiles.filter((file) => !selectedFiles.includes(file));
        const filteredNewFiles = newFiles.filter((file) => !selectedFiles.includes(file));
        setExistingFiles(filteredExistingFiles);
        setNewFiles(filteredNewFiles);
        setSelectedFiles([]);
    };

    return (
        <div className="file-uploader-container">
            <div className="file-list-container">
                <div className="file-list-header">
                <span className="main-header">
                    게시글 첨부 파일 목록
                </span>
                <span className="sub-header" >
                    ※ 업로드 제한 :   <span style={{color:'green'}}>5개 </span>  / 최대 용량 :  <span style={{color:'green'}}>10MB </span> 이내
                </span>
                </div>
            <ul className="file-list">
                {
                    existingFiles.length ===0 && newFiles.length === 0 ?
                        <>
                            <div className="no-file-name"> 📎 파일 추가 버튼을 클릭하여 파일 첨부 </div>

                        </>
                        :
                        <>
                            {existingFiles.map((file) => (
                                <li key={file.id}>
                                    <input
                                        type="checkbox"
                                        checked={selectedFiles.some((selectedFile) => selectedFile.id === file.id)}
                                        onChange={() => {
                                            const isChecked = selectedFiles.some((selectedFile) => selectedFile.id === file.id);
                                            setSelectedFiles(isChecked
                                                ? selectedFiles.filter((f) => f.id !== file.id)
                                                : [...selectedFiles, file]
                                            );
                                        }}
                                    />
                                    <span className="file-name">{file.name}</span>
                                </li>
                            ))}
                            {newFiles.map((file, index) => (
                                <li key={index}>
                                    <input
                                        type="checkbox"
                                        checked={selectedFiles.includes(file)}
                                        onChange={() => {
                                            const isChecked = selectedFiles.includes(file);
                                            setSelectedFiles(isChecked
                                                ? selectedFiles.filter((f) => f !== file)
                                                : [...selectedFiles, file]
                                            );
                                        }}
                                    />
                                    <span className="file-name">{file.name}</span>
                                    <button className="new-tag">신규</button>
                                </li>
                            ))}
                        </>
                }

            </ul>

                <label htmlFor="file-input" className="custom-file-input">
                    파일 추가
                    <input
                        id="file-input"
                        ref={fileInputRef}
                        type="file"
                        multiple
                        onChange={handleFileChange}
                        style={{ display: 'none' }} // 숨겨진 파일 입력 요소
                    />
                </label>
                <button className="file-delete-button" onClick={handleFileRemove} disabled={selectedFiles.length === 0}>
                    파일 삭제
                </button>

        </div>
        </div>
    );
};