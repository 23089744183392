import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { CustomTable, SearchInputBar } from 'components/molecules';
import store from 'scripts/store';
import Net from 'scripts/net';
import {CustomExcelDownload, ExcelUpload} from "../../../components/molecules";
import {useDidMountEffect} from "scripts/hooks";

const columnList = [
    {
        code: 'site.name',
        title: 'name',
        width: '',
    },
    {
        code: 'site.code',
        title: 'code',
        width: '',
    },
    {
        code: 'dong',
        title: 'dong',
        width: '',
    },
    {
        code: 'ho',
        title: 'ho',
        width: '',
    },
    {
        code: 'type',
        title: 'type',
        width: '',
    },
    {
        code: 'usable',
        title: 'usable',
        width: '',
    },
];

const datacolumnList = [
    {
        code: 'name',
        title: 'name',
        width: '',
    },
    {
        code: 'code',
        title: 'code',
        width: '',
    },
    {
        code: 'dong',
        title: 'dong',
        width: '',
    },
    {
        code: 'ho',
        title: 'ho',
        width: '',
    },
    {
        code: 'type',
        title: 'type',
        width: '',
    },
    {
        code: 'usable',
        title: 'usable',
        width: '',
    },
];

export const SitePlaceUploadList = (props) => {

    // useHistory 선언
    const history = useHistory();
    const user = props.userInfo;
    const [contentData, setContentData] = useState([]);
    const [addData, setAddData] = useState([]);
    const [checkedList, setCheckedList] = useState([]);
    const [contentData4Excel, setContentData4Excel] = useState([]);
    const [url, setUrl] = useState();

    const [searchCategoryGroup, setSearchCategoryGroup] = useState([
        [
            [
                {code: '', name: '고객사명 선택'}
            ]
        ],
        [
            [
                {code: '', name: '현장명 선택'}
            ]
        ],
    ]);

    const [pageState, setPageState] = useState({
        currentPage: 1,
        rowPerPage: 10,
        totalElementsCnt: 0,
        drawElementsCnt: 0,
        customerCode: user.menuGroup === 'ADMIN' ? '' : user.customer && user.customer.code,
        siteCode: user.menuGroup === 'OPERATOR' ? user.site && user.site.code: '',
        searchText: '',
    });

    const [programAuth, setProgramAuth] = useState({});

    useEffect(() => {
        store.openedPage = '현장 장소 목록';
        getCategoryList();
        pageAuthInfo();
    }, []);

    useEffect(() => {
        loadData();
    }, [
        pageState.customerCode,
        pageState.siteCode,
        pageState.currentPage,
        pageState.searchText,
    ]);

    useDidMountEffect( ()=>{
        addData.forEach(async (item) => {
            const form = {
                dong:item.dong,
                ho:item.ho,
                site:{
                    name:item.name,
                    code:item.code
                },
                type:item.type,
                usable:item.usable
            }
            await Net.addSitePlaceAsync(form);
        });
    },[addData])

    const pageAuthInfo = () => {
        const authGroupCode = props.userInfo.authList[0];
        const menuId = sessionStorage.getItem('menuId');

        Net.getAuthGroupMenuList(menuId, (response) => {
            if(response.status === 200) {
                response.data.forEach(item => {
                    if(item.authGroup === authGroupCode && item.authGroupMenu) {
                        setProgramAuth({
                            searchAuth: Boolean(item.authGroupMenu.searchAuth),
                            saveAuth: Boolean(item.authGroupMenu.saveAuth),
                            excelAuth: Boolean(item.authGroupMenu.excelAuth),
                            deleteAuth: Boolean(item.authGroupMenu.deleteAuth),
                        });
                    }
                });
            }
        });
    }

    const loadData = () => {
        Net.getSitePlaceList(pageState, (response) => {
            if(response.status === 200) {
                if(response.data.content) {
                    setContentData(response.data.content);
                    setContentData4Excel(response.data.content);
                    setPageState({
                        ...pageState,
                        totalElementsCnt: response.data.totalElements,
                        drawElementsCnt: response.data.numberOfElements,
                    });
                }
            } else if(response.status === 204) {
                setContentData([]);
                setPageState({
                    ...pageState,
                    totalElementsCnt: 0,
                    drawElementsCnt: 0,
                });
            }
        });
    }

    const getCategoryList = () => {
        if (user.menuGroup === 'ADMIN') {
            getCustomerList((response1) => {
                getSitesList(null, (response2) => {
                    setSearchCategoryGroup([response1, response2]);
                });
            });
        } else if (user.menuGroup === 'MANAGER') {
            getSitesList(pageState.customerCode, (response2) => {
                setSearchCategoryGroup([response2]);
            });
        }
    }

    const getRelateCategory = (selectedCategory) => {
        if (selectedCategory === "reset") selectedCategory = "";
        getSitesList(selectedCategory, (response2) => {
            setSearchCategoryGroup([
                searchCategoryGroup[0],
                response2
            ]);
        });
    }

    const getCustomerList = (callback) => {
        Net.getCustomerList({currentPage: '', rowPerPage: '', searchText: ''}, (response) => {
            if(response.status === 200) {
                let newArray = searchCategoryGroup[0][0];
                response.data.content.forEach(items => {
                    newArray = [
                        ...newArray,
                        {
                            code: items.code,
                            name: items.name
                        }
                    ]
                });
                callback(newArray);
            }
        });
    }

    const getSitesList = (selectedCategory, callback) => {
        if (selectedCategory) {
            Net.getSitesList(
                {currentPage: '', rowPerPage: '', searchText: '', customerCode: selectedCategory ? selectedCategory : false}, (response) => {
                    if (response.status === 200) {
                        let newArray = [{code: '', name: "현장명 선택"}];
                        if (response.data.content.length > 0) {
                            response.data.content.forEach(items => {
                                newArray = [
                                    ...newArray,
                                    {
                                        code: items.code,
                                        name: items.name
                                    }
                                ]

                            })
                        }
                        callback(newArray);
                    } else {
                        let newArray = [{code: '', name: "현장명 선택"}];
                        callback(newArray);
                    }
                });
        } else {
            let newArray = [{code: '', name: "현장명 선택"}];
            callback(newArray);
        }
    }

    const onMovePage = (e) => {
        setPageState({
            ...pageState,
            currentPage: e
        });
    }

    const onSubmitHandler = (e) => {
        if (user.menuGroup === 'ADMIN') {
            setPageState({
                ...pageState,
                customerCode: e.selectedCategory[0],
                siteCode: e.selectedCategory[1],
                searchText: pageState.searchText !== e.searchText ? e.searchText : ''
            });

        } else if (user.menuGroup === 'MANAGER') {
            setPageState({
                ...pageState,
                customerCode: pageState.customerCode,
                siteCode: e.selectedCategory[0],
                searchText: pageState.searchText !== e.searchText ? e.searchText : ''
            });
        } else {
            setPageState({
                ...pageState,
                customerCode: pageState.customerCode,
                siteCode: pageState.siteCode,
                searchText: pageState.searchText !== e.searchText ? e.searchText : ''
            });
        }
    }

    const addList = () => {
        history.push('/location/siteplace/regist');
    }

    const onClickRow = (e) => {
    }

    const removeList = () => {
        const result = window.confirm('삭제하시겠습니까?');
        const removeCodeList = [];
        checkedList.map((id) => {
            const node = document.querySelector(`#${id}`);
            const nodeTr = node.closest('tr');
            const codeValue = nodeTr.querySelector('td[name=code]').textContent;
            removeCodeList.push(codeValue);
        });

        if(result) {
            Net.removeSitePlaceList(removeCodeList, (response) => {
                if(response) {
                    loadData();
                }
            });
        }
    }

    const getImgFile = (dataUrl) => {
        setUrl(dataUrl);
    }

    return (
        <div className="w-100 h-100">
            <div className="w-100 h-7 display_table">
                <SearchInputBar
                    className="w-100"
                    searchCategory={user.menuGroup === 'ADMIN' || user.menuGroup === 'MANAGER'}
                    searchCategoryGroup={searchCategoryGroup}
                    searchText={true}
                    searchPlaceHolder="유형을 입력해주세요."
                    searchBtn={true}
                    searchBtnTitle="검색"
                    dynamicSearch={false}
                    relateCategoryHandler={user.menuGroup === 'ADMIN' && getRelateCategory}
                    onSubmit={onSubmitHandler}
                />
                <CustomExcelDownload
                    className="downloadBtn w-90px h-30px floatR mt-2 mr-20"
                    sheetTitle="device_history_list"
                    columnList={columnList}
                    contentData4Excel={contentData4Excel}
                    pageState={pageState}
                />
                <div className="display_table_cell v-middle">
                    <button
                        className="addBtn w-90px h-30px floatR mr-20"
                        onClick={addList}
                    >등록
                    </button>
                </div>
            </div>

            <div className="w-100 h-1px bgC-efefef"/>
            <div className="w-100 h-50">
                <p className="totalCnt">Old Total {pageState.totalElementsCnt ? pageState.totalElementsCnt : 0}</p>
                <ExcelUpload
                    imgUpload={getImgFile}
                    imgFile={url}
                    list={setAddData}
                />
                {
                    programAuth.deleteAuth && checkedList.length > 0 &&
                    <button
                        className="removeBtn w-90px h-30px floatR mt-7 mr-20"
                        onClick={removeList}
                    >삭제</button>
                }
                <CustomTable
                    className={"w-100 h-30vh overFlowYscrollView"}
                    columnList={columnList}
                    contentData={contentData}
                    checkbox={programAuth.deleteAuth}
                    checkedList={(checkedList) => {
                        setCheckedList(checkedList)
                    }}
                    paging={false}
                    rowPerPage={99999}
                    currentPage={0}
                    pagination={onMovePage}
                    rowClickEvt={true}
                    onClickRow={onClickRow}
                    // sort = {true}
                />
            </div>
            <p className="totalCnt">업로드 Total {addData ? addData.length : 0}</p>
            <CustomTable
                className={"w-100 h-30vh overFlowYscrollView"}
                columnList={datacolumnList}
                contentData={addData}
                checkbox={programAuth.deleteAuth}
                checkedList={(checkedList) => {
                    setCheckedList(checkedList)
                }}
                paging={false}
                rowPerPage={99999}
                currentPage={0}
                pagination={onMovePage}
                rowClickEvt={true}
                onClickRow={onClickRow}
                // sort = {true}
            />
        </div>
    )
}
