import React, { useEffect, useState } from 'react';
import { useHistory, withRouter } from 'react-router-dom';
import { CustomTextField, CustomRadioButton, CustomSelectBox, QuillEditor, ImgUpload, CustomCheckbox, BasicModal } from 'components/molecules';
import store from 'scripts/store';
import DatePicker from "react-datepicker";
import Net from 'scripts/net';
import Common from 'scripts/common';
import {toJS} from "mobx";
import {EditorState} from "draft-js";

const ReservationRegist = ({match}) => {
    const cloneUser = toJS(store.user)

    const [form, setForm] = useState({
        itemId: '',
        name: '',
        description: '',
        customer : cloneUser.menuGroup === 'ADMIN' ? {code: '',name: '',} : cloneUser.customer,
        site: cloneUser.menuGroup === 'OPERATOR' ? cloneUser.site : { code: '', name: '',},
        groupCode: {
            code:'',
            name:'',
        },
        period: 'TIME',
        startDate: new Date(),
        endDate: new Date("9999-12-31"),
        startHour: '',
        endHour: '',
        timePeriod: '',
        image: '',
        imageDataUrl: '',
        usable: true,
        holiday: [],
        newHoliday: [],
    });

    const [registPage, setRegistPage] = useState(true);
    const [duplicateChk, setDuplicateChk] = useState(false);
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date("9999-12-31"));
    const [customerList, setCustomerList] = useState([{
        code: '',
        name: '고객사명 선택'
    }]);
    const [siteList, setSiteList] = useState([{
        code: '',
        name: '현장명 선택'
    }]);

    const [groupCodeList, setGroupCodeList] = useState([
        [
            {code: '', name: '예약그룹 선택'}
        ]
    ]);
    const [selectHourList, setSelectHourList] = useState([]);

    const [modalState, setModalState] = useState({
        open: false,
        data: [],
        dateData: {
            startDate: "",
            endDate: "",
            holidayList: [],
        }
    });

    const [checkedItems, setCheckedItems] = useState({
        holiday: []
    });
    const [checkSubmit, setCheckSubmit] = useState(false);

    const [editorState, setEditorState] = useState(EditorState.createEmpty());

    useEffect(() => {
        store.openedPage = '예약 등록/수정';

        setPageState(status => {
            if(status){
                 // 상세 조회(수정 목적으로 진입시)
                if(match.params.itemId) {

                    Net.getReservationOne(match.params.itemId, (response) => {

                        if(response.status === 200) {
                            if(!response.data.customer){
                                response.data.customer = {code: "", name: ""};
                            }
                            if(!response.data.site){
                                response.data.site = {code: "", name: ""};
                            }
                            setEditorState(Common.htmlToDraftBlocks(response.data.description));

                            //휴일 리스트 가져오기
                            Net.getHolidayList(match.params.itemId, (response2) => {

                                if(response2.status === 200) {
                                    if(!response2.data){
                                        response2.data = {id: "", itemId: "", holiday: ""};
                                    }
                                    setForm({
                                        ...form,
                                        ...response.data,
                                        holiday: response2.data,
                                        newHoliday: response2.data.slice(),
                                    });
                                }
                            });

                            // 등록페이지 여부 확인
                            setRegistPage(false);

                            setStartDate(new Date(response.data.startDate));
                            setEndDate(new Date(response.data.endDate));

                        }
                    });
                }
            }
        })
    }, []);

    useEffect(()=>{
        if(checkSubmit){
            saveLogic(form);
        }
    },[checkSubmit])

    useEffect(() => {
        if(form.customer.code !== '') {
            getSiteList(form.customer.code, (response) => {
                setSiteList(response);
            })
        } else {
            // 빈값 선택시 장비모델명 selectbox 초기화
            setSiteList([
                {
                    code: '',
                    name: '현장명 선택'
                }
            ])
        }
    }, [form.customer.code]);

    const setPageState = (status) => {
        // 고객사 전체 목록
        getCustomerList((response) => {
            setCustomerList(response);
        });
        getGroupCodeList();
        createHourList();

        status(true);
    }

    const getCustomerList = (callback) => {
        Net.getCustomerList({currentPage: '', rowPerPage: '', searchText: ''}, (response) => {
            if(response.status === 200) {
                let newArray = customerList;
                response.data.content.map(items => {
                    newArray = [
                        ...newArray,
                        {
                            code: items.code,
                            name: items.name
                        }
                    ]
                });
                callback(newArray);
            }
        });
    }

    const getSiteList = (code, callback) => {
        Net.getSitesList(
            {
                currentPage: '',
                rowPerPage: '',
                searchText: '',
                customerCode: code,
            }, (response) => {
            if(response.status === 200) {
                let newArray = [
                    {
                        code: '',
                        name: '현장명 선택'
                    }
                ];
                response.data.content.map(items => {
                    newArray = [
                        ...newArray,
                        {
                            code: items.code,
                            name: items.name
                        }
                    ]
                });
                callback(newArray);
            } else if(response.status === 204) {
                let newArray = [
                    {
                        code: '',
                        name: '현장명 선택'
                    }
                ];
                callback(newArray);
            }
        });
    }


    const getGroupCodeList = () => {
        Net.getCommunityCode(
            "RESERVATION_GROUP", (response) => {
                if(response.status === 200) {
                    let newArray = groupCodeList[0];

                    response.data.map(items => {
                        if( items.usable ){
                            newArray = [
                                ...newArray,
                                {
                                    code: items.code,
                                    name: items.name
                                }
                            ]
                        }
                    })
                    setGroupCodeList(newArray);
                }
            }
        )

    }

    const createHourList = () => {
        let hourList = [{code: "", name:"시간선택"}];
        for(let i=1; i<=24; i++) {
           hourList.push({code: i, name: i+"시"});
        }

        setSelectHourList(hourList)
    }

    const history = useHistory();

    const textFieldHandler = (name, value) => {
        if(name === 'code') setDuplicateChk(false);
        const nextForm = {
            ...form,
            [name]: value
        }
        setForm(nextForm);
    }

    const onKeyPressHandler = (e) => {
        const keyValue = e.which;
        if( ((keyValue >= 48) && (keyValue <= 57)) ) return true;
        else return e.preventDefault();
    }

    const radioBtnHandler = (name, value) => {
        let nextForm;
        if(name === "period"){
            nextForm = {
                ...form,
                [name]: value,
                timePeriod: "",
            }
        } else {
            nextForm = {
                ...form,
                [name]: value === 'true' ? true : false,
            }
        }

        setForm(nextForm);
    }

    const selectBoxHandler = (name, value) => {
        switch (name) {
            case 'customer.code':
                setForm({
                    ...form,
                    customer: {
                        code: value.code,
                        name: value.name
                    },
                    site: {
                        code: "",
                        name: ""
                    }
                });
                break;
            case 'site.code':
                setForm({
                    ...form,
                    site: {
                        code: value.code,
                        name: value.name
                    }
                });
                break;
            case 'groupCode.code':
                setForm({
                    ...form,
                    groupCode: {
                        code: value,
                    }
                });
                break;
            case 'startHour':
                setForm({
                    ...form,
                    startHour: value
                });
                break;
            case 'endHour':
                setForm({
                    ...form,
                    endHour: value
                });
                break;
            default:
                break;
        }
    }

    const onSubmitHandler = () => {

        if( !form.groupCode.code ) {
            alert('예약그룹을 선택해 주세요.');
            document.getElementsByName('groupCode.code')[0].focus();
            return false;
        }
        if( !form.name ) {
            alert('제목을 입력해 주세요.');
            document.getElementsByName('name')[0].focus();
            return false;
        }
        if( !startDate ) {
            alert('개시일자를 입력해 주세요.');
            document.getElementsByName('startDate')[0].focus();
            return false;
        }
        if( !endDate ) {
            alert('종료일자를 입력해 주세요.');
            document.getElementsByName('endDate')[0].focus();
            return false;
        }
        if( !form.startHour ) {
            alert('시작시간을 입력해 주세요.');
            document.getElementsByName('startHour')[0].focus();
            return false;
        }
        if( !form.endHour ) {
            alert('종료시간을 입력해 주세요.');
            document.getElementsByName('endHour')[0].focus();
            return false;
        }
        if( form.period === "TIME" && !form.timePeriod ) {
            alert('예약주기 시간을 입력해 주세요.');
            document.getElementsByName('timePeriod')[0].focus();
            return false;
        }
        if (cloneUser.menuGroup === 'OPERATOR') {
            setForm({
                ...form,
                customer: cloneUser.customer,
                site: cloneUser.site ,
            });
        } else if (cloneUser.menuGroup === 'MANAGER') {
            if (!form.site.code) {
                alert('현장명을 선택해 주세요.');
                document.getElementsByName('site.code')[0].focus();
                return false;
            }
                setForm({
                    ...form,
                    customer: cloneUser.customer,
                });

        } else {
            if (!form.customer.code) {
                alert('고객사를 선택해 주세요.');
                document.getElementsByName('customer.code')[0].focus();
                return false;
            }
            if (!form.site.code) {
                alert('현장명을 선택해 주세요.');
                document.getElementsByName('site.code')[0].focus();
                return false;
            }
        }
        setCheckSubmit(true);
    }

    const saveLogic = (saveform) => {
        let result = window.confirm('저장하시겠습니까?');

        if(result) {
            if(registPage) {
                Net.addReservation(saveform, (response) => {
                    if(response.status === 200) {
                        history.push('/community/reservation/list');
                    }
                });
            } else {
                Net.updateReservation(saveform, (response) => {
                    if(response.status === 200) {
                        history.push('/community/reservation/list');
                    }
                });
            }
        }
    }

    const onCancelHandler = () => {
        history.push('/community/reservation/list');
    }

    const sendData = (e) => {
        const newArray = form.newHoliday;
        newArray.push({id: "", holiday: e});

        setForm({
            ...form,
            newHoliday: newArray
        });
    }

    const getImgFile = (dataUrl) => {
        setForm({
            ...form,
            imageDataUrl: dataUrl,
        });
    }

    const liftValue = (value) => {
        setForm({
            ...form,
            description: value,
        });
    }

    const checkDate = (date, type) => {
        const gFullYear = date && date.getFullYear();
        const gMonth = date && date.getMonth();
        const gDate = date && date.getDate();
        let result = true;
        const dateFormating = Number(gFullYear + ('0' + (Number(gMonth)+1)).slice(-2) + ('0' + gDate).slice(-2));
        const formDateFormating = gFullYear + '-' + ('0' + (Number(gMonth)+1)).slice(-2) + '-' + ('0' + gDate).slice(-2);
        let holidayFormating;
        if(type === "startDate"){
            form.newHoliday.map((hDay) => {
                holidayFormating = Number(hDay.holiday.replace(/\-/g,''));
                if(dateFormating > holidayFormating){
                    alert("개시일자는 휴일 이후일 수 없습니다.");
                    result = false;
                }
            })

            if(result){
                setStartDate(date);
                setForm({
                    ...form,
                    startDate: formDateFormating
                });

            }
        } else {
            form.newHoliday.map((hDay) => {
                holidayFormating = Number(hDay.holiday.replace(/\-/g,''));
                if(dateFormating < holidayFormating){
                    alert("종료일자는 휴일 이전일 수 없습니다.");
                    result = false;
                }
            })

            if(result){
                setEndDate(date);

                setForm({
                    ...form,
                    endDate: formDateFormating
                });
            }
        }
    }

    const onRemoveHandler = () => {
        const result = window.confirm('삭제하시겠습니까?');
        if(result) {
            Net.removeReservation([{itemId: form.itemId}], (response) => {
                if(response) {
                    history.push('/community/reservation/list');
                }
            });
        }
    }

    // Checkbox onChange Event
    const handleCheckbox = (id, checked) => {
        if(checked) {
            setCheckedItems({
                ...checkedItems,
                holiday: checkedItems.holiday.concat(id)
            });
        } else {
            setCheckedItems({
                ...checkedItems,
                holiday: checkedItems.holiday.filter(items => items !== id)
            });
        }
    }

    // Open Modal and setClickedBtnName
    const onClickModalOpen = () => {
        setModalState({
            ...modalState,
            open: !modalState.open,
            dateData:{
                startDate: startDate,
                endDate: endDate,
                holidayList: form.newHoliday,
            }
        });
    }

    // remove checkedList
    const onClickRemoveList = () => {
        const newArray = form.newHoliday;
        checkedItems.holiday.map(id => {
            form.newHoliday.map((data, index) => {
                if(id.includes(data.holiday)) {
                    //삭제해야 할 대상
                    newArray.splice(index, 1);
                }
            });
        });

        setForm({
            ...form,
            newHoliday: newArray
        });
        const node = document.querySelectorAll(`input[id$=_holiday]`);
        for(let i=0; i<node.length; i++) {
            node[i].checked = false;
        }

        setCheckedItems({
            holiday: []
        });
    }

    return (
        <div className="w-100 h-100">
            <BasicModal
                title={`휴일 등록`}
                content={<ModalContent
                            sendData={sendData}
                            modalClose={(e) => setModalState({
                                                    ...modalState,
                                                    open: e
                                                })
                                        }
                            dateData={modalState.dateData}
                        />}

                modalOpen={modalState.open}
                modalClose={(e) => setModalState({
                    ...modalState,
                    open: e
                })}
            />
            <div className="w-100 h-1px bgC-efefef" />
            <div className="h-93">
                <div className="flex h-90">
                    <div className="w-50 h-100">
                        <div className="ml-55 mt-12">
                            {
                                cloneUser.menuGroup === 'ADMIN' ? (
                                    <div className="flex mb-12 w-90">
                                        <label className="label_title">고객사 *</label>
                                        <CustomSelectBox
                                            name="customer.code"
                                            categoryList={customerList}
                                            value={form.customer.code}
                                            onChangeHandler={(value, name) => selectBoxHandler('customer.code', {code: value, name: name})}
                                            callbackCodeName="Y"
                                            disabled={!registPage}
                                        />
                                        {
                                            registPage ? <div></div> :
                                                <div className="label_summary ml-10">※ 수정시 고객사 및 현장은 변경 불가능 합니다. </div>
                                        }
                                    </div>
                                ) : <></>
                            }
                            {
                                cloneUser.menuGroup === 'OPERATOR' ? <></> :(
                                    <div className="flex mb-12 w-90">
                                        <label className="label_title">현장 *</label>
                                        {
                                            form.classification==='INCIDENT'  ?
                                                <div>
                                                    <div className="label_summary ml-10">※ 구분이 `장애`이면 현장 값을 지정 할 수 없습니다.</div>
                                                </div>
                                                :
                                                <CustomSelectBox
                                                    name="site.code"
                                                    categoryList={siteList}
                                                    value={form.site.code}
                                                    onChangeHandler={(value, name) => selectBoxHandler('site.code', {code: value, name: name})}
                                                    callbackCodeName="Y"
                                                    disabled={!registPage}
                                                />
                                        }
                                    </div>
                                )
                            }
                            <div className="flex mb-12 w-90">
                                <label className="label_title">예약 그룹 *</label>
                                <CustomSelectBox
                                    name="groupCode.code"
                                    categoryList={groupCodeList}
                                    value={form.groupCode.code}
                                    onChangeHandler={(value) => selectBoxHandler('groupCode.code', value)}
                                    disabled={!registPage}
                                />
                                {
                                    registPage ? <div></div> :
                                        <div className="label_summary ml-10">※ 수정시 예약그룹은 변경 불가능 합니다. </div>
                                }
                            </div>

                            <div className="flex mb-12 w-90">
                                <CustomTextField
                                    className="w-75 h-26px"
                                    title="예약 명"
                                    name="name"
                                    required={true}
                                    disabled={false}
                                    placeholder=""
                                    customOnChange={textFieldHandler}
                                    value={form}
                                />
                            </div>

                            <div className="flex mb-10 w-90">
                                <label className="label_title">예약 내용</label>
                                <div className="w-75">
                                    <QuillEditor
                                        onChange={liftValue}
                                        value={editorState}
                                        setEditorState={setEditorState}
                                    />
                                </div>
                            </div>
                            <div className="flex w-90 mb-10">
                                <label className="label_title">예약 주기</label>
                                <CustomRadioButton
                                    className="mr-60"
                                    title="시간"
                                    id="periodT"
                                    name="period"
                                    overFlowYscrollViewHidden                    value="TIME"
                                    checked={form.period === "TIME"}
                                    customOnChange={radioBtnHandler}
                                />
                                <CustomRadioButton
                                    title="일"
                                    id="periodD"
                                    name="period"
                                    value="DAY"
                                    checked={form.period === "DAY"}
                                    customOnChange={radioBtnHandler}
                                />
                            </div>
                            <div className="flex mb-12 w-90">
                                <div className="flex w-50 h-26px">
                                    <label className="label_title">개시일자 *</label>
                                    <DatePicker
                                        dateFormat="yyyy-MM-dd"
                                        selected={startDate}
                                        maxDate={endDate}
                                        onChange={date => {
                                            checkDate(date, "startDate");
                                        }}
                                        name="startDate"
                                        className="w-77 textField"
                                    />
                                </div>
                                <div className="flex w-50 h-26px">
                                    <label className="label_title">종료일자 *</label>
                                    <DatePicker
                                        dateFormat="yyyy-MM-dd"
                                        selected={endDate}
                                        minDate={startDate}
                                        onChange={date => {
                                            checkDate(date, "endDate");
                                        }}
                                        name="endDate"
                                        className="w-77 textField"
                                    />
                                </div>
                            </div>
                            <div className="flex mb-12 w-90">
                                <div className="flex w-50 h-26px">
                                    <label className="label_title">시작시간 *</label>
                                    <CustomSelectBox
                                        name="startHour"
                                        categoryList={selectHourList}
                                        value={form.startHour}
                                        onChangeHandler={(value) => selectBoxHandler('startHour', value)}
                                        callbackCodeName="Y"
                                    />
                                </div>
                                <div className="flex w-50 h-26px">
                                    <label className="label_title">종료시간 *</label>
                                    <CustomSelectBox
                                        name="endHour"
                                        categoryList={selectHourList}
                                        value={form.endHour}
                                        onChangeHandler={(value) => selectBoxHandler('endHour', value)}
                                        callbackCodeName="Y"
                                    />
                                </div>
                            </div>
                            <div className="flex mb-12 w-90">
                                <CustomTextField
                                    className="w-17 h-26px"
                                    title="예약주기 시간"
                                    name="timePeriod"
                                    required={form.period === "TIME" ? true : false}
                                    disabled={form.period === "TIME" ? false : true}
                                    validRegExp={/[ㄱ-ㅎ|ㅏ-ㅣ|가-힣|a-zA-Z\{\}\[\]\/?.,;:|\)*~`!^\-+<>@\#$%&\\\=\(\'\"]/g}
                                    placeholder=""
                                    customOnChange={textFieldHandler}
                                    customOnKeyPress={onKeyPressHandler}
                                    value={form}
                                />

                            </div>
                            <div className="flex w-90">
                                <label className="label_title">사용유무</label>
                                <CustomRadioButton
                                    className="mr-60"
                                    title="사용"
                                    id="radioBtn1"
                                    name="usable"
                                    value={true}
                                    checked={form.usable}
                                    customOnChange={radioBtnHandler}
                                />
                                <CustomRadioButton
                                    title="사용안함"
                                    id="radioBtn2"
                                    name="usable"
                                    value={false}
                                    checked={!form.usable}
                                    customOnChange={radioBtnHandler}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="w-40 h-100">
                        <div className="w-100 h-40 mt-12">
                            <div className="floatC mr-2p" >
                                <ImgUpload
                                    title={"사진"}
                                    imgUpload={getImgFile}
                                    imgSize={"300px"}
                                    imgFile={form.imageDataUrl}
                                />
                            </div>
                        </div>
                        <div className="h-5"></div>
                        <div className="w-100 h-45 ">
                            <div className="mt-40">
                                <p className="UploadTitle" style={{marginBottom: "0"}}>휴일 설정</p>
                            </div>

                            <div className="h-95 overFlowYscrollView">
                                <div className="floatR mr-2p">
                                    <button className="addBtn w-25px h-25px" onClick={() => onClickModalOpen()}>+</button>
                                    <button className="removeBtn w-25px h-25px" onClick={() => onClickRemoveList()}>-</button>
                                </div>
                                <table className="mainTable">
                                    <colgroup>
                                        <col width="10%"/>
                                        <col width="90%"/>
                                    </colgroup>
                                    <thead className="tableHead">
                                        <tr>
                                            <th></th>
                                            <th className="v-middle"><span className="h-24px">일자</span></th>
                                        </tr>
                                    </thead>
                                    <tbody className="tableBody">
                                        {
                                            form.newHoliday.length > 0 ?
                                            (
                                                form.newHoliday.map((data, index) => {
                                                    return(
                                                        <tr key={index}>
                                                            <td onClick={ e => e.stopPropagation() }>
                                                                <CustomCheckbox
                                                                    id={`${data.holiday}_holiday`}
                                                                    className="j-c-c h-16px"
                                                                    title=""
                                                                    checked={checkedItems.holiday.includes(data.holiday)}
                                                                    onChange={handleCheckbox}
                                                                />
                                                            </td>
                                                            <td>{data.holiday}</td>
                                                        </tr>
                                                    )
                                                })
                                            )
                                            :
                                            (<tr><td colSpan="2">조회 된 데이터가 없습니다.</td></tr>)
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer_btn h-10">
                    <button className="w-160px h-30px addBtn mr-10" onClick={onSubmitHandler}>저장</button>
                    { registPage ? "" : <button className="w-160px h-30px removeBtn mr-10" onClick={onRemoveHandler}>삭제</button>}
                    <button className="w-160px h-30px cancelBtn" onClick={onCancelHandler}>취소</button>
                </div>
            </div>
        </div>
    )
}

export default withRouter(ReservationRegist);

export const ModalContent = (props) => {

    const { dateData, sendData, modalClose } = props;

    const history = useHistory();

    const [holiday, setHoliday] = useState(dateData.startDate);

    const [form, setForm] = useState({
        contents: '',
        finished: false,
    });

    const onSubmitHandler = () => {
        const newHoliday = Number(holiday.getFullYear() + ('0' + (Number(holiday.getMonth())+1)).slice(-2) + ('0' + holiday.getDate()).slice(-2));
        let oriHoliday;
        let result = true;
        dateData.holidayList.map((hDay) => {
            oriHoliday = Number(hDay.holiday.replace(/\-/g,''));

            if(newHoliday === oriHoliday){
                alert("이미 등록된 휴일 입니다.");
                result = false;
            }
        })
        if(result){
            const holidayFormating = holiday.getFullYear() + '-' + ('0' + (Number(holiday.getMonth())+1)).slice(-2) + '-' + ('0' + holiday.getDate()).slice(-2);
            sendData(holidayFormating);
            handleClose();
        }
    }
    const handleClose = () => {
        modalClose(false);
    };

    return (
        <div className="h-100">
            <div className="h-90" style={{display:"table"}}>
                <div className="flex w-100 h-26px">
                    <label className="label_title">개시일자 *</label>
                    <DatePicker
                        dateFormat="yyyy-MM-dd"
                        minDate={dateData.startDate}
                        maxDate={dateData.endDate}
                        selected={holiday}
                        onChange={date => {
                            setHoliday(date);
                        }}
                        name="holiday"
                        className="w-100 textField"
                    />
                </div>

            </div>
            <div className="footer_btn h-10 m-20">
                <div className="">
                    <button className="w-80px h-30px addBtn mr-10" onClick={onSubmitHandler}>등록</button>
                    {/*<button className="w-80px h-30px cancelBtn" onClick={handleClose}>취소</button>*/}
                </div>
            </div>
        </div>
    )
}
