import React, {useState, useEffect} from 'react'
import './ImgUpload.scss';
import Zoom from 'react-medium-image-zoom';
import 'react-medium-image-zoom/dist/styles.css';

export const ImgUpload = (props) => {
    const {title, inputName, disabled, removeHandler} = props;
    const [imgFileName, setImgFileName] = useState("");
    const [prevImgUrl, setPrevImgUrl] = useState("");

    useEffect(() => {
        setPrevImgUrl(props.imgFile);
    }, [props.imgFile])

    const onChangeImg = (e) => {
        e.preventDefault();

        let reader = new FileReader();
        let file = e.target.files[0];
        if(file.size > 10120000) {
            alert('Image 사이즈가 10MB를 넘습니다.');
            return;
        }
        reader.onloadend = () => {
            props.imgUpload(reader.result);
            setImgFileName(file.name);
            setPrevImgUrl(reader.result);
        }
        reader.readAsDataURL(file);
    }

    return (
        <>
        <span className="UploadTitle">{title} 첨부</span>
            {
                removeHandler && <>
                    <span className="floatR">
                        <button
                            className= "w-90px h-25px cancelBtn mr-2"
                            onClick={removeHandler}
                        >이미지 삭제</button>
                    </span>
                </>
            }
         <div className="imgUploadWrapper ms-1 mt-2">
            <div className="flex fileArea">
                <span className="imgUploadTitle">파일</span>
                <input className="upload-name" id="img" value={imgFileName} disabled="disabled"/>
                <label htmlFor={inputName ? inputName : "ex_filename"}>찾아보기</label>
                <input
                    type="file"
                    id={inputName ? inputName : "ex_filename"}
                    className="upload-hidden"
                    onChange={onChangeImg}
                    accept=".jpg,.jpeg,.png"
                    disabled={disabled}
                />
            </div>
            <div className="flex mt-1 ms-3 me-1 mb-2 justify-content-end">
                <span className="imgUploadNotice">※지원하는 파일 형식: <a style={{color:'green'}}>JPG, PNG </a>/ 용량: <a style={{color:'green'}}>10MB </a> 이내</span>
            </div>
            <div className="flex prevImgArea">
                <Zoom>
                    <img
                        className="imgFile"
                        src={prevImgUrl === undefined || prevImgUrl === "" || prevImgUrl === "data:image/jpeg;base64," ? "/noImage.jpg" : prevImgUrl}
                        alt="complex Img"
                        width={props.imgSize ? props.imgSize : "200"}
                    />
                </Zoom>
            </div>
        </div>
        </>
    )
}
