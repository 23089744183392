import { observable, toJS } from 'mobx';
import config from 'config/config';

const host = config.api_host;
const cloudn_host = config.cloudn_host;
const odp_host = config.odp_host;
const file_host = config.file_host

export const store = observable({

  api: host,
  cloudn_api: cloudn_host,
  odp_api: odp_host,
  file_host : file_host,
  auth: {
    token: null,
    refreshToken: null,
  },
  customer: null,
  site: null,

  user: {
    authList: [],
    customer: {code :'', name: ''},
    site: {code:'', name: ''},
    enabled : null,
    username: null,
    nickname: null,
    email: null,
    phoneNumber: null,
    status: null,
    menuGroup: null,
    roleList: [],
    temporaryPassword: false,
  },

  setUser(user) {
    this.user.authList = user.authList;
    this.user.customer = user.customer;
    this.user.site = user.site;
    this.user.enabled = user.enabled;
    this.user.username = user.username;
    this.user.nickname = user.nickname;
    this.user.email = user.email;
    this.user.phoneNumber = user.phoneNumber;
    this.user.status = user.status;
    this.user.menuGroup = user.menuGroup;
    this.user.roleList = user.roleList;
    this.user.temporaryPassword = user.temporaryPassword;
  },

  isAuthorized: false,

  openedPage: null,

  alert: msg => {
    alert(msg);
  }
});

export default store;
