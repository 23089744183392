import React, {Fragment, useEffect, useState} from 'react';
import {CustomPagination, CustomTable, SearchInputBar, BasicModal} from 'components/molecules';
import store from 'scripts/store';
import Net from 'scripts/net';
import {useHistory} from "react-router-dom";
import classNames from "classnames";
import {toast} from "react-toastify";

const columnList = [
    {
        code: 'blockId',
        title: 'blockId',
        width: '8',
        hidden: true,
    },
    {
        code: 'username',
        title: '사용자명',
        width: '8',
    },
    {
        code: 'blockedUsername',
        title: '신고글 사용자',
        width: '8',
    },
    {
        code: 'blockedDate',
        title: '차단 일시',
        width: '10',
    },
    {
        code: 'unblockedDate',
        title: '차단 해제 일시',
        width: '10',
    },
    {
        code: 'blockedYn',
        title: '차단 여부',
        width: '5',
    },
];

export const BlockList = (props) => {
    // useHistory 선언
    const [contentData, setContentData] = useState([]);
    const [checkedList, setCheckedList] = useState([]);

    const [feedDetail, setFeedDetail] = useState([]);
    const [commentData, setCommentData] = useState([]);
    const [programAuth, setProgramAuth] = useState({});
    const [clickedRowIdx, setClickedRowIdx] = useState(null);

    const [pageState, setPageState] = useState({
        currentPage: 0,
        rowPerPage: 10,
        totalElementsCnt: 0,
        drawElementsCnt: 0,
        searchText: '',
        searchText2: '',

    });

    const [loading, setLoading] = useState(false);

    useEffect(() => {
        store.openedPage = '피드 차단 목록';
        pageAuthInfo();
    }, [])

    useEffect(() => {
        setContentData([]);
        setTimeout(() => {
            loadData();
        }, 10)
    }, [pageState.currentPage,
        pageState.searchText,
        pageState.searchText2,
    ]);

    const pageAuthInfo = () => {
        const authGroupCode = props.userInfo.authList[0];
        const menuId = sessionStorage.getItem('menuId');

        Net.getAuthGroupMenuList(menuId, (response) => {
            if(response.status === 200) {
                response.data.forEach(item => {
                    if(item.authGroup === authGroupCode && item.authGroupMenu) {
                        setProgramAuth({
                            searchAuth: Boolean(item.authGroupMenu.searchAuth),
                            saveAuth: Boolean(item.authGroupMenu.saveAuth),
                            excelAuth: Boolean(item.authGroupMenu.excelAuth),
                            deleteAuth: Boolean(item.authGroupMenu.deleteAuth),
                        });
                    }
                });
            }
        });
    }

    const loadData = () => {
        setLoading(true);
        Net.getBlockList(
            {
                currentPage: pageState.currentPage,
                rowPerPage: pageState.rowPerPage,
                searchText: pageState.searchText,
                searchText2: pageState.searchText2,
            }, async (response) => {
                setLoading(false);
                if (response.status === 200) {
                    console.log(response);
                    setContentData(response.data.content);
                    setPageState({
                        ...pageState,
                        totalElementsCnt: response.data.totalElements,
                        drawElementsCnt: response.data.numberOfElements,
                    });
                } else {
                    setContentData([]);
                    setPageState({
                        ...pageState,
                        totalElementsCnt: 0,
                        drawElementsCnt: 0,
                    });
                }
            });
    }

    const onMovePage = (e) => {
        setPageState({
            ...pageState,
            currentPage: e-1,
        });
    }

    const onSubmitHandler = (e) => {
            setPageState({
                ...pageState,
                currentPage: 0,
                searchText: e.searchText,
                searchText2: e.searchText2

            });
    }

    const removeList = () => {
        const result = window.confirm('차단/차단 해제를 진행 하시겠습니까?');
        const removeList = [];
        checkedList.forEach((id) => {
            const node = document.querySelector(`#${id}`);
            const nodeTr = node.closest('tr');
            const blockId = nodeTr.querySelector('td[name=blockId]').textContent;
            const blockedYnText = nodeTr.querySelector('td[name=blockedYn]').textContent;
            const blockedYn = blockedYnText === 'true' ? true : false;
            removeList.push({
                blockId: blockId,
                blockedYn : !blockedYn
            });

        });
        if(result) {
            toast('차단 / 차단해제 되었습니다.',{
                autoClose : 1500,
                pauseOnHover: false,
                draggable : false,
                position : "top-center"
            });

            console.log(removeList);
            Net.updateBlockList(removeList, (response) => {
                if(response) {
                    loadData();
                    setClickedRowIdx(null);
                }
            });
        }
    }

    return (
        <div className="w-100 h-100">
            <div className="row col-12 h-7 ">
                <div className="col-9 mt-2">
                    <SearchInputBar
                        className="w-100 searchInput-wrapper-nodp "
                        searchText={true}
                        searchPlaceHolder={"사용자"}
                        searchText2={true}
                        searchPlaceHolder2={"신고된 사용자"}
                        searchBtn={true}
                        searchBtnTitle="검색"
                        dynamicSearch={false}
                        onSubmit={onSubmitHandler}
                        refreshBtn={true}
                        refreshHandler={loadData}
                    />
                </div>
            </div>
            <div className="w-100 h-1px bgC-efefef"/>
            <div className="flex">
                <div className="w-100 h-93 overflowYscrollView">
                    <p className="totalCnt">Total {pageState.totalElementsCnt}</p>
                    {
                        (programAuth.deleteAuth && checkedList.length > 0) ? <>
                                <button
                                    className="cancelBtn w-120px h-30px floatR mt-20 me-5"
                                    onClick={removeList}
                                > 차단/차단 해제
                                </button></>
                             : <><button
                                className="cancelBtn w-120px h-30px floatR mt-20 me-5"
                                onClick={removeList}
                                disabled={true}
                            > 차단/차단 해제
                            </button></>
                    }

                    <CustomTable
                        columnList={columnList}
                        contentData={contentData}
                        paging={true}
                        checkbox={programAuth.deleteAuth}
                        checkedList={(checkedList) => {
                            setCheckedList(checkedList)
                        }}
                        totalElementsCnt={pageState.totalElementsCnt}
                        rowPerPage={pageState.rowPerPage}
                        currentPage={pageState.currentPage}
                        drawElementsCnt={pageState.drawElementsCnt}
                        pagination={onMovePage}
                        loading={loading}
                        // sort = {true}
                    />
                </div>
            </div>
        </div>
    );
}
