import React from 'react';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import {  convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';

export const QuillEditor = (props) =>{
    const{ value, setEditorState, height, disabled, onChange} = props;

    const onEditorStateChange = (editorState) => {
        // editorState에 값 설정
        setEditorState(editorState);

        const value = draftToHtml(convertToRaw(editorState.getCurrentContent()));
        const addedWhitespaceCharacters = value.replace(/&nbsp;/gi," ");

        onChange(addedWhitespaceCharacters);
    };

    return (
        <div>
            <Editor
                // 에디터와 툴바 모두에 적용되는 클래스
                wrapperClassName="wrapper-class"
                // 에디터 주변에 적용된 클래스
                editorClassName="editor"
                // 툴바 주위에 적용된 클래스
                toolbarClassName="toolbar-class"
                // 툴바 설정
                toolbar={{
                    options: [ 'inline','textAlign', 'history', 'link'],
                    // inDropdown: 해당 항목과 관련된 항목을 드롭다운으로 나타낼것인지
                    inline: {
                        options : ['bold', 'underline']
                    },
                    list: { inDropdown: true },
                    textAlign: { inDropdown: true },
                    link: { inDropdown: true },
                    history: { inDropdown: false },
                }}
                placeholder="내용을 작성해주세요."
                // 한국어 설정
                localization={{
                    locale: 'ko',
                }}
                // 초기값 설정
                editorState={value}
                // 에디터의 값이 변경될 때마다 onEditorStateChange 호출
                onEditorStateChange={onEditorStateChange}
                handlePastedText={() => false}
                editorStyle={{ border: "1px solid #C0C0C0", "maxHeight" : height ? height : "270px" ,"minHeight":"110px" ,"overflow":"auto"}}
            />
        </div>
    );
};
