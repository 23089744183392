import React, {Fragment, useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom'
import {CustomTable, SearchInputBar} from 'components/molecules';
import store from 'scripts/store';
import Net from 'scripts/net';

const columnList = [
    {
        code: 'manufacturerCode',
        title: '제조사 코드',
        width: '15',
    },
    {
        code: 'manufacturerName',
        title: '제조사명',
        width: '15',
    },
    {
        code: 'platformYn',
        title: '플랫폼',
        width: '10',
    },
    {
        code: 'authenticationYn',
        title: '인증서버',
        width: '10',
    },
    {
        code: 'serverYn',
        title: '단지서버',
        width: '10',
    },
];

const detailColumnList =[
    {
        code: 'manufacturerCode',
        title: '고객사',
        width: '15',
    },
    {
        code: 'manufacturerName',
        title: '제조사명',
        width: '15',
    },
    {
        code: 'platformYn',
        title: '플랫폼',
        width: '10',
    },
    {
        code: 'authenticationYn',
        title: '인증서버',
        width: '10',
    },
    {
        code: 'serverYn',
        title: '단지서버',
        width: '10',
    },
];

export const HomenetHealthMonitoring = (props) => {

    // useHistory 선언
    const history = useHistory();
    const user = props.userInfo;
    const [programAuth, setProgramAuth] = useState({});
    const [contentData, setContentData] = useState([]);
    const [warningDetail, setWarningDetail] = useState([]);

    useEffect(() => {
        store.openedPage = '홈넷사별 모니터링';
        pageAuthInfo();
        loadData();
    }, [])

    const pageAuthInfo = () => {
        const authGroupCode = props.userInfo.authList[0];
        const menuId = sessionStorage.getItem('menuId');

        Net.getAuthGroupMenuList(menuId, (response) => {
            if (response.status === 200) {
                response.data.forEach(item => {
                    if (item.authGroup === authGroupCode && item.authGroupMenu) {
                        setProgramAuth({
                            searchAuth: Boolean(item.authGroupMenu.searchAuth),
                            saveAuth: Boolean(item.authGroupMenu.saveAuth),
                            excelAuth: Boolean(item.authGroupMenu.excelAuth),
                            deleteAuth: Boolean(item.authGroupMenu.deleteAuth),
                        });
                    }
                });
            }
        });
    }

    const loadData = () => {

        Net.asdf(100060, (response)=>{
            console.log(response);
        });
        //모니터링 정보 GET !

    }

    return (
        <div className="w-100 h-100">
            <div className="w-100 h-7 display_table">
                <SearchInputBar
                    role={user.menuGroup}
                    className="w-100"
                    searchCategory={false}
                    searchText={false}
                    dynamicSearch={false}
                />


            </div>
            <div className="w-100 h-1px bgC-efefef"/>
            <div className="h-93">
                <div className="w-70 h-50">
                    <h5 className="floatL mt-2p ml-2p">홈넷사 모니터링 현황</h5>
                    <button
                        className="removeBtn w-90px h-30px floatR mt-2 mb-1 mr-20"
                        onClick={loadData}
                    >수동 조회</button>
                    <h5 className="floatR mt-2p mr-5"> 갱신시각 : 1시간 단위 자동 조회 </h5>

                    <CustomTable
                        columnList={columnList}
                        contentData={contentData}
                        rowClickEvt={true}
                    />
                </div>
                <div className="w-95 h-50">
                    <div className="w-100 h-100vh">
                        <h5 className="floatL mt-1p mb-2p ml-2p">홈넷사 모니터링 현황</h5>

                        <div className="w-100 h-60vh mt-12 overFlowYscrollView">
                            <table className="mainTable ">
                                <colgroup>
                                    <col width="15%"/>
                                    <col width="15%"/>
                                    <col width="10%"/>
                                    <col width="10%"/>
                                    <col width="10%"/>
                                    <col width="10%"/>
                                    <col width="10%"/>
                                    <col width="10%"/>
                                    <col width="10%"/>
                                </colgroup>
                                <thead>
                                <tr>
                                    <th className="v-middle tableHead fixedHeader "><span className="w-12px">고객사</span></th>
                                    <th className="v-middle tableHead fixedHeader "><span className="w-12px">현장명</span></th>
                                    <th className="v-middle tableHead fixedHeader "><span className="w-12px">제조사</span></th>
                                    <th className="v-middle tableHead fixedHeader " colSpan="2"><span className="w-12px">플랫폼</span></th>
                                    <th className="v-middle tableHead fixedHeader " colSpan="2"><span className="w-12px">인증서버</span></th>
                                    <th className="v-middle tableHead fixedHeader " colSpan="2"><span className="w-12px">단지서버</span></th>

                                </tr>
                                </thead>
                                <tbody className="tableBody">
                                {
                                    contentData.length > 0 ?
                                        (
                                            <Fragment>
                                                {
                                                    contentData.map(list => {
                                                        return (
                                                            <tr key={list.commentsId}>
                                                                <td style={{whiteSpace: "initial", borderBottom: "none"}}>{list.user.userFullName}</td>
                                                                <td style={{whiteSpace: "initial", borderBottom: "none"}}>{list.contents}</td>
                                                                <td style={{whiteSpace: "initial", borderBottom: "none"}}>{list.createdDatetime}</td>
                                                            </tr>
                                                        )
                                                    })
                                                }

                                            </Fragment>
                                        ) : (
                                            <tr>
                                                <td colSpan="9">조회 된 데이터가 없습니다.</td>
                                            </tr>
                                        )
                                }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}



