import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { CustomTable, SearchInputBar } from 'components/molecules';
import store from 'scripts/store';
import Net from 'scripts/net';

const columnList = [
    {
        code: 'deviceId',
        title: '디바이스ID',
        width: '25',
    },
    {
        code: 'name',
        title: '디바이스명',
        width: '10',
    },
    {
        code: 'serialNumber',
        title: 'SerialNumber',
        width: '23',
    },
    {
        code: 'model.name',
        title: '모델명',
        width: '10',
    },
    {
        code: 'model.type.name',
        title: '장비타입',
        width: '10',
    },
    {
        code: 'model.manufacturer.name',
        title: '제조사명',
        width: '20',
    },
];

export const ToolsDeviceList = (props) => {

    // useHistory 선언
    const history = useHistory();

    const [contentData, setContentData] = useState([]);
    const [checkedList, setCheckedList] = useState([]);
    const [searchCategoryGroup, setSearchCategoryGroup] = useState([
        [
            {code: '', name: '카테고리 선택'},
            {code: 'deviceId', name: '디바이스ID'},
            {code: 'serialNumber', name: 'SerialNumber'},
            {code: 'model.modelId', name: '모델ID'},
        ]
    ]);
    const [clickDevice, setClickDevice] = useState('');
    const [loading, setLoading] = useState(false);
    const [pageState, setPageState] = useState({
        currentPage: 0,
        rowPerPage: 13,
        totalElementsCnt: 0,
        drawElementsCnt: 0,
        searchText: '',
        searchText2: '',
        searchText4: '',
        categoryCode: '',
    });

    const [programAuth, setProgramAuth] = useState({});

    useEffect(() => {
        store.openedPage = '디바이스 목록';
        pageAuthInfo();
    }, []);

    useEffect(() => {
        loadData();
    }, [pageState.currentPage,
        pageState.searchText,
        pageState.searchText2,
        pageState.searchText4,
        pageState.rowPerPage,
        pageState.categoryCode,
    ]);

    const pageAuthInfo = () => {
        const authGroupCode = props.userInfo.authList[0];
        const menuId = sessionStorage.getItem('menuId');

        Net.getAuthGroupMenuList(menuId, (response) => {
            if(response.status === 200) {
                response.data.map(item => {
                    if(item.authGroup === authGroupCode && item.authGroupMenu) {
                        setProgramAuth({
                            searchAuth: Boolean(item.authGroupMenu.searchAuth),
                            saveAuth: Boolean(item.authGroupMenu.saveAuth),
                            excelAuth: Boolean(item.authGroupMenu.excelAuth),
                            deleteAuth: Boolean(item.authGroupMenu.deleteAuth),
                        });
                    }
                });
            }
        });
    }

    const loadData = () => {
        setLoading(true);
        Net.getToolsDeviceList(pageState, (response) => {
            setLoading(false);
            if(response.status === 200) {
                setContentData(response.data.content);
                setPageState({
                    ...pageState,
                    totalElementsCnt: response.data.totalElements,
                    drawElementsCnt: response.data.numberOfElements,
                });
            } else if(response.status === 204) {
                setContentData([]);
                setPageState({
                    ...pageState,
                    totalElementsCnt: 0,
                    drawElementsCnt: 0,
                });
            }
        });
    }

    const onMovePage = (e) => {
        setPageState({
            ...pageState,
            currentPage: e-1
        });
    }

    const onSubmitHandler = (e) => {
        if(e.searchText !== ''){
            if(e.selectedCategory[0] === ''){
                alert('카테고리를 선택해 주세요.');
                return false;
            }
        }
        if(e.searchText3 === '' ){
            e.searchText3 = 13;
        }


        setPageState({
            ...pageState,
            currentPage: 0,
            searchText: e.searchText,
            searchText2: e.searchText2,
            searchText4 : e.searchText4,
            rowPerPage: e.searchText3,
            categoryCode: e.selectedCategory[0],
        });
    }

    const addList = () => {
        history.push('/tools/device/regist');
    }

    const onClickRow = (e) => {
        setClickDevice(e.deviceId);
    }

    const goModifypage = () => {
        history.push(`/tools/device/regist/${clickDevice}`);
    }

    const removeList = () => {
        const result = window.confirm('삭제하시겠습니까?');
        const removeCodeList = [];
        checkedList.map((id) => {
            const node = document.querySelector(`#${id}`);
            const nodeTr = node.closest('tr');
            const codeValue = nodeTr.querySelector('td[name=deviceId]').textContent;
            removeCodeList.push(codeValue);
        });

        if(result) {
            Net.removeToolsDeviceList(removeCodeList, (response) => {
                if(response) {
                    loadData();
                }
            });
        }
    }
    return (
        <div className="w-100 h-100">
            <div className="w-100 h-7 display_table">
                <SearchInputBar
                    className="w-100"
                    searchCategory={true}
                    searchCategoryGroup={searchCategoryGroup}
                    searchText={true}
                    searchText2={true}
                    searchText3={true}
                    smSearchText3={true}
                    searchText4={true}
                    searchPlaceHolder=""
                    searchPlaceHolder2="Updater"
                    searchPlaceHolder3="페이지당 개수(13)"
                    searchPlaceHolder4="디바이스 명"
                    searchBtn={true}
                    searchBtnTitle="검색"
                    dynamicSearch={false}
                    // onSubmit={onChangeSelectBox}
                    onSubmit={onSubmitHandler}
                    refreshBtn={true}
                    refreshHandler={loadData}
                />
                {
                    programAuth.saveAuth &&
                    <div className="display_table_cell v-middle">
                        <button
                            className="addBtn w-90px h-30px floatR mr-20"
                            onClick={addList}
                        >등록</button>
                    </div>
                }
            </div>
            <div className="w-100 h-1px bgC-efefef" />
            <div className="flex">
                <div className="w-100 h-93 overflowYscroll">
                    <p className="totalCnt">Total {pageState.totalElementsCnt}</p>
                    {
                        clickDevice !== '' ?
                            (
                                <button
                                    className="defaultBtn bgC-eb878c borderC-eb878c w-90px h-30px floatR mr-20 mt-7 mb-auto ml-auto"
                                    onClick={goModifypage}
                                >수정</button>
                            ) : ""
                    }
                    {
                        programAuth.deleteAuth && checkedList.length > 0 &&
                        <button
                            className="removeBtn w-90px h-30px floatR mt-7 mr-20"
                            onClick={removeList}
                        >삭제</button>
                    }
                    <CustomTable
                        columnList = {columnList}
                        contentData = {contentData}
                        checkbox = {programAuth.deleteAuth}
                        checkedList = {(checkedList) => {setCheckedList(checkedList)} }
                        paging = {true}
                        totalElementsCnt = {pageState.totalElementsCnt}
                        rowPerPage = {pageState.rowPerPage}
                        currentPage = {pageState.currentPage}
                        drawElementsCnt = {pageState.drawElementsCnt}
                        pagination = {onMovePage}
                        rowClickEvt = {true}
                        onClickRow = {onClickRow}
                        className={"w-100 h-60vh table-wrapper"}
                        loading={loading}
                        // sort = {true}
                    />
                </div>
            </div>
        </div>
    )
}
