import React, { Fragment, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom'
import { CustomTable, SearchInputBar, BasicModal, ReportModal, CustomExcelDownload } from 'components/molecules';
import store from 'scripts/store';
import Net from 'scripts/net';
import {toast} from "react-toastify";
import {useDidMountEffect } from "scripts/hooks";
import './VoteList.scss';

import {
    AgreeModalContent,
    AutomationModalContent,
    DeviceModalContent,
    NotiModalContent
} from "../../MemberManagePage/UserManagePage/UserList";

const columnList = [
    {
        code: 'voteId',
        title: 'voteId',
        width: '0',
        hidden: true,
    },
    {
        code: 'classificationKOR',
        title: '구분',
        width: '10',
    },
    {
        code: 'title',
        title: '제목',
        width: '20',
    },
    {
        code: 'dong',
        title: '장소(동)',
        width: '10',
    },
    {
        code: 'site.name',
        title: '현장',
        width: '15',
    },
    {
        code: 'customer.name',
        title: '고객사',
        width: '15',
    },
    {
        code: 'startDate',
        title: '개시일자',
        width: '15',
    },
    {
        code: 'voteStartDate',
        title: '시작일자',
        width: '10',
    },
    {
        code: 'voteEndDate',
        title: '종료일자',
        width: '10',
    },
    {
        code: 'statusKOR',
        title: '상태',
        width: '10',
    },
    {
        code: '',
        title: '주민투표결과',
        width: '10',
        tdClickEvent: true,
    },
    {
        code: '',
        title: '주민투표인쇄',
        width: '10',
        tdClickEvent: true,
    },
];

export const VoteList = (props) => {
    // useHistory 선언
    const history = useHistory();
    const user = props.userInfo;
    const [programAuth, setProgramAuth] = useState({});
    const [contentData, setContentData] = useState([]);
    const [checkedList, setCheckedList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [searchCategory, setSearchCategory] = useState([
        [
            [
                {code: '', name: '고객사 선택'}
            ]
        ],
        [
            [
                {code: '', name: '현장명 선택'}
            ]
        ],
        [

                {code: '', name: '진행상태 선택'},
                {code: 'READY', name: '준비'},
                {code: 'PROCEEDING', name: '진행중'},
                {code: 'END', name: '종료'},

        ],
    ]);

    const [pageState, setPageState] = useState({
        currentPage: 0,
        rowPerPage: 13,
        totalElementsCnt: 0,
        drawElementsCnt: 0,
        searchText: '',
        searchCategory1: user.menuGroup === 'ADMIN' ? '' : user.customer && user.customer.code,
        searchCategory2: user.menuGroup === 'OPERATOR' ? user.site && user.site.code: '',
        searchCategory3: '',
    });

    const [modalState, setModalState] = useState({
        open: false,
        data: [],
        voteId: "",
        clickedBtnName: '',
    });

    const [reportModalState, setReportModalState] = useState({
        open: false,
        data: [],
        voteId: "",
        clickedBtnName: '',
    });

    useEffect(() => {
        store.openedPage = '주민투표관리';
        getCategoryList('');
        pageAuthInfo();

    }, [])

    useEffect(() => {
        loadData();
    }, [pageState.currentPage,
        pageState.searchText,
        pageState.searchCategory1,
        pageState.searchCategory2,
        pageState.searchCategory3,
    ]);

    const pageAuthInfo = () => {
        const authGroupCode = props.userInfo.authList[0];
        const menuId = sessionStorage.getItem('menuId');

        Net.getAuthGroupMenuList(menuId, (response) => {
            if(response.status === 200) {
                response.data.forEach(item => {
                    if(item.authGroup === authGroupCode && item.authGroupMenu) {
                        setProgramAuth({
                            searchAuth: Boolean(item.authGroupMenu.searchAuth),
                            saveAuth: Boolean(item.authGroupMenu.saveAuth),
                            excelAuth: Boolean(item.authGroupMenu.excelAuth),
                            deleteAuth: Boolean(item.authGroupMenu.deleteAuth),
                        });
                    }
                });
            }
        });
    }

    const loadData = () => {
        setLoading(true);
        Net.getVoteList(
            {
                currentPage: pageState.currentPage,
                rowPerPage: pageState.rowPerPage,
                searchText: pageState.searchText,
                searchCategory1: pageState.searchCategory1,
                searchCategory2: pageState.searchCategory2,
                searchCategory3: pageState.searchCategory3
            }, (response) => {
                setLoading(false);
                if (response.status === 200) {
                    response.data.content.forEach((item) => {
                        if (item.status === "PROCEEDING") {
                            item.statusKOR = "진행중";
                        } else if (item.status === "END") {
                            item.statusKOR = "종료";
                        } else if (item.status === "READY") {
                            item.statusKOR = "준비";
                        }
                        if (item.dong === "ALL") {
                            item.dong = "";
                        }

                        if (item.classification === "election") {
                            item.classificationKOR = "경선"
                        } else {
                            item.classificationKOR = "안건"
                        }

                    })
                    console.log(response.data.content);
                    setContentData(response.data.content);
                    setPageState({
                        ...pageState,
                        totalElementsCnt: response.data.totalElements,
                        drawElementsCnt: response.data.numberOfElements,
                    });

                } else {
                    setContentData([]);
                    setPageState({
                        ...pageState,
                        totalElementsCnt: 0,
                        drawElementsCnt: 0,
                    });
                }
            });
    }

    const getCategoryList = () => {
        const response3 = searchCategory[2];
        if (user.menuGroup === 'ADMIN') {
            getCustomerList((response1) => {
                getSitesList(null, (response2) => {
                    setSearchCategory([response1, response2, response3]);
                });
            });
        } else if (user.menuGroup === 'MANAGER') {
            getSitesList(pageState.searchCategory1, (response2) => {
                setSearchCategory([response2, response3]);
            });
        } else {
            setSearchCategory([response3]);
        }
    }

    const getRelateCategory = (selectedCategory) => {
        if(selectedCategory === "reset") selectedCategory = "";
        getSitesList(selectedCategory, (response2) => {
            setSearchCategory([
                searchCategory[0],
                response2,
                searchCategory[2]
            ]);
        });
    }

    const getCustomerList = (callback) => {
        Net.getCustomerList(
            {currentPage: '', rowPerPage: '', searchText: ''}, (response) => {
            if(response.status === 200) {
                let newArray = searchCategory[0][0];
                response.data.content.forEach(items => {
                    newArray = [
                        ...newArray,
                        {
                            code: items.code,
                            name: items.name
                        }
                    ]
                });
                callback(newArray);
            }
        });
    }

    const getSitesList = (selectedCategory, callback) => {
        if(selectedCategory){
            Net.getSitesList(
                {currentPage: '', rowPerPage: '', searchText: '', customerCode: selectedCategory?selectedCategory:false}, (response) => {
                 if(response.status === 200) {
                     let newArray =  [{code:'',name:"현장명 선택"}];
                     if(response.data.content.length > 0) {
                         response.data.content.forEach(items => {
                             newArray = [
                                 ...newArray,
                                 {
                                     code: items.code,
                                     name: items.name
                                 }
                             ]

                         })
                     }
                    callback(newArray);
                 } else {
                     let newArray = [{code:'',name:"현장명 선택"}];
                     callback(newArray);
                 }
            });
        } else {
            let newArray = [{code:'',name:"현장명 선택"}];
            callback(newArray);
        }
    }

    const onClickRow = (e) => {
        history.push(`/community/vote/regist/${e.voteId}`);
    }

    const tdOnClickEvent = (title, e) => {
        console.log(title);

        if(title === "주민투표인쇄"){
            onClickReportModalOpen(title,e);
        }else{
            onClickModalOpen(title,e);

        }
    }

    const onMovePage = (e) => {
        setPageState({
            ...pageState,
            currentPage: e-1
        });
    }

    const removeList = () => {
        const result = window.confirm('삭제하시겠습니까?');
        const removeList = [];
        checkedList.forEach((id) => {
            const node = document.querySelector(`#${id}`);
            const nodeTr = node.closest('tr');
            const voteId = nodeTr.querySelector('td[name=voteId]').textContent;
            removeList.push({voteId: voteId});
        });
        if(result) {
            Net.removeVote(removeList, (response) => {
                if(response) {
                    toast('삭제 되었습니다.',{
                        autoClose : 1500,
                        pauseOnHover: false,
                        draggable : false,
                        position : "top-center"
                    });
                    loadData();
                }
            });
        }
    }

    const onSubmitHandler = (e) => {

        if (user.menuGroup === 'ADMIN') {
            setPageState({
                ...pageState,
                currentPage: 0,
                searchCategory1: e.selectedCategory[0],
                searchCategory2: e.selectedCategory[1],
                searchCategory3: e.selectedCategory[2],
                searchText: e.searchText
            });

        } else if (user.menuGroup === 'MANAGER') {
            setPageState({
                ...pageState,
                currentPage: 0,
                searchCategory1: pageState.searchCategory1,
                searchCategory2: e.selectedCategory[0],
                searchCategory3: e.selectedCategory[1],
                searchText: e.searchText
            });
        } else {
            setPageState({
                ...pageState,
                currentPage: 0,
                searchCategory1: pageState.searchCategory1,
                searchCategory2: pageState.searchCategory2,
                searchCategory3: e.selectedCategory[0],
                searchText: e.searchText
            });
        }
    }

    const addList = () => {
        history.push('/community/vote/regist');
    }

    // Open Modal and setClickedBtnName
    const onClickModalOpen = (type, e) => {
        setModalState({
            ...modalState,
            open: !modalState.open,
            voteId: e.voteId,
            clickedBtnName: type
        });
    }

    const onClickReportModalOpen = (type, e)=>{
        setReportModalState({
            ...modalState,
            open: !modalState.open,
            voteId: e.voteId,
            clickedBtnName: type
        });
    }

    const sendData = (e) => {
        setModalState({
            ...modalState,
            data: e,
        })
    }

    return (
        <div className="w-100 h-100">
            <BasicModal
                className="w-70 h-80"
                title={`주민투표 현황`}
                content={<ModalContent
                            sendData={sendData}
                            modalType={modalState.clickedBtnName}
                            voteId={modalState.voteId}
                        />}
                modalOpen={modalState.open}
                modalClose={(e) => setModalState({
                    ...modalState,
                    open: e
                })}
                sendData={modalState.data}
                //rcvData={rcvData}
            />
            <ReportModal
                className="w-100"
                title={`주민투표 현황`}
                content={<ModalContent
                    modalType={reportModalState.clickedBtnName}
                    voteId={reportModalState.voteId}
                />}
                modalOpen={reportModalState.open}
                modalClose={(e) => setReportModalState({
                    ...reportModalState,
                    open: e
                })}
            />

            <div className="w-100 h-7 display_table">
                <SearchInputBar
                    className="w-100"
                    searchCategory={true}
                    searchCategoryGroup={searchCategory}
                    searchText={true}
                    searchPlaceHolder="제목을 입력하세요"
                    searchBtn={true}
                    searchBtnTitle="검색"
                    dynamicSearch={false}
                    relateCategoryHandler={user.menuGroup === 'ADMIN' && getRelateCategory}
                    onSubmit={onSubmitHandler}
                    refreshBtn={true}
                    refreshHandler={loadData}
                />
                {
                    programAuth.saveAuth &&
                    <div className="display_table_cell v-middle">
                        <button
                            className="addBtn w-90px h-30px floatR mr-20"
                            onClick={addList}
                        >등록</button>
                    </div>
                }
            </div>
            <div className="w-100 h-1px bgC-efefef" />
            <div className="flex h-93">
                <div className="w-100 h-100">
                    <p className="totalCnt">Total {pageState.totalElementsCnt}</p>
                    {
                        programAuth.deleteAuth && checkedList.length > 0 &&
                        <button
                            className="removeBtn w-90px h-30px floatR mt-7 mr-15"
                            onClick={removeList}
                        >삭제</button>
                    }
                    <CustomTable
                        columnList = {columnList}
                        contentData = {contentData}
                        paging = {true}
                        checkbox = {programAuth.deleteAuth}
                        checkedList = {(checkedList) => {setCheckedList(checkedList)} }
                        totalElementsCnt = {pageState.totalElementsCnt}
                        rowPerPage = {pageState.rowPerPage}
                        currentPage = {pageState.currentPage}
                        drawElementsCnt = {pageState.drawElementsCnt}
                        pagination = {onMovePage}
                        rowClickEvt = {true}
                        onClickRow = {onClickRow}
                        tdClickEvt = {tdOnClickEvent}
                        loading={loading}
                    />
                </div>
            </div>
        </div>
    );
}

export const ModalContent = (props) => {
    switch (props.modalType) {
        case "주민투표결과":
            return <VoteResultContent data={props}/>
            break;
        case "주민투표인쇄":
            return <VoteReportContent data={props}/>
            break;
        default:
            break;
    }

}

export const VoteResultContent = (props) => {

    const countColumnList = [
        {
            code: 'user.userFullName',
            title: '사용자(이름)',
            width: '25',
        },
        {
            code: 'place.name',
            title: '장소',
            width: '25',
        },
        {
            code: 'createdDatetime',
            title: '투표 일시',
            width: '25',
        },
        {
            code: 'voteAgenda.title',
            title: '선택',
            width: '25',
        },
    ];
    const [contentData, setContentData] = useState([]);
    const [countContentData, setCountContentData] = useState([]);

    useEffect(() => {
        loadData();
    }, []);

    const loadData = () => {
        Net.getVoteResult(
            props.data.voteId, (response) => {
            if(response.status === 200) {
                setContentData(response.data);

            } else {
                setContentData([]);
            }

       });

        Net.getVoteCountList(
            props.data.voteId, (response) => {
                if(response.status === 200) {
                    setCountContentData(response.data);
                } else {
                    setCountContentData([]);
                }
        });
    }
    return (
        <div className="print-section h-100">
            <div className="w-100 h-50">
                <table className="mainTable">
                    <colgroup>
                        <col width="30%"/>
                        <col width="50%"/>
                        <col width="20%"/>
                    </colgroup>
                    <thead className="tableHead">

                        <tr>
                            <th className="v-middle"><span className="w-24px">제목</span></th>
                            <th className="v-middle"><span className="w-48px">내용</span></th>
                            <th className="v-middle"><span className="w-24px">득표수</span></th>
                        </tr>
                    </thead>
                    <tbody className="tableBody">
                        {
                            contentData.voteAgenda ?
                            (
                                <Fragment>
                                    {
                                        contentData.voteAgenda.map(list => {
                                            return (
                                                <tr key={list.agendaId}>
                                                    <td style={{display: 'none'}} name="agendaId">{list.agendaId}</td>
                                                    <td style={{overflow:"unset", whiteSpace:"initial", borderBottom: "none"}}>{list.title}</td>
                                                    <td style={{overflow:"unset", whiteSpace:"initial", borderBottom: "none"}}><div dangerouslySetInnerHTML={{__html: list.contents}}/></td>
                                                    <td style={{overflow:"unset", whiteSpace:"initial", borderBottom: "none"}}>{list.resultCount}</td>
                                                </tr>
                                            )
                                        })
                                    }

                                </Fragment>
                            ) : (
                                <tr><td colSpan="3">조회 된 데이터가 없습니다.</td></tr>
                            )
                        }
                        <tr>
                            <th colSpan="2"  className="tableHead">총참여수</th>
                            <th>{contentData.totalCount}</th>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div className="w-100 mt-15">
                <CustomExcelDownload
                    className="downloadBtn w-90px h-30px floatR mr-20"
                    sheetTitle="device_history_list"
                    columnList={countColumnList}
                    contentData4Excel={countContentData}
                />
            </div>
            <div className="w-100 mt-5 overFlowYscrollView  ">
                <CustomTable
                    className="none"
                    columnList = {countColumnList}
                    contentData = {countContentData}
                    checkbox = {false}
                />
            </div>
        </div>
    )
}


export const VoteReportContent = (props) => {
    const [contentData, setContentData] = useState({});
    const [voteAgendaData, setVoteAgendaData] = useState([]);
    const [voteData, setVoteData] = useState({
        site : {
            name : "코오롱 하늘채"
        },
        voteStartDate : "2023-01-01",
        voteEndDate : "2023-12-31",
        title : "주민투표",
        contents : "주민투표내용",
    });
    const [siteNumberOfHousehold, setSiteNumberOfHousehold] = useState(0);
    const [today, setToday] = useState('2023.01.01')
    const [maxNum, setMaxNum] = useState(0);
    const [maxName, setMaxName] = useState("알수없음");
    const [boolTie, setBoolTie] = useState(false);



    useEffect(() => {
        const today = new Date();
        const year = today.getFullYear();
        const month = (today.getMonth() + 1).toString().padStart(2, "0");
        const date = today.getDate().toString().padStart(2, "0");

        const formattedDate = `${year}.${month}.${date}`;
        setToday(formattedDate);

        loadData();
    }, []);

    useEffect(()=>{

    },[siteNumberOfHousehold]);

    useEffect(()=>{
        let maxNum = 0;
        let maxItem = {};

        voteAgendaData.forEach((item)=>{
            if(item.resultCount > maxNum){
                maxNum = item.resultCount;
                maxItem = item;
            }
        });

        setMaxNum(maxNum);
        setMaxName(maxItem.title);
    },[voteAgendaData]);

    useEffect(()=>{
        let countNum = 0;
        let multiName = "";
        voteAgendaData.forEach((item)=>{
            if(item.resultCount === maxNum) {
                multiName += (item.title+", ")
                countNum++;
            }
        });

        if(countNum > 1) {
            setBoolTie(true);
            setMaxName(multiName);
        }
    },[maxNum])


    const loadData = () => {
        Net.getVoteOneNoImage(
            props.data.voteId, (response1) => {
                setVoteData(response1.data);
                Net.getSiteOne(response1.data.site.code, (response2)=>{
                   setSiteNumberOfHousehold(response2.data.numberOfHouseHold)
                });

            }
        )
        Net.getVoteResult(
            props.data.voteId, (response) => {
                if(response.status === 200) {
                    setContentData(response.data);
                    setVoteAgendaData(response.data.voteAgenda.sort((a,b)=> a.voteNum - b.voteNum));
                } else {
                    setContentData([]);
                }
            });
    };


    return (
        <div className="print-section h-100 overflowYscroll">
            <div className=""
                style={{margin : "50px 40px 0 40px"}}
            >
                <table className="reportTable">
                    <thead className="tableHead"
                        style={{margin : "5px", height : "15px"}}
                    >
                    <tr>
                        <th className="v-middle"
                            style={{background : "yellow", width : "8%", height : "40px" , fontSize : "20px", fontWeight : "800"}}
                        >1</th>
                        <th className="v-middle"
                            style={{background : "white", textAlign : "left", paddingLeft : "10px",
                            fontSize : "20px", fontWeight : "800"}}
                        >전자투표 실시결과 보고서</th>
                    </tr>
                    </thead>
                </table>



                <div className="mt-4 mb-3 ml-2">
                    <span className=""
                    style={{textAlign : "left", paddingLeft : "10px",
                    fontSize : "20px", fontWeight : "800"}}
                    >
                        □ 전자 투표 개요
                    </span>
                </div>


                <div>
                    <span  style={{textAlign : "left", paddingLeft : "20px",
                        fontSize : "18px", fontWeight : "500", marginBottom : "1rem", display : "block"}}
                    >
                        ○  현 장  : {voteData.site.name} <br/> </span>
                    <span  style={{textAlign : "left", paddingLeft : "20px",
                        fontSize : "18px", fontWeight : "500", marginBottom : "1rem" , display : "block"}}
                    >
                        ○ 일 시 : {voteData.voteStartDate} ~ {voteData.voteEndDate}<br/> </span>
                    <span  style={{textAlign : "left", paddingLeft : "20px",
                        fontSize : "18px", fontWeight : "500", marginBottom : "1rem" , display : "block"}}
                    >
                        ○ 제 목  : {voteData.title} <br/></span>
                    <span  style={{textAlign : "left", paddingLeft : "20px",
                        fontSize : "18px", fontWeight : "500", marginBottom : "1rem" , display : "block"}}
                    >
                        ○ 내 용  : {voteData.contents.replace(/<[^>]+>/g, "")} <br/></span>
                    <span  style={{textAlign : "left", paddingLeft : "20px",
                        fontSize : "18px", fontWeight : "500", marginBottom : "1rem" , display : "block"}}
                    >
                        ○ 목 록  <br/></span>
                </div>



                <table className="mainTable">
                    <colgroup>
                        <col width="10%"/>
                        <col width="40%"/>
                        <col width="40%"/>
                        <col width="10%"/>
                    </colgroup>
                    <thead className="tableHead">

                    <tr>
                        <th className="v-middle"><span className="w-24px">No</span></th>
                        <th className="v-middle"><span className="w-24px">제목</span></th>
                        <th className="v-middle"><span className="w-48px">내용</span></th>
                        <th className="v-middle"><span className="w-24px">비고</span></th>
                    </tr>
                    </thead>
                    <tbody className="tableBody">
                    {
                        voteAgendaData.length > 0  ?
                            (
                                <Fragment>
                                    {
                                        voteAgendaData.map((list,index) => {
                                            return (
                                                <tr key={list.agendaId}>
                                                    <td style={{display: 'none'}} name="agendaId">{list.agendaId}</td>
                                                    <td style={{overflow:"unset", whiteSpace:"initial", borderBottom: "none", verticalAlign : "middle"}}>{index+1}</td>
                                                    <td style={{overflow:"unset", whiteSpace:"initial", borderBottom: "none", verticalAlign : "middle"}}>{list.title}</td>
                                                    <td style={{overflow:"unset", whiteSpace:"initial", borderBottom: "none"}}><div dangerouslySetInnerHTML={{__html: list.contents}}/></td>
                                                    <td style={{overflow:"unset", whiteSpace:"initial", borderBottom: "none"}}>{}</td>
                                                </tr>
                                            )
                                        })
                                    }

                                </Fragment>
                            ) : (
                                <tr><td colSpan="4">해당 안건의 데이터가 없습니다.</td></tr>
                            )
                    }
                    </tbody>
                </table>

                <div className="mt-4 mb-2 ml-2">
                    <span className=""
                          style={{textAlign : "left", paddingLeft : "10px",
                              fontSize : "20px", fontWeight : "800"}}
                    >
                        □ 전자 투표 결과
                    </span>
                </div>

                <div  className="mt-2 mb-2 ml-2">
                    <span  style={{textAlign : "left", paddingLeft : "20px",
                        fontSize : "12px", fontWeight : "600"}}
                    >
                        {
                            boolTie ? <>
                                ○ 총 참여율 {contentData.totalCount}/{siteNumberOfHousehold}세대({Number(contentData.totalCount/siteNumberOfHousehold*100).toFixed(1)}%) , 총 득표율 {maxNum}/{contentData.totalCount}세대
                                <br/>
                                ○ {maxName} 동률 득표로 앞으로 진행과정에 대해서는 추후 안내드리겠습니다.
                            </> : <>
                                ○ 총 참여율 {contentData.totalCount}/{siteNumberOfHousehold}세대({Number(contentData.totalCount/siteNumberOfHousehold*100).toFixed}%) , 총 득표율 {maxNum}/{contentData.totalCount}세대로,
                                <span style={{textDecoration : "underline"}}>{maxName}</span> 선정 <br/>
                            </>
                        }

                    </span>
                </div>

                <table className="mainTable">
                    <colgroup>
                        <col width="10%"/>
                        <col width="30%"/>
                        <col width="20%"/>
                        <col width="20%"/>
                        <col width="20%"/>
                    </colgroup>
                    <thead className="tableHead">

                    <tr>
                        <th className="v-middle"><span className="w-24px">No</span></th>
                        <th className="v-middle"><span className="w-24px">제목</span></th>
                        <th className="v-middle"><span className="w-24px">득표수</span></th>
                        <th className="v-middle"><span className="w-24px">참여 득표율</span></th>
                        <th className="v-middle"><span className="w-24px">전체 득표율</span></th>
                    </tr>
                    </thead>
                    <tbody className="tableBody">
                    {
                        voteAgendaData.length > 0  ?
                            (
                                <Fragment>
                                    {
                                        voteAgendaData.map((list,index) => {
                                            return (
                                                <tr key={list.agendaId}>
                                                    <td style={{display: 'none'}} name="agendaId">{list.agendaId}</td>
                                                    <td style={{overflow:"unset", whiteSpace:"initial", borderBottom: "none"}}>{index+1}</td>
                                                    <td style={{overflow:"unset", whiteSpace:"initial", borderBottom: "none"}}>{list.title}</td>
                                                    <td style={{overflow:"unset", whiteSpace:"initial", borderBottom: "none"}}>{list.resultCount}</td>
                                                    <td style={{overflow:"unset", whiteSpace:"initial", borderBottom: "none"}}>{(Number(list.resultCount/contentData.totalCount)*100).toFixed(2)}%</td>
                                                    <td style={{overflow:"unset", whiteSpace:"initial", borderBottom: "none"}}>{(Number(list.resultCount/siteNumberOfHousehold)*100).toFixed(2)}%</td>

                                                </tr>
                                            )
                                        })
                                    }

                                </Fragment>
                            ) : (
                                <tr><td colSpan="3">조회 된 데이터가 없습니다.</td></tr>
                            )
                    }
                    <tr>
                        <th colSpan="2"  className="tableHead">총참여수</th>
                        <th>{contentData.totalCount}</th>
                        <th>100%</th>
                        <th>{Number(contentData.totalCount/siteNumberOfHousehold)*100}%</th>
                    </tr>
                    </tbody>
                </table>
                <div  className="mt-2 mb-2 ml-2">
                    <span  style={{textAlign : "left", paddingLeft : "20px",
                        fontSize : "12px", fontWeight : "500"}}
                    >
                        * 참여 득표율(%) = (득표 세대수/투표 세대수) x 100<br/>
                    </span>
                    <span  style={{textAlign : "left", paddingLeft : "20px",
                        fontSize : "12px", fontWeight : "500"}}
                    >
                       * 전체 득표율(%) = (득표 세대수/전체 세대수) x 100<br/>

                    </span>
                </div>


            </div>

            <div className="w-100 mt-5 mb-5 text-align-center"
            >
                <div className="mb-3">
                    <span  style={{fontSize : "14px", fontWeight : "500"}}
                    >
                        {today}<br/>
                    </span>
                </div>
                <span  style={{fontSize : "14px", fontWeight : "500"}}
                >
                        {voteData.site.name} 관리사무소<br/>
                </span>
            </div>
        </div>
    )
}
