import React, { useEffect, useState } from 'react';
import { useHistory, withRouter } from 'react-router-dom';
import { CustomTextField, CustomRadioButton,CustomMultiSelectBox, CustomSelectBox, ImgUpload } from 'components/molecules';
import store from 'scripts/store';
import DatePicker from "react-datepicker";
import Net from 'scripts/net';
import Common from 'scripts/common';
import TextareaAutosize from "react-textarea-autosize";

const O2ORegist = ({match}) => {
    const [form, setForm] = useState({
        o2oId: '',
        o2oServiceName: '',
        o2oServiceOverview: '',
        companyName: '',
        webUrl: '',
        iosUrl: '',
        aosUrl: '',
        customer: {
            code:'',
            name:'',
        },
        site: [{
            code:'',
            name:'',
        }],
        startDate: '',
        endDate: '',
        image: '',
        imageDataUrl: '',
        thumbnailImage: '',
        thumbnailImageDataUrl: '',
        sort: '',
        pushNotiDateTime : '',
        popupImage : '',
        popupImageDataUrl : '',
        popupStartDate : '',
        popupEndDate : '',
        popupSort : ''
    });

    const [registPage, setRegistPage] = useState(true);
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [popupStartDate, setPopupStartDate] = useState('');
    const [popupEndDate, setPopupEndDate] = useState('');

    const [pushNotiDate, setPushNotiDate] = useState('');
    const [pushNotiTime, setPushNotiTime] = useState('');

    const [updateSite, setUpdateSite ] = useState([{ code: '', name: ''}]);

    const [customerList, setCustomerList] = useState([{
        code: '',
        name: '고객사명 선택'
    }]);
    const [siteList, setSiteList] = useState([{
        code: '',
        name: '고객사를 선택해주세요.'
    }]);

    useEffect(() => {
        store.openedPage = 'O2O 제휴서비스 등록/수정';

        // 상세 조회(수정 목적으로 진입시)
        if(match.params.o2oId !== undefined) {

            Net.getO2OOne(match.params.o2oId, (response) => {

                if(response.status === 200) {
                    if(!response.customer){
                        response.customer = {code: "", name: ""};
                    }
                    if(!response.site){
                        response.site = [{code: "", name: ""}];
                    }

                    setForm({
                        ...form,
                        ...response,
                    });
                    // 등록페이지 여부 확인
                    setRegistPage(false);

                    setStartDate(new Date(response.startDate));
                    setEndDate(new Date(response.endDate));

                    if(response.popupStartDate){
                        setPopupStartDate(new Date(response.popupStartDate));
                    }
                    if(response.popupEndDate){
                        setPopupEndDate(new Date(response.popupEndDate));
                    }
                    if(response.pushNotiDateTime){
                        const tempPushNotiDateTime = response.pushNotiDateTime.split(" ");
                        setPushNotiDate(new Date(tempPushNotiDateTime[0]))

                        const tempHourMinute = tempPushNotiDateTime[1].split(":");
                        setPushNotiTime(tempHourMinute[0]+':'+tempHourMinute[1]);
                    }

                }
            });
        }

        // 고객사 전체 목록
        Common.getCustomerList((response) => {
            setCustomerList(response);
        });
    }, []);


    useEffect(() => {
        if(startDate !== ''){
            dateChangeHandler(startDate, response => {
                setForm({
                    ...form,
                    startDate: response,
                });
            });
        }

    }, [ startDate]);

    useEffect(() => {
        if(endDate !== '') {

            dateChangeHandler(endDate, response => {
                setForm({
                    ...form,
                    endDate: response,
                });
            });
        }
    }, [ endDate]);

    useEffect(() => {
        if(popupEndDate !== '') {

            dateChangeHandler(popupEndDate, response => {
                setForm({
                    ...form,
                    popupEndDate: response,
                });
            });
        }
    }, [ popupEndDate]);

    useEffect(() => {
        if(popupStartDate !== '') {
            dateChangeHandler(popupStartDate, response => {
                setForm({
                    ...form,
                    popupStartDate: response,
                });
            });
        }
    }, [ popupStartDate]);

    useEffect(()=>{
        if(pushNotiDate !== ''){
            let pushNotiDateFormating = '';
            dateChangeHandler(pushNotiDate, response => {
                if(pushNotiTime !== '' ){
                    pushNotiDateFormating = response + ' ' +pushNotiTime+':00';
                }else {
                    pushNotiDateFormating = response + ' 00:00:00';
                }
                setForm({
                    ...form,
                    pushNotiDateTime: pushNotiDateFormating
                })
            });
        }

    },[pushNotiTime, pushNotiDate]);

    useEffect(() => {
        if(form.customer.code !== '') {
            getSitesList(form.customer.code, (response) => {
                setSiteList(response);
            })
        } else {
            setSiteList([
                {
                    code: '',
                    name: '현장명 선택'
                }
            ])
        }
    }, [form.customer.code]);

    const history = useHistory();

    const getSitesList = (customerCode, callback) => {
        Net.getSitesList(
            {currentPage: '', rowPerPage: '', searchText: '', customerCode: customerCode }, (response) => {
                if (response.status === 200) {
                    let newArray = [];
                    if (response.data.content.length > 0) {
                        response.data.content.map(items => {
                            newArray = [
                                ...newArray,
                                {
                                    code: items.code,
                                    name: items.name
                                }
                            ]

                        })
                    }
                    callback(newArray);
                } else {
                    let newArray = [{code: '', name: "현장명 선택"}];
                    callback(newArray);
                }
            });
    }
    const textFieldHandler = (name, value) => {
            if(name==="sort" || name === "popupSort"){
                const nextForm = {
                    ...form,
                    [name]: Number(value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1'))
                }
                setForm(nextForm);
            }else{
                const nextForm = {
                    ...form,
                    [name]: value
                }
                setForm(nextForm);
            }

    }

    const selectBoxHandler = (name, value) => {
        switch (name) {
            case 'customer.code':
                setForm({
                    ...form,
                    customer: {
                        code: value.code,
                        name: value.name
                    },
                    site: {
                        code: "",
                        name: ""
                    }
                });
                break;
            case 'site.code':
                setForm({
                    ...form,
                    site: {
                        code: value.code,
                        name: value.name
                    }
                });
                break;
            default:
                break;
        }
    }

    const onSubmitHandler = async () => {

        if( !form.o2oServiceName ) {
            alert('서비스 이름을 입력해 주세요.');
            document.getElementsByName('title')[0].focus();
            return false;
        }
        if( !form.o2oServiceOverview ) {
            alert('내용을 입력해 주세요.');
            return false;
        }
        if( !form.companyName ) {
            alert('업체명을 입력해 주세요.');
            return false;
        }
        if( !form.sort ) {
            alert('정렬순서를 입력해 주세요.');
            document.getElementsByName('sort')[0].focus();
            return false;
        }

        if(form.startDate){
            if(!form.endDate){
                alert('공개기간 종료 일자 입력해 주세요.');
                return false;
            }
        }

        if(form.popupStartDate){
            if(!form.popupEndDate){
                alert('팝업 공개기간 종료 일자 입력해 주세요.');
                return false;
            }
            if(!form.popupSort){
                alert('팝업정렬순서를 입력해 주세요.');
                return false;
            }
        }


        let result = window.confirm('저장하시겠습니까?');
        if(result) {
            if(registPage) {
                const response = await Net.addO2Oasync(form ,updateSite );
                if(response.status === 201) {
                    history.push('/community/o2o/list');
                }
            } else {
                const response = await Net.updateO2OAsync(form, updateSite );
                if(response.status === 200) {
                    history.push('/community/o2o/list');
                }
            }
        }
    }

    const onCancelHandler = () => {
        history.push('/community/o2o/list');
    }

    const getImgFile = (dataUrl) => {
        setForm({
            ...form,
            imageDataUrl: dataUrl,
        });
    }
    const getThumbnailImgFile = (dataUrl) => {
        setForm({
            ...form,
            thumbnailImageDataUrl: dataUrl,
        });
    }

    const getPopupImgFile = (dataUrl) => {
        setForm({
            ...form,
            popupImageDataUrl: dataUrl,
        });
    }

    const imageRemoveHandler = () => {
        setForm({
            ...form,
            image : "",
            imageDataUrl : ''
        })
    }

    const thumbnailRemoveHandler = () => {
        setForm({
            ...form,
            thumbnailImage : "",
            thumbnailImageDataUrl : ''
        })
    }

    const popupRemoveHandler = () => {
        setForm({
            ...form,
            popupImage : "",
            popupImageDataUrl : ''
        })
    }

    const dateChangeHandler = (date , callback) => {
        const dateFormating = date.getFullYear() + '-' + ('0' + (Number(date.getMonth())+1)).slice(-2) + '-' + ('0' + date.getDate()).slice(-2);

        callback(dateFormating)
    }

    const onRemoveHandler = () => {
        const result = window.confirm('삭제하시겠습니까?');
        if(result) {
            Net.removeO2O([{o2oId: form.o2oId}], (response) => {
                if(response) {
                    history.push('/community/o2o/list');
                }
            });
        }
    }

    const handleMultiSelectChange = (selectedOptions) => {
        // 여기서 selectedOptions를 활용하여 원하는 동작을 수행
        console.log(selectedOptions);
        // ... 추가적인 로직
        if (!arrayEquality(selectedOptions, updateSite)) {  // 배열이 같은지 확인 후 변경 시에만 업데이트
            setUpdateSite(selectedOptions);
        }
    };

// 배열 비교 함수
    function arrayEquality(arr1, arr2) {
        if (arr1.length !== arr2.length) {
            return false;
        }
        for (let i = 0; i < arr1.length; i++) {
            if (arr1[i].code !== arr2[i].code || arr1[i].name !== arr2[i].name) {
                return false;
            }
        }
        return true;
    }

    return (
        <div className="w-100 h-100">
            <div className="w-100 h-1px bgC-efefef" />
            <div className="h-93">
                <div className="flex h-90">
                    <div className="w-100 h-100 overFlowYscrollView">
                        <div className="ml-55 mt-12">
                            <div className="flex mb-12 w-90">
                                <CustomTextField
                                    className="w-60 h-26px"
                                    title="서비스 이름"
                                    name="o2oServiceName"
                                    required={true}
                                    disabled={false}
                                    placeholder=""
                                    customOnChange={textFieldHandler}
                                    value={form}
                                />
                            </div>
                            <div className="flex mb-12 w-90">
                                <CustomTextField
                                    className="w-60 h-26px"
                                    title="업체명"
                                    name="companyName"
                                    required={true}
                                    disabled={false}
                                    placeholder=""
                                    customOnChange={textFieldHandler}
                                    value={form}
                                />
                            </div>
                            <div className="flex mb-3 w-90">
                                <label className="label_title mr-10">내용 *</label>
                                <div className="w-100">
                                    <TextareaAutosize
                                        name="content"
                                        className="board_content form-control col-12 h-25"
                                        onChange={(event)=>{
                                            setForm({...form,
                                                o2oServiceOverview: event.target.value});
                                        }}
                                        value={form.o2oServiceOverview}
                                        minRows={3}
                                        type="text"
                                    />
                                </div>
                            </div>

                            <div className="flex mb-12 w-90">
                                <CustomTextField
                                    className="w-60 h-26px"
                                    title="webUrl"
                                    name="webUrl"
                                    disabled={false}
                                    placeholder=""
                                    customOnChange={textFieldHandler}
                                    value={form}
                                />
                            </div>
                            <div className="flex mb-12 w-90">
                                <CustomTextField
                                    className="w-60 h-26px"
                                    title="iosUrl"
                                    name="iosUrl"
                                    disabled={false}
                                    placeholder=""
                                    customOnChange={textFieldHandler}
                                    value={form}
                                />
                            </div>
                            <div className="flex mb-12 w-90">
                                <CustomTextField
                                    className="w-60 h-26px"
                                    title="aosUrl"
                                    name="aosUrl"
                                    disabled={false}
                                    placeholder=""
                                    customOnChange={textFieldHandler}
                                    value={form}
                                />
                            </div>
                            <div className="flex mb-12 w-90">
                                <label className="label_title">고객사 *</label>
                                <CustomSelectBox
                                    name="customer.code"
                                    categoryList={customerList}
                                    value={form.customer.code}
                                    onChangeHandler={(value, name) => selectBoxHandler('customer.code', {code: value, name: name})}
                                    callbackCodeName="Y"
                                    disabled={!registPage}
                                />
                                {
                                    registPage ? <div></div> :
                                        <div className="label_summary ml-10">※ 수정시 고객사 변경 불가능 합니다. </div>
                                }
                            </div>
                            <div className="flex mb-12 w-90">
                                <label className="label_title">현장</label>
                                {
                                    <CustomMultiSelectBox
                                        categoryList={siteList}
                                        className="multi-select"
                                        value={form.site}
                                        onChangeHandler={handleMultiSelectChange}
                                        callbackCodeName="Y"
                                    />
                                }
                            </div>

                            <div className="flex mb-12 w-90">
                                <label className="label_title">공개기간</label>
                                <div className="w-18 mr-10">
                                    <DatePicker
                                        dateFormat="yyyy-MM-dd"
                                        selected={startDate}
                                        onChange={date => {
                                            setStartDate(date);
                                        }}
                                        className="w-100 textField"
                                    />
                                </div>
                                ~
                                <div className="w-18 ml-10 me-4">
                                    <DatePicker
                                        dateFormat="yyyy-MM-dd"
                                        selected={endDate}
                                        onChange={date => {
                                            setEndDate(date);
                                        }}
                                        className="w-100 textField"
                                    />
                                </div>
                                <CustomTextField
                                    className="w-18 h-26px"
                                    title="정렬 순서"
                                    name="sort"
                                    required={true}
                                    disabled={false}
                                    placeholder="숫자 입력 ex)1, 2, 3"
                                    customOnChange={textFieldHandler}
                                    value={form}
                                    type={"number"}
                                />

                            </div>

                            <div className="flex mb-12 w-90">
                                <label className="label_title">팝업 공개기간</label>
                                <div className="w-18 mr-10">
                                    <DatePicker
                                        dateFormat="yyyy-MM-dd"
                                        selected={popupStartDate}
                                        onChange={date => {
                                            setPopupStartDate(date);
                                        }}
                                        className="w-100 textField"
                                    />
                                </div>
                                ~
                                <div className="w-18 ml-10 me-4">
                                    <DatePicker
                                        dateFormat="yyyy-MM-dd"
                                        selected={popupEndDate}
                                        onChange={date => {
                                            setPopupEndDate(date);
                                        }}
                                        className="w-100 textField"
                                    />
                                </div>
                                <CustomTextField
                                    className="w-18 h-26px"
                                    title="팝업 정렬 순서"
                                    name="popupSort"
                                    disabled={false}
                                    placeholder="숫자 입력 ex)1, 2, 3"
                                    customOnChange={textFieldHandler}
                                    value={form}
                                    type={"number"}
                                />
                            </div>
                            <div className="flex mb-12 w-90">
                                <label className="label_title">팝업 푸시 날짜</label>
                                <div className="w-18 mr-10">
                                    <DatePicker
                                        dateFormat="yyyy-MM-dd"
                                        selected={pushNotiDate}
                                        onChange={date => {
                                            setPushNotiDate(date);
                                        }}
                                        className="w-100 textField"
                                    />
                                </div>
                                <input
                                    style={{border : '1px solid', fontSize :'12px', height : '22px'}}
                                    type="time"
                                    id="pushNotiTime"
                                    value={pushNotiTime}
                                    onChange={(e) => setPushNotiTime(e.target.value)}
                                />
                            </div>

                        </div>
                    </div>
                    <div className="w-40 m-4 overflowYscroll h-80vh">
                        <div className="w-100 mt-12" style={{display:"block"}} >
                            <div className="floatC mr-2p" >
                                <ImgUpload
                                    title={"이미지[750x1000]"}
                                    imgUpload={getImgFile}
                                    imgFile={form.imageDataUrl}
                                    inputName={"image"}
                                    removeHandler = {form.imageDataUrl !== "" && imageRemoveHandler}
                                />
                            </div>
                        </div>
                        <div className="w-100 mt-12" style={{display:"block"}} >
                            <div className="floatC mr-2p" >
                                <ImgUpload
                                    title={"썸네일[148x148]"}
                                    imgUpload={getThumbnailImgFile}
                                    imgFile={form.thumbnailImageDataUrl}
                                    inputName={"thumbnail"}
                                    removeHandler = {form.thumbnailImageDataUrl !== "" && thumbnailRemoveHandler}
                                />
                            </div>
                        </div>
                        <div className="w-100 mt-12" style={{display:"block"}} >
                            <div className="floatC mr-2p" >
                                <ImgUpload
                                    title={"팝업 [750x480]"}
                                    imgUpload={getPopupImgFile}
                                    imgFile={form.popupImageDataUrl}
                                    inputName={"popup"}
                                    removeHandler = {form.popupImageDataUrl !== "" && popupRemoveHandler}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer_btn h-10">
                    <div className="">
                        <button className="w-160px h-30px addBtn mr-10" onClick={onSubmitHandler}>저장</button>
                        { registPage ? "" : <button className="w-160px h-30px removeBtn mr-10" onClick={onRemoveHandler}>삭제</button>}
                        <button className="w-160px h-30px cancelBtn" onClick={onCancelHandler}>취소</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default withRouter(O2ORegist);
