import React from 'react';
import {Auth} from 'aws-amplify'
import {LoginAvatar} from "../../components/molecules";

function CognitoLogin() {

    return (
        <div className="login_bg overflowYscroll">
            <div className="login_content_area">
                <img
                    className="mt-auto mb-auto ml-auto"
                    src='/icon/login_logo.png'
                />
                <p className="main_logo">스마트홈 관리 시스템</p>
                <div className="row justify-content-center">
                    <LoginAvatar
                        imgUrl='/icon/login-icon.png'
                        click={()=> Auth.federatedSignIn()}
                        title="관리 시스템 로그인"
                    />
                    {/*<LoginAvatar*/}
                    {/*    imgUrl='/icon/home_user.png'*/}
                    {/*    click={()=> window.open('http://user.smartiok.com', '_blank')}*/}
                    {/*    title="스마트홈 지원페이지"*/}
                    {/*/>*/}
                    {/*<LoginAvatar*/}
                    {/*    imgUrl='/icon/reading_manual.png'*/}
                    {/*    click={()=> window.open('https://s3.ap-northeast-2.amazonaws.com/guide.smartiok.com/adminManual_KGC_iok.pdf', '_blank')}*/}
                    {/*    title="관리사무소 운영 매뉴얼"*/}
                    {/*/>*/}
                    {/*<LoginAvatar*/}
                    {/*    imgUrl='/icon/move_manual.png'*/}
                    {/*    click={()=> window.open('https://s3.ap-northeast-2.amazonaws.com/guide.smartiok.com/adminManual_iok_delete.pdf', '_blank')}*/}
                    {/*    title="홈네트워크 회원탈퇴 매뉴얼"*/}
                    {/*/>*/}
                </div>
            </div>

            <div className="text-center mt-5">
                <h5 className="input_img_text mb-3 mt-5">이메일 문의처 : woonkyoung_ham1@kolon.com </h5>
                <br />
                <img
                    className="mt-2 ml-auto"
                    src='/icon/logo_white.png'
                />
                <br />
                <img
                    className="mb-4 ml-auto"
                    src='/icon/group_footer.png'
                />
            </div>
        </div>
    );
}

const styles = {
    container: {
        height: '80vh',
        width: '100vw',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column'
    },
    button: {
        width: '100%',
        maxWidth: 250,
        marginBottom: 10,
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        padding: '0px 16px',
        borderRadius: 2,
        boxShadow: '0px 1px 3px rgba(0, 0, 0, .3)',
        cursor: 'pointer',
        outline: 'none',
        border: 'none',
        minHeight: 40
    },
    facebook: {
        backgroundColor: "#3b5998"
    },
    email: {
        backgroundColor: '#db4437'
    },
    checkAuth: {
        backgroundColor: '#02bd7e'
    },
    hostedUI: {
        backgroundColor: 'rgba(0, 0, 0, .6)'
    },
    signOut: {
        backgroundColor: 'black'
    },
    withAuthenticator: {
        backgroundColor: '#FF9900'
    },
    icon: {
        height: 16,
        marginLeft: -1
    },
    text: {
        color: 'white',
        fontSize: 14,
        marginLeft: 10,
        fontWeight: 'bold'
    },
    blackText: {
        color: 'black'
    },
    grayText: {
        color: 'rgba(0, 0, 0, .75)'
    },
    orangeText: {
        color: '#FF9900'
    }
}


export default CognitoLogin;
