/* eslint-disable */
// noinspection RegExpRedundantEscape,RegExpRedundantEscape

import React, {useEffect, useState} from 'react'
import {
    CustomTextField,
    CustomRadioButton,
    CustomSelectBox,
    CustomCheckbox,
    BasicModal,
    CustomTable
} from 'components/molecules';
import {useHistory, withRouter} from 'react-router-dom';
import store from 'scripts/store';
import Net from 'scripts/net';
import {SearchInputBar} from "../../../components/molecules";

const ToolsModelRegist = ({match}) => {

    const history = useHistory();

    const [form, setForm] = useState({
        code: '',
        name: '',
        type: {
            code: ''
        },
        manufacturer: {
            code: ''
        },
        attributes: [],
        operations: [],
        automationOperations: [],
        automationAttributes: [],
        usable: true,
    });
    const [registPage, setRegistPage] = useState(true);
    const [duplicateChk, setDuplicateChk] = useState(false);
    const [toolsTypeList, setToolsTypeList] = useState([{
        code: '',
        name: '장비타입 선택'
    }]);
    const [manufacturerList, setManufacturerList] = useState([{
        code: '',
        name: '제조사명 선택'
    }]);
    const [checkedItems, setCheckedItems] = useState({
        attributes: [],
        operations: [],
        automationOperations: [],
        automationAttributes: [],
    });
    const [modalState, setModalState] = useState({
        open: false,
        data: [],
        clickedBtnName: ''
    });

    useEffect(() => {
        store.openedPage = '모델 등록/수정';

        // 상세 조회(수정 목적으로 진입시)
        if (match.params.manufacturerCode !== undefined && match.params.code !== undefined) {
            Net.getToolsModelOne(match.params, (response) => {
                if (response.status === 200) {
                    setForm({
                        ...form,
                        ...response.data,
                    });

                    // 장비타입코드 중복체크 true
                    setDuplicateChk(true);
                    // 등록페이지 여부 확인
                    setRegistPage(false);
                }
            });
        }

        // 제조사 전체 목록
        getToolsManufacturerList((response) => {
            setManufacturerList(response);
        });
        // 장비 타입 전체 목록
        getToolsTypeList((response) => {
            setToolsTypeList(response);
        });
    }, []);

    const getToolsManufacturerList = (callback) => {
        Net.getToolsManufacturerList({currentPage: '', rowPerPage: '', searchText: ''}, (response) => {
            if (response.status === 200) {
                let newArray = manufacturerList;
                response.data.content.forEach(items => {
                    newArray = [
                        ...newArray,
                        {
                            code: items.code,
                            name: items.name
                        }
                    ]
                });
                callback(newArray);
            }
        });
    }

    const getToolsTypeList = (callback) => {
        Net.getToolsTypeList({currentPage: '', rowPerPage: '', searchText: ''}, (response) => {
            if (response.status === 200) {
                let newArray = toolsTypeList;
                response.data.content.forEach(items => {
                    newArray = [
                        ...newArray,
                        {
                            code: items.code,
                            name: items.name
                        }
                    ]
                });
                callback(newArray);
            }
        });
    }

    const textFieldHandler = (name, value) => {
        const nextForm = {
            ...form,
            [name]: value
        }
        setForm(nextForm);
    }

    const radioBtnHandler = (name, value) => {
        const nextForm = {
            ...form,
            [name]: value === 'true' ? true : false,
        }
        setForm(nextForm);
    }

    const selectBoxHandler = (name, value) => {
        if (name === 'type.code') {
            setForm({
                ...form,
                type: {
                    code: value
                }
            });

        } else if (name === 'manufacturer.code') {
            setForm({
                ...form,
                manufacturer: {
                    code: value
                }
            });
        }
    }

    const onDuplicateHandler = () => {
        if (form.manufacturer.code === '') {
            alert('제조사명을 선택해주세요.');
            document.getElementsByName('manufacturer.code')[0].focus();
            return false;
        }
        if (form.code !== '') {
            Net.chkDuplicateToolsModel(form, (response) => {
                if (response.status === 200) {
                    alert('이미 등록된 모델코드입니다.');
                } else if (response.status === 404) {
                    alert('등록 가능한 모델코드입니다.');
                    setDuplicateChk(true);
                }
            });
        } else if (form.code === '') {
            alert('모델코드를 입력해주세요.');
        }
    }

    // Checkbox onChange Event
    const handleCheckbox = (id, checked, type) => {
        if (type === 'operations') {
            if (checked) {
                setCheckedItems({
                    // ...checkedItems,
                    attributes: checkedItems.attributes,
                    operations: checkedItems.operations.concat(id)
                });
            } else {
                setCheckedItems({
                    // ...checkedItems,
                    attributes: checkedItems.attributes,
                    operations: checkedItems.operations.filter(items => items !== id)
                });
            }
        } else if (type === 'attributes') {
            if (checked) {
                setCheckedItems({
                    // ...checkedItems,
                    attributes: checkedItems.attributes.concat(id),
                    operations: checkedItems.operations
                });
            } else {
                setCheckedItems({
                    // ...checkedItems,
                    attributes: checkedItems.attributes.filter(items => items !== id),
                    operations: checkedItems.operations
                });
            }
        }
    }

    // Open Modal and setClickedBtnName
    const onClickModalOpen = (type) => {
        setModalState({
            ...modalState,
            open: !modalState.open,
            clickedBtnName: type
        });
    }

    // remove checkedList
    const onClickRemoveList = (type) => {
        const result = window.confirm('선택된 속성값을 삭제하시겠습니까?');

        if (result) {
            if (type === 'operations') {
                const newArray = form.operations;
                const newAutoArray = form.automationOperations;
                checkedItems.operations.forEach(code => {
                    form.operations.forEach((data, index) => {
                        if (code.includes(data.id)) {
                            //삭제해야 할 대상
                            newArray.splice(index, 1);
                            form.automationOperations.forEach((data2, index2) => {
                                if (code.includes(data2.id)) {
                                    newAutoArray.splice(index2, 1);
                                }
                            })
                        }
                    });
                });
                setCheckedItems({
                    attributes: checkedItems.attributes,
                    operations: [],

                });
                setForm({
                    ...form,
                    operations: newArray,
                    automationOperations: newAutoArray,
                });

            } else if (type === 'attributes') {
                const newArray = form.attributes;
                const newAutoArray = form.automationAttributes;

                checkedItems.attributes.forEach(code => {
                    form.attributes.forEach((data, index) => {
                        if (code.includes(data.id)) {
                            //삭제해야 할 대상
                            newArray.splice(index, 1);
                            form.automationAttributes.forEach((data2, index2) => {
                                if (code.includes(data2.id)) {
                                    newAutoArray.splice(index2, 1);
                                }
                            })
                        }
                    });
                })
                setCheckedItems({
                    attributes: [],
                    operations: checkedItems.operations,
                });
                setForm({
                    ...form,
                    attributes: newArray,
                    automationAttributes: newAutoArray,
                });
            }
        }
    }

    const onSubmitHandler = () => {
        if (form.manufacturer.code === '') {
            alert('제조사명은 필수값입니다.');
            document.getElementsByName('manufacturer.code')[0].focus();
            return false;
        }
        if (form.code === '') {
            alert('모델코드는 필수값입니다.');
            document.getElementsByName('code')[0].focus();
            return false;
        }
        if (!duplicateChk) {
            alert('모델코드 중복체크를 해주세요.');
            return false;
        }
        if (form.name === '') {
            alert('모델명은 필수값입니다.');
            document.getElementsByName('name')[0].focus();
            return false;
        }
        if (form.type.code === '') {
            alert('장비타입은 필수값입니다.');
            document.getElementsByName('type.code')[0].focus();
            return false;
        }

        var result = window.confirm('저장하시겠습니까?');
        if (result) {
            if (registPage) {
                Net.addToolsModel(form, (response) => {
                    if (response.status === 201) {
                        history.push('/tools/model/list');
                    }
                });
            } else {
                Net.updateToolsModel(form, (response) => {
                    if (response.status === 200) {
                        history.push('/tools/model/list');
                    }
                });
            }
        }
    }

    const onCancelHandler = () => {
        history.push('/tools/model/list');
    }

    const sendData = (e) => {
        setModalState({
            ...modalState,
            data: e,
        })
    }

    const rcvData = (e) => {
        if (modalState.clickedBtnName === 'operations') {
            setForm({
                ...form,
                operations: modalState.data,
            });
        } else if (modalState.clickedBtnName === 'attributes') {
            setForm({
                ...form,
                attributes: modalState.data,
            });
        }
    }

    const onRemoveHandler = () => {
        const result = window.confirm('삭제하시겠습니까?');
        // return axios.delete(`/manufacturers/${codeList.manufacturerCode}/models/${codeList.code}`)
        if (result) {
            Net.removeToolsModelList([{manufacturerCode: form.manufacturer.code, code: form.code}], (response) => {
                if (response) {
                    history.push('/tools/model/list');
                }
            });
        }
    }

    const ableUse = (id, operation) => {
        if (operation) {
            const array = form.automationOperations.map((list) => {
                if (list.id !== id) {
                    return list;
                }
            })
            const newArr = array.filter(
                (e, i) => e !== undefined
            );
            setForm({
                ...form,
                automationOperations: newArr
            })
            console.log(array, form);
        } else {
            const array = form.automationAttributes.map((list) => {
                if (list.id !== id) {
                    return list;
                }
            })
            const newArr = array.filter(
                (e, i) => e !== undefined
            );
            setForm({
                ...form,
                automationAttributes: newArr
            })
        }
    }

    const unAbleUse = (data, operation) => {
        if (operation) {
            const array = form.automationOperations.concat([data]);
            setForm({
                ...form,
                automationOperations: array
            })
        } else {
            const array = form.automationAttributes.concat([data]);
            setForm({
                ...form,
                automationAttributes: array
            })
        }
    }

    const makeListToDom = (list, autoList, operation) => {
        const dom = (
            list.map((data, index) => {
                return (
                    <tr key={index}>
                        {
                            operation ? (
                                <td onClick={e => e.stopPropagation()}>
                                    <CustomCheckbox
                                        id={`${data.id}_oper`}
                                        className="j-c-c h-16px"
                                        title=""
                                        checked={checkedItems.operations.includes(data.id)}
                                        onChange={(id, checked) => handleCheckbox(id, checked, 'operations')}
                                    />
                                </td>
                            ) : (
                                <td onClick={e => e.stopPropagation()}>
                                    <CustomCheckbox
                                        id={`${data.id}_attr`}
                                        className="j-c-c h-16px"
                                        title=""
                                        checked={checkedItems.operations.includes(data.id)}
                                        onChange={(id, checked) => handleCheckbox(id, checked, 'attributes')}
                                    />
                                </td>
                            )
                        }


                        <td>{data.code}</td>
                        <td>{data.name}</td>
                        <td>
                            {
                                data.valueType === 'ENUMERATION' ?
                                    (data.availableValues !== undefined && data.availableValues.map((value, index) => {
                                        if (data.availableValues.length - 1 === index) {
                                            return value;
                                        } else {
                                            return value += ', ';
                                        }
                                    })) :
                                    (data.bottomValue + ' ~ ' + data.topValue)
                            }
                        </td>
                        <td>{
                            autoList.findIndex(i => i.id == data.id) >= 0 ? <button
                                    className="addBtn w-50px h-20px"
                                    onClick={() => {
                                        ableUse(data.id, operation)
                                    }}
                                >사용</button>
                                : <button
                                    className="cancelBtn w-60px h-20px"
                                    onClick={() => {
                                        unAbleUse(data, operation)
                                    }}
                                >미사용</button>
                        }
                        </td>
                    </tr>
                )
            })
        )

        return dom;
    }

    return (
        <div className="w-100 h-100">
            <BasicModal
                className="w-70 h-67"
                title={modalState.clickedBtnName === 'operations' ? '장비행위 속성 추가' : '장비정보 속성 추가'}
                content={<ModalContent sendData={sendData} formData={form} modalState={modalState}/>}
                modalOpen={modalState.open}
                modalClose={(e) => setModalState({
                    ...modalState,
                    open: e
                })}
                sendData={modalState.data}
                rcvData={rcvData}
                activeCloseBtn={true}
            />
            <div className="w-100 h-7 display_table"></div>
            <div className="w-100 h-1px bgC-efefef"/>
            <div className="h-93">
                <div className="flex h-90">
                    <div className="w-60 h-100">
                        <div className="ml-55 mt-12">
                            <div className="flex mb-12 w-90">
                                <label className="label_title">제조사명 *</label>
                                <CustomSelectBox
                                    name="manufacturer.code"
                                    categoryList={manufacturerList}
                                    onChangeHandler={(value) => selectBoxHandler('manufacturer.code', value)}
                                    value={form.manufacturer.code}
                                    disabled={!registPage}
                                />
                            </div>
                            <div className="flex mb-12 w-90">
                                <CustomTextField
                                    className="w-65 h-26px"
                                    title="모델코드"
                                    name="code"
                                    required={true}
                                    disabled={false}
                                    validRegExp={/[ㄱ-ㅎ|ㅏ-ㅣ|가-힣|\{\}\[\]\/?.,;:|\)*~`!^+<>@\#$%&\\\=\(\'\"]/g}
                                    placeholder="코드는 영문 및 숫자 조합이며, 단어와 단어 사이는 언더바 및 하이픈(-) 사용"
                                    customOnChange={textFieldHandler}
                                    value={form}
                                    disabled={!registPage}
                                />
                                <div className="mt-auto mb-auto">
                                    <button
                                        className="w-78px h-22px duplicateBtn"
                                        onClick={onDuplicateHandler}
                                        disabled={!registPage}
                                    >중복체크
                                    </button>
                                </div>
                            </div>
                            <div className="flex mb-12 w-90">
                                <CustomTextField
                                    className="w-80 h-26px"
                                    title="모델명"
                                    name="name"
                                    required={true}
                                    disabled={false}
                                    validLen="15"
                                    validRegExp={/[\{\}\[\]\/?.,;:|\)*~`!^\-_+<>@\#$%&\\\=\(\'\"]/gi}
                                    placeholder="타입명은 특수문자 제외 최대 15자"
                                    customOnChange={textFieldHandler}
                                    value={form}
                                />
                            </div>
                            <div className="flex mb-12 w-90">
                                <label className="label_title">장비타입 *</label>
                                <CustomSelectBox
                                    name="type.code"
                                    categoryList={toolsTypeList}
                                    onChangeHandler={(value) => selectBoxHandler('type.code', value)}
                                    value={form.type.code}
                                />
                            </div>
                            <div className="flex w-90">
                                <label className="label_title">사용유무</label>
                                <CustomRadioButton
                                    className="mr-60"
                                    title="사용"
                                    id="radioBtn1"
                                    name="usable"
                                    value={true}
                                    checked={form.usable}
                                    customOnChange={radioBtnHandler}
                                />
                                <CustomRadioButton
                                    title="사용안함"
                                    id="radioBtn2"
                                    name="usable"
                                    value={false}
                                    checked={!form.usable}
                                    customOnChange={radioBtnHandler}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="w-40 h-100">
                        <div className="w-100 h-50 mt-12 overflowYscroll">
                            <div className="floatR mr-2p fixedHeader t-0px w-96 txtR bgC-ffffff">
                                <button className="addBtn w-25px h-25px"
                                        onClick={() => onClickModalOpen('operations')}>+
                                </button>
                                <button className="removeBtn w-25px h-25px"
                                        onClick={() => onClickRemoveList('operations')}>-
                                </button>
                            </div>
                            <table className="mainTable">
                                <colgroup>
                                    <col width="10%"/>
                                    <col width="35%"/>
                                    <col width="20%"/>
                                    <col width="20%"/>
                                    <col width="15%"/>
                                </colgroup>
                                <thead className="bgC-c5c5c5">
                                <tr>
                                    <th colSpan="5" className="tableHead fixedHeader t-25px">
                                        장비행위
                                    </th>
                                </tr>
                                <tr>
                                    <th className="tableHead fixedHeader t-61px"></th>
                                    <th className="v-middle tableHead fixedHeader t-61px"><span
                                        className="h-24px">속성코드</span></th>
                                    <th className="v-middle tableHead fixedHeader t-61px"><span
                                        className="h-24px">속성명</span></th>
                                    <th className="v-middle tableHead fixedHeader t-61px"><span
                                        className="h-24px">속성값</span></th>
                                    <th className="v-middle tableHead fixedHeader t-61px"><span className="h-24px">자동화 여부</span>
                                    </th>

                                </tr>
                                </thead>
                                <tbody className="tableBody">
                                {
                                    form.operations.length > 0 ?
                                        makeListToDom(form.operations, form.automationOperations, true)
                                        :
                                        (<tr>
                                            <td colSpan="5">조회 된 데이터가 없습니다.</td>
                                        </tr>)
                                }
                                </tbody>
                            </table>
                        </div>
                        <div className="w-100 h-50 overflowYscroll">
                            <div className="floatR mr-2p fixedHeader t-0px w-96 txtR bgC-ffffff">
                                <button className="addBtn w-25px h-25px"
                                        onClick={() => onClickModalOpen('attributes')}>+
                                </button>
                                <button className="removeBtn w-25px h-25px"
                                        onClick={() => onClickRemoveList('attributes')}>-
                                </button>
                            </div>
                            <table className="mainTable">
                                <colgroup>
                                    <col width="10%"/>
                                    <col width="35%"/>
                                    <col width="20%"/>
                                    <col width="20%"/>
                                    <col width="15%"/>
                                </colgroup>
                                <thead className="bgC-c5c5c5">
                                <tr>
                                    <th colSpan="5" className="tableHead fixedHeader t-25px">장비정보</th>
                                </tr>
                                <tr>
                                    <th className="tableHead fixedHeader t-36px"></th>
                                    <th className="v-middle tableHead fixedHeader t-61px"><span
                                        className="h-24px">속성코드</span></th>
                                    <th className="v-middle tableHead fixedHeader t-61px"><span
                                        className="h-24px">속성명</span></th>
                                    <th className="v-middle tableHead fixedHeader t-61px"><span
                                        className="h-24px">속성값</span></th>
                                    <th className="v-middle tableHead fixedHeader t-61px"><span className="h-24px">자동화 여부</span>
                                    </th>

                                </tr>
                                </thead>
                                <tbody className="tableBody">
                                {
                                    form.attributes.length > 0 ?
                                        makeListToDom(form.attributes, form.automationAttributes, false)
                                        :
                                        (<tr>
                                            <td colSpan="5">조회 된 데이터가 없습니다.</td>
                                        </tr>)
                                }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div className="footer_btn h-10">
                    <button className="w-160px h-30px addBtn mr-10" onClick={onSubmitHandler}>저장</button>
                    {registPage ? "" :
                        <button className="w-160px h-30px removeBtn mr-10" onClick={onRemoveHandler}>삭제</button>}
                    <button className="w-160px h-30px cancelBtn" onClick={onCancelHandler}>취소</button>
                </div>
            </div>
        </div>
    )
}

export default withRouter(ToolsModelRegist);

const modalColumnList = [
    {
        code: 'id',
        title: '속성 아이디',
        width: '20',
        hidden: true
    },
    {
        code: 'code',
        title: '속성코드',
        width: '20',
    },
    {
        code: 'name',
        title: '속성명',
        width: '10',
    },
    {
        code: 'valueType',
        title: '속성 형태',
        width: '10',
    },
    {
        code: 'topValue',
        title: '최고값',
        width: '8',
    },
    {
        code: 'bottomValue',
        title: '최저값',
        width: '8',
    },
    {
        code: '',
        title: '단계',
        width: '10'
    },
    {
        code: 'availableValues',
        title: '열거값',
        width: '16',
    },
    {
        code: 'unit',
        title: '단위',
        width: '8',
    },
    {
        code: 'usable',
        title: '사용 여부',
        width: '8',
    },
]

export const ModalContent = (props) => {

    const [firstCheckedData, setFirstCheckedData] = useState([]);
    const [propertyList, setpropertyList] = useState([]);
    const [propertyCheckedList, setPropertyCheckedList] = useState([]);
    const [modalSearchText, setModalSearchText] = useState('');

    useEffect(() => {
        // 모델 속성 전체 목록
        Net.getToolsPropertyList(
            {currentPage: '0', rowPerPage: '1000000', searchText: modalSearchText}, (response) => {
                if (response.status === 200) {
                    const operationsCodeList = props.formData.operations && props.formData.operations.map(data => {
                        return data.id || [];
                    });

                    const attributesCodeList = props.formData.attributes && props.formData.attributes.map(data => {
                        return data.id || [];
                    });

                    const rowIdxList = [];
                    response.data.content.map((data, index) => {
                        const code = data.id;
                        switch (props.modalState.clickedBtnName) {
                            case 'operations':
                                if (operationsCodeList.indexOf(code) != -1) {
                                    rowIdxList.push('row_' + index);
                                }
                                break;
                            case 'attributes':
                                if (attributesCodeList.indexOf(code) != -1) {
                                    rowIdxList.push('row_' + index);
                                }
                                break;

                            default:
                                break;
                        }
                    });
                    setpropertyList(response.data.content);
                    setFirstCheckedData(rowIdxList);
                }
            });
    }, [modalSearchText])

    useEffect(() => {
        const sendData = [];
        propertyCheckedList.forEach(id => {
            const node = document.querySelector(`#${id}`);
            const nodeTr = node.closest('tr');
            const codeValue = nodeTr.querySelector('td[name=id]').textContent;
            propertyList.forEach(data => {
                if (data.id === codeValue) {
                    sendData.push(data);
                }
            });
        });
        props.sendData(sendData);
    }, [propertyCheckedList])

    const onSubmitHandler = (e) => {
        setModalSearchText(e.searchText);
    }
    return (
        <div className="w-100 h-100">
            <div className="w-100 h-7 mb-2 display_table">
                <SearchInputBar
                    className="w-100"
                    searchCategory={false}
                    searchCategoryGroup=""
                    searchText={true}
                    searchPlaceHolder="장비속성명 입력해 주세요."
                    searchBtn={true}
                    searchBtnTitle="검색"
                    dynamicSearch={false}
                    onSubmit={onSubmitHandler}

                />
            </div>
            <div className="flex h-93">
                <CustomTable
                    className="w-100 h-100 overflowYscroll"
                    columnList={modalColumnList}
                    contentData={propertyList}
                    checkbox={true}
                    checkedList={(checkedList) => {
                        setPropertyCheckedList(checkedList);
                    }}
                    firstCheckedData={firstCheckedData}
                    rowClickEvt={false}
                    paging={false}
                />
            </div>
        </div>
    )
}
