import React, { Fragment, useState, useEffect } from "react";
import {ListItemLink} from "components/molecules";
import classNames from 'classnames';
import store from 'scripts/store';
import Net from 'scripts/net';
import "./Menu.scss";

export const Menu = (props) => {

    const [routes, setRoutes] = useState([]);
    const [leftMenuDisplay, setLeftMenuDisplay] = useState(true);
    useEffect(() => {
        getMenuList((response) => {
            setRoutes(response);
        });
    }, [])
    useEffect(() => {
        props.onDrawer(leftMenuDisplay);
    }, [leftMenuDisplay])

    const displayHandler = () => {
        setLeftMenuDisplay(!leftMenuDisplay);
    }

    const getMenuList = (callback) => {
        Net.getMenuTreeList({codeType: store.user.menuGroup || props.userInfo.menuGroup}, (response) => {
            if(response.status === 200) {
                callback(response.data);
            }
        });
    }

    return (
        <Fragment>
            <div className={classNames('menu_bg',
                {'show' : leftMenuDisplay,
                'hide' : !leftMenuDisplay})}>
                {routes.map((route, index) => {
                    return (
                        <ListItemLink key={index} {...route} />
                        // <ListItemLink_test key={index} {...route} />
                    )
                })}
            </div>
            <div className={classNames('position_abs',
                {'l-210px' : leftMenuDisplay,
                'l-0px' : !leftMenuDisplay})}>
                <button className="left_menu_show_hide_btn" onClick={displayHandler}>
                    { leftMenuDisplay ? <img className="w-100 h-100" src="/icon/prev.png"/> : <img className="w-100 h-100" src="/icon/next.png"/> }
                </button>
            </div>
        </Fragment>

    );
};
