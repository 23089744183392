import React from "react";
import ReactTooltip from "react-tooltip";
import './Tooltip.scss';

export const Tooltip = ({description, id, tooltipClassName, place, type, effect ,children}) => {
    return (
        <div className="">
            <a data-event='click focus' data-for={id} data-tip>
                {description}
            </a>
            <ReactTooltip
                id={id}
                className={tooltipClassName}
                place={place}
                type={type}
                effect={effect}
                globalEventOff='click'
            >
                <div className="tooltipArea">
                   {children}
                </div>
            </ReactTooltip>
        </div>
    )

}
