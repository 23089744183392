import React, {Fragment, useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom'
import {CustomTable, SearchInputBar} from 'components/molecules';
import store from 'scripts/store';
import Net from 'scripts/net';
import DatePicker from "react-datepicker";
import {Checkbox} from "@material-ui/core";

const columnList = [
    {
        code: 'bannerId',
        title: 'bannerId',
        width: '8',
        hidden: true,
    },
    {
        code: 'title',
        title: '제목',
        width: '20',
    },
    {
        code: 'viewDate',
        title: '공개기간',
        width: '18',
    },
    {
        code: 'siteName',
        title: '현장명',
        width: '10',
    },
    {
        code: 'customer.name',
        title: '고객사',
        width: '8',
    },
    {
        code: 'sort',
        title: '정렬',
        width: '5',
    },
    {
        code: 'readCount',
        title: '조회수',
        width: '6',
    },
    {
        code: 'linkCount',
        title: '링크수',
        width: '6',
    },
    {
        code: 'usable',
        title: '사용여부',
        width: '6',
    },
];

export const BannerList = (props) => {

    // useHistory 선언
    const history = useHistory();
    const user = props.userInfo;
    const [programAuth, setProgramAuth] = useState({});
    const [contentData, setContentData] = useState([]);
    const [checkedList, setCheckedList] = useState([]);
    const [bannerDetail, setBannerDetail] = useState([]);
    const [loading, setLoading] = useState(false);
    const [clickLoading, setClickLoading] = useState(false);

    const [searchCategory, setSearchCategory] = useState([
        [
            [
                {code: '', name: '고객사 선택'}
            ]
        ],
        [
            [
                {code: '', name: '현장명 선택'}
            ]
        ]
    ]);

    const [pageState, setPageState] = useState({
        currentPage: 0,
        rowPerPage: 7,
        totalElementsCnt: 0,
        drawElementsCnt: 0,
        searchText: '',
        searchCategory1: user.menuGroup === 'ADMIN' ? '' : user.customer && user.customer.code,
        searchCategory2: user.menuGroup === 'OPERATOR' ? user.site && user.site.code : '',
        isAllDay: true

    });

    const [startDate, setStartDate] = useState(new Date(2022,1,1));
    const [endDate, setEndDate] = useState(new Date());
    const [clickData, setClickData] = useState({
        readView : 0,
        linkClick : 0,
        linkClickDistinct : 0
    });

    useEffect(() => {
        store.openedPage = '배너 목록';
        getCategoryList('');
        pageAuthInfo();
    }, [])

    useEffect(() => {
        setTimeout(() => {
            loadData();
        }, 10)
    }, [pageState.currentPage,
        pageState.searchCategory1,
        pageState.searchCategory2,
        pageState.searchText
    ]);

    useEffect(()=>{
        if(pageState.currentPage === 0 ){
            loadData();
        }else {
            setPageState({
                ...pageState,
                currentPage: 0
            });
        }
    },[pageState.isAllDay])

    useEffect(()=>{
        setClickLoading(false);
    },[clickData]);

    useEffect(() =>{
        if(bannerDetail.hasOwnProperty('bannerId')){
            onClickRow({bannerId : bannerDetail.bannerId}).then();
        }
    },[startDate, endDate])

    const pageAuthInfo = () => {
        const authGroupCode = props.userInfo.authList[0];
        const menuId = sessionStorage.getItem('menuId');

        Net.getAuthGroupMenuList(menuId, (response) => {
            if (response.status === 200) {
                response.data.forEach(item => {
                    if (item.authGroup === authGroupCode && item.authGroupMenu) {
                        setProgramAuth({
                            searchAuth: Boolean(item.authGroupMenu.searchAuth),
                            saveAuth: Boolean(item.authGroupMenu.saveAuth),
                            excelAuth: Boolean(item.authGroupMenu.excelAuth),
                            deleteAuth: Boolean(item.authGroupMenu.deleteAuth),
                        });
                    }
                });
            }
        });
    }

    const loadData = () => {
        setLoading(true);
        Net.getBannerList(
            {
                currentPage: pageState.currentPage,
                rowPerPage: pageState.rowPerPage,
                searchText: pageState.searchText,
                searchCategory1: pageState.searchCategory1,
                searchCategory2: pageState.searchCategory2,
                isAllDay : pageState.isAllDay

            }, (response) => {
                setLoading(false);
                if (response.status === 200) {
                    setContentData(response.data.content.map((item, i) => {
                        item.viewDate = item.startDate + " ~ " + item.endDate;
                        if(item.site){
                            let siteName_ = '';
                            item.site.forEach(siteItem=>{
                                siteName_ += siteItem.name+', ';
                            });
                            item.siteName = siteName_;

                        }else{
                            item.siteName = '전체'
                        }

                        return item;
                    }))

                    setPageState({
                        ...pageState,
                        totalElementsCnt: response.data.totalElements,
                        drawElementsCnt: response.data.numberOfElements,
                    });
                } else {
                    setContentData([]);
                }
            });
    }

    const getCategoryList = () => {
        if (user.menuGroup === 'ADMIN') {
            getCustomerList((response1) => {
                getSitesList(null, (response2) => {
                    setSearchCategory([response1, response2]);
                });
            });
        } else if (user.menuGroup === 'MANAGER') {
            getSitesList(pageState.searchCategory1, (response2) => {
                setSearchCategory([response2]);
            });
        }
    }

    const getRelateCategory = (selectedCategory) => {
        if (selectedCategory === "reset") selectedCategory = "";
        getSitesList(selectedCategory, (response2) => {
            setSearchCategory([
                searchCategory[0],
                response2
            ]);
        });
    }

    const getCustomerList = (callback) => {
        Net.getCustomerList(
            {currentPage: '', rowPerPage: '', searchText: ''}, (response) => {
                if (response.status === 200) {
                    let newArray = searchCategory[0][0];
                    response.data.content.forEach(items => {
                        newArray = [
                            ...newArray,
                            {
                                code: items.code,
                                name: items.name
                            }
                        ]
                    });
                    callback(newArray);
                }
            });
    }

    const getSitesList = (selectedCategory, callback) => {
        if (selectedCategory) {
            Net.getSitesList(
                {
                    currentPage: '',
                    rowPerPage: '',
                    searchText: '',
                    customerCode: selectedCategory ? selectedCategory : false
                }, (response) => {
                    if (response.status === 200) {
                        let newArray = [{code: '', name: "현장명 선택"}];
                        if (response.data.content.length > 0) {
                            response.data.content.forEach(items => {
                                newArray = [
                                    ...newArray,
                                    {
                                        code: items.code,
                                        name: items.name
                                    }
                                ]

                            })
                        }
                        callback(newArray);
                    } else {
                        let newArray = [{code: '', name: "현장명 선택"}];
                        callback(newArray);
                    }
                });
        } else {
            let newArray = [{code: '', name: "현장명 선택"}];
            callback(newArray);
        }
    }

    const onClickRow = async (e) => {
        setClickLoading(true);
        Net.getBannerOne(e.bannerId, (response) => {
            if (response.status === 200) {
                setBannerDetail(response.data);
            } else {
                setBannerDetail([]);
            }
        });

        const bannerResponse = await Net.getBannerLinkListAsync({
            classification : 'bannerLink',
            parentId : e.bannerId,
            startDate : startDate,
            endDate : endDate,
        })
        const readResponse = await Net.getReadListAsync({
            classification : 'bannerRead',
            parentId : e.bannerId,
            startDate : startDate,
            endDate : endDate
        })
        let _clickData = {
            readView : 0,
            linkClick : 0,
            linkClickDistinct : 0
        };

        if(bannerResponse.status === 200){
            let linkCount = 0;
            const linkSet = new Set();
            bannerResponse.data.content.forEach(item => {
                linkSet.add(item.username);
                linkCount++;
            });
            _clickData = {
                ..._clickData,
                linkClick : linkCount,
                linkClickDistinct : linkSet.size
            };
        } else {
            _clickData = {
                ..._clickData,
                linkClick : 0,
                linkClickDistinct : 0
            };
        }
        if(readResponse.status === 200) {
            _clickData = {
                ..._clickData,
                readView: readResponse.data.content.length
            };
        }

        await setClickData(_clickData);
        setTimeout(()=>{
            setClickLoading(false);
        },[3000])
    }

    const onMovePage = (e) => {
        setPageState({
            ...pageState,
            currentPage: e - 1
        });
    }

    const removeList = () => {
        const result = window.confirm('삭제하시겠습니까?');
        const removeList = [];
        checkedList.map((id) => {
            const node = document.querySelector(`#${id}`);
            const nodeTr = node.closest('tr');
            const bannerId = nodeTr.querySelector('td[name=bannerId]').textContent;
            removeList.push({bannerId: bannerId});
        });
        if (result) {
            Net.removeBanner(removeList, (response) => {
                if (response) {
                    getCustomerList((response) => loadData(response.data));
                }
            });
        }
    }

    const onSubmitHandler = (e) => {
        if (user.menuGroup === 'ADMIN') {
            setPageState({
                ...pageState,
                searchCategory1: e.selectedCategory[0],
                searchCategory2: e.selectedCategory[1],
            });

        } else if (user.menuGroup === 'MANAGER') {
            setPageState({
                ...pageState,
                searchCategory1: pageState.searchCategory1,
                searchCategory2: e.selectedCategory[0],
            });
        } else {
            setPageState({
                ...pageState,
                searchCategory1: pageState.searchCategory1,
                searchCategory2: pageState.searchCategory2,
            });
        }
    }

    const addList = () => {
        history.push('/community/banner/regist');
    }

    const goModifypage = () => {
        history.push(`/community/banner/regist/${bannerDetail.bannerId}`);
    }

    return (
        <div className="w-100 h-100">
            <div className="w-100 h-7 display_table">
                <SearchInputBar
                    role={user.menuGroup}
                    className="w-100"
                    searchCategory={true}
                    searchCategoryGroup={searchCategory}
                    searchText={true}
                    searchPlaceHolder="제목을 입력해 주세요."
                    searchBtn={true}
                    searchBtnTitle="검색"
                    refreshBtn={true}
                    refreshHandler={loadData}
                    dynamicSearch={false}
                    relateCategoryHandler={user.menuGroup === 'ADMIN' && getRelateCategory}
                    onSubmit={onSubmitHandler}
                />
                {
                    programAuth.saveAuth &&
                    <div className="display_table_cell v-middle">
                        <button
                            className="addBtn w-90px h-30px floatR mr-20"
                            onClick={addList}
                        >등록
                        </button>
                    </div>

                }

            </div>
            <div className="w-100 h-1px bgC-efefef"/>
            <div className="flex h-93">
                <div className="w-70 h-100">
                    <div className="h-72">
                        <div className="row">
                            <div className="col-8">
                                <p className="totalCnt">Total {pageState.totalElementsCnt}</p>
                                {
                                    programAuth.deleteAuth && checkedList.length > 0 &&
                                    <button
                                        className="removeBtn w-90px h-30px floatR mt-7 mr-15"
                                        onClick={removeList}
                                    >삭제</button>
                                }
                            </div>
                            <div className="col-4 mt-2">
                                <span className="text-muted mt-7 mr-10">공개 중인 서비스만 보기</span>
                                <Checkbox
                                    checked={pageState.isAllDay}
                                    onChange={() => setPageState({
                                        ...pageState,
                                        isAllDay: !pageState.isAllDay
                                    })}
                                />
                            </div>
                        </div>


                        <CustomTable
                            columnList={columnList}
                            contentData={contentData}
                            paging={true}
                            checkbox={programAuth.deleteAuth}
                            checkedList={(checkedList) => {
                                setCheckedList(checkedList)
                            }}

                            totalElementsCnt={pageState.totalElementsCnt}
                            rowPerPage={pageState.rowPerPage}
                            currentPage={pageState.currentPage}
                            drawElementsCnt={pageState.drawElementsCnt}
                            pagination={onMovePage}
                            rowClickEvt={true}
                            onClickRow={onClickRow}
                            loading={loading}
                            displayAbsolute={true}
                            // sort = {true}
                        />
                    </div>
                    <div className="h-20">
                        <div className="flex justify-content-between">
                            <p className="totalCnt"> 배너 링크 클릭</p>
                            <div className="mt-3 me-2">
                                <span className="searchDate">
                                    <label>검색기간</label>
                                    <span>
                                        <DatePicker
                                           dateFormat="yyyy-MM-dd"
                                           selected={startDate}
                                           onChange={date => {
                                               setStartDate(date);
                                           }}
                                           className="w-100 textField"
                                        />
                                    </span>
                                       <span className="ml-8 mr-8 m-auto"> ~ </span>
                                       <span className="mr-10">
                                           <DatePicker
                                                dateFormat="yyyy-MM-dd"
                                                selected={endDate}
                                                onChange={date => {
                                                    setEndDate(date);
                                                }} className="w-100 textField"
                                     />
                                     </span>
                                </span>
                            </div>
                        </div>
                        <div id={clickLoading ? "cover-spin" : ""}>
                        <table className="mainTable">
                            <colgroup>
                                <col width="50%"/>
                                <col width="15%"/>
                                <col width="15%"/>
                                <col width="20%"/>
                            </colgroup>
                            <thead className="tableHead">
                            <tr>
                                <th>제목</th>
                                <th>조회 수</th>
                                <th>링크 클릭 수</th>
                                <th>링크 클릭(중복 제거)</th>
                            </tr>
                            </thead>
                            <tbody className="tableBody">
                            <tr>
                                <td>
                                    {!clickLoading ? bannerDetail.title : ''}
                                </td>
                                <td>
                                    {clickData.readView}
                                </td>
                                <td>
                                    {clickData.linkClick}
                                </td>
                                <td>
                                    {clickData.linkClickDistinct}
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        </div>
                    </div>
                </div>


                <div className="w-30 h-100">
                    <div className="flex">
                        <p className="body_content_subtitle">내용</p>
                        {
                            bannerDetail.contents ?
                                (
                                    <button
                                        className="defaultBtn bgC-eb878c borderC-eb878c w-90px h-30px mr-20 mt-auto mb-auto ml-auto"
                                        onClick={goModifypage}
                                    >수정</button>
                                ) : ""
                        }

                    </div>
                    <div className="w-100 h-100">
                        <table className="mainTable">
                            <thead className="tableHead">
                            <tr>
                                <th>내용</th>
                            </tr>
                            </thead>
                            <tbody className="tableBody">
                            {
                                bannerDetail.contents ?
                                    (
                                        <Fragment>
                                            <tr>
                                                <td style={{padding: "10px", overflow: "unset", whiteSpace: "initial"}}>
                                                    <div className="overFlowYscrollView h-60vh">
                                                        <div
                                                            dangerouslySetInnerHTML={{__html: bannerDetail.contents}}></div>
                                                        {
                                                            bannerDetail.imageDataUrl &&
                                                            <div className="mt-10">
                                                                <img src={bannerDetail.imageDataUrl} alt="complex=Img"
                                                                     width="300px"/>
                                                            </div>
                                                        }
                                                    </div>
                                                </td>
                                            </tr>
                                        </Fragment>
                                    ) : (
                                        <tr>
                                            <td>조회 된 데이터가 없습니다.</td>
                                        </tr>
                                    )
                            }

                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
}



