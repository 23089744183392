import React, {useEffect, useState} from "react";
import classNames from "classnames";
import Net from 'scripts/net';
import {CustomTable, SearchInputBar} from 'components/molecules';

export const ApplianceUserDeviceModal = (props) => {
    const modalColumnList = [
        {
            code: 'deviceId',
            title: '디바이스 ID',
            width: '15',
        },
        {
            code: 'serialNumber',
            title: 'serialNumber',
            width: '10',
        },
        {
            code: 'appliances',
            title: '서비스',
            width: '10',
        },
        {
            code: 'modelCode',
            title: '모델 코드',
            width: '10',
        },
        {
            code: 'type',
            title: '모델 타입',
            width: '10',
        },
    ];

    const [propertyList, setpropertyList] = useState([]);

    const [pageState, setPageState] = useState({
        currentPage: 1,
        rowPerPage: 10,
        totalElementsCnt: 0,
        drawElementsCnt: 0,
        searchText: '',
    });

    const [loading, setLoading] = useState(false);

    useEffect(() => {
        loadData();
    }, [pageState.currentPage, pageState.searchText]);

    const loadData = () => {
        setLoading(true);
        Net.getLgApplianceUserDeviceList(
            {
                currentPage: pageState.currentPage-1,
                rowPerPage: pageState.rowPerPage,
                username: props.propsData.username,
                searchText: pageState.searchText,
            }, (response) => {
                setLoading(false);
                if (response.status === 200) {
                    setpropertyList(response.data.content);
                    setPageState({
                        ...pageState,
                        totalElementsCnt: response.data.totalElements,
                        drawElementsCnt: response.data.numberOfElements,
                    });
                } else {
                    setpropertyList([]);
                    setPageState({
                        ...pageState,
                        totalElementsCnt: 0,
                        drawElementsCnt: 0,
                    });
                }
            });
    }

    const onMovePage = (e) => {
        setPageState({
            ...pageState,
            currentPage: e
        });
    }

    const onSubmitHandler = (e) => {
            setPageState({
                ...pageState,
                currentPage: 1,
                searchText: e.searchText,
            })
    }


    return (
        <div>
            <SearchInputBar
                className="w-100 display_table mb-2"
                searchText={true}
                searchPlaceHolder="Device ID 입력하세요."
                searchBtn={true}
                searchBtnTitle="검색"
                dynamicSearch={false}
                onSubmit={onSubmitHandler}
            />
            <p className={classNames("totalCnt")} style={{"marginTop": "0"}}>Total : {pageState.totalElementsCnt}</p>
            <CustomTable
                className={"w-100 h-57vh overflowYscroll"}
                columnList={modalColumnList}
                contentData={propertyList}
                checkbox={false}
                paging={true}
                pagination={onMovePage}
                totalElementsCnt={pageState.totalElementsCnt}
                rowPerPage={pageState.rowPerPage}
                currentPage={pageState.currentPage - 1}
                drawElementsCnt={pageState.drawElementsCnt}
                loading={loading}
            />
        </div>
    )
}
