export const columnList = [
    {
        code: 'homenet',
        name: '홈넷인증',
        user: '사용자',
      //  device: '디바이스',
    },
    {
        code: 'authentication',
        name: '인증사용자',
        user: '사용자',
        //device: '디바이스',
    },
    {
        code: 'status',
        name: '상태',
        user: '사용자',
        device: '디바이스',
    },
    {
        code: 'skt',
        name: 'SKT',
        user: '사용자',
        device: '디바이스',
    },
    {
        code: 'kt',
        name: 'KT',
        user: '사용자',
        device: '디바이스',
    },
    {
        code: 'lgu',
        name: 'LGU+',
        user: '사용자',
        device: '디바이스',
    },
    {
        code: 'lg',
        name: 'LG가전',
        user: '사용자',
        device: '디바이스',
    },
    {
        code: 'samsung',
        name: '삼성전자',
        user: '사용자',
        device: '디바이스',
    },
    {
        code: 'google',
        name: '구글',
        user: '사용자'
    },
];

export const managerColumnList = [
    {
        code: 'authentication',
        name: '연동 사용자',
        user: '사용자',
        device: '디바이스',
    },
    {
        code: 'skt',
        name: 'SKT',
        user: '사용자',
        device: '디바이스',
    },
    {
        code: 'kt',
        name: 'KT',
        user: '사용자',
        device: '디바이스',
    },
    {
        code: 'lgu',
        name: 'LGU+',
        user: '사용자',
        device: '디바이스',
    },
    {
        code: 'lg',
        name: 'LG가전',
        user: '사용자',
        device: '디바이스',
    },
    {
        code: 'samsung',
        name: '삼성전자',
        user: '사용자',
        device: '디바이스',
    },
];

