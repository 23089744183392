import React, {useState, useEffect} from 'react'
import './FileUpload.scss';
import 'react-medium-image-zoom/dist/styles.css';

export const FileUpload = (props) => {
    const {title, inputName, disabled, removeHandler, fileUpload } = props;
    const [fileName, setFileName] = useState("");
    const [prevImgUrl, setPrevImgUrl] = useState("");

    useEffect(() => {
        //setPrevImgUrl(props.imgFile);
        console.log(props.fileName);
        if(fileName !== props.fileName){
            setFileName(props.fileName);
        }
    }, [props.fileName])

    const onChangeImg = (e) => {
        e.preventDefault();

        let reader = new FileReader();
        let file = e.target.files[0];

        console.log(reader);

        if(file.size > 10150000) {
            alert('사이즈가 10 MB를 넘습니다.');
            return;

        }

        reader.onloadend = () => {
            props.fileUpload(file, file.name);
            setFileName(file.name);
        }
        reader.readAsDataURL(file);
    }

    return (
        <>
        <span className="UploadTitle">{title} 첨부</span>
            {
                removeHandler && <>
                    <span className="floatR">
                        <button
                            className= "w-90px h-25px cancelBtn mr-2"
                            onClick={removeHandler}
                        >이미지 삭제</button>
                    </span>
                </>
            }
         <div className="imgUploadWrapper ms-1 mt-2">
            <div className="flex fileArea">
                <span className="imgUploadTitle">파일</span>
                <input className="upload-name" id="file" value={fileName} disabled="disabled"/>
                <label htmlFor={inputName ? inputName : "ex_filename"}>찾아보기</label>
                <input
                    type="file"
                    id={inputName ? inputName : "ex_filename"}
                    className="upload-hidden"
                    onChange={onChangeImg}
                    accept=".pdf,.jpeg,.png,.jpg,.zip,.pptx,xlsx"
                    disabled={disabled}
                />
            </div>
             {/*<div*/}
             {/*    style={{*/}
             {/*        width: 400,*/}
             {/*        height: 400,*/}
             {/*        border: '2px dashed black',*/}
             {/*        borderRadius: 5,*/}
             {/*        display: 'flex',*/}
             {/*        alignItems: 'center',*/}
             {/*        justifyContent: 'center',*/}
             {/*        flexDirection: 'column',*/}
             {/*    }}*/}
             {/*    onDragOver={(event) => event.preventDefault()}*/}
             {/*    onDrop={onChangeImg}*/}
             {/*>*/}

             {/*</div>*/}
            <div className="flex mt-1 ms-3 me-1 mb-2 justify-content-end">
                <span className="imgUploadNotice">※지원하는 파일 형식: <a style={{color:'green'}}>pdf, xlsx, pptx, word </a>/ 용량: <a style={{color:'green'}}>10MB </a> 이내</span>
            </div>
        </div>
        </>
    )
}
