import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { CustomTable, SearchInputBar } from 'components/molecules';
import store from 'scripts/store';
import Net from 'scripts/net';
import Common from 'scripts/common';
import {usePageAuth , useDidMountEffect} from "scripts/hooks";

const columnList = [
    {
        code: 'code',
        title: '',
        width: '',
        hidden: true,
    },
    {
        code: 'site.name',
        title: '현장명',
        width: '15',
    },
    {
        code: 'freePassEmail',
        title: 'FreePass 이메일',
        width: '15',
    },

    {
        code: 'freePassAccessToken',
        title: 'AccessToken',
        width: '15',
    },
    {
        code: 'freePassMessage',
        title: 'FreePass Message',
        width: '8',
    },
    {
        code: 'autoParking',
        title: 'autoParking 여부',
        width: '10',
    },

];

export const FreePassSiteList = (props) => {

    // useHistory 선언
    const history = useHistory();
    const user = props.userInfo;
    const [contentData, setContentData] = useState([]);
    const [checkedList, setCheckedList] = useState([]);
    const [searchCategoryGroup, setSearchCategoryGroup] = useState([
        [
            [
                {code: '', name: '고객사 선택'}
            ],
            [
                {code: '', name: '현장 선택'}
            ]
        ],
    ]);

    const [siteDetail, setSiteDetail] = useState({});

    const [pageState, setPageState] = useState({
        totalElementsCnt: 0,
        drawElementsCnt: 0,

    });

    const [searchState, setSearchState] = useState({
        currentPage: 1,
        rowPerPage: 13,
        customerCode: user.menuGroup === 'ADMIN' ? '' : user.customer && user.customer.code,
        siteCode: user.menuGroup === 'OPERATOR' ? user.site && user.site.name: '',
        searchText : '',
    })
    const [loading, setLoading] =useState(false);

    const programAuth = usePageAuth(props.userInfo.authList[0], sessionStorage.getItem('menuId'));


    useEffect(() => {
        store.openedPage = 'FreePass 현장 목록';
        getCategoryList();
    }, []);

    useEffect(() => {
        loadData();
    }, [searchState.currentPage,
        searchState.siteCode,
        searchState.searchText]);

    const getCategoryList = () => {
        if (user.menuGroup === 'ADMIN') {
            Common.getCustomerList((response1) => {
                Common.getSitesList(null, (response2) => {
                    setSearchCategoryGroup([response1, response2]);
                });
            });
        } else if (user.menuGroup === 'MANAGER') {
            Common.getSitesList(searchState.customerCode, (response2) => {
                setSearchCategoryGroup([response2]);
            });
        }
    }

    const getRelateCategory = (selectedCategory) => {
        if (selectedCategory === "reset") selectedCategory = "";
        Common.getSitesList(selectedCategory, (response2) => {
            setSearchCategoryGroup([
                searchCategoryGroup[0],
                response2
            ]);
        });
    }


    const loadData = () => {
        setLoading(true);
        Net.getFreePasssitesList(searchState, (response) => {
            setLoading(false);
            if(response.status === 200) {
                console.log(response)
                setContentData(response.data.content);
                setPageState({
                    totalElementsCnt: response.data.totalElements,
                    drawElementsCnt: response.data.numberOfElements,
                });

            } else if(response.status === 204) {
                setContentData([]);
                setPageState({
                    totalElementsCnt: 0,
                    drawElementsCnt: 0,
                });
            }
        });
    }


    const onMovePage = (e) => {
        setSearchState({
            ...searchState,
            currentPage: e
        });
    }

    const onSubmitHandler = (e) => {
        if (user.menuGroup === 'ADMIN') {
            setSearchState({
                ...searchState,
                customerCode: e.selectedCategory[0],
                siteCode: e.selectedCategory[1],
                searchText: pageState.searchText !== e.searchText ? e.searchText : ''
            });

        } else if (user.menuGroup === 'MANAGER') {
            setSearchState({
                ...searchState,
                customerCode: pageState.customerCode,
                siteCode: e.selectedCategory[0],
                searchText: pageState.searchText !== e.searchText ? e.searchText : ''
            });
        } else {
            setSearchState({
                ...searchState,
                customerCode: pageState.customerCode,
                siteCode: pageState.siteCode,
                searchText: pageState.searchText !== e.searchText ? e.searchText : ''
            });
        }
    }

    const addList = () => {
        history.push('/freepass/site/regist');
    }

    const removeList = () => {
        const result = window.prompt('관리자 아이디 : '+siteDetail.freePassEmail +
            '\n현장 코드 : '+siteDetail.site.code+'\n비밀번호를 입력해주세요');
        if(result !== undefined) {
            Net.removeFreePassSitesList(siteDetail, result, (response) => {
                if(response.status === 200) {
                    loadData();
                } else if(response.status === 400){
                    alert('비밀번호를 확인해주세요.');
                }
            });
        }
    }

    const onClickRow = (e) => {
       setSiteDetail(e);
    }

    return (
        <div className="w-100 h-100">
            <div className="w-100 h-7 display_table">
                <SearchInputBar
                    className="w-100"
                    searchCategory={user.menuGroup === 'ADMIN' || user.menuGroup === 'MANAGER'}
                    searchCategoryGroup={searchCategoryGroup}
                    searchText={true}
                    searchPlaceHolder="이메일을 입력해주세요."
                    searchBtn={true}
                    searchBtnTitle="검색"
                    dynamicSearch={false}
                    onSubmit={onSubmitHandler}
                    relateCategoryHandler={user.menuGroup === 'ADMIN' && getRelateCategory}
                    refreshBtn={true}
                    refreshHandler={loadData}
                />
                {
                    programAuth.saveAuth &&
                    <div className="display_table_cell v-middle">
                        <button
                            className="addBtn w-90px h-30px floatR mr-20"
                            onClick={addList}
                        >등록</button>
                    </div>
                }
            </div>
            <div className="w-100 h-1px bgC-efefef" />
            <div className="flex h-93">
                <div className="w-100 h-100 overflowYscroll">
                    <p className="totalCnt">Total {pageState.totalElementsCnt}</p>
                    {
                        programAuth.deleteAuth && Object.keys(siteDetail).length !== 0 &&
                        <button
                            className="removeBtn w-90px h-30px floatR mt-7 mr-20"
                            onClick={removeList}
                        >삭제</button>
                    }
                    <CustomTable
                        columnList = {columnList}
                        contentData = {contentData}
                        paging = {true}
                        totalElementsCnt = {pageState.totalElementsCnt}
                        rowPerPage = {searchState.rowPerPage}
                        currentPage = {searchState.currentPage - 1}
                        drawElementsCnt = {pageState.drawElementsCnt}
                        pagination = {onMovePage}
                        rowClickEvt = {true}
                        onClickRow={onClickRow}
                        loading={loading}
                        // sort = {true}
                    />
                </div>
            </div>
        </div>
    )
}
