import React, {Fragment, useEffect, useState} from "react";
import {CustomTable, SearchInputBar, BasicModal} from 'components/molecules';
import Net from 'scripts/net';
import Common from 'scripts/common';


export const TestRecordList = (props) => {
    const modalColumnList = [
        {
            code : 'no',
            title: 'No',
            width: '5',
        },
        {
            code : 'createdDatetime',
            title: '일시',
            width: '10',
        },
        {
            code : 'performer',
            title: '수행자',
            width: '6',
        },
        {
            code : 'note',
            title: '비고',
            width: '20',
        },
        {
            code : 'check',
            title: '성공여부',
            width: '7',
        },
        {
            code : 'removeData',
            title: '삭제',
            width: '7',
            tdClickEvent: true,
        },
    ];

    const deviceColumnList = [
        {
            code : 'deviceId',
            title: '디바이스ID',
            width: '10',
        },
        {
            code : 'name',
            title: '장비명',
            width: '6',
        },
        {
            code : 'note',
            title: '비고',
            width: '20',
        },
    ];

    const [recordList, setRecordList] = useState([]);
    const [deviceList, setDeviceList] = useState([]);
    const [recordDetail, setRecordDetail] = useState('');

    useEffect( ()=>{
        {/*
          1. 테스트 기록 목록 조회
        */}
        loadData();
    },[])

    const loadData = () => {
        Net.getHoListForCheckListBySiteAndDongAsync(props.data.siteCode, props.data.dong, props.data.ho).then( response => {
            if(response.data[0].hasOwnProperty('placeCheck')){
                setRecordList(response.data[0].placeCheck);
            }
        });
    }

    const tdOnClickEvent = async (title, e) => {
        const result = window.confirm('삭제 하시겠습니까?');
        if (result) {
            const response = await Net.deleteCheckListById(e.id);
            await loadData();
            setDeviceList([]);
            setRecordDetail([]);
        }
    }

    const onClickRow = (e) => {
        setDeviceList(e.devices);
        setRecordDetail(e.note);
    }

    const removeRecordList = async () =>{
        const result = window.confirm('삭제 하시겠습니까?');
        if (result) {
            await recordList.forEach(record => {
                try{
                    const response = Net.deleteCheckListById(record.id);
                }catch (error){
                    console.log(error);
                }
            })
            await loadData();
            setDeviceList([]);
            setRecordDetail([]);
        }
    }

    return (
        <div className="overflow-hidden">
            <h5 className="m-3 ml-50">{props.data.dong}동 {props.data.ho}호 테스트 기록</h5>
            <div className="row">
                <div className="col-4 ml-20">
                    <h5> 테스트 목록 </h5>
                </div>
                <div className="col-2">
                    <button className="cancelBtn col-10 mb-10" onClick={removeRecordList}>전체 기록 삭제</button>
                </div>
                <div className="col-1"></div>
                <div className="col-2">
                    <h5 className=""> 디바이스 목록 </h5>
                </div>

            </div>
            <div className="flex justify-content-between">
                <div className="col-6">
                    <CustomTable
                        columnList={modalColumnList}
                        contentData={recordList}
                        totalElementsCnt={recordList.length}
                        rowPerPage={999999}
                        currentPage={0}
                        tdClickEvt={tdOnClickEvent}
                        rowClickEvt={true}
                        onClickRow={onClickRow}
                    />
                </div>
                <div className="col-5 ml-3 w-40">
                    <div  className="overflow-hidden h-23vh">
                        <CustomTable
                            className="w-100 h-20vh overflowYscroll"
                            columnList={deviceColumnList}
                            contentData={deviceList}
                            totalElementsCnt={deviceList.length}
                            rowPerPage={999999}
                            currentPage={0}
                            tdClickEvt={tdOnClickEvent}
                        />
                    </div>
                        <h5 className="m-2"> 상세 비고 내용 </h5>
                        <table className="mainTable">
                            <thead className="tableHead">
                            <tr>
                                <th>내용</th>
                            </tr>
                            </thead>
                            <tbody className="tableBody">
                            {
                                recordDetail !== '' ?
                                    (
                                        <Fragment>
                                            <tr>
                                                <td style={{padding: "10px", overflow: "unset", whiteSpace: "initial"}}>
                                                    <div className="overFlowYscrollView h-20vh w-90">
                                                        <div dangerouslySetInnerHTML={{__html: recordDetail}}/>
                                                    </div>
                                                </td>
                                            </tr>
                                        </Fragment>
                                    ) : (
                                        <tr>
                                            <td>조회 된 데이터가 없습니다.</td>
                                        </tr>
                                    )
                            }
                            </tbody>
                        </table>
                </div>
            </div>

        </div>
    )
}
