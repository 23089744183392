/* eslint-disable no-lone-blocks */
import React, {useEffect, useState} from 'react';
import Net from 'scripts/net';
import Common from 'scripts/common';
import { CustomTextField, CustomSelectBox, BasicModal   } from 'components/molecules';
import {useDidMountEffect} from "scripts/hooks/useDidMountEffect";
export const HomenetAuth = (props) => {

    const [form, setForm] = useState({
        site: {
            code:'',
            name:'',
        },
        code : '',
        name : '',
        region: {
            code: '',
            name: '',
        },
        dong : '',
        ho : '',
        homenetUsername: '',
        homenetPassword:'',
        username: '',
        tempUserPrefix : '',
    });

    const [regionList, setRegionList] = useState([{
        code: '',
        name: '지역명 선택'
    }]);

    const [siteList, setSiteList] = useState([{
        code: '',
        name: '현장명 선택'
    }])

    useEffect(()=>{
        {/*
            1. 홈넷 인증 모달 창
            2. 지역 데이터 불러오기
            3. props 기반으로 region, siteCode, dong, ho, HomenetUsername, HomenetPassword 데이터 입력
            4. 순차적으로 async await 사용하기.
        */}

        Common.getRegionList((response) => {
            setRegionList(response);
        });

        Common.getTotalSitesList((response)=> {
            setSiteList(response);
        });

    },[]);

    useDidMountEffect(()=>{
        let homenetusername = '';
        let homenetpassword = '';
        let username = '';

        if(props.data.siteCode !== undefined){
            if(props.data.siteCode === '220221'){
                Net.getSiteOne(props.data.siteCode, (responseSite) => {
                    if(responseSite.status === 200 ){
                        if(props.data){
                            homenetusername = props.data.siteCode+'u'+props.data.dong+props.data.ho;
                            homenetpassword = 'cv83695*'
                            username = props.data.siteCode+'@'+props.data.dong+'.'+props.data.ho;
                        }
                        setForm({
                            ...form,
                            dong:props.data.dong,
                            ho: props.data.ho,
                            site: {
                                code : props.data.siteCode,
                                name : responseSite.data.name
                            },
                            region: responseSite.data.region,
                            homenetUsername : homenetusername,
                            homenetPassword : homenetpassword,
                            username : username,
                        })
                    }
                })
            }else{
                Net.getSiteOne(props.data.siteCode, (responseSite) => {
                    if(responseSite.status === 200 ){
                        if(props.data){
                            homenetusername = props.data.siteCode+'u'+props.data.dong+props.data.ho;
                            homenetpassword = 'cv83695*'
                            username = responseSite.data.tempUserPrefix+props.data.dong+props.data.ho;
                        }
                        setForm({
                            ...form,
                            dong:props.data.dong,
                            ho: props.data.ho,
                            site: {
                                code : props.data.siteCode,
                                name : responseSite.data.name
                            },
                            region: responseSite.data.region,
                            homenetUsername : homenetusername,
                            homenetPassword : homenetpassword,
                            username : username,
                            tempUserPrefix : responseSite.data.tempUserPrefix
                        })
                    }
                })
            }
        }
    },[regionList]);


    const selectBoxHandler = (name, value) => {
        if(name === 'regionCode'){
            setForm({
                ...form,
                region: {
                    code: value.code,
                    name: value.name
                }
            })
        } else if( name === 'siteCode'){
            setForm({
                ...form,
                site: {
                    code: value.code,
                    name: value.name,
                }
            })
        }
    }

    const textFieldHandler = (name, value) => {
        const nextForm = {
            ...form,
            [name] : value
        }
        setForm(nextForm);
    }


    const onSubmitHandler = async () => {
        let result = window.confirm('홈넷 인증을 진행하시겠습니까?');
        if(result){
            {/* 홈넷 인증 */}
            Net.checkUsername(form.username, async response1 => {
                if(response1.status === 200){
                    alert('IoK 아이디를 확인해주세요. 등록되지 않은 아이디 입니다.');
                } else if (response1.status === 409 ){
                    const response = await Net.HomenetAuthorization(form);
                    if(response.status === 200 ){
                        props.modalClose(false);
                        props.reload('reload');
                    }

                }

            })

        }
    }

    return (
        <div className="w-100 h-100">
            <div className='w-100 h-1px bgC-efefef'/>
            <div className='h-93'>
                <div className='row flex h-90 justify-content-center'>
                    <div className="flex mt-3 col-11">
                        ※ 임시 계정용 데이터가 들어가 있습니다. 바로 인증버튼을 눌러주세요.
                    </div>
                    <div className="flex mb-12 col-11">
                            <label className="label_title">지역코드 *</label>
                            <CustomSelectBox
                                    name="region.code"
                                    categoryList={regionList}
                                    onChangeHandler={(value, name) => selectBoxHandler('regionCode', {code: value, name: name})}
                                    callbackCodeName="Y"
                                    value={form.region.code}
                                    disabled={true}

                            />
                    </div>
                    <div className="flex mb-12 col-11">
                            <label className="label_title">현장코드 *</label>
                            <CustomSelectBox
                                    name="site.code"
                                    categoryList={siteList}
                                    onChangeHandler={(value, name) => selectBoxHandler('siteCode', {code: value, name: name})}
                                    callbackCodeName="Y"
                                    value={form.site.code}
                                    disabled={true}
                            />
                    </div>
                    <div className='flex mb-12 col-11'>
                        <CustomTextField
                            className="h-30px"
                            title="동"
                            name="dong"
                            required={true}
                            customOnChange={textFieldHandler}
                            value={form}
                            disabled={true}
                            />
                    </div>
                    <div className='flex mb-12 col-11'>
                        <CustomTextField
                            className="h-30px"
                            title="호"
                            name="ho"
                            required={true}
                            customOnChange={textFieldHandler}
                            value={form}
                            disabled={true}
                            />
                    </div>
                    <div className='flex mb-12 col-11'>
                        <CustomTextField
                            className="h-30px"
                            title="IoK ID"
                            name="username"
                            required={true}
                            customOnChange={textFieldHandler}
                            value={form}
                            disabled={false}
                            />
                    </div>
                    <div className='flex mb-12 col-11'>
                        <CustomTextField
                            className="h-30px"
                            title="홈넷 ID"
                            name="homenetUsername"
                            required={true}
                            customOnChange={textFieldHandler}
                            value={form}
                            disabled={false}
                            />
                    </div>
                    <div className='flex mb-12 col-11'>
                        <CustomTextField
                            className="h-30px"
                            title="홈넷 Password"
                            name="homenetPassword"
                            required={true}
                            customOnChange={textFieldHandler}
                            value={form}
                            disabled={false}
                            type = {"password"}
                            />
                    </div>
                </div>
                <div className="footer_btn h-10 mt-1">
                <div className="">
                    <button className="w-160px h-30px addBtn mr-10 ml-10" onClick={onSubmitHandler}>인증</button>
                    <button className="w-160px h-30px cancelBtn mr-10" onClick={()=>props.modalClose(false)}>취소</button>
                </div>
            </div>
            </div>
        </div>
    )

}
