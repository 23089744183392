import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { CustomTable, SearchInputBar } from 'components/molecules';
import store from 'scripts/store';
import Net from 'scripts/net';

const columnList = [
    {
        code: 'policyId',
        title: '',
        width: '',
        hidden: true,
    },
    {
        // code: 'classification',
        code: 'classificationName',
        title: '구분',
        width: '20',
    },
    {
        code: 'version',
        title: '버전',
        width: '20',
    },
    {
        code: 'title',
        title: '제목',
        width: '20',
    },
    {
        code: 'customer.name',
        title: '고객사명',
        width: '20',
    },
    {
        code: 'usable',
        title: '사용 여부',
        width: '18',
    },
];

export const PolicyManageList = (props) => {

    // useHistory 선언
    const history = useHistory();

    const [contentData, setContentData] = useState([]);
    const [checkedList, setCheckedList] = useState([]);
    const [searchCategoryGroup, setSearchCategoryGroup] = useState([
        [
            [
                {code: '', name: '고객사명 선택'}
            ]
        ],
    ]);

    const [pageState, setPageState] = useState({
        currentPage: 0,
        rowPerPage: 13,
        totalElementsCnt: 0,
        drawElementsCnt: 0,
        customer: {
            code: ''
        },
    });

    const [programAuth, setProgramAuth] = useState({});

    useEffect(() => {
        store.openedPage = '개인정보동의 목록';
        getCustomerList((response) => {
            setSearchCategoryGroup([response,]);
        });
        pageAuthInfo();
    }, []);

    useEffect(() => {
        loadData();
    }, [pageState.currentPage, pageState.customer.code]);

    const pageAuthInfo = () => {
        const authGroupCode = props.userInfo.authList[0];
        const menuId = sessionStorage.getItem('menuId');

        Net.getAuthGroupMenuList(menuId, (response) => {
            if(response.status === 200) {
                response.data.map(item => {
                    if(item.authGroup === authGroupCode && item.authGroupMenu) {
                        setProgramAuth({
                            searchAuth: Boolean(item.authGroupMenu.searchAuth),
                            saveAuth: Boolean(item.authGroupMenu.saveAuth),
                            excelAuth: Boolean(item.authGroupMenu.excelAuth),
                            deleteAuth: Boolean(item.authGroupMenu.deleteAuth),
                        });
                    }
                });
            }
        });
    }

    const loadData = () => {
        Net.getPolicyList(pageState, (response) => {
            if(response.status === 200) {
                getClassificationList((responseList) => {
                    response.data.content.map((data) => {
                        const classificationName = responseList[data.classification];
                        data.classificationName = classificationName;
                        if(data.usable === "true"){
                            data.usable = true;
                        }else{
                            data.usable = false;
                        }
                    });

                    setContentData(response.data.content);
                });

                setPageState({
                    ...pageState,
                    totalElementsCnt: response.data.totalElements,
                    drawElementsCnt: response.data.numberOfElements,
                });
            } else if(response.status === 204) {
                setContentData([]);
                setPageState({
                    ...pageState,
                    totalElementsCnt: 0,
                    drawElementsCnt: 0,
                });
            }
        });
    }

    const getCustomerList = (callback) => {
        Net.getCustomerList(
            {currentPage: '', rowPerPage: '', searchText: ''}, (response) => {
            if(response.status === 200) {
                let newArray = searchCategoryGroup[0][0];
                response.data.content.map(items => {
                    newArray = [
                        ...newArray,
                        {
                            code: items.code,
                            name: items.name
                        }
                    ]
                });
                callback(newArray);
            }
        });
    }

    const getClassificationList = (callback) => {
        Net.getCodeList('POLICY', (response) => {
            if(response.status === 200) {
                let obj = {};
                response.data.map(items => {
                    obj[items.code] = items.name
                });
                callback(obj);
            }
        });
    }

    const onMovePage = (e) => {
        setPageState({
            ...pageState,
            currentPage: e-1
        });
    }

    const onSubmitHandler = (e) => {
        setPageState({
            ...pageState,
            currentPage: 0,
            customer: {
                code: e.selectedCategory[0],
            },
        });
    }

    const addList = () => {
        history.push('/setting/policy/regist');
    }

    const onClickRow = (e) => {
        history.push(`/setting/policy/regist/${e.policyId}`);
    }

    const removeList = () => {
        const result = window.confirm('삭제하시겠습니까?');
        const removeCodeList = [];
        checkedList.map((id) => {
            const node = document.querySelector(`#${id}`);
            const nodeTr = node.closest('tr');
            const codeValue = nodeTr.querySelector('td[name=policyId]').textContent;
            removeCodeList.push(codeValue);
        });

        if(result) {
            Net.removePolicyList(removeCodeList, (response) => {
                if(response) {
                    loadData();
                }
            });
        }
    }

    return (
        <div className="w-100 h-100">
            <div className="w-100 h-7 display_table">
                <SearchInputBar
                    className="w-100"
                    searchCategory={true}
                    searchCategoryGroup={searchCategoryGroup}
                    searchText={false}
                    searchBtn={true}
                    searchBtnTitle="검색"
                    dynamicSearch={false}
                    onSubmit={onSubmitHandler}
                />
                {
                    programAuth.saveAuth &&
                    <div className="display_table_cell v-middle">
                        <button
                            className="addBtn w-90px h-30px floatR mr-20"
                            onClick={addList}
                        >등록</button>
                    </div>
                }
            </div>
            <div className="w-100 h-1px bgC-efefef" />
            <div className="flex">
                <div className="w-100 h-93 overflowYscroll">
                    <p className="totalCnt">Total {pageState.totalElementsCnt}</p>
                    {
                        programAuth.deleteAuth && checkedList.length > 0 &&
                        <button
                            className="removeBtn w-90px h-30px floatR mt-7 mr-20"
                            onClick={removeList}
                        >삭제</button>
                    }
                    <CustomTable
                        columnList = {columnList}
                        contentData = {contentData}
                        checkbox = {programAuth.deleteAuth}
                        checkedList = {(checkedList) => {setCheckedList(checkedList)} }
                        paging = {true}
                        totalElementsCnt = {pageState.totalElementsCnt}
                        rowPerPage = {pageState.rowPerPage}
                        currentPage = {pageState.currentPage}
                        drawElementsCnt = {pageState.drawElementsCnt}
                        pagination = {onMovePage}
                        rowClickEvt = {true}
                        onClickRow = {onClickRow}
                        // sort = {true}
                    />
                </div>
            </div>
        </div>
    )
}
