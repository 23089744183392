import React, { useState, useEffect, Fragment } from 'react'
import './CustomMultiSelectBox.scss';

const CustomMultiSelectBox = (props) => {
    const { categoryList, onChangeHandler, disabled, value } = props;
    const [selectedOptions, setSelectedOptions] = useState([]);

    useEffect(() => {
        // value가 변경되면 selectedOptions 업데이트
        console.log(value);
        if (Array.isArray(value)) {
            setSelectedOptions(value.filter((item) => item.code !== '').map((item) => ({ code: item.code, name: item.name })));
        } else {
            setSelectedOptions([]);
        }
    }, [value]);

    useEffect(() => {
        // 부모 컴포넌트로 선택된 값들을 전달
        onChangeHandler([...selectedOptions]); // 레퍼런스 변경을 유도
    }, [selectedOptions, onChangeHandler]);

    const handleOptionClick = (code, name) => {
        if (!disabled && code !== '') {
            const isSelected = selectedOptions.some((option) => option.code === code.code);

            // 이미 선택된 항목이면 제거, 아니면 추가
            if (isSelected) {
                setSelectedOptions(selectedOptions.filter((option) => option.code !== code.code));
            } else {
                setSelectedOptions([...selectedOptions, { code: code.code, name }]);
            }
        }
    };

    return (
        <div className={`multi-select-container ${disabled ? 'disabled' : ''}`}>
            <div className="custom-select">
                {categoryList.map((option) => {
                    // code가 ''이면 렌더링하지 않음
                    if (option.code === '') {
                        return null;
                    }

                    return (
                        <div
                            key={option.code}
                            className={`custom-option ${disabled ? 'disabled' : ''} ${selectedOptions.some((selected) => selected.code === option.code) ? 'selected' : ''}`}
                            onClick={() => !disabled && handleOptionClick(option, option.name)}
                        >
                            <input
                                type="checkbox"
                                checked={selectedOptions.some((selected) => selected.code === option.code)}
                                readOnly
                                className="checkbox-input"
                                disabled={disabled} // 비활성화된 상태에 따라 체크박스 비활성화
                            />
                            <label>{option.name}</label>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default CustomMultiSelectBox;