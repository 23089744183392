import React from 'react'
import { Switch, Route } from 'react-router-dom'
import {HomenetHealthMonitoring} from "./HomenetHealthMonitoring";
import {ServiceMonitoring} from "./ServiceMonitoring";
import {SiteUserMonitoring} from "./SiteUserMonitoring";
import {UserAndDeviceMonitoring} from "./UserAndDeviceMonitoring";
import {DeviceStatusMonitoring} from "./DeviceStatusMonitoring";
import {AutomationTest, AutomationTestRegist} from "./AutomationTest";
import {DuplicatePlaceMonitoring} from "./DuplicatePlaceMonitoring";
import {HomenetAuthMonitoring} from "./HomenetAuthMonitoring";
import {MonthlyUserMonitoring} from "./MonthlyUserMonitoring";
import NotFound from "../../components/templates/NotFound";
import {DailyUserStatus} from "./DailyUserStatus";
import {UncertifiedUser} from "./UncertifiedUser";
import {CallbackMonitoring} from "./CallbackMonitoring";
import {ExhaustiveTest} from "./exhaustiveTest";
import {ExhaustiveTestMonitoring} from "./ExhaustiveTestMonitoring";
import {
        DeviceUsedStatus,
        DeviceUsedStatusForExcel,
        UsedStatusByDevice,
        UsedStatusByHousehold,
        UsedStatusByTime
} from "./UsageStatus";
import {AutoExcelModify} from "./AutoExcelModify";


export const Monitoring = ({userInfo}) => {

    return (

        <Switch>
            <Route path={"/monitoring/homenet"} exact children={<HomenetHealthMonitoring userInfo={userInfo} />}/>
            <Route path={"/monitoring/service"} exact children={<ServiceMonitoring userInfo={userInfo} />}/>
            <Route path={"/monitoring/siteuser"} exact children={<SiteUserMonitoring userInfo={userInfo} />}/>
            <Route path={"/monitoring/devicestatus"} exact children={<DeviceStatusMonitoring userInfo={userInfo} />}/>
            <Route path={"/monitoring/automationtest"} exact children={<AutomationTest userInfo={userInfo} />}/>
            <Route path={"/monitoring/exhaustivetest"} exact children={<ExhaustiveTest userInfo={userInfo} />}/>
            <Route path={"/monitoring/exhaustivetestmonitoring"} exact children={<ExhaustiveTestMonitoring userInfo={userInfo} />}/>
            <Route path={"/monitoring/automationtest/regist"} exact children={<AutomationTestRegist/>} />
            <Route path={"/monitoring/duplicateplace"} exact children={<DuplicatePlaceMonitoring userInfo={userInfo} />}/>
            <Route path={"/monitoring/homenetauth"} exact children={<HomenetAuthMonitoring userInfo={userInfo} />}/>
            <Route path={"/monitoring/callback"} exact children={<CallbackMonitoring userInfo={userInfo} />}/>
            <Route path={"/monitoring/monthlyuser"} exact children={<MonthlyUserMonitoring userInfo={userInfo} />}/>
            <Route path={"/monitoring/userdevice"} exact children={<UserAndDeviceMonitoring userInfo={userInfo} />}/>
            <Route path={"/monitoring/dailyuser"} exact children={<DailyUserStatus userInfo={userInfo} />}/>
            <Route path={"/monitoring/uncertifieduser"} exact children={<UncertifiedUser userInfo={userInfo} />}/>
            <Route path={"/monitoring/usedstatus/used"} exact children={<DeviceUsedStatus userInfo={userInfo} />}/>
            <Route path={"/monitoring/usedstatus/usedforexcel"} exact children={<DeviceUsedStatusForExcel userInfo={userInfo} />}/>
            <Route path={"/monitoring/usedstatus/house"} exact children={<UsedStatusByHousehold userInfo={userInfo} />}/>
            <Route path={"/monitoring/usedstatus/device"} exact children={<UsedStatusByDevice userInfo={userInfo} />}/>
            <Route path={"/monitoring/usedstatus/time"} exact children={<UsedStatusByTime userInfo={userInfo} />}/>
            <Route path={"/monitoring/autoexcelmodify"} exact children={<AutoExcelModify/>}/>

            <Route path={"*"}>
                <NotFound />
            </Route>
        </Switch>

    )
}
