import React, {Fragment, useEffect, useState} from 'react';
import {useHistory, withRouter} from 'react-router-dom';
import {CustomTextField, CustomRadioButton, CustomSelectBox, BasicModal, CustomTable} from 'components/molecules';
import store from 'scripts/store';
import Net from 'scripts/net';
import Common from 'scripts/common';
import {toJS} from "mobx";
import {useStateWithCallbackLazy} from "use-state-with-callback";
import {SearchInputBar} from "../../../components/molecules";

const AutomationTestRegist = ({match}) => {

    const history = useHistory();
    const cloneUser = toJS(store.user)
    const [form, setForm] = useStateWithCallbackLazy({
        customer: {
            code: '',
            name: '',
        },
        site: {
            code: '',
            name: '',
        },
        dong: '',
        ho: '',
        startTime: '',
        onlyTemporary: true,
        name: '',
        description: '',
        deviceModel: '',
        attributeCode: '',
        attributeValue: '',
        attributeName: '',
    });

    const [customerList, setCustomerList] = useState([{
        code: '',
        name: '고객사명 선택'
    }]);
    const [siteList, setSiteList] = useState([{
        code: '',
        name: '현장명 선택'
    }]);

    const [modalState, setModalState] = useState({
        open: false,
        data: [],
        clickedBtnName: ''
    });
    const [automationAttributes, setAutomationAttributes] = useState([]);
    const [automationOperations, setAutomationOperations] = useState([]);
    const [valueData, setValueData] = useState([]);
    const [selectValue, setSelectValue] = useState([]);
    useEffect(() => {
        store.openedPage = '입사점 자동화 일괄 생성';
        // 고객사 전체 목록
        Common.getCustomerList((response) => {
            setCustomerList(response);
        });
    }, []);

    useEffect(() => {
        if (form.customer.code !== '') {
            Common.getSitesList(form.customer.code, (response) => {
                setSiteList(response);
            })
        } else {
            setSiteList([
                {
                    code: '',
                    name: '현장명 선택'
                }
            ])
        }
    }, [form.customer.code]);


    const radioBtnHandler = (name, value) => {
        let nextForm;
        nextForm = {
            ...form,
            [name]: value === 'true',
        }
        setForm(nextForm);
    }

    const textFieldHandler = (name, value) => {
        const nextForm = {
            ...form,
            [name]: value
        }
        setForm(nextForm);
    }

    const selectBoxHandler = (name, value) => {
        switch (name) {
            case 'customer.code':
                setForm({
                    ...form,
                    customer: {
                        code: value.code,
                        name: value.name
                    },
                    site: {
                        code: "",
                        name: ""
                    }
                });
                break;
            case 'site.code':
                setForm({
                    ...form,
                    site: {
                        code: value.code,
                        name: value.name
                    }
                });
                break;
            case 'selectValue.code':
                setForm({
                    ...form,
                    attributeValue: value.code
                });
                break;
            default:
                break;
        }
    }

    const onSubmitHandler = async () => {
        if (!form.customer.code) {
            alert('고객사를 선택해 주세요.');
            document.getElementsByName('customer.code')[0].focus();
            return false;
        }
        if (!form.site.code) {
            alert('현장명을 선택해 주세요.');
            document.getElementsByName('site.code')[0].focus();
            return false;
        }
        if(!form.deviceModel){
            alert('장비 모델을 선택해 주세요.')
            return false;
        }
        if(!form.attributeCode){
            alert('속성 코드를 선택해 주세요.')
            return false;
        }
        if(!form.attributeValue){
            alert('속성 값을 선택해 주세요.')
            return false;
        }

        var result = window.confirm('저장하시겠습니까?');
        if (result) {
            const response = await Net.addAutomationPost(form);
            if(response.status === 200 ){
                history.push('/monitoring/automationtest');
            }
        }
    }

    const onCancelHandler = () => {
        history.push('/monitoring/automationtest');
    }
    const onClickModalOpen = (type) => {
        setModalState({
            ...modalState,
            open: !modalState.open,
            clickedBtnName: type
        });
    }

    const sendData = (e, type) => {
        if(type === 'code') {
            setForm({
                ...form,
                deviceModel: e.deviceModel,
                attributeCode: e.attributeCode,
                attributeName: e.name,
            })
        }else if (type === 'data'){
            if(e.valueType === 'ENUMERATION'){
                let response = [{
                    code: '',
                    name: '속성값 선택'
                }];
                e.availableValues.forEach(item => {
                    response = [
                        ...response,
                        {
                            code: item,
                            name: item
                        }
                    ]
                });
                setSelectValue(response)

            } else {
                setSelectValue([e.bottomValue + '~'+ e.topValue]);
            }
            setValueData(e);
        }

        setModalState({
            ...modalState,
            open: !modalState.open,
            data: e,
        })
    }

    return (
        <div className="w-100 h-100">
            <BasicModal
                className="w-85 h-90"
                title={`장비 ${modalState.clickedBtnName} 선택`}
                content={
                    <ModalContent
                        sendData={sendData}
                        modalType={modalState.clickedBtnName}
                        propsData={modalState.propsData}
                        formData={form} modalState={modalState}
                        attributes={automationAttributes} operations={automationOperations}
                    />
                }
                modalOpen={modalState.open}
                modalClose={(e) => setModalState({
                    ...modalState,
                    open: e
                })}
                sendData={modalState.data}
            />
            <div className="w-100 h-7 display_table"/>
            <div className="w-100 h-1px bgC-efefef"/>
            <div className="h-93">
                <div className="h-90">
                    <div className="ml-55 mt-12">
                        <div className="row mb-3 w-90">
                            <label className="label_title">고객사 *</label>
                            <CustomSelectBox
                                name="customer.code"
                                categoryList={customerList}
                                value={form.customer.code}
                                onChangeHandler={(value, name) => selectBoxHandler('customer.code', {
                                    code: value,
                                    name: name
                                })}
                                callbackCodeName="Y"
                            />
                        </div>
                        <div className="row mb-3 w-90">
                            <label className="label_title">현장 *</label>
                            <CustomSelectBox
                                name="site.code"
                                categoryList={siteList}
                                value={form.site.code}
                                onChangeHandler={(value, name) => selectBoxHandler('site.code', {
                                    code: value,
                                    name: name
                                })}
                                callbackCodeName="Y"
                            />
                        </div>
                        <div className="row mb-12 w-90">
                            <CustomTextField
                                className="w-120px"
                                title="동"
                                name="dong"
                                required={false}
                                disabled={false}
                                placeholder=""
                                customOnChange={textFieldHandler}
                                value={form}
                            />
                        </div>
                        <div className="row mb-12 w-90">
                            <CustomTextField
                                className="w-120px"
                                title="호"
                                name="ho"
                                required={false}
                                disabled={false}
                                placeholder=""
                                customOnChange={textFieldHandler}
                                value={form}
                            />
                        </div>
                        <div className="row mb-12 w-90">
                            <CustomTextField
                                className="w-120px"
                                title="시작 시간"
                                name="startTime"
                                required={true}
                                disabled={false}
                                placeholder="11:00"
                                customOnChange={textFieldHandler}
                                value={form}
                            />
                        </div>
                        <div className="row w-90 mb-12">
                            <label className="label_title">임시 사용자</label>
                            <CustomRadioButton
                                className="col-2"
                                title="예"
                                id="radioBtn1"
                                name="onlyTemporary"
                                value={true}
                                checked={form.onlyTemporary}
                                customOnChange={radioBtnHandler}
                            />
                            <CustomRadioButton
                                className="col"
                                title="아니요"
                                id="radioBtn2"
                                name="onlyTemporary"
                                value={false}
                                checked={!form.onlyTemporary}
                                customOnChange={radioBtnHandler}
                            />
                        </div>
                        <div className="row mb-12 w-90">
                            <CustomTextField
                                className="w-25"
                                title="자동화명"
                                name="name"
                                required={false}
                                disabled={false}
                                placeholder=""
                                customOnChange={textFieldHandler}
                                value={form}
                            />
                        </div>
                        <div className="row mb-12 w-90">
                            <CustomTextField
                                className="w-25"
                                title="자동화 설명"
                                name="description"
                                required={false}
                                disabled={false}
                                placeholder=""
                                customOnChange={textFieldHandler}
                                value={form}
                            />
                        </div>
                        <div className="row mb-12 w-90 mr-2">
                            <CustomTextField
                                className="w-25"
                                title="장비모델"
                                name="deviceModel"
                                required={true}
                                disabled={true}
                                placeholder=""
                                customOnChange={textFieldHandler}
                                value={form}
                            />
                            <button className="addBtn w-60px h-25px ml-2"
                                    onClick={() => onClickModalOpen('장비모델')}>검색
                            </button>
                        </div>

                        <div className="row mb-12 w-90 mr-2">
                            <CustomTextField
                                className="w-25"
                                title="속성코드"
                                name="attributeCode"
                                required={true}
                                disabled={true}
                                placeholder=""
                                customOnChange={textFieldHandler}
                                value={form}
                            />
                        </div>

                        {
                            Array.isArray(valueData) ?
                                (
                                    <div className="row mb-12 w-90 mr-2">
                                        <CustomTextField
                                            className="w-25"
                                            title="속성 값"
                                            name="attributeValue"
                                            required={true}
                                            disabled={true}
                                            placeholder=""
                                            customOnChange={textFieldHandler}
                                            value={form}
                                        />
                                    </div>
                                ) :
                                (
                                    valueData.valueType === "ENUMERATION" ? (

                                        <div className="row mb-12 w-90 mr-2">
                                            <label className="label_title">속성 값 * </label>
                                            <CustomSelectBox
                                                name="selectValue"
                                                categoryList={selectValue}
                                                value={form.attributeValue.code}
                                                onChangeHandler={(value, name) => selectBoxHandler('selectValue.code', {
                                                    code: value,
                                                    name: name
                                                })}
                                                callbackCodeName="Y"
                                            />
                                        </div>
                                    ) : (
                                        <div className="row mb-12 w-90 mr-2">
                                            <CustomTextField
                                                className="w-25"
                                                title="속성 값"
                                                name="attributeValue"
                                                required={true}
                                                disabled={false}
                                                placeholder={selectValue}
                                                customOnChange={textFieldHandler}
                                                value={form}
                                            />
                                        </div>
                                    )
                                )
                        }

                    </div>
                </div>
                <div className="footer_btn h-10">
                    <div className="">
                        <button className="w-160px h-30px addBtn mr-10" onClick={onSubmitHandler}>저장</button>
                        <button className="w-160px h-30px cancelBtn" onClick={onCancelHandler}>취소</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default withRouter(AutomationTestRegist);

const modalColumnList = [
    {
        code: 'code',
        title: '모델코드',
        width: '38',
    },
    {
        code: 'name',
        title: '모델명',
        width: '20',
    },
    {
        code: 'type.name',
        title: '장비타입',
        width: '20',
    },
    {
        code: 'manufacturer.name',
        title: '제조사',
        width: '20',
    },
    {
        code: 'manufacturer.code',
        title: '',
        width: '',
        hidden: true,
    },
]

export const ModalContent = (props) => {
    switch(props.modalType) {
        case "장비모델":
            return <ModelContent props={props}/>
            break;
        default:
            break;
    }
}

export const ModelContent = (props) => {
    const [propertyList, setpropertyList] = useState([]);
    const [propertyValue, setPropertyValue] = useState(
        {
            attributes: [],
            operations: [],
            automationOperations: [],
            automationAttributes: []
        }
    );
    const [searchCategoryGroup, setSearchCategoryGroup] = useState([
        [
            [
                {code: '', name: '제조사 선택'}
            ]
        ],
        [
            [
                {code: '', name: '장비타입 선택'}
            ]
        ],
    ]);
    const [pageState, setPageState] = useState({
        totalElementsCnt : 0,
        drawElementsCnt : 0,
    })

    const [searchState, setSearchState] = useState({
        currentPage: 0,
        rowPerPage: 13,
        searchText: '',
        manufacturer: {
            code: '',
        },
        type: {
            code: '',
        },
    });

    const [clickedRowData, setClickedRowData] = useState({
        manufacturerCode: '',
        code: '',
    });

    useEffect(() => {
        getToolsManufacturerList((response1) => {
            getToolsTypeList((response2) => {
                setSearchCategoryGroup([response1, response2]);
            });
        });
    }, [])

    useEffect(() => {
        // 모델 속성 전체 목록
        Net.getToolsModelList(searchState, (response) => {
            if (response.status === 200) {
                setpropertyList(response.data.content);
                setPageState({
                    totalElementsCnt: response.data.totalElements,
                    drawElementsCnt: response.data.numberOfElements,
                });
                setPropertyValue({
                    attributes: [],
                    operations: [],
                    automationOperations: [],
                    automationAttributes: []

                });
            } else if (response.status === 404) {
                setpropertyList([]);
                setPageState({
                    totalElementsCnt: 0,
                    drawElementsCnt: 0,
                });
                setPropertyValue({
                    attributes: [],
                    operations: [],
                    automationOperations: [],
                    automationAttributes: []
                });
            } else if (response.status === 204) {
                setpropertyList([]);
                setPageState({
                    totalElementsCnt: 0,
                    drawElementsCnt: 0,
                });
                setPropertyValue({
                    attributes: [],
                    operations: [],
                    automationOperations: [],
                    automationAttributes: []
                });
            }
        });
    }, [searchState.currentPage,
        searchState.searchText,
        searchState.manufacturer.code,
        searchState.type.code])

    const getToolsManufacturerList = (callback) => {
        Net.getToolsManufacturerList(
            {currentPage: '', rowPerPage: '', searchText: ''}, (response) => {
                if (response.status === 200) {
                    let newArray = searchCategoryGroup[0][0];
                    response.data.content.forEach(items => {
                        newArray = [
                            ...newArray,
                            {
                                code: items.code,
                                name: items.name
                            }
                        ]
                    });
                    callback(newArray);
                }
            });
    }

    const getToolsTypeList = (callback) => {
        Net.getToolsTypeList(
            {currentPage: '', rowPerPage: '', searchText: ''}, (response) => {
                if (response.status === 200) {
                    let newArray = searchCategoryGroup[1][0];
                    response.data.content.forEach(items => {
                        newArray = [
                            ...newArray,
                            {
                                code: items.code,
                                name: items.name
                            }
                        ]
                    });
                    callback(newArray);
                }
            });
    }

    const onMovePage = (e) => {
        setSearchState({
            ...searchState,
            currentPage: e - 1
        });
    }

    const onSubmitHandler = (e) => {
        setSearchState({
            ...searchState,
            currentPage: 0,
            searchText: e.searchText,
            manufacturer: {
                code: e.selectedCategory[0],
            },
            type: {
                code: e.selectedCategory[1],
            },
        });
    }

    const onClickEvt = (data, clickedRow ) => {
        const value = {
            attributeCode : data.code,
            deviceModel : clickedRow.code,
            name : data.name,
        }
        props.props.sendData(value, 'code')
        props.props.sendData(data, 'data')
    }

    const onClickRow = (e) => {
        const manufacturerCode = e.manufacturer.code;
        const code = e.code;
        Net.getToolsModelOne({manufacturerCode: manufacturerCode, code: code}, (response) => {
            if (response.status === 200) {
                setPropertyValue(
                    {
                        attributes: response.data.attributes ? response.data.attributes : [],
                        operations: response.data.operations ? response.data.operations : [],
                        automationOperations: response.data.automationOperations ? response.data.automationOperations : [],
                        automationAttributes: response.data.automationAttributes? response.data.automationAttributes : []
                    }
                );
                setClickedRowData({
                    manufacturerCode: manufacturerCode,
                    code: code,
                })
            }
        });
    }

    const makeListToDom = (list, autoList) =>{
        const dom = <Fragment>
            {
                list.map((data, index1) => {
                    return (
                        <tr key={index1}>
                            <td>{index1 + 1}</td>
                            <td>{data.code}</td>
                            <td>{data.name}</td>
                            <td>
                                {
                                    data.valueType === 'ENUMERATION' ?
                                        (data.availableValues !== undefined && data.availableValues.map((value, index) => {
                                            if (data.availableValues.length - 1 === index ) {
                                                return value;
                                            } else {
                                                return value += ', ';
                                            }
                                        })) :
                                        (data.bottomValue + ' ~ ' + data.topValue)
                                }
                            </td>
                            <td>
                                {
                                    autoList.findIndex( i => i.id === data.id) >= 0 ?
                                        <p>활용</p>
                                        :<p>
                                            미활용
                                        </p>
                                }
                            </td>
                            <td>
                                {
                                    autoList.findIndex( i => i.id === data.id) >= 0 ?
                                        <button className="addBtn" onClick={()=>onClickEvt(data, clickedRowData)}> 선택 </button>
                                        :<p>
                                            -
                                        </p>
                                }

                            </td>
                        </tr>
                    )
                })
            }
        </Fragment>

        return dom;
    }

    return (
        <div className="w-100 h-100">
            <div className="w-100 h-7 mb-2 display_table">
                <SearchInputBar
                    className="w-100"
                    searchCategory={true}
                    searchCategoryGroup={searchCategoryGroup}
                    searchText={true}
                    searchPlaceHolder="모델명을 입력해 주세요."
                    searchBtn={true}
                    searchBtnTitle="검색"
                    dynamicSearch={false}
                    onSubmit={onSubmitHandler}
                />
            </div>
            <div className="flex h-93">
            <div className="w-60 h-100">
                <p className="totalCnt">Total {pageState.totalElementsCnt}</p>
                <CustomTable
                    className="w-100 h-70 overflowYscroll"
                    columnList={modalColumnList}
                    contentData={propertyList}
                    paging={true}
                    totalElementsCnt={pageState.totalElementsCnt}
                    rowPerPage={searchState.rowPerPage}
                    currentPage={searchState.currentPage}
                    drawElementsCnt={pageState.drawElementsCnt}
                    pagination={onMovePage}
                    rowClickEvt={true}
                    onClickRow={onClickRow}
                />
            </div>
                <div className="w-40 h-90 mt-10">
                    <div className="w-100 h-50 overFlowYscrollView">
                        <table className="mainTable">
                            <colgroup>
                                <col width="10%"/>
                                <col width="35%"/>
                                <col width="20%"/>
                                <col width="20%"/>
                                <col width="15%"/>
                                <col width="15%"/>
                            </colgroup>
                            <thead className="bgC-c5c5c5">
                            <tr>
                                <th colSpan="6" className="tableHead fixedHeader t-0px">장비행위</th>
                            </tr>
                            <tr>
                                <th className="v-middle tableHead fixedHeader t-36px"><span className="h-24px">No</span>
                                </th>
                                <th className="v-middle tableHead fixedHeader t-36px"><span
                                    className="h-24px">속성코드</span></th>
                                <th className="v-middle tableHead fixedHeader t-36px"><span
                                    className="h-24px">속성명</span></th>
                                <th className="v-middle tableHead fixedHeader t-36px"><span
                                    className="h-24px">속성값</span></th>
                                <th className="v-middle tableHead fixedHeader t-36px"><span
                                    className="h-24px">자동화 여부</span></th>
                                <th className="v-middle tableHead fixedHeader t-36px"><span
                                    className="h-24px">선택</span></th>
                            </tr>
                            </thead>
                            <tbody className="tableBody">
                            {
                                propertyValue.operations.length > 0 ?
                                    (
                                        makeListToDom(propertyValue.operations, propertyValue.automationOperations)
                                    )
                                    :
                                    (<tr>
                                        <td colSpan="6">조회 된 데이터가 없습니다.</td>
                                    </tr>)
                            }
                            </tbody>
                        </table>
                    </div>
                    <div className="w-100 h-45 overFlowYscrollView">
                        <table className="mainTable ">
                            <colgroup>
                                <col width="10%"/>
                                <col width="35%"/>
                                <col width="20%"/>
                                <col width="20%"/>
                                <col width="15%"/>
                                <col width="15%"/>
                            </colgroup>
                            <thead className="bgC-c5c5c5">
                            <tr>
                                <th colSpan="6" className="tableHead fixedHeader t-0px">장비정보</th>
                            </tr>
                            <tr>
                                <th className="v-middle tableHead fixedHeader t-36px"><span className="h-24px">No</span>
                                </th>
                                <th className="v-middle tableHead fixedHeader t-36px"><span
                                    className="h-24px">속성코드</span></th>
                                <th className="v-middle tableHead fixedHeader t-36px"><span
                                    className="h-24px">속성명</span></th>
                                <th className="v-middle tableHead fixedHeader t-36px"><span
                                    className="h-24px">속성값</span></th>
                                <th className="v-middle tableHead fixedHeader t-36px"><span
                                    className="h-24px">자동화 여부</span></th>
                                <th className="v-middle tableHead fixedHeader t-36px"><span
                                    className="h-24px">선택</span></th>

                            </tr>
                            </thead>
                            <tbody className="tableBody">
                            {
                                propertyValue.attributes.length > 0 ?
                                    (
                                        makeListToDom(propertyValue.attributes, propertyValue.automationAttributes)
                                    )
                                    :
                                    (<tr>
                                        <td colSpan="6">조회 된 데이터가 없습니다.</td>
                                    </tr>)
                            }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

        </div>
    )
}
