import React, {Fragment, useEffect, useState} from 'react';
import {CustomTable, SearchInputBar, BasicModal} from 'components/molecules';
import store from 'scripts/store';
import Net from 'scripts/net';
import Common from 'scripts/common';
import {usePageAuth , useDidMountEffect} from "scripts/hooks";

const columnList = [
    {
        code: 'commandId',
        title: 'CommandID',
        width: '8',
    },
    {
        code: 'deviceId',
        title: 'DeviceID',
        width: '8',
    },
    {
        code: 'serialNumber',
        title: 'serialNumber',
        width: '8',
    },
    {
        code: '',
        title: '장비 행위 상태',
        width: '4',
        tdClickEvent: true,
    },
    {
        code: '',
        title: '장비 정보 상태',
        width: '4',
        tdClickEvent: true,

    },
    {
        code: 'requestedTime',
        title: 'requestedTime',
        width: '8',
    },
];


export const CallbackMonitoring = (props) => {

    // useHistory 선언
    const user = props.userInfo;
    const programAuth = usePageAuth(props.userInfo.authList[0], sessionStorage.getItem('menuId'));
    const [checkedList, setCheckedList] = useState([]);
    const [contentData, setContentData] = useState([]);
    const [totalElementsCnt, setTotalElementsCnt] = useState(0);
    const [modalState, setModalState] = useState({
        open: false,
        data: [],
        username: '',
        clickedBtnName: '',
    });

    useEffect(() => {
        store.openedPage = '콜백 모니터링 현황';
    }, [])

    const loadData = async () => {
        const response = await Net.getCallbackLogAsync();
        setContentData(response.data)
        setTotalElementsCnt(response.data.length)
    }

    const tdOnClickEvent = (title, e) => {
        setModalState({
            ...modalState,
            open: !modalState.open,
            propsData: e,
            clickedBtnName: title
        });
    }

    const sendData = (e) => {
        setModalState({
            ...modalState,
            data: e,
        })
    }

    const removeList = (e) => {
        const removeList = [];
        checkedList.map((id) => {
            const node = document.querySelector(`#${id}`);
            const nodeTr = node.closest('tr');
            const faqId = nodeTr.querySelector('td[name=faqId]').textContent;
            removeList.push({faqId: faqId});
        });
        console.log(removeList)
        alert("미구현")
    }

    return (
        <div className="w-100 h-100">
            <BasicModal
                className="w-70 h-80"
                title={`사용자 ${modalState.clickedBtnName} 목록`}
                content={
                    <ModalContent
                        sendData={sendData}
                        modalType={modalState.clickedBtnName}
                        propsData={modalState.propsData}
                        />
                }
                modalOpen={modalState.open}
                modalClose={(e) => setModalState({
                    ...modalState,
                    open: e
                })}
                sendData={modalState.data}
            />
            <div className="w-100 h-1px bgC-efefef"/>
            <div className="h-93 row">
                <h5 className="floatL mt-1p mb-1p ml-2p">콜백 모니터링</h5>

                <div className="w-100 h-100">
                    <div id="container">
                        <p className="totalCnt floatL">Total : {totalElementsCnt}</p>
                        {
                            checkedList.length > 0 &&
                            <button
                                className="cancelBtn w-90px h-30px floatR mt-7 mr-20"
                                onClick={removeList}
                            >삭제</button>
                        }
                        <button
                            className="removeBtn w-90px h-30px floatR mt-2 mb-1 mr-25"
                            onClick={loadData}
                        >수동 조회</button>

                        <CustomTable
                            columnList={columnList}
                            contentData={contentData}
                            tdClickEvt={tdOnClickEvent}
                            checkbox={true}
                            checkedList={(checkedList) => {
                                setCheckedList(checkedList)
                            }}
                        />
                    </div>
                </div>

            </div>
        </div>
    );
}

export const ModalContent = (props) => {
    switch(props.modalType) {
        case "장비 행위 상태":
            return <AttributesContent propsData={props.propsData}/>
            break;
        case "장비 정보 상태":
            return <OperationsContent propsData={props.propsData} />
            break;
        default:
            break;
    }
}

export const AttributesContent = (props) => {
    const modalColumnList = [
        {
            code : 'code',
            title: '코드',
            width: '5',
        },
        {
            code : 'value',
            title: '값',
            width: '5',
        },
        {
            code : 'name',
            title: '이름',
            width: '5',
        }
    ];
    const [propertyList, setPropertyList] = useState([]);

    useEffect(()=>{
        setPropertyList(props.propsData.attributes);
    },[])
    return (
        <div>
            <CustomTable
                columnList={modalColumnList}
                contentData={propertyList}
            />
        </div>
    )
}

export const OperationsContent = (props) => {
    const modalColumnList = [
        {
            code : 'code',
            title: '코드',
            width: '5',
        },
        {
            code : 'value',
            title: '값',
            width: '5',
        },
        {
            code : 'name',
            title: '이름',
            width: '5',
        }
    ];
    const [propertyList, setPropertyList] = useState([]);

    useEffect(()=>{
        setPropertyList(props.propsData.operations);
    },[])
    return (
        <div>
            <CustomTable
                columnList={modalColumnList}
                contentData={propertyList}
                />
        </div>
    )
}
