import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import Modal from '@material-ui/core/Modal';
import Grow from '@material-ui/core/Grow';
import './AlertModal.scss';


export default function AlertModal(props) {

  const [open, setOpen] = useState(false);

  useEffect(() => {
    setOpen(props.modalOpen);
  }, [props.modalOpen])

  const handleClose = () => {
    setOpen(false);
    props.modalClose(false);
  };

  const body = (
    <div className={classNames('alert_modal_wrapper', props.className)}>
      <div className={classNames('alert_modal_body', props.b_className)}>
        {props.content}
      </div>
    </div>
  );

  return (
      <div>
          <Modal
              open={open}
              onClose={handleClose}
          >
              <Grow in={open} >
              {body}
              </Grow>
          </Modal>
      </div>
  );
}
