import React, {Fragment, useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom'
import {CustomTable, SearchInputBar, BasicModal} from 'components/molecules';
import store from 'scripts/store';
import Net from 'scripts/net';
import Common from 'scripts/common';
import {usePageAuth } from "scripts/hooks";
import Zoom from "react-medium-image-zoom";
import {BoardRegistModal} from "./modal/BoardRegistModal";
import {BoardUpdateModal} from "./modal/BoardUpdateModal";

const columnList = [
    {
        code: 'boardId',
        title: 'boardId',
        width: '8',
        hidden: true,
    },
    {
        code: 'category',
        title: '구분',
        width: '5',
    },
    {
        code: 'site.name',
        title: '현장명',
        width: '8',
    },
    {
        code: 'user.username',
        title: '글쓴이',
        width: '8',
    },
    {
        code: 'title',
        title: '제목',
        width: '15',
    },
    {
        code: 'attribute1',
        title: '추가속성1',
        width: '8',
    },
    {
        code: 'attribute2',
        title: '추가속성2',
        width: '8',
    },
];

export const BoardList = (props) => {

    // useHistory 선언
    const history = useHistory();
    const user = props.userInfo;
    const [contentData, setContentData] = useState([]);
    const [checkedList, setCheckedList] = useState([]);
    const [boardDetail, setBoardDetail] = useState([]);
    const programAuth = usePageAuth(props.userInfo.authList[0], sessionStorage.getItem('menuId'));
    const [loading, setLoading] = useState(false);
    const [modalState, setModalState] = useState({
        open: false,
        data: [],
        clickedBtnName: '',
    });
    const [searchCategory, setSearchCategory] = useState([
        [
            [
                {code: '', name: '고객사 선택'}
            ]
        ],
        [
            [
                {code: '', name: '현장명 선택'}
            ]
        ],
        [
            [
                {code: '', name: '구분 선택'}
            ]
        ]
    ]);

    const [searchState, setSearchState] = useState({
        currentPage: 0,
        rowPerPage: 10,
        searchText: '',
        searchCategory1: user.menuGroup === 'ADMIN' ? '' : user.customer && user.customer.code,
        searchCategory2: user.menuGroup === 'OPERATOR' ? user.site && user.site.code: '',
        searchCategory3: '',
    })

    const [pageState, setPageState] = useState({
        totalElementsCnt: 0,
        drawElementsCnt: 0,
    });

    useEffect(()=>{

    },[boardDetail])

    useEffect(() => {
        store.openedPage = '게시판 전체 목록';

        getCategoryList('');
    }, [])

    useEffect(() => {
        setTimeout(() => {
            loadData();
        }, 10)
    }, [searchState.currentPage,
        searchState.searchCategory1,
        searchState.searchCategory2,
        searchState.searchCategory3,
    ]);

    const loadData = () => {
        setLoading(true);
        Net.getBoardList(
            {
                category : searchState.searchCategory3,
                searchCategory1 : searchState.searchCategory1,
                searchCategory2 : searchState.searchCategory2,
                currentPage: searchState.currentPage,
                rowPerPage: searchState.rowPerPage,
            }, (response) => {
                setLoading(false);
                if (response.status === 200) {
                    console.log(response);
                    setContentData(response.data.content);
                    setPageState({
                        ...pageState,
                        totalElementsCnt: response.data.totalElements,
                        drawElementsCnt: response.data.numberOfElements,
                    });
                }else if(response.status === 204){
                    setContentData([]);
                    setPageState({
                        ...pageState,
                        totalElementsCnt:0,
                        drawElementsCnt: 0,
                    });
                }
            });
    }


    const getCategoryList = () => {
        let response3 = [];

        getBoardCategoryList(response => {
            if(response.status === 200) {
                response3 = response.data;
                if (user.menuGroup === 'ADMIN') {
                    Common.getCustomerList((response1) => {
                        Common.getSitesList(null, (response2) => {
                            setSearchCategory([response1, response2, response3]);
                        });
                    });
                } else if (user.menuGroup === 'MANAGER') {
                    Common.getSitesList(pageState.searchCategory1, (response2) => {
                        setSearchCategory([response2, response3]);
                    });
                } else {
                    setSearchCategory([response3]);

                }
            }
        })

    }

    const getBoardCategoryList = (callback) => {
        Net.getBoardMasterList({
            currentPage : 0,
            rowPerPage : 9999
            }, (response) => {
                if (response.status === 200) {
                    let newArray = [{code: '', name: "구분 선택"}];
                    console.log(response);
                    response.data.content.forEach(items => {
                            newArray = [
                                ...newArray,
                                {
                                    code: items.code,
                                    name: items.name
                                }
                            ]
                    })
                    callback({status: 200, data: newArray});
                }
            }
        )
    }

    const getRelateCategory = (selectedCategory) => {
        if (selectedCategory === "reset") selectedCategory = "";
        Common.getSitesList(selectedCategory, (response2) => {
            setSearchCategory([
                searchCategory[0],
                response2,
                searchCategory[2]
            ]);
        });
    }

    const onClickRow = async (e) => {
        const responseBoardOne = await Net.getBoardOneAsync(e.boardId);
        console.log(responseBoardOne)
        if(responseBoardOne.status === 200 ) {
            setBoardDetail(responseBoardOne.data)
        } else {
            setBoardDetail([]);
        }
    }

    const onMovePage = (e) => {
        setSearchState({
            ...searchState,
            currentPage: e - 1
        });
    }


    const removeList = () => {
        const result = window.confirm('삭제하시겠습니까?');
        const removeList = [];
        checkedList.map((id) => {
            const node = document.querySelector(`#${id}`);
            const nodeTr = node.closest('tr');
            const boardId = nodeTr.querySelector('td[name=boardId]').textContent;
            removeList.push({boardId: boardId});
        });
        if (result) {
            Net.removeBoardList(removeList, (response) => {
                if (response) {
                    loadData();
                }
            });
        }
    }

    const onSubmitHandler = (e) => {
        if (user.menuGroup === 'ADMIN') {
            setSearchState({
                ...searchState,
                currentPage: 0,
                searchCategory1: e.selectedCategory[0],
                searchCategory2: e.selectedCategory[1],
                searchCategory3: e.selectedCategory[2],
            });
        } else if (user.menuGroup === 'MANAGER') {
            setSearchState({
                ...searchState,
                currentPage: 0,
                searchCategory1: searchState.searchCategory1,
                searchCategory2: e.selectedCategory[0],
                searchCategory3: e.selectedCategory[1],

            });
        } else {
            setSearchState({
                ...searchState,
                currentPage: 0,
                searchCategory1: searchState.searchCategory1,
                searchCategory2: searchState.searchCategory2,
                searchCategory3: e.selectedCategory[0],
            });
        }
    }

    const addList = () => {
        /*
        *
        * */
        setModalState({
            ...modalState,
            open: !modalState.open,
            propsData: {
                user: user,
            },
            clickedBtnName: '게시글 등록',
        });
    }

    const reload = (e) => {
        if(e === 'reload'){
            loadData();
        }
    }

    const goModifypage = () => {
        setModalState({
            ...modalState,
            open: !modalState.open,
            propsData: {
                user : user,
                boardDetail : boardDetail,

            },
            clickedBtnName: '게시글 수정',
        });
    }

    return (
        <div className="w-100 h-100">
            <BasicModal
                className="w-80 h-88"
                title={`${modalState.clickedBtnName}`}
                content={
                    <ModalContent
                        modalType={modalState.clickedBtnName}
                        propsData={modalState.propsData}
                        modalClose={(e) => setModalState({
                            ...modalState,
                            open: e
                        })}
                        deleteAuth = {programAuth.deleteAuth}
                        reload={reload}
                    />
                }
                modalOpen={modalState.open}
                modalClose={() => setModalState({
                    ...modalState,
                    open: false
                })}
                sendData={modalState.data}
            />
            <div className="w-100 h-7 display_table">
                <SearchInputBar
                    role={user.menuGroup}
                    className="w-100"
                    searchCategory={true}
                    searchCategoryGroup={searchCategory}
                    searchBtn={true}
                    searchBtnTitle="검색"
                    dynamicSearch={false}
                    relateCategoryHandler={user.menuGroup === 'ADMIN' && getRelateCategory}
                    onSubmit={onSubmitHandler}
                    refreshBtn={true}
                    refreshHandler={loadData}
                />
                {
                    programAuth.saveAuth &&
                    <div className="display_table_cell v-middle">
                        <button
                            className="addBtn w-90px h-30px floatR mr-20"
                            onClick={addList}
                        >등록
                        </button>
                    </div>

                }

            </div>
            <div className="w-100 h-1px bgC-efefef"/>
            <div className="flex h-93">
                <div className="w-70 h-100">
                    <div className="row">
                        <div className="col-8">
                            <p className="totalCnt">Total {pageState.totalElementsCnt}</p>
                            {
                                programAuth.deleteAuth && checkedList.length > 0 &&
                                <button
                                    className="removeBtn w-90px h-30px floatR mt-15 mr-5"
                                    onClick={removeList}
                                >삭제</button>
                            }
                        </div>
                    </div>
                    <CustomTable
                        columnList={columnList}
                        contentData={contentData}
                        paging={true}
                        checkbox={programAuth.deleteAuth}
                        checkedList={(checkedList) => {
                            setCheckedList(checkedList)
                        }}
                        totalElementsCnt={pageState.totalElementsCnt}
                        rowPerPage={searchState.rowPerPage}
                        currentPage={searchState.currentPage}
                        drawElementsCnt={pageState.drawElementsCnt}
                        pagination={onMovePage}
                        rowClickEvt={true}
                        onClickRow={onClickRow}
                        loading={loading}

                        // sort = {true}
                    />
                </div>
                <div className="w-30 h-100">
                    <div className="flex">
                        <p className="body_content_subtitle">내용</p>
                        {
                            boardDetail.boardId ?
                                (
                                    <button
                                        className="defaultBtn bgC-eb878c borderC-eb878c w-90px h-30px mr-20 mt-auto mb-auto ml-auto"
                                        onClick={goModifypage}
                                    >수정</button>
                                ) : ""
                        }

                    </div>
                    <div className="w-100 h-100">
                        <table className="mainTable">
                            <thead className="tableHead">
                            <tr>
                                <th>내용</th>
                            </tr>
                            </thead>
                            <tbody className="tableBody">
                            {
                                boardDetail.content ?
                                    (
                                        <Fragment>
                                            <tr>
                                                <td style={{padding: "10px", overflow: "unset", whiteSpace: "initial"}}>
                                                    <div className="overFlowYscrollView h-60vh">
                                                        <div className="w-auto">
                                                            <p style={{whiteSpace : "pre-line"}}>
                                                                {boardDetail.content}
                                                            </p>
                                                        </div>
                                                        {
                                                            boardDetail.imageDataUrl &&
                                                            <div className="mt-10 text-center">
                                                                <Zoom>
                                                                    <img src={boardDetail.imageDataUrl} alt="complex=Img"
                                                                         width="300"/>
                                                                </Zoom>
                                                                <p>이미지</p>
                                                            </div>
                                                        }
                                                    </div>
                                                </td>
                                            </tr>
                                        </Fragment>
                                    ) : (
                                        <tr>
                                            <td>조회 된 데이터가 없습니다.</ td>
                                        </tr>
                                    )
                            }

                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
}

export const ModalContent = (props) => {
    switch (props.modalType) {
        case "게시글 등록":
            return <BoardRegistModal data={props.propsData}  modalClose={props.modalClose} reload={props.reload}/>
            break;
        case "게시글 수정":
            return <BoardUpdateModal data={props.propsData}  modalClose={props.modalClose} reload={props.reload}/>
            break;
        default:
            break;
    }
}




