import React, {Fragment, useEffect, useState} from 'react';
import {CustomTable, SearchInputBar} from 'components/molecules';
import Net from 'scripts/net';


const detailColumnList = [
    {
        code: 'homenetUsername',
        title: '홈넷 계정',
        width: '8',
    },
    {
        code: 'username',
        title: '사용자ID',
        width: '8',
    },
    {
        code: 'dongHo',
        title: '동호',
        width: '8',
    },
    {
        code: 'createdDatetime',
        title: '홈넷 계정 연동 일시',
        width: '8',
    },
    {
        code: 'delete',
        title: '삭제',
        width: '8',
        tdClickEvent: true,
    },
]

const notDeleteColumnList = [
    {
        code: 'homenetUsername',
        title: '홈넷 계정',
        width: '8',
    },
    {
        code: 'username',
        title: '사용자ID',
        width: '8',
    },
    {
        code: 'dongHo',
        title: '동호',
        width: '8',
    },
    {
        code: 'createdDatetime',
        title: '홈넷 계정 연동 일시',
        width: '8',
    },
]

export const HomenetAuthDetailList =  (props) => {

    const tdOnClickEvent = async (title, e) => {
        const result = window.confirm('삭제 하시겠습니까?');
        if(result){
            const response = await  Net.HomenetDisconnect(e.username);
            console.log(response);
            props.reload('reload');

        }
    }

    return (
        <>
        <CustomTable
            className="overFlowYscrollView h-93"
            columnList={ props.deleteAuth ? detailColumnList : notDeleteColumnList}
            contentData={props.detailContentData}
            tdClickEvt={tdOnClickEvent}
            />
        </>
    )

}
