import React from 'react';
import Net from 'scripts/visitor/net';
import Common from 'scripts/common';
import logo from "./image/logoHanulchaeWhite@3x.png";
import complete from "./image/carImg.png";

import './VisitorComplete.scss';
import inviteImage from "./image/inviteImg@2x.png";

export const VisitorComplete = ({match}) => {
    console.log(match);

    return (
        <div className="page">
            <div className="text-center visitorComplete">
                <div className="cardTop">
                    <div className="flex">
                        <div className="col-left">
                            <img src={logo}
                                 className="hanulche"
                                 alt="logo"/>
                        </div>
                        <div className="col-right text-header flex ">
                            <p className="whiteP "> 모바일 방문등록 서비스</p>
                            <p className="greyP "> 하늘채 방문을 환영합니다.</p>
                        </div>
                    </div>
                </div>

                <div className="cardBottomm px-4 shadow">
                    <div className="flex-column px-3">
                        <div className="row text-center complete_pd">
                            <img src={complete}
                                 className="completeImage"
                                 alt="logo"/>
                        </div>
                        <div className="text-container">
                            <div className="row">
                                <p className="completeTopP"> 차량등록이 <span className="font-medium">완료</span> 되었어요.</p>
                                <p className="completeTopP"> 방문일에 주차장 <span className="font-medium">진입 및 주차가 가능</span>해요.</p>

                                <p className="completeBottomP">하늘채와 함께 편안한 방문 되세요.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )

}
