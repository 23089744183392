import React from 'react';
import {useHistory} from 'react-router-dom';

export default function NotFound() {
    const history = useHistory();

    const goBack = () => history.goBack(-1);

    return (
        <div className="text-center align-self-center mx-auto">
            <h1>찾을 수 없는 페이지 입니다.</h1>
            <h1>404 Not Found</h1>
            <button className="btn btn-primary" onClick={goBack}>돌아가기</button>
        </div>
    );
}
