import React, { useEffect, useState } from 'react';
import { useHistory, withRouter } from 'react-router-dom';
import { CustomTextField, CustomRadioButton,QuillEditor, CustomMultiSelectBox, CustomSelectBox, ImgUpload } from 'components/molecules';import store from 'scripts/store';
import DatePicker from "react-datepicker";
import Net from 'scripts/net';
import Common from 'scripts/common';
import {EditorState} from "draft-js";
import {toJS} from "mobx";

const BannerRegist = ({match}) => {

    const cloneUser = toJS(store.user)

    const [form, setForm] = useState({
        bannerId: '',
        title: '',
        contents: '',
        bannerUrl: '',
        customer : cloneUser.menuGroup === 'ADMIN' ? {code: '',name: '',} : cloneUser.customer,
        site: cloneUser.menuGroup === 'OPERATOR' ? [cloneUser.site] : [{ code: '', name: '',}],
        startDate: '',
        endDate: '',
        sort : '',
        usable: true,
        image: '',
        imageDataUrl: '',
    });

    const [registPage, setRegistPage] = useState(true);
    const [duplicateChk, setDuplicateChk] = useState(false);
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [oriTextValue, setOriTextValue] = useState("");
    const [updateSite, setUpdateSite ] = useState([{ code: '', name: ''}]);

    const [customerList, setCustomerList] = useState([{
        code: '',
        name: '고객사명 선택'
    }]);
    const [siteList, setSiteList] = useState([{
        code: '',
        name: '고객사를 선택해주세요.'
    }]);

    const [editorState, setEditorState] = useState(EditorState.createEmpty());

    useEffect(() => {
        store.openedPage = '배너 등록/수정';

        // 고객사 전체 목록
        getCustomerList((response) => {
            setCustomerList(response);
        });
    }, []);


    useEffect(() => {
        // 상세 조회(수정 목적으로 진입시)
        if(match.params.bannerId !== undefined) {
            getBannerOne(match.params, (response) => {
                console.log(response)
                if(response.status === 200) {
                    if(!response.data.customer){
                        response.data.customer = {code: "", name: ""};
                    }
                    if(!response.data.site){
                        response.data.site = [{code: "", name: ""}];
                    }
                    setForm({
                        ...form,
                        ...response.data,
                    });
                    setEditorState(Common.htmlToDraftBlocks(response.data.contents));
                    setOriTextValue(response.data.contents);
                    // 등록페이지 여부 확인
                    setRegistPage(false);

                    setStartDate(new Date(response.data.startDate));
                    setEndDate(new Date(response.data.endDate));
                }
            });

        }
    }, [match.params.bannerId]);

    useEffect(() => {
        dateChangeHandler();
    }, [ startDate, endDate ])

    useEffect(() => {
        if(form.customer.code !== '') {
            getSitesList(form.customer.code, (response) => {
                setSiteList(response);
            })
        } else {
            // 빈값 선택시 장비모델명 selectbox 초기화
            setSiteList([
                {
                    code: '',
                    name: '현장명 선택'
                }
            ])
        }
    }, [form.customer.code]);

    const getSitesList = (customerCode, callback) => {
        Net.getSitesList(
            {currentPage: '', rowPerPage: '', searchText: '', customerCode: customerCode }, (response) => {
                if (response.status === 200) {
                    let newArray = [];
                    if (response.data.content.length > 0) {
                        response.data.content.map(items => {
                            newArray = [
                                ...newArray,
                                {
                                    code: items.code,
                                    name: items.name
                                }
                            ]

                        })
                    }
                    callback(newArray);
                } else {
                    let newArray = [{code: '', name: "전체 현장"}];
                    callback(newArray);
                }
            });
    }

    const getCustomerList = (callback) => {
        Net.getCustomerList(
            {currentPage: '', rowPerPage: '', searchText: ''}, (response) => {
                if(response.status === 200) {
                    let newArray = customerList;
                    response.data.content.forEach(items => {
                        newArray = [
                            ...newArray,
                            {
                                code: items.code,
                                name: items.name
                            }
                        ]
                    });
                    callback(newArray);
                }
            });
    }

    const history = useHistory();

    const getBannerOne = (params, callback) => {
        Net.getBannerOne(params.bannerId, (response) => {
            callback(response);
        });
    }

    const textFieldHandler = (name, value) => {
        if(name === 'code') setDuplicateChk(false);
        const nextForm = {
            ...form,
            [name]: value
        }
        setForm(nextForm);
    }

    const radioBtnHandler = (name, value) => {
        let nextForm;
        if(name === "authGroup"){
            nextForm = {
                ...form,
                [name]: value,
            }
        } else {
            nextForm = {
                ...form,
                [name]: value === 'true' ? true : false,
            }
        }
        setForm(nextForm);
    }

    const selectBoxHandler = (name, value) => {
        switch (name) {
            case 'customer.code':
                setForm({
                    ...form,
                    customer: {
                        code: value.code,
                        name: value.name
                    },
                    site: {
                        code: "",
                        name: ""
                    }
                });
                break;
            case 'site.code':
                setForm({
                    ...form,
                    site: {
                        code: value.code,
                        name: value.name
                    }
                });
                break;
            default:
                break;
        }
    }

    const onSubmitHandler = () => {

        if( !form.title ) {
            alert('제목을 입력해 주세요.');
            document.getElementsByName('title')[0].focus();
            return false;
        }
        if( !form.contents ) {
            alert('내용을 입력해 주세요.');
            return false;
        }
        if( !form.bannerUrl ) {
            alert('url을 입력해 주세요.');
            document.getElementsByName('bannerUrl')[0].focus();
            return false;
        }
        if( !form.customer.code ) {
            alert('고객사를 선택해 주세요.');
            document.getElementsByName('customer.code')[0].focus();
            return false;
        }
        if( !form.sort ) {
            alert('정렬순서를 입력해주세요.');
            document.getElementsByName('sort')[0].focus();
            return false;
        }

        let result = window.confirm('저장하시겠습니까?');
        if(result) {
            if(registPage) {
                Net.addBanner(form, updateSite, (response) => {
                    if(response.status === 200 || response.status === 201) {
                        history.push('/community/banner/list');
                    }
                });
            } else {
                Net.updateBanner(form, updateSite,(response) => {
                    if(response.status === 200) {
                        history.push('/community/banner/list');
                    }
                });
            }
        }
    }

    const onCancelHandler = () => {
        history.push('/community/banner/list');
    }

    const getImgFile = (dataUrl) => {
        setForm({
            ...form,
            imageDataUrl: dataUrl,
        });
    }

    const liftValue = (value) => {
        setForm({
            ...form,
            contents: value,
        });
    }

    const dateChangeHandler = () => {
        const startDateFormating = startDate.getFullYear() + '-' + ('0' + (Number(startDate.getMonth())+1)).slice(-2) + '-' + ('0' + startDate.getDate()).slice(-2);
        const endDateFormating = endDate.getFullYear() + '-' + ('0' + (Number(endDate.getMonth())+1)).slice(-2) + '-' + ('0' + endDate.getDate()).slice(-2);
        setForm({
            ...form,
            startDate: startDateFormating,
            endDate: endDateFormating
        });
    }

    const onRemoveHandler = () => {
        const result = window.confirm('삭제하시겠습니까?');
        if(result) {
            Net.removeBanner([{bannerId: form.bannerId}], (response) => {
                if(response) {
                    history.push('/community/banner/list');
                }
            });
        }
    }

    const handleMultiSelectChange = (selectedOptions) => {
        // 여기서 selectedOptions를 활용하여 원하는 동작을 수행
        console.log(selectedOptions);
        // ... 추가적인 로직
        if (!arrayEquality(selectedOptions, updateSite)) {  // 배열이 같은지 확인 후 변경 시에만 업데이트
            setUpdateSite(selectedOptions);
        }
    };

// 배열 비교 함수
    function arrayEquality(arr1, arr2) {
        if (arr1.length !== arr2.length) {
            return false;
        }
        for (let i = 0; i < arr1.length; i++) {
            if (arr1[i].code !== arr2[i].code || arr1[i].name !== arr2[i].name) {
                return false;
            }
        }
        return true;
    }

    return (
        <div className="w-100 h-100">
            <div className="w-100 h-1px bgC-efefef" />
            <div className="h-93">
                <div className="flex h-90">
                    <div className="w-100 h-100 overFlowYscrollView">
                        <div className="ml-55 mt-12">
                            <div className="flex mb-12 w-90">
                                <CustomTextField
                                    className="w-60 h-26px"
                                    title="제목"
                                    name="title"
                                    required={true}
                                    disabled={false}
                                    placeholder=""
                                    customOnChange={textFieldHandler}
                                    value={form}
                                />
                            </div>
                            <div className="flex mb-10 w-90">
                                <label className="label_title">내용 *</label>
                                <div className="w-80">
                                    <QuillEditor
                                        onChange={liftValue}
                                        value={editorState}
                                        setEditorState={setEditorState}
                                    />
                                </div>
                            </div>
                            <div className="flex mb-12 w-90">
                                <CustomTextField
                                    className="w-60 h-26px"
                                    title="bannerUrl"
                                    name="bannerUrl"
                                    required={true}
                                    disabled={false}
                                    placeholder=""
                                    customOnChange={textFieldHandler}
                                    value={form}
                                />
                            </div>
                            <div className="flex mb-12 w-90">
                                <label className="label_title">고객사 *</label>
                                <CustomSelectBox
                                    name="customer.code"
                                    categoryList={customerList}
                                    value={form.customer.code}
                                    onChangeHandler={(value, name) => selectBoxHandler('customer.code', {code: value, name: name})}
                                    callbackCodeName="Y"
                                    disabled={!registPage}
                                />
                                {
                                    registPage ? <div></div> :
                                        <div className="label_summary ml-10">※ 수정시 고객사 변경 불가능 합니다. </div>
                                }
                            </div>
                            <div className="flex mb-12 w-90">
                                <label className="label_title">현장</label>
                                {
                                    <CustomMultiSelectBox
                                        categoryList={siteList}
                                        className="multi-select"
                                        value={form.site}
                                        onChangeHandler={handleMultiSelectChange}
                                        callbackCodeName="Y"
                                    />
                                }
                            </div>
                            <div className="flex mb-12 w-90">
                                <CustomTextField
                                    className="w-60 h-26px"
                                    title="정렬순서"
                                    name="sort"
                                    required={true}
                                    disabled={false}
                                    placeholder="ex) 1, 2, 10"
                                    customOnChange={textFieldHandler}
                                    value={form}
                                />
                            </div>

                            <div className="flex mb-12 w-90">
                                <label className="label_title">공개기간 *</label>
                                <div className="w-20 mr-10">
                                    <DatePicker
                                        dateFormat="yyyy-MM-dd"
                                        selected={startDate}
                                        onChange={date => {
                                            setStartDate(date);
                                        }}
                                        className="w-100 textField"
                                    />
                                </div>
                                ~
                                <div className="w-20 ml-10">
                                    <DatePicker
                                        dateFormat="yyyy-MM-dd"
                                        selected={endDate}
                                        onChange={date => {
                                            setEndDate(date);
                                        }}
                                        className="w-100 textField"
                                    />
                                </div>
                            </div>

                            <div className="flex w-90">
                                <label className="label_title">사용유무</label>
                                <CustomRadioButton
                                    className="mr-60"
                                    title="사용"
                                    id="radioBtn1"
                                    name="usable"
                                    value={true}
                                    checked={form.usable}
                                    customOnChange={radioBtnHandler}
                                />
                                <CustomRadioButton
                                    title="사용안함"
                                    id="radioBtn2"
                                    name="usable"
                                    value={false}
                                    checked={!form.usable}
                                    customOnChange={radioBtnHandler}
                                />
                            </div>

                        </div>
                    </div>
                    <div className="w-40 h-100 m-4">
                        <div className="w-100 h-100 mt-12" style={{display:"block"}} >
                            <div className="floatC mr-2p" >
                                <ImgUpload
                                    title={"사진"}
                                    imgUpload={getImgFile}
                                    imgSize={"300px"}
                                    imgFile={form.imageDataUrl}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer_btn h-10">
                    <div className="">
                        <button className="w-160px h-30px addBtn mr-10" onClick={onSubmitHandler}>저장</button>
                        { registPage ? "" : <button className="w-160px h-30px removeBtn mr-10" onClick={onRemoveHandler}>삭제</button>}
                        <button className="w-160px h-30px cancelBtn" onClick={onCancelHandler}>취소</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default withRouter(BannerRegist);
