/* eslint-disable no-lone-blocks */
import React, {useEffect, useState} from 'react';
import Net from 'scripts/net';
import Common from 'scripts/common';
import { CustomTextField, CustomSelectBox, BasicModal   } from 'components/molecules';
import {useDidMountEffect} from "scripts/hooks/useDidMountEffect";
export const BatchExecute = (props) => {

    const [form, setForm] = useState({
        site: {
            code:'',
            name:'',
        },
        code : '',
        name : '',
        dong : '',
        ho : '',
        line: '',
        modelCode : {
            code : 'MULTI_LIGHT_SWITCH',
            name : '조명'
        },
        property : 'power',
        value : 'on'
    });

    const [siteList, setSiteList] = useState([{
        code: '',
        name: '현장명 선택'
    }])

    const modelCodeList = [
        {
            code : 'MULTI_LIGHT_SWITCH',
            name : '조명'
        },
        {
            code : 'WALL_OUTLET_POWER',
            name : '대기 전력'
        },
        {
            code : 'THERMOSTAT_HEATER',
            name : '난방'
        },
        {
            code : 'VENTILATION',
            name : '환기'
        }
    ]

    useEffect(()=>{
        {/*

        */}

        if (props.data.siteCode !== undefined) {
            Net.getSiteOne(props.data.siteCode, (responseSite) => {
                if (responseSite.status === 200) {
                    setForm({
                        ...form,
                        dong: props.data.dong,
                        site: {
                            code: props.data.siteCode,
                            name: responseSite.data.name
                        },
                    })
                }
            })
        }
    },[]);


    const selectBoxHandler = (name, value) => {
        console.log(name, value);
        if(name === 'modelCode'){
            setForm({
                ...form,
                modelCode: {
                    code: value.code,
                    name: value.name
                }
            })
        }
    }

    const textFieldHandler = (name, value) => {
        let nextForm = {}
        if(name === 'ho'){
             nextForm = {
                ...form,
                [name] : value,
                line : ''
            }
        } else if (name === 'line'){
            nextForm = {
                ...form,
                [name] : value,
                ho : ''
            }
        } else {
            nextForm = {
                ...form,
                [name] : value
            }
        }
        setForm(nextForm);
    }


    const onSubmitHandler = async () => {
        let result = window.confirm('일괄 제어를 진행하시겠습니까?');
        if(result){
            {/* 제어 */}
            const response = await Net.executeBatchControl(form);
            if(response.status === 200){
                props.modalClose(false);
            } else{
                alert('알수없는 이유로 명령 실패. 로그 확인 필요.')
                props.modalClose(false);
            }
        }
    }

    return (
        <div className="w-100 h-90">
            <div className='w-100 h-1px bgC-efefef'/>
            <div className='h-93'>
                <div className='row flex h-90 justify-content-center'>

                    <div className="flex mb-12 col-11">
                        <CustomTextField
                            className="h-30px"
                            title="현장"
                            name="site.name"
                            required={true}
                            customOnChange={textFieldHandler}
                            value={form}
                            disabled={true}
                        />
                    </div>
                    <div className='flex mb-12 col-11'>
                        <CustomTextField
                            className="h-30px"
                            title="동"
                            name="dong"
                            required={true}
                            customOnChange={textFieldHandler}
                            value={form}
                            disabled={true}
                            />
                    </div>

                    <div className='flex mb-12 col-11'>
                        <CustomTextField
                            className="h-30px"
                            title="라인"
                            name="line"
                            customOnChange={textFieldHandler}
                            value={form}
                            disabled={form.ho}
                        />
                    </div>

                    <div className='flex mb-12 col-11'>
                        <CustomTextField
                            className="h-30px"
                            title="호"
                            name="ho"
                            customOnChange={textFieldHandler}
                            value={form}
                            disabled={form.line}
                            />
                    </div>

                    <div className="flex mb-12 col-11">
                        <label className="label_title">모델 코드 *</label>
                        <CustomSelectBox
                            name="modelCode"
                            categoryList={modelCodeList}
                            onChangeHandler={(value, name) => selectBoxHandler('modelCode', {code: value, name: name})}
                            value={form.modelCode.code}
                            disabled={false}
                        />
                    </div>

                    <div className='flex mb-12 col-11'>
                        <CustomTextField
                            className="h-30px"
                            title="속성(property)"
                            name="property"
                            customOnChange={textFieldHandler}
                            value={form}
                            disabled={false}
                        />
                    </div>

                    <div className='flex mb-12 col-11'>
                        <CustomTextField
                            className="h-30px"
                            title="값(value)"
                            name="value"
                            customOnChange={textFieldHandler}
                            value={form}
                            disabled={false}
                        />
                    </div>



                </div>
                <div className="footer_btn h-10 mt-1">
                <div className="">
                    <button className="w-160px h-30px addBtn mr-10 ml-10" onClick={onSubmitHandler}>제어</button>
                    <button className="w-160px h-30px cancelBtn mr-10" onClick={()=>props.modalClose(false)}>취소</button>
                </div>
            </div>
            </div>
        </div>
    )

}
