/* eslint-disable no-lone-blocks */
import React, {useEffect, useState} from 'react';
import Net from 'scripts/net';
import Common from 'scripts/common';
import { CustomTextField,  FileUpload ,QuillEditor,ImgUpload  } from 'components/molecules';
import {EditorState} from "draft-js";

export const BoardUpdateModal = (props) => {

    const [form, setForm] = useState({
        boardId: '',
        category: '',
        title: '',
        content: '',
        customer : {code: "", name: ""},
        site: {code: "", name: ""},
        user: props.data.user,
        attribute1: '',
        attribute2: '',
        fileData : '',
        fileDataurl : '',
        image: '',
        imageDataUrl: '',
    });
    const [customerName, setCustomerName] = useState('');
    const [siteName, setSiteName] = useState('');
    const [categoryName, setCategoryName] = useState('');
    const [addProperty1Name, setAddProperty1Name] = useState('');
    const [addProperty2Name, setAddProperty2Name] = useState('');

    const [editorState, setEditorState] = useState(EditorState.createEmpty());

    useEffect(() => {
        async function mounted(){
            if(props.data.boardDetail !== undefined){
                const response = await Net.getBoardOneAsync(props.data.boardDetail.boardId);
                if(response){
                    setForm({
                        ...form,
                        ...response.data
                    })
                    setEditorState(Common.htmlToDraftBlocks(response.data.content));
                    setCustomerName(response.data.customer.name);
                    setSiteName(response.data.site.name);
                    Net.getBoardMasterOne(props.data.boardDetail.category, response => {
                        if(response.status === 200 ){
                            setCategoryName(response.data.name);
                            if(response.data.addProperty1){
                                setAddProperty1Name(response.data.addProperty1);
                            }
                            if(response.data.addProperty2){
                                setAddProperty2Name(response.data.addProperty2);
                            }
                        } else {
                            alert('데이터 오류, 관리자에게 문의해주세요.')
                        }
                    });
                }

            }
        }
        mounted().then();
    },[]);


    const textFieldHandler = (name, value) => {
        const nextForm = {
            ...form,
            [name] : value
        }
        setForm(nextForm);
    }

    const onSubmitHandler = async () => {
        let result = window.confirm('게시글을 수정 하시겠습니까?');
        if(result){
            if( !form.title ) {
                alert('제목을 입력해 주세요.');
                document.getElementsByName('title')[0].focus();
                return false;
            }
            if( !form.content ) {
                alert('내용을 입력해 주세요.');
                return false;
            }
            const res = await Net.updateBoardAsync(form);
            if (res.status === 200 || res.status === 201) {
                props.modalClose(false);
                props.reload('reload');
            }
        }
    }

    const getFileUrl = (dataUrl, filename) => {
        setForm({
            ...form,
            fileData: dataUrl,
            fileName : filename
        });
    }

    const getImgFile = (dataUrl) => {
        setForm({
            ...form,
            imageDataUrl: dataUrl,
        });
    }

    const liftValue = (value) => {
        setForm({
            ...form,
            content: value,
        });
    }

    const onCancelHandler = () => {
        props.modalClose(false);
    }

    return (
        <div className="w-100 h-100">
            <div className="w-100 h-3"/>
            <div className="h-80 flex">
                <div className="w-60 h-100 overFlowYscrollView">
                        <div className="ms-5 mt-3">
                            <div className="flex mb-12 w-90">
                                <label className="label_title">구분  :</label>
                                <label className="label_title">{categoryName}</label>

                            </div>
                            {
                                props.data.user.menuGroup === 'ADMIN' ? (
                                        <div className="flex mb-12 w-90">
                                                <label className="label_title">고객사  : </label>
                                                <label className="label_title">{customerName}</label>
                                      </div>
                                ) : <></>
                            }
                            {
                                props.data.user.menuGroup === 'OPERATOR' ? <></> : (
                                    <div className="flex mb-3 mt-2 w-90">
                                        <label className="label_title">현장  :</label>
                                        <label className="label_title">{siteName}</label>

                                    </div>
                                )
                            }
                            <div className="flex mb-3 w-90">
                                <CustomTextField
                                    className="w-100 h-26px"
                                    title="제목"
                                    name="title"
                                    required={true}
                                    disabled={false}
                                    placeholder=""
                                    customOnChange={textFieldHandler}
                                    value={form}
                                />
                            </div>
                            <div className="flex mb-3 w-90">
                                <label className="label_title">내용 *</label>
                                <div className="w-100">
                                    <QuillEditor
                                        onChange={liftValue}
                                        value={editorState}
                                        setEditorState={setEditorState}
                                    />
                                </div>
                            </div>

                            {
                                addProperty1Name !== '' ?
                                    <div className="flex mb-3 w-90">
                                        <CustomTextField
                                            className="w-100 h-26px"
                                            title={addProperty1Name}
                                            name="attribute1"
                                            disabled={false}
                                            placeholder=""
                                            customOnChange={textFieldHandler}
                                            value={form}
                                        />
                                    </div> : <></>
                            }
                            {
                                addProperty2Name !== '' ?
                                    <div className="flex mb-3 w-90">
                                        <CustomTextField
                                            className="w-100 h-26px"
                                            title={addProperty2Name}
                                            name="attribute2"
                                            disabled={false}
                                            placeholder=""
                                            customOnChange={textFieldHandler}
                                            value={form}
                                        />
                                    </div> : <></>
                            }

                        </div>
                </div>
                <div className="w-35 h-100">
                    <div className="flex w-100">
                        <div className="w-90 h-100 mt-3 m-3" style={{display: "block"}}>
                            <div className="">
                                <ImgUpload
                                    title={"사진"}
                                    imgUpload={getImgFile}
                                    imgSize={"200px"}
                                    imgFile={form.imageDataUrl}
                                />
                            </div>
                            <FileUpload
                                title={"파일"}
                                inputName={"file_board"}
                                fileUpload={getFileUrl}
                                fileUrl={form.fileDataUrl}
                                fileName={form.fileName}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="footer_btn h-10 mt-2">
                <div className="">
                    <button className="w-160px h-30px addBtn mr-10" onClick={onSubmitHandler}>저장</button>
                    <button className="w-160px h-30px cancelBtn" onClick={onCancelHandler}>취소</button>
                </div>
            </div>
        </div>
)

}
