import React, {Fragment, useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom'
import {CustomTable, SearchInputBar} from 'components/molecules';
import store from 'scripts/store';
import Net from 'scripts/net';
import Common from 'scripts/common';
import {Card, CardBody} from "../../../components/molecules/Card";
import CardLeft from "../../../components/molecules/Card/CardLeft";
import {AiFillControl, FaUserAlt, FaUserCheck} from "react-icons/all";
import CardRight from "../../../components/molecules/Card/CardRight";
import classNames from "classnames";
import {toast} from "react-toastify";

const columnList = [
    {
        code: 'phoneNumber',
        title: '전화번호',
        width: '8',
        hidden : true
    },
    {
        code: 'username',
        title: '사용자ID',
        width: '8',
    },
    {
        code: 'nickname',
        title: '별칭',
        width: '9',
    },
    {
        code: 'customer.name',
        title: '고객사명',
        width: '8',
    },
    {
        code: 'createdDatetime',
        title: '가입 일시',
        width: '8',
    },
];

export const UncertifiedUser = (props) => {

    // useHistory 선언
    const history = useHistory();
    const user = props.userInfo;
    const [contentData, setContentData] = useState([]);
    const [checkedList, setCheckedList] = useState([]);
    const [homenetUsers, setHomenetUsers] = useState(0);
    const [loading, setLoading] =useState(false);

    const [searchCategory, setSearchCategory] = useState([
        [
            [
                {code: '', name: '고객사 선택'}
            ]
        ]
    ]);

    const [pageState, setPageState] = useState({
        currentPage: 0,
        rowPerPage: 10,
        searchCategory1 : '',
    });
    const [responseState, setResponseState] = useState({
        totalElementsCnt: 0,
        drawElementsCnt: 0,
        searchCategory1: user.menuGroup === 'ADMIN' ? '' : user.customer && user.customer.code,
    });

    useEffect(() => {
        loadData();
    }, [pageState.currentPage,pageState.rowPerPage]);

    useEffect(()=>{
        if(pageState.currentPage === 0){
            loadData();
        }else {
            setPageState({
                ...pageState,
                currentPage : 0
            });
        }

        Net.getUserList({
            currentPage : 0,
            rowPerPage : 5,
            searchCategory1 : pageState.searchCategory1
        }, (response) => {
            if(response.status === 200){
                setHomenetUsers(response.data.numberOfElements)
            }
        })
    },[pageState.searchCategory1])

    useEffect(()=>{
        store.openedPage = '미인증 사용자 현황';
        getCategoryList('');
    },[]);

    const getCategoryList = () => {
        Common.getCustomerList((response1) => {
            setSearchCategory([response1]);
        });
    }

    const getRelateCategory = (selectedCategory) => {
        if (selectedCategory === "reset") selectedCategory = "";
        setSearchCategory([
            searchCategory[0], // 고객사
        ]);
    }

    const onSubmitHandler = (e) => {
        setPageState({
            ...pageState,
            searchCategory1: e.selectedCategory[0],
        });
    }

    const gruopBy = ((list, keyGetter)=>{
       const map = new Map();
       list.forEach((item)=>{
           if(item.site){
               const key = keyGetter(item);
               const collection = map.get(key);
               if(!collection){
                   map.set(key, [item]);
               }else{
                   collection.push(item);
               }
           }
       });
       return map;
    })


    const loadData = async () => {
        setLoading(true);


        Net.getUnHomenetUserList(
            {
                currentPage: pageState.currentPage,
                rowPerPage: pageState.rowPerPage,
                searchCategory1 : pageState.searchCategory1
            }, (response) => {
                setLoading(false)
                if (response.status === 200) {
                    let newArray = [];
                    response.data.content.forEach((content) =>{
                        if(content.roleList && content.roleList[0] === "TEMPORARY"){
                            return null;
                        }else{
                            newArray = [
                                ...newArray,
                                content
                            ];
                        }
                    })

                    setContentData(newArray);
                    setResponseState({
                        ...responseState,
                        totalElementsCnt: response.data.totalElements,
                        drawElementsCnt: newArray.length,
                    });
                } else {
                    setContentData([]);
                    setResponseState({
                        ...responseState,
                        totalElementsCnt: 0,
                        drawElementsCnt: 0,
                    });
                }
            });
    }
    const onMovePage = (e) => {
        setPageState({
            ...pageState,
            currentPage: e-1
        });
    }
    const onClickRow = (e) => {

    }

    const sendMessage = async () => {
        const result = window.confirm('웰컴 안내톡을 전송하시겠습니까?');
        const sendList = [];
        checkedList.forEach((id) => {
            const node = document.querySelector(`#${id}`);
            const nodeTr = node.closest('tr');
            const phoneNumber = nodeTr.querySelector('td[name=phoneNumber]').textContent;
            const username = nodeTr.querySelector('td[name=username]').textContent;

            sendList.push({
                phoneNumber: phoneNumber,
                username: username
            });

        });
        if (result) {
            const response = await Net.sendWelcomeTalkAsyncAll(sendList);
            if (response) {
                toast('전송 완료', {
                    autoClose: 1500,
                    pauseOnHover: false,
                    draggable: false,
                    position: "top-center"
                });
                loadData().then(r => console.log('reset'));
            }
        }
    }



    return (
        <div className="w-100 h-100">
            <div className="w-100 h-1px bgC-efefef"/>
            <div className="h-93">
                <div className="row">
                    <SearchInputBar
                        className="col-md-8 ml-8 mb-1"
                        searchCategory={true}
                        searchCategoryGroup={searchCategory}
                        searchBtn={true}
                        searchBtnTitle="검색"
                        dynamicSearch={false}
                        relateCategoryHandler={user.menuGroup === 'ADMIN' && getRelateCategory}
                        onSubmit={onSubmitHandler}
                        refreshBtn={true}
                        refreshHandler={loadData}
                    />
                        <div className="container-fluid bgC-operator">
                                <div className="col-12 col-sm-8 col-md-10">
                                    <div className="row mt-2 mb-2 justify-content-center">
                                        <div className="col">
                                            <Card className="card_border">
                                                <CardBody className="px-3 py-3-4">
                                                    <div className="row">
                                                        <CardLeft className="col-md-8 bgc-purple icon-box"><AiFillControl size="24" color="white"/>
                                                        </CardLeft>
                                                        <CardRight className="col-md-8" title={"전체 회원 가입 수"} content={homenetUsers}>
                                                        </CardRight>
                                                    </div>
                                                </CardBody>
                                            </Card>
                                        </div>
                                        <div className="col">
                                            <Card className="card_border">
                                                <CardBody className="px-3 py-4-5">
                                                    <div className="row">
                                                        <CardLeft className="col-md-8 bgc-purple icon-box"><FaUserCheck size="24" color="white"/></CardLeft>
                                                        <CardRight className="col-md-8" title={"홈넷 인증 사용자 수"} content={homenetUsers - responseState.totalElementsCnt}/>
                                                    </div>
                                                </CardBody>
                                            </Card>
                                        </div>
                                        <div className="col">
                                            <Card className="card_border">
                                                <CardBody className="px-3 py-3-4">
                                                    <div className="row">
                                                        <CardLeft className="col-md-8 bgc-purple icon-box"><FaUserAlt size="24" color="white"/>
                                                        </CardLeft>
                                                        <CardRight className="col-md-8" title={"홈넷 미인증 사용자수"} content={responseState.totalElementsCnt}>
                                                        </CardRight>
                                                    </div>
                                                </CardBody>
                                            </Card>
                                        </div>
                                    </div>
                                </div>
                        </div>

                        <span className="totalCnt">Total {responseState.totalElementsCnt} </span>
                        <span className="col-7 text-muted card-title ml-4 mt-20 mb-20"> ※ 임시 사용자 제외</span>
                    {
                        checkedList.length > 0 ?
                            <button
                                className="downloadBtn w-120px h-30px mt-3 me-3"
                                onClick={sendMessage}
                            >메시지 발송</button> :
                            <button
                                className="downloadBtn w-120px h-30px mt-3 me-3"
                                disabled={true}
                            >메시지 발송</button>
                    }

                    <input
                        className="searchText sm-searchText mr-10 mt-15 floatR"
                        type="number"
                        value={pageState.rowPerPage}
                        onChange={(e)=>setPageState({
                            ...pageState,
                            rowPerPage: e.target.value,
                            currentPage: 0
                        })}/>
                    <button
                        className="removeBtn w-150px h-30px floatR mt-15 mr-10"
                    >페이지 행 수 지정</button>

                    <div className="">
                            <CustomTable
                                className={"h-50vh overflowYscroll"}
                                columnList={columnList}
                                contentData={contentData}
                                checkbox={true}
                                checkedList={(checkedList) => {
                                    setCheckedList(checkedList)
                                }}
                                paging={true}
                                totalElementsCnt={responseState.totalElementsCnt}
                                rowPerPage={pageState.rowPerPage}
                                currentPage={pageState.currentPage}
                                drawElementsCnt={responseState.drawElementsCnt}
                                pagination={onMovePage}
                                rowClickEvt={true}
                                onClickRow={onClickRow}
                                loading={loading}
                                // sort = {true}
                            />
                        </div>
                    </div>
                </div>
        </div>
    );
}

