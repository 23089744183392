import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { CustomTable, SearchInputBar, BasicModal } from 'components/molecules';
import {usePageAuth, useDidMountEffect } from "scripts/hooks";
import store from 'scripts/store';
import Net from 'scripts/net';
import Common from 'scripts/common';
import {TelecomDvAttrMapp} from "./modal/TelecomDvAttrMapp";
import {CvGgDvAttrMapp} from "./modal/CvGgDvAttrMapp";

const columnList = [
    {
        code: 'code',
        title: '',
        width: '',
        hidden: true,
    },
    {
        code: 'extensionService',
        title: '음성 서비스',
        width: '8',
    },
    {
        code: 'typeCode',
        title: 'IoK 타입',
        width: '8',
    },
    {
        code: 'modelCode',
        title: 'IoK 모델',
        width: '8',
    },
    {
        code: 'extensionType',
        title: 'Third Party 타입',
        width: '8',
    },
    {
        code: 'jsonTooltip',
        title: '기타 속성',
        width: '5',
        tdClickEvent: true,
    },
];

export const VoiceRecognitionSettingList = (props) => {

    // useHistory 선언
    const history = useHistory();
    const user = props.userInfo;
    const programAuth = usePageAuth(props.userInfo.authList[0], sessionStorage.getItem('menuId'));
    const [contentBridgeData, setContentBridgeData] = useState([]);
    const [contentTelecomData, setContentTelecomData] = useState([]);
    const [contentData, setContentData] = useState([]);
    const [contentBaseData, setContentBaseData] = useState([]);
    const [displaytData, setDisplayData] = useState([]);
    const [checkedList, setCheckedList] = useState([]);
    const [searchCategoryGroup, setSearchCategoryGroup] = useState([
        [
                {code: '', name: '음성서비스'},
                {code: 'clova', name: '클로바'},
                {code: 'google', name: '구글'},
                {code: 'skt', name:'SKT'},
                {code: 'lguplus', name:'LGU+'},
                {code: 'samsung', name:'samsung'},
                {code: 'kt', name:'kt'},
        ],
        [
            [
                {code: '', name: '장비 타입 선택'},
            ]
        ]
    ]);
    const [modalState, setModalState] = useState({
        open: false,
        data: [],
        clickedBtnName: '',
    });

    const [pageState, setPageState] = useState({
        totalElementsCnt: 0,
        drawElementsCnt: 0,
    });

    const [searchState, setSearchState] = useState({
        currentPage: 1,
        rowPerPage: 99999,
        voiceService : '',
        deviceType : '',
    })

    useEffect(() => {
        store.openedPage = '음성서비스 장비 타입 설정 현황';
        getToolType();
        loadData();
    }, []);

    useDidMountEffect(()=>{
        let tempData = contentTelecomData.concat(contentBridgeData);
        setContentData(tempData);
        setContentBaseData(tempData);
        console.log(tempData);

    },[contentBridgeData, contentTelecomData])

    useDidMountEffect(()=>{
        const dataLength = contentData.length;
        setPageState({
            totalElementsCnt: dataLength,
            drawElementsCnt: dataLength > 13 ? 13 : dataLength
        })
        setSearchState({
            ...searchState,
            currentPage: 1,
        })
        setDisplayData(contentData.slice(0,dataLength > 13 ? 13 : dataLength));
    },[contentData]);

    useDidMountEffect(()=>{
        setDisplayData(contentData.slice((searchState.currentPage-1)*13, ((searchState.currentPage-1)*13)+13 > pageState.totalElementsCnt ? pageState.totalElementsCnt-1 : ((searchState.currentPage-1)*13)+13));
    },[searchState.currentPage]);

    useDidMountEffect(()=>{
        let tempData = contentBaseData;
        let tempData_ = [];

        if(searchState.voiceService === '' && searchState.deviceType === ''){
            setContentData(tempData);
        }else if(searchState.voiceService === '' && searchState.deviceType !== ''){
            tempData_ = tempData.filter(item=>item.typeCode === searchState.deviceType);
            setContentData(tempData_);
        }else if(searchState.voiceService !== '' && searchState.deviceType === ''){
            tempData_ = tempData.filter(item=>item.extensionService === searchState.voiceService);
            setContentData(tempData_);
        }else{
            tempData_ = tempData.filter(item=>item.extensionService === searchState.voiceService).filter(item=>item.typeCode === searchState.deviceType);
            setContentData(tempData_);
        }
    },[searchState.deviceType, searchState.voiceService])

    useEffect(()=>{
        console.log(displaytData);
    },[displaytData])

    useEffect(() => {

    }, [searchState.currentPage, searchState.deviceType, searchState.voiceService]);

    const getToolType = () => {
        Common.getToolsTypeList((response)=>{
            setSearchCategoryGroup([searchCategoryGroup[0], response]);
        })
    }

    const loadData =  () => {
        Net.getBridgeSettingType(searchState,(response) => {
            if(response.status === 200 ) {
                setContentBridgeData(response.data.content.map(item => {
                    if(item.googleAttributes)
                        item.jsonTooltip = item.googleAttributes
                    return item;
                }));
                Net.getTelecomSettingType(searchState, (response)=>{
                    if(response.status === 200){
                        setContentTelecomData(response.data.content.map(item => {
                            if(item.attribute)
                                item.jsonTooltip = item.attribute
                            return item;
                        }));
                    }
                })
            }
        });
    }

    const onMovePage = (e) => {
        setSearchState({
            ...searchState,
            currentPage: e
        });
    }

    const onSubmitHandler = (e) => {
        if (user.menuGroup === 'ADMIN') {
            setSearchState({
                ...searchState,
                currentPage: 1,
                voiceService: e.selectedCategory[0] ? e.selectedCategory[0] : '',
                deviceType: e.selectedCategory[1] ? e.selectedCategory[1] : '',
            });
        }
    }

    const reload = async (e) => {
        if(e === 'reload'){

        }
    }

    const sendData = (e) => {
        setModalState({
            ...modalState,
            data: e,
        })
    }

    const onClickRow = (e) => {
        /*
        *  팝업 창 띄우기
        * */
        if(e.extensionService === 'clova' || e.extensionService === 'google'){
            setModalState({
                ...modalState,
                open: !modalState.open,

                propsData: {
                    userInfo : user,
                    data : {
                        extensionService : e.extensionService,
                        deviceType : e.typeCode
                    }
                },
                clickedBtnName: '타입별 장비 속성 맵핑(클로바,구글)',
            });
        }else{
            setModalState({
                ...modalState,
                open: !modalState.open,

                propsData: {
                    userInfo : user,
                    data : {
                        extensionService : e.extensionService,
                        deviceType : e.typeCode,
                        modelCode : e.modelCode

                    }
                },
                clickedBtnName: '통신사 타입별 장비 속성 맵핑',
            });
        }
    }

    const tdOnClickEvent = (e, list) => {
        /*
        * 기타 속성 팝업 띄우기
        * */
        console.log(e, list);

    }

    return (
        <div className="w-100 h-100">
            <BasicModal
                className="w-80 h-88"
                title={`${modalState.clickedBtnName}`}
                content={
                    <ModalContent
                        sendData={sendData}
                        modalType={modalState.clickedBtnName}
                        propsData={modalState.propsData}
                        modalClose={(e) => setModalState({
                            ...modalState,
                            open: e
                        })}
                        reload={reload}
                        deleteAuth = {programAuth.deleteAuth}
                    />
                }
                modalOpen={modalState.open}
                modalClose={() => setModalState({
                    ...modalState,
                    open: false
                })}
                sendData={modalState.data}
            />
            <div className="w-100 h-7 display_table">
                <SearchInputBar
                    className="w-100"
                    searchCategory={user.menuGroup === 'ADMIN'}
                    searchCategoryGroup={searchCategoryGroup}
                    searchBtn={true}
                    searchBtnTitle="검색"
                    dynamicSearch={false}
                    onSubmit={onSubmitHandler}
                />
            </div>
            <div className="w-100 h-1px bgC-efefef" />
            <div className="flex h-93">
                <div className="w-100 h-100 overflowYscroll">
                    <p className="totalCnt">Total {pageState.totalElementsCnt}</p>

                    <CustomTable
                        columnList = {columnList}
                        contentData = {displaytData}
                        paging = {true}
                        totalElementsCnt = {pageState.totalElementsCnt}
                        rowPerPage = {13}
                        currentPage = {searchState.currentPage-1}
                        drawElementsCnt = {pageState.drawElementsCnt}
                        tdClickEvt={tdOnClickEvent}
                        rowClickEvt={true}
                        pagination = {onMovePage}
                        rowClickEvt = {true}
                        onClickRow = {onClickRow}
                    />
                </div>
            </div>
        </div>
    )
}

export const ModalContent = (props) => {
    switch (props.modalType) {
        case "통신사 타입별 장비 속성 맵핑":
            return <TelecomDvAttrMapp data={props.propsData} modalClose={props.modalClose}/>
        case "타입별 장비 속성 맵핑(클로바,구글)":
            return <CvGgDvAttrMapp data={props.propsData} modalClose={props.modalClose}/>
        default:
            break;
    }
}

