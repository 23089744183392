import React from 'react';
import classnames from 'classnames/bind';


const cx = classnames.bind();

export default function CardRight(props) {

    const {className} = props;

    return (
        <div className={cx("mt-2 ml-2", className)}>
            <h5 className="text-muted card-title cardTitle">{props.title}</h5>
            <h6 className="">{props.content}</h6>
        </div>
    );
}
