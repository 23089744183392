import React from 'react'
import { Switch, Route} from 'react-router-dom';
import { CustomerList, CustomerRegist } from './CustomerPage';
import {
    SiteList,
    SiteRegist,
    FacilityList,
    FacilityRegist,
    FloorPlanList,
    FloorPlanRegist,
    SiteServiceRegist,
    SiteServiceList,
    AppServiceList
} from './SitePage';
import {SitePlaceList, SitePlaceRegist, SitePlaceUploadList} from './SitePlacePage';
import NotFound from "../../components/templates/NotFound";
import {SitePlaceGrid} from "./SitePlacePage/SitePlaceGrid";

export const LocationPage = ({userInfo}) => {
    return (
        <Switch>
            <Route path={"/location/customer/list"} exact children={<CustomerList userInfo={userInfo}/>}/>
            <Route path={"/location/site/list"} exact children={<SiteList userInfo={userInfo}/>}/>
            <Route path={"/location/siteService/list"} exact children={<SiteServiceList userInfo={userInfo}/>}/>
            <Route path={"/location/appService/list"} exact children={<AppServiceList userInfo={userInfo}/>}/>
            <Route path={"/location/facility/list"} exact children={<FacilityList userInfo={userInfo}/>}/>
            <Route path={"/location/floorPlan/list"} exact children={<FloorPlanList userInfo={userInfo}/>}/>
            <Route path={"/location/siteplace/list"} exact children={<SitePlaceList userInfo={userInfo}/>}/>
            <Route path={"/location/siteplace/grid"} children={<SitePlaceGrid userInfo={userInfo}/>} />
            <Route path={"/location/siteplace/upload"} children={<SitePlaceUploadList userInfo={userInfo}/>} />
            <Switch>
                <Route path={"/location/customer/regist/:code"} children={<CustomerRegist/>} />
                <Route path={"/location/customer/regist"} children={<CustomerRegist/>} />

                <Route path={"/location/site/regist/:code"} children={<SiteRegist/>} />
                <Route path={"/location/site/regist"} children={<SiteRegist/>} />

                <Route path={"/location/siteService/regist/:code"} children={<SiteServiceRegist/>} />
                <Route path={"/location/siteService/regist"} children={<SiteServiceRegist/>} />

                <Route path={"/location/facility/regist/:code"} children={<FacilityRegist/>} />
                <Route path={"/location/facility/regist"} children={<FacilityRegist/>} />

                <Route path={"/location/floorPlan/regist/:code"} children={<FloorPlanRegist/>} />
                <Route path={"/location/floorPlan/regist"} children={<FloorPlanRegist/>} />

                <Route path={"/location/siteplace/regist/:code"} children={<SitePlaceRegist/>} />
                <Route path={"/location/siteplace/regist"} children={<SitePlaceRegist/>} />
            </Switch>
            <Route path={"*"}>
                <NotFound />
            </Route>
        </Switch>
    )
}
