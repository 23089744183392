import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { CustomTable, SearchInputBar } from 'components/molecules';
import store from 'scripts/store';
import Net from 'scripts/net';

const columnList = [
    {
        code: 'code',
        title: '',
        width: '',
        hidden: true,
    },
    {
        code: 'site.name',
        title: '현장명',
        width: '15',
    },
    {
        code: 'customer.name',
        title: '고객사명',
        width: '15',
    },
    {
        code: 'commonService',
        title: '공용부 서비스',
        width: '8',
    },
    {
        code: 'deviceService',
        title: '세대장비 서비스',
        width: '15',
    },
];

export const SiteServiceList = (props) => {

    // useHistory 선언
    const history = useHistory();
    const user = props.userInfo;
    const [contentData, setContentData] = useState([]);
    const [checkedList, setCheckedList] = useState([]);
    const [clickRow, setClickRow] = useState(null);
    const [loading, setLoading] = useState(false);
    const [searchCategoryGroup, setSearchCategoryGroup] = useState([
        [
            [
                {code: '', name: '고객사 선택'}
            ]
        ],
    ]);

    const [pageState, setPageState] = useState({
        totalElementsCnt: 0,
        drawElementsCnt: 0,
    });

    const [searchState, setSearchState] = useState({
        searchText: user.menuGroup === 'OPERATOR' ? user.site && user.site.name: '',
        customerCode: user.menuGroup === 'ADMIN' ? '' : user.customer && user.customer.code,
        currentPage: 1,
        rowPerPage: 13,
    })

    const [programAuth, setProgramAuth] = useState({});
    useEffect(() => {
        store.openedPage = '현장별 서비스 목록';
        if (user.menuGroup === 'ADMIN') {
            getCustomerList((response1) => {
                setSearchCategoryGroup([response1],);
            });
        }
        pageAuthInfo();
    }, []);

    useEffect(() => {
        loadData();
    }, [searchState.currentPage, searchState.searchText, searchState.customerCode]);

    const pageAuthInfo = () => {
        const authGroupCode = props.userInfo.authList[0];
        const menuId = sessionStorage.getItem('menuId');

        Net.getAuthGroupMenuList(menuId, (response) => {
            if(response.status === 200) {
                response.data.forEach(item => {
                    if(item.authGroup === authGroupCode && item.authGroupMenu) {
                        setProgramAuth({
                            searchAuth: Boolean(item.authGroupMenu.searchAuth),
                            saveAuth: Boolean(item.authGroupMenu.saveAuth),
                            excelAuth: Boolean(item.authGroupMenu.excelAuth),
                            deleteAuth: Boolean(item.authGroupMenu.deleteAuth),
                        });
                    }
                });
            }
        });
    }

    const loadData = () => {
        setLoading(true);
        Net.getSitesMngitemList(searchState, (response) => {
            setLoading(false);
            if (response.status === 200) {
                setContentData(response.data.content.map(item => {
                    let deivceServiceCount = 0;
                    let commonServiceCount = 0;
                    if(item.gas) deivceServiceCount += 1;
                    if(item.valve) deivceServiceCount += 1;
                    if(item.electricCurtain) deivceServiceCount += 1;
                    if(item.hybrid) deivceServiceCount += 1;
                    if(item.light) deivceServiceCount += 1;
                    if(item.lightNormal) deivceServiceCount += 1;
                    if(item.lightDimming) deivceServiceCount += 1;
                    if(item.lightColor) deivceServiceCount += 1;
                    if(item.standbyPower) deivceServiceCount += 1;
                    if(item.allLightsOut) deivceServiceCount += 1;
                    if(item.heating) deivceServiceCount += 1;
                    if(item.systemAirConditioner) deivceServiceCount += 1;
                    if(item.wallPadLinkage) deivceServiceCount += 1;
                    if(item.wifiLinkage) deivceServiceCount += 1;
                    if(item.ventilation) deivceServiceCount += 1;
                    if(item.indoorAirQualitySensor) deivceServiceCount += 1;
                    if(item.interlockingIndoorAirQualitySensor) deivceServiceCount += 1;
                    if(item.electricCurtain) deivceServiceCount += 1;
                    if(item.elevatorCall) deivceServiceCount += 1;
                    if(item.householdVisitors) deivceServiceCount += 1;
                    if(item.commonEntrance) deivceServiceCount += 1;
                    if(item.blackbox) deivceServiceCount += 1;
                    if(item.crimePreventionMode) deivceServiceCount += 1;
                    if(item.emergency) deivceServiceCount += 1;
                    if(item.wallpadEmergency) deivceServiceCount += 1;
                    if(item.emergencyLadder) deivceServiceCount += 1;
                    if(item.fire) deivceServiceCount += 1;
                    if(item.restroom) deivceServiceCount += 1;
                    if(item.crimePreventionEmergency) deivceServiceCount += 1;
                    if(item.ventilationLinkage) deivceServiceCount += 1;

                    if(item.householdRegistrationEntryAndExit) commonServiceCount += 1;
                    if(item.householdVehicleParkingLocation) commonServiceCount += 1;
                    if(item.visitingVehicleRegistration) commonServiceCount += 1;
                    if(item.visitingVehicleEntryAndExit) commonServiceCount += 1;
                    if(item.visitingVehicleParkingLocation) commonServiceCount += 1;
                    if(item.unmannedDelivery) commonServiceCount += 1;
                    if(item.deliveryByCourier) commonServiceCount += 1;
                    if(item.householdDelivery) commonServiceCount += 1;
                    if(item.ems) commonServiceCount += 1;
                    if(item.emsRemoteMeterReading) commonServiceCount += 1;
                    if(item.emsTarget) commonServiceCount += 1;
                    if(item.emsElectricity) commonServiceCount += 1;
                    if(item.emsGas) commonServiceCount += 1;
                    if(item.emsWater) commonServiceCount += 1;
                    if(item.emsHeating) commonServiceCount += 1;
                    if(item.emsHotWater) commonServiceCount += 1;

                    item.deviceService = deivceServiceCount;
                    item.commonService = commonServiceCount;
                    return item;
                }));
                setPageState({
                    ...pageState,
                    totalElementsCnt: response.data.totalElements,
                    drawElementsCnt: response.data.numberOfElements,
                });

            } else if (response.status === 204) {
                setContentData([]);
                setPageState({
                    ...pageState,
                    totalElementsCnt: 0,
                    drawElementsCnt: 0,
                });
            }
        });
    }

    const getCustomerList = (callback) => {
        Net.getCustomerList({currentPage: '', rowPerPage: '', searchText: ''}, (response) => {
            if(response.status === 200) {
                let newArray = searchCategoryGroup[0][0];
                response.data.content.forEach(items => {
                    newArray = [
                        ...newArray,
                        {
                            code: items.code,
                            name: items.name
                        }
                    ]
                });
                callback(newArray);
            }
        });
    }

    const onMovePage = (e) => {
        setSearchState({
            ...pageState,
            currentPage: e
        });
    }

    const onSubmitHandler = (e) => {
        if (user.menuGroup === 'ADMIN') {
            setSearchState({
                ...searchState,
                currentPage: 1,
                searchText: e.searchText,
                customerCode: e.selectedCategory[0],
            });
        } else {
            setSearchState({
                ...searchState,
                currentPage: 1,
                searchText: e.searchText,
            });
        }
    }

    const addList = () => {
        history.push('/location/siteService/regist');
    }

    const onClickRow = (e) => {
        setClickRow(e.code);
    }

    const goModifypage = () => {
        history.push(`/location/siteService/regist/${clickRow}`);
    }

    const removeList = () => {
        const result = window.confirm('삭제하시겠습니까?');
        const removeCodeList = [];
        checkedList.forEach((id) => {
            const node = document.querySelector(`#${id}`);
            const nodeTr = node.closest('tr');
            const codeValue = nodeTr.querySelector('td[name=code]').textContent;
            removeCodeList.push(codeValue);
        });

        if(result) {
            Net.removeSitesMngitem(removeCodeList, (response) => {
                if(response) {
                    loadData();
                }
            });
        }
    }

    return (
        <div className="w-100 h-100">
            <div className="w-100 h-7 display_table">
                <SearchInputBar
                    className="w-100"
                    searchCategory={user.menuGroup === 'ADMIN'}
                    searchCategoryGroup={searchCategoryGroup}
                    searchText={user.menuGroup === 'ADMIN' || user.menuGroup === 'MANAGER'}
                    searchPlaceHolder="현장명을 입력해 주세요."
                    searchBtn={user.menuGroup === 'ADMIN' || user.menuGroup === 'MANAGER'}
                    searchBtnTitle="검색"
                    dynamicSearch={false}
                    onSubmit={onSubmitHandler}
                    refreshBtn={true}
                    refreshHandler={loadData}
                />
                {
                    programAuth.saveAuth &&
                    <div className="display_table_cell v-middle">
                        <button
                            className="addBtn w-90px h-30px floatR mr-20"
                            onClick={addList}
                        >등록
                        </button>
                    </div>
                }
                {
                    clickRow ?
                        (
                            <div className="display_table_cell v-middle">
                                <button
                                    className="defaultBtn bgC-eb878c borderC-eb878c w-90px h-30px mr-20  "
                                    onClick={goModifypage}
                                >수정
                                </button>
                            </div>
                        ) : ""
                }
            </div>
            <div className="w-100 h-1px bgC-efefef" />
            <div className="flex h-93">
                <div className="w-100 h-100 overflowYscroll">
                    <p className="totalCnt">Total {pageState.totalElementsCnt}</p>
                    {
                        programAuth.deleteAuth && checkedList.length > 0 &&
                        <button
                            className="removeBtn w-90px h-30px floatR mt-7 mr-20"
                            onClick={removeList}
                        >삭제</button>
                    }
                    <CustomTable
                        columnList = {columnList}
                        contentData = {contentData}
                        checkbox = {programAuth.deleteAuth}
                        checkedList = {(checkedList) => {setCheckedList(checkedList)} }
                        paging = {true}
                        totalElementsCnt = {pageState.totalElementsCnt}
                        rowPerPage = {searchState.rowPerPage}
                        currentPage = {searchState.currentPage - 1}
                        drawElementsCnt = {pageState.drawElementsCnt}
                        pagination = {onMovePage}
                        rowClickEvt = {true}
                        onClickRow = {onClickRow}
                        loading={loading}
                        // sort = {true}
                    />
                </div>
            </div>
        </div>
    )
}
