import React, { useRef, useState } from 'react'
import {Link} from 'react-router-dom';
import './ImageCard.scss';


const ImageCard = ({
                  children,
                  title,
                  description,
                  imgSrc,
                  href,
                  onlyImg = false,
                  className,
                  mdSize = true,
                  href2 = '/',
                  addLink,

              }) => {
    const ref = useRef(null)
    const [xys, set] = useState([0, 0, 1])

    return (
        <div className=
                 {`${!onlyImg && mdSize && 'w-full md:w-1/3 max-height-900'} ${className} overflow-hidden rounded-md m-2 bg-1 `}>
            <div
            className="row"
                style={{
                display: "flex",
                justifyContent: "center",
                position : "relative"
            }}>
                <div className="col-12 flex object-center  px-4">
                    <img alt={title} src={imgSrc} className="object-cover w-full max-height-200 max-width-100 rounded-md col-12 box-shadow-21"/>
                </div>
                {!onlyImg && (
                <div className="col-12 object-center px-4 ">
                   <div className="bg-white rounded-md py-3 box-shadow-21">

                   <h2 className="mb-2 text-2xl text-gray-900 font-bold leading-8 tracking-tight">{title}</h2>
                        <p className="mb-2 prose text-gray-900 max-w-none dark:text-gray-400 mt-4 mb-3">
                            {description} {addLink ? <>
                            <a target='_blank' href={addLink}>사용 설명서</a>
                        </> : <></>}
                        </p>
                        <a href={href2} target='_blank' aria-label={`Link to ${title}`}>
                            <div
                                className="text-xl font-thin leading-6 text-primary-500 hover:text-primary-600 border-gray-200">
                                <span className="font-medium" style={{color: 'blue'}}> › 바로 가기</span>
                            </div>
                        </a>
                    </div>
                </div>)}
            </div>
        </div>
    )
};

export default ImageCard
