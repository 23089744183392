import React, {useState, useEffect} from 'react';
import {CustomTable, SearchInputBar} from 'components/molecules';
import store from 'scripts/store';
import Net from 'scripts/net';

const columnList = [
    {
        code: 'no',
        title: 'No',
        width: '5',
    },
    {
        code: 'carNumber',
        title: '차량번호',
        width: '8',
    },
    {
        code: 'reservationDatetime',
        title: '예약 일시',
        width: '9',
    },
    {
        code: 'reservationStartDate',
        title: '입차 가능 일',
        width: '8',
    },
    {
        code: 'reservationEndDate',
        title: '출차 예정 일',
        width: '10',
    },
    {
        code: 'place.name',
        title: '장소',
        width: '8',
    },
    {
        code: 'site.name',
        title: '현장명',
        width: '8',
    },
    {
        code: 'customer.name',
        title: '고객사명',
        width: '6',
    },
];

function VisitingcarsList(props) {
    const user = props.userInfo;
    const [contentData, setContentData] = useState([]);
    const [checkedList, setCheckedList] = useState([]);
    const [searchCategory, setSearchCategory] = useState([
        [
            [
                {code: '', name: '고객사 선택'}
            ]
        ],
        [
            [
                {code: '', name: '현장명 선택'}
            ]
        ]
    ]);

    const [pageState, setPageState] = useState({
        currentPage: 0,
        rowPerPage: 13,
        totalElementsCnt: 0,
        drawElementsCnt: 0,
        searchText: '',
        searchStartDate: '',
        searchEndDate: '',
        searchCategory1: user.menuGroup === 'ADMIN' ? '' : user.customer && user.customer.code,
        searchCategory2: user.menuGroup === 'OPERATOR' ? user.site && user.site.code: '',

    });

    const [programAuth, setProgramAuth] = useState({});

    useEffect(() => {
        store.openedPage = '방문자 차량 출입 목록';
        getCategoryList('');
        pageAuthInfo();

    }, []);

    useEffect(() => {
        setTimeout(() => {
            loadData();
        }, 10)

    }, [pageState.currentPage,
        pageState.searchText,
        pageState.searchCategory1,
        pageState.searchCategory2]);

    const pageAuthInfo = () => {
        const authGroupCode = props.userInfo.authList[0];
        const menuId = sessionStorage.getItem('menuId');

        Net.getAuthGroupMenuList(menuId, (response) => {
            if (response.status === 200) {
                response.data.forEach(item => {
                    if (item.authGroup === authGroupCode && item.authGroupMenu) {
                        setProgramAuth({
                            searchAuth: Boolean(item.authGroupMenu.searchAuth),
                            saveAuth: Boolean(item.authGroupMenu.saveAuth),
                            excelAuth: Boolean(item.authGroupMenu.excelAuth),
                            deleteAuth: Boolean(item.authGroupMenu.deleteAuth),
                        });
                    }
                });
            }
        });
    }

    const loadData = () => {
        Net.getVisitingcarsList(pageState, (response) => {
            if (response.status === 200) {
                setContentData(response.data.content);
                setPageState({
                    ...pageState,
                    totalElementsCnt: response.data.totalElements,
                    drawElementsCnt: response.data.numberOfElements,
                });
            } else {
                setContentData([]);
                setPageState({
                    ...pageState,
                    totalElementsCnt: 0,
                    drawElementsCnt: 0,
                });
            }
        });
    }

    const getCategoryList = () => {
        if (user.menuGroup === 'ADMIN') {
            getCustomerList((response1) => {
                getSitesList(null, (response2) => {
                    setSearchCategory([response1, response2]);
                });
            });
        } else if (user.menuGroup === 'MANAGER') {
            getSitesList(pageState.searchCategory1, (response2) => {
                setSearchCategory([response2]);
            });
        }
    }

    const getRelateCategory = (selectedCategory) => {
        if(selectedCategory === "reset") selectedCategory = "";
        getSitesList(selectedCategory, (response2) => {
            setSearchCategory([
                searchCategory[0],
                response2
            ]);
        });
    }

    const getCustomerList = (callback) => {
        Net.getCustomerList(
            {currentPage: '', rowPerPage: '', searchText: ''}, (response) => {
                if(response.status === 200) {
                    let newArray = searchCategory[0][0];
                    response.data.content.forEach(items => {
                        newArray = [
                            ...newArray,
                            {
                                code: items.code,
                                name: items.name
                            }
                        ]
                    });
                    callback(newArray);
                }
            });
    }

    const getSitesList = (selectedCategory, callback) => {
        if(selectedCategory){
            Net.getSitesList(
                {currentPage: '', rowPerPage: '', searchText: '', customerCode: selectedCategory?selectedCategory:false}, (response) => {
                    if(response.status === 200) {
                        let newArray =  [{code:'',name:"현장명 선택"}];
                        if(response.data.content.length > 0) {
                            response.data.content.forEach(items => {
                                newArray = [
                                    ...newArray,
                                    {
                                        code: items.code,
                                        name: items.name
                                    }
                                ]

                            })
                        }
                        callback(newArray);
                    } else {
                        let newArray = [{code:'',name:"현장명 선택"}];
                        callback(newArray);
                    }
                });
        } else {
            let newArray = [{code:'',name:"현장명 선택"}];
            callback(newArray);
        }
    }

    const onMovePage = (e) => {
        setPageState({
            ...pageState,
            currentPage: e-1
        });
    }

    const onSubmitHandler = (e) => {
        if (user.menuGroup === 'ADMIN') {
            setPageState({
                ...pageState,
                currentPage: 0,
                searchText: e.searchText,
                searchCategory1: e.selectedCategory[0],
                searchCategory2: e.selectedCategory[1],
            });

        } else if (user.menuGroup === 'MANAGER') {
            setPageState({
                ...pageState,
                searchText: e.searchText,
                searchCategory1: pageState.searchCategory1,
                searchCategory2: e.selectedCategory[0],
            });
        }
    }

    const searchDateHandler = (startDate, endDate) => {
        startDate = startDate.getFullYear() + '-' + ('0' + (Number(startDate.getMonth()) + 1)).slice(-2) + '-' + ('0' + startDate.getDate()).slice(-2) + ' 00:00';
        endDate = endDate.getFullYear() + '-' + ('0' + (Number(endDate.getMonth()) + 1)).slice(-2) + '-' + ('0' + endDate.getDate()).slice(-2)+ ' 23:59';

        if(!pageState.searchStartDate && !pageState.searchEndDate) {
            pageState.searchStartDate = startDate;
            pageState.searchEndDate = endDate;
        } else {
            setPageState({
                ...pageState,
                searchStartDate: startDate,
                searchEndDate: endDate,
            });
        }
    }

    return (
        <div>
            <div className="w-100 h-100">
                <div className="w-100 h-7 display_table">
                    <SearchInputBar
                        className="w-100"
                        searchCategory={user.menuGroup === 'ADMIN' || user.menuGroup === 'MANAGER'}
                        searchCategoryGroup={searchCategory}
                        searchText={true}
                        searchPlaceHolder="차량 번호를 입력해주세요."
                        searchBtn={true}
                        searchBtnTitle="검색"
                        dynamicSearch={false}
                        searchDate={searchDateHandler}
                        relateCategoryHandler={user.menuGroup === 'ADMIN' && getRelateCategory}
                        onSubmit={onSubmitHandler}
                    />
                </div>
                <div className="w-100 h-1px bgC-efefef" />
                <div className="flex">
                    <div className="w-100 h-93 overflowYscroll">
                        <p className="totalCnt">Total {pageState.totalElementsCnt}</p>
                        <CustomTable
                            columnList = {columnList}
                            contentData = {contentData}
                            checkbox={programAuth.deleteAuth}
                            checkedList = {(checkedList) => {setCheckedList(checkedList)} }
                            paging = {true}
                            totalElementsCnt = {pageState.totalElementsCnt}
                            rowPerPage = {pageState.rowPerPage}
                            currentPage = {pageState.currentPage}
                            drawElementsCnt = {pageState.drawElementsCnt}
                            pagination = {onMovePage}
                            rowClickEvt = {false}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default VisitingcarsList;
