import React, { useEffect, useState, Fragment } from 'react'
import { withRouter, useHistory } from 'react-router-dom'
import { CustomTextField, CustomRadioButton } from 'components/molecules';
import store from 'scripts/store';
import Net from 'scripts/net';

const ToolsPropertyRegist = ({match}) => {

    const history = useHistory();
    const [form, setForm] = useState({
        id: '',
        code: '',
        name: '',
        valueType: '',
        availableValues: [''],
        topValue: '',
        bottomValue: '',
        resolution: '',
        unit: '',
        // usable: true,
    });
    const [registPage, setRegistPage] = useState(true);


    useEffect(() => {
        store.openedPage = '장비속성 등록/수정';

        // 상세 조회
        if(match.params.code !== undefined) {
            Net.getToolsPropertyOne(match.params.code, (response) => {
                if(response.status === 200) {
                    setForm({
                        ...form,
                        ...response.data,
                    });

                    // 등록페이지 여부 확인
                    setRegistPage(false);
                }
            });
        }
    }, []);

    const textFieldHandler = (name, value) => {
        const nextForm = {
            ...form,
            [name]: value
        }
        setForm(nextForm);
    }

    const textFieldHandler4Array = (name, value, index) => {
        form.availableValues[index] = value;
        setForm({
            ...form,
            availableValues: [
                ...form.availableValues
            ]
        });
    }

    const radioBtnHandler = (name, value) => {
        let init = {};
        if(match.params.code === undefined) {
            if(value === 'RANGE') {
                init = {
                    availableValues: [''],
                }
            } else if(value === 'ENUMERATION') {
                init = {
                    bottomValue: '',
                    topValue: '',
                    unit: '',
                }
            }
        }

        const nextForm = {
            ...form,
            [name]: value,
            ...init,
        }
        setForm(nextForm);
    }

    const onSubmitHandler = () => {
        if(form.code === '') {
            alert('장비속성코드는 필수값입니다.');
            document.getElementsByName('code')[0].focus();
            return false;
        }
        if(form.name === '') {
            alert('장비속성명은 필수값입니다.');
            document.getElementsByName('name')[0].focus();
            return false;
        }
        if(form.valueType === '') {
            alert('속성형태는 필수값입니다.');
            document.getElementsByName('valueType')[0].focus();
            return false;
        }
        if(form.valueType === 'RANGE') {
            if(form.topValue === '') {
                alert('최고값은 필수값입니다.');
                document.getElementsByName('topValue')[0].focus();
                return false;
            }
            if(form.bottomValue === '') {
                alert('최저값은 필수값입니다.');
                document.getElementsByName('bottomValue')[0].focus();
                return false;
            }
            if(form.resolution === '') {
                alert('단계는 필수값입니다.');
                document.getElementsByName('resolution')[0].focus();
                return false;
            }
        }
        if(form.valueType === 'ENUMERATION') {
            let bool = false;
            form.availableValues.map((value, index) => {
                if(value === '') {
                    alert('열거값은 필수값입니다.');
                    document.getElementsByName(index)[0].focus();
                    bool = false;
                } else {
                    bool = true;
                }
            });
            if(!bool) {
                return false;
            }
        }

        var result = window.confirm('저장하시겠습니까?');
        if(result) {
            if(registPage) {
                Net.addToolsProperty(form, (response) => {
                    if(response.status === 201) {
                        history.push('/tools/property/list');
                    }
                });
            } else {
                Net.updateToolsProperty(form, (response) => {
                    if(response.status === 200) {
                        history.push('/tools/property/list');
                    }
                });
            }

        }
    }

    const onCancelHandler = () => {
        history.push('/tools/property/list');
    }

    const removeAvailableValue = (e) => {
        const removeTargetIndex = e.target.parentNode.parentNode.getElementsByClassName('textField')[0].name;
        const list = [...form.availableValues];
        list.splice(parseInt(removeTargetIndex), 1);
        setForm({
            ...form,
            availableValues: list,
        });
    }

    const addAvailableValue = () => {
        setForm({
            ...form,
            availableValues: [
                ...form.availableValues,
                ''
            ]
        });
    }

    const onRemoveHandler = () => {
        const result = window.confirm('삭제하시겠습니까?');
        if(result) {
            Net.removeToolsPropertyList([form.id], (response) => {
                if(response) {
                    history.push('/tools/property/list');
                }
            });
        }
    }

    const enumerationList =
        form.availableValues.map((value, index) => {
            return (
                <div key={index} className="flex mb-12 w-80">
                    <CustomTextField
                        className="w-60 h-26px"
                        title="열거값"
                        name={index}
                        required={true}
                        disabled={false}
                        customOnChange={(name, value) => textFieldHandler4Array(name, value, index)}
                        value={form.availableValues}
                    />
                    <div className="flex w-10">
                        {
                            form.availableValues.length !== 1 &&
                            <input
                                type="button"
                                className="removeRowBtn"
                                onClick={removeAvailableValue}
                            >
                            </input>
                        }
                        {
                            form.availableValues.length - 1 === index &&
                            <input
                                type="button"
                                className="addRowBtn"
                                onClick={addAvailableValue}
                            >
                            </input>
                        }
                    </div>
                </div>
            )
        })

    return (
        <div className="w-100 h-100">
            <div className="w-100 h-7 display_table"></div>
            <div className="w-100 h-1px bgC-efefef" />
            <div className="h-93">
                <div className="flex h-90">
                    <div className="w-100 h-100 overFlowYscrollView">
                        <div className="ml-85 mt-12">
                            <div className="flex mb-12 w-80">
                                <CustomTextField
                                    className="w-60 h-26px"
                                    title="장비속성코드"
                                    name="code"
                                    required={true}
                                    disabled={false}
                                    customOnChange={textFieldHandler}
                                    value={form}
                                    disabled={match.params.code !== undefined ? true : false}
                                />
                            </div>
                            <div className="flex mb-12 w-80">
                                <CustomTextField
                                    className="w-60 h-26px"
                                    title="장비속성명"
                                    name="name"
                                    required={true}
                                    disabled={false}
                                    validLen="15"
                                    validRegExp={/[\{\}\[\]\/?.,;:|\)*~`!^\-_+<>@\#$%&\\\=\(\'\"]/gi}
                                    placeholder="속성명은 특수문자 제외 최대 15자"
                                    customOnChange={textFieldHandler}
                                    value={form}
                                />
                            </div>
                            <div className="flex mb-12 w-80">
                                <label className="label_title">속성형태 *</label>
                                <CustomRadioButton
                                    className="mr-60"
                                    title="범위형"
                                    id="radioBtn1"
                                    name="valueType"
                                    value="RANGE"
                                    checked={form.valueType === "RANGE"}
                                    customOnChange={radioBtnHandler}
                                />
                                <CustomRadioButton
                                    title="열거형"
                                    id="radioBtn2"
                                    name="valueType"
                                    value="ENUMERATION"
                                    checked={form.valueType === "ENUMERATION"}
                                    customOnChange={radioBtnHandler}
                                />
                            </div>
                            {
                                form.valueType === "RANGE" &&
                                <Fragment>
                                    <div className="flex mb-12 w-80">
                                        <CustomTextField
                                            type="number"
                                            className="w-60 h-26px"
                                            title="최고값"
                                            name="topValue"
                                            required={true}
                                            disabled={false}
                                            customOnChange={textFieldHandler}
                                            value={form}
                                        />
                                    </div>
                                    <div className="flex mb-12 w-80">
                                        <CustomTextField
                                            type="number"
                                            className="w-60 h-26px"
                                            title="최저값"
                                            name="bottomValue"
                                            required={true}
                                            disabled={false}
                                            customOnChange={textFieldHandler}
                                            value={form}
                                        />
                                    </div>
                                    <div className="flex mb-12 w-80">
                                        <CustomTextField
                                            className="w-60 h-26px"
                                            title="단계"
                                            name="resolution"
                                            required={true}
                                            disabled={false}
                                            customOnChange={textFieldHandler}
                                            value={form}
                                        />
                                    </div>
                                    <div className="flex mb-12 w-80">
                                        <CustomTextField
                                            className="w-60 h-26px"
                                            title="단위"
                                            name="unit"
                                            required={false}
                                            disabled={false}
                                            customOnChange={textFieldHandler}
                                            value={form}
                                        />
                                    </div>
                                </Fragment>
                            }
                            {
                                form.valueType === "ENUMERATION" &&
                                <Fragment>
                                    {enumerationList}
                                </Fragment>
                            }

                        </div>
                    </div>
                </div>
                <div className="footer_btn h-10">
                    <button className="w-160px h-30px addBtn mr-10" onClick={onSubmitHandler}>저장</button>
                    { registPage ? "" : <button className="w-160px h-30px removeBtn mr-10" onClick={onRemoveHandler}>삭제</button>}
                    <button className="w-160px h-30px cancelBtn" onClick={onCancelHandler}>취소</button>
                </div>
            </div>
        </div>
    )
}

export default withRouter(ToolsPropertyRegist);
