import { useEffect, useState} from "react";
import Net from 'scripts/net';

export default function usePageAuth(authGroupCode, menuId){
    const [programAuth, setProgramAuth] = useState([]);

    useEffect(()=>{
        Net.getAuthGroupMenuList(menuId, (response) => {
            if(response.status === 200 ){
                response.data.forEach(item => {
                    if(item.authGroup === authGroupCode && item.authGroupMenu){
                        setProgramAuth({
                            searchAuth: Boolean(item.authGroupMenu.searchAuth),
                            saveAuth: Boolean(item.authGroupMenu.saveAuth),
                            excelAuth: Boolean(item.authGroupMenu.excelAuth),
                            deleteAuth: Boolean(item.authGroupMenu.deleteAuth),
                        })
                    }
                });
            }
        })
    },[])

    return programAuth;
}

