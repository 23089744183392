import React, { useEffect, useState } from 'react'
import { CustomTextField, CustomRadioButton, CustomSelectBox ,QuillEditor} from 'components/molecules';
import { useHistory, withRouter } from 'react-router-dom';
import store from 'scripts/store';
import Net from 'scripts/net';
import {EditorState} from "draft-js";
import Common from 'scripts/common';

const PolicyManageRegist = ({match}) => {

    const history = useHistory();

    const [form, setForm] = useState({
        policyId: '',
        classification: '',
        version: '',
        title: '',
        contents: '',
        customer: {
            code: '',
            name: '',
        },
        usable: "true",
    });

    const [registPage, setRegistPage] = useState(true);
    const [customerList, setCustomerList] = useState([{
        code: '',
        name: '고객사명 선택'
    }]);
    const [classificationList, setClassificationList] = useState([{
        code: '',
        name: '구분명 선택'
    }]);
    const [editorState, setEditorState] = useState(EditorState.createEmpty());

    useEffect(()=>{
    },[form])

    useEffect(() => {
        store.openedPage = '약관 등록/수정';

        // 상세 조회(수정 목적으로 진입시)
        console.log(match)
        if(match.params.policyId !== undefined && match.params.policyId !== undefined) {
            Net.getPolicyOne(match.params.policyId, (response) => {
                if(response.status === 200) {
                        setForm({
                            ...form,
                            ...response.data,
                        });
                    setEditorState(Common.htmlToDraftBlocks(response.data.contents));

                    // 등록페이지 여부 확인
                    setRegistPage(false);
                }
            });
        }

        // 고객사 전체 목록
        getCustomerList((response) => {
            setCustomerList(response);
        });
        // 코드(약관) 전체 목록
        getClassificationList((response) => {
            setClassificationList(response);
        });
    }, []);

    const getCustomerList = (callback) => {
        Net.getCustomerList(
            {currentPage: '', rowPerPage: '', searchText: ''}, (response) => {
            if(response.status === 200) {
                let newArray = customerList;
                response.data.content.map(items => {
                    newArray = [
                        ...newArray,
                        {
                            code: items.code,
                            name: items.name
                        }
                    ]
                });
                callback(newArray);
            }
        });
    }

    const getClassificationList = (callback) => {
        Net.getCodeList('POLICY', (response) => {
            if(response.status === 200) {
                let newArray = classificationList;
                response.data.map(items => {
                    newArray = [
                        ...newArray,
                        {
                            code: items.code,
                            name: items.name
                        }
                    ]
                });
                callback(newArray);
            }
        });
    }

    const textFieldHandler = (name, value) => {
        console.log(form)

        const nextForm = {
            ...form,
            [name]: value
        }
        setForm(nextForm);
    }

    const radioBtnHandler = (name, value) => {
        console.log(form)

        const nextForm = {
            ...form,
            [name]: value,
        }
        setForm(nextForm);
    }

    const selectBoxHandler = (name, value) => {
        if(name === 'customer.code') {
            setForm({
                ...form,
                customer: {
                    code: value.code,
                    name: value.name,
                }
            });
        } else if(name === 'classification') {
            setForm({
                ...form,
                classification: value.code,
            });
        }
    }

    const liftValue = (value) => {
        setTimeout(()=>{
            setForm({
                ...form,
                contents: value,
            });
        },10)

    }

    const onSubmitHandler = () => {

        var result = window.confirm('저장하시겠습니까?');
        if(result) {
            if(registPage) {
                Net.addPolicy(form, (response) => {
                    if(response.status === 200) {
                        history.push('/setting/policy/list');
                    }
                });
            } else {
                Net.updatePolicy(form, (response) => {
                    if(response.status === 200) {
                        history.push('/setting/policy/list');
                    }
                });
            }
        }
    }

    const onCancelHandler = () => {
        history.push('/setting/policy/list');
    }

    const onRemoveHandler = () => {
        const result = window.confirm('삭제하시겠습니까?');
        if(result) {
            Net.removePolicyList([form.policyId], (response) => {
                if(response) {
                    history.push('/setting/policy/list');
                }
            });
        }
    }

    return (
        <div className="w-100 h-100">
            <div className="w-100 h-7 display_table"></div>
            <div className="w-100 h-1px bgC-efefef" />
            <div className="h-93">
                <div className="flex h-90">
                    <div className="w-60 h-100">
                        <div className="ml-55 mt-12">
                            <div className="flex mb-12 w-90">
                                <label className="label_title">고객사명 *</label>
                                <CustomSelectBox
                                    name="customer.code"
                                    categoryList={customerList}
                                    onChangeHandler={(value, name) => selectBoxHandler('customer.code', {code: value, name: name})}
                                    callbackCodeName="Y"
                                    value={form.customer.code}
                                />
                            </div>
                            <div className="flex mb-12 w-90">
                                <label className="label_title">구분 *</label>
                                <CustomSelectBox
                                    name="classification"
                                    categoryList={classificationList}
                                    onChangeHandler={(value, name) => selectBoxHandler('classification', {code: value, name: name})}
                                    value={form.classification}
                                />
                            </div>
                            <div className="flex mb-12 w-90">
                                <CustomTextField
                                    className="w-70 h-26px"
                                    title="버전"
                                    name="version"
                                    required={true}
                                    disabled={false}
                                    customOnChange={textFieldHandler}
                                    value={form}
                                />
                            </div>
                            <div className="flex mb-12 w-90">
                                <CustomTextField
                                    className="w-70 h-26px"
                                    title="제목"
                                    name="title"
                                    required={false}
                                    disabled={false}
                                    customOnChange={textFieldHandler}
                                    value={form}
                                />
                            </div>
                            <div className="flex mb-10 w-90">
                                <label className="label_title">내용</label>
                                <div className="w-80">
                                    <QuillEditor
                                        onChange={liftValue}
                                        value={editorState}
                                        setEditorState={setEditorState}
                                    />
                                </div>
                            </div>
                            <div className="flex w-90">
                                <label className="label_title">사용유무</label>
                                <CustomRadioButton
                                    className="mr-60"
                                    title="사용"
                                    id="radioBtn1"
                                    name="usable"
                                    value={true}
                                    checked={form.usable === "true"}
                                    customOnChange={radioBtnHandler}
                                />
                                <CustomRadioButton
                                    title="사용안함"
                                    id="radioBtn2"
                                    name="usable"
                                    value={false}
                                    checked={form.usable === "false"}
                                    customOnChange={radioBtnHandler}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer_btn h-10">
                    <button className="w-160px h-30px addBtn mr-10" onClick={onSubmitHandler}>저장</button>
                    { registPage ? "" : <button className="w-160px h-30px removeBtn mr-10" onClick={onRemoveHandler}>삭제</button>}
                    <button className="w-160px h-30px cancelBtn" onClick={onCancelHandler}>취소</button>
                </div>
            </div>
        </div>
    )
}

export default withRouter(PolicyManageRegist);
