import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { CustomTable, SearchInputBar, BasicModal } from 'components/molecules';
import store from 'scripts/store';
import Net from 'scripts/net';
import Common from 'scripts/common';
import {usePageAuth , useDidMountEffect} from "scripts/hooks";
import {ServiceRegistModal} from "./modal/ServiceRegistModal";
import {SiteRegistModal} from "./modal/SiteRegistModal";
import {ServiceUpdateModal} from "./modal/ServiceUpdateModal";

const siteColumnList = [
    {
        code: 'id',
        title: '',
        width: '',
        hidden: true,
    },
    {
        code: 'siteName',
        title: '현장명',
        width: '15',
    },
];

const columnList = [
    {
        code: 'type',
        title: '구분',
        width: '15',
    },
    {
        code: 'code',
        title: '코드',
        width: '15',
    },
    {
        code: 'name',
        title: '코드명',
        width: '15',
    },
    {
        code: 'usable',
        title: '사용여부',
        width: '10',
    },
    {
        code: 'onepassUsable',
        title: '원패스 사용여부',
        width: '10',
    },
    {
        code: 'homenetUsable',
        title: '홈넷 사용여부',
        width: '10',
    },
    {
        code: 'sort',
        title: '정렬순서',
        width: '15',
    },
    {
        code: 'jsonTooltip',
        title: '추가 속성',
        width: '10',
    },
];

export const AppServiceList = (props) => {

    // useHistory 선언
    const history = useHistory();
    const programAuth = usePageAuth(props.userInfo.authList[0], sessionStorage.getItem('menuId'));

    const user = props.userInfo;

    // 현장 리스트 ( Grid - Table )
    const [siteListData, setSiteListData] = useState([]);

    // 서비스 리스트 ( Grid - Table )
    const [serviceListData, setServiceListData] = useState([]);

    // 선택된 현장의 서비스 Data
    const [selectData, setSelectData] = useState({});

    // 선택된 현장의 원본 Data
    const [baseData, setBaseData] = useState({});

    const [checkedList, setCheckedList] = useState([]);

    // 원본 모바일 메뉴 Code List 값
    const [mobileCodeList, setMobileCodeList] = useState([]);

    // 전체 모바일 메뉴 와 선택된 현장의 코드 리스트의 차집합
    const [modalCodeList, setModalCodeList] = useState([]);

    const [clickRow, setClickRow] = useState(null);
    const [clickServiceRow, setClickServiceRow] = useState({});
    const [siteDataLoading, setSiteDataLoading] = useState(false);
    const [loading, setLoading] = useState(false);


    const [searchCategoryGroup, setSearchCategoryGroup] = useState([
        [
            [
                {code: '', name: '고객사명 선택'}
            ]
        ],
        [
            [
                {code: '', name: '현장명 선택'}
            ]
        ],
    ]);

    const [modalState, setModalState] = useState({
        open: false,
        data: [],
        clickedBtnName: '',
    });

    const [pageState, setPageState] = useState({
        totalElementsCnt: 0,
        drawElementsCnt: 0,
    });

    const [searchState, setSearchState] = useState({
        searchCategory1: user.menuGroup === 'ADMIN' ? '' : user.customer && user.customer.code,
        searchCategory2: user.menuGroup === 'OPERATOR' ? user.site && user.site.code: '',
        searchText : '',
        currentPage: 1,
        rowPerPage: 99,
    })

    useEffect(() => {
        store.openedPage = '현장별 메뉴/서비스 목록';
        getCategoryList();
        getMobileMenu();
    }, []);

    useEffect(() => {
        loadData();
    }, [searchState.searchCategory1, searchState.searchCategory2, searchState.searchText]);

    useEffect(()=>{
        setSiteDataLoading(true);
        setSelectData(baseData);

        setTimeout(()=>{
            setSiteDataLoading(false);
        },[1000])
    },[baseData]);

    const serviceList = ["communityService", "mainService", "searchService","settings"];

    useEffect(()=>{
        /*
            현장 선택시 서비스데이터, 차집합 서비스데이터(모달에 전달할) 설정

         */

        let tempData = [];
        let tempCodeList = [];
        let tempModalList = [];
        console.log(serviceList, selectData)
        serviceList.forEach((service, i)=>{
           if(selectData.hasOwnProperty(service)){
                selectData[service].forEach(item=>{
                    let tempItem = item;
                    tempCodeList.push(item);
                    tempItem.type = service;
                    tempData.push({
                        ...item,
                        type : service,
                        jsonTooltip : item.addProperty
                    });
                })
            }
        });

        const sortData = tempData.sort(function (a, b){
            if(a.sort > b.sort) return 1;
            if(a.sort === b.sort) return 0;
            if(a.sort < b.sort) return -1;
        }).sort(function (a,b){
            if(a.type > b.type) return 1;
            if(a.type === b.type) return 0;
            if(a.type < b.type) return -1;
        })

        modalCodeList.forEach(x => {
            let check = false;
            tempCodeList.forEach(y => {
                if (x.code === y.code) {
                    check = true;

                }
            })
            if (!check) tempModalList.push(x);
        });

        setModalCodeList(tempModalList);
        setServiceListData(sortData);
    },[selectData]);

    useEffect(()=>{
    },[clickServiceRow]);

    useEffect(()=>{
        setSiteDataLoading(false);
    },[serviceListData]);


    const loadData = () => {
        setLoading(true);
        Net.getAppServiceList(searchState, (response) => {
            setLoading(false);
            if (response.status === 200) {
                response.data.content.map(x=>{
                    if(x.site){
                        x.siteName = x.site.name;
                    }else{
                        x.siteName = x.customer.name+'[고객사]';
                    }
                    return x;
                })
                setSiteListData(response.data.content);
                setPageState({
                    ...pageState,
                    totalElementsCnt: response.data.totalElements,
                    drawElementsCnt: response.data.numberOfElements,
                });
                setServiceListData([]);
                setSelectData({});
                setBaseData({});
            } else if (response.status === 204) {
                setSiteListData([]);
                setPageState({
                    ...pageState,
                    totalElementsCnt: 0,
                    drawElementsCnt: 0,
                });
            }
        });
    }

    const onSubmitHandler = (e) => {
        if (user.menuGroup === 'ADMIN') {
            setSearchState({
                ...searchState,
                currentPage: 1,
                searchText: e.searchText,
                searchCategory1: e.selectedCategory[0],
                searchCategory2: e.selectedCategory[1],
            });
        } else {
            setSearchState({
                ...searchState,
                currentPage: 1,
                searchText: e.searchText,
                searchCategory1: e.selectedCategory[0],
            });
        }
    }

    const sendData = (e) => {
        setModalState({
            ...modalState,
            data: e,
        })
    }

    const getCategoryList = () => {
        if (user.menuGroup === 'ADMIN') {
            Common.getCustomerList((response1) => {
                Common.getSitesList(null, (response2) => {
                    setSearchCategoryGroup([response1, response2]);
                });
            });
        } else if (user.menuGroup === 'MANAGER') {
            Common.getSitesList(pageState.customerCode, (response2) => {
                setSearchCategoryGroup([response2]);
            });
        }
    }

    const getRelateCategory = (selectedCategory) => {
        if (selectedCategory === "reset") selectedCategory = "";
        Common.getSitesList(selectedCategory, (response2) => {
            setSearchCategoryGroup([
                searchCategoryGroup[0],
                response2
            ]);
        });
    }

    const reload = async (e) => {
        if(e === 'reloadSite'){
            // 현장 전체 재조회
            resetSite();
            loadData();
        }else if(e === 'reloadService'){
            Net.getAppServiceById(clickRow, response => {
                setModalCodeList(mobileCodeList);
                setBaseData(response.data);
                setClickServiceRow({});
            });
        }
    }

    const addSite = () => {
        /*
          신규 현장 등록
        * */
        setModalState({
            ...modalState,
            open: !modalState.open,
            clickedBtnName: '현장 등록',
        });
    }

    const onClickRow = (e) => {
        setClickRow(e.id);
        setClickServiceRow({});
        setServiceListData([]);
        setModalCodeList(mobileCodeList);

        Net.getAppServiceById(e.id, response => {
            console.log(response);
            setBaseData(response.data);
        })
    }

    const onClickServiceRow = (e) => {
        setClickServiceRow(e);
    }

    const removeSite = () => {
        const result = window.confirm('삭제하시겠습니까?');

        if(result){
            Net.deleteAppService(clickRow, (response) =>{
                if(response.status === 200){
                    resetSite();
                    loadData();
                }
            })
        }
    }

    const resetSite = () => {
        setClickRow(null);
        setClickServiceRow({});
        setServiceListData([]);
        setModalCodeList(mobileCodeList);
        setSiteListData([]);
        setServiceListData([]);
        setSelectData({});
        setBaseData({});
    }

    const addService = () => {
        setModalState({
            ...modalState,
            open: !modalState.open,
            propsData : modalCodeList,
            baseData : baseData,
            clickedBtnName: '서비스 등록',
        });
    }

    const updateService = () => {
        setModalState({
            ...modalState,
            open: !modalState.open,
            propsData : mobileCodeList,
            baseData : baseData,
            clickedBtnName: '서비스 수정',
            clickData : clickServiceRow
        });
    }

    const removeServiceList = () => {
        const result = window.confirm('삭제하시겠습니까?');

        let updateData = baseData;
        const removeCodeList = [];
        let mainServiceRemoveList = []; let searchServiceRemoveList = []; let communityServiceRemoveList = []; let settingsRemoveList = [];
        checkedList.map((id) => {
            const node = document.querySelector(`#${id}`);
            const nodeTr = node.closest('tr');
            const codeValue = nodeTr.querySelector('td[name=code]').textContent;
            const typeValue = nodeTr.querySelector('td[name=type]').textContent;
            removeCodeList.push({codeValue, typeValue});
            if(typeValue === 'mainService') mainServiceRemoveList.push(codeValue);
            else if(typeValue === 'searchService') searchServiceRemoveList.push(codeValue);
            else if(typeValue === 'communityService') communityServiceRemoveList.push(codeValue);
            else if(typeValue === 'settings') settingsRemoveList.push(codeValue);
        });

        function filteringService(baseData, serviceName, removeList){
            return baseData[serviceName].filter( x => {
                if(removeList.some(i => {
                    return (x.code === i)
                })) console.log(x);
                else return x;
            })
        }

        updateData.mainService = filteringService(baseData, "mainService", mainServiceRemoveList);
        updateData.searchService = filteringService(baseData, "searchService", searchServiceRemoveList);
        updateData.communityService = filteringService(baseData, "communityService", communityServiceRemoveList);
        updateData.settings = filteringService(baseData, "settings", settingsRemoveList);

         if(result) {
             Net.updateAppService(updateData, response => {
                 if(response.status === 200){
                     reload('reloadService').then(r => console.log("삭제 성공", r));
                 } else{
                     alert('오류 발생');
                 }
             })
         }
    }

    function getMobileMenu() {
        Net.getSystemCode('MOBILE_MENU', (response)=>{
            setMobileCodeList(response.data);
            setModalCodeList(response.data);
        })
    }

    return (
        <div className="w-100 h-100">
            <BasicModal
                className="w-50 h-50"
                title={`${modalState.clickedBtnName}`}
                content={
                    <ModalContent
                        sendData={sendData}
                        modalType={modalState.clickedBtnName}
                        propsData={modalState.propsData}
                        baseData={modalState.baseData}
                        clickData={modalState.clickData}
                        modalClose={(e) => setModalState({
                            ...modalState,
                            open: e
                        })}
                        reload={reload}
                        deleteAuth = {programAuth.deleteAuth}
                    />
                }
                modalOpen={modalState.open}
                modalClose={() => setModalState({
                    ...modalState,
                    open: false
                })}
                sendData={modalState.data}
            />
            <div className="w-100 h-7 display_table">
                <SearchInputBar
                    className="w-100"
                    searchCategory={user.menuGroup === 'ADMIN'}
                    searchCategoryGroup={searchCategoryGroup}
                    searchText={user.menuGroup === 'ADMIN' || user.menuGroup === 'MANAGER'}
                    searchPlaceHolder="현장명을 입력해 주세요."
                    searchBtn={user.menuGroup === 'ADMIN' || user.menuGroup === 'MANAGER'}
                    searchBtnTitle="검색"
                    relateCategoryHandler={user.menuGroup === 'ADMIN' && getRelateCategory}
                    dynamicSearch={false}
                    onSubmit={onSubmitHandler}
                    refreshBtn={true}
                    refreshHandler={loadData}
                />
                {
                    programAuth.saveAuth &&
                    <div className="display_table_cell v-middle">
                        <button
                            className="addBtn w-90px h-30px floatR mr-20"
                            onClick={addSite}
                        >현장 등록
                        </button>
                    </div>
                }

                {
                    programAuth.deleteAuth && Object.keys(selectData).length > 0 &&
                    <div className="display_table_cell v-middle">
                        <button
                            className="cancelBtn w-90px h-30px floatR mr-20"
                            onClick={removeSite}
                        >현장 삭제
                        </button>
                    </div>
                }
            </div>
            <div className="w-100 h-1px bgC-efefef" />
            <div className="flex h-93">
                <div className="w-100 h-100 overflowYscroll d-inline-block">
                    <div className="flex justify-content-between">
                        <p className="totalCnt">Total {pageState.totalElementsCnt}</p>
                        <div className="float-end">
                            <button
                                className="addBtn w-120px h-30px floatR mt-20 mr-20"
                                onClick={addService}
                                disabled={Object.keys(selectData).length === 0}
                            >신규 서비스 등록
                            </button>
                            <button
                                className="downloadBtn w-90px h-30px floatR mt-20 mr-20"
                                onClick={updateService}
                                disabled={Object.keys(clickServiceRow).length === 0}
                            >서비스 수정
                            </button>
                            <button
                                className="cancelBtn w-90px h-30px floatR mt-20 mr-20"
                                onClick={removeServiceList}
                                disabled={checkedList.length === 0}
                            >서비스 삭제
                            </button>
                        </div>
                    </div>
                    <div className="flex">
                    <div className="col-2">
                        <CustomTable
                            columnList = {siteColumnList}
                            contentData = {siteListData}
                            rowClickEvt = {!siteDataLoading}
                            onClickRow = {onClickRow}
                            loading = {loading}
                            // sort = {true}
                        />
                    </div>
                    <div className="col-10">
                        <CustomTable
                            columnList = {columnList}
                            contentData = {serviceListData}
                            checkbox = {programAuth.deleteAuth}
                            checkedList = {(checkedList) => {setCheckedList(checkedList)} }
                            rowClickEvt = {true}
                            onClickRow = {onClickServiceRow}
                        />
                    </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export const ModalContent = (props) => {
    switch (props.modalType) {
        case "현장 등록":
            return <SiteRegistModal data={props.propsData} modalClose={props.modalClose} reload={props.reload} />
        case "서비스 등록":
            return <ServiceRegistModal data={props.propsData} baseData={props.baseData} modalClose={props.modalClose} reload={props.reload} />
        case "서비스 수정":
            return <ServiceUpdateModal data={props.propsData} baseData={props.baseData} modalClose={props.modalClose} reload={props.reload} clickData={props.clickData} />
        default:
            break;
    }
}
