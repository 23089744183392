import React, { useEffect, useState } from 'react';
import { CustomTextField, CustomRadioButton } from 'components/molecules';
import { useHistory, withRouter } from 'react-router-dom';
import store from 'scripts/store';
import Net from 'scripts/net';

const ToolsTypeRegist = ({match}) => {

    const history = useHistory();

    const [form, setForm] = useState({
        code: '',
        name: '',
        usable: true,
    });
    const [registPage, setRegistPage] = useState(true);
    const [duplicateChk, setDuplicateChk] = useState(false);

    useEffect(() => {
        store.openedPage = '장비타입 등록/수정';

        // 상세 조회
        if(match.params.code !== undefined) {
            Net.getToolsTypeOne(match.params.code, (response) => {
                if(response.status === 200) {
                    setForm({
                        ...form,
                        ...response.data,
                    });

                    // 장비타입코드 중복체크 true
                    setDuplicateChk(true);
                    // 등록페이지 여부 확인
                    setRegistPage(false);
                }
            });
        }
    }, []);

    const textFieldHandler = (name, value) => {
        // 장비타입코드 중복체크 후 수정 시 중복체크 재확인 요망을 위한 값 셋팅
        if(name === 'code') setDuplicateChk(false);
        const nextForm = {
            ...form,
            [name]: value
        }
        setForm(nextForm);
    }

    const radioBtnHandler = (name, value) => {
        const nextForm = {
            ...form,
            [name]: value === 'true' ? true : false,
        }
        setForm(nextForm);
    }

    const onDuplicateHandler = () => {
        if(form.code !== '') {
            Net.chkDuplicateToolsType(form.code, (response) => {
                if(response.status === 200) {
                    alert('이미 등록된 장비타입코드입니다.');
                } else if(response.status === 404) {
                    alert('등록 가능한 장비타입코드입니다.');
                    setDuplicateChk(true);
                }
            });
        } else {
            alert('장비타입코드를 입력해주세요.');
        }
    }

    const onSubmitHandler = () => {
        if(form.code === '') {
            alert('장비타입코드는 필수값입니다.');
            document.getElementsByName('code')[0].focus();
            return false;
        }
        if(!duplicateChk) {
            alert('장비타입코드 중복체크를 해주세요.');
            return false;
        }
        if(form.name === '') {
            alert('장비타입명은 필수값입니다.');
            document.getElementsByName('name')[0].focus();
            return false;
        }
        
        var result = window.confirm('저장하시겠습니까?');
        if(result) {
            if(registPage) {
                Net.addToolsType(form, (response) => {
                    if(response.status === 201) {
                        history.push('/tools/type/list');
                    }
                });
            } else {
                Net.updateToolsType(form, (response) => {
                    if(response.status === 200) {
                        history.push('/tools/type/list');
                    }
                });
            }
            
        }
    }

    const onRemoveHandler = () => {
        const result = window.confirm('삭제하시겠습니까?');
        if(result) {
            Net.removeNotice([form.code], (response) => {
                if(response) {
                    history.push('/tools/type/list');
                }
            });
        }
    }

    const onCancelHandler = () => {
        history.push('/tools/type/list');
    }

    return (
        <div className="w-100 h-100">
            <div className="w-100 h-7 display_table"></div>
            <div className="w-100 h-1px bgC-efefef" />
            <div className="h-93">
                <div className="flex h-90">
                    <div className="w-100 h-100">
                        <div className="ml-85 mt-12">
                            <div className="flex mb-12 w-80">
                                <CustomTextField 
                                    className="w-50 h-26px"
                                    title="장비타입코드"
                                    name="code"
                                    required={true}
                                    disabled={false}
                                    validRegExp={/[ㄱ-ㅎ|ㅏ-ㅣ|가-힣|a-z0-9\{\}\[\]\/?.,;:|\)*~`!^\-+<>@\#$%&\\\=\(\'\"]/g}
                                    placeholder="코드는 대문자이며, 단어와 단어 사이는 언더바 사용"
                                    customOnChange={textFieldHandler}
                                    value={form}
                                    disabled={match.params.code !== undefined ? true : false}
                                />
                                <div className="mt-auto mb-auto">
                                    <button 
                                        className="w-78px h-22px duplicateBtn" 
                                        onClick={onDuplicateHandler}
                                    >중복체크</button>
                                </div>
                            </div>
                            <div className="flex mb-12 w-80">
                                <CustomTextField 
                                    className="w-60 h-26px"
                                    title="장비타입명"
                                    name="name"
                                    required={true}
                                    disabled={false}
                                    validLen="15"
                                    validRegExp={/[\{\}\[\]\/?.,;:|\)*~`!^\-_+<>@\#$%&\\\=\(\'\"]/gi}
                                    placeholder="타입명은 특수문자 제외 최대 15자"
                                    customOnChange={textFieldHandler}
                                    value={form}
                                />
                            </div>
                            <div className="flex w-80">
                                <label className="label_title">사용유무</label>
                                <CustomRadioButton
                                    className="mr-60"
                                    title="사용"
                                    id="radioBtn1"
                                    name="usable"
                                    value={true}
                                    checked={form.usable}
                                    customOnChange={radioBtnHandler}
                                />
                                <CustomRadioButton 
                                    title="사용안함"
                                    id="radioBtn2"
                                    name="usable"
                                    value={false}
                                    checked={!form.usable}
                                    customOnChange={radioBtnHandler}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer_btn h-10">
                    <button className="w-160px h-30px addBtn mr-10" onClick={onSubmitHandler}>저장</button>
                    { registPage ? "" : <button className="w-160px h-30px removeBtn mr-10" onClick={onRemoveHandler}>삭제</button>}
                    <button className="w-160px h-30px cancelBtn" onClick={onCancelHandler}>취소</button>
                </div>
            </div>
        </div>
    )
}

export default withRouter(ToolsTypeRegist);