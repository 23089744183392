import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { CustomTable, SearchInputBar } from 'components/molecules';
import store from 'scripts/store';
import Net from 'scripts/net';

const columnList = [
    {
        code: 'code',
        title: '',
        width: '',
        hidden: true,
    },
    {
        code: 'name',
        title: '현장명',
        width: '15',
    },
    {
        code: 'customer.name',
        title: '고객사명',
        width: '15',
    },
    {
        code: 'region.name',
        title: '지역명',
        width: '15',
    },
    {
        code: 'address',
        title: '주소(상세)',
        width: '15',
    },
    {
        code: 'numberOfHouseHold',
        title: '세대수',
        width: '8',
    },
    {
        code: 'builtDate',
        title: '준공년월',
        width: '10',
    },
    {
        code: 'manufacturer.name',
        title: '홈넷사',
        width: '20',
    },
    {
        code: 'usable',
        title: '사용여부',
        width: '8',
    },
];

export const SiteList = (props) => {

    // useHistory 선언
    const history = useHistory();
    const user = props.userInfo;
    const [contentData, setContentData] = useState([]);
    const [checkedList, setCheckedList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [searchCategoryGroup, setSearchCategoryGroup] = useState([
        [
            [
                {code: '', name: '고객사 선택'}
            ]
        ],
    ]);

    const [pageState, setPageState] = useState({
        currentPage: 1,
        rowPerPage: 13,
        totalElementsCnt: 0,
        drawElementsCnt: 0,
        searchText: user.menuGroup === 'OPERATOR' ? user.site && user.site.name: '',
        customerCode: user.menuGroup === 'ADMIN' ? '' : user.customer && user.customer.code,
    });

    const [programAuth, setProgramAuth] = useState({});

    useEffect(() => {
        store.openedPage = '현장 목록';
        if (user.menuGroup === 'ADMIN') {
            getCustomerList((response1) => {
                setSearchCategoryGroup([response1],);
            });
        }
        pageAuthInfo();
    }, []);

    useEffect(() => {
        loadData();
    }, [pageState.currentPage, pageState.searchText, pageState.customerCode]);

    const pageAuthInfo = () => {
        const authGroupCode = props.userInfo.authList[0];
        const menuId = sessionStorage.getItem('menuId');

        Net.getAuthGroupMenuList(menuId, (response) => {
            if(response.status === 200) {
                response.data.forEach(item => {
                    if(item.authGroup === authGroupCode && item.authGroupMenu) {
                        setProgramAuth({
                            searchAuth: Boolean(item.authGroupMenu.searchAuth),
                            saveAuth: Boolean(item.authGroupMenu.saveAuth),
                            excelAuth: Boolean(item.authGroupMenu.excelAuth),
                            deleteAuth: Boolean(item.authGroupMenu.deleteAuth),
                        });
                    }
                });
            }
        });
    }

    const loadData = () => {
        setLoading(true);
        Net.getSitesList(pageState, (response) => {
            if(response.status === 200) {
                const manufacturerCodeArray = [];
                response.data.content.forEach((list) => {
                    const manufacturerCode = list.manufacturerCode;
                    if(manufacturerCode)
                        manufacturerCodeArray.push(manufacturerCode);
                });

                if(manufacturerCodeArray.length > 0) {
                    getManufacturerDataList(manufacturerCodeArray, (response2) => {
                        setLoading(false);
                        const newContentData = [];
                        response.data.content.forEach(list => {
                            const obj = list;
                            const manufacturerCode = list.manufacturerCode;
                            if(manufacturerCode) {
                                response2.forEach(data => {
                                    if(manufacturerCode === data.code) {
                                        obj.manufacturer = {
                                            name: data.name,
                                        }
                                    }
                                });
                            }
                            newContentData.push(obj);
                        });
                        setContentData(
                            newContentData
                        )
                    });
                } else {
                    setContentData(response.data.content);
                }

                setPageState({
                    ...pageState,
                    totalElementsCnt: response.data.totalElements,
                    drawElementsCnt: response.data.numberOfElements,
                });

            } else if(response.status === 204) {
                setContentData([]);
                setPageState({
                    ...pageState,
                    totalElementsCnt: 0,
                    drawElementsCnt: 0,
                });
            }
        });
        setTimeout(()=>{
            setLoading(false);
        },[3000])
    }

    const getManufacturerDataList = (manufacturerCodeArray, callback) => {
        const manufacturerDataArray = [];
        manufacturerCodeArray.forEach((code, index) => {
            Net.getToolsManufacturerOne(code, (response) => {
                const manufacturerDataObj = {};
                if(response.status === 200) {
                    manufacturerDataObj.code = code;
                    manufacturerDataObj.name = response.data.name;

                    manufacturerDataArray.push(manufacturerDataObj);

                    if(manufacturerCodeArray.length === (index+1)) {
                        callback(manufacturerDataArray);
                    }
                }
            });
        })
    }

    const getCustomerList = (callback) => {
        Net.getCustomerList({currentPage: '', rowPerPage: '', searchText: ''}, (response) => {
            if(response.status === 200) {
                let newArray = searchCategoryGroup[0][0];
                response.data.content.forEach(items => {
                    newArray = [
                        ...newArray,
                        {
                            code: items.code,
                            name: items.name
                        }
                    ]
                });
                callback(newArray);
            }
        });
    }

    const onMovePage = (e) => {
        setPageState({
            ...pageState,
            currentPage: e
        });
    }

    const onSubmitHandler = (e) => {
        if (user.menuGroup === 'ADMIN') {
            setPageState({
                ...pageState,
                currentPage: 1,
                searchText: e.searchText,
                customerCode: e.selectedCategory[0],
            });
        } else {
            setPageState({
                ...pageState,
                currentPage: 1,
                searchText: e.searchText,
            });
        }
    }

    const addList = () => {
        history.push('/location/site/regist');
    }

    const onClickRow = (e) => {
        if(user.menuGroup !== 'OPERATOR') {
            history.push(`/location/site/regist/${e.code}`);
        }
    }

    const removeList = () => {
        const result = window.confirm('삭제하시겠습니까?');
        const removeCodeList = [];
        checkedList.forEach((id) => {
            const node = document.querySelector(`#${id}`);
            const nodeTr = node.closest('tr');
            const codeValue = nodeTr.querySelector('td[name=code]').textContent;
            removeCodeList.push(codeValue);
        });

        if(result) {
            Net.removeSitesList(removeCodeList, (response) => {
                if(response) {
                    loadData();
                }
            });
        }
    }

    return (
        <div className="w-100 h-100">
            <div className="w-100 h-7 display_table">
                <SearchInputBar
                    className="w-100"
                    searchCategory={user.menuGroup === 'ADMIN'}
                    searchCategoryGroup={searchCategoryGroup}
                    searchText={user.menuGroup === 'ADMIN' || user.menuGroup === 'MANAGER'}
                    searchPlaceHolder="현장명을 입력해 주세요."
                    searchBtn={user.menuGroup === 'ADMIN' || user.menuGroup === 'MANAGER'}
                    searchBtnTitle="검색"
                    dynamicSearch={false}
                    onSubmit={onSubmitHandler}
                    refreshBtn={true}
                    refreshHandler={loadData}
                />
                {
                    programAuth.saveAuth &&
                    <div className="display_table_cell v-middle">
                        <button
                            className="addBtn w-90px h-30px floatR mr-20"
                            onClick={addList}
                        >등록</button>
                    </div>
                }
            </div>
            <div className="w-100 h-1px bgC-efefef" />
            <div className="flex h-93">
                <div className="w-100 h-100 overflowYscroll">
                    <p className="totalCnt">Total {pageState.totalElementsCnt}</p>
                    {
                        programAuth.deleteAuth && checkedList.length > 0 &&
                        <button
                            className="removeBtn w-90px h-30px floatR mt-7 mr-20"
                            onClick={removeList}
                        >삭제</button>
                    }
                    <CustomTable
                        columnList = {columnList}
                        contentData = {contentData}
                        checkbox = {programAuth.deleteAuth}
                        checkedList = {(checkedList) => {setCheckedList(checkedList)} }
                        paging = {true}
                        totalElementsCnt = {pageState.totalElementsCnt}
                        rowPerPage = {pageState.rowPerPage}
                        currentPage = {pageState.currentPage - 1}
                        drawElementsCnt = {pageState.drawElementsCnt}
                        pagination = {onMovePage}
                        rowClickEvt = {true}
                        onClickRow = {onClickRow}
                        loading={loading}
                        // sort = {true}
                    />
                </div>
            </div>
        </div>
    )
}
