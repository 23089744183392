import React, { useEffect, useState } from 'react';
import { useHistory, withRouter } from 'react-router-dom';
import { CustomTextField, CustomRadioButton, QuillEditor, CustomSelectBox, ImgUpload } from 'components/molecules';
import store from 'scripts/store';
import Net from 'scripts/net';
import Common from 'scripts/common';
import {toJS} from "mobx";
import TextareaAutosize from "react-textarea-autosize"

const FreePassSiteRegist = ({match}) => {

    const cloneUser = toJS(store.user)

    const [customerList, setCustomerList] = useState([{
        code: '',
        name: '고객사명 선택'
    }]);
    const [siteList, setSiteList] = useState([{
        code: '',
        name: '현장명 선택'
    }]);

    const [checkSubmit, setCheckSubmit] = useState(false);
    const [form, setForm] = useState({
        user: cloneUser,
        customer : cloneUser.menuGroup === 'ADMIN' ? {code: '',name: '',} : cloneUser.customer,
        site: cloneUser.menuGroup === 'OPERATOR' ? cloneUser.site : { code: '', name: '',},
        freePassEmail : '',
        freePassPassword : '',
        freePassAccessToken : '',
        freePassMessage : '',
        autoParking: false
    });

    useEffect( () => {
        store.openedPage = 'FreePass 현장 등록 [ 루테크 현장 ]';

        // 고객사 전체 목록
        Common.getCustomerList((response) => {
            setCustomerList(response);
        });
    }, []);

    useEffect(()=>{
        if(checkSubmit){
            saveLogic(form);
        }
    },[checkSubmit])

    useEffect(() => {
        if(form.customer.code !== '') {
            Common.getSitesList(form.customer.code, (response) => {
                setSiteList(response);
            })
        } else {
            setSiteList([
                {
                    code: '',
                    name: '현장명 선택'
                }
            ])
        }
    }, [form.customer.code]);

    const history = useHistory();

    const textFieldHandler = (name, value) => {
        const nextForm = {
            ...form,
            [name]: value
        }
        setForm(nextForm);
    }

    const radioBtnHandler = (name, value) => {
        let nextForm;
        if(name === "authGroup"){
            nextForm = {
                ...form,
                [name]: value,
            }
        } else {
            nextForm = {
                ...form,
                [name]: value === 'true',
            }
        }
        setForm(nextForm);
    }

    const selectBoxHandler = (name, value) => {
        switch (name) {
            case 'customer.code':
                setForm({
                    ...form,
                    customer: {
                        code: value.code,
                        name: value.name
                    },
                    site: {
                        code: "",
                        name: ""
                    }
                });
                break;
            case 'site.code':
                setForm({
                    ...form,
                    site: {
                        code: value.code,
                        name: value.name
                    }
                });
                break;
            default:
                break;
        }
    }

    const onSubmitHandler = () => {
        if (cloneUser.menuGroup === 'OPERATOR') {
            setForm({
                ...form,
                customer: cloneUser.customer,
                site: cloneUser.site ,
            });
        } else if (cloneUser.menuGroup === 'MANAGER') {
            if (!form.site.code) {
                alert('현장명을 선택해 주세요.');
                document.getElementsByName('site.code')[0].focus();
                return false;
            }
            setForm({
                ...form,
                customer: cloneUser.customer,
            });
        } else {
            if (!form.customer.code) {
                alert('고객사를 선택해 주세요.');
                document.getElementsByName('customer.code')[0].focus();
                return false;
            }
            if (!form.site.code) {
                alert('현장명을 선택해 주세요.');
                document.getElementsByName('site.code')[0].focus();
                return false;
            }
            if (!form.freePassEmail) {
                alert('이메일을 입력해 주세요.');
                document.getElementsByName('freePassEmail')[0].focus();
                return false;
            }
            if (!form.freePassPassword) {
                alert('비밀번호를 입력해 주세요.');
                document.getElementsByName('freePassPassword')[0].focus();
                return false;
            }
            setCheckSubmit(true);
        }
    }

    const saveLogic = (saveform) => {
        let result = window.confirm('저장하시겠습니까?');

        if (result) {
            Net.addFreePassSite(saveform, (response) => {
                if (response.status === 200 || response.status === 201) {
                    history.push('/freepass/site/list');
                }
            });
        }
    }

    const onCancelHandler = () => {
        history.push('/freepass/site/list');
    }

    return (
        <div className="w-100 h-100">
            <div className="w-100 h-3 display_table"/>
            <div className="w-100 h-1px bgC-efefef" />
            <div className="h-95">
                <div className="flex h-90">
                    <div className="w-50 h-100 overFlowYscrollView">
                        <div className="ml-55 mt-12">
                            {
                                cloneUser.menuGroup === 'ADMIN' ? (
                                    <div className="flex mb-3 w-90">
                                        <label className="label_title">고객사 {
                                            form.classification==='INCIDENT'  ? <></> : <>*</>
                                        }</label>
                                        <CustomSelectBox
                                            name="customer.code"
                                            categoryList={customerList}
                                            value={form.customer.code}
                                            onChangeHandler={(value, name) => selectBoxHandler('customer.code', {code: value, name: name})}
                                            callbackCodeName="Y"
                                        />
                                    </div>
                                ) : <></>
                            }
                            {
                                cloneUser.menuGroup === 'OPERATOR' ? <></> :(
                                    <div className="flex mb-3 w-90">
                                        <label className="label_title">현장 *</label>
                                        <CustomSelectBox
                                            name="site.code"
                                            categoryList={siteList}
                                            value={form.site.code}
                                            onChangeHandler={(value, name) => selectBoxHandler('site.code', {code: value, name: name})}
                                            callbackCodeName="Y"
                                        />
                                    </div>
                                )
                            }
                            <div className="flex mb-3 ">
                                <div className="w-110 h-26px">
                                    <CustomTextField
                                        className="w-65"
                                        title="이메일"
                                        name="freePassEmail"
                                        required={true}
                                        disabled={false}
                                        validLen="20"
                                        customOnChange={textFieldHandler}
                                        value={form}
                                        disable={false}
                                    />
                                </div>
                            </div>
                            <div className="flex mb-3 col-12 ">
                                <div className="w-110 h-26px">
                                    <CustomTextField
                                        className="w-65"
                                        title="패스워드"
                                        name="freePassPassword"
                                        required={true}
                                        disabled={false}
                                        validLen="20"
                                        customOnChange={textFieldHandler}
                                        value={form}
                                        disable={false}
                                    />
                                </div>
                            </div>
                            <div className="flex mb-3 w-90">
                                <label className="label_title">AccessToken</label>
                                <div className="w-100">
                                    <TextareaAutosize
                                        name="freePassAccessToken"
                                        className="board_content form-control ml-15 col-12 h-25"
                                        onChange={(event)=>{
                                            setForm({...form,
                                                freePassAccessToken: event.target.value});
                                        }}
                                        value={form.freePassAccessToken}
                                        minRows={3}
                                        type="text"
                                    />
                                </div>
                            </div>
                            <div className="flex mb-3 w-90">
                                <label className="label_title">비고</label>
                                <div className="w-100">
                                    <TextareaAutosize
                                        name="freePassMessage"
                                        className="board_content form-control ml-15 col-12 h-25"
                                        onChange={(event)=>{
                                            setForm({...form,
                                                freePassMessage: event.target.value});
                                        }}
                                        value={form.freePassMessage}
                                        minRows={3}
                                        type="text"
                                    />
                                </div>
                            </div>
                            <div className="flex w-90 mb-3">
                                <label className="label_title">AutoParking</label>
                                <CustomRadioButton
                                    className="mr-70"
                                    title="사용"
                                    id="radioBtn1"
                                    name="AutoParking"
                                    value={true}
                                    checked={form.AutoParking}
                                    customOnChange={radioBtnHandler}
                                />
                                <CustomRadioButton
                                    title="사용안함"
                                    id="radioBtn2"
                                    name="AutoParking"
                                    value={false}
                                    checked={!form.AutoParking}
                                    customOnChange={radioBtnHandler}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="w-45 h-100">

                    </div>
                </div>
                <div className="footer_btn h-10 mt-2">
                    <div className="">
                        <button className="w-160px h-30px addBtn mr-10" onClick={onSubmitHandler}>저장</button>
                        <button className="w-160px h-30px cancelBtn" onClick={onCancelHandler}>취소</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default withRouter(FreePassSiteRegist);
