import React, { useEffect, useState } from 'react';
import { CustomTextField, CustomRadioButton, ImgUpload, SearchAddress } from 'components/molecules';
import { useHistory, withRouter } from 'react-router-dom';
import store from 'scripts/store';
import Net from 'scripts/net';

const CustomerRegist = ({match}) => {

    const history = useHistory();

    const [form, setForm] = useState({
        code: '',
        name: '',
        englishName: '',
        phoneNumber: '',
        representativeName: '',
        postalCode: '',
        address: '',
        detailAddress: '',
        imageId: '',
        imageDataUrl: '',
        content: '',
        usable: true,
    });
    const [registPage, setRegistPage] = useState(true);
    const [duplicateChk, setDuplicateChk] = useState(false);
    const [isDaumPost, setIsDaumPost] = useState(false);

    useEffect(() => {
        store.openedPage = '고객사 등록/수정';

        // 상세 조회
        if(match.params.code !== undefined) {
            Net.getCustomerOne(match.params.code, (response) => {
                if(response.status === 200) {
                    setForm({
                        ...form,
                        ...response.data,
                    });

                    // 장비타입코드 중복체크 true
                    setDuplicateChk(true);
                    // 등록페이지 여부 확인
                    setRegistPage(false);
                }
            });
        }
    }, []);

    const textFieldHandler = (name, value) => {
        // 장비타입코드 중복체크 후 수정 시 중복체크 재확인 요망을 위한 값 셋팅
        if(name === 'code') setDuplicateChk(false);
        const nextForm = {
            ...form,
            [name]: value
        }
        setForm(nextForm);
    }

    const radioBtnHandler = (name, value) => {
        const nextForm = {
            ...form,
            [name]: value === 'true',
        }
        setForm(nextForm);
    }

    const onDuplicateHandler = () => {
        if(form.code !== '') {
            Net.chkDuplicateCustomer(form.code, (response) => {
                if(response.status === 200) {
                    alert('이미 등록된 고객사ID입니다.');
                } else if(response.status === 404) {
                    alert('등록 가능한 고객사ID입니다.');
                    setDuplicateChk(true);
                }
            });
        } else {
            alert('고객사ID를 입력해주세요.');
        }
    }

    const openAddressPopUpHandler = () => {
        setIsDaumPost(true);
    }

    const closeAddressPopUpHandler = (e) => {
        setIsDaumPost(Boolean(e));
    }

    const addressData = (data) => {
        setForm({
            ...form,
            postalCode: data.zonecode,
            address: data.address,
        })
    }

    const getImgFile = (dataUrl) => {
        setForm({
            ...form,
            imageDataUrl: dataUrl,
        });
    }

    const onSubmitHandler = () => {
        if(form.code === '') {
            alert('고객사ID는 필수값입니다.');
            document.getElementsByName('code')[0].focus();
            return false;
        }
        if(!duplicateChk) {
            alert('고객사ID 중복체크를 해주세요.');
            return false;
        }
        if(form.name === '') {
            alert('고객사명은 필수값입니다.');
            document.getElementsByName('name')[0].focus();
            return false;
        }
        if(form.phoneNumber === '') {
            alert('전화번호는 필수값입니다.');
            document.getElementsByName('phoneNumber')[0].focus();
            return false;
        }
        if(form.postalCode === '' || form.address === '') {
            alert('주소는 필수값입니다.');
            document.getElementsByName('postalCode')[0].focus();
            return false;
        }
        if(form.detailAddress === '') {
            alert('주소상세는 필수값입니다.');
            document.getElementsByName('detailAddress')[0].focus();
            return false;
        }

        var result = window.confirm('저장하시겠습니까?');
        if(result) {
            if(registPage) {
                Net.addCustomer(form, (response) => {
                    if(response.status === 201) {
                        history.push('/location/customer/list');
                    }
                });
            } else {
                Net.updateCustomer(form, (response) => {
                    if(response.status === 200) {
                        history.push('/location/customer/list');
                    }
                });
            }

        }
    }

    const onCancelHandler = () => {
        history.push('/location/customer/list');
    }

    const onRemoveHandler = () => {
        const result = window.confirm('삭제하시겠습니까?');
        if(result) {
            Net.removeCustomerList([form.code], (response) => {
                if(response) {
                    history.push('/location/customer/list');
                }
            });
        }
    }

    return (
        <div className="w-100 h-100">
            {
                isDaumPost ?
                    <SearchAddress className="w-40" isDaumPost={isDaumPost} closePopUp={closeAddressPopUpHandler} rtnData={addressData}/>
                    : null
            }
            <div className="w-100 h-7 display_table"/>
            <div className="w-100 h-1px bgC-efefef" />
            <div className="h-93">
                <div className="flex h-90">
                    <div className="w-60 h-100">
                        <div className="ml-55 mt-12">
                            <div className="flex mb-12 w-90">
                                <CustomTextField
                                    className="w-70 h-26px"
                                    title="고객사 ID"
                                    name="code"
                                    required={true}
                                    validRegExp={/[ㄱ-ㅎ|ㅏ-ㅣ|가-힣|\{\}\[\]\/?.,;:|\)*~`!^+<>@\#$%&\\\=\(\'\"]/g}
                                    placeholder="코드는 영문 및 숫자 조합이며, 단어와 단어 사이는 언더바 및 하이픈(-) 사용"
                                    customOnChange={textFieldHandler}
                                    value={form}
                                    disabled={match.params.code !== undefined}
                                />
                                <div className="mt-auto mb-auto">
                                    <button
                                        className="w-78px h-22px duplicateBtn"
                                        onClick={onDuplicateHandler}
                                    >중복체크</button>
                                </div>
                            </div>
                            <div className="flex mb-12 w-90">
                                <CustomTextField
                                    className="w-70 h-26px"
                                    title="고객사명"
                                    name="name"
                                    required={true}
                                    disabled={false}
                                    validLen="15"
                                    validRegExp={/[\{\}\[\]\/?.,;:|\)*~`!^\-_+<>@\#$%&\\\=\(\'\"]/gi}
                                    placeholder="고객사명은 특수문자 제외 최대 15자"
                                    customOnChange={textFieldHandler}
                                    value={form}
                                />
                            </div>
                            <div className="flex mb-12 w-90">
                                <CustomTextField
                                    className="w-70 h-26px"
                                    title="고객사영문명"
                                    name="englishName"
                                    required={false}
                                    disabled={false}
                                    customOnChange={textFieldHandler}
                                    value={form}
                                />
                            </div>
                            <div className="flex mb-12 w-90">
                                <CustomTextField
                                    className="w-70 h-26px"
                                    title="전화번호"
                                    name="phoneNumber"
                                    required={true}
                                    disabled={false}
                                    validLen="11"
                                    validRegExp={/[ㄱ-ㅎ|ㅏ-ㅣ|가-힣|a-zA-Z\{\}\[\]\/?.,;:|\)*~`!^\-+<>@\#$%&\\\=\(\'\"]/g}
                                    customOnChange={textFieldHandler}
                                    value={form}
                                />
                            </div>
                            <div className="flex mb-12 w-90">
                                <CustomTextField
                                    className="w-70 h-26px"
                                    title="대표자명"
                                    name="representativeName"
                                    required={false}
                                    disabled={false}
                                    customOnChange={textFieldHandler}
                                    value={form}
                                />
                            </div>
                            <div className="flex mb-12 w-90">
                                <CustomTextField
                                    className="w-15 h-26px"
                                    title="주소"
                                    name="postalCode"
                                    required={true}
                                    disabled={false}
                                    customOnChange={textFieldHandler}
                                    value={form}
                                />
                                <CustomTextField
                                    className="w-55 h-26px ml-10"
                                    name="address"
                                    required={false}
                                    disabled={true}
                                    customOnChange={textFieldHandler}
                                    value={form}
                                />
                                <div className="mt-auto mb-auto">
                                    <button
                                        className="w-78px h-22px duplicateBtn"
                                        onClick={openAddressPopUpHandler}
                                    >주소검색</button>
                                </div>
                            </div>
                            <div className="flex mb-12 w-90">
                                <CustomTextField
                                    className="w-70 h-26px"
                                    title="주소상세"
                                    name="detailAddress"
                                    required={true}
                                    disabled={false}
                                    customOnChange={textFieldHandler}
                                    value={form}
                                />
                            </div>
                            <div className="flex w-90">
                                <label className="label_title">사용유무</label>
                                <CustomRadioButton
                                    className="mr-60"
                                    title="사용"
                                    id="radioBtn1"
                                    name="usable"
                                    value={true}
                                    checked={form.usable}
                                    customOnChange={radioBtnHandler}
                                />
                                <CustomRadioButton
                                    title="사용안함"
                                    id="radioBtn2"
                                    name="usable"
                                    value={false}
                                    checked={!form.usable}
                                    customOnChange={radioBtnHandler}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="w-40 h-100">
                        <div className="w-100 h-100 mt-12">
                            <div className="floatC mr-55">
                                <ImgUpload
                                    title={"사진"}
                                    imgUpload={getImgFile}
                                    imgFile={form.imageDataUrl}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer_btn h-10">
                    <button className="w-160px h-30px addBtn mr-10" onClick={onSubmitHandler}>저장</button>
                    { registPage ? "" : <button className="w-160px h-30px removeBtn mr-10" onClick={onRemoveHandler}>삭제</button>}
                    <button className="w-160px h-30px cancelBtn" onClick={onCancelHandler}>취소</button>
                </div>
            </div>
        </div>
    )
}

export default withRouter(CustomerRegist);
