import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom'
import { CustomTable, SearchInputBar, CustomTextField, CustomRadioButton, BasicModal, CustomSelectBox } from 'components/molecules';
import store from 'scripts/store';
import Common from 'scripts/common';
import Net from 'scripts/net';
import {usePageAuth } from "scripts/hooks";
import TextareaAutosize from "react-textarea-autosize";

const columnList = [
    {
        code: 'versionControlId',
        title: 'versionControlId',
        width: '8',
        hidden: true,
    },
    {
        code: 'customer.name',
        title: '고객사',
        width: '30',
    },
    {
        code: 'appType',
        title: '앱 구분',
        width: '30',
    },
    {
        code: 'version',
        title: '버전',
        width: '30',
    },
    {
        code: 'description',
        title: '사유',
        width : '50'
    },
    {
        code: 'boolForceUpdateName',
        title: '강제 업데이트 유무',
        width: '20',
    },
    {
        code: 'boolGeneralUpdateName',
        title: '일반 업데이트 유무',
        width: '20',
    },
    {
        code: 'usable',
        title: '사용 여부',
        width: '20',
    },
];

export const VersionManageList = (props) => {
    // useHistory 선언
    const history = useHistory();
    const user = props.userInfo;
    const programAuth = usePageAuth(props.userInfo.authList[0], sessionStorage.getItem('menuId'));
    const [contentData, setContentData] = useState([]);
    const [checkedList, setCheckedList] = useState([]);
    const [loading, setLoading] = useState(false);

    const [searchCategory, setSearchCategory] = useState([
        [
            [
                {code: '', name: '고객사 선택'}
            ]
        ],
        [
            {code: '', name: 'OS 선택'},
            {code: 'AOS', name: 'AOS'},
            {code: 'IOS', name: 'IOS'},
        ]
    ]);

    const [pageState, setPageState] = useState({
        totalElementsCnt: 0,
        drawElementsCnt: 0,
    });

    const [searchState, setSearchState] = useState({
        currentPage: 0,
        rowPerPage: 8,
        searchText: '',
        searchCategory1: user.menuGroup === 'ADMIN' ? '' : user.customer && user.customer.code,
        searchCategory2: '',
        boolForceUpdate : false,
        boolGeneralUpdate : false,
    })

    const [modalState, setModalState] = useState({
        open: false,
        data: [],
        code: '',
    });

    useEffect(() => {
        store.openedPage = '앱 버전 관리';
        getCategoryList('');

    }, [])

    useEffect(() => {
        loadData();
    }, [searchState.currentPage,
        searchState.searchText,
        searchState.searchCategory1,
        searchState.searchCategory2,
        modalState.open,
    ]);

    const loadData = async () => {
        setLoading(true);
        const response = await Net.getVersionControlListAsync({
            currentPage : searchState.currentPage,
            rowPerPage : searchState.rowPerPage,
            searchText : searchState.searchText,
            searchCategory1 : searchState.searchCategory1,
            searchCategory2 : searchState.searchCategory2,
        });
        if(response.status === 200){
            setLoading(false);
            setContentData(response.data.content.map(item=>{
                if(item.boolForceUpdate) item.boolForceUpdateName = '사용'
                else item.boolForceUpdateName = '사용안함'

                if(item.boolGeneralUpdate) item.boolGeneralUpdateName = '사용'
                else item.boolGeneralUpdateName = '사용안함'

                return item;
            }));
            setPageState({
                ...pageState,
                totalElementsCnt: response.data.totalElements,
                drawElementsCnt: response.data.numberOfElements,
            });
        }else {
            setLoading(false);
            setContentData([]);
            setPageState({
                ...pageState,
                totalElementsCnt: 0,
                drawElementsCnt: 0,
            });
        }

        setTimeout(()=>{
            setLoading(false);
        },[3000])
    }

    const getCategoryList = () => {
        const response2 = searchCategory[1];
        if (user.menuGroup === 'ADMIN') {
            Common.getCustomerList((response1) => {
                    setSearchCategory([response1, response2]);
            });
        }
    }


    const onClickRow = (e) => {
        onClickModalOpen(e.versionControlId);
    }

    const onMovePage = (e) => {
        setSearchState({
            ...searchState,
            currentPage: e-1
        });
    }

    const removeList = async () => {
        const result = window.confirm('삭제하시겠습니까?');
        const removeList = [];
        checkedList.forEach((id) => {
            const node = document.querySelector(`#${id}`);
            const nodeTr = node.closest('tr');
            const versionControlId = nodeTr.querySelector('td[name=versionControlId]').textContent;
            removeList.push({id: versionControlId});
        });

        if (result) {
            const response = await Net.removeVersionControlListAsync(removeList);
            console.log(response);
            if(response){
                loadData();
            }
        }

    }

    const onSubmitHandler = (e) => {
        setSearchState({
            ...searchState,
            searchCategory1: e.selectedCategory[0],
            searchCategory2: e.selectedCategory[1],
            searchText: e.searchText,
        });
    }

    const addList = () => {
        onClickModalOpen();
    }

    const onClickModalOpen = (id) => {
        setModalState({
            ...modalState,
            open: !modalState.open,
            id: id
        });
    }

    const sendData = (e) => {
        setModalState({
            ...modalState,
            data: e,
        })
    }

    return (
        <div className="w-100 h-100">
            <BasicModal
                className="w-35"
                b_className="pe-5"
                title={`버전관리 등록/수정`}
                content={<ModalContent
                            sendData={sendData}
                            modalClose={(e) => setModalState({
                                                    ...modalState,
                                                    open: e
                                                })
                                        }
                            id={modalState.id}
                        />}

                modalOpen={modalState.open}
                modalClose={(e) => setModalState({
                    ...modalState,
                    open: e
                })}
                sendData={modalState.data}
                //rcvData={rcvData}
            />
            <div className="w-100 h-7 display_table">
                <SearchInputBar
                    className="w-100"
                    searchCategory={true}
                    searchCategoryGroup={searchCategory}
                    searchText={true}
                    searchPlaceHolder="버전을 입력하세요"
                    searchBtn={true}
                    searchBtnTitle="검색"
                    dynamicSearch={false}
                    onSubmit={onSubmitHandler}
                    refreshBtn={true}
                    refreshHandler={loadData}
                />
                {
                    programAuth.saveAuth &&
                    <div className="display_table_cell v-middle">
                        <button
                            className="addBtn w-90px h-30px floatR mr-20"
                            onClick={addList}
                        >등록</button>
                    </div>
                }
            </div>
            <div className="w-100 h-1px bgC-efefef" >
                <div className="flex h-93">
                    <div className="w-100 h-100">
                        <p className="totalCnt">Total {pageState.totalElementsCnt}</p>
                        {
                            programAuth.deleteAuth && checkedList.length > 0 &&
                            <button
                                className="removeBtn w-90px h-30px floatR mt-7 mr-15"
                                onClick={removeList}
                            >삭제</button>
                        }
                        <CustomTable
                            columnList = {columnList}
                            contentData = {contentData}
                            paging = {true}
                            checkbox = {programAuth.deleteAuth}
                            checkedList = {(checkedList) => {setCheckedList(checkedList)} }
                            totalElementsCnt = {pageState.totalElementsCnt}
                            rowPerPage = {searchState.rowPerPage}
                            currentPage = {searchState.currentPage}
                            drawElementsCnt = {pageState.drawElementsCnt}
                            pagination = {onMovePage}
                            rowClickEvt = {programAuth.saveAuth}
                            onClickRow = {onClickRow}
                            loading = {loading}
                            // sort = {true}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

export const ModalContent = (props) => {

    const [form, setForm] = useState({
        versionControlId: '',
        customer : {
            code : '',
            name : '',
        },
        appType : '',
        version : '',
        description : '',
        boolForceUpdate : false,
        boolGeneralUpdate : false,
        usable: true,
    });
    const [registPage, setRegistPage] = useState(true);
    const [customerList, setCustomerList] = useState([{
        code: '',
        name: '고객사명 선택'
    }]);
    const [classificationList, setClassificationList] = useState([
            {code: '', name: '구분 선택'},
            {code: 'AOS', name: 'AOS'},
            {code: 'IOS', name: 'IOS'}
    ]);
    const textFieldHandler = (name, value) => {
        //if(name === 'code') setDuplicateChk(false);
        const nextForm = {
            ...form,
            [name]: value
        }
        setForm(nextForm);
    }

    useEffect(() => {
        async function mounted(){
            Common.getCustomerList((response) => {
                setCustomerList(response);
            });
            // 수정인 경우
            if(props.id) {
                const response = await Net.getVersionControlOneAsync(props.id);
                if(response.status === 200){
                    setForm({
                        ...form,
                        ...response.data
                    });

                    setRegistPage(false);
                }
            }
        }
        mounted().then(r => console.log("mounted"));
    }, [])


    const onRemoveHandler = async () => {
        const result = window.confirm('삭제하시겠습니까?');
        if(result) {
            const response = await Net.removeVersionControlAsync(form.versionControlId);
            if(response.status === 200){
                props.modalClose(false);
            } else{
                alert(' 일시적인 오류. 관리자 문의');

            }
        }
    }

    const onSubmitHandler = async () => {

        let result = window.confirm('저장하시겠습니까?');
        if(result) {
            if(registPage) {
                console.log(form);
                const response = await Net.getVersionControlListAsync({
                    currentPage : 0,
                    rowPerPage : 9999,
                    searchCategory2 : form.appType,
                    searchText : form.version,
                    description : form.description,
                    searchCategory1 : form.customer.code
                });
                if(response.data) {
                    alert('이미 등록된 버전 입니다.');
                } else{
                    // 등록
                    const addResponse = await Net.addVersionControlAsync(form);
                    if(addResponse.status === 200){
                        props.modalClose(false);
                    }else {
                        alert(' 일시적인 오류. 관리자 문의');
                    }
                }
            } else {
                const updateResponse = await Net.updateVersionControlAsync(form);
                if(updateResponse.status === 200) {
                    props.modalClose(false);
                } else {
                    alert(' 일시적인 오류. 관리자 문의');
                }

            }
        }
    };

    const handleClose = () => {
        props.modalClose(false);
    };

    const selectBoxHandler = (name, value) => {
        switch (name) {
            case 'customer.code':
                setForm({
                    ...form,
                    customer: {
                        code: value.code,
                        name: value.name
                    }
                });
                break;
            case 'appType':
                setForm({
                    ...form,
                    appType: value
                });
                break;
            default:
                break;
        }
    }

    const radioBtnHandler = (name, value) => {
        let nextForm;
         nextForm = {
                ...form,
                [name]: value === 'true',
         }
        setForm(nextForm);
    }
    return (
        <div className="h-100">
            <div className="h-90">
                <div className="flex mb-2 w-90">
                    <label className="label_title">고객사 *</label>
                    <CustomSelectBox
                        name="customer.code"
                        className="d-block"
                        categoryList={customerList}
                        value={form.customer.code}
                        onChangeHandler={(value, name) => selectBoxHandler('customer.code', {code: value, name: name})}
                        callbackCodeName="Y"
                        disabled={!registPage}
                    />
                </div>
                <div className="flex mb-3 w-90">
                    <label className="label_title">앱 구분 *</label>
                    <CustomSelectBox
                        name="appType"
                        categoryList={classificationList}
                        value={form.appType}
                        onChangeHandler={(value) => selectBoxHandler('appType', value)}
                        callbackCodeName="N"
                        disabled={!registPage}
                    />
                </div>
                <div className="flex mb-3 w-100">
                    <CustomTextField
                        className="w-50 h-26px"
                        title="버전"
                        name="version"
                        required={true}
                        disabled={false}
                        placeholder="ex) 01.00.02"
                        customOnChange={textFieldHandler}
                        value={form}
                    />
                </div>
                <div className="flex mb-3 w-90">
                    <label className="label_title">사유</label>
                    <div className="w-100">
                        <TextareaAutosize
                            name="description"
                            className="board_content form-control ms-3 col-12 h-25"
                            onChange={(event)=>{
                                setForm({...form,
                                    description: event.target.value});
                            }}
                            value={form.description}
                            minRows={3}
                            type="text"
                        />
                    </div>
                </div>
                <div className="flex w-90 mb-3">
                    <label className="label_title">강제 업데이트 *</label>
                    <CustomRadioButton
                        className="mr-70"
                        title="사용"
                        id="boolForceUpdateY"
                        name="boolForceUpdate"
                        value={true}
                        checked={form.boolForceUpdate}
                        customOnChange={radioBtnHandler}
                    />
                    <CustomRadioButton
                        title="사용 안함"
                        id="boolForceUpdateN"
                        name="boolForceUpdate"
                        value={false}
                        checked={!form.boolForceUpdate}
                        customOnChange={radioBtnHandler}
                    />
                </div>
                <div className="flex w-90 mb-3">
                    <label className="label_title">일반 업데이트 *</label>
                    <CustomRadioButton
                        className="mr-70"
                        title="사용"
                        id="boolGeneralUpdateY"
                        name="boolGeneralUpdate"
                        value={true}
                        disabled={form.boolForceUpdate}
                        checked={form.boolGeneralUpdate}
                        customOnChange={radioBtnHandler}
                    />
                    <CustomRadioButton
                        title="사용 안함"
                        id="boolGeneralUpdateN"
                        name="boolGeneralUpdate"
                        value={false}
                        disabled={form.boolForceUpdate}
                        checked={!form.boolGeneralUpdate}
                        customOnChange={radioBtnHandler}
                    />
                </div>
                <div className="flex w-90 mb-3">
                    <label className="label_title">사용 여부 *</label>
                    <CustomRadioButton
                        className="mr-70"
                        title="사용"
                        id="usableY"
                        name="usable"
                        value={true}
                        checked={form.usable}
                        customOnChange={radioBtnHandler}
                    />
                    <CustomRadioButton
                        title="사용 안함"
                        id="usableN"
                        name="usable"
                        value={false}
                        checked={!form.usable}
                        customOnChange={radioBtnHandler}
                    />
                </div>
            </div>

            <div className="footer_btn h-10 mt-50 mb-30">
                <div className="">
                    <button className="w-80px h-30px addBtn mr-10" onClick={onSubmitHandler}>저장</button>
                    { registPage ? "" : <button className="w-80px h-30px removeBtn mr-10" onClick={onRemoveHandler}>삭제</button>}
                    <button className="w-80px h-30px cancelBtn" onClick={handleClose}>취소</button>
                </div>
            </div>
        </div>
    )
}
