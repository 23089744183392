import React, {Fragment, useEffect, useState} from 'react';
import {CustomTable, SearchInputBar} from 'components/molecules';
import store from 'scripts/store';
import Net from 'scripts/net';
import Common from 'scripts/common';
import {usePageAuth , useDidMountEffect} from "scripts/hooks";
import {HomenetAuthDetailList} from "./HomenetAuthDetailList";
import {Checkbox} from "@material-ui/core";

const columnList = [
    {
        code: 'siteCode',
        title: '현장',
        width: '12',
    },
    {
        code: 'dongHo',
        title: '동호',
        width: '7',
    },
    {
        code: 'homenetAuthorizationCount',
        title: '연동 계정 수',
        width: '6',
    },
    {
        code: 'homenetAccountCount',
        title: '홈넷 계정 수',
        width: '6',
    },
];

export const HomenetAuthMonitoring = (props) => {

    // useHistory 선언
    const user = props.userInfo;
    const programAuth = usePageAuth(props.userInfo.authList[0], sessionStorage.getItem('menuId'));
    const [defaultHo, setDefaultHo] = useState([]);
    const [searchCategory, setSearchCategory] = useState([
        [
            {code: '', name: '고객사 선택'}
        ],
        [
            {code: '', name: '현장명 선택'}
        ],
    ]);
    const [selectedCustomerCode, setSelectedCustomerCode] = useState(user.menuGroup === 'ADMIN' ? '' : user.customer.code);
    const [selectedSiteName, setSelectedSiteName] = useState('');
    const [selectedDongName, setSelectedDongName] = useState('');
    const [selectedHoName, setSelectedHoName] = useState('');
    const [contentData, setContentData] = useState([]);
    const [contentAuthData, setContentAuthData] = useState([]);
    const [contentMultiAuthData, setContentMultiAuthData] = useState([]);
    const [contentUnAuthData, setContentUnuthData] = useState([]);
    const [detailContentData, setDetailContentData] = useState([]);
    const [onlyHomenet, setOnlyHomenet] = useState(false);
    const [unHomenet, setUnHomenet] = useState(false);
    const [onlyMultiHomenet, setOnlyMultiHomenet] = useState(false);

    useEffect(() => {
        if( user.menuGroup === 'OPERATOR' ){
            setSelectedSiteName(user.site.code);
        }
        store.openedPage = '세대별 홈넷 인증 현황';
        getCategoryList('');
    }, [])

    useDidMountEffect(()=>{
        if( selectedSiteName !== undefined){
            loadData();
        }
    },[selectedSiteName])

    const getCategoryList = () => {
        if (user.menuGroup === 'ADMIN') {
            Common.getCustomerList((response) => {
                setSearchCategory([response, searchCategory[1]]);
            });

        } else if (user.menuGroup === 'MANAGER') {
            Common.getSitesList(selectedCustomerCode, (response) => {
                setSearchCategory([response]);
            });

        }
    }

    const loadData =  async () => {
        let tempMap = new Map();
        setContentData([]);
        const response = await Net.getHomenetAuthorizationStatusBySiteAsync(selectedSiteName);
        if(response && response.status === 200){
            response.data.forEach(item => {
                tempMap.set(item.dongHo, { auth : item.homenetAuthorizationCount, account : item.homenetAccountCount});
            })
        }

        await Net.getSitePlaceList({
            siteCode:selectedSiteName,
            currentPage:1,
            rowPerPage:99999,
        }, (response2 ) => {
            if( response2.status === 200 ) {
                let tempList = [];
                let tempListMultiHomenet = [];
                let tempListUnHomenet = [];
                setContentData(response2.data.content.map(item => {
                    const siteName = window.SITE_LIST ? window.SITE_LIST[selectedSiteName].name : selectedSiteName;
                    const dongHo = item.dong+'-'+item.ho;
                    let authCount = 0;
                    let accountCount = 0;
                    if(tempMap.has(dongHo)){
                      authCount = tempMap.get(dongHo).auth;
                      accountCount = tempMap.get(dongHo).account;
                      if(accountCount > 1){
                          tempListMultiHomenet.push({
                              siteCode: siteName,
                              dongHo: dongHo,
                              homenetAuthorizationCount: authCount,
                              homenetAccountCount: accountCount,
                          })
                      }
                      tempList.push({
                          siteCode : siteName,
                          dongHo : dongHo,
                          homenetAuthorizationCount : authCount,
                          homenetAccountCount : accountCount,
                      })
                    }else{
                        tempListUnHomenet.push({
                            siteCode : siteName,
                            dongHo : dongHo,
                            homenetAuthorizationCount : authCount,
                            homenetAccountCount : accountCount,
                        })
                    }
                    return (
                        {
                            siteCode : siteName,
                            dongHo : dongHo,
                            homenetAuthorizationCount : authCount,
                            homenetAccountCount : accountCount,
                        }
                    )
                }));
                setContentAuthData(tempList);
                setContentMultiAuthData(tempListMultiHomenet);
                setContentUnuthData(tempListUnHomenet);
            }
        })
        // 현장코드 --> 이름변경해야함
    }
    const reload = async (e) => {
        if(e === 'reload'){
            const response = await Net.getHomenetAuthorizationStatusListAsync(
                selectedSiteName,
                selectedDongName,
                selectedHoName);
            setDetailContentData(response.data);
        }
    }

    const onSubmitHandler = async (e) => {
        if (user.menuGroup === 'ADMIN') {
            if(e.selectedCategory[0] === ''){
                alert("고객사를 선택해주세요.");
                return false;
            }else if(e.selectedCategory[1] === ''){
                alert("현장을 선택해주세요.");
                return false;
            }

            setSelectedSiteName(e.selectedCategory[1]);
            setDetailContentData([]);
        } else if (user.menuGroup === 'MANAGER') {
            if(e.selectedCategory[0] === ''){
                alert("현장을 선택해주세요.");
                return false;
            }
            setSelectedSiteName(e.selectedCategory[0]);
            setDetailContentData([]);
        }

    }

    const getRelateCategory = (selectedCategory) => {
        if (selectedCategory === "reset") selectedCategory = "";
        Common.getSitesList(selectedCategory, (response2) => {
            setSearchCategory([
                searchCategory[0],
                response2,
            ]);
        });
    }

    const onClickRow = async (e) => {
        //동 리스트
        const dong = e.dongHo.split('-',1);
        const ho = e.dongHo.split('-',2)[1];
        setSelectedDongName(dong);
        setSelectedHoName(ho);
        const response = await Net.getHomenetAuthorizationStatusListAsync(selectedSiteName,dong,ho);
        setDetailContentData(response.data);
    }

    return (
        <div className="w-100 h-100">
            <div className="w-100 h-1px bgC-efefef"/>
            <div className="h-93 row">
                <SearchInputBar
                    role={user.menuGroup}
                    className=""
                    searchCategory={user.menuGroup === 'ADMIN' || user.menuGroup === 'MANAGER'}
                    searchCategoryGroup={searchCategory}
                    searchBtn={user.menuGroup === 'ADMIN' || user.menuGroup === 'MANAGER'}
                    searchBtnTitle="검색"
                    dynamicSearch={false}
                    relateCategoryHandler={user.menuGroup === 'ADMIN' && getRelateCategory}
                    onSubmit={onSubmitHandler}
                />

                <div className="w-40 h-93 mt-3">
                    <div className="row">
                        <div className="col-12">
                            <h5 className="mt-1p mb-2 ml-2p ">현장별 홈넷 계정 현황</h5>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-4 flex justify-content-end">
                            <span className="text-muted mt-3 ml-2"
                                  style={{fontSize : "12px"}}
                            >미연동 세대</span>
                            <Checkbox
                                className={"mt-1"}
                                checked={unHomenet}
                                onChange={()=> setUnHomenet(!unHomenet)}
                            />
                        </div>

                        <div className="col-4 flex justify-content-end">
                            <span className="text-muted mt-3 ml-1"
                                style={{fontSize : "12px"}}
                            >연동 세대</span>
                            <Checkbox
                                className={"mt-1"}
                                checked={onlyHomenet}
                                disabled={unHomenet}
                                onChange={()=> setOnlyHomenet(!onlyHomenet)}
                            />
                        </div>
                        <div className="col-4 flex justify-content-end"
                             style={{fontSize : "12px"}}

                        >
                            <span className="text-muted mt-3">다중 홈넷 세대</span>
                            <Checkbox
                                className={"mt-1"}
                                checked={onlyMultiHomenet}
                                disabled={unHomenet}
                                onChange={()=> setOnlyMultiHomenet(!onlyMultiHomenet)}
                            />
                        </div>
                    </div>
                    <div className="h-95 overFlowYscrollView">
                        <CustomTable
                            className=" h-90"
                            columnList={columnList}
                            contentData={ unHomenet ? contentUnAuthData : (onlyMultiHomenet ? contentMultiAuthData :
                                onlyHomenet ? contentAuthData : contentData)}
                            onClickRow={onClickRow}
                            rowClickEvt={true}
                        />
                    </div>
                </div>
                <div className="w-60 h-93 mt-3 overflow-scroll">
                    <h5 className="mt-1p mb-1p ml-2p ">세대별 상세 현황</h5>
                    <HomenetAuthDetailList
                        detailContentData={detailContentData}
                        reload = {reload}
                        deleteAuth = {programAuth.deleteAuth}
                    />
                </div>
            </div>
        </div>
    );
}


