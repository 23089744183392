import React from "react";
import JSONPretty from 'react-json-pretty'
//import 'react-json-pretty/themes/monikai.css';
import './JsonDisplay.css';

export const JsonDisplay = ({data, children}) => {
    return (
      <JSONPretty data={data} />
    )
}
