import store from './store';
import axios from 'axios';

const guideAxios = axios.create({
    baseURL : store.api
});

guideAxios.defaults.headers.common['Content-Type'] = 'application/json; charset=UTF-8';

guideAxios.interceptors.request.use(
    function (config) {
        return config;
    },

    function (error) {
        return Promise.reject(error);
    }
);

guideAxios.interceptors.response.use(
    function (response) {
        return response;
    },

    function (error) {
        if (error.response !== undefined) {
            if (error.response.status === 401) {
                if (window.location.href.indexOf('/login') < 0) {

                } else if (error.response.data.message === 'Unauthorized') {
                    console.log(error.response.data)
                    //window.location.replace('/login');
                    return error.response;
                }
            } else {
                //store.alert('서버 통신 오류, 잠시 후 다시 시도해주세요.');
                return error.response;
            }
        }

        if(error.config.timeout && error.config.retry < 2){
            //console.log("retry...", error.config)
            const config = {
                ...error.config,
                retry : error.config.retry+1,
                timeout : error.config.timeout+500
            }
            return axios.request(config);
        } else{
            return Promise.reject(error);
        }
    }
);

function imageEncode(arrayBuffer) {
    let b64encoded = btoa([].reduce.call(new Uint8Array(arrayBuffer), function (p, c) {
        return p + String.fromCharCode(c)
    }, ''))
    let mimetype = "image/jpeg"
    return "data:" + mimetype + ";base64," + b64encoded
}

export default {
    /*
    * 현장 목록 조회 API
    * */
    async getBoardImg(image) {
        try {
            const response = await guideAxios
                .get(`/community/board/image/view/${image}`, {
                    responseType: 'arraybuffer'
                });
            return imageEncode(response.data);
        }catch (err){
            console.log("getBoardImg", err);
        }
    },

    async getGuideSitesListAsync() {

        try {
            return await guideAxios.get(`/community/board?page=0&sizePerPage=99999&category=sites`)

        } catch(err) {
            console.log(err);
            return err;
        }
    },


}


