import React, {Fragment, useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom'
import {CustomTable, SearchInputBar} from 'components/molecules';
import store from 'scripts/store';
import Net from 'scripts/net';
import {columnList} from "./content";
import {usePageAuth, useDidMountEffect } from "scripts/hooks";
import classNames from 'classnames';

export const UserAndDeviceMonitoring = (props) => {

    // useHistory 선언
    const history = useHistory();
    const user = props.userInfo;
    const programAuth = usePageAuth(props.userInfo.authList[0], sessionStorage.getItem('menuId'));
    const [contentData, setContentData] = useState([]);
    const [detailContentData, setDetailContentData] = useState([]);
    const [clickSite, setClickSite] = useState();
    const [clickSiteDongs, setClickSiteDong] = useState([]);
    const [clickedRowIdx, setClickedRowIdx] = useState(null);
    const [clickedRowIdxDong, setClickedRowIdxDong] = useState(null);
    const [searchCategory, setSearchCategory] = useState([
        [
            [
                {code: '', name: '고객사 선택'}
            ]
        ]
    ]);
    const [pageState, setPageState] = useState({
        totalElementsCnt: 0,
        drawElementsCnt: 0,
        searchCategory1: user.menuGroup === 'ADMIN' ? '' : user.customer && user.customer.code,
    });

    useEffect(() => {
        store.openedPage = '현장별 서비스별 사용자 가입 및 디바이스 현황';
        getCategoryList('');

    }, [])

    useDidMountEffect(()=>{
        loadData();
    },[pageState.searchCategory1])

    const loadData = async () => {
        //모니터링 정보 GET !
        const response = await Net.getUserDeviceCountsByCustomerAsync(pageState.searchCategory1);
        const response2 = await Net.getGoogleUserCountByCustomerAsync(pageState.searchCategory1);
        // window.SITE_LIST가 있는 경우에만 정렬 수행
        let sortedData = response.data;
        if (window.SITE_LIST) {
            // window.SITE_LIST 기준으로 정렬
            sortedData = response.data.sort((a, b) => {
                const siteAIndex = Object.keys(window.SITE_LIST).indexOf(a.site.code);
                const siteBIndex = Object.keys(window.SITE_LIST).indexOf(b.site.code);
                return siteAIndex - siteBIndex;
            });
        }

        setContentData(sortedData.map((item)=>{
            const googleData = response2.data.filter(x => x.siteCode === item.site.code);
            const userStatus = item.homenetUserCount === item.userAccountCount ? "정상" : "비정상";
            const deviceStatus = item.homenerUserDeviceCount === item.userDeviceCount ? "정상" : "비정상";
            return {
                customer : {
                    name : item.site.customer.name,
                    code : item.site.customer.code,
                },
                site : {
                    name : item.site.name,
                    code : item.site.code,
                },
                homenet: {
                    user : NumberConvert(item.homenetUserCount),
                    //device : NumberConvert(item.homenerUserDeviceCount),
                },
                authentication: {
                    user : NumberConvert(item.userAccountCount),
                    //device : NumberConvert(item.userDeviceCount),
                },
                status : {
                    user : userStatus,
                    device : deviceStatus
                },
                skt : {
                    user : NumberConvert(item.sktUserCount),
                    device : NumberConvert(item.sktUserDeviceCount)
                },
                kt : {
                    user : NumberConvert(item.ktUserCount),
                    device : NumberConvert(item.ktUserDeviceCount)
                },
                lgu : {
                    user : NumberConvert(item.lgUPlusUserCount),
                    device : NumberConvert(item.lgUplusUserDeviceCount)
                },
                lg : {
                    user : NumberConvert(item.lgUserCount),
                    device : NumberConvert(item.lgUserDeviceCount)
                },
                samsung : {
                    user : NumberConvert(item.samsungUserCount),
                    device : NumberConvert(item.samsungUserDeviceCount)
                },
                google : {
                    user : NumberConvert(googleData.length=== 0 ? '0' : googleData[0].googleUserCount)
                }
            }
        }))

    }
    const getCategoryList = () => {
        getCustomerList((response1) => {
            setSearchCategory([response1]);
        });
    }

    const getCustomerList = (callback) => {
        Net.getCustomerList(
            {currentPage: '', rowPerPage: '' ,searchText: ''}, (response) => {
                if (response.status === 200) {
                    let newArray = searchCategory[0][0];
                    response.data.content.forEach(items => {
                        newArray = [
                            ...newArray,
                            {
                                code: items.code,
                                name: items.name
                            }
                        ]
                    });
                    callback(newArray);
                }
            });
    }

    const getRelateCategory = (selectedCategory) => {
        if (selectedCategory === "reset") selectedCategory = "";
        setSearchCategory([
            searchCategory[0], // 고객사
        ]);
    }

    const onSubmitHandler = (e) => {
        if(e.selectedCategory[0] === ''){
            alert('고객사는 필수 선택입니다.');
            return;
        }
        setPageState({
            ...pageState,
            searchCategory1: e.selectedCategory[0],
        });
    }

    const onClickRow = async (e,index) => {
        setClickSite(e);
        setClickSiteDong([]);
        setClickedRowIdx(index)
        setClickedRowIdxDong(null);
        const response = await Net.getDongsBySiteAsync(e.site.code);
        setClickSiteDong(response.data);
        setDetailContentData([]);
    }
    const NumberConvert = (n) => {
        if(n === null || n === undefined) return ;

        const parts = n.toString().split('.');
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        return parts.join('.');
    }

    const onClickDongRow = async (e,index) => {
        //호 리스트
        setDetailContentData([]);
        setClickedRowIdxDong(index)
        const response = await Net.getUserDeviceCountsBySiteAndDongAsync(e.site.code, e.dong);
        await setDetailContentData(response.data.map((item)=>{
            const userStatus = item.homenetUserCount === item.userAccountCount ? "정상" : "비정상";
            const deviceStatus = item.homenerUserDeviceCount === item.userDeviceCount ? "정상" : "비정상";
            return {
                homenet: {
                    user : NumberConvert(item.homenetUserCount),
                    device : NumberConvert(item.homenerUserDeviceCount),
                },
                authentication: {
                    user : NumberConvert(item.userAccountCount),
                    device : NumberConvert(item.userDeviceCount),
                },
                status : {
                    user : userStatus,
                    device : deviceStatus
                },
                skt : {
                    user : NumberConvert(item.sktUserCount),
                    device : NumberConvert(item.sktUserDeviceCount)
                },
                kt : {
                    user : NumberConvert(item.ktUserCount),
                    device : NumberConvert(item.ktUserDeviceCount)
                },
                lgu : {
                    user : NumberConvert(item.lgUPlusUserCount),
                    device : NumberConvert(item.lgUplusUserDeviceCount)
                },
                lg : {
                    user : NumberConvert(item.lgUserCount),
                    device : NumberConvert(item.lgUserDeviceCount)
                },
                samsung : {
                    user : NumberConvert(item.samsungUserCount),
                    device : NumberConvert(item.samsungUserDeviceCount)
                }
            }
        }))

    }

    return (
        <div className="w-100 h-100">
            <div className="w-100 h-1px bgC-efefef"/>
            <div className="h-93">
                <div className="w-100 h-60 overflowYscroll">
                    <div className="row ">
                        <SearchInputBar
                            className="col-md-8 mt-4 ml-12"
                            searchCategory={user.menuGroup === 'ADMIN'}
                            searchCategoryGroup={searchCategory}
                            searchBtn={user.menuGroup === 'ADMIN'}
                            searchBtnTitle="검색"
                            dynamicSearch={false}
                            relateCategoryHandler={user.menuGroup === 'ADMIN' && getRelateCategory}
                            onSubmit={onSubmitHandler}
                        />
                        <h5 className="floatL mt-1p mb-1p ml-2p">현장별 사용자 현황</h5>
                        <table className="mainTable hoverbtn ">
                            <colgroup>
                                <col width="10%"/>
                                <col width="15%"/>
                                {columnList.map((item,index) => <col key={'a'+index} width="5%"/>)}
                                {columnList.map((item,index) => <col key={'b'+index} width="5%"/>)}
                            </colgroup>

                            <thead>
                            <tr>
                                <th className="tableHead fixedHeader v-middle t-0px" rowSpan="2"
                                    colSpan="1">고객사
                                </th>
                                <th className="tableHead fixedHeader v-middle t-0px" rowSpan="2"
                                    colSpan="1">현장
                                </th>
                                {
                                    columnList.map((item, index) => <th className="tableHead fixedHeader t-0px"
                                                                        colSpan="2"
                                                                        key={item.code}>{item.name}</th>)
                                }
                            </tr>
                            <tr>
                                {
                                    columnList.map((item, index) =>
                                        <>
                                            <th className="v-middle tableHead fixedHeader t-36px" colSpan={item.device ? 1 : 2}><span
                                                className="w-24px">{item.user}</span></th>
                                            {
                                                item.device ?  <th className="v-middle tableHead fixedHeader t-36px"><span
                                                    className="w-24px">{item.device}</span></th> : <></>
                                            }

                                        </>
                                    )
                                }
                            </tr>
                            </thead>
                            <tbody className="tableBody">
                            {
                                contentData.length > 0 ?
                                    (
                                        <Fragment>
                                            {
                                                contentData.map((list,index) => {
                                                    const clicked = clickedRowIdx === index ? 'clicked' : '';
                                                    return (
                                                        <tr key={list.site.code} className={clicked}>
                                                            <td key={index+'_1'} onClick={()=>onClickRow(list,index)} style={{whiteSpace: "initial", borderBottom: "none"}}>{list.customer.name}</td>
                                                            <td key={index+'_2'} onClick={()=>onClickRow(list,index)} style={{whiteSpace: "initial", borderBottom: "none"}}>{list.site.name}</td>
                                                            {

                                                                columnList.map((data, index2) =>
                                                                    <>
                                                                        <td key={index+'_1_'+index2} colSpan={list[data.code].hasOwnProperty('device') ? 1 : 2} onClick={()=>onClickRow(list,index)} style={{whiteSpace: "initial", borderBottom: "none"}}>{list[data.code].user}</td>
                                                                        {
                                                                            list[data.code].hasOwnProperty('device') ?
                                                                                <td key={index+'_2_'+index2} onClick={()=>onClickRow(list,index)} style={{whiteSpace: "initial", borderBottom: "none"}}>{list[data.code].device}</td>
                                                                                : <></>
                                                                        }
                                                                    </>
                                                                )
                                                            }
                                                        </tr>
                                                    )
                                                })
                                            }

                                        </Fragment>
                                    ) : (
                                        <tr>
                                            <td colSpan="12">조회 된 데이터가 없습니다.</td>
                                        </tr>
                                    )
                            }
                            </tbody>
                        </table>
                    </div>
                </div>
                <h5 className="floatL mt-10 mb-1p ml-2p">동별 사용자 및 디바이스 현황 - {clickSite ? clickSite.site.name : ''}</h5>
                <div className="w-95 h-40 row">
                    <div className="col-3 h-100 overFlowYscrollView ">
                        <table className="mainTable ">
                            <colgroup>
                                <col width="100%"/>
                            </colgroup>
                            <thead className="bgC-c5c5c5">
                            <tr>
                                <th className="v-middle tableHead fixedHeader "><span
                                    className="h-24px">동</span></th>
                            </tr>
                            </thead>
                            <tbody className="tableBody">
                            {
                                clickSite ? (
                                    <>
                                        {
                                            clickSiteDongs ?
                                                clickSiteDongs.map((item,index) => {
                                                    const clicked = clickedRowIdxDong===index ? 'clickedTd' : '';
                                                    return <tr key={index}><td key={item.dong} className={classNames.bind()('text-center hoverbtn',clicked)} onClick={()=>onClickDongRow(item, index)} colSpan="2">{item.dong}</td></tr>
                                                }) : <></>
                                        }
                                    </>
                                ) : (
                                    <tr>
                                        <td className="v-middle" rowSpan="2" colSpan="2">
                                            현장 미선택
                                        </td>
                                    </tr>
                                )
                            }
                            </tbody>
                        </table>
                    </div>
                    <div className="col-9">
                        <table className="mainTable ">
                            <colgroup>
                                {columnList.map(() => <col width="5%"/>)}
                                {columnList.map(() => <col width="5%"/>)}
                            </colgroup>

                            <thead>
                            <tr>
                                {
                                    columnList.map((item, index) => <th className="tableHead fixedHeader t-0px"
                                                                        colSpan="2"
                                                                        key={item.code}>{item.name}</th>)
                                }
                            </tr>
                            <tr>
                                {
                                    columnList.map((item, index) =>
                                        <>
                                            <th key={'a'+index} className="v-middle tableHead fixedHeader t-36px"><span
                                                className="w-24px">{item.user}</span></th>
                                            <th key={'b'+index} className="v-middle tableHead fixedHeader t-36px"><span
                                                className="w-24px">{item.device}</span></th>
                                        </>
                                    )
                                }
                            </tr>
                            </thead>
                            <tbody className="tableBody">
                            {
                                detailContentData.length > 0 ?
                                    (
                                        <Fragment>
                                            {
                                                detailContentData.map((list,index) => {
                                                    return (
                                                        <tr key={index}>
                                                            {
                                                                columnList.map((data, index) =>
                                                                    <>
                                                                        <td key={index+'user'} style={{whiteSpace: "initial", borderBottom: "none"}}>{list[data.code] ? list[data.code].user : '0'}</td>
                                                                        <td key={index+'device'} style={{whiteSpace: "initial", borderBottom: "none"}}>{list[data.code]? list[data.code].device : '0'}</td>
                                                                    </>
                                                                )
                                                            }
                                                        </tr>
                                                    )
                                                })
                                            }

                                        </Fragment>
                                    ) : (
                                        <tr>
                                            <td colSpan="16">조회 된 데이터가 없습니다.</td>
                                        </tr>
                                    )
                            }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
}



