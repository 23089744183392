import React from 'react'
import { Switch, Route } from 'react-router-dom';
import { ToolsManufacturerList, ToolsManufacturerRegist } from './ToolsManufacturerPage';
import { ToolsPropertyList, ToolsPropertyRegist } from './ToolsPropertyPage';
import { ToolsTypeList, ToolsTypeRegist } from './ToolsTypePage';
import { ToolsModelList, ToolsModelRegist } from './ToolsModelPage';
import { ToolsDeviceList, ToolsDeviceRegist } from './ToolsDevicePage';
import NotFound from "../../components/templates/NotFound";

export const ToolsPage = ({userInfo}) => {
    return (
        <Switch>
            <Route path={"/tools/type/list"} exact children={<ToolsTypeList userInfo={userInfo}/>}/>
            <Route path={"/tools/property/list"} exact children={<ToolsPropertyList userInfo={userInfo}/>}/>
            <Route path={"/tools/manufacturer/list"} exact children={<ToolsManufacturerList userInfo={userInfo}/>}/>
            <Route path={"/tools/model/list"} exact children={<ToolsModelList userInfo={userInfo}/>}/>
            <Route path={"/tools/device/list"} exact children={<ToolsDeviceList userInfo={userInfo}/>}/>
            <Switch>
                <Route path={"/tools/type/regist/:code"} children={<ToolsTypeRegist/>} />
                <Route path={"/tools/type/regist"} children={<ToolsTypeRegist/>} />

                <Route path={"/tools/property/regist/:code"} children={<ToolsPropertyRegist/>} />
                <Route path={"/tools/property/regist"} children={<ToolsPropertyRegist/>} />

                <Route path={"/tools/manufacturer/regist/:code"} children={<ToolsManufacturerRegist/>} />
                <Route path={"/tools/manufacturer/regist"} children={<ToolsManufacturerRegist/>} />

                <Route path={"/tools/model/regist/:manufacturerCode/:code"} children={<ToolsModelRegist/>} />
                <Route path={"/tools/model/regist"} children={<ToolsModelRegist/>} />

                <Route path={"/tools/device/regist/:deviceId"} children={<ToolsDeviceRegist/>} />
                <Route path={"/tools/device/regist"} children={<ToolsDeviceRegist/>} />
            </Switch>
            <Route path={"*"}>
                <NotFound />
            </Route>
        </Switch>
    )
}
