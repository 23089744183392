import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { CookiesProvider } from 'react-cookie';
import App from '../shared/App'
import {HelmetProvider} from "react-helmet-async";


const Root = () => (
        <CookiesProvider>
            <BrowserRouter>
                <HelmetProvider>
                <App/>
                </HelmetProvider>
            </BrowserRouter>
        </CookiesProvider>
);

export default Root;
