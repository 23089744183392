import React, {useEffect, useState} from 'react'
import store from 'scripts/store';
import Net from 'scripts/net';
import Common from 'scripts/common';
import {useHistory} from "react-router-dom";


export const Etc = (props) => {
    const history = useHistory();
    const user = props.userInfo;

    useEffect(() => {
        store.openedPage = '대시 보드';
        init().then(() => {
            console.log("init complete");
        });
    }, [])




    const init = async () => {

    }

    return (
        <div className='container-fluid bgC-operator overFlowYscrollViewHidden h-100'>
           <h4>전수 테스트 전용 계정입니다.</h4>
        </div>
    )
}
