import React, {Fragment, useEffect, useState} from 'react';
import {CustomPagination, CustomTable, SearchInputBar, BasicModal} from 'components/molecules';
import store from 'scripts/store';
import Net from 'scripts/net';
import {toast} from "react-toastify";
import {useHistory} from "react-router-dom";
import classNames from "classnames";

const columnList = [
    {
        code: 'reportId',
        title: 'reportId',
        width: '8',
        hidden: true,
    },
    {
        code: 'classification',
        title: '구분',
        width: '8',
    },
    {
        code: 'username',
        title: '사용자명',
        width: '8',
    },
    {
        code: 'reportedUsername',
        title: '신고글 사용자',
        width: '8',
    },
    {
        code: 'reportedReasonCode',
        title: '신고 코드',
        width: '10',
    },
    {
        code: 'createdDatetime',
        title: '신고 일시',
        width: '10',
    },
    {
        code: 'reportedDelteYn',
        title: '삭제 여부',
        width: '5',
    },
];

export const ReportList = (props) => {
    // useHistory 선언
    const history = useHistory();
    const user = props.userInfo;
    const [contentData, setContentData] = useState([]);
    const [feedDetail, setFeedDetail] = useState([]);
    const [commentDetail, setCommentDetail] = useState([]);
    const [commentData, setCommentData] = useState([]);
    const [programAuth, setProgramAuth] = useState({});
    const [clickedRowIdx, setClickedRowIdx] = useState(null);

    const [pageState, setPageState] = useState({
        currentPage: 0,
        rowPerPage: 10,
        totalElementsCnt: 0,
        drawElementsCnt: 0,
        searchCategory1 : '',
        searchText: '',
        searchText2: '',

    });

    const [searchCategory, setSearchCategory] = useState([
        [
            {code: '', name: '전체 선택'},
            {code: 'FEED', name: '피드'},
            {code: 'COMMENTS', name: '댓글'},
        ],
    ]);

    const [commentPageState, setCommentPageState] = useState({
        currentPage: 0,
        rowPerPage: 8,
        searchCategory1 : '',
        totalElementsCnt: 0,
        drawElementsCnt: 0,
    });

    const [loading, setLoading] = useState(false);

    const [isFeedYn , setIsFeedYn] = useState(null);

    useEffect(() => {
        store.openedPage = '피드 신고 목록';
        pageAuthInfo();
    }, [])

    useEffect(() => {
        setTimeout(() => {
            loadData();
        }, 10)
    }, [pageState.currentPage,
        pageState.searchText,
        pageState.searchText2,
        pageState.searchCategory1
    ]);

    useEffect(() => {
        goSearchComment(null);
    }, [commentPageState.currentPage]);

    useEffect(()=>{
        setLoading(false);
    },[contentData])
    const pageAuthInfo = () => {
        const authGroupCode = props.userInfo.authList[0];
        const menuId = sessionStorage.getItem('menuId');

        Net.getAuthGroupMenuList(menuId, (response) => {
            if(response.status === 200) {
                response.data.forEach(item => {
                    if(item.authGroup === authGroupCode && item.authGroupMenu) {
                        setProgramAuth({
                            searchAuth: Boolean(item.authGroupMenu.searchAuth),
                            saveAuth: Boolean(item.authGroupMenu.saveAuth),
                            excelAuth: Boolean(item.authGroupMenu.excelAuth),
                            deleteAuth: Boolean(item.authGroupMenu.deleteAuth),
                        });
                    }
                });
            }
        });
    }

    const loadData = () => {
        setLoading(true);
        Net.getReportList(
            {
                currentPage: pageState.currentPage,
                rowPerPage: pageState.rowPerPage,
                searchCategory1 : pageState.searchCategory1,
                searchText: pageState.searchText,
                searchText2: pageState.searchText2,
            }, async (response) => {
                setTimeout(()=>{
                    setLoading(false);
                },[2000])
                if (response.status === 200) {
                    const contentData_ = await Promise.all(
                        response.data.content.map(async item => {
                            if (item.classification === "FEED") {
                                const feedOneResponse = await Net.getFeedOneNotImage(item.parentId);
                                if (feedOneResponse.status === 200) {
                                    item.feedData = feedOneResponse.data;
                                    if (feedOneResponse.data.reportedDeleteYn) {
                                        item.reportedDelteYn = "삭제"
                                    } else {
                                        item.reportedDelteYn = "-"
                                    }
                                } else {
                                    item.reportedDelteYn = "원본 삭제";
                                }
                            } else {
                                const commentOneResponse = await Net.getFeedCommentOne(item.parentId);
                                if (commentOneResponse.status === 200) {
                                    item.commentData = commentOneResponse.data;
                                    if (commentOneResponse.data.reportedDeleteYn) {
                                        item.reportedDelteYn = "삭제"
                                    } else {
                                        item.reportedDelteYn = "-"
                                    }
                                } else {
                                    item.reportedDelteYn = "원본 삭제";
                                }
                            }
                            return item;
                        })
                    )
                    setContentData(contentData_);
                    setPageState({
                        ...pageState,
                        totalElementsCnt: response.data.totalElements,
                        drawElementsCnt: response.data.numberOfElements,
                    });
                } else {
                    setContentData([]);
                    setPageState({
                        ...pageState,
                        totalElementsCnt: 0,
                        drawElementsCnt: 0,
                    });
                    setCommentPageState({
                        ...commentPageState,
                        totalElementsCnt: 0,
                        drawElementsCnt: 0,
                    });
                }
                setFeedDetail([]);
                setCommentData([]);
            });
    }

    const getRelateCategory = (selectedCategory) => {
        if (selectedCategory === "reset") selectedCategory = "";
    }

    const onClickRow = (e) => {
        if(e.classification === "FEED"){
            Net.getFeedOne(e.parentId, (response) => {
                if (response.status === 200) {
                    setFeedDetail(response.data);
                } else {
                    setFeedDetail([]);
                }
            });
            goSearchComment(e.parentId);
            setIsFeedYn(true);
        }else{
            setFeedDetail({
                feedId : e.parentId,
                commentsId : e.commentsId,
                contents : e.commentData.contents
            });
            setCommentDetail(e.commentData);
            setCommentData([]);
            setCommentPageState({
                currentPage: 0,
                rowPerPage: 8,
                totalElementsCnt: 0,
                drawElementsCnt: 0,
            });
            setIsFeedYn(false);
        }
        setClickedRowIdx(null);
    }


    const goSearchComment = (feedId) => {
        if (!feedId) {
            feedId = feedDetail.feedId;
        }
        Net.getFeedCommentList({currentPage: commentPageState.currentPage, rowPerPage: commentPageState.rowPerPage, feedId: feedId}, (response) => {
            if (response.status === 200) {
                setCommentData(response.data.content);
                setCommentPageState({
                    ...commentPageState,
                    totalElementsCnt: response.data.totalElements,
                    drawElementsCnt: response.data.numberOfElements,
                });
            } else {
                setCommentData([]);
                setCommentPageState({
                    currentPage: 0,
                    rowPerPage: 8,
                    totalElementsCnt: 0,
                    drawElementsCnt: 0,
                });
            }
        });
    }

    const onMovePage = (e) => {
        setPageState({
            ...pageState,
            currentPage: e-1,
        });
    }

    const onMoveCommentPage = (e) => {
        setCommentPageState({
            ...commentPageState,
            currentPage: e - 1
        });
    }

    const onSubmitHandler = (e) => {
            setPageState({
                ...pageState,
                currentPage: 0,
                searchText: e.searchText,
                searchText2: e.searchText2,
                searchCategory1: e.selectedCategory[0],
            });
    }

    const deleteOne = () => {
        if(isFeedYn){
            Net.updateFeedOne({...feedDetail, reportedDeleteYn : true}, response => {
                if(response.status === 200){
                    loadData();
                }
            })
        }else {
            Net.updateFeedComment({
                ...commentDetail,
                reportedDeleteYn : true
            }, response => {
                if(response.status === 200){
                    loadData();
                }
            })
        }


    }

    return (
        <div className="w-100 h-100">
            <div className="row col-12 h-7 ">
                <div className="col-9 mt-2">
                    <SearchInputBar
                        className="w-100 searchInput-wrapper-nodp "
                        searchCategory={user.menuGroup === 'ADMIN' || user.menuGroup === 'MANAGER'}
                        searchCategoryGroup={searchCategory}
                        searchText={true}
                        searchPlaceHolder={"사용자"}
                        searchText2={true}
                        searchPlaceHolder2={"신고된 사용자"}
                        searchBtn={true}
                        searchBtnTitle="검색"
                        dynamicSearch={false}
                        relateCategoryHandler={user.menuGroup === 'ADMIN' && getRelateCategory}
                        onSubmit={onSubmitHandler}
                        refreshBtn={true}
                        refreshHandler={loadData}
                    />
                </div>
            </div>
            <div className="w-100 h-1px bgC-efefef"/>
            <div className="flex">
                <div className="w-50 h-93 overflowYscrollView">
                    <p className="totalCnt">Total {pageState.totalElementsCnt}</p>
                    {
                        feedDetail.feedId ?
                            (
                                <button
                                    className="cancelBtn w-90px h-30px floatR mt-20 mr-15"
                                    onClick={deleteOne}
                                > 삭제 처리
                                </button>
                            ) : <></>
                    }

                    <CustomTable
                        columnList={columnList}
                        contentData={contentData}
                        paging={true}
                        totalElementsCnt={pageState.totalElementsCnt}
                        rowPerPage={pageState.rowPerPage}
                        currentPage={pageState.currentPage}
                        drawElementsCnt={pageState.drawElementsCnt}
                        pagination={onMovePage}
                        rowClickEvt={true}
                        onClickRow={onClickRow}
                        loading={loading}
                        // sort = {true}
                    />
                </div>
                <div className="w-30 h-93" style={{display: "block"}}>
                    <div className="w-100 h-60vh mt-60">
                        {/* <div className="w-100 h-45" style={{display: "table"}}> */}
                        <div className="w-100 h-100" style={{display: "table"}}>
                            <table className="mainTable">
                                <thead className="tableHead">
                                <tr>
                                    <th>상세 내용</th>
                                </tr>
                                </thead>
                                <tbody className="tableBody">
                                {
                                    feedDetail.contents ?
                                        (
                                            <Fragment>
                                                <tr>
                                                    <td style={{padding: "10px", overflow: "unset", whiteSpace: "initial"}}>
                                                        <div className="h-57vh overFlowYscrollView">
                                                            <div dangerouslySetInnerHTML={{__html: feedDetail.contents}}/>
                                                            {
                                                                feedDetail.imageDataUrl &&
                                                                <div className="mt-10"><img src={feedDetail.imageDataUrl} alt="complex=Img" width="300px"/></div>
                                                            }
                                                        </div>

                                                    </td>
                                                </tr>

                                            </Fragment>


                                        ) : (
                                            <tr>
                                                <td>
                                                    <div className="h-7vh">
                                                        조회 된 데이터가 없습니다.
                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                }

                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div className="w-30 h-93 " style={{display: "block"}}>
                    <div className="w-100 h-60vh">
                        <div className="w-100 h-60vh mt-12 overFlowYscrollView">
                            <table className="mainTable ">
                                <colgroup>
                                    <col width="20%"/>
                                    <col width="80%"/>
                                </colgroup>
                                <thead>
                                <tr>
                                    <th className="tableHead fixedHeader t-0px" colSpan="2">댓글 리스트</th>
                                </tr>
                                <tr>
                                    <th className="v-middle tableHead fixedHeader t-36px"><span className="w-24px">사용자명</span></th>
                                    <th className="v-middle tableHead fixedHeader t-36px"><span className="w-48px">내용</span></th>
                                </tr>
                                </thead>
                                <tbody className="tableBody">
                                {
                                    commentData.length > 0 ?
                                        (
                                            <Fragment>
                                                {
                                                    commentData.map((list,index) => {
                                                        return (
                                                            <tr key={list.commentsId}
                                                                onClick={()=>{
                                                                    setClickedRowIdx(index);
                                                                }}
                                                                className={classNames({'clicked': index===clickedRowIdx})}
                                                            >
                                                                <td style={{whiteSpace: "initial", borderBottom: "none"}}>{list.user.userFullName}</td>
                                                                <td style={{whiteSpace: "initial", borderBottom: "none"}}>{list.contents}</td>
                                                            </tr>
                                                        )
                                                    })
                                                }

                                            </Fragment>
                                        ) : (
                                            <tr>
                                                <td colSpan="2">조회 된 데이터가 없습니다.</td>
                                            </tr>
                                        )
                                }
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="pagination-wrappper">
                        {commentData.length > 0 &&
                            <CustomPagination
                                activePage={commentPageState.currentPage}
                                itemsCountPerPage={commentPageState.rowPerPage}
                                totalItemsCount={commentPageState.totalElementsCnt}
                                pageRange={5}
                                onChange={onMoveCommentPage}
                            />
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}
