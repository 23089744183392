import React, {Fragment, useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom'
import {CustomTable, SearchInputBar} from 'components/molecules';
import store from 'scripts/store';
import Net from 'scripts/net';
import classNames from 'classnames';


const columnList = [
    {
        code: 'customer.name',
        title: '고객사',
        width: '8',
    },
    {
        code: 'site.name',
        title: '현장',
        width: '9',
    },
    {
        code: 'totalHousehold',
        title: '총 세대수',
        width: '6',
        number : true
    },
    {
        code: 'moveInHousehold',
        title: '가입세대',
        width: '6',
        number : true
    },
    {
        code: 'notInHousehold',
        title: '미가입세대',
        width: '6',
        number : true
    },
    {
        code: 'maxHousehold',
        title: 'MAX 세대',
        width: '7',
        number : true
    },
    {
        code: 'userCount',
        title: '세대당 평균가입자',
        width: '8',
    },

];

const detailColumnList =[
    {
        code: 'ho',
        title: '호',
        width: '5',
    },
    {
        code: 'userCount',
        title: '사용자',
        width: '8',
    },
    {
        code: 'homenetAccountCount',
        title: '홈넷계정',
        width: '8',
    },
];

const userColumnList =[
    {
        code: 'username',
        title: '사용자',
        width: '6',
    },
    {
        code: 'homenetUsername',
        title: '홈넷계정',
        width: '8',
    },
    {
        code: 'accountCreatedDatetime',
        title: '회원가입 일자',
        width: '10',
    },
    {
        code: 'homenetCreatedDatetime',
        title: '홈넷계정 연동일자',
        width: '10',
    },
];

export const SiteUserMonitoring = (props) => {

    // useHistory 선언
    const history = useHistory();
    const user = props.userInfo;
    const [programAuth, setProgramAuth] = useState({});
    const [contentData, setContentData] = useState([]);
    const [detailContentData, setDetailContentData] = useState([
        {
            ho: '101',
            user: '7',
            homenetAccount: '2',
        }
    ]);

    const [userContentData, setUserContentData] = useState([]);
    const [searchCategory, setSearchCategory] = useState([
        [
            [
                {code: '', name: '고객사 선택'}
            ]
        ]
    ]);
    const [clickSite, setClickSite] = useState();
    const [clickSiteDongs, setClickSiteDong] = useState([]);
    const [clickedRowIdx, setClickedRowIdx] = useState(null);

    const [pageState, setPageState] = useState({
        totalElementsCnt: 0,
        drawElementsCnt: 0,
        searchCategory1: user.menuGroup === 'ADMIN' ? '' : user.customer && user.customer.code,
    });

    useEffect(() => {
        store.openedPage = '현장별 사용자 가입 현황';
        pageAuthInfo();
        getCategoryList('');
    }, [])

    const pageAuthInfo = () => {
        const authGroupCode = props.userInfo.authList[0];
        const menuId = sessionStorage.getItem('menuId');

        Net.getAuthGroupMenuList(menuId, (response) => {
            if (response.status === 200) {
                response.data.forEach(item => {
                    if (item.authGroup === authGroupCode && item.authGroupMenu) {
                        setProgramAuth({
                            searchAuth: Boolean(item.authGroupMenu.searchAuth),
                            saveAuth: Boolean(item.authGroupMenu.saveAuth),
                            excelAuth: Boolean(item.authGroupMenu.excelAuth),
                            deleteAuth: Boolean(item.authGroupMenu.deleteAuth),
                        });
                    }
                });
            }
        });
    }

    useEffect(()=>{
        if(pageState.searchCategory1 !== '') {
            loadData();
        }
    },[pageState.searchCategory1])

    const loadData = async () => {
        //모니터링 정보 GET !
        setContentData([]);
        const response = await Net.getUserBySiteCountAsync(pageState.searchCategory1);
        const tempList = await response.data.map((item)=>{
            return {
                site : {
                    code : item.site.code,
                    name : item.site.name,
                },
                customer : {
                    code : item.site.customer.code,
                    name : item.site.customer.name,
                },
                totalHousehold : item.totalHousehold,
                moveInHousehold : item.moveInHousehold,
                maxHousehold : item.maxHousehold,
                userCount : (item.userCount/item.moveInHousehold).toFixed(2),
                notInHousehold : item.totalHousehold - item.moveInHousehold
            }
        })
        setContentData(tempList);
    }

    const getCategoryList = () => {
        getCustomerList((response1) => {
            setSearchCategory([response1]);
        });
    }

    const getCustomerList = (callback) => {
        Net.getCustomerList(
            {currentPage: '', rowPerPage: '' ,searchText: ''}, (response) => {
                if (response.status === 200) {
                    let newArray = searchCategory[0][0];
                    response.data.content.forEach(items => {
                        newArray = [
                            ...newArray,
                            {
                                code: items.code,
                                name: items.name
                            }
                        ]
                    });
                    callback(newArray);
                }
            });
    }

    const getRelateCategory = (selectedCategory) => {
        if (selectedCategory === "reset") selectedCategory = "";
            setSearchCategory([
                searchCategory[0], // 고객사
            ]);
    }

    const onSubmitHandler = (e) => {
        if(e.selectedCategory[0] === '') {
            alert('고객사명은 필수값입니다.');
            return false;
        }

        setPageState({
            ...pageState,
            searchCategory1: e.selectedCategory[0],
        });
    }

    const onClickRow = async (e) => {
        //동 리스트
        setClickSite(e);
        setClickSiteDong([]);
        const response = await Net.getDongsBySiteAsync(e.site.code);
        await setClickSiteDong(response.data);
        setUserContentData([]);
        setDetailContentData([]);
        setClickedRowIdx(null);
    }

    const onClickDongRow = async (e, index) => {
        //호 리스트
        const response = await Net.getUserCountsBySiteAndDongAsync(e.site.code, e.dong);
        if(response !== undefined){
            const sortedArray = response.data.sort((a, b) => {
                if (Number(a.ho) > Number(b.ho)) return 1;
                if (Number(a.ho) < Number(b.ho)) return -1;
            })
            await setDetailContentData(sortedArray);
            setUserContentData([]);
        }
        setClickedRowIdx(index);
    }

    const onClickHoRow = async (e) => {
        //세부 홈넷 리스트
        const response = await Net.getUserListByPlacement(e.siteCode, e.dong, e.ho);
        if(response.status === 200 ){
            await setUserContentData(response.data);
        }
    }

    return (
        <div className="w-100 h-100">
            <div className="w-100 h-1px bgC-efefef"/>
            <div className="h-93">
                <div className="w-97 h-50">
                    <div className="row ">
                        <SearchInputBar
                            className="col-md-8 mt-2 ml-12 mb-3"
                            searchCategory={true}
                            searchCategoryGroup={searchCategory}
                            searchBtn={true}
                            searchBtnTitle="검색"
                            dynamicSearch={false}
                            relateCategoryHandler={user.menuGroup === 'ADMIN' && getRelateCategory}
                            onSubmit={onSubmitHandler}
                        />
                        <h5 className="floatL mb-1p ml-2p">현장별 사용자 가입 현황</h5>
                        <div className="overFlowYscroll w-100 h-30vh d-inline-flex" style={{maxHeight : "100%"}}>
                            <CustomTable
                                className="hoverbtn w-100 overflowOverlay"
                                columnList={columnList}
                                contentData={contentData}
                                onClickRow = {onClickRow}
                                rowClickEvt = {true}
                            />

                        </div>
                    </div>
                </div>
                <h5 className="floatL mt-1p mb-1p ml-2p w-55">동별 사용자 현황 - {clickSite ? clickSite.site.name : ''}</h5>
                <h5 className="floatL mt-1p mb-1p ml-2p ">세대 사용자 / 홈넷 계정 정보</h5>

                <div className="w-95 h-50 flex ml-15">
                    <div className="w-30 h-80 overFlowYscrollView ">
                        <table className="mainTable">
                            <colgroup>
                                <col width="100%"/>
                            </colgroup>
                            <thead className="bgC-c5c5c5">
                            <tr>
                                <th className="v-middle tableHead fixedHeader "><span
                                    className="">동</span></th>
                            </tr>
                            </thead>
                            <tbody className="tableBody">
                            {
                                clickSite ? (
                                    <>
                                        {
                                            clickSiteDongs.length > 0 ? clickSiteDongs.map((item,index) => {
                                                return <tr key={index} className={classNames({'clicked':index===clickedRowIdx})} ><td key={item.dong} className="hoverbtn" onClick={()=>onClickDongRow(item, index)} colSpan="2">{item.dong}</td></tr>
                                            }) : <></>
                                        }
                                    </>
                                    ) : (
                                        <tr>
                                            <td className="v-middle" rowSpan="2" colSpan="2">
                                                현장 미선택
                                            </td>
                                        </tr>
                                    )
                                    }
                            </tbody>
                        </table>
                    </div>
                    <div className="w-30 h-90 overFlowYscrollView">
                        <CustomTable
                            className="hoverbtn h-100"
                            columnList={detailColumnList}
                            contentData={detailContentData}
                            onClickRow = {onClickHoRow}
                            rowClickEvt = {true}
                        />
                    </div>
                    <div className="w-40 h-90 overFlowYscrollView">
                        <CustomTable
                            className="h-100"
                            columnList={userColumnList}
                            contentData={userContentData}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}


