import React, {Fragment, useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom'
import {CustomTable, SearchInputBar} from 'components/molecules';
import store from 'scripts/store';
import Net from 'scripts/net';
import Common from 'scripts/common';
import {usePageAuth } from "scripts/hooks";
import {Checkbox} from "@material-ui/core";
import Zoom from "react-medium-image-zoom";
import DatePicker from "react-datepicker";

const columnList = [
    {
        code: 'o2oId',
        title: 'o2oId',
        width: '8',
        hidden: true,
    },
    {
        code: 'o2oServiceName',
        title: 'O2O 서비스명',
        width: '25',
    },
    {
        code: 'companyName',
        title: '업체명',
        width: '10',
    },
    {
        code: 'customer.name',
        title: '고객사명',
        width: '10',
    },
    {
        code: 'siteName',
        title: '현장명',
        width: '10',
    },
    {
        code: 'viewDate',
        title: '공개 기간',
        width: '15',
    },
    {
        code: 'sort',
        title: '정렬순서',
        width: '4',
    },
    {
        code: 'popupSort',
        title: '팝업정렬',
        width: '4',
    },
    {
        code: 'readCount',
        title: '조회수',
        width: '4',
    },
    {
        code: 'linkCount',
        title: '링크 클릭',
        width: '4',
    },
];

export const O2OList = (props) => {

    // useHistory 선언
    const history = useHistory();
    const user = props.userInfo;
    const [contentData, setContentData] = useState([]);
    const [checkedList, setCheckedList] = useState([]);
    const [o2oDetail, setO2oDetail] = useState([]);
    const programAuth = usePageAuth(props.userInfo.authList[0], sessionStorage.getItem('menuId'));
    const [loading, setLoading] = useState(false);
    const [clickLoading, setClickLoading] = useState(false);

    const [searchCategory, setSearchCategory] = useState([
        [
            [
                {code: '', name: '고객사 선택'}
            ]
        ],
        [
            [
                {code: '', name: '현장명 선택'}
            ]
        ]
    ]);
    const [searchState, setSearchState] = useState({
        currentPage: 0,
        rowPerPage: 7,
        searchText: '',
        searchCategory1: user.menuGroup === 'ADMIN' ? '' : user.customer && user.customer.code,
        searchCategory2: user.menuGroup === 'OPERATOR' ? user.site && user.site.code: '',
        isAllDay: true

    })

    const [pageState, setPageState] = useState({
        totalElementsCnt: 0,
        drawElementsCnt: 0,
    });

    const [startDate, setStartDate] = useState(new Date(2022,1,1));
    const [endDate, setEndDate] = useState(new Date());
    const [clickData, setClickData] = useState({
        readView : 0,
        linkClick : 0,
        linkClickDistinct : 0
    });


    useEffect(() => {
        store.openedPage = 'O2O 제휴서비스 목록';
        getCategoryList('');
    }, [])

    useEffect(()=>{
        if(searchState.currentPage === 0 ){
            loadData();
        }else {
            setSearchState({
                ...searchState,
                currentPage: 0
            });
        }
    },[searchState.isAllDay])

    useEffect(() => {
        setTimeout(() => {
            loadData();
        }, 10)
    }, [searchState.currentPage,
        searchState.searchCategory1,
        searchState.searchCategory2,
        searchState.searchText
    ]);

    useEffect(()=>{
        setClickLoading(false);
    },[clickData]);

    useEffect(() =>{
        if(o2oDetail.hasOwnProperty('o2oId')){
            onClickRow({o2oId : o2oDetail.o2oId}).then();
        }
    },[startDate, endDate])

    const loadData = () => {
        setLoading(true);
        Net.getO2OList(
            {
                currentPage: searchState.currentPage,
                rowPerPage: searchState.rowPerPage,
                searchText: searchState.searchText,
                searchCategory1: searchState.searchCategory1,
                searchCategory2: searchState.searchCategory2,
                isAllDay : searchState.isAllDay
            }, (error , response) => {
                setLoading(false);
                if(error !== null){
                    if (searchState.currentPage > 0) {
                        setSearchState({
                            ...searchState,
                            currentPage: searchState.currentPage - 1
                        });
                    }

                }
                else {
                    if (response.status === 200) {
                        //const sta = new Date();

                        console.log(response);
                        setContentData(response.data.content.map((item, i) => {
                            item.viewDate = item.startDate + " ~ " + item.endDate;
                            if(item.site){
                                let siteName_ = '';
                                item.site.forEach(siteItem=>{
                                    siteName_ += siteItem.name+', ';
                                });
                                item.siteName = siteName_;

                            }else{
                                item.siteName = '전체'
                            }

                            return item;
                        }))

                        setPageState({
                            ...pageState,
                            totalElementsCnt: response.data.totalElements,
                            drawElementsCnt: response.data.numberOfElements,
                        });
                    } else {
                        setContentData([]);
                    }
                }
            });
    }


    const getCategoryList = () => {
        if (user.menuGroup === 'ADMIN') {
            Common.getCustomerList((response1) => {
                Common.getSitesList(null, (response2) => {
                    setSearchCategory([response1, response2]);
                });
            });
        } else if (user.menuGroup === 'MANAGER') {
            Common.getSitesList(pageState.searchCategory1, (response2) => {
                setSearchCategory([response2]);
            });
        }
    }

    const getRelateCategory = (selectedCategory) => {
        if (selectedCategory === "reset") selectedCategory = "";
        Common.getSitesList(selectedCategory, (response2) => {
            setSearchCategory([
                searchCategory[0],
                response2
            ]);
        });
    }

    const onClickRow = async (e)  => {
        setClickLoading(true);
        Net.getO2OOne(e.o2oId, (response) => {
            if (response.status === 200) {
                setO2oDetail(response);
            } else{
                setO2oDetail([]);
            }
        });


        const o2oResponse = await Net.getO2OLinkList({
            classification : 'o2oLink',
            parentId : e.o2oId,
            startDate : startDate,
            endDate : endDate,
        })
        const readResponse = await Net.getReadListAsync({
            classification : 'o2oRead',
            parentId : e.o2oId,
            startDate : startDate,
            endDate : endDate
        })

        let _clickData = {
            readView : 0,
            linkClick : 0,
            linkClickDistinct : 0
        };

        if(o2oResponse.status === 200){
            let linkCount = 0;
            const linkSet = new Set();
            o2oResponse.data.content.forEach(item => {
                linkSet.add(item.username);
                linkCount++;
            });
            _clickData = {
                ..._clickData,
                linkClick : linkCount,
                linkClickDistinct : linkSet.size
            };
        } else {
            _clickData = {
                ..._clickData,
                linkClick : 0,
                linkClickDistinct : 0
            };
        }
        if(readResponse.status === 200) {
            _clickData = {
                ..._clickData,
                readView: readResponse.data.content.length
            };
        }

        await setClickData(_clickData);
        setTimeout(()=>{
            setClickLoading(false);
        },[3000])
    }

    const onMovePage = (e) => {
        setSearchState({
            ...searchState,
            currentPage: e - 1
        });
    }


    const removeList = () => {
        const result = window.confirm('삭제하시겠습니까?');
        const removeList = [];
        checkedList.map((id) => {
            const node = document.querySelector(`#${id}`);
            const nodeTr = node.closest('tr');
            const o2oId = nodeTr.querySelector('td[name=o2oId]').textContent;
            removeList.push({o2oId: o2oId});
        });
        if (result) {
            Net.removeO2O(removeList, (response) => {
                if (response) {
                     loadData();
                }
            });
        }
    }

    const onSubmitHandler = (e) => {
        if (user.menuGroup === 'ADMIN') {
            setSearchState({
                ...searchState,
                searchCategory1: e.selectedCategory[0],
                searchCategory2: e.selectedCategory[1],
                searchText: e.searchText

            });

        } else if (user.menuGroup === 'MANAGER') {
            setSearchState({
                ...searchState,
                searchCategory1: searchState.searchCategory1,
                searchCategory2: e.selectedCategory[0],
                searchText: e.searchText

            });
        } else {
            setSearchState({
                ...searchState,
                searchCategory1: searchState.searchCategory1,
                searchCategory2: searchState.searchCategory2,
                searchText: e.searchText

            });
        }
    }

    const addList = () => {
        history.push('/community/o2o/regist');
    }

    const goModifypage = () => {
        history.push(`/community/o2o/regist/${o2oDetail.o2oId}`);
    }

    return (
        <div className="w-100 h-100">
            <div className="w-100 h-7 display_table">
                <SearchInputBar
                    role={user.menuGroup}
                    className="w-100"
                    searchCategory={true}
                    searchCategoryGroup={searchCategory}
                    searchText={true}
                    searchPlaceHolder="업체명을 입력해 주세요."
                    searchBtn={true}
                    searchBtnTitle="검색"
                    dynamicSearch={false}
                    relateCategoryHandler={user.menuGroup === 'ADMIN' && getRelateCategory}
                    onSubmit={onSubmitHandler}
                    refreshBtn={true}
                    refreshHandler={loadData}
                />
                {
                    programAuth.saveAuth &&
                    <div className="display_table_cell v-middle">
                        <button
                            className="addBtn w-90px h-30px floatR mr-20"
                            onClick={addList}
                        >등록
                        </button>
                    </div>
                }
            </div>
            <div className="w-100 h-1px bgC-efefef"/>
            <div className="flex h-93">
                <div className="w-70 h-100">
                    <div className="h-72">
                    <div className="row">
                        <div className="col-8">
                            <p className="totalCnt">Total {pageState.totalElementsCnt}</p>
                            {
                                programAuth.deleteAuth && checkedList.length > 0 &&
                                <button
                                    className="removeBtn w-90px h-30px floatR mt-15 mr-5"
                                    onClick={removeList}
                                >삭제</button>
                            }
                        </div>
                        <div className="col-4 mt-2">
                            <span className="text-muted mt-7 mr-10">공개 중인 서비스만 보기</span>
                            <Checkbox
                                checked={searchState.isAllDay}
                                onChange={() => setSearchState({
                                    ...searchState,
                                    isAllDay: !searchState.isAllDay
                                })}
                            />
                        </div>
                    </div>
                    <CustomTable
                        columnList={columnList}
                        contentData={contentData}
                        paging={true}
                        checkbox={programAuth.deleteAuth}
                        checkedList={(checkedList) => {
                            setCheckedList(checkedList)
                        }}
                        totalElementsCnt={pageState.totalElementsCnt}
                        rowPerPage={searchState.rowPerPage}
                        currentPage={searchState.currentPage}
                        pagination={onMovePage}
                        drawElementsCnt={pageState.drawElementsCnt}
                        rowClickEvt={true}
                        onClickRow={onClickRow}
                        loading={loading}
                        displayAbsolute={true}
                        // sort = {true}
                    />
                </div>
                    <div className="h-20">
                        <div className="flex justify-content-between">
                            <p className="totalCnt"> O2O 링크 클릭</p>
                            <div className="mt-3 me-2">
                                <span className="searchDate">
                                    <label>검색기간</label>
                                    <span>
                                        <DatePicker
                                            dateFormat="yyyy-MM-dd"
                                            selected={startDate}
                                            onChange={date => {
                                                setStartDate(date);
                                            }}
                                            className="w-100 textField"
                                        />
                                    </span>
                                       <span className="ml-8 mr-8 m-auto"> ~ </span>
                                       <span className="mr-10">
                                           <DatePicker
                                               dateFormat="yyyy-MM-dd"
                                               selected={endDate}
                                               onChange={date => {
                                                   setEndDate(date);
                                               }} className="w-100 textField"
                                           />
                                     </span>
                                </span>
                            </div>
                        </div>
                        <div id={clickLoading ? "cover-spin" : ""}>
                        <table className="mainTable">
                            <colgroup>
                                <col width="50%"/>
                                <col width="15%"/>
                                <col width="15%"/>
                                <col width="20%"/>
                            </colgroup>
                            <thead className="tableHead">
                            <tr>
                                <th>제목</th>
                                <th>조회 수</th>
                                <th>링크 클릭 수</th>
                                <th>링크 클릭(중복 제거)</th>
                            </tr>
                            </thead>
                            <tbody className="tableBody">
                            <tr>
                                <td>
                                    {!clickLoading ? o2oDetail.o2oServiceName : ''}
                                </td>
                                <td>
                                    {clickData.readView}
                                </td>
                                <td>
                                    {clickData.linkClick}
                                </td>
                                <td>
                                    {clickData.linkClickDistinct}
                                </td>

                            </tr>
                            </tbody>
                        </table>
                        </div>
                    </div>
                </div>
                <div className="w-30 h-100">
                    <div className="flex">
                        <p className="body_content_subtitle">내용</p>
                        {
                            o2oDetail.o2oServiceOverview ?
                                (
                                    <button
                                        className="defaultBtn bgC-eb878c borderC-eb878c w-90px h-30px mr-20 mt-auto mb-auto ml-auto"
                                        onClick={goModifypage}
                                    >수정</button>
                                ) : ""
                        }

                    </div>
                    <div className="w-100 h-100">
                        <table className="mainTable">
                            <thead className="tableHead">
                            <tr>
                                <th>내용</th>
                            </tr>
                            </thead>
                            <tbody className="tableBody">
                            {
                                o2oDetail.o2oServiceOverview ?
                                    (
                                        <Fragment>
                                            <tr>
                                                <td style={{padding: "10px", overflow: "unset", whiteSpace: "initial"}}>
                                                    <div className="overFlowYscrollView h-60vh">
                                                        <div className="w-auto">
                                                            <p style={{whiteSpace : "pre-line"}}>
                                                                {o2oDetail.o2oServiceOverview}
                                                            </p>
                                                            </div>
                                                        {
                                                            o2oDetail.imageDataUrl &&
                                                            <div className="mt-10 text-center">
                                                                <Zoom>
                                                                <img src={o2oDetail.imageDataUrl} alt="complex=Img"
                                                                     width="300"/>
                                                                </Zoom>
                                                                <p>이미지</p>
                                                            </div>
                                                        }
                                                        {
                                                            o2oDetail.thumbnailImageDataUrl &&
                                                            <div className="mt-10 text-center">
                                                                <Zoom>
                                                                <img src={o2oDetail.thumbnailImageDataUrl} alt="complex=Image"
                                                                     width="300px"/>
                                                                </Zoom>
                                                                <p>썸네일</p>
                                                            </div>
                                                        }
                                                        {
                                                            o2oDetail.popupImageDataUrl &&
                                                            <div className="mt-10 text-center">
                                                                <Zoom>
                                                                <img src={o2oDetail.popupImageDataUrl} alt="complex=Image"
                                                                     width="300px"/>
                                                                </Zoom>
                                                                <p>팝업</p>
                                                            </div>
                                                        }
                                                    </div>
                                                </td>
                                            </tr>
                                        </Fragment>
                                    ) : (
                                        <tr>
                                            <td>조회 된 데이터가 없습니다.</ td>
                                        </tr>
                                    )
                            }

                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
}



