/* eslint-disable no-useless-escape */
import React, { useEffect, useState } from 'react';
import { useHistory, withRouter } from 'react-router-dom';
import { CustomTextField, CustomRadioButton, CustomSelectBox, ImgUpload, CustomCheckbox, BasicModal } from 'components/molecules';
import store from 'scripts/store';
import DatePicker from "react-datepicker";
import Net from 'scripts/net';
import Common from 'scripts/common';
import {toJS} from "mobx";
import {QuillEditor} from "../../../components/molecules";
import {EditorState} from "draft-js";

const VoteRegist = ({match}) => {

    const cloneUser = toJS(store.user)

    const history = useHistory();

    const [form, setForm] = useState({
        voteId: '',
        voteNum: '',
        title: '',
        contents: '',
        dong: '',
        customer : cloneUser.menuGroup === 'ADMIN' ? {code: '',name: '',} : cloneUser.customer,
        site: cloneUser.menuGroup === 'OPERATOR' ? cloneUser.site : { code: '', name: '',},
        classification: "",
        voteStartDate: '',
        voteEndDate: '',
        startDate: '',
        image: '',
        imageDataUrl: '',
        publicYn: true,
        agenda: [],
        newAgenda: [],
        removeAgendaList: [],
    });

    const [registPage, setRegistPage] = useState(true);
    const [voteStartDate, setVoteStartDate] = useState(new Date());
    const [voteEndDate, setVoteEndDate] = useState(new Date());
    const [startDate, setStartDate] = useState(new Date());
    const [customerList, setCustomerList] = useState([{
        code: '',
        name: '고객사명 선택'
    }]);
    const [siteList, setSiteList] = useState([{
        code: '',
        name: '현장명 선택'
    }]);
    const [classificationList, setClassificationList] = useState([
        [
            {code: '', name: '투표종류 선택'}
        ]
    ]);
    const [selectHourList, setSelectHourList] = useState([]);

    const [modalState, setModalState] = useState({
        open: false,
        data: [],
        vData: {
            voteId: '',
            agendaId: '',
        }
    });

    const [checkedItems, setCheckedItems] = useState({
        agenda: []
    });
    const [checkSubmit, setCheckSubmit] = useState(false);
    const [editorState, setEditorState] = useState(EditorState.createEmpty());

    useEffect(() => {
        store.openedPage = '주민투표 등록/수정';
        loadData();
    }, []);

    useEffect(()=>{
        if(checkSubmit){
            saveLogic(form);
        }
    },[checkSubmit])

    useEffect(() => {
        dateChangeHandler();
    }, [ startDate, voteStartDate, voteEndDate ])

    useEffect(() => {
        if(form.customer.code !== '') {
            getSiteList(form.customer.code, (response) => {
                setSiteList(response);
            })
        } else {
            // 빈값 선택시 장비모델명 selectbox 초기화
            setSiteList([
                {
                    code: '',
                    name: '현장명 선택'
                }
            ])
        }
    }, [form.customer.code]);

    useEffect(()=>{
    },[form])

    const setPageState = (status) => {
         // 고객사 전체 목록
         getCustomerList((response) => {
            setCustomerList(response);
        });
        getClassificationList();
        createHourList();

        status(true);
    }

    const loadData = () => {
        setPageState(status => {
            if(status){
                 // 상세 조회(수정 목적으로 진입시)
                if(match.params.voteId) {

                    Net.getVoteOne(match.params.voteId, (response) => {

                        if(response.status === 200) {
                            if(!response.data.customer){
                                response.data.customer = {code: "", name: ""};
                            }
                            if(!response.data.site){
                                response.data.site = {code: "", name: ""};
                            }

                            if(response.data.dong === "ALL"){
                                response.data.dong = "";
                            }

                            setEditorState(Common.htmlToDraftBlocks(response.data.contents));

                            //투표안건 리스트 가져오기
                            Net.getVoteAgendaList(match.params.voteId, (response2) => {

                                if(response2.status === 200) {
                                    if(!response2.data){
                                        response2.data = {voteId: "", agendaId: ""};
                                    }
                                    setForm({
                                        ...form,
                                        ...response.data,
                                        agenda: response2.data.sort((a, b) => a["voteNum"] - b["voteNum"]),
                                        newAgenda: response2.data.slice(),
                                    });
                                }
                            });

                            // 등록페이지 여부 확인
                            setRegistPage(false);

                            setStartDate(new Date(response.data.startDate));
                            setVoteStartDate(new Date(response.data.voteStartDate));
                            setVoteEndDate(new Date(response.data.voteEndDate));
                        }
                    });
                }
            }
        })
    }

    const getCustomerList = (callback) => {
        Net.getCustomerList({currentPage: '', rowPerPage: '', searchText: ''}, (response) => {
            if(response.status === 200) {
                let newArray = customerList;
                response.data.content.forEach(items => {
                    newArray = [
                        ...newArray,
                        {
                            code: items.code,
                            name: items.name
                        }
                    ]
                });
                callback(newArray);
            }
        });
    }

    const getSiteList = (code, callback) => {
        Net.getSitesList(
            {
                currentPage: '',
                rowPerPage: '',
                searchText: '',
                customerCode: code,
            }, (response) => {
            if(response.status === 200) {
                let newArray = [
                    {
                        code: '',
                        name: '현장명 선택'
                    }
                ];
                response.data.content.forEach(items => {
                    newArray = [
                        ...newArray,
                        {
                            code: items.code,
                            name: items.name
                        }
                    ]
                });
                callback(newArray);
            } else if(response.status === 204) {
                let newArray = [
                    {
                        code: '',
                        name: '현장명 선택'
                    }
                ];
                callback(newArray);
            }
        });
    }

    const getClassificationList = () => {
        Net.getCommunityCode(
            "VOTE_CLASSIFICATION", (response) => {
                if(response.status === 200) {
                    let newArray = classificationList[0];

                    response.data.forEach(items => {
                        if( items.usable ){
                            newArray = [
                                ...newArray,
                                {
                                    code: items.code,
                                    name: items.name
                                }
                            ]
                        }
                    })
                    setClassificationList(newArray);
                }
            }
        )

    }

    const createHourList = () => {
        let hourList = [];
        for(let i=0; i<=24; i++) {
           hourList.push({code: i, name: i+"시"});
        }
        setSelectHourList(hourList)
    }


    const textFieldHandler = (name, value) => {
        const nextForm = {
            ...form,
            [name]: value
        }
        setForm(nextForm);
    }

    // const onKeyPressHandler = (e) => {
    //     const keyValue = e.which;
    //     if( ((keyValue >= 48) && (keyValue <= 57)) ) return true;
    //     else return e.preventDefault();
    // }

    const radioBtnHandler = (name, value) => {
        let nextForm;
        if(name === "period"){
            nextForm = {
                ...form,
                [name]: value,
            }
        } else {
            nextForm = {
                ...form,
                [name]: value === 'true',
            }
        }

        setForm(nextForm);
    }

    const selectBoxHandler = (name, value) => {
        switch (name) {
            case 'customer.code':
                setForm({
                    ...form,
                    customer: {
                        code: value.code,
                        name: value.name
                    },
                    site: {
                        code: "",
                        name: ""
                    }
                });
                break;
            case 'site.code':
                setForm({
                    ...form,
                    site: {
                        code: value.code,
                        name: value.name
                    }
                });
                break;
            case 'classification':
                setForm({
                    ...form,
                    classification: value
                });
                break;
            case 'startHour':
                setForm({
                    ...form,
                    startHour: value
                });
                break;
            case 'endHour':
                setForm({
                    ...form,
                    endHour: value
                });
                break;
            default:
                break;
        }
    }

    const onSubmitHandler = () => {

        if (!form.classification) {
            alert('투표종료를 선택해 주세요.');
            document.getElementsByName('classification')[0].focus();
            return false;
        }
        if (!form.title) {
            alert('제목을 입력해 주세요.');
            document.getElementsByName('title')[0].focus();
            return false;
        }
        if (!voteStartDate) {
            alert('시작일자를 입력해 주세요.');
            document.getElementsByName('voteStartDate')[0].focus();
            return false;
        } else if (!voteEndDate) {
            alert('종료일자를 입력해 주세요.');
            document.getElementsByName('voteEndDate')[0].focus();
            return false;
        } else if (!startDate) {
            alert('공개일자를 입력해 주세요.');
            document.getElementsByName('startDate')[0].focus();
            return false;
        } else {
            const voteStartDt = Number(form.voteStartDate.replace(/\-/g, ''));
            const voteEndDt = Number(form.voteEndDate.replace(/\-/g, ''));
            const startDt = Number(form.startDate.replace(/\-/g, ''));

            if (voteStartDt > voteEndDt) {
                alert("종료일자는 시작일자 이전일 수 없습니다.");
                document.getElementsByName('voteEndDate')[0].focus();
                return false;
            } else if (startDt > voteStartDt) {
                alert("공개일자는 시작일자 이전이어야 합니다.");
                document.getElementsByName('startDate')[0].focus();
                return false;
            }
        }
        if(form.newAgenda.length < 1 ){
            alert("투표안건을 1개 이상 등록하셔야만 합니다.");
            return false;
        }

        if (cloneUser.menuGroup === 'OPERATOR') {
                setForm({
                    ...form,
                    customer: cloneUser.customer,
                    site: cloneUser.site ,
                });

        } else if (cloneUser.menuGroup === 'MANAGER') {
            if (!form.site.code) {
                alert('현장명을 선택해 주세요.');
                document.getElementsByName('site.code')[0].focus();
                return false;
            }
                setForm({
                    ...form,
                    customer: cloneUser.customer,
                });
        } else {
            if (!form.customer.code) {
                alert('고객사를 선택해 주세요.');
                document.getElementsByName('customer.code')[0].focus();
                return false;
            }
            if (!form.site.code) {
                alert('현장명을 선택해 주세요.');
                document.getElementsByName('site.code')[0].focus();
                return false;
            }
        }
        setCheckSubmit(true);

    }

    const saveLogic = (saveform) => {
        var result = window.confirm('저장하시겠습니까?');

        if(result) {
            if(registPage) {
                Net.addVote(saveform, (response) => {
                    if(response.status === 200 || response.status === 201) {
                        history.push('/community/vote/list');
                    }
                });
            } else {
                Net.updateVote(saveform, (response) => {
                    if(response.status === 200) {
                        history.push('/community/vote/list');
                    }
                });
            }
        }
    }


    const onCancelHandler = () => {
        history.push('/community/vote/list');
    }

    const sendData = (e) => {
        const newArray = form.newAgenda;
        if(e.agendaIndex !== ""){
            newArray[e.agendaIndex] = e;
        } else {
            newArray.push(e);
        }

        setForm({
            ...form,
            newAgenda: newArray
        });
    }

    const getImgFile = (dataUrl) => {
        setForm({
            ...form,
            imageDataUrl: dataUrl,
        });
    }

    const liftValue = (value) => {
        setForm({
            ...form,
            contents: value,
        });
    }




    const dateChangeHandler = () => {
        if(voteStartDate && voteEndDate){
            const voteStartDateFormating = voteStartDate.getFullYear() + '-' + ('0' + (Number(voteStartDate.getMonth())+1)).slice(-2) + '-' + ('0' + voteStartDate.getDate()).slice(-2);
            const voteEateFormating = voteEndDate.getFullYear() + '-' + ('0' + (Number(voteEndDate.getMonth())+1)).slice(-2) + '-' + ('0' + voteEndDate.getDate()).slice(-2);
            const startDateFormating = startDate.getFullYear() + '-' + ('0' + (Number(startDate.getMonth())+1)).slice(-2) + '-' + ('0' + startDate.getDate()).slice(-2);
            setForm({
                ...form,
                startDate: startDateFormating,
                voteStartDate: voteStartDateFormating,
                voteEndDate: voteEateFormating
            });
        }
    }

    const onRemoveHandler = () => {
        const result = window.confirm('삭제하시겠습니까?');
        if(result) {
            Net.removeVote([{voteId: form.voteId}], (response) => {
                if(response) {
                    history.push('/community/vote/list');
                }
            });
        }
    }

    // Checkbox onChange Event
    const handleCheckbox = (id, checked) => {
        if(checked) {
            setCheckedItems({
                ...checkedItems,
                agenda: checkedItems.agenda.concat(id)
            });
        } else {
            setCheckedItems({
                ...checkedItems,
                agenda: checkedItems.agenda.filter(items => items !== id)
            });
        }
    }

    // Open Modal and setClickedBtnName
    const onClickModalOpen = (index) => {
        setModalState({
            ...modalState,
            open: !modalState.open,
            vData: {
                voteId: form.voteId,
                status: form.status,
                agenda: form.newAgenda[index]?form.newAgenda[index]:"",
                agendaIndex: index,
            }
        });
    }

    // remove checkedList
    const onClickRemoveList = () => {

        const newArray = form.newAgenda;
        const removeAgendaList = form.removeAgendaList;
        let deleteCnt = 0;
        checkedItems.agenda.map(id => {
            if(newArray[Number(id) - deleteCnt].agendaId){
                removeAgendaList.push(newArray[id - deleteCnt].agendaId);
            }
            newArray.splice(Number(id) - deleteCnt, 1);
            deleteCnt++;
        });

        setForm({
            ...form,
            newHoliday: newArray,
            removeAgendaList: removeAgendaList,
        });

        setCheckedItems({
            agenda: []
        });

    }

    //const disabledYn = registPage || form.status === "READY" ? false : true;

    return (
        <div className="w-100 h-100">
            <BasicModal
                className="col-md-4 row-md-8"
                title={`투표안건 등록/수정`}
                content={<ModalContent
                            sendData={sendData}
                            modalClose={(e) => setModalState({
                                                    ...modalState,
                                                    open: e
                                                })
                                        }
                            vData={modalState.vData}
                        />}

                modalOpen={modalState.open}
                modalClose={(e) => setModalState({
                    ...modalState,
                    open: e
                })}
            />
            <div className="w-100 h-1px bgC-efefef" />
            <div className="h-93">
                <div className="flex h-90">
                    <div className="w-50 h-100">
                        <div className="ml-55 mt-12">
                            {
                                cloneUser.menuGroup === 'ADMIN' ? (
                                    <div className="flex mb-12 w-100">
                                        <label className="label_title">고객사 *</label>
                                        <CustomSelectBox
                                            name="customer.code"
                                            categoryList={customerList}
                                            value={form.customer.code}
                                            onChangeHandler={(value, name) => selectBoxHandler('customer.code', {code: value, name: name})}
                                            callbackCodeName="Y"
                                            disabled={!registPage}
                                        />
                                        {
                                            registPage ? <div/> :
                                                <div className="label_summary ml-10">※ 수정시 고객사 및 현장은 변경 불가능 합니다. </div>
                                        }
                                    </div>
                                ) : <></>
                            }
                            {
                                cloneUser.menuGroup === 'OPERATOR' ? <></> :(
                                    <div className="flex mb-12 w-100">
                                        <label className="label_title">현장 *</label>
                                                <CustomSelectBox
                                                    name="site.code"
                                                    categoryList={siteList}
                                                    value={form.site.code}
                                                    onChangeHandler={(value, name) => selectBoxHandler('site.code', {code: value, name: name})}
                                                    callbackCodeName="Y"
                                                    disabled={!registPage}
                                                />
                                    </div>
                                )
                            }
                            <div className="flex mb-12 w-100">
                                <label className="label_title">투표종류 *</label>
                                <CustomSelectBox
                                    name="classification"
                                    categoryList={classificationList}
                                    value={form.classification}
                                    onChangeHandler={(value) => selectBoxHandler('classification', value)}
                                />
                            </div>

                            <div className="flex mb-12 w-100">
                                <CustomTextField
                                    className="w-65 h-26px"
                                    title="장소(동)"
                                    name="dong"
                                    required={false}
                                    placeholder=""
                                    customOnChange={textFieldHandler}
                                    value={form}
                                />
                            </div>

                            <div className="flex mb-12 w-100">
                                <CustomTextField
                                    className="w-65 h-26px"
                                    title="제목"
                                    name="title"
                                    required={true}
                                    placeholder=""
                                    customOnChange={textFieldHandler}
                                    value={form}
                                />
                            </div>
                            <div className="flex mb-10 w-100">
                                <label className="label_title">내용</label>
                                <div className="w-65">
                                    <QuillEditor
                                        onChange={liftValue}
                                        value={editorState}
                                        setEditorState={setEditorState}
                                    />
                                </div>
                            </div>

                            <div className="flex mb-12 w-90 mt-10">
                                <div className="flex w-40 h-26px">
                                    <label className="label_title">시작일자 *</label>
                                    <DatePicker
                                        dateFormat="yyyy-MM-dd"
                                        selected={voteStartDate}
                                        //maxDate={voteEndDate}
                                        onChange={date => {
                                            setVoteStartDate(date);
                                        }}
                                        name="voteStartDate"
                                        className="w-100 textField"
                                    />
                                </div>
                                <div className="flex w-45 h-26px">
                                    <label className="label_title">종료일자 *</label>
                                    <DatePicker
                                        dateFormat="yyyy-MM-dd"
                                        selected={voteEndDate}
                                        minDate={voteStartDate}
                                        onChange={date => {
                                            setVoteEndDate(date);
                                        }}
                                        name="voteEndDate"
                                        className="w-98 textField"
                                    />
                                </div>
                            </div>
                            <div className="flex mb-12 w-90">
                                <div className="flex w-40 h-26px">
                                    <label className="label_title">공개일자 *</label>
                                    <DatePicker
                                        dateFormat="yyyy-MM-dd"
                                        selected={startDate}
                                        maxDate={voteStartDate}
                                        onChange={date => {
                                            setStartDate(date);
                                        }}
                                        name="startDate"
                                        className="w-100 textField"
                                    />
                                </div>
                            </div>

                            <div className="flex w-90">
                                <label className="label_title">사용유무</label>
                                <CustomRadioButton
                                    className="mr-60"
                                    title="사용"
                                    id="radioBtn1"
                                    name="publicYn"
                                    value={true}
                                    checked={form.publicYn}
                                    customOnChange={radioBtnHandler}
                                />
                                <CustomRadioButton
                                    title="사용안함"
                                    id="radioBtn2"
                                    name="publicYn"
                                    value={false}
                                    checked={!form.publicYn}
                                    customOnChange={radioBtnHandler}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="w-40 h-100">
                        <div className="w-100 h-40 mt-12" style={{display:"block"}} >
                            <div className="floatC mr-2p" >
                                <ImgUpload
                                    title={"사진"}
                                    imgSize={"180"}
                                    imgUpload={getImgFile}
                                    imgFile={form.imageDataUrl}
                                />
                            </div>

                        </div>
                        <div className="h-5"/>
                        <div className="w-100 h-45 ">
                            <div className="mt-40">
                                <p className="UploadTitle" style={{marginBottom: "0"}}>투표안건 목록</p>
                            </div>
                            <div className="h-85 overFlowYscrollView">
                                <div className="floatR mr-2p">
                                    <button className="addBtn w-25px h-25px" onClick={() => onClickModalOpen("")}>+</button>
                                    <button className="removeBtn w-25px h-25px" onClick={() => onClickRemoveList()}>-</button>
                                </div>
                                <table className="mainTable">
                                    <colgroup>
                                        <col width="10%"/>
                                        <col width="40%"/>
                                        <col width="50%"/>
                                    </colgroup>
                                    <thead className="tableHead">
                                        <tr>
                                            <th/>
                                            <th className="v-middle"><span className="h-24px">제목</span></th>
                                            <th className="v-middle"><span className="h-24px">내용</span></th>
                                        </tr>
                                    </thead>
                                    <tbody className="tableBody">
                                        {
                                            form.newAgenda.length > 0 ?
                                            (
                                                form.newAgenda.map((data, index) => {
                                                    return(
                                                        <tr key={index} onClick={() => onClickModalOpen(index)}>
                                                            <td onClick={ e => e.stopPropagation() }>
                                                                <CustomCheckbox
                                                                    id={`${index}`}
                                                                    name="agendaChk"
                                                                    className="j-c-c h-16px"
                                                                    title=""
                                                                    checked={checkedItems.agenda.includes(String(index))}
                                                                    onChange={handleCheckbox}
                                                                />
                                                            </td>
                                                            <td>{data.title}</td>
                                                            <td><div dangerouslySetInnerHTML={{__html: data.contents}}/></td>
                                                        </tr>
                                                    )
                                                })
                                            )
                                            :
                                            (<tr><td colSpan="3">조회 된 데이터가 없습니다.</td></tr>)
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer_btn h-10">
                    <div className="">
                        <button className="w-160px h-30px addBtn mr-10" onClick={onSubmitHandler}>저장</button>
                        { registPage ? "" : <button className="w-160px h-30px removeBtn mr-10" onClick={onRemoveHandler}>삭제</button>}
                        <button className="w-160px h-30px cancelBtn" onClick={onCancelHandler}>취소</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default withRouter(VoteRegist);

export const ModalContent = (props) => {

    const { vData, sendData, modalClose } = props;
    const [registPage, setRegistPage] = useState(true);
    const [form, setForm] = useState({
        voteId: vData.voteId,
        status: vData.status ? vData.status : "",
        agendaId: vData.agenda.agendaId ? vData.agenda.agendaId : "",
        title: vData.agenda.title?vData.agenda.title:"",
        contents: props.vData.agenda.contents?props.vData.agenda.contents:"",
        voteNum: vData.agenda.voteNum?vData.agenda.voteNum:"",
        image: vData.agenda.image?vData.agenda.image:"",
        imageDataUrl: vData.agenda.imageDataUrl?vData.agenda.imageDataUrl:"",
        agendaIndex: vData.agendaIndex !== "undefined"?vData.agendaIndex:"",
    });
    const [modalEditorState, setModalEditorState] = useState(EditorState.createEmpty());

    useEffect(() => {
        if( form.status ){
            // 등록페이지 여부 확인
            setRegistPage(false);
        }
        loadData();
    }, []);

    useEffect(()=>{
        if(!registPage){
            if(vData.agenda.contents){
                setModalEditorState(Common.htmlToDraftBlocks(vData.agenda.contents));
            }
        }
    },[registPage])

    const loadData = () => {
        if(form.image && !form.imageDataUrl){
            Net.getVoteImg(form.image, (response) => {
                if(response.status === 200) {
                    setForm({
                        ...form,
                        imageDataUrl: response.data,
                    })
                }
            });
        }
    }

    const onSubmitHandler = () => {
        sendData(form);
        handleClose();
    }
    const handleClose = () => {
        modalClose(false);
    };

    const liftModalValue = (value) => {
        setForm({
            ...form,
            contents: value,
        });
    }

    const textFieldHandler = (name, value) => {
        const nextForm = {
            ...form,
            [name]: value
        }
        setForm(nextForm);
    }

    const getAgendaImgFile = (dataUrl) => {
        setForm({
            ...form,
            imageDataUrl: dataUrl,
        });
    }

    return (
        <div className="h-100 w-90">
            <div className="h-100 w-100" style={{display:"table"}}>
                <div className="mb-12 w-98">
                    <CustomTextField
                        className="w-100 h-26px"
                        title="제목"
                        name="title"
                        required={true}
                        disabled={false}
                        placeholder=""
                        customOnChange={textFieldHandler}
                        value={form}
                    />
                </div>

                <div className="mb-12 w-98">
                    <label className="label_title">내용 *</label>
                    <QuillEditor
                        onChange={liftModalValue}
                        value={modalEditorState}
                        setEditorState={setModalEditorState}
                        height={"160px"}
                    />
                </div>
                <div className="flex mb-12 w-100">
                    <div className="w-100 h-50 mt-12" style={{display:"block"}} >
                        <div className="floatC mr-2p" >
                            <ImgUpload
                                title={"사진"}
                                imgUpload={getAgendaImgFile}
                                imgSize={"200px"}
                                imgFile={form.imageDataUrl}
                                inputName={`${form.agendaId}_agendaImg`}
                                disabled ={false}
                            />
                        </div>
                    </div>
                </div>
                <div className="footer_btn h-10 m-20 ml-20">
                        <button className="w-80px h-30px addBtn mr-10" onClick={onSubmitHandler}>저장</button>
                        <button className="w-80px h-30px cancelBtn" onClick={handleClose}>취소</button>
                </div>
            </div>
        </div>
    )
}
