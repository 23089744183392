import React, { useState, useEffect } from 'react'
import classNames from 'classnames';
import DaumPostcode from 'react-daum-postcode';

export const SearchAddress = (props) => {

    const [isDaumPost, setIsDaumPost] = useState(props.isDaumPost);
    useEffect(() => {
        setIsDaumPost(props.isDaumPost);
    }, [props.isDaumPost]);

    const handleComplete = (data) => {
        let fullAddress = data.address;
        let extraAddress = ''; 
        
        if (data.addressType === 'R') {
          if (data.bname !== '') {
            extraAddress += data.bname;
          }
          if (data.buildingName !== '') {
            extraAddress += (extraAddress !== '' ? `, ${data.buildingName}` : data.buildingName);
          }
          fullAddress += (extraAddress !== '' ? ` (${extraAddress})` : '');
        }
     
        // console.log(fullAddress);  // e.g. '서울 성동구 왕십리로2길 20 (성수동1가)'
        props.rtnData(data);
        onCloseHandler();
    }

    const onCloseHandler = () => {
        props.closePopUp(false);
    }

    const closeBtnStyle = {
        cursor: "pointer",
        position: "absolute",
        right:"0",
        top: "0",
        zIndex: "1"
    }

    return (
        <div 
            className={classNames('modal_wrapper', props.className)} 
            style={{
                zIndex: '1',
                display: isDaumPost ? 'black' : 'none'
            }}
        >
            <img 
                src="//t1.daumcdn.net/postcode/resource/images/close.png" 
                id="btnCloseLayer" 
                style={closeBtnStyle}
                onClick={onCloseHandler}
                alt="닫기 버튼"
            />
            <DaumPostcode 
                onComplete={handleComplete}
            />
        </div>
    )
}
