import React, {useEffect, useState} from 'react'
import store from 'scripts/store';
import Net from 'scripts/net';
import Common from 'scripts/common';
import classnames from 'classnames/bind';
import {Card, CardBody, CardText, CardTitle, Cardleft, CardSubTitle} from "../../../components/molecules/Card";

const cx = classnames.bind();

const dateRegex = /(.[0-9]+)?(Z)?$/g;
const startDate = new Date();
const timezoneOffset = startDate.getTimezoneOffset() * 60000; // for toISOString
const today = new Date(startDate - timezoneOffset);

export const Admin = () => {
    const [customers, setCustomers] = useState(0);
    const [sites, setSites] = useState(0);
    const [users, setUsers] = useState(0);
    const [homenetUsers, setHomenetUsers] = useState(0);
    const [homenetHouseholds, setHomenetHouseholds] = useState(0);
    const [households, setHouseholds] = useState({});
    const [telecomCount, setTelecomCount] = useState({});
    const [telecomHouseHoldCount, setTelecomHouseHoldCount] = useState({});
    const [applianceCount, setApplianceCount] = useState({});
    const [applianceDevicesCount, setApplianceDevicesCount] = useState({});
    const [deviceCount, setDeviceCount] = useState();
    const [homenetDeviceCount, setHomenetDeviceCount] = useState(0);
    const day = today.toISOString().replace("T", " ").replace(dateRegex, "");

    useEffect(() => {
        init().then(() => {
            console.log("init complete");
        });

    }, [])

    const init = async () => {
        store.openedPage = '';
        await Net.getCustomerList({currentPage: 0, rowPerPage: 1000000000, searchText: ''}, response => {
            setCustomers(response.data && response.data.totalElements);
        });
        await Net.getSitesList({currentPage: 0, rowPerPage: 1000000000, searchText: ''}, response => {
            setSites(response.data && response.data.totalElements);
        });
        await Net.getUserList({currentPage: 0, rowPerPage: 1000000000}, response => {
            setUsers(response.data && response.data.totalElements);
        });
        await Net.getHomeNetUserCounts(undefined, response => {
            setHomenetUsers(response);
        })
        await Net.getHouseholds({site: undefined, dong: undefined}, response => {
            setHouseholds(response);
        });
        await Net.getTelecomCountsAsync().then(response => {
            setTelecomCount(response.data);
        })
        await Net.getAppliancesCountsAsync().then(response => {
            setApplianceCount(response.data);
        })
        await Net.getAppliancesDevicesCountsAsync().then(response => {
            setApplianceDevicesCount(response.data);
        })
        await Net.getToolsDeviceList({ currentPage: 0, rowPerPage: 1},response => {
            setDeviceCount(response.data.totalElements);
        })

        const userBySite = await Net.getUserBySiteCountAsync();
        let tempHomenetHouseHold = 0;
        await userBySite.data.forEach(data => {
            tempHomenetHouseHold += data.moveInHousehold;
        })
        setHomenetHouseholds(tempHomenetHouseHold);

        const tempEtcCount = await Net.getHouseHoldVoiceServiceCountAsync();
        let tempTelecomHouseCount = {
            lgHouseHold : 0,
            sktHouseHold : 0,
            ktHouseHold : 0,
            totalHouseHold : 0,
        }
        tempEtcCount.data.forEach(data =>{
            tempTelecomHouseCount.lgHouseHold += data.lgUPlusHouseHoldCount;
            tempTelecomHouseCount.sktHouseHold += data.sktHouseHoldCount;
            tempTelecomHouseCount.ktHouseHold += data.ktHouseHoldCount;
            tempTelecomHouseCount.totalHouseHold += data.ktHouseHoldCount + data.sktHouseHoldCount + data.lgUPlusHouseHoldCount;
        });
        setTelecomHouseHoldCount(tempTelecomHouseCount);
    }

    return (
        <div className={cx('container-fluid', 'pt-1')}>
            <h1 className={cx('d-inline-block', 'mt-3', 'ml-2')}>메인 화면</h1><span className={'ml-20'}>갱신 시각 : {day}</span>
            <h2 className={cx('mt-3', 'ml-2')}>사용자 현황</h2>
            <div className={cx('row', 'mt-4')}>
                <div className={cx('col')}>
                    <Card>
                        <CardBody>
                            <CardTitle>총 고객사</CardTitle>
                            <CardText className={cx('text-end')}>{customers}</CardText>
                        </CardBody>
                    </Card>
                </div>
                <div className={cx('col')}>
                    <Card>
                        <CardBody>
                            <CardTitle>총 현장수</CardTitle>
                            <CardText className={cx('text-end')}>{sites}</CardText>
                        </CardBody>
                    </Card>
                </div>
                <div className={cx('col')}>
                    <Card>
                        <CardBody>
                            <CardTitle>총 세대수</CardTitle>
                            <CardText className={cx('text-end')}>{Common.switchStringtoNumber(Common.switchStringtoNumber(households.numberOfHouseHold))}</CardText>
                        </CardBody>
                    </Card>
                </div>
                <div className={cx('col')}>
                    <Card>
                        <CardBody>
                            <CardTitle>총 연동 세대수</CardTitle>
                            <CardText className={cx('text-end')}>{Common.switchStringtoNumber(homenetHouseholds)}</CardText>
                        </CardBody>
                    </Card>
                </div>
                <div className={cx('col')}>
                    <Card>
                        <CardBody>
                            <CardTitle>총 회원 가입자</CardTitle>
                            <CardText className={cx('text-end')}>{Common.switchStringtoNumber(users)}</CardText>
                        </CardBody>
                    </Card>
                </div>
                <div className={cx('col')}>
                    <Card>
                        <CardBody>
                            <CardTitle>총 홈넷 연동자</CardTitle>
                            <CardText className={cx('text-end')}>{Common.switchStringtoNumber(homenetUsers)}</CardText>
                        </CardBody>
                    </Card>
                </div>
            </div>

            <h2 className={cx('mt-3', 'ml-2')}>음성 인식 연동 사용자 현황</h2>
            <div className={cx('row', 'mt-4')}>
            <div className={cx('col-3')}>
                    <Card className={cx('')}>
                        <CardBody>
                            <CardSubTitle className="col-11"
                                title={"전체 음성인식 연동 현황"}
                                account={Common.switchStringtoNumber(telecomCount.totalCount)}
                                houseHold={Common.switchStringtoNumber(telecomHouseHoldCount.totalHouseHold)}  />
                        </CardBody>
                    </Card>
                </div>
                <div className={cx('col-3')}>
                    <Card className={cx('')}>
                        <CardBody>
                            <CardSubTitle className="col-11"
                                title={"SKT 누구"}
                                account={Common.switchStringtoNumber(telecomCount.sktCount)}
                                houseHold={Common.switchStringtoNumber(telecomHouseHoldCount.sktHouseHold)}  />
                        </CardBody>
                    </Card>
                </div>
                <div className={cx('col-3')}>
                    <Card className={cx('')}>
                        <CardBody>
                            <CardSubTitle className="col-11"
                                title={"KT 기가지니"}
                                account={Common.switchStringtoNumber(telecomCount.ktCount)}
                                houseHold={Common.switchStringtoNumber(telecomHouseHoldCount.ktHouseHold)}  />
                        </CardBody>
                    </Card>
                </div>
                <div className={cx('col-3')}>
                    <Card className={cx('')}>
                        <CardBody>
                            <CardSubTitle className="col-11"
                                title={"LG U+"}
                                account={Common.switchStringtoNumber(telecomCount.lgUPlusCount)}
                                houseHold={Common.switchStringtoNumber(telecomHouseHoldCount.lgHouseHold)}  />
                        </CardBody>
                    </Card>
                </div>
            </div>
            <h2 className={cx('mt-3', 'ml-2')}>가전사 연동 사용자 현황</h2>
            <div className={cx('row', 'mt-4')}>
                <div className={cx('col-3')}>
                    <Card>
                        <CardBody>
                            <CardTitle>가전사 연동 사용자</CardTitle>
                            <CardText className={cx('text-end')}>{Common.switchStringtoNumber(applianceCount.totalCount)}</CardText>
                        </CardBody>
                    </Card>
                </div>
                <div className={cx('col-3')}>
                    <Card>
                        <CardBody>
                            <CardTitle>LG가전 연동 사용자</CardTitle>
                            <CardText className={cx('text-end')}>{Common.switchStringtoNumber(applianceCount.lgCount)}</CardText>
                        </CardBody>
                    </Card>
                </div>
                <div className={cx('col-3')}>
                    <Card>
                        <CardBody>
                            <CardTitle>삼성전자 연동 사용자</CardTitle>
                            <CardText className={cx('text-end')}>{Common.switchStringtoNumber(applianceCount.samsungCount)}</CardText>
                        </CardBody>
                    </Card>
                </div>
            </div>
            <h2 className={cx('mt-3', 'ml-2')}>디바이스 현황</h2>
            <div className={cx('row', 'mt-4')}>
                <div className={cx('col-3')}>
                    <Card>
                        <CardBody>
                            <CardTitle>총 디바이스 수</CardTitle>
                            <CardText className={cx('text-end')}>{Common.switchStringtoNumber(deviceCount)}</CardText>
                        </CardBody>
                    </Card>
                </div>
                <div className={cx('col-3')}>
                    <Card>
                        <CardBody>
                            <CardTitle>총 가전 연동 장비 수</CardTitle>
                            <CardText className={cx('text-end')}>{Common.switchStringtoNumber(applianceDevicesCount.totalCount)}</CardText>
                        </CardBody>
                    </Card>
                </div>
            </div>
        </div>
    )
}
