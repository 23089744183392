import React, {Fragment, useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom'
import {CustomTable, SearchInputBar} from 'components/molecules';
import store from 'scripts/store';
import Net from 'scripts/net';

const columnList = [
    {
        code: 'customer.name',
        title: '고객사',
        width: '8',
    },
    {
        code: 'site.name',
        title: '현장',
        width: '9',
    },
    {
        code: 'duplicatedCount',
        title: '중복 장소 수',
        width: '6',
    },
    {
        code: 'status',
        title: '상태',
        width: '6',
    },
];

const detailColumnList =[
    {
        code: 'username',
        title: '사용자ID',
        width: '5',
    },
    {
        code: 'dong',
        title: '동',
        width: '5',
    },
    {
        code: 'ho',
        title: '호',
        width: '5',
    },
    {
        code: 'code',
        title: 'Place-ID',
        width: '8',
    },
    {
        code: 'placeName',
        title: 'Place 명',
        width: '8',
    },
];


export const DuplicatePlaceMonitoring = (props) => {

    // useHistory 선언
    const history = useHistory();
    const user = props.userInfo;
    const [programAuth, setProgramAuth] = useState({});
    const [contentData, setContentData] = useState([]);
    const [detailContentData, setDetailContentData] = useState([]);
    const [searchCategory, setSearchCategory] = useState([
        [
            [
                {code: '', name: '고객사 선택'}
            ]
        ]
    ]);
    const [pageState, setPageState] = useState({
        totalElementsCnt: 0,
        drawElementsCnt: 0,
        searchCategory1: user.menuGroup === 'ADMIN' ? '' : user.customer && user.customer.code,
    });

    useEffect(() => {
        store.openedPage = '중복 장소 현황';
        pageAuthInfo();
        getCategoryList('');

    }, [])

    useEffect(()=>{
        loadData();
    },[pageState.searchCategory1])

    const pageAuthInfo = () => {
        const authGroupCode = props.userInfo.authList[0];
        const menuId = sessionStorage.getItem('menuId');

        Net.getAuthGroupMenuList(menuId, (response) => {
            if (response.status === 200) {
                response.data.forEach(item => {
                    if (item.authGroup === authGroupCode && item.authGroupMenu) {
                        setProgramAuth({
                            searchAuth: Boolean(item.authGroupMenu.searchAuth),
                            saveAuth: Boolean(item.authGroupMenu.saveAuth),
                            excelAuth: Boolean(item.authGroupMenu.excelAuth),
                            deleteAuth: Boolean(item.authGroupMenu.deleteAuth),
                        });
                    }
                });
            }
        });
    }

    const loadData = async () => {
        const response = await Net.getDuplicatedPlaceCountsBySiteAsync(pageState.searchCategory1);
        const tempList = response.data.map((item)=>{
            const status = item.duplicatedCount > 0 ? "비정상" : "정상";
            return {
                site : {
                    code : item.site.code,
                    name : item.site.name,
                },
                customer : {
                    code : item.site.customer.code,
                    name : item.site.customer.name,
                },
                duplicatedCount : item.duplicatedCount,
                status : status
            }
        })
        setContentData(tempList);
    }

    const getCategoryList = () => {
        getCustomerList((response1) => {
            setSearchCategory([response1]);
        });
    }

    const getCustomerList = (callback) => {
        Net.getCustomerList(
            {currentPage: '', rowPerPage: '' ,searchText: ''}, (response) => {
                if (response.status === 200) {
                    let newArray = searchCategory[0][0];
                    response.data.content.forEach(items => {
                        newArray = [
                            ...newArray,
                            {
                                code: items.code,
                                name: items.name
                            }
                        ]
                    });
                    callback(newArray);
                }
            });
    }

    const getRelateCategory = (selectedCategory) => {
        if (selectedCategory === "reset") selectedCategory = "";
        setSearchCategory([
            searchCategory[0], // 고객사
        ]);
    }

    const onSubmitHandler = (e) => {
        setPageState({
            ...pageState,
            searchCategory1: e.selectedCategory[0],
        });
    }

    const onClickRow = async (e) => {
        // 리스트
        const response = await Net.getDuplicatedPlaceDetailBySiteAsync(e.site.code);
        setDetailContentData(response.data);
    }


    return (
        <div className="w-100 h-100">
            <div className="h-93">
                <div className="w-97 h-80 d-block">
                        <SearchInputBar
                            className="col-md-8 mt-2 ml-12 mb-3"
                            searchCategory={true}
                            searchCategoryGroup={searchCategory}
                            searchBtn={true}
                            searchBtnTitle="검색"
                            dynamicSearch={false}
                            relateCategoryHandler={user.menuGroup === 'ADMIN' && getRelateCategory}
                            onSubmit={onSubmitHandler}

                        />
                        <h5 className="floatL mb-1p ml-2p mt-2">현장별 중복 장소 현황</h5>
                        <div className="overFlowYscrollView w-99 h-80">
                            <CustomTable
                                className="hoverbtn h-99 w-100 overFlowYscrollViewHidden"
                                columnList={columnList}
                                contentData={contentData}
                                onClickRow={onClickRow}
                                rowClickEvt={true}
                            />
                        </div>
                </div>
                <div className = "row mt-4">
                    <h5 className="floatL mt-1p mb-1p ml-2p ">상세 현황</h5>
                </div>

                <div className="w-95 h-20 flex ml-15">
                    <div className="w-100 h-100">
                        <CustomTable
                            className="h-93 overFlowYscrollViewHidden"
                            columnList={detailColumnList}
                            contentData={detailContentData}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}


