import React from 'react';
import classnames from 'classnames/bind';


const cx = classnames.bind();

export default function CardSubTitle(props) {

    const {className} = props;

    return (
        <div className={cx("mt-2 ml-2", className)}>
            <h5 className="text-muted card-title cardTitle">{props.title}</h5>
            <h5 className="ml-1 text-end">세대 : {props.houseHold}</h5>
            <h5 className="ml-1 text-end">유저 : {props.account}</h5>
        </div>
    );
}
