import React, {Fragment, useEffect, useState} from 'react';
import {CustomTable, SearchInputBar} from 'components/molecules';
import store from 'scripts/store';
import Net from 'scripts/net';
import Common from 'scripts/common';
import {usePageAuth , useDidMountEffect} from "scripts/hooks";
import {HomenetAuthDetailList} from "../../HomenetAuthMonitoring/HomenetAuthDetailList";


export const HomenetDisconnect = (props) => {

    const [detailContentData, setDetailContentData] = useState([]);

    useEffect(() => {
        async function getLoad(){
            const response = await Net.getHomenetAuthorizationStatusListAsync(props.data.siteCode,props.data.dong, props.data.ho);
            setDetailContentData(response.data);
        }
        getLoad();
    }, [props.data]);

    const reload = async (e) => {
        if(e === 'reload'){
            setTimeout(async ()=>{
                const response = await Net.getHomenetAuthorizationStatusListAsync(
                    props.data.siteCode,props.data.dong, props.data.ho);
                await setDetailContentData(response.data);
                await props.reload('reload');
                console.log("Disconnect reload");
                props.modalClose(false);
            },[500])
        }
    }

    return (
        <div className="w-100 h-100">
            <div className="w-100 h-1px bgC-efefef"/>
            <div className="h-93 row">
                <div className="w-60 h-93 mt-3 overFlowYscrollView">
                    <h5 className="mt-1p mb-1p ml-2p ">세대별 상세 현황</h5>
                    <HomenetAuthDetailList
                        detailContentData={detailContentData}
                        reload = {reload}
                        modal = {true}
                        modalClose = {props.modalClose}
                        deleteAuth = {props.deleteAuth}
                    />
                </div>
            </div>
        </div>
    );
}


