import React from 'react';
import ReactDOM from 'react-dom';
import Root from './client/Root';
import registerServiceWorker from './registerServiceWorker';
import './index.css';
import "./reset.css";
import 'react-toastify/dist/ReactToastify.css';
import 'react-app-polyfill/stable';
import 'react-app-polyfill/ie11';
import 'core-js/stable';
import 'regenerator-runtime/runtime';

import Amplify from 'aws-amplify'
import config from './aws-exports'
Amplify.configure(config)

ReactDOM.render(
    <Root/>, document.getElementById('root'));
registerServiceWorker();
