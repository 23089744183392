import React, {Component} from 'react'
import {Route, Switch} from 'react-router-dom';
import {ChangePassword, CognitoLogin, HomePage, LoginCheck, } from 'pages';
import './App.scss'
import NotFound from "../components/templates/NotFound";
import { VisitorRegist, VisitorComplete } from 'pages/ServicePage/Visitor';
import { GuideMain, GuideSite } from 'pages/ServicePage/Guide';
import { Helmet } from 'react-helmet-async';
import {GuideHomeNet} from "../pages/ServicePage/Guide";


export default class App extends Component {


    render() {
        return (
            <div>
                <Helmet>
                    <title> 코오롱베니트 | 스마트홈 관리시스템 </title>
                    <meta charSet="utf-8" />
                    <meta name="description" content="코오롱베니트 홈IoT 서비스 관리 시스템" />
                    <meta property="og:image" content="%PUBLIC_URL%/logo192.png" data-react-helmet="true" />
                    <meta property="og:title" content="관리 시스템 | 코오롱베니트 홈IoT 서비스" data-react-helmet="true"/>
                </Helmet>
                <Switch>
                    <Route path={"/login"} exact component={CognitoLogin}/>
                    <Route path={"/login/check"} exact component={LoginCheck}/>
                    <Route path={"/changepwd"} exact component={ChangePassword}/>
                    <Route path={"/not-found"} exact component={NotFound}/>
                    <Route path={'/service/visitor'}>
                        <Helmet>
                            <title>방문 차량 등록 | 스마트하늘채 IoK </title>
                            <meta name="description" content="[코오롱하늘채]방문 차량 등록 HomePage" data-react-helmet="true"/>
                            <meta property="og:image" content="%PUBLIC_URL%/logo192.png" data-react-helmet="true"/>
                            <meta property="og:title" content="방문 차량 등록 | IoK 스마트하늘채" data-react-helmet="true"/>
                            <meta property="og:description" content="직접 방문 차량 등록을 등록해보세요. 집주인 초대별 1대의 차량만 등록 가능한 점 유의바랍니다." data-react-helmet="true"/>
                            <meta
                                name="viewport"
                                content="user-scalable=yes, initial-scale=1.0, maximum-scale=2.0, minimum-scale=0.5, width=device-width"/>
                        </Helmet>
                        <Switch>
                            <Route path={"/service/visitor/regist"} exact children={<VisitorRegist/>} />
                            <Route path={"/service/visitor/regist/:visitorId"} children={<VisitorRegist/>}/>
                            <Route path={"/service/visitor/complete/:visitorId"} children={<VisitorComplete/>}/>
                            <Route path={"*"} exact component={NotFound} />
                        </Switch>
                    </Route>
                    <Route path={'/service/guide'}>
                        <Helmet>
                            <title>홈 네트워크 인증 안내 | 스마트하늘채 IoK </title>
                            <meta name="description" content="[코오롱하늘채] 홈 네트워크 인증 가이드 HomePage" data-react-helmet="true"/>
                            <meta property="og:image" content="%PUBLIC_URL%/logo192.png" data-react-helmet="true"/>
                            <meta property="og:title" content="홈 네트워크 인증 안내 | IoK 스마트하늘채" data-react-helmet="true"/>
                            <meta property="og:description" content="시작하기 어려웠던 스마트하늘채, 온라인 가이드와 함께 해보세요." data-react-helmet="true"/>
                            <meta
                                name="viewport"
                                content="user-scalable=yes, initial-scale=1.0, maximum-scale=2.0, minimum-scale=0.5, width=device-width"/>
                        </Helmet>
                        <Switch>
                            <Route path={"/service/guide/main"} exact children={<GuideMain/>} />
                            <Route path={"/service/guide/site"} children={<GuideSite/>}/>
                            <Route path={"/service/guide/homenet"} children={<GuideHomeNet/>} />
                            <Route path={"*"} exact component={NotFound} />

                        </Switch>
                    </Route>
                    <Route path={"*"} exact component={HomePage} />
                </Switch>
            </div>
        )
    }
}
