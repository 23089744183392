import React, { useEffect, useState} from 'react';
import { useLocation } from 'react-router';
import logo from 'pages/ServicePage/Visitor/image/logoHanulchaeWhite@3x.png';
import Lottie from "react-lottie";
import animationData from "./Lottie/LottieJs/lf_house";
import alertAnimationData from "./Lottie/LottieJs/lf_alert";
import { AlertModal } from 'components/molecules';
import './GuideHomeNet.scss';
import {useHistory} from "react-router-dom";
import { Document, Page, pdfjs } from 'react-pdf/dist/esm/entry.webpack';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
export const GuideHomeNet = ( {} ) => {
    const history = useHistory();
    const location = useLocation();
    if(location.state === undefined){
        alert('새로고침 후 현장을 선택해주세요.');
        history.push('/service/guide/site');
    }

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    };

    const [modalState, setModalState] = useState({
        open: true
    });


    useEffect(()=>{
        /*

        * */
        console.log(location)
        if(location.state.site === undefined){
            alert(' 현장을 다시 선택해주세요.');
            history.push('/service/guide/site');
        }
    },[]);

    const nextPage = ()=>{
        setModalState({
            open: !modalState.open,
        })
    }

    const [numPages, setNumPages] = useState(1);
    const [pageNumber, setPageNumber] = useState(1);

    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
    }

    return (
        <div className="page">
            <div className="text-center guideMain">
                <AlertModal
                    className="w-100"
                    content={<ModalContent
                        modalClose={(e) => setModalState({
                            ...modalState,
                            open: e
                        })
                        }
                    />}
                    modalOpen={modalState.open}
                    modalClose={(e) => setModalState({
                        ...modalState,
                        open: e
                    })}
                />
                <div className="cardTop"
                    style={{marginLeft : "0rem", marginRight : "0rem", paddingBottom : "3.5rem"}}
                >
                    <div className="d-table w-100"
                        style={{paddingLeft : "1rem"}}
                    >
                        <div
                            style={{color : "wheat", paddingRight:"1.15rem", wordBreak:"keep-all"}}
                        >
                            사용 안내서를 따라 단지등록
                        </div>

                        {
                            location.state.site.attribute2 === 'Cvnet' ?
                                <>
                                    <div className="text-header">
                                        <a href={"https://cv-cloud1.uasis.com/views/b2b/signup/iok"} target='_blank'
                                           aria-label={`홈 네트워크 회원가입`}>
                                            <button className="blue-button-h floatR"
                                                    style={{width:"7rem"}}
                                            >홈 네트워크 회원가입</button>
                                        </a>
                                    </div>
                                </>
                                : location.state.site.attribute2 === 'HT' ?
                                    <div className="text-header">
                                        <a href={"https://www2.hthomeservice.com/"} target='_blank'
                                           aria-label={`홈 네트워크 회원가입`}>
                                            <button className="blue-button-h floatR"
                                                style={{width:"7rem"}}
                                            >홈 네트워크 회원가입</button>
                                        </a>
                                    </div>
                                    : <></>

                        }

                        <div className="text-header">
                            <a href={"http://m.site.naver.com/10ZKb"} target='_blank' aria-label={`앱 다운로드`}>
                                <button className="blue-button-h floatR"
                                        style={{width:"3rem"}}
                                >앱 실행</button>
                            </a>
                        </div>
                    </div>

                    <div className="main-area mt-3">
                        <div className="w-100">
                            <Document file={location.state.site.attribute1} onLoadSuccess={onDocumentLoadSuccess}>
                                <Page className="w-100" pageNumber={pageNumber}>

                                </Page>
                            </Document>
                            <div className="flex justify-content-center align-items-center text-center mt-1">
                                {
                                    pageNumber === 1 ?
                                        <> <p className="white-header"
                                        > {'-'}  </p> </> :
                                        <>
                                            <p className="white-header"
                                               onClick={()=>setPageNumber(pageNumber-1)}
                                            > {'<'}  </p>
                                        </>
                                }
                                <p className="white_page ms-4 me-4">
                                     {pageNumber} / {numPages}
                                </p>

                                {
                                    pageNumber === numPages ?
                                        <><p className="white-header"
                                        > {'-'}  </p></>
                                        :
                                        <>
                                            <p className="white-header"
                                               onClick={()=>setPageNumber(pageNumber+1)}
                                            >  > </p>
                                        </>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export const ModalContent = (props) => {
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: alertAnimationData,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    };

    const location = useLocation();
    console.log(location);

    useEffect(() => {
        async function mounted(){

        }
        mounted().then(() => console.log("mounted"));
    }, [])


    const handleClose = () => {
        props.modalClose(false);
    };

    return (
        <div className="h-100">
            <div className="modal_main mt-5 me-3 ms-3">
                <div className="mt-2">
                    <p className="modal-grey-text mt-2">{location.state.site.title}의</p>
                    <p className="modal-grey-text mt-1">홈 네트워크 회사는 {location.state.site.attribute2} 입니다.</p>
                </div>

                {
                    location.state.site.attribute2 === 'Kocom' ?
                        <>
                            <p className="modal-black-header text-color-gray mt-3"> ※ 코콤 아이디는 관리사무소에서 지급 됩니다.</p>
                            <p className="modal-black-header mt-1"> 해당 아이디로 단지등록을 진행해주세요.</p>

                        </>
                        : <>
                            <p className="modal-black-header mt-3"> 안내에 따라 홈네트워크 회원가입을</p>
                            <p className="modal-black-header mt-1"> 진행하시고, 단지등록을 마무리 해보세요.</p>

                        </>
                }


                {
                    location.state.site.attribute2 === 'HT' ?
                        <>
                            <p className="modal-black-header text-color-gray mt-3"
                                style={{color:"red"}}
                            > ※ 관리사무소 승인 과정이 필요합니다.</p>
                        </>
                        : <></>
                }

                {
                    location.state.site.attribute2 === 'Cvnet' ?
                        <>
                            <p className="modal-black-header text-color-gray mt-3"> ※ 월패드 초기 비밀번호는 1111 입니다.</p>
                        </>
                        : <></>
                }

                <div className="mb-5">
                    <Lottie
                        options={defaultOptions}
                        height={200}
                        width={200}
                        speed={3}
                        isStopped={false}
                    />
                </div>
                <div className="mt-2 footer-fixed">
                    <button
                        onClick={handleClose}
                        className="blue-square-button hoverbtn"> 확인 </button>
                </div>
            </div>


        </div>
    )
}
