import React from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'
import {UserList, UserRegist} from "./CloudN";

export const Bridge = ({userInfo}) => {
    return (
        <Switch>
            <Route path={"/bridge/cloudn/list"} exact children={<UserList userInfo={userInfo}/>}/>
            <Route path={"/bridge/cloudn/regist"} exact children={<UserRegist/>}/>
            <Route path={"/bridge/cloudn/regist/:username"} children={<UserRegist/>}/>
           {/* <Route path={"*"}>
                <Redirect to={"/not-found"}/>
            </Route>*/}
        </Switch>
    )
}  