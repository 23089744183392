import React, {useEffect, useState} from "react";
import {CustomTable, SearchInputBar, QuillEditor, CustomTextField} from 'components/molecules';
import Net from 'scripts/net';
import Common from 'scripts/common';
import classNames from "classnames";
import {EditorState} from "draft-js";
import TextareaAutosize from "react-textarea-autosize";

const columnList = [
    {
        code: 'name',
        title: '장비명',
        width: '6',
        disableEdit : true,
    },
    {
        code: 'deviceId',
        title: 'DeviceID',
        width: '10',
        disableEdit : true,
    },
    {
        code: 'note',
        title: '비고(메모)',
        width: '13',
    },
];

export const SaveRecord = (props) => {

    const [recordList, setRecordList] = useState([]);
    const [oriTextValue, setOriTextValue] = useState("");

    const [isEditMode, setIsEditMode] = useState(true);
    const [contentData, setContentData] = useState([]);
    const [editorState, setEditorState] = useState(EditorState.createEmpty());

    useEffect( ()=>{
        {/*
          1. 체크 디바이스  데이터 저장
        */}
        setContentData(props.data.passList);
    },[])

    const onSubmitHandler = async (check) => {
        let noteString = oriTextValue;
        if(check === 'true' && noteString === ''){
            noteString = '성공';
        }
        let result = window.confirm('저장하시겠습니까?');

        if (result) {
            const response = await Net.saveTestRecord({
                code: props.data.siteCode,
                dong: props.data.dong,
                ho: props.data.ho,
                check: check,
                performer: props.data.userInfo.username,
                note: noteString,
                devices: contentData,
            })
            if(response.status === 201){
                props.modalClose(false);
            } else{
                alert('오류 발생');
            }
        }
    }

    return (
        <div className="w-100 h-98">
            <h3 className="m-3 ">{props.data.dong}동 {props.data.ho}호 테스트 기록 저장</h3>
            <h5 className="ml-40 mb-1">이슈 장비 리스트 - {contentData.length}개</h5>
            <div className="row ml-10 h-65 overflowYscroll w-97">
                <CustomTable
                    columnList={columnList}
                    contentData = {contentData}
                    isEditMode = {isEditMode}
                    paging = {false}
                    editTable = {true}
                />
            </div>

            <div className="flex mb-3 w-100 mt-3">
                <label className="label_title text-center">메모</label>
                <div className="w-45">
                    <TextareaAutosize
                        name="content"
                        className="board_content form-control col-12 h-25"
                        onChange={(event)=>{
                            setOriTextValue(event.target.value);
                        }}
                        value={oriTextValue}
                        minRows={3}
                        type="text"
                    />
                </div>
                <div className="w-55">
                    <h5 className="ml-40 mb-2" style={{color: 'rgb(203,168,79)'}}> ※ 가스, 환기, 공기질 센서 오작동시 - 보류</h5>
                    <h5 className="ml-40 mt-3 mb-2" style={{color: 'rgb(235,135,140)'}}>  ※ 전등(일괄소등), 난방, 대기 전력 오작동시, 일부 디바이스 누락 - 실패</h5>
                    <h5 className="ml-40 mt-3 mb-2" style={{color: 'rgb(52,52,52)'}}>  ※ 기기 상태가 불규칙하여 현장확인이 필요한 경우 / 홈넷 인증 실패 및 디바이스 전체 누락 - 기타</h5>
                    <h5 className="ml-40 mt-3 mb-2" color="grey"> ※ 명칭및 장비 목록에 오류가 있다고 판단되면 홈넷 해지 후 <span style={{color: 'rgb(235,135,140)'}} >재인증 후 테스트 및 모바일</span>에서 확인 </h5>

                </div>
            </div>

            <div className="footer_btn h-8">
                <div className="">
                    <button className="w-160px h-30px addBtn mr-10 ml-10" onClick={()=>onSubmitHandler('true')}>성공</button>
                    <button className="w-160px h-30px cancelBtn mr-10" onClick={()=>onSubmitHandler('false')}>실패</button>
                    <button className="w-160px h-30px defaultBtn mr-10 check-clickedBg" onClick={()=>onSubmitHandler('check')}>보류</button>
                    <button className="w-160px h-30px greyBtn mr-10 check-clickedBg" onClick={()=>onSubmitHandler('etc')}>기타</button>
                </div>
            </div>
        </div>
    )
}
