import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom'
import { CustomTable, SearchInputBar, CustomTextField, CustomRadioButton, BasicModal } from 'components/molecules';
import store from 'scripts/store';
import Common from 'scripts/common';
import Net from 'scripts/net';
import {usePageAuth } from "scripts/hooks";
import {ApartmentRegistModal} from "./modal/ApartmentRegistModal";

const columnList = [
    {
        code: 'site.code',
        title: 'site.code',
        width: '',
        hidden: true,
    },
    {
        code: 'site.name',
        title: '현장명',
        width: '15',
    },
    {
        code: 'inappcomcd',
        title: '업체코드',
        width: '8',
    },
    {
        code: 'connection',
        title: '연동정보',
        width: '10',
    },
    {
        code: 'seedKey',
        title: 'seedKey',
        width: '10',
    },
    {
        code: 'otp',
        title: 'OTP',
        width: '10',
    },
    {
        code: 'payNumber',
        title: '납부 코드',
        width: '10',
    },
    {
        code: 'comId',
        title: 'comId',
        width: '10',
    },
];

export const ApartmentList = (props) => {
    // useHistory 선언
    const history = useHistory();

    const user = props.userInfo;
    const programAuth = usePageAuth(props.userInfo.authList[0], sessionStorage.getItem('menuId'));
    const [contentData, setContentData] = useState([]);
    const [checkedList, setCheckedList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [detailContent, setDetailContent] = useState(null);

    const [pageState, setPageState] = useState({
        currentPage: 0,
        rowPerPage: 10,
        totalElementsCnt: 0,
        drawElementsCnt: 0,
        searchText: '',
    });

    const [modalState, setModalState] = useState({
        open: false,
        data: [],
        code: '',
    });

    useEffect(() => {
        store.openedPage = '관리비 현장 관리';
    }, [])

    useEffect(() => {
        loadData().then();
    }, [pageState.currentPage,
        pageState.searchText,
        modalState.open,
    ]);

    const loadData = async () => {
        setLoading(true);
        const response = await Net.getApartmentList({
            currentPage: pageState.currentPage,
            rowPerPage: pageState.rowPerPage,
            searchText: pageState.searchText
        });
        await setLoading(false);
        if(response.status === 200){
            setContentData(response.data.content);
            setPageState({
                ...pageState,
                totalElementsCnt: response.data.totalElements,
                drawElementsCnt: response.data.numberOfElements,
            });
        }else{
            setContentData([]);
            setPageState({
                ...pageState,
                totalElementsCnt: 0,
                drawElementsCnt : 0,
            })
        }
    }

    const onClickRow = (e) => {
        setDetailContent(e);
    }

    const onMovePage = (e) => {
        setPageState({
            ...pageState,
            currentPage: e-1
        });
    }

    const removeList = async () => {
        const result = window.confirm('삭제하시겠습니까?');
        const removeList = [];
        checkedList.forEach((id) => {
            const node = document.querySelector(`#${id}`);
            const nodeTr = node.closest('tr');
            const code = nodeTr.querySelector('td[name="site.code"]').textContent;
            removeList.push({id:code});
        });

        if(result) {
            const res = await Net.removeApartmentList(removeList);
            if(res){
                await loadData();
            }else {
                alert('통신 오류, 관리자 문의')
            }
        }
    }

    const onSubmitHandler = (e) => {
        setPageState({
            ...pageState,
            searchText: e.searchText,
        });
    }

    const addList = () => {
        /*
        *
        * */
        setModalState({
            ...modalState,
            open: !modalState.open,
            propsData: {
                user: user,
            },
            clickedBtnName: '관리비 현장 등록/수정',
        });
    }

    const goModifypage = () => {
        setModalState({
            ...modalState,
            open: !modalState.open,
            propsData: {
                user : user,
                detailContent : detailContent
            },
            clickedBtnName: '관리비 현장 등록/수정',
        });
    }

    const reload = (e) => {
        if(e === 'reload'){
            loadData().then(console.log('reload'));
        }
    }

    return (
        <div className="w-100 h-100">
            <BasicModal
                className="w-75 h-74"
                title={`${modalState.clickedBtnName}`}
                content={
                <ModalContent
                    modalType={modalState.clickedBtnName}
                    propsData={modalState.propsData}
                    modalClose={(e) => setModalState({
                        ...modalState,
                        open: e
                    })}
                    programAuth = {programAuth}
                    reload={reload}
                />
                }
                modalOpen={modalState.open}
                modalClose={() => setModalState({
                    ...modalState,
                    open: false
                })}
                sendData={modalState.data}
            />
            <div className="w-100 h-7 display_table">
                <SearchInputBar
                    className="w-100"
                    searchCategory={false}
                    searchText={true}
                    searchPlaceHolder="현장명을 입력하세요"
                    searchBtn={true}
                    searchBtnTitle="검색"
                    dynamicSearch={false}
                    onSubmit={onSubmitHandler}
                    refreshBtn={true}
                    refreshHandler={loadData}
                />
                {
                    programAuth.saveAuth &&
                    <div className="display_table_cell v-middle">
                        <button
                            className="addBtn w-90px h-30px floatR mr-20"
                            onClick={addList}
                        >등록</button>
                    </div>
                }
            </div>
            <div className="w-100 h-1px bgC-efefef" >
                <div className="flex h-93">
                    <div className="w-100 h-100">
                        <div>
                            <div className="row d-inline">
                                <p className="totalCnt">Total {pageState.totalElementsCnt}</p>
                                    {
                                        programAuth.deleteAuth && checkedList.length > 0 &&
                                        <button
                                            className="removeBtn w-90px h-30px floatR mt-7 mr-20"
                                            onClick={removeList}
                                        >삭제</button>
                                    }
                                    {
                                        programAuth.saveAuth && detailContent &&
                                        <button
                                            className="cancelBtn w-90px h-30px floatR mt-7 mr-20"
                                            onClick={goModifypage}
                                        >수정</button>
                                    }
                            </div>

                        </div>


                        <CustomTable
                            columnList = {columnList}
                            contentData = {contentData}
                            paging = {true}
                            checkbox = {programAuth.deleteAuth}
                            checkedList = {(checkedList) => {setCheckedList(checkedList)} }
                            totalElementsCnt = {pageState.totalElementsCnt}
                            rowPerPage = {pageState.rowPerPage}
                            currentPage = {pageState.currentPage}
                            drawElementsCnt = {pageState.drawElementsCnt}
                            pagination = {onMovePage}
                            rowClickEvt = {programAuth.saveAuth}
                            onClickRow = {onClickRow}
                            loading={loading}
                            // sort = {true}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

export const ModalContent = (props) => {
    switch (props.modalType) {
        case "관리비 현장 등록/수정":
            return <ApartmentRegistModal data={props.propsData}  modalClose={props.modalClose} reload={props.reload}/>;
        default:
            alert("오류");
    }
}
