import React, {useEffect, useState} from 'react'
import store from 'scripts/store';
import Net from 'scripts/net';
import Common from 'scripts/common';
import classnames from 'classnames/bind';
import Carousel from "react-multi-carousel";
import 'react-multi-carousel/lib/styles.css'
import ImageCard from "../../../components/molecules/ImageCard/ImageCard";

const cx = classnames.bind();


export const MainAdmin = () => {
    useEffect(() => {
        store.openedPage = '';
        init().then(() => {
            console.log("init complete");
        });

    }, [])
    const responsive = {
        superLargeDeskop : {
            breakpoint : { max: 4000, min : 3000},
            items : 5
        },
        desktop: {
            breakpoint : { max : 3000, min : 1024 },
            items : 3
        },
        tablet : {
            breakpoint : { max : 1024, min : 464 },
            items : 2
        },
        mobile : {
            breakpoint : { max : 464, min : 0 },
            items : 1
        }
    }
    const init = async () => {

    }

    return (
        <>
        <div className={cx('container-fluid', 'p-3','bg-2','h-100')}>
            <div className="col-12 object-center px-4 ">
                <h2 className="mb-2 text-2xl text-gray-900 font-bold leading-8 tracking-tight">스마트홈 관리 시스템</h2>
            </div>
            <Carousel
                swipeable={false}
                draggable={false}
                showDots={true}
                responsive={responsive}
                ssr={false} // means to render carousel on server-side.
                infinite={true}
                autoPlay={true}
                autoPlaySpeed={4000}
                keyBoardControl={true}
                customTransition="all .5"
                transitionDuration={500}
                containerClass="carousel-container"
                removeArrowOnDeviceType={["tablet", "mobile"]}
                dotListClass="custom-dot-list-style"
                itemClass="carousel-item-padding-40-px"
            >
                <div>
                    <ImageCard
                        title={'IoK 유저 지원 홈페이지'}
                        description={'코오롱 하늘채 스마트홈 홈네트워크 회원가입 홈페이지'}
                        href={'http://user.smartiok.com/'}
                        href2={'http://user.smartiok.com/'}
                        className="py-4 md:px-4"
                        imgSrc={"/image/iok_user.png"}
                    ></ImageCard>
                </div>
                <div>
                    <ImageCard
                    title={'안드로이드 어플리케이션 페이지'}
                    description={'코오롱 하늘채 IoK 어플리케이션 안드로이드 버전의 구글 스토어 페이지'}
                    href={'https://play.google.com/store/apps/details?id=com.kolon.hanulcheiok'}
                    href2={'https://play.google.com/store/apps/details?id=com.kolon.hanulcheiok'}
                    className="py-4 md:px-4"
                    imgSrc={"/image/iok_gp.png"}
                ></ImageCard></div>
                <div>
                    <ImageCard
                    title={'IOS 어플리케이션 페이지'}
                    description={'코오롱 하늘채 IoK 어플리케이션 IoS 버전의 앱 스토어 페이지'}
                    href={'https://apps.apple.com/kr/app/apple-store/id1554045296'}
                    href2={'https://apps.apple.com/kr/app/apple-store/id1554045296'}
                    className="py-4 md:px-4"
                    imgSrc={"/image/iok_ios.png"}
                ></ImageCard></div>
                <div>
                    <ImageCard
                        title={'포레나 유저 지원 홈페이지'}
                        description={'한화 건설 포레나 스마트홈 홈네트워크 회원가입 홈페이지'}
                        href={'http://user.forenahiot.com/'}
                        href2={'http://user.forenahiot.com/'}
                        className="py-4 md:px-4"
                        imgSrc={"/image/forena_user.png"}
                    ></ImageCard></div>
            </Carousel>
        </div>
        </>
    )
}
