import React, {useState, useEffect} from 'react';
import {useHistory} from 'react-router-dom';
import {CustomTable, SearchInputBar, CustomExcelDownload} from 'components/molecules';
import store from 'scripts/store';
import Net from 'scripts/net';

const columnList = [
    {
        code: 'no',
        title: 'No',
        width: '5',
    },
    {
        code: 'createdDatetime',
        title: '생성일자',
        width: '8',
    },
    {
        code: 'deviceId',
        title: '디바이스 번호',
        width: '8',
    },
    {
        code: 'deviceName',
        title: '디바이스명',
        width: '10',
    },
    {
        code: 'logType',
        title: '로그타입',
        width: '5',
    },
    {
        code: 'attributes',
        title: 'Attributes',
        width: '8',
    },
    {
        code: 'operations',
        title: 'Operations',
        width: '8',
    },
    {
        code: 'typeName',
        title: '디바이스 타입',
        width: '6',
    },
    {
        code: 'modelName',
        title: '모델명',
        width: '6',
    },
    {
        code: 'manufacturerName',
        title: '제조사명',
        width: '5',
    },
    {
        code: 'username',
        title: '사용자ID',
        width: '10',
    },
    {
        code: 'place.name',
        title: '장소명',
        width: '8',
    },
    {
        code: 'site.name',
        title: '현장명',
        width: '8',
    },
    {
        code: 'customer.name',
        title: '고객사명',
        width: '5',
    },
];

export const DeviceHistoryList = (props) => {

    const [check, setCheck] = useState(false);
    const [contentData, setContentData] = useState([]);
    const [contentData4Excel, setContentData4Excel] = useState([]);
    const [searchCategoryGroup, setSearchCategoryGroup] = useState([
        [
            [
                {code: '', name: '고객사 선택'}
            ]
        ],
        [
            [
                {code: '', name: '현장명 선택'}
            ]
        ],
    ]);

    const [pageState, setPageState] = useState({
        currentPage: 0,
        rowPerPage: 13,
        totalElementsCnt: 0,
        drawElementsCnt: 0,
        searchText: '',
        searchText2: '',
        searchStartDate: '',
        searchEndDate: '',
        searchCategory1: '',
        searchCategory2: ''
    });

    const [programAuth, setProgramAuth] = useState({});

    useEffect(() => {
        store.openedPage = '디바이스 이력 목록';
        getCategoryList('');
        pageAuthInfo();
    }, []);

    useEffect(() => {
        // 고객사코드값은 필수값이다.
        if (pageState.searchCategory2 !== '') {
            loadData();
        }
    }, [pageState.currentPage,
        pageState.searchCategory1,
        pageState.searchCategory2,
    ]);

    const pageAuthInfo = () => {
        const authGroupCode = props.userInfo.authList[0];
        const menuId = sessionStorage.getItem('menuId');

        Net.getAuthGroupMenuList(menuId, (response) => {
            if (response.status === 200) {
                response.data.forEach(item => {
                    if (item.authGroup === authGroupCode && item.authGroupMenu) {
                        setProgramAuth({
                            searchAuth: Boolean(item.authGroupMenu.searchAuth),
                            saveAuth: Boolean(item.authGroupMenu.saveAuth),
                            excelAuth: Boolean(item.authGroupMenu.excelAuth),
                            deleteAuth: Boolean(item.authGroupMenu.deleteAuth),
                        });
                    }
                });
            }
        });
    }

    const loadData = () => {
        Net.getDeviceHistoryList(pageState, (response) => {
            console.log(response)
            if (response.status === 200) {
                if (response.data.content) {
                    setContentData(response.data.content);
                    setPageState({
                        ...pageState,
                        totalElementsCnt: response.data.totalElements,
                        drawElementsCnt: response.data.numberOfElements,
                    });
                } else {
                    setContentData([]);
                    setPageState({
                        ...pageState,
                        totalElementsCnt: 0,
                        drawElementsCnt: 0,
                    });
                }
            } else if (response.status === 204) {
                setContentData([]);
                setPageState({
                    ...pageState,
                    totalElementsCnt: 0,
                    drawElementsCnt: 0,
                });
            }
        });

        const copiedPageState = JSON.parse(JSON.stringify(pageState));
        copiedPageState.rowPerPage = 999999;
        Net.getDeviceHistoryList(copiedPageState, (response) => {
            if (response.status === 200) {
                if (response.data.content) {
                    setContentData4Excel(response.data.content);
                } else {
                    setContentData4Excel([]);
                }
            }
        });
    }

    const getCategoryList = () => {
        getCustomerList((response1) => {
            getSitesList(null, (response2) => {
                setSearchCategoryGroup([response1, response2]);
            });
        });
    }

    const getRelateCategory = (selectedCategory) => {
        if (selectedCategory === "reset") selectedCategory = "";
        getSitesList(selectedCategory, (response2) => {
            setSearchCategoryGroup([
                searchCategoryGroup[0],
                response2
            ]);
        });
    }

    const getCustomerList = (callback) => {
        Net.getCustomerList(
            {currentPage: '', rowPerPage: '', searchText: ''}, (response) => {
                if (response.status === 200) {
                    let newArray = searchCategoryGroup[0][0];
                    response.data.content.forEach(items => {
                        newArray = [
                            ...newArray,
                            {
                                code: items.code,
                                name: items.name
                            }
                        ]
                    });
                    callback(newArray);
                }
            });
    }

    const getSitesList = (selectedCategory, callback) => {
        if (selectedCategory) {
            Net.getSitesList(
                {
                    currentPage: '',
                    rowPerPage: '',
                    searchText: '',
                    customerCode: selectedCategory ? selectedCategory : false
                }, (response) => {
                    if (response.status === 200) {
                        let newArray = [{code: '', name: "현장명 선택"}];
                        if (response.data.content.length > 0) {
                            response.data.content.forEach(items => {
                                newArray = [
                                    ...newArray,
                                    {
                                        code: items.code,
                                        name: items.name
                                    }
                                ]

                            })
                        }
                        callback(newArray);
                    } else {
                        let newArray = [{code: '', name: "현장명 선택"}];
                        callback(newArray);
                    }
                });
        } else {
            let newArray = [{code: '', name: "현장명 선택"}];
            callback(newArray);
        }
    }

    const onMovePage = (e) => {
        setPageState({
            ...pageState,
            currentPage: e - 1
        });
    }

    const onSubmitHandler = (e) => {
        pageState.searchText = e.searchText;
        pageState.searchText2 = e.searchText2;
        if (e.selectedCategory[0] === '') {
            alert("고객사 선택 값은 필수입니다.")
            document.querySelector('.searchCategory').focus();
        } else if (e.selectedCategory[1] === '') {
            alert("현장 선택 값은 필수입니다.")
            document.querySelector('.searchCategory.ml-10').focus();
        } else if (check === false) {
            alert("검색기간은 한달을 초과할 수 없습니다.")
        } else {
            setPageState({
                ...pageState,
                currentPage: 0,
                searchText: e.searchText,
                searchText2: e.searchText2,
                searchCategory1: e.selectedCategory[0],
                searchCategory2: e.selectedCategory[1],
            })
            loadData();
        }
    }

    const searchDateHandler = (startDate, endDate) => {
        setCheck(false);
        if (endDate.getFullYear() - startDate.getFullYear() === 0) {
            if (endDate.getMonth() - startDate.getMonth() === 0) {
                setCheck(true);
            } else if (endDate.getMonth() - startDate.getMonth() === 1) {
                if (endDate.getDate() - startDate.getDate() <= 0)
                    setCheck(true);
            }
        }
        startDate = startDate.getFullYear() + '-' + ('0' + (Number(startDate.getMonth()) + 1)).slice(-2) + '-' + ('0' + startDate.getDate()).slice(-2) + ' ' + startDate.getHours() + ':' + ('0' + startDate.getMinutes()).slice(-2);
        endDate = endDate.getFullYear() + '-' + ('0' + (Number(endDate.getMonth()) + 1)).slice(-2) + '-' + ('0' + endDate.getDate()).slice(-2) + ' ' + endDate.getHours() + ':' + ('0' + endDate.getMinutes()).slice(-2);

        if (!pageState.searchStartDate && !pageState.searchEndDate) {
            pageState.searchStartDate = startDate;
            pageState.searchEndDate = endDate;
        } else {
            setPageState({
                ...pageState,
                searchStartDate: startDate,
                searchEndDate: endDate,
            });

        }
    }

    return (
        <div className="w-100 h-100">
            <div className="w-100 h-7 display_table">
                <SearchInputBar
                    className="w-100"
                    searchCategory={true}
                    searchCategoryGroup={searchCategoryGroup}
                    searchText={true}
                    searchText2={true}
                    searchPlaceHolder="사용자ID를 입력해주세요."
                    searchPlaceHolder2="디바이스번호를 입력해주세요."
                    searchBtn={true}
                    searchBtnTitle="검색"
                    dynamicSearch={false}
                    searchDate={searchDateHandler}
                    onSubmit={onSubmitHandler}
                    relateCategoryHandler={getRelateCategory}
                />
            </div>
            <div className="w-100 h-1px bgC-efefef"/>
            <div className="flex">
                <div className="w-100 h-93 overflowYscroll">
                    <p className="totalCnt">Total {pageState.totalElementsCnt}</p>
                    {
                        programAuth.excelAuth &&
                        <CustomExcelDownload
                            className="downloadBtn w-90px h-30px floatR mt-7 mr-20"
                            sheetTitle="device_history_list"
                            columnList={columnList}
                            contentData4Excel={contentData4Excel}
                            pageState={pageState}
                        />
                    }
                    <CustomTable
                        columnList={columnList}
                        contentData={contentData}
                        checkbox={false}
                        paging={true}
                        totalElementsCnt={pageState.totalElementsCnt}
                        rowPerPage={pageState.rowPerPage}
                        currentPage={pageState.currentPage}
                        drawElementsCnt={pageState.drawElementsCnt}
                        pagination={onMovePage}
                        rowClickEvt={false}
                    />
                </div>
            </div>
        </div>
    )
}
