import React, {Fragment, useEffect, useState} from "react";
import {CustomTable, SearchInputBar, BasicModal} from 'components/molecules';
import Net from 'scripts/net';
import Common from 'scripts/common';


export const TelecomDvAttrMapp = (props) => {
    const modalColumnList = [
        {
            code : 'no',
            title: 'No',
            width: '5',
        },
        {
            code : 'actionType',
            title: '명령/상태 타입',
            width: '10',
        },
        {
            code : 'requestName',
            title: '요청명',
            width: '10',
        },
        {
            code : 'propertyCode',
            title: 'IoK 속성',
            width: '10',
        },
        {
            code : 'extensionProperty',
            title: '3rd Party 속성',
            width: '10',
        },
        {
            code : 'value',
            title: 'IoK value',
            width: '10',
        },
        {
            code : 'modelCode',
            title: '모델 코드',
            width: '10',
        },
        {
            code : 'jsonTooltip',
            title: '속성 맵핑',
            width: '7',
        },
        {
            code : 'jsonTooltip2',
            title: '상태 속성 맵핑',
            width: '7',
        },
        {
            code : 'jsonTooltip3',
            title: '기타 속성',
            width: '7',
        },
    ];

    const [dvAttrList, setDvAttrList] = useState([]);

    useEffect( ()=>{
        {/*
          1. 목록 조회
        */}
        loadData();
    },[])

    const loadData = () => {
        Net.getTelecomSettingProperty(props.data.data, response => {
            setDvAttrList(response.data.content.map(item=>{
                item.jsonTooltip = item.extensionAvailableValues;
                item.jsonTooltip2 = item.extensionAvailableStatuses;
                item.jsonTooltip3 = item.attribute;
                return item;
            }));
        });
    }

    const tdOnClickEvent = async (title, e) => {

        const result = window.confirm('삭제 하시겠습니까?');
        if (result) {
            //const response = await Net.deleteCheckListById(e.id);
            await loadData();
        }
    }

    const onClickRow = (e) => {

    }


    return (
        <div className="overflow-hidden">
            <h5 className="m-3 ml-50">타입별 장비 속성 맵핑 [ 통신사, 삼성 ] {props.data.data.extensionService} - {props.data.data.deviceType} - {props.data.data.modelCode}</h5>
            <div className="row">
                <div className="col-4 ml-20">
                </div>
            </div>
            <div className="flex justify-content-between">
                <div className="col-12">
                    <CustomTable
                        columnList={modalColumnList}
                        contentData={dvAttrList}
                        totalElementsCnt={dvAttrList.length}
                        rowPerPage={999999}
                        currentPage={0}
                    />
                </div>
            </div>
        </div>
    )
}
