import React, { useEffect, useState } from 'react';
import { useHistory, withRouter } from 'react-router-dom';
import { CustomTextField, CustomRadioButton,QuillEditor, CustomSelectBox, ImgUpload } from 'components/molecules';
import store from 'scripts/store';
import Net from 'scripts/net';
import Common from 'scripts/common';
import {toJS} from "mobx";
import {EditorState} from "draft-js";

const FaqRegist = ({match}) => {

    const cloneUser = toJS(store.user)

    const [checkSubmit, setCheckSubmit] = useState(false);
    const [form, setForm] = useState({
        faqId: '',
        classification: '',
        title: '',
        contents: '',
        customer : cloneUser.menuGroup === 'ADMIN' ? {code: '',name: '',} : cloneUser.customer,
        site: cloneUser.menuGroup === 'OPERATOR' ? cloneUser.site : { code: '', name: '',},
        publicYn: true,
        image: '',
        imageDataUrl: '',
    });

    const [registPage, setRegistPage] = useState(true);
    const [customerList, setCustomerList] = useState([{
        code: '',
        name: '고객사명 선택'
    }]);
    const [siteList, setSiteList] = useState([{
        code: '',
        name: '현장명 선택'
    }]);
    const [classificationList, setClassificationList] = useState([
        [
            {code: '', name: '구분 선택'}
        ]
    ]);
    const [editorState, setEditorState] = useState(EditorState.createEmpty());

    useEffect(() => {
        store.openedPage = '자주묻는 질문 등록/수정';

        // 상세 조회(수정 목적으로 진입시)
        if(match.params.faqId) {

            getFaqOne(match.params, (response) => {

                if(response.status === 200) {
                    if(!response.data.customer){
                        response.data.customer = {code: "", name: ""};
                    }
                    if(!response.data.site){
                        response.data.site = {code: "", name: ""};
                    }
                    setForm({
                        ...form,
                        ...response.data,
                    });
                    setEditorState(Common.htmlToDraftBlocks(response.data.contents));

                    // 등록페이지 여부 확인
                    setRegistPage(false);


                }
            });
        }

        // 고객사 전체 목록
        getCustomerList((response) => {
            setCustomerList(response);
        });
        getClassificationList();
    }, []);

    useEffect(()=>{
        if(checkSubmit){
            saveLogic(form);
        }
    },[checkSubmit])

    useEffect(() => {
        if(form.customer.code !== '') {
            getSiteList(form.customer.code, (response) => {
                setSiteList(response);
            })
        } else {
            // 빈값 선택시 장비모델명 selectbox 초기화
            setSiteList([
                {
                    code: '',
                    name: '현장명 선택'
                }
            ])
        }
    }, [form.customer.code]);

    const textFieldHandler = (name, value) => {
        const nextForm = {
            ...form,
            [name]: value
        }
        setForm(nextForm);
    }

    const getCustomerList = (callback) => {
        Net.getCustomerList({currentPage: '', rowPerPage: '', searchText: ''}, (response) => {
            if(response.status === 200) {
                let newArray = customerList;
                response.data.content.forEach(items => {
                    newArray = [
                        ...newArray,
                        {
                            code: items.code,
                            name: items.name
                        }
                    ]
                });
                callback(newArray);
            }
        });
    }

    const getSiteList = (code, callback) => {
        Net.getSitesList(
            {
                currentPage: '',
                rowPerPage: '',
                searchText: '',
                customerCode: code,
            }, (response) => {
            if(response.status === 200) {
                let newArray = [
                    {
                        code: '',
                        name: '현장명 선택'
                    }
                ];
                response.data.content.forEach(items => {
                    newArray = [
                        ...newArray,
                        {
                            code: items.code,
                            name: items.name
                        }
                    ]
                });
                callback(newArray);
            } else if(response.status === 204) {
                let newArray = [
                    {
                        code: '',
                        name: '현장명 선택'
                    }
                ];
                callback(newArray);
            }
        });
    }

    const getClassificationList = () => {
        Net.getCommunityCode(
            "FAQ_CLASSIFICATION", (response) => {
                if(response.status === 200) {
                    let newArray = classificationList[0];

                    response.data.forEach(items => {
                        if( items.usable){
                            if(cloneUser.menuGroup === "OPERATOR"){
                                if(items.code !== "COMMON"){
                                    newArray = [
                                        ...newArray,
                                        {
                                            code: items.code,
                                            name: items.name
                                        }
                                    ]
                                }
                            }else{
                                newArray = [
                                    ...newArray,
                                    {
                                        code: items.code,
                                        name: items.name
                                    }
                                ]
                            }
                        }
                    })
                    setClassificationList(newArray);
                }
            }
        )

    }

    const history = useHistory();

    const getFaqOne = (params, callback) => {
        Net.getFaqOne(params.faqId, (response) => {
            callback(response);
        });
    }

    const radioBtnHandler = (name, value) => {
        let nextForm;

        nextForm = {
            ...form,
            [name]: value === 'true',
        }

        setForm(nextForm);
    }

    const selectBoxHandler = (name, value) => {
        switch (name) {
            case 'customer.code':
                setForm({
                    ...form,
                    customer: {
                        code: value.code,
                        name: value.name
                    },
                    site: {
                        code: "",
                        name: ""
                    }
                });
                break;
            case 'site.code':
                setForm({
                    ...form,
                    site: {
                        code: value.code,
                        name: value.name
                    }
                });
                break;
            case 'classification':
                if(value === "COMMON"){
                    setForm({
                        ...form,
                        classification: value,
                        site : {
                            code : '',
                            name : '',
                        }
                    })
                }else{
                    setForm({
                        ...form,
                        classification: value
                    });
                }
                break;
            default:
                break;
        }
    }

    const onSubmitHandler = () => {

        if (!form.classification) {
            alert('구분은 필수값입니다.');
            document.getElementsByName('classification')[0].focus();
            return false;
        }
        if (!form.title) {
            alert('제목은 필수값입니다.');
            document.getElementsByName('title')[0].focus();
            return false;
        }
        if (!form.contents) {
            alert('내용은 필수값입니다.');
            return false;
        }
        if (cloneUser.menuGroup === 'OPERATOR') {
            setForm({
                ...form,
                customer: cloneUser.customer,
                site: cloneUser.site,
            });

        } else if (cloneUser.menuGroup === 'MANAGER') {
            if (form.classification !== "COMMON") {
                if (!form.site.code) {
                    alert('현장명을 선택해 주세요.');
                    document.getElementsByName('site.code')[0].focus();
                    return false;
                }
            }
                setForm({
                    ...form,
                    customer: cloneUser.customer,
                });
        } else {
            if (form.classification === "COMMON") {
                if (!form.customer.code) {
                    alert('고객사를 선택해 주세요.');
                    document.getElementsByName('customer.code')[0].focus();
                    return false;
                }
            } else {
                if (!form.customer.code) {
                    alert('고객사를 선택해 주세요.');
                    document.getElementsByName('customer.code')[0].focus();
                    return false;
                }
                if (!form.site.code) {
                    alert('현장명을 선택해 주세요.');
                    document.getElementsByName('site.code')[0].focus();
                    return false;
                }
            }
            setCheckSubmit(true);
        }
    }

    const saveLogic = (saveform) => {
        var result = window.confirm('저장하시겠습니까?');

        if(result) {
            if(registPage) {
                Net.addFaq(saveform, (response) => {
                    if(response.status === 200 || response.status === 201) {
                        history.push('/community/faq/list');

                    }
                });
            } else {
                Net.updateFaq(saveform, (response) => {
                    if(response.status === 200) {
                        history.push('/community/faq/list');

                    }
                });
            }
        }
    }

    const onCancelHandler = () => {
        history.push('/community/faq/list');
    }

    const getImgFile = (dataUrl) => {
        setForm({
            ...form,
            imageDataUrl: dataUrl,
        });
    }

    const liftValue = (value) => {
        setForm({
            ...form,
            contents: value,
        });
    }

    const onRemoveHandler = () => {
        const result = window.confirm('삭제하시겠습니까?');
        if(result) {
            Net.removeFaq([{faqId: form.faqId}], (response) => {
                if(response) {
                    history.push('/community/faq/list');
                }
            });
        }
    }

    return (
        <div className="w-100 h-100">
            <div className="w-100 h-7 display_table"/>
            <div className="w-100 h-1px bgC-efefef" />
            <div className="h-93">
                <div className="flex h-90">
                    <div className="w-55 h-100">
                        <div className="ml-55 mt-12">
                            <div className="flex mb-12 w-90">
                                <label className="label_title">구분 (*)</label>
                                <CustomSelectBox
                                    name="classification"
                                    categoryList={classificationList}
                                    value={form.classification}
                                    onChangeHandler={(value) => selectBoxHandler('classification', value)}
                                    callbackCodeName="N"
                                    disabled={!registPage}
                                />
                            </div>
                            <div className="flex mb-12 w-90">
                                <CustomTextField
                                    className="w-65 h-26px"
                                    title="제목"
                                    name="title"
                                    required={true}
                                    disabled={false}
                                    placeholder=""
                                    customOnChange={textFieldHandler}
                                    value={form}
                                />
                            </div>
                            <div className="flex mb-10 w-90">
                                <label className="label_title">내용 *</label>
                                <div className="w-80">
                                    <QuillEditor
                                        onChange={liftValue}
                                        value={editorState}
                                        setEditorState={setEditorState}
                                    />
                                </div>
                            </div>
                            {
                                cloneUser.menuGroup === 'ADMIN' ? (
                                    <div className="flex mb-3 w-90">
                                        <label className="label_title">고객사 *</label>
                                        <CustomSelectBox
                                            name="customer.code"
                                            categoryList={customerList}
                                            value={form.customer.code}
                                            onChangeHandler={(value, name) => selectBoxHandler('customer.code', {code: value, name: name})}
                                            callbackCodeName="Y"
                                            disabled={!registPage}
                                        />
                                        {
                                            registPage ? <div/> :
                                                <div className="label_summary ml-10">※ 수정시 고객사 및 현장은 변경 불가능 합니다. </div>
                                        }
                                    </div>
                                ) : <></>
                            }
                            {
                                cloneUser.menuGroup === 'OPERATOR' ? <></> :(
                                    <div className="flex mb-3 w-90">
                                        <label className="label_title">현장 *</label>
                                        {
                                            form.classification==='COMMON'  ?
                                                <div>
                                                    <div className="label_summary ml-10">※ 구분이 `공통`이면 현장 값을 지정 할 수 없습니다.</div>
                                                </div>
                                                :
                                                <CustomSelectBox
                                                    name="site.code"
                                                    categoryList={siteList}
                                                    value={form.site.code}
                                                    onChangeHandler={(value, name) => selectBoxHandler('site.code', {code: value, name: name})}
                                                    callbackCodeName="Y"
                                                    disabled={!registPage}
                                                />
                                        }
                                    </div>
                                )
                            }

                            <div className="flex w-90">
                                <label className="label_title">사용유무</label>
                                <CustomRadioButton
                                    className="mr-60"
                                    title="사용"
                                    id="radioBtn1"
                                    name="publicYn"
                                    value={true}
                                    checked={form.publicYn}
                                    customOnChange={radioBtnHandler}
                                />
                                <CustomRadioButton
                                    title="사용안함"
                                    id="radioBtn2"
                                    name="publicYn"
                                    value={false}
                                    checked={!form.publicYn}
                                    customOnChange={radioBtnHandler}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="w-40 h-100">
                        <div className="w-100 h-100 mt-12" style={{display:"block"}} >
                            <div className="floatC mr-2p" >
                                <ImgUpload
                                    title={"사진"}
                                    imgUpload={getImgFile}
                                    imgSize={"300px"}
                                    imgFile={form.imageDataUrl}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer_btn h-10">
                    <div className="">
                        <button className="w-160px h-30px addBtn mr-10" onClick={onSubmitHandler}>저장</button>
                        { registPage ? "" : <button className="w-160px h-30px removeBtn mr-10" onClick={onRemoveHandler}>삭제</button>}
                        <button className="w-160px h-30px cancelBtn" onClick={onCancelHandler}>취소</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default withRouter(FaqRegist);
