import React, {useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom'
import {CustomTable, SearchInputBar} from 'components/molecules';
import store from 'scripts/store';
import Net from 'scripts/net';
import classNames from "classnames";

const columnList = [
    {
        code: 'username',
        title: '사용자ID',
        width: '8',
    },
    {
        code: 'homenetUsername',
        title: '홈넷ID',
        width: '8',
    },
    {
        code: 'regionCode',
        title: '홈넷사 지역코드',
        width: '6',
    },
    {
        code: 'regionCodeName',
        title: '홈넷사 지역명',
        width: '9',
    },
    {
        code: 'siteName',
        title: '현장 명',
        width: '8',
    },
    {
        code: 'dong',
        title: '동',
        width: '6',
    },
    {
        code: 'ho',
        title: '호',
        width: '6',
    },
    {
        code: 'createdDatetime',
        title: '생성 일시',
        width: '8',
    },
    {
        code: 'updatedDatetime',
        title: '수정 일시',
        width: '8',
    },
];

export const HomenetUserList = (props) => {
    // useHistory 선언
    const history = useHistory();

    const user = props.userInfo;
    const [contentData, setContentData] = useState([]);
    const [loading, setLoading] =useState(false);
    const [searchCategory, setSearchCategory] = useState([
        [
            [
                {code: '', name: '고객사 선택'}
            ]
        ],
        [
            [
                {code: '', name: '현장명 선택'}
            ]
        ],
    ]);

    const [pageState, setPageState] = useState({
        currentPage: 1,
        rowPerPage: 10,
        totalElementsCnt: 0,
        drawElementsCnt: 0,
        searchText: '',
        searchText2: '',
        searchText3: '',
        searchCategory1: user.menuGroup === 'ADMIN' ? '' : user.customer && user.customer.code,
        searchCategory2: user.menuGroup === 'OPERATOR' ? user.site && user.site.code : '',
    });

    const [programAuth, setProgramAuth] = useState({});


    useEffect(() => {
        store.openedPage = '홈넷 인증 사용자 목록';
        getCategoryList('');
        pageAuthInfo();
    }, [])

    useEffect(() => {
        if( pageState.searchCategory1 === '' || pageState.searchCategory2 === ''){
            alert('현장을 선택 해주세요.');
            return;
        }

        loadData()
    }, [pageState.currentPage,
        pageState.searchCategory1,
        pageState.searchCategory2,
        pageState.searchText,
        pageState.searchText2,
        pageState.searchText3,
    ]);

    const pageAuthInfo = () => {
        const authGroupCode = props.userInfo.authList[0];
        const menuId = sessionStorage.getItem('menuId');

        Net.getAuthGroupMenuList(menuId, (response) => {
            if (response.status === 200) {
                response.data.forEach(item => {
                    if (item.authGroup === authGroupCode && item.authGroupMenu) {
                        setProgramAuth({
                            searchAuth: Boolean(item.authGroupMenu.searchAuth),
                            saveAuth: Boolean(item.authGroupMenu.saveAuth),
                            excelAuth: Boolean(item.authGroupMenu.excelAuth),
                            deleteAuth: Boolean(item.authGroupMenu.deleteAuth),
                        });
                    }
                });
            }
        });
    }

    const loadData = () => {
        setLoading(true);
        Net.getHomenetUserList(
            {
                currentPage: pageState.currentPage,
                rowPerPage: pageState.rowPerPage,
                searchText: pageState.searchText,
                searchText2: pageState.searchText2,
                searchText3: pageState.searchText3,
                searchCategory1: pageState.searchCategory1,
                searchCategory2: pageState.searchCategory2,
            }, (response) => {
                setLoading(false);
                if (response.status === 200) {
                    setContentData(response.data.content.map((items)=>{
                        items.regionCodeName = window.REGION_CODE ? window.REGION_CODE[items.regionCode] : "-";
                        return items;
                    }));
                    setPageState({
                        ...pageState,
                        totalElementsCnt: response.data.totalElements,
                        drawElementsCnt: response.data.numberOfElements,
                    });
                } else {
                    setContentData([]);
                    setPageState({
                        ...pageState,
                        totalElementsCnt: 0,
                        drawElementsCnt: 0,
                    });
                }
            });
    }


    const getCategoryList = () => {
        if (user.menuGroup === 'ADMIN') {
            getCustomerList((response1) => {
                getSitesList(null, (response2) => {
                    setSearchCategory([response1, response2]);
                });
            });
        } else if (user.menuGroup === 'MANAGER') {
            getSitesList(pageState.searchCategory1, (response2) => {
                setSearchCategory([response2]);
            });
        }
    }

    const getRelateCategory = (selectedCategory) => {
        if (selectedCategory === "reset") selectedCategory = "";
        getSitesList(selectedCategory, (response2) => {
            setSearchCategory([
                searchCategory[0], // 고객사
                response2,         // 현장
            ]);
        });
    }

    const getCustomerList = (callback) => {
        Net.getCustomerList(
            {currentPage: '', rowPerPage: '', searchText: ''}, (response) => {
                if (response.status === 200) {
                    let newArray = searchCategory[0][0];
                    response.data.content.forEach(items => {
                        newArray = [
                            ...newArray,
                            {
                                code: items.code,
                                name: items.name
                            }
                        ]
                    });
                    callback(newArray);
                }
            });
    }

    const getSitesList = (selectedCategory, callback) => {
        if (selectedCategory) {
            Net.getSitesList(
                {
                    currentPage: '',
                    rowPerPage: '',
                    searchText: '',
                    customerCode: selectedCategory ? selectedCategory : false
                }, (response) => {
                    if (response.status === 200) {
                        let newArray = [{code: '', name: "현장명 선택"}];
                        if (response.data.content.length > 0) {
                            response.data.content.forEach(items => {
                                newArray = [
                                    ...newArray,
                                    {
                                        code: items.code,
                                        name: items.name
                                    }
                                ]

                            })
                        }
                        callback(newArray);
                    } else {
                        let newArray = [{code: '', name: "현장명 선택"}];
                        callback(newArray);
                    }
                });
        } else {
            let newArray = [{code: '', name: "현장명 선택"}];
            callback(newArray);
        }
    }

    const onClickRow = (e) => {

    }

    const onMovePage = (e) => {
        setPageState({
            ...pageState,
            currentPage: e
        });
    }

    const onSubmitHandler = (e) => {



        if (user.menuGroup === 'ADMIN') {
            setPageState({
                ...pageState,
                currentPage: 1,
                searchCategory1: e.selectedCategory[0],
                searchCategory2: e.selectedCategory[1],
                searchText: e.searchText,
                searchText2: e.searchText2,
                searchText3: e.searchText3
            });
        } else if (user.menuGroup === 'MANAGER') {
            setPageState({
                ...pageState,
                currentPage: 1,
                searchCategory1: pageState.searchCategory1,
                searchCategory2: e.selectedCategory[0],
                searchText: e.searchText,
                searchText2: e.searchText2,
                searchText3: e.searchText3,
            });
        } else {
            setPageState({
                ...pageState,
                currentPage: 1,
                searchCategory1: pageState.searchCategory1,
                searchCategory2: pageState.searchCategory2,
                searchText: e.searchText,
                searchText2: e.searchText2,
                searchText3: e.searchText3
            });
        }
    }


    return (
        <div className="w-100 h-100">
            <div className="w-100 h-7 display_table">
                <SearchInputBar
                    className="w-100"
                    role={user.menuGroup}
                    searchCategory={user.menuGroup === 'ADMIN' || user.menuGroup === 'MANAGER'}
                    searchCategoryGroup={searchCategory}
                    searchText={true}
                    searchText2={true}
                    searchText3={true}
                    searchPlaceHolder="사용자아이디 입력하세요."
                    searchPlaceHolder2="동 입력하세요."
                    searchPlaceHolder3="호 입력하세요."
                    searchBtn={true}
                    searchBtnTitle="검색"
                    dynamicSearch={false}
                    relateCategoryHandler={getRelateCategory}
                    onSubmit={onSubmitHandler}
                    refreshBtn={true}
                    refreshHandler={loadData}
                />
            </div>
            <div className="w-100 h-1px bgC-efefef"/>
            <div className="w-100 h-100">
                <p className={classNames("totalCnt")}>Total {pageState.totalElementsCnt}</p>
                <CustomTable
                    columnList={columnList}
                    contentData={contentData}
                    checkbox={programAuth.deleteAuth}
                    paging={true}
                    totalElementsCnt={pageState.totalElementsCnt}
                    rowPerPage={pageState.rowPerPage}
                    currentPage={pageState.currentPage - 1}
                    drawElementsCnt={pageState.drawElementsCnt}
                    pagination={onMovePage}
                    rowClickEvt={true}
                    onClickRow={onClickRow}
                    loading={loading}
                    // sort = {true}
                />
            </div>
        </div>
    )
}
