import React, {useEffect, useState} from "react";
import {CustomTextField,CustomSelectBox, CustomRadioButton} from 'components/molecules';
import Net from 'scripts/net';
import Common from 'scripts/common';


export const ServiceRegistModal = (props) => {

    const [codeClassificationList, setCodeClassificationList] = useState([
        {code: '', name: '코드 선택'},
    ]);
    const [form, setForm] = useState({
        type : '',
        code : '',
        name : '',
        usable: true,
        homenetUsable : false,
        onepassUsable : false,
        sort : '',
        addProperty: [],
        attribute2 : '',
        attribute3 : '',
        attribute4 : '',
        attribute5 : '',
    })
    const [selectClassification , setSelectClassification] = useState();
    const [selectCode , setSelectCode] = useState();
    const [selectName , setSelectName] = useState();
    const [addProperty, setAddProperty] = useState(false);
    const [attribute2Code, setAttribute2Code] = useState('');
    const [attribute3Code, setAttribute3Code] = useState('');
    const [attribute4Code, setAttribute4Code] = useState('');
    const [attribute5Code, setAttribute5Code] = useState('');


    const classificationList = [
        {code: '', name: '서비스 선택'},
        {code: 'communityService', name: 'communityService'},
        {code: 'mainService', name: 'mainService'},
        {code: 'searchService', name: 'searchService'},
        {code: 'settings', name: 'settings'},
    ];

    const attributeCodeList = ["attribute2Code", "attribute3Code", "attribute4Code", "attribute5Code"];
    const attributeStateList = [attribute2Code, attribute3Code, attribute4Code, attribute5Code];
    const attributeList = ["attribute2", "attribute3", "attribute4", "attribute5"];
    const attributeSetCodeList = [setAttribute2Code, setAttribute3Code, setAttribute4Code, setAttribute5Code];

    useEffect(()=>{
        const filterService = props.data.filter(item=>{
            if( item.attribute1 === selectClassification) return item;
        });
        const tempSelectList = [
            {code: '', name: '코드 선택'},
            ...filterService
        ]
        setCodeClassificationList(tempSelectList);
    },[selectClassification]);

    useEffect(()=>{
        props.data.forEach((item)=>{
            if(item.code === selectCode){
                for (let i=0; i<attributeCodeList.length; i++){
                    const tempString = "attribute"+(Number(i)+2);
                    if(item.hasOwnProperty(tempString)){
                        setAddProperty(true);
                        attributeSetCodeList[i](item[tempString]);
                    }
                }
            }
        })
    },[selectCode]);

    const selectBoxHandler = (name, value, selectName) => {
        switch (name) {
                case 'classification':
                    setSelectClassification(value);
                break;
                case 'codeSelect':
                    setSelectCode(value);
                    setSelectName(selectName);
                break;
            default:
                break;
        }
    }

    const textFieldHandler = (name, value) => {
        if(name==="sort"){
            const nextForm = {
                ...form,
                [name]: Number(value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1'))
            }
            setForm(nextForm);
        }else{
            const nextForm = {
                ...form,
                [name]: value,
            }
            setForm(nextForm);
        }
    }


    const radioBtnHandler = (name, value) => {
        const nextForm = {
            ...form,
            [name]: value === 'true' ? true : false,
        }
        setForm(nextForm);
    }

    const onSubmitHandler = async () => {
        if(!selectCode){
            alert("코드를 선택 해주세요.");
            return false;
        }
        if(!form.sort){
            alert("정렬순서를 입력해주세요.");
            return false;
        }

        let result = window.confirm('저장하시겠습니까?');
        if (result) {

            let tempAddProperty = {};

            for( let i =0; i<attributeList.length; i++){
                if(form[attributeList[i]]){
                    tempAddProperty = {
                        ...tempAddProperty,
                        [attributeStateList[i]] : form[attributeList[i]]
                    }
                }
            }

            let tempState = props.baseData;
            switch (selectClassification){
                default :
                    if(Object.keys(tempAddProperty).length === 0){
                        tempState[selectClassification] = [
                            ...tempState[selectClassification],
                            {
                                code:selectCode,
                                name:selectName,
                                usable : form.usable,
                                onepassUsable : form.onepassUsable,
                                homenetUsable : form.homenetUsable,
                                sort : form.sort,
                            }
                        ]
                    }else {
                        tempState[selectClassification] = [
                            ...tempState[selectClassification],
                            {
                                code:selectCode,
                                name:selectName,
                                usable : form.usable,
                                onepassUsable : form.onepassUsable,
                                homenetUsable : form.homenetUsable,
                                sort : form.sort,
                                addProperty : [tempAddProperty]
                            }
                        ]
                    }
            }

            Net.updateAppService(tempState, response => {
                if(response.status === 200){
                    props.reload('reloadService');
                    props.modalClose(false);
                } else{
                    alert('오류 발생');
                }
            })
        }
    }



    return (
        <div className="overflow-hidden">
            <h5 className="m-3 ml-50">서비스 등록 | 앱 서비스 관리</h5>
            <div className="flex">
            <div className="w-40">
                <div className="flex mb-12 w-100">
                    <label className="label_title">구분 *</label>
                    <CustomSelectBox
                        name="classification"
                        categoryList={classificationList}
                        value={selectClassification}
                        onChangeHandler={(value) => selectBoxHandler('classification', value)}
                    />
                </div>
                <div className="flex mb-12 w-100">
                    <label className="label_title">코드 *</label>
                    <CustomSelectBox
                        name="codeSelect"
                        categoryList={codeClassificationList}
                        value={selectCode}
                        onChangeHandler={(value, name) => selectBoxHandler('codeSelect', value, name)}
                        callbackCodeName={true}
                    />
                </div>
                <div className="flex w-100 mb-12">
                    <label className="label_title">사용여부*</label>
                    <CustomRadioButton
                        className="w-10 mr-20 ml-5"
                        title="Y"
                        id="radioBtn1"
                        name="usable"
                        value={true}
                        checked={form.usable}
                        customOnChange={radioBtnHandler}
                    />
                    <CustomRadioButton
                        className="w-10 mr-20"
                        title="N"
                        id="radioBtn2"
                        name="usable"
                        value={false}
                        checked={!form.usable}
                        customOnChange={radioBtnHandler}
                    />
                </div>
                <div className="flex w-100 mb-12">
                    <label className="label_title">원패스 사용여부*</label>
                    <CustomRadioButton
                        className="w-10 mr-20 ml-5"
                        title="Y"
                        id="radioBtn3"
                        name="onepassUsable"
                        value={true}
                        checked={form.onepassUsable}
                        customOnChange={radioBtnHandler}
                    />
                    <CustomRadioButton
                        className="w-10 mr-20"
                        title="N"
                        id="radioBtn4"
                        name="onepassUsable"
                        value={false}
                        checked={!form.onepassUsable}
                        customOnChange={radioBtnHandler}
                    />
                </div>
                <div className="flex w-100 mb-12">
                    <label className="label_title">홈넷 사용여부*</label>
                    <CustomRadioButton
                        className="w-10 mr-20 ml-5"
                        title="Y"
                        id="radioBtn5"
                        name="homenetUsable"
                        value={true}
                        checked={form.homenetUsable}
                        customOnChange={radioBtnHandler}
                    />
                    <CustomRadioButton
                        className="w-10 mr-20"
                        title="N"
                        id="radioBtn6"
                        name="homenetUsable"
                        value={false}
                        checked={!form.homenetUsable}
                        customOnChange={radioBtnHandler}
                    />
                </div>
                <div className="flex w-90">
                    <CustomTextField
                        className="w-30 h-26px"
                        title="정렬 순서"
                        name="sort"
                        required={true}
                        disabled={false}
                        placeholder="숫자 입력 ex)1, 2, 3"
                        customOnChange={textFieldHandler}
                        value={form}
                        type={"number"}
                    />
                </div>
            </div>
            <div className="w-55">
                {
                    addProperty ? <h5 className="mb-3">추가 속성</h5>  : <></>
                }

                {
                    attribute2Code ? <>
                        <div className="flex w-100 mb-2">
                            <CustomTextField
                                className="w-100 h-26px"
                                title={attribute2Code}
                                name="attribute2"
                                disabled={false}
                                customOnChange={textFieldHandler}
                                value={form}
                                type={"text"}
                            />
                        </div>
                    </> : <></>
                }
                {
                    attribute3Code ? <>
                        <div className="flex w-100 mb-2">
                            <CustomTextField
                                className="w-100 h-26px"
                                title={attribute3Code}
                                name="attribute3"
                                disabled={false}
                                customOnChange={textFieldHandler}
                                value={form}
                                type={"text"}
                            />
                        </div>
                    </> : <></>
                }
                {
                    attribute4Code ? <>
                        <div className="flex w-100 mb-2">
                            <CustomTextField
                                className="w-100 h-26px"
                                title={attribute4Code}
                                name="attribute4"
                                disabled={false}
                                customOnChange={textFieldHandler}
                                value={form}
                                type={"text"}
                            />
                        </div>
                    </> : <></>
                }
                {
                    attribute5Code ? <>
                        <div className="flex w-100 mb-2">
                            <CustomTextField
                                className="w-100 h-26px"
                                title={attribute5Code}
                                name="attribute5"
                                disabled={false}
                                customOnChange={textFieldHandler}
                                value={form}
                                type={"text"}
                            />
                        </div>
                    </> : <></>
                }
            </div>
            </div>
            <div className="footer_btn h-8 mt-15">
                    <button className="w-160px h-30px addBtn mr-10 ml-10" onClick={()=>onSubmitHandler()}>저장</button>
                    <button className="w-160px h-30px cancelBtn mr-10" onClick={()=>props.modalClose(false)}>취소</button>
            </div>
        </div>
    )
}
