import React, {Fragment, useEffect, useLayoutEffect, useState} from 'react';
import {useHistory} from 'react-router-dom'
import {CustomTable, SearchInputBar} from 'components/molecules';
import store from 'scripts/store';
import Net from 'scripts/net';
import {toast} from "react-toastify";

const columnList = [
    {
        code: 'classification',
        title: '구분',
        width: '7',
    },
    {
        code: 'total',
        title: '총합',
        width: '8',
        number : true
    },
    {
        code: 'homenetCnt',
        title: '홈넷 서비스',
        width: '8',
        number : true

    },
    {
        code: 'userCnt',
        title: '인증 사용자',
        width: '8',
        number : true

    },
    {
        code: 'sktcnt',
        title: 'SKT',
        width: '6',
        number : true

    },
    {
        code: 'ktcnt',
        title: 'KT',
        width: '6',
        number : true
    },
    {
        code: 'lgucnt',
        title: 'LGU+',
        width: '6',
        number : true
    },
    {
        code: 'lgcnt',
        title: 'LG전자',
        width: '6',
        number : true
    },
    {
        code: 'samsungcnt',
        title: 'SAMSUNG전자',
        width: '8',
        number : true
    },
];

const detailColumnList =[
    {
        code: 'manufacturer',
        title: '제조사',
        width: '15',
    },
    {
        code: 'numberOfDevice',
        title: '중복 디바이스 총갯수',
        width: '15',
    },
    {
        code: 'status',
        title: '상태',
        width: '10',
    },
];

export const ServiceMonitoring = (props) => {

    // useHistory 선언
    const history = useHistory();
    const user = props.userInfo;
    const siteCode = user.site && user.site.code;
    const [programAuth, setProgramAuth] = useState({});
    const [contentData, setContentData] = useState([]);
    const [detailContentData, setDetailContentData] = useState([]);

    useLayoutEffect( () => {
        toast('데이터 로드 중입니다.',{
            autoClose : 4000,
            pauseOnHover: false,
            draggable : false,
            position : "top-center"
        });
        store.openedPage = '서비스 연동 및 중복 디바이스 현황';

        loadData().then(()=>{  console.log("init complete"); });
    }, [])


    const loadData = async () => {
        //모니터링 정보 GET
        const response = await Net.getServiceConnections();
        let tempList = [
            {
                classification : "device",
                ...response.data.device
            },
            {
                classification : "user",
                ...response.data.user
            }
        ];
        const response2 = await Net.getManufacturerDeviceDuplication();
        setContentData(tempList);
        setDetailContentData(response2.data.map(item=>{
            item.status = item.numberOfDevice > 0 ? false : true;
            return item;
        }));
    }


    return (
        <div className="w-100 h-100">
            <div className="w-100 h-1px bgC-efefef"/>
            <div className="h-93">
                <div className="w-100 h-50">
                    <h5 className="floatL mt-1p mb-1p ml-2p">서비스 현황</h5>

                    <CustomTable
                        columnList={columnList}
                        contentData={contentData}
                    />
                </div>
                <div className="w-95 h-50">
                    <h5 className="floatL mt-1p mb-1p ml-2p">제조사별 중복 디바이스</h5>
                    <CustomTable
                        columnList={detailColumnList}
                        contentData={detailContentData}
                    />
                </div>
            </div>
        </div>
    );
}



