import React, { useEffect, useState} from 'react';
import { useHistory } from 'react-router-dom'
import { CustomTable, SearchInputBar, BasicModal, CustomExcelDownload } from 'components/molecules';
import store from 'scripts/store';
import Net from 'scripts/net';
import {toast} from "react-toastify";

const columnList = [
    {
        code: 'itemId',
        title: 'itemId',
        width: '0',
        hidden: true,
    },
    {
        code: 'groupCode.name',
        title: '예약그룹명',
        width: '10',
    },
    {
        code: 'name',
        title: '예약명',
        width: '25',
    },
    {
        code: 'startDate',
        title: '개시일자',
        width: '10',
    },
    {
        code: 'endDate',
        title: '종료일자',
        width: '10',
    },
    {
        code: 'site.name',
        title: '현장명',
        width: '15',
    },
    {
        code: 'customer.name',
        title: '고객사명',
        width: '15',
    },
    {
        code: '',
        title: '예약현황보기',
        width: '10',
        tdClickEvent: true,
    },
    {
        code: 'usable',
        title: '사용여부',
        width: '5',
    },
];

export const ReservationList = (props) => {
    // useHistory 선언
    const history = useHistory();
    const user = props.userInfo;
    const [contentData, setContentData] = useState([]);
    const [checkedList, setCheckedList] = useState([]);

    const [searchCategory, setSearchCategory] = useState([
        [
            [
                {code: '', name: '고객사 선택'}
            ]
        ],
        [
            [
                {code: '', name: '현장명 선택'}
            ]
        ],
        [
            [
                {code: '', name: '예약그룹 선택'}
            ]
        ],
    ]);

    const [pageState, setPageState] = useState({
        currentPage: 0,
        rowPerPage: 13,
        totalElementsCnt: 0,
        drawElementsCnt: 0,
        searchText: '',
        searchCategory1: user.menuGroup === 'ADMIN' ? '' : user.customer && user.customer.code,
        searchCategory2: user.menuGroup === 'OPERATOR' ? user.site && user.site.code: '',
        searchCategory3: '',
    });

    useEffect(() => {
        store.openedPage = '예약 목록';
        getCategoryList('');
        pageAuthInfo();

    }, [])

    useEffect(() => {
        loadData();
    }, [pageState.currentPage,
        pageState.searchCategory1,
        pageState.searchCategory2,
        pageState.searchCategory3,
    ]);

    const [programAuth, setProgramAuth] = useState({});
    const [modalState, setModalState] = useState({
        open: false,
        data: [],
        form: {
            title: '',
            itemId: '',
            groupCode: '',
            name: '',
        }
    });

    const pageAuthInfo = () => {
        const authGroupCode = props.userInfo.authList[0];
        const menuId = sessionStorage.getItem('menuId');

        Net.getAuthGroupMenuList(menuId, (response) => {
            if(response.status === 200) {
                response.data.forEach(item => {
                    if(item.authGroup === authGroupCode && item.authGroupMenu) {
                        setProgramAuth({
                            searchAuth: Boolean(item.authGroupMenu.searchAuth),
                            saveAuth: Boolean(item.authGroupMenu.saveAuth),
                            excelAuth: Boolean(item.authGroupMenu.excelAuth),
                            deleteAuth: Boolean(item.authGroupMenu.deleteAuth),
                        });
                    }
                });
            }
        });
    }

    const loadData = () => {
        Net.getReservationList(
            {
                currentPage: pageState.currentPage,
                rowPerPage: pageState.rowPerPage,
                searchText: pageState.searchText,
                searchCategory1: pageState.searchCategory1,
                searchCategory2: pageState.searchCategory2,
                searchCategory3: pageState.searchCategory3
            }, (response) => {
            if(response.status === 200) {

                setContentData(response.data.content);
                setPageState({
                    ...pageState,
                    totalElementsCnt: response.data.totalElements,
                    drawElementsCnt: response.data.numberOfElements,
                });

            } else {
                setContentData([]);
                setPageState({
                    ...pageState,
                    totalElementsCnt: 0,
                    drawElementsCnt: 0,
                });
            }
       });
    }

    const getReservationGroupList = (callback) => {

        Net.getCommunityCode(
            "RESERVATION_GROUP", (response) => {
                if(response.status === 200) {
                    let newArray = [{code:'',name:"예약그룹 선택"}];
                    response.data.forEach(items => {
                        if( items.usable ){
                            newArray = [
                                ...newArray,
                                {
                                    code: items.code,
                                    name: items.name
                                }
                            ]
                        }
                    })
                    callback({status: 200, data: newArray});
                }
            }
        )
    }

    const getCategoryList = () => {
        let response3 = [];
        getReservationGroupList(response => {
            if(response.status === 200) {
                response3 = response.data;
                if (user.menuGroup === 'ADMIN') {
                    getCustomerList((response1) => {
                        getSitesList(null, (response2) => {
                            setSearchCategory([response1, response2, response3]);
                        });
                    });
                } else if (user.menuGroup === 'MANAGER') {
                    getSitesList(pageState.searchCategory1, (response2) => {
                        setSearchCategory([response2, response3]);
                    });
                } else {
                    setSearchCategory([response3]);
                }
            }
        });
    }

    const getRelateCategory = (selectedCategory) => {
        if(selectedCategory === "reset") selectedCategory = "";
        getSitesList(selectedCategory, (response2) => {
            setSearchCategory([
                searchCategory[0],
                response2,
                searchCategory[2]
            ]);
        });
    }

    const getCustomerList = (callback) => {
        Net.getCustomerList(
            {currentPage: '', rowPerPage: '', searchText: ''}, (response) => {
            if(response.status === 200) {
                let newArray = searchCategory[0][0];
                response.data.content.forEach(items => {
                    newArray = [
                        ...newArray,
                        {
                            code: items.code,
                            name: items.name
                        }
                    ]
                });
                callback(newArray);
            }
        });
    }

    const getSitesList = (selectedCategory, callback) => {
        if(selectedCategory){
            Net.getSitesList(
                {currentPage: '', rowPerPage: '', searchText: '', customerCode: selectedCategory?selectedCategory:false}, (response) => {
                 if(response.status === 200) {
                     let newArray =  [{code:'',name:"현장명 선택"}];
                     if(response.data.content.length > 0) {
                         response.data.content.forEach(items => {
                             newArray = [
                                 ...newArray,
                                 {
                                     code: items.code,
                                     name: items.name
                                 }
                             ]

                         })
                     }
                    callback(newArray);
                 } else {
                     let newArray = [{code:'',name:"현장명 선택"}];
                     callback(newArray);
                 }
            });
        } else {
            let newArray = [{code:'',name:"현장명 선택"}];
            callback(newArray);
        }


    }

    const onClickRow = (e) => {
        history.push(`/community/reservation/regist/${e.itemId}`);
    }

    const tdOnClickEvent = (title, e) => {
        onClickModalOpen({title: title, itemId: e.itemId, groupCode: e.groupCode.name, name:e.name});
    }

    const onMovePage = (e) => {
        setPageState({
            ...pageState,
            currentPage: e-1
        });
    }

    const removeList = () => {
        const result = window.confirm('삭제하시겠습니까?');
        const removeList = [];
        checkedList.forEach((id) => {
            const node = document.querySelector(`#${id}`);
            const nodeTr = node.closest('tr');
            const itemId = nodeTr.querySelector('td[name=itemId]').textContent;
            removeList.push({itemId: itemId});
        });
        if(result) {
            Net.removeReservation(removeList, (response) => {
                if(response) {
                    toast('삭제 되었습니다.',{
                        autoClose : 1500,
                        pauseOnHover: false,
                        draggable : false,
                        position : "top-center"
                    });
                    loadData();
                }
            });
        }
    }

    const onSubmitHandler = (e) => {
        if (user.menuGroup === 'ADMIN') {
            setPageState({
                ...pageState,
                currentPage: 0,
                searchCategory1: e.selectedCategory[0],
                searchCategory2: e.selectedCategory[1],
                searchCategory3: e.selectedCategory[2],
            });

        } else if (user.menuGroup === 'MANAGER') {
            setPageState({
                ...pageState,
                currentPage: 0,
                searchCategory1: pageState.searchCategory1,
                searchCategory2: e.selectedCategory[0],
                searchCategory3: e.selectedCategory[1],
            });
        } else {
            setPageState({
                ...pageState,
                currentPage: 0,
                searchCategory1: pageState.searchCategory1,
                searchCategory2: pageState.searchCategory2,
                searchCategory3: e.selectedCategory[0],
            });
        }

    }

    const addList = () => {
        history.push('/community/reservation/regist');
    }

    // Open Modal and setClickedBtnName
    const onClickModalOpen = (form) => {
        setModalState({
            ...modalState,
            open: !modalState.open,
            form: form
        });

    }

    const sendData = (e) => {
        setModalState({
            ...modalState,
            data: e,
        })
    }

    return (
        <div className="w-100 h-100">
            <BasicModal
                className="w-75 h-78"
                title={`${modalState.form.title} 목록`}
                content={<ModalContent
                            sendData={sendData}
                            modalType={modalState.form.title}
                            excelAuth={programAuth.excelAuth}
                            form={modalState.form}
                        />}
                modalOpen={modalState.open}
                modalClose={(e) => setModalState({
                    ...modalState,
                    open: e
                })}
                sendData={modalState.data}
                //rcvData={rcvData}
            />
            <div className="w-100 h-7 display_table">
                <SearchInputBar
                    className="w-100"
                    searchCategory={true}
                    searchCategoryGroup={searchCategory}
                    searchBtn={true}
                    searchBtnTitle="검색"
                    dynamicSearch={false}
                    relateCategoryHandler={user.menuGroup === 'ADMIN' && getRelateCategory}
                    onSubmit={onSubmitHandler}
                />
                {
                    programAuth.saveAuth &&
                    <div className="display_table_cell v-middle">
                        <button
                            className="addBtn w-90px h-30px floatR mr-20"
                            onClick={addList}
                        >등록</button>
                    </div>
                }
            </div>
            <div className="w-100 h-1px bgC-efefef" />
            <div className="flex h-93">
                <div className="w-100 h-100">
                    <p className="totalCnt">Total {pageState.totalElementsCnt}</p>
                    {
                        programAuth.deleteAuth && checkedList.length > 0 &&
                        <button
                            className="removeBtn w-90px h-30px floatR mt-7 mr-15"
                            onClick={removeList}
                        >삭제</button>
                    }
                    <CustomTable
                        columnList = {columnList}
                        contentData = {contentData}
                        paging = {true}
                        checkbox = {programAuth.deleteAuth}
                        checkedList = {(checkedList) => {setCheckedList(checkedList)} }
                        totalElementsCnt = {pageState.totalElementsCnt}
                        rowPerPage = {pageState.rowPerPage}
                        currentPage = {pageState.currentPage}
                        drawElementsCnt = {pageState.drawElementsCnt}
                        pagination = {onMovePage}
                        rowClickEvt = {true}
                        onClickRow = {onClickRow}
                        tdClickEvt = {tdOnClickEvent}
                    />
                </div>
            </div>
        </div>
    );
}


export const ModalContent = (props) => {

    const columnList = [
        {
            code: 'groupCode',
            title: '예약그룹',
            width : '10',
        },
        {
            code: 'name',
            title: '예약명',
            width: '10',
        },
        {
            code: 'user.username',
            title: '사용자ID',
            width: '10',
        },
        {
            code: 'user.userFullName',
            title: '사용자명',
            width: '10',
        },
        {
            code: 'place.name',
            title: '장소',
            width: '10',
        },
        {
            code: 'createdDatetime',
            title: '요청 일시',
            width: '10',
        },
        {
            code: 'reservationDateTime',
            title: '예약 일시/시간',
            width: '10',
        },
        {
            code: 'statusKOR',
            title: '예약상태',
            width: '10',
        },
    ];

    const [form, setForm] = useState({
        itemId: props.form.itemId,
        contents: '',
        finished: false,
    });

    const [pageState, setPageState] = useState({
        itemId: form.itemId,
        currentPage: 0,
        rowPerPage: 10,
        totalElementsCnt: 0,
        drawElementsCnt: 0,
        searchText: '',
        searchCategory1: '',
        searchStartDate: '',
        searchEndDate: '',
    });

    const [searchCategory, setSearchCategory] = useState([
        [
            {code: '', name: '예약 상태 선택'},
            {code: 'RESERVATION', name: '예약'},
            {code: 'CANCEL', name: '취소'}
        ],
    ]);

    const [contentData, setContentData] = useState([]);
    const [contentData4Excel, setContentData4Excel] = useState([]);

    useEffect(() => {
        loadData();
    }, [ pageState.currentPage,
        pageState.searchText,
        pageState.searchCategory1,
        pageState.searchStartDate,
        pageState.searchEndDate,
    ]);

    const loadData = () => {
        Net.getReservationConditionList(
            {
                itemId: pageState.itemId,
                currentPage: pageState.currentPage,
                rowPerPage: pageState.rowPerPage,
                searchText: pageState.searchText,
                searchCategory1: pageState.searchCategory1,
                searchStartDate : pageState.searchStartDate,
                searchEndDate : pageState.searchEndDate
            }, (response) => {
            if(response.status === 200) {
                setContentData(response.data.content);
                response.data.content.forEach((item) => {
                    if(item.status === "RESERVATION"){
                        item.statusKOR = "예약";
                    } else if(item.status === "CANCEL"){
                        item.statusKOR = "취소";
                    } else {
                        item.statusKOR = "";
                    }
                    item.reservationDateTime = item.reservationDate + " " + (item.reservationTime ? item.reservationTime : "")
                    item.groupCode = props.form.groupCode;
                    item.name = props.form.name;
                })
                setPageState({
                    ...pageState,
                    totalElementsCnt: response.data.totalElements,
                    drawElementsCnt: response.data.numberOfElements,
                });
            } else {
                setContentData([]);
                setPageState({
                    ...pageState,
                    totalElementsCnt: response.data.totalElements,
                    drawElementsCnt: response.data.numberOfElements,
                });
            }

            const copiedPageState = JSON.parse(JSON.stringify(pageState));
            copiedPageState.rowPerPage = 999999;
            Net.getReservationConditionList(copiedPageState, (response) => {
                if(response.status === 200) {
                    if(response.data.content) {
                        response.data.content.forEach((item) => {
                            if(item.status === "RESERVATION"){
                                item.statusKOR = "예약";
                            } else if(item.status === "CANCEL"){
                                item.statusKOR = "취소";
                            } else {
                                item.statusKOR = "";
                            }
                            item.reservationDateTime = item.reservationDate + " " + (item.reservationTime ? item.reservationTime : "")
                            item.groupCode = props.form.groupCode;
                            item.name = props.form.name;
                        });

                        setContentData4Excel(response.data.content);
                    } else {
                        setContentData4Excel([]);
                    }
                }
            });

       });
    }

    const searchDateHandler = (startDate, endDate) => {

        startDate = startDate.getFullYear() + '-' + ('0' + (Number(startDate.getMonth()) + 1)).slice(-2) + '-' + ('0' + startDate.getDate()).slice(-2) + ' 00:00';
        endDate = endDate.getFullYear() + '-' + ('0' + (Number(endDate.getMonth()) + 1)).slice(-2) + '-' + ('0' + endDate.getDate()).slice(-2)+ ' 23:59';

        if(!pageState.searchStartDate && !pageState.searchEndDate) {
            pageState.searchStartDate = startDate;
            pageState.searchEndDate = endDate;
        } else {
            setPageState({
                ...pageState,
                searchStartDate: startDate,
                searchEndDate: endDate,
            });
        }
    }

    const onMovePage = (e) => {
        setPageState({
            ...pageState,
            currentPage: e-1
        });
    }

    const onSubmitHandler = (e) => {
        setPageState({
            ...pageState,
            currentPage: 0,
            searchText: e.searchText,
            searchCategory1: e.selectedCategory[0],
            searchCategory2: e.selectedCategory[1],
        });
    }
    return (
        <div>
            <div className="w-100 h-7 display_table mb-5">
                <SearchInputBar
                    className="w-100"
                    searchCategory={true}
                    searchCategoryGroup={searchCategory}
                    searchText={true}
                    searchPlaceHolder="사용자명을 입력하세요."
                    searchBtn={true}
                    searchBtnTitle="검색"
                    dynamicSearch={false}
                    searchDate={searchDateHandler}
                    onSubmit={onSubmitHandler}
                    datetype={"reservation"}
                />
                {
                    props.excelAuth &&
                    <CustomExcelDownload
                        className="downloadBtn w-90px h-30px floatR mt-7 mr-20"
                        sheetTitle="device_history_list"
                        columnList={columnList}
                        contentData4Excel={contentData4Excel}
                        pageState={pageState}
                    />
                }
            </div>
            <div className="w-100  h-1px bgC-efefef">
                <CustomTable
                    className = "footer_btn"
                    columnList = {columnList}
                    contentData = {contentData}
                    checkbox = {false}
                    paging = {true}
                    totalElementsCnt = {pageState.totalElementsCnt}
                    rowPerPage = {pageState.rowPerPage}
                    currentPage = {pageState.currentPage}
                    drawElementsCnt = {pageState.drawElementsCnt}
                    pagination = {onMovePage}
                />
            </div>


        </div>
    )
}
