import React from 'react'
import { Switch, Route } from 'react-router-dom';
// import {  } from './AlertSecurityHistoryPage';
import { DeviceHistoryList } from './DeviceHistoryPage';
import NotFound from "../../components/templates/NotFound";
// import {  } from './ElevatorHistoryPage';
// import {  } from './InOutCarHistoryPage';
// import {  } from './ParcelHistoryPage';
// import {  } from './ParkingHistoryPage';
// import {  } from './VisitorHistoryPage';

export const HistoryPage = ({userInfo}) => {

    return (
        <Switch>
            <Route path={"/history/device/list"} exact children={<DeviceHistoryList userInfo={userInfo} />}/>
            <Switch>
                {/* <Route path={"/location/customer/regist/:code"} children={<CustomerRegist/>} />
                <Route path={"/location/customer/regist"} children={<CustomerRegist/>} /> */}
            </Switch>
            <Route path={"*"}>
                <NotFound />
            </Route>
        </Switch>
    )
}
