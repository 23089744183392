/* eslint-disable */
import React, { Fragment, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom'
import { CustomTable, SearchInputBar, CustomTextField, CustomRadioButton, BasicModal } from 'components/molecules';
import store from 'scripts/store';
import Net from 'scripts/net';

const codeTypeColumnList = [
    {
        code: 'code',
        title: '유형코드',
        width: '45',
    },
    {
        code: 'name',
        title: '유형명',
        width: '35',
    },
    {
        code: 'usable',
        title: '사용여부',
        width: '20',
    },
];

const columnList = [
    {
        code: 'id',
        title: 'id',
        width: '',
        hidden: true,
    },
    {
        code: 'codeType',
        title: 'codeType',
        width: '20',
        hidden: true,
    },
    {
        code: 'code',
        title: '코드',
        width: '20',
    },
    {
        code: 'name',
        title: '코드명',
        width: '10',
    },
    {
        code: 'sort',
        title: '정렬순서',
        width: '5',
    },
    {
        code: 'attribute1',
        title: '속성1',
        width: '8',
    },
    {
        code: 'attribute2',
        title: '속성2',
        width: '8',
    },
    {
        code: 'attribute3',
        title: '속성3',
        width: '8',
    },
    {
        code: 'attribute4',
        title: '속성4',
        width: '8',
    },
    {
        code: 'attribute5',
        title: '속성5',
        width: '8',
    },
    {
        code: 'usable',
        title: '사용여부',
        width: '5',
    },
];


export const CodeList = (props) => {
    // useHistory 선언
    const history = useHistory();

    const [programAuth, setProgramAuth] = useState({});
    const [codeTypeContentData, setCodeTypeContentData] = useState([]);
    const [clickedCodeType, setClickedCodeType] = useState('');
    const [contentData, setContentData] = useState([]);
    const [checkedList, setCheckedList] = useState([]);

    const [codeTypeDetail, setCodeTypeDetail] = useState([]);

    const [pageState, setPageState] = useState({
        currentPage: 0,
        rowPerPage: 13,
        totalElementsCnt: 0,
        drawElementsCnt: 0,
        searchText: '',
    });

    const [modalState, setModalState] = useState({
        open: false,
        data: [],
        code: '',
    });

    useEffect(() => {
        store.openedPage = '코드관리';
    }, [])

    useEffect(() => {
        loadData();
        pageAuthInfo();
    }, [pageState.currentPage,
        pageState.searchText,
    ]);

    useEffect(() => {
        clickedCodeType &&
        onClicCodeTypekRow({code:clickedCodeType});
    }, [modalState]);

    const pageAuthInfo = () => {
        const authGroupCode = props.userInfo.authList[0];
        const menuId = sessionStorage.getItem('menuId');

        Net.getAuthGroupMenuList(menuId, (response) => {
            if(response.status === 200) {
                response.data.map(item => {
                    if(item.authGroup === authGroupCode && item.authGroupMenu) {
                        setProgramAuth({
                            searchAuth: Boolean(item.authGroupMenu.searchAuth),
                            saveAuth: Boolean(item.authGroupMenu.saveAuth),
                            excelAuth: Boolean(item.authGroupMenu.excelAuth),
                            deleteAuth: Boolean(item.authGroupMenu.deleteAuth),
                        });
                    }
                });
            }
        });
    }

    const loadData = () => {
        Net.getCommunityCodeTypeList(
            {
                currentPage: pageState.currentPage,
                rowPerPage: pageState.rowPerPage,
                searchText: pageState.searchText
            }, (response) => {
            if(response.status === 200) {
                setCodeTypeContentData(response.data.content);
                setPageState({
                    ...pageState,
                    totalElementsCnt: response.data.totalElements,
                    drawElementsCnt: response.data.numberOfElements,
                });
            } else {
                setCodeTypeContentData([]);
                setPageState({
                    ...pageState,
                    totalElementsCnt: 0,
                    drawElementsCnt: 0,
                });
            }
            setContentData([]);
            setClickedCodeType('');
       });
    }

    const onClicCodeTypekRow = (e) => {
        setClickedCodeType(e.code);
        Net.getCommunityCode(e.code, (response) => {
            if(response.status === 200) {
                setContentData(response.data);
            }
        });
    }

    const onClickRow = (e) => {
        onClickModalOpen({id: e.id, codeType: e.codeType});
    }

    const onMovePage = (e) => {
        setPageState({
            ...pageState,
            currentPage: e-1
        });
    }

    const removeList = () => {
        const result = window.confirm('삭제하시겠습니까?');
        const removeList = [];
        checkedList.map((id) => {
            const node = document.querySelector(`#${id}`);
            const nodeTr = node.closest('tr');
            const codeId = nodeTr.querySelector('td[name=id]').textContent;
            removeList.push({id: codeId});
        });
        if(result) {
            Net.removeCommunityCode(removeList, (response) => {
                if(response) {
                    onClicCodeTypekRow({code:clickedCodeType});
                }
            });
        }
    }

    const onSubmitHandler = (e) => {
        setPageState({
            ...pageState,
            searchText: e.searchText,
        });
    }

    const addList = () => {
        onClickModalOpen({id: '', codeType: clickedCodeType});
    }

    const onClickModalOpen = (form) => {
        setModalState({
            ...modalState,
            open: !modalState.open,
            form: form
        });
    }

    const sendData = (e) => {
        setModalState({
            ...modalState,
            data: e,
        })
    }

    return (
        <div className="w-100 h-100">
            <BasicModal
                className=""
                title={`코드 등록/수정`}
                content={<ModalContent
                            sendData={sendData}
                            modalClose={(e) => setModalState({
                                                    ...modalState,
                                                    open: e
                                                })
                                        }
                            form={modalState.form}
                        />}

                modalOpen={modalState.open}
                modalClose={(e) => setModalState({
                    ...modalState,
                    open: e
                })}
                sendData={modalState.data}
                //rcvData={rcvData}
            />
            <div className="w-100 h-7 display_table">
                <SearchInputBar
                    className="w-100"
                    searchCategory={false}
                    searchText={true}
                    searchPlaceHolder="유형명을 입력하세요"
                    searchBtn={true}
                    searchBtnTitle="검색"
                    dynamicSearch={false}
                    onSubmit={onSubmitHandler}
                />
                {
                    programAuth.saveAuth && clickedCodeType &&
                    <div className="display_table_cell v-middle">
                        <button
                            className="addBtn w-90px h-30px floatR mr-20"
                            onClick={addList}
                        >등록</button>
                    </div>
                }

            </div>
            <div className="w-100 h-1px bgC-efefef" />
            <div className="flex w-100 h-93">
                <div className="w-30 h-100">
                    <p className="totalCnt">Total {pageState.totalElementsCnt}</p>
                    <CustomTable
                        columnList = {codeTypeColumnList}
                        contentData = {codeTypeContentData}
                        paging = {true}
                        checkbox = {false}
                        checkedList = {(checkedList) => {setCheckedList(checkedList)} }
                        totalElementsCnt = {pageState.totalElementsCnt}
                        rowPerPage = {pageState.rowPerPage}
                        currentPage = {pageState.currentPage}
                        drawElementsCnt = {pageState.drawElementsCnt}
                        pagination = {onMovePage}
                        rowClickEvt = {true}
                        onClickRow = {onClicCodeTypekRow}
                        // sort = {true}
                    />
                </div>
                <div className="w-70 h-100">
                    <p className="body_content_subtitle">코드 목록</p>
                    {
                        programAuth.deleteAuth && checkedList.length > 0 &&
                        <button
                            className="removeBtn w-90px h-30px floatR mt-7 mr-15"
                            onClick={removeList}
                        >삭제</button>
                    }
                    <CustomTable
                        columnList = {columnList}
                        contentData = {contentData}
                        paging = {false}
                        checkbox = {programAuth.deleteAuth}
                        checkedList = {(checkedList) => {setCheckedList(checkedList)} }
                        //totalElementsCnt = {pageState.totalElementsCnt}
                        //rowPerPage = {pageState.rowPerPage}
                        //currentPage = {pageState.currentPage}
                        //drawElementsCnt = {pageState.drawElementsCnt}
                        //pagination = {onMovePage}
                        rowClickEvt = {programAuth.saveAuth}
                        onClickRow = {onClickRow}
                        // sort = {true}
                    />
                </div>
            </div>
        </div>
    );
}

export const ModalContent = (props) => {
    const history = useHistory();
    const [propertyList, setpropertyList] = useState([]);
    //const [duplicateChk, setDuplicateChk] = useState(false);
    const [propertyCheckedList, setPropertyCheckedList] = useState([]);

    const [form, setForm] = useState({
        id: props.form.id ? props.form.id : '',
        codeType: props.form.codeType ? props.form.codeType : '',
        code: '',
        name: '',
        sort: '',
        attribute1: '',
        attribute2: '',
        attribute3: '',
        attribute4: '',
        attribute5: '',
        usable: true,
    });
    const [registPage, setRegistPage] = useState(true);
    const textFieldHandler = (name, value) => {
        //if(name === 'code') setDuplicateChk(false);
        const nextForm = {
            ...form,
            [name]: value
        }
        setForm(nextForm);
    }
    useEffect(() => {
        if(form.id) {
            Net.getCommunityCodeOne(form.id, (response) => {

                if(response.status === 200) {
                    setForm({
                        ...form,
                        ...response.data,
                    });

                    // 등록페이지 여부 확인
                    setRegistPage(false);
                }
            });
        }
    }, [])

    const radioBtnHandler = (name, value) => {
        const nextForm = {
            ...form,
            [name]: value === 'true' ? true : false,
        }
        setForm(nextForm);
    }

    const onRemoveHandler = () => {
        const result = window.confirm('삭제하시겠습니까?');
        if(result) {
            Net.removeCommunityCode([{id: form.id}], (response) => {
                if(response) {
                    handleClose();
                }
            });
        }
    }

    const onSubmitHandler = () => {
        if(!form.code){
            alert("코드를 입력해주세요.");
            return false;
        }
        if(!form.name){
            alert("코드명을 입력해주세요.");
            return false;
        }
        if(!form.sort){
            alert("정렬순서를 입력해주세요.");
            return false;
        }

        var result = window.confirm('저장하시겠습니까?');
        if(result) {
            if(registPage) {
                Net.addCommunityCode(form, (response) => {
                    if(response.status === 200 || response.status === 201) {
                        handleClose();
                    } else if(response.status === 409){
                        alert('이미 등록된 코드 입니다.');
                    }
                });

            } else {
                Net.updateCommunityCode(form, (response) => {
                    if(response.status === 200) {
                        handleClose();
                    }
                });
            }
        }
    }

    const handleClose = () => {
        props.modalClose(false);
    };

    return (
        <div className="w-100 h-100">
            <div className="h-90" style={{display:"contents"}}>
                <div className="flex mb-12 w-100">
                    <div className="flex mb-12 w-50">
                        <CustomTextField
                            className="w-50 h-26px"
                            title="코드"
                            name="code"
                            required={true}
                            disabled={registPage ? false : true}
                            placeholder=""
                            customOnChange={textFieldHandler}
                            value={form}
                        />
                    </div>
                    <div className="flex mb-12 w-50">
                        <CustomTextField
                            className="w-50 h-26px"
                            title="코드명"
                            name="name"
                            required={true}
                            disabled={false}
                            placeholder=""
                            customOnChange={textFieldHandler}
                            value={form}
                        />
                    </div>
                </div>
                <div className="flex mb-24 w-100">
                    <CustomTextField
                        className="w-25 h-26px"
                        title="정렬순서"
                        name="sort"
                        validRegExp={/[ㄱ-ㅎ|ㅏ-ㅣ|가-힣|a-zA-Z\{\}\[\]\/?.,;:|\)*~`!^\-+<>@\#$%&\\\=\(\'\"]/g}
                        required={true}
                        disabled={false}
                        placeholder=""
                        customOnChange={textFieldHandler}
                        value={form}
                    />
                </div>
                <div className="flex mb-12 w-100">
                    <div className="flex mb-12 w-50">
                        <CustomTextField
                            className="w-50 h-26px"
                            title="속성1"
                            name="attribute1"
                            required={false}
                            disabled={false}
                            placeholder=""
                            customOnChange={textFieldHandler}
                            value={form}
                        />
                    </div>
                    <div className="flex mb-12 w-50">
                        <CustomTextField
                            className="w-50 h-26px"
                            title="속성2"
                            name="attribute2"
                            required={false}
                            disabled={false}
                            placeholder=""
                            customOnChange={textFieldHandler}
                            value={form}
                        />
                    </div>
                </div>
                <div className="flex mb-12 w-100">
                    <div className="flex mb-12 w-50">
                        <CustomTextField
                            className="w-50 h-26px"
                            title="속성3"
                            name="attribute3"
                            required={false}
                            disabled={false}
                            placeholder=""
                            customOnChange={textFieldHandler}
                            value={form}
                        />
                    </div>
                    <div className="flex mb-12 w-50">
                        <CustomTextField
                            className="w-50 h-26px"
                            title="속성4"
                            name="attribute4"
                            required={false}
                            disabled={false}
                            placeholder=""
                            customOnChange={textFieldHandler}
                            value={form}
                        />
                    </div>
                </div>
                <div className="flex mb-12 w-100">
                    <CustomTextField
                        className="w-25 h-26px"
                        title="속성5"
                        name="attribute5"
                        required={false}
                        disabled={false}
                        placeholder=""
                        customOnChange={textFieldHandler}
                        value={form}
                    />
                </div>
                <div className="flex w-100 mb-12">
                    <label className="label_title">사용유무*</label>
                    <CustomRadioButton
                        className="mr-60"
                        title="사용"
                        id="radioBtn1"
                        name="usable"
                        value={true}
                        checked={form.usable}
                        customOnChange={radioBtnHandler}
                    />
                    <CustomRadioButton
                        title="사용안함"
                        id="radioBtn2"
                        name="usable"
                        value={false}
                        checked={!form.usable}
                        customOnChange={radioBtnHandler}
                    />
                </div>
            </div>
            <div className="footer_btn h-10 mt-30 mb-30">
                <div className="">
                    <button className="w-80px h-30px addBtn mr-10" onClick={onSubmitHandler}>저장</button>
                    { registPage ? "" : <button className="w-80px h-30px removeBtn mr-10" onClick={onRemoveHandler}>삭제</button>}
                    <button className="w-80px h-30px cancelBtn" onClick={handleClose}>취소</button>
                </div>
            </div>
        </div>
    )
}
