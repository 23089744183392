import React, {useCallback, useEffect, useRef, useState} from 'react';
import Net from 'scripts/visitor/net';
import Common from 'scripts/common';
import logo from './image/logoHanulchaeWhite@3x.png';
import inviteImage from './image/inviteImg@2x.png';
import alertIcon from './image/icon14Mark.png';
import minusIcon from './image/icon40Delete@3x.png';
import {useHistory, withRouter} from "react-router-dom";
import './VisitorRegist.scss';
import { useDidMountEffect } from "scripts/hooks";


const VisitorRegist = ({match}) => {
    const inputFocus = useRef(null);
    const history = useHistory();
    const [inviteData, setInviteData] = useState({
        inviteName : '',
        inviteMessage : '',
        startDate : '',
        endDate : '',
        address : '',
    });
    const [carNumber, setCarNumber] = useState('');
    const [boolRegCar, setBoolRegCar] = useState(false);
    const [boolPast, setBoolPast] =useState(false);
    const [boolLoading, setBoolLoading] =useState(false);
    const [boolDeleteLoading, setBoolDeleteLoading] =useState(false);
    const [carForm, setCarForm] = useState({
        visitorId : '',
        carNumber : ''
    })

    useEffect(()=>{
        /*
        * 1. Invite 정보 조회
        * 2. 방문 차량 등록 여부 조회
        * */
        Net.getVisitorCar((match.params), response => {
            if(response.status === 200 ){
                /*
                * 1. Start Date , End Date 다듬기
                * 2. 주소 합치기
                * 3. 차량 등록 여부 판별 후 boolRegCar 설정하기기
                * 4. EndDate 기준으로 오늘을 넘겼으면 안내
               * */
                const today = new Date(Common.getTodayTime());
                today.setHours(0);
                today.setMinutes(0);
                const endDateTime = new Date(response.data.invite.endDatetime);
                //const endDate = response.data.invite.endDatetime;
                const startDate = response.data.invite.startDatetime;

                if( today > endDateTime ){
                    alert('방문 일자가 지난 초대장 입니다. 입주민에게 다시 초대장을 요청해주세요.');
                    setBoolPast(true);
                }

                const StartWeek = getDate(startDate)
                const StartBaseArr = startDate.split(" ");
                const StartDateArr = StartBaseArr[0].split("-");

                //const EndWeek = getDate(endDate)
                // const EndBaseArr = endDate.split(" ");
                // const EndDateArr = EndBaseArr[0].split("-");
                // const EndHour = EndBaseArr[1].split(":");

                const startDateString = StartWeek === undefined ? StartDateArr[0]+'.'+StartDateArr[1]+'.'+StartDateArr[2] : StartDateArr[0]+'.'+StartDateArr[1]+'.'+StartDateArr[2]+'('+StartWeek+')';
                //const endDateString = EndDateArr[0]+'.'+EndDateArr[1]+'.'+EndDateArr[2]+' '+EndHour[0]+'시';

                let address = '';
                const dong = response.data.invite.place.name.split("-");
                address = response.data.invite.site.name+' '+dong[0]+'동 '+dong[1]+'호'

                setInviteData({
                    inviteName: response.data.invite.inviteName,
                    inviteMessage: response.data.invite.inviteMessage,
                    startDate : startDateString,
                    //endDate : endDateString,
                    address: address,
                });

                if(response.data.visitorCarsResponse === null){
                    setBoolRegCar(false);
                    setCarNumber('');
                }else {
                    setBoolRegCar(true);
                    setCarNumber(response.data.visitorCarsResponse.carNumber);
                }
            } else {
                alert("취소된 초대장입니다. 입주민에게 다시 초대를 요청해주세요.");
                setBoolPast(true);
            }
        })
    },[]);

    useDidMountEffect(()=>{
        /* 등록 성공 여부*/
        setBoolLoading(true);
        Net.registerVisitorCar(carForm, response=>{
            setBoolLoading(false);
            if(response.status===200){
                history.push('/service/visitor/complete/'+match.params.visitorId);
            } else if (response.status === 409){
                alert('이미 차량이 등록 되었습니다.');
                window.location.reload();
            } else{
                alert('차량 등록에 실패하였습니다. 입주민에게 직접 방문 차량 등록을 요청해주세요. ');
            }
        })
    },[carForm]);

    const textHandler = (e) => {
        const pattern = /[^(가-힣ㄱ-ㅎㅏ-ㅣㆍᆢa-zA-Z0-9\-)]/gi;
        const tempCarNumber = e.target.value.replace(pattern,"");
        setCarNumber(tempCarNumber);
    };

    const submitHandler = ()=>{
        if(carNumber === ''){
            alert(' 차량번호를 입력해주세요.');
        }else{
            setCarForm({
                carNumber: carNumber.replace(" ",""),
                visitorId: match.params.visitorId
            })
        }
    }

    const deleteCarHandler = () => {
        const result = window.confirm('등록을 취소하시겠습니까?');

        if(result){
            setBoolDeleteLoading(true);
            Net.deleteVisitorCar(match.params, response => {
                setBoolDeleteLoading(false);
                if(response.status === 200){
                    setBoolRegCar(false);
                    setCarNumber('');
                } else if (response.status === 404 ){
                    alert('주차 관제 시스템 오류... 잠시 후 시도해주세요.');
                } else {
                    alert('방문 차량 취소에 실패하였습니다. 입주민에게 새로운 방문 차량 등록을 요청해주세요.');
                }
            })
        }
    }


    function getDate(date){
        const week = ['일', '월', '화', '수', '목', '금', '토'];

        let dayOfWeek = week[new Date(date).getDay()];

        return dayOfWeek;
    }

    return (
        <div className="page">
            <div className="text-center visitorRegist">
                <div className="cardTop">
                    <div className="flex" >
                        <div className="col-left">
                        <img src={logo}
                             className="hanulche"
                             alt="logo"/>
                        </div>
                        <div className="col-right text-header flex ">
                            <p className="whiteP "> 모바일 방문등록 서비스</p>
                            <p className="greyP "> 하늘채 방문을 환영합니다.</p>
                        </div>
                    </div>

                    <div className="flex-column card-invite px-3">
                        <div className="row text-center pt-4">
                            <img src={inviteImage}
                                 className="inviteImage"
                                 alt="logo"/>
                        </div>
                        <div className="text-container">
                            <div className="row">
                                <p className="inviteP">{inviteData.inviteName}네 초대</p>
                                <p className="descriptionP">{inviteData.inviteMessage}</p>
                            </div>
                        </div>
                        <div className="w-100 h-1px bgc-line"/>
                        <div className="flex pt-3 px-1">
                            <div className="col-left-bottom">
                                <p> 일정 </p>
                            </div>
                            <div className="col-right-bottom">
                                <p className=""> {inviteData.startDate}</p>
                            </div>
                        </div>
                        <div className="flex mt-1 mb-3 px-1">
                            <div className="col-left-bottom">
                                <p className=""> 주소 </p>
                            </div>
                            <div className="col-right-bottom">
                                <p className=""> {inviteData.address} </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="cardBottom px-4 shadow">
                    <div className="my-3 text-left flex-column">
                        <label className="headerLabel d-block">차량번호</label>
                        {
                            boolRegCar ?
                                <div className="alert alert-RegCar flex my-2 ">
                                    <div className="d-table">
                                        <p className="d-table-cell vertical-align-middle font-medium flex">
                                            {boolDeleteLoading ? <>등록 취소중...</> : carNumber}
                                        </p>
                                    </div>
                                    <div className="hoverbtn" disabled={boolDeleteLoading} onClick={deleteCarHandler}>
                                        <img src={minusIcon}
                                             className="alertMinusIcon"
                                             alt="logo"/>
                                    </div>
                                </div>
                                :
                                <>
                                    <input type="text" id="carNumber" name="carNumber" className="d-block w-100 px-3 py-1 mt-2 rounded input-carNumber"
                                           placeholder="띄어쓰기 없이 입력해 주세요. 예) 000가0000" value={carNumber}  disabled={boolPast || boolLoading} onChange={textHandler}  ref={inputFocus} />
                                </>
                        }
                        <div className="my-3 flex flex-row">
                            <img src={alertIcon}
                                 className="alertIcon"
                                 alt="logo"/>
                            <p className="alertP">차량은 한대만 등록 가능 합니다.</p>
                        </div>
                        <div>
                            <button className="btn btn-lg btn-block btn-register"
                                    onClick={submitHandler}
                                    disabled={boolRegCar || boolPast || boolLoading}
                            > {boolLoading ? <>등록중...</>
                                : (boolRegCar||boolPast) ? <>등록하기</> : <>등록하기</>}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default withRouter(VisitorRegist);
