import React, {useEffect, useState} from "react";
import {CustomTable, SearchInputBar, BasicModal} from 'components/molecules';
import Net from 'scripts/net';
import Common from 'scripts/common';


export const AttributesContent = (props) => {
    const modalColumnList = [
        {
            code : 'code',
            title: '코드',
            width: '5',
        },
        {
            code : 'value',
            title: '값',
            width: '5',
        },
        {
            code : 'name',
            title: '이름',
            width: '3',
        }
    ];
    const [reportedOperation, setReportedOperation] = useState([]);
    const [reportedAttributes, setReportedAttributes] = useState([]);
    const [desiredOperation, setDesiredOperation] = useState([]);
    const [desiredAttributes, setDesiredAttributes] = useState([]);

    useEffect(()=>{
        if(props.data.reported !== null){
            setReportedOperation(props.data.reported.operations);
            setReportedAttributes(props.data.reported.attributes);
        }
        if(props.data.desired !== null){
            setDesiredOperation(props.data.desired.operations);
            setDesiredAttributes(props.data.desired.attributes);
        }

    },[])
    return (
        <div>
            <h2 className="m-1 mt-2"> Desired </h2>
            <h5 className="m-3 ml-50"> 장비 행위 상태</h5>
            <CustomTable
                className="h-50"
                columnList={modalColumnList}
                contentData={desiredOperation}
            />
            <h5 className="m-3 ml-50"> 장비 속성 상태</h5>
            <CustomTable
                className="h-50"
                columnList={modalColumnList}
                contentData={desiredAttributes}
            />
            <h2 className="m-1"> Reported </h2>
            <h5 className="m-3 ml-50"> 장비 행위 상태</h5>
            <CustomTable
                className="h-50"
                columnList={modalColumnList}
                contentData={reportedOperation}
            />
            <h5 className="m-3 ml-50"> 장비 속성 상태</h5>
            <CustomTable
                className="h-50"
                columnList={modalColumnList}
                contentData={reportedAttributes}
            />
        </div>
    )
}
