import React, {Fragment, useEffect, useState} from 'react';
import classNames from 'classnames';
import CustomSelectBox from '../SelectBox/CustomSelectBox';
import DatePicker from "react-datepicker";
import './SearchInputBar.scss';

const settingStartdate = (datetype) => {
    const d = new Date();
    const dayOfMonth = d.getDate();

    if(datetype === "reservation"){
    }
    else if(datetype === "week"){
        d.setDate(dayOfMonth - 6);
    }
    else{
        d.setDate(dayOfMonth - 29);
    }

    return d;
}
const settingEnddate = (datetype) => {
    const d = new Date();
    const dayOfMonth = d.getDate();

    if(datetype === "reservation"){
        d.setDate(dayOfMonth + 30);
    }

    return d;
}

export const SearchInputBar = (props) => {

    const [state, setState] = useState({
        searchCategoryGroup: props.searchCategoryGroup || [],
        searchText: '',
        searchText2: '',
        searchText3: '',
        searchText4: '',
        searchText5: '',
        searchStartDate: '',
        searchEndDate: '',
    });

    const [selectedCategory, setSelectedCategory] = useState(props.searchCategory && (props.searchCategoryGroup[0][0].code || (props.searchCategoryGroup && props.searchCategoryGroup.map(() => ""))));
    const [startDate, setStartDate] = useState(settingStartdate(props.datetype));
    const [endDate, setEndDate] = useState(settingEnddate(props.datetype));

    useEffect(() => {
        setState({
            ...state,
            searchCategoryGroup: props.searchCategoryGroup
        })
    }, [props.searchCategoryGroup])

    useEffect(() => {
        props.dynamicSearch && onSubmit('selectbox');
    }, [selectedCategory])

    useEffect(() => {
        dateChangeHandler();
        if (props.searchDate) {
            props.searchDate(startDate, endDate);
        }
    }, [startDate, endDate])

    const onChangeHandler = (index, value) => {

        let newArr = [];


        if (props.relateCategoryHandler) {
            if (index === 0) {
                props.relateCategoryHandler(value ? value : "reset");
                newArr = [...selectedCategory];
                newArr[0] = value;
                if(newArr.length > 1){
                    newArr.forEach((item, index)=>{
                        if(index > 0){
                            newArr[index] = '';
                        }
                    })
                }
            } else if( index === 1){
                if(props.relateSiteCategoryHandler)
                    props.relateSiteCategoryHandler(value ? value : "reset");
                newArr = [...selectedCategory];
                newArr[index] = value;
                if(newArr.length > 2){
                    newArr.forEach((item, index)=>{
                        if(index > 1){
                            newArr[index] = '';
                        }
                    })
                }
            } else{
                newArr = [...selectedCategory];
                newArr[index] = value;
            }
        } else {
            newArr = [...selectedCategory];
            newArr[index] = value;
        }
        setSelectedCategory(newArr);
    }

    const onSubmit = (type) => {
        if (props.searchDate) {
            if (!startDate && !endDate) {

            } else if (!startDate) {
                alert("검색 시작일자를 입력해주세요.");
                return false;
            } else if (!endDate) {
                alert("검색 종료일자를 입력해주세요.");
                return false;
            }
        }

        if (type) {
            state.type = type;
        }
        props.onSubmit({
            ...state,
            selectedCategory
        });
    }

    const keyPressHandler = (e, type) => {
        if (e.key === 'Enter') {
            onSubmit(type);
        }
    }

    const dateChangeHandler = () => {
        if (startDate && endDate) {
            const startDateFormating = startDate.getFullYear() + '-' + ('0' + (Number(startDate.getMonth()) + 1)).slice(-2) + '-' + ('0' + startDate.getDate()).slice(-2);
            const endDateFormating = endDate.getFullYear() + '-' + ('0' + (Number(endDate.getMonth()) + 1)).slice(-2) + '-' + ('0' + endDate.getDate()).slice(-2);
            setState({
                ...state,
                searchStartDate: startDateFormating,
                searchEndDate: endDateFormating
            });
        } else {
            setState({
                ...state,
                searchStartDate: '',
                searchEndDate: ''
            });
        }
    }

    const resetHandler = (type) => {
        const resetState = {
            searchCategoryGroup: props.searchCategoryGroup || [],
            searchText: '',
            searchText2: '',
            searchText3: '',
            searchText4: '',
            searchText5: '',
            searchStartDate: '',
            searchEndDate: ''
        }
        setSelectedCategory(props.searchCategory &&( props.searchCategoryGroup[0][0].code || (props.searchCategoryGroup && props.searchCategoryGroup.map(() => ""))));
        setState(resetState);
        if (!props.dynamicSearch) {
            props.onSubmit({
                ...resetState,
                type: type
            });
        }
    }

    const loadDataHandler = (type) => {
        if(type === 'refresh'){
            props.refreshHandler();
        }
    }

    return (
        <div className={classNames('searchInput-wrapper', props.className, {'pdl-0' : !props.searchCategory})}>
            {
                props.dynamicSearch ?
                    (
                        <Fragment>
                            {props.searchCategory &&
                            state.searchCategoryGroup.map((data, index) => {
                                return (
                                    <CustomSelectBox
                                        key={index}
                                        className={index > 0 ? 'ms-1' : ''}
                                        categoryList={data}
                                        value={selectedCategory[index]}
                                        onChangeHandler={(value) => onChangeHandler(index, value)}
                                    />
                                )
                            })}

                            {props.searchText &&
                            <input
                                className={classNames('searchText',
                                    {
                                        'ml-2p': !props.searchCategory,
                                        'ml-10': props.searchCategory,
                                        'mr-10': props.searchBtn
                                    }
                                )}
                                type="text"
                                value={state.searchText}
                                placeholder={props.searchPlaceHolder}
                                onChange={(e) => setState({
                                    ...state,
                                    searchText: e.target.value
                                })}
                                onKeyPress={(e) => keyPressHandler(e, 'button')}
                            />}
                            {props.searchText2 &&
                            <input
                                className={classNames('searchText',
                                    {
                                        'mr-10': props.searchBtn
                                    }
                                )}
                                type="text"
                                value={state.searchText2}
                                placeholder={props.searchPlaceHolder2}
                                onChange={(e) => setState({
                                    ...state,
                                    searchText2: e.target.value
                                })}
                                onKeyPress={(e) => keyPressHandler(e, 'button')}
                            />}

                            {props.searchDate &&
                            <span className="searchDate">
                            <label>검색기간</label>
                            <span>
                                <DatePicker
                                    dateFormat="yyyy-MM-dd"
                                    selected={startDate}
                                    onChange={date => {
                                        setStartDate(date);
                                    }}
                                    className="w-100 textField"
                                />
                            </span>
                            <span className="ml-8 mr-8 m-auto"> ~ </span>
                            <span className="mr-10">
                                <DatePicker
                                    dateFormat="yyyy-MM-dd"
                                    selected={endDate}
                                    onChange={date => {
                                        setEndDate(date);
                                    }}
                                    className="w-100 textField"
                                />
                            </span>
                        </span>}

                            {props.searchBtn &&
                            <span style={{display: "inline-flex"}}>
                                    <button
                                        className={classNames('searchBtn',
                                            {
                                                'ml-10': !props.searchText
                                            }
                                        )}
                                        onClick={() => onSubmit('button')}
                                    >
                                        {props.searchBtnTitle}
                                    </button>
                                    <div className={"w-5"}/>
                                    <button className={classNames("searchBtn", "w-100px")} onClick={() => resetHandler('reset')}>검색 초기화</button>
                                </span>
                            }
                        </Fragment>
                    ) :
                    (
                        <Fragment>
                            {props.searchCategory &&
                            state.searchCategoryGroup.map((data, index) => {
                                return (
                                    <CustomSelectBox
                                        key={index}
                                        className={index > 0 ? 'ms-1' : ''}
                                        categoryList={data}
                                        value={selectedCategory[index]}
                                        onChangeHandler={(value) => onChangeHandler(index, value)}
                                    />
                                )
                            })}


                            {props.searchText &&
                            <input
                                className={classNames('searchText',
                                    {
                                        'ml-2p': !props.searchCategory,
                                        'ml-10': props.searchCategory,
                                        'mr-10': props.searchBtn,
                                        'sm-searchText' : props.smSearchText,
                                        'md-searchText' : props.mdSearchText,
                                    }
                                )}
                                type="text"
                                value={state.searchText}
                                placeholder={props.searchPlaceHolder}
                                onChange={(e) => setState({
                                    ...state,
                                    searchText: e.target.value
                                })}
                                onKeyPress={keyPressHandler}
                            />}

                            {props.searchText2 &&
                            <input
                                className={classNames('searchText',
                                    {
                                        'mr-10': props.searchBtn,
                                        'sm-searchText' : props.smSearchText2,
                                        'md-searchText' : props.mdSearchText2,
                                    }
                                )}
                                type="text"
                                value={state.searchText2}
                                placeholder={props.searchPlaceHolder2}
                                onChange={(e) => setState({
                                    ...state,
                                    searchText2: e.target.value
                                })}
                                onKeyPress={(e) => keyPressHandler(e, 'button')}
                            />}

                            {props.searchText3 &&
                            <input
                                className={classNames('searchText',
                                    {
                                        'mr-10': props.searchBtn,
                                        'sm-searchText' : props.smSearchText3,
                                        'md-searchText' : props.mdSearchText3,
                                    }
                                )}
                                type="text"
                                value={state.searchText3}
                                placeholder={props.searchPlaceHolder3}
                                onChange={(e) => setState({
                                    ...state,
                                    searchText3: e.target.value
                                })}
                                onKeyPress={(e) => keyPressHandler(e, 'button')}
                            />}

                            {props.searchText4 &&
                            <input
                                className={classNames('searchText',
                                    {
                                        'mr-10': props.searchBtn,
                                        'sm-searchText' : props.smSearchText4,
                                        'md-searchText' : props.mdSearchText4,
                                    }
                                )}
                                type="text"
                                value={state.searchText4}
                                placeholder={props.searchPlaceHolder4}
                                onChange={(e) => setState({
                                    ...state,
                                    searchText4: e.target.value
                                })}
                                onKeyPress={(e) => keyPressHandler(e, 'button')}
                            />}

                            {props.searchText5 &&
                                <input
                                    className={classNames('searchText',
                                        {
                                            'mr-10': props.searchBtn,
                                            'sm-searchText' : props.smSearchText5
                                        }
                                    )}
                                    type="text"
                                    value={state.searchText5}
                                    placeholder={props.searchPlaceHolder5}
                                    onChange={(e) => setState({
                                        ...state,
                                        searchText5: e.target.value
                                    })}
                                    onKeyPress={(e) => keyPressHandler(e, 'button')}
                                />}

                        </Fragment>
                    )
            }
            {!props.dynamicSearch && props.searchDate &&
            <span className="searchDate ml-10">
                    <label>검색기간</label>
                    <span>
                        <DatePicker
                            dateFormat="yyyy-MM-dd"
                            selected={startDate}
                            onChange={date => {
                                setStartDate(date);
                            }}
                            className="w-100 textField"
                        />
                    </span>
                    <span className="ml-8 mr-8 m-auto"> ~ </span>
                    <span className="mr-10">
                        <DatePicker
                            dateFormat="yyyy-MM-dd"
                            selected={endDate}
                            onChange={date => {
                                setEndDate(date);
                            }}
                            className="w-100 textField"
                        />
                    </span>
                </span>
            }
            {!props.dynamicSearch && props.searchBtn &&
                <span style={{display: "inline-flex"}}>
                    <button
                        className={classNames('searchBtn',
                            {
                                'ml-10': !props.searchText
                            }
                        )}
                        onClick={() => onSubmit('button')}
                    >
                        {props.searchBtnTitle}
                    </button>
                    <div className={"w-5"}/>
                    <button className={classNames("searchBtn", "w-100px")}
                            onClick={() => resetHandler('reset')}>검색 초기화</button>
                    {
                        props.refreshBtn && <button className={classNames("downloadBtn", "w-80px", "ms-2")}
                                                    onClick={() => loadDataHandler('refresh')}>새로고침</button>
                    }
                </span>
            }

        </div>
    )
}
