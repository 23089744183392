import React, { useState, useEffect } from 'react';
import { CustomTable, SearchInputBar, CustomExcelDownload } from 'components/molecules';
import store from 'scripts/store';
import Net from 'scripts/net';

const columnList = [
    {
        code: 'no',
        title: 'No',
        width: '5',
    },
    {
        code: 'createdDatetime',
        title: '생성일자',
        width: '8',
    },
    {
        code: 'category',
        title: '알림 유형',
        width: '8',
    },
    {
        code: 'type',
        title: '알림 타입',
        width: '10',
    },
    {
        code: 'destination',
        title: '수신 목적지',
        width: '5',
    },
    {
        code: 'message',
        title: '메시지',
        width: '8',
    },
    {
        code: 'appOsType',
        title: 'OS 타입',
        width: '8',
    },
    {
        code: 'appVersion',
        title: 'APP VER',
        width: '6',
    },
    {
        code: 'deviceVersion',
        title: 'Device VER',
        width: '6',
    },
    {
        code: 'sender',
        title: '보내는 사람',
        width: '5',
    },
    {
        code: 'username',
        title: '수신자ID',
        width: '8',
    },
];

export const NotificationHistoryList = (props) => {


    const [contentData, setContentData] = useState([]);
    const [contentData4Excel, setContentData4Excel] = useState([]);
    const [searchCategory, setSearchCategory] = useState([
        [
            [
                 {code: '', name: '고객사 선택'}
            ]
        ],
        [
            [
                {code: '', name: '현장명 선택'}
            ]
        ],
        [
            [
                {code: '', name: '알림 유형 명'},
                {code: 'EMERGENCY', name: '비상 알림'},
                {code: 'ANNOUNCEMENT', name: '공지사항'},
                {code: 'PARCEL', name: '무인택배'},
                {code: 'CAR', name: '차량입출차'},
                {code: 'ENERGY', name: '에너지 사용량'},
                {code: 'PARKING', name: '주차 위치'},
                {code: 'RESERVATION', name: '예약'},
                {code: 'VISITOR', name: '방문자'},
                {code: 'ELEVATOR', name: '엘리베이터 호출'},
                {code: 'DEVICE', name: '기기제어'},
                {code: 'AUTOMATION', name: '자동화 모드'},
                {code: 'INFORMATION', name: '정보 알림'},
                {code: 'QNA', name: 'QNA 답변 알림'},
                {code: 'VOTE', name: '투표'},
                {code: 'DUPLICATIONLOGIN', name: '중복 로그인'},
            ]
        ],
        [
            [
                {code: '', name: '알림 타입 명'}
            ]
        ],
    ]);

    const [searchState, setSearchState] = useState({
        rowPerPage: 13,
        searchText: '',
        searchStartDate: '',
        searchEndDate: '',
        searchCategory1: '',
        searchCategory2: '',
        searchCategory3: '',
        searchCategory4: '',
        currentPage: 0,
    });

    const [pageState, setPageState] = useState({
        totalElementsCnt: 0,
        drawElementsCnt: 0,
    })

    const [programAuth, setProgramAuth] = useState({});

    useEffect(() => {
        store.openedPage = '알림 이력 목록';
        getCategoryList('');
        pageAuthInfo();
    }, []);

    useEffect(() => {
        loadData()
    }, [searchState.currentPage,
        searchState.searchCategory1,
        searchState.searchCategory2,
        searchState.searchCategory3,
        searchState.searchCategory4,
        searchState.searchStartDate,
        searchState.searchEndDate,
        searchState.searchText,
]);

    const pageAuthInfo = () => {
        const authGroupCode = props.userInfo.authList[0];
        const menuId = sessionStorage.getItem('menuId');

        Net.getAuthGroupMenuList(menuId, (response) => {
            if(response.status === 200) {
                response.data.forEach(item => {
                    if(item.authGroup === authGroupCode && item.authGroupMenu) {
                        setProgramAuth({
                            searchAuth: Boolean(item.authGroupMenu.searchAuth),
                            saveAuth: Boolean(item.authGroupMenu.saveAuth),
                            excelAuth: Boolean(item.authGroupMenu.excelAuth),
                            deleteAuth: Boolean(item.authGroupMenu.deleteAuth),
                        });
                    }
                });
            }
        });
    }

    const loadData = () => {
        Net.getNotificationLogsList(searchState, (response) => {
            if(response.status === 200) {
                if(response.data.content) {
                    setContentData(response.data.content);
                    setPageState({
                        totalElementsCnt: response.data.totalElements,
                        drawElementsCnt: response.data.numberOfElements,
                    });
                } else {
                    setContentData([]);
                    setPageState({
                        totalElementsCnt: 0,
                        drawElementsCnt: 0,
                    });
                }
            } else if(response.status === 204 ){
                setContentData([]);
                setPageState({
                    totalElementsCnt: 0,
                    drawElementsCnt: 0,
                });
            }
        });

        // 엑셀 다운로드 시 사용
        // const copiedPageState = JSON.parse(JSON.stringify(searchState));
        // copiedPageState.rowPerPage = 999999;
        // Net.getNotificationLogsList(copiedPageState, (response) => {
        //     if(response.status === 200) {
        //         if(response.data.content) {
        //             setContentData4Excel(response.data.content);
        //         } else {
        //             setContentData4Excel([]);
        //         }
        //     }
        // });
    }


    const getNotificationTypeList = (callback) => {
        Net.getSystemCode(
            "NOTIFICATION_TYPE", (response) =>{
                if(response.status === 200 ){
                    let newArray = [{code:'', name:"타입 선택"}];
                    response.data.forEach(items =>{
                        if(items.usable){
                            newArray = [
                                ...newArray,
                                {
                                    code: items.code,
                                    name: items.name
                                }
                            ]
                        }
                    })
                    callback({status: 200, data:newArray});
                }
            }
        )
    }

    const getCategoryList = () => {
        let response4 = [];

        getNotificationTypeList(response5 => {
            if (response5.status === 200) {
                response4 = response5.data;
                getCustomerList((response1) => {
                    getSitesList(null, (response2) => {
                        setSearchCategory([response1, response2, ...searchCategory[2], response4]);
                    });
                });
            }
        });
    }

    const getRelateCategory = (selectedCategory) => {
        if(selectedCategory === "reset") selectedCategory = "";
        getSitesList(selectedCategory, (response2) => {
            setSearchCategory([
                searchCategory[0],
                response2,
                searchCategory[2],
                searchCategory[3],
            ]);
        });
    }

    const getCustomerList = (callback) => {
        Net.getCustomerList(
            {currentPage: '', rowPerPage: '', searchText: ''}, (response) => {
                if(response.status === 200) {
                    let newArray = searchCategory[0][0];
                    response.data.content.forEach(items => {
                        newArray = [
                            ...newArray,
                            {
                                code: items.code,
                                name: items.name
                            }
                        ]
                    });
                    callback(newArray);
                }
            });
    }

    const getSitesList = (selectedCategory, callback) => {
        if(selectedCategory){
            Net.getSitesList(
                {currentPage: '', rowPerPage: '', searchText: '', customerCode: selectedCategory?selectedCategory:false}, (response) => {
                    if(response.status === 200) {
                        let newArray =  [{code:'',name:"현장명 선택"}];
                        if(response.data.content.length > 0) {
                            response.data.content.forEach(items => {
                                newArray = [
                                    ...newArray,
                                    {
                                        code: items.code,
                                        name: items.name
                                    }
                                ]

                            })
                        }
                        callback(newArray);
                    } else {
                        let newArray = [{code:'',name:"현장명 선택"}];
                        callback(newArray);
                    }
                });
        } else {
            let newArray = [{code:'',name:"현장명 선택"}];
            callback(newArray);
        }
    }

    const onMovePage = (e) => {
        setSearchState({
            ...searchState,
            currentPage: e-1
        });
    }


    const onSubmitHandler = (e) => {
        if (e.selectedCategory[3] === '') {
            alert("타입 선택 값은 필수입니다.")
        } else {
            setSearchState({
                ...searchState,
                searchCategory1: e.selectedCategory[0],
                searchCategory2: e.selectedCategory[1],
                searchCategory3: e.selectedCategory[2],
                searchCategory4: e.selectedCategory[3],
                searchText: e.searchText
            });
        }
    }

    const searchDateHandler = (startDate, endDate) => {
    startDate = startDate.getFullYear() + '-' + ('0' + (Number(startDate.getMonth()) + 1)).slice(-2) + '-' + ('0' + startDate.getDate()).slice(-2) + ' 00:00';
    endDate = endDate.getFullYear() + '-' + ('0' + (Number(endDate.getMonth()) + 1)).slice(-2) + '-' + ('0' + endDate.getDate()).slice(-2)+ ' 23:59';

    if(!searchState.searchStartDate && !searchState.searchEndDate) {
        searchState.searchStartDate = startDate;
        searchState.searchEndDate = endDate;
        } else {
            setSearchState({
                ...searchState,
                searchStartDate: startDate,
                searchEndDate: endDate,
            });
        }
    }

    return (
        <div className="w-100 h-100">
            <div className="w-100 h-7 display_table">
                <SearchInputBar
                    className="w-100"
                    searchCategory={true}
                    searchCategoryGroup={searchCategory}
                    searchText={true}
                    searchPlaceHolder="사용자ID를 입력 (미개발)."
                    searchBtn={true}
                    searchBtnTitle="검색"
                    dynamicSearch={false}
                    searchDate={searchDateHandler}
                    relateCategoryHandler={getRelateCategory}
                    onSubmit={onSubmitHandler}
                />
            </div>
            <div className="w-100 h-1px bgC-efefef" />
            <div className="flex">
                <div className="w-100 h-93 overflowYscroll">
                    <p className="totalCnt">Total {pageState.totalElementsCnt}</p>
                    {
                        programAuth.excelAuth &&
                        <CustomExcelDownload
                            className="downloadBtn w-90px h-30px floatR mt-7 mr-20"
                            sheetTitle="device_history_list"
                            columnList={columnList}
                            contentData4Excel={contentData4Excel}
                            searchState={searchState}
                        />
                    }
                    <CustomTable
                        columnList = {columnList}
                        contentData = {contentData}
                        checkbox = {false}
                        paging = {true}
                        totalElementsCnt = {pageState.totalElementsCnt}
                        rowPerPage = {searchState.rowPerPage}
                        currentPage = {searchState.currentPage}
                        drawElementsCnt = {pageState.drawElementsCnt}
                        pagination = {onMovePage}
                        rowClickEvt = {false}
                    />
                </div>
            </div>
        </div>
    )
}
