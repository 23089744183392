/* eslint-disable */
// noinspection RegExpDuplicateCharacterInClass,RegExpRedundantEscape

import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { CustomTable, SearchInputBar, BasicModal, CustomTextField, CustomRadioButton } from 'components/molecules';
import store from 'scripts/store';
import Net from 'scripts/net';

const columnList = [
    {
        code: 'code',
        title: '유형코드',
        width: '45',
    },
    {
        code: 'name',
        title: '유형명',
        width: '35',
    },
    {
        code: 'usable',
        title: '사용여부',
        width: '20',
    },
];

const columnList2 = [
    {
        code: 'id',
        title: '',
        width: '',
        hidden: true,
    },
    {
        code: 'code',
        title: '코드',
        width: '20',
    },
    {
        code: 'name',
        title: '코드명',
        width: '10',
    },
    {
        code: 'sort',
        title: '정렬순서',
        width: '5',
    },
    {
        code: 'attribute1',
        title: '속성1',
        width: '8',
    },
    {
        code: 'attribute2',
        title: '속성2',
        width: '8',
    },
    {
        code: 'attribute3',
        title: '속성3',
        width: '8',
    },
    {
        code: 'attribute4',
        title: '속성4',
        width: '8',
    },
    {
        code: 'attribute5',
        title: '속성5',
        width: '8',
    },
    {
        code: 'usable',
        title: '사용여부',
        width: '5',
    },
];

export const CodeManageList = (props) => {

    // useHistory 선언
    const history = useHistory();

    const [contentData, setContentData] = useState([]);
    const [contentData2, setContentData2] = useState([]);

    const [pageState, setPageState] = useState({
        currentPage: 0,
        rowPerPage: 13,
        totalElementsCnt: 0,
        drawElementsCnt: 0,
        searchText: '',
    });

    const [codeType, setCodeType] = useState('');
    const [codeId, setCodeId] = useState('');
    const [modalState, setModalState] = useState({
        open: false,
        data: [],
    });

    const [programAuth, setProgramAuth] = useState({});

    useEffect(() => {
        store.openedPage = '코드 목록';
        pageAuthInfo();
    }, []);

    useEffect(() => {
        loadData();
    }, [pageState.currentPage, pageState.searchText]);

    useEffect(() => {
        if(codeType !== '') {
            loadData2();
        }
    }, [codeType]);

    const pageAuthInfo = () => {
        const authGroupCode = props.userInfo.authList[0];
        const menuId = sessionStorage.getItem('menuId');

        Net.getAuthGroupMenuList(menuId, (response) => {
            if(response.status === 200) {
                response.data.forEach(item => {
                    if(item.authGroup === authGroupCode && item.authGroupMenu) {
                        setProgramAuth({
                            searchAuth: Boolean(item.authGroupMenu.searchAuth),
                            saveAuth: Boolean(item.authGroupMenu.saveAuth),
                            excelAuth: Boolean(item.authGroupMenu.excelAuth),
                            deleteAuth: Boolean(item.authGroupMenu.deleteAuth),
                        });
                    }
                });
            }
        });
    }

    const loadData = () => {
        Net.getCodeTypeList(pageState, (response) => {
            if(response.status === 200) {
                setContentData(response.data.content);
                setPageState({
                    ...pageState,
                    totalElementsCnt: response.data.totalElements,
                    drawElementsCnt: response.data.numberOfElements,
                });
            } else if(response.status === 204) {
                setContentData([]);
                setPageState({
                    ...pageState,
                    totalElementsCnt: 0,
                    drawElementsCnt: 0,
                });
            }
            setContentData2([]);
            setCodeType('');
            setCodeId('');
        });
    }

    const loadData2 = () => {
        Net.getCodeList(codeType, (response) => {
            if(response.status === 200) {
                if(response.data.length > 0) {
                    setContentData2(response.data);
                } else {
                    setContentData2([]);
                }
            }
        });
    }

    const onMovePage = (e) => {
        setPageState({
            ...pageState,
            currentPage: e-1
        });
    }

    const onSubmitHandler = (e) => {
        setPageState({
            ...pageState,
            currentPage: 0,
            searchText: e.searchText
        });
    }

    const addList = () => {
        if(codeType === '') {
            alert('유형코드를 선택해주세요.');
            return false;
        }
        setCodeId('');
        onClickModalOpen();
    }

    const onClickRow = (e) => {
        setCodeType(e.code);
    }

    const onClickRow2 = (e) => {
        setCodeId(e.id);
        onClickModalOpen();
    }

    const onClickModalOpen = () => {
        setModalState({
            ...modalState,
            open: !modalState.open,
        });
    }

    const onClickModalClose = () => {
        setModalState({
            ...modalState,
            open: !modalState.open,
        });
        loadData2();
    }

    const removeCode = () => {
        const result = window.confirm('삭제하시겠습니까?');
        const removeCodeList = [];
        // checkedList.map((id) => {
        //     const node = document.querySelector(`#${id}`);
        //     const nodeTr = node.closest('tr');
        //     const codeValue = nodeTr.querySelector('td[name=code]').textContent;
        //     removeCodeList.push(codeValue);
        // });

        // if(result) {
        //     Net.removeCode(codeId, (response) => {
        //         if(response) {
        //             loadData();
        //         }
        //     });
        // }
    }


    return (
        <div className="w-100 h-100">
            <BasicModal
                className=""
                title="코드 등록/수정"
                content={
                    <ModalContent
                        codeType={codeType}
                        codeId={codeId}
                        modalClose={onClickModalClose}
                    />
                }
                modalOpen={modalState.open}
                modalClose={(e) => setModalState({
                    ...modalState,
                    open: e
                })}
            />
            <div className="w-100 h-7 display_table">
                <SearchInputBar
                    className="w-100"
                    searchCategory={false}
                    searchCategoryGroup=""
                    searchText={true}
                    searchPlaceHolder="유형명을 입력해 주세요."
                    searchBtn={true}
                    searchBtnTitle="검색"
                    dynamicSearch={false}
                    onSubmit={onSubmitHandler}
                />
                {
                    programAuth.saveAuth &&
                    <div className="display_table_cell v-middle">
                        <button
                            className="addBtn w-90px h-30px floatR mr-20"
                            onClick={addList}
                        >등록</button>
                    </div>
                }
            </div>
            <div className="w-100 h-1px bgC-efefef" />
            <div className="flex">
                <div className="w-30 h-93 overflowYscroll">
                    <p className="body_content_subtitle">유형코드 목록</p>
                    <CustomTable
                        columnList = {columnList}
                        contentData = {contentData}
                        checkbox = {false}
                        paging = {true}
                        totalElementsCnt = {pageState.totalElementsCnt}
                        rowPerPage = {pageState.rowPerPage}
                        currentPage = {pageState.currentPage}
                        drawElementsCnt = {pageState.drawElementsCnt}
                        pagination = {onMovePage}
                        rowClickEvt = {true}
                        onClickRow = {onClickRow}
                        // sort = {true}
                    />
                </div>
                <div className="w-70 h-93 overflowYscroll">
                    <p className="body_content_subtitle">코드 목록</p>
                    <CustomTable
                        columnList = {columnList2}
                        contentData = {contentData2}
                        checkbox = {false}
                        paging = {false}
                        rowClickEvt = {true}
                        onClickRow = {onClickRow2}
                        // sort = {true}
                    />
                </div>
            </div>
        </div>
    )
}

export const ModalContent = (props) => {

    const { codeType, codeId } = props;

    const [form, setForm] = useState({
        codeType: codeType,
        code: '',
        name: '',
        sort: '',
        attribute1: '',
        attribute2: '',
        attribute3: '',
        attribute4: '',
        attribute5: '',
        usable: true,
    });
    const [registPage, setRegistPage] = useState(true);

    useEffect(() => {
        if(codeId !== "") {
            Net.getCodeOne(codeId, (response) => {
                if(response.status === 200) {
                    setForm({
                        ...form,
                        ...response.data,
                    });
                    // 등록페이지 여부 확인
                    setRegistPage(false);
                }
            });
        }
    }, []);

    const textFieldHandler = (name, value) => {
        const nextForm = {
            ...form,
            [name]: value
        }
        setForm(nextForm);
    }

    const radioBtnHandler = (name, value) => {
        const nextForm = {
            ...form,
            [name]: value === 'true' ? true : false,
        }
        setForm(nextForm);
    }

    const onSubmitHandler = () => {
        if(form.code === '') {
            alert("코드를 입력해주세요.");
            document.getElementsByName('code')[0].focus();
            return false;
        }
        if(form.name === '') {
            alert("코드명을 입력해주세요.");
            document.getElementsByName('name')[0].focus();
            return false;
        }
        if(!form.sort){
            alert("정렬순서를 입력해주세요.");
            return false;
        }

        var result = window.confirm('저장하시겠습니까?');
        if(result) {
            if(registPage) {
                Net.addCode(form, (response) => {
                    if(response.status === 200  || response.status === 201) {
                        onCancelHandler();
                    } else if(response.status === 409){
                        alert('이미 등록된 코드 입니다.');
                    }
                });
            } else {
                Net.updateCode(form, (response) => {
                    if(response.status === 200) {
                        onCancelHandler();
                    }
                });
            }
        }
    }

    const onRemoveHandler = () => {
        const result = window.confirm('삭제하시겠습니까?');
        if(result) {
            Net.removeCode(codeId, (response) => {
                if(response) {
                    onCancelHandler();
                }
            });
        }
    }

    const onCancelHandler = () => {
        props.modalClose();
    }


    return (
        <div className="w-100 h-100">
            <div className="h-90" style={{display:"contents"}}>
                <div className="flex mb-12 w-100">
                    <div className="flex mb-12 w-50">
                        <CustomTextField
                            className="w-50 h-26px"
                            title="코드"
                            name="code"
                            required={true}
                            disabled={registPage ? false : true}
                            placeholder=""
                            customOnChange={textFieldHandler}
                            value={form}
                        />
                    </div>
                    <div className="flex mb-12 w-50">
                        <CustomTextField
                            className="w-50 h-26px"
                            title="코드명"
                            name="name"
                            required={true}
                            disabled={false}
                            placeholder=""
                            customOnChange={textFieldHandler}
                            value={form}
                        />
                    </div>
                </div>
                <div className="flex mb-24 w-100">
                    <CustomTextField
                        className="w-25 h-26px"
                        title="정렬순서"
                        name="sort"
                        validRegExp={/[ㄱ-ㅎ|ㅏ-ㅣ|가-힣|a-zA-Z\{\}\[\]\/?.,;:|\)*~`!^\-+<>@\#$%&\\\=\(\'\"]/g}
                        required={true}
                        disabled={false}
                        placeholder=""
                        customOnChange={textFieldHandler}
                        value={form}
                    />
                </div>
                <div className="flex mb-12 w-100">
                    <div className="flex mb-12 w-50">
                        <CustomTextField
                            className="w-50 h-26px"
                            title="속성1"
                            name="attribute1"
                            required={false}
                            disabled={false}
                            placeholder=""
                            customOnChange={textFieldHandler}
                            value={form}
                        />
                    </div>
                    <div className="flex mb-12 w-50">
                        <CustomTextField
                            className="w-50 h-26px"
                            title="속성2"
                            name="attribute2"
                            required={false}
                            disabled={false}
                            placeholder=""
                            customOnChange={textFieldHandler}
                            value={form}
                        />
                    </div>
                </div>
                <div className="flex mb-12 w-100">
                    <div className="flex mb-12 w-50">
                        <CustomTextField
                            className="w-50 h-26px"
                            title="속성3"
                            name="attribute3"
                            required={false}
                            disabled={false}
                            placeholder=""
                            customOnChange={textFieldHandler}
                            value={form}
                        />
                    </div>
                    <div className="flex mb-12 w-50">
                        <CustomTextField
                            className="w-50 h-26px"
                            title="속성4"
                            name="attribute4"
                            required={false}
                            disabled={false}
                            placeholder=""
                            customOnChange={textFieldHandler}
                            value={form}
                        />
                    </div>
                </div>
                <div className="flex mb-12 w-100">
                    <CustomTextField
                        className="w-25 h-26px"
                        title="속성5"
                        name="attribute5"
                        required={false}
                        disabled={false}
                        placeholder=""
                        customOnChange={textFieldHandler}
                        value={form}
                    />
                </div>
                <div className="flex w-100 mb-12">
                    <label className="label_title">사용유무*</label>
                    <CustomRadioButton
                        className="mr-60"
                        title="사용"
                        id="radioBtn1"
                        name="usable"
                        value={true}
                        checked={form.usable}
                        customOnChange={radioBtnHandler}
                    />
                    <CustomRadioButton
                        title="사용안함"
                        id="radioBtn2"
                        name="usable"
                        value={false}
                        checked={!form.usable}
                        customOnChange={radioBtnHandler}
                    />
                </div>
            </div>
            <div className="footer_btn h-10 mt-30 mb-30">
                <div className="">
                    <button className="w-80px h-30px addBtn mr-10" onClick={onSubmitHandler}>저장</button>
                    { registPage ? "" : <button className="w-80px h-30px removeBtn mr-10" onClick={onRemoveHandler}>삭제</button>}
                    <button className="w-80px h-30px cancelBtn" onClick={onCancelHandler}>취소</button>
                </div>
            </div>
        </div>
    )
}
