import React, { useEffect, useState } from 'react'
import { withRouter, useHistory } from 'react-router-dom';
import { CustomTextField, CustomRadioButton, CustomSelectBox, CustomCheckbox, BasicModal, CustomTable } from 'components/molecules';
import store from 'scripts/store';
import Net from 'scripts/net';

const ToolsDeviceRegist = ({match}) => {
    
    const history = useHistory();

    const [form, setForm] = useState({
        name: '',
        deviceId: '',
        serialNumber: '',
        model: {},
        usable: true,
    });
    const [registPage, setRegistPage] = useState(true);
    const [modalState, setModalState] = useState({
        open: false,
        data: [],
    });

    useEffect(() => {
        store.openedPage = '디바이스 등록/수정';

        if(match.params.deviceId !== undefined) {
            Net.getToolsDeviceOne(match.params.deviceId, (response) => {
                if(response.status === 200) {
                    setForm({
                        ...form,
                        ...response.data,
                    });
                    // 등록페이지 여부 확인
                    setRegistPage(false);
                }
            });
        }
    }, [])

    const textFieldHandler = (name, value) => {
        const nextForm = {
            ...form,
            [name]: value
        }
        setForm(nextForm);
    }

    const radioBtnHandler = (name, value) => {
        const nextForm = {
            ...form,
            [name]: value === 'true' ? true : false,
        }
        setForm(nextForm);
    }

    const modelChoiceHandler = (name) => {
        onClickModalOpen();
    }

    const onSubmitHandler = () => {
        
        var result = window.confirm('저장하시겠습니까?');
        if(result) {
            if(registPage) {
                Net.addToolsDevice(form, (response) => {
                    if(response.status === 200) {
                        history.push('/tools/device/list');
                    }
                });
            } else {
                Net.updateToolsDevice(form, (response) => {
                    if(response.status === 200) {
                        history.push('/tools/device/list');
                    }
                });
            }
        }
    }

    const onCancelHandler = () => {
        history.push('/tools/device/list');
    }

    // Open Modal and setClickedBtnName
    const onClickModalOpen = () => {
        setModalState({
            ...modalState,
            open: !modalState.open,
        });
    }

    const sendData = (e) => {
        setModalState({
            ...modalState,
            data: e,
        })
    }

    const rcvData = (e) => {
        setForm({
            ...form,
            model: {
                ...e[0],
            }
        });
    }

    const onRemoveHandler = () => {
        const result = window.confirm('삭제하시겠습니까?');
        if(result) {
            Net.removeToolsDeviceList([form.deviceId], (response) => {
                if(response) {
                    history.push('/tools/device/list');
                }
            });
        }
    }

    return (
        <div className="w-100 h-100">
            <BasicModal 
                className="w-70 h-67"
                title="장비모델"
                content={<ModalContent sendData={sendData} formData={form} />}
                modalOpen={modalState.open}
                modalClose={(e) => setModalState({
                    ...modalState,
                    open: e
                })}
                sendData={modalState.data}
                rcvData={rcvData}
                activeCloseBtn={true}
            /> 
            <div className="w-100 h-7 display_table"></div>
            <div className="w-100 h-1px bgC-efefef" />
            <div className="h-93">
                <div className="flex h-90">
                    <div className="w-60 h-100">
                        <div className="ml-55 mt-12">
                            <div className="flex mb-12 w-90">
                                <CustomTextField 
                                    className="w-80 h-26px"
                                    title="디바이스명"
                                    name="name"
                                    required={true}
                                    disabled={false}
                                    customOnChange={textFieldHandler}
                                    value={form}
                                />
                            </div>
                            <div className="flex mb-12 w-90">
                                <CustomTextField 
                                    className="w-80 h-26px"
                                    title="디바이스ID"
                                    name="deviceId"
                                    required={true}
                                    disabled={true}
                                    value={form}
                                />
                            </div>
                            <div className="flex mb-12 w-90">
                                <CustomTextField 
                                    className="w-80 h-26px"
                                    title="serialNumber"
                                    name="serialNumber"
                                    required={true}
                                    disabled={false}
                                    customOnChange={textFieldHandler}
                                    value={form}
                                />
                            </div>
                            <div className="flex mb-12 w-90">
                                <CustomTextField 
                                    className="w-80 h-26px"
                                    title="모델명"
                                    name="model.name"
                                    required={true}
                                    disabled={false}
                                    readOnly={true}
                                    customOnClick={modelChoiceHandler}
                                    value={form}
                                />
                            </div>
                            <div className="flex mb-12 w-90">
                                <CustomTextField 
                                    className="w-80 h-26px"
                                    title="타입명"
                                    name="model.type.name"
                                    required={true}
                                    disabled={true}
                                    readOnly={true}
                                    value={form}
                                />
                            </div>
                            <div className="flex mb-12 w-90">
                                <CustomTextField 
                                    className="w-80 h-26px"
                                    title="제조사명"
                                    name="model.manufacturer.name"
                                    required={true}
                                    disabled={true}
                                    readOnly={true}
                                    value={form}
                                />
                            </div>
                            <div className="flex w-90">
                                <label className="label_title">사용유무</label>
                                <CustomRadioButton
                                    className="mr-60"
                                    title="사용"
                                    id="radioBtn1"
                                    name="usable"
                                    value={true}
                                    checked={form.usable}
                                    customOnChange={radioBtnHandler}
                                />
                                <CustomRadioButton 
                                    title="사용안함"
                                    id="radioBtn2"
                                    name="usable"
                                    value={false}
                                    checked={!form.usable}
                                    customOnChange={radioBtnHandler}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="w-40 h-100">
                        <div className="w-100 h-50 mt-12 overflowYscroll">
                            <table className="mainTable">
                                <colgroup>
                                    <col width="10%"/>
                                    <col width="45%"/>
                                    <col width="30%"/>
                                    <col width="30%"/>
                                </colgroup>
                                <thead className="bgC-c5c5c5">
                                    <tr>
                                        <th colSpan="4" className="tableHead fixedHeader t-0px">
                                            장비행위
                                        </th>
                                    </tr>
                                    <tr>
                                        <th className="v-middle tableHead fixedHeader t-36px"><span className="h-24px">No</span></th>
                                        <th className="v-middle tableHead fixedHeader t-36px"><span className="h-24px">속성코드</span></th>
                                        <th className="v-middle tableHead fixedHeader t-36px"><span className="h-24px">속성명</span></th>
                                        <th className="v-middle tableHead fixedHeader t-36px"><span className="h-24px">속성값</span></th>
                                    </tr>
                                </thead>
                                <tbody className="tableBody">
                                    {
                                        form.model.operations && form.model.operations.length > 0 ?
                                        (
                                            form.model.operations.map((data, index) => {
                                                return(
                                                    <tr key={index}>
                                                        <td>{index+1}</td>
                                                        <td>{data.code}</td>
                                                        <td>{data.name}</td>
                                                        <td>
                                                            {
                                                                data.valueType === 'ENUMERATION' ?
                                                                (data.availableValues !== undefined && data.availableValues.map((value, index) => {
                                                                    if(data.availableValues.length-1 === index) {
                                                                        return value;
                                                                    } else {
                                                                        return value+=', ';
                                                                    }
                                                                })) :
                                                                (data.bottomValue +' ~ '+ data.topValue)
                                                            }
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        )
                                        :
                                        (<tr><td colSpan="4">조회 된 데이터가 없습니다.</td></tr>)
                                    }
                                </tbody>
                            </table>
                        </div>

                        <div className="w-100 h-50 mt-12 overflowYscroll">
                            <table className="mainTable">
                                <colgroup>
                                    <col width="10%"/>
                                    <col width="45%"/>
                                    <col width="30%"/>
                                    <col width="30%"/>
                                </colgroup>
                                <thead className="bgC-c5c5c5">
                                    <tr>
                                        <th colSpan="4" className="tableHead fixedHeader t-0px">
                                            장비정보
                                        </th>
                                    </tr>
                                    <tr>
                                        <th className="v-middle tableHead fixedHeader t-36px"><span className="h-24px">No</span></th>
                                        <th className="v-middle tableHead fixedHeader t-36px"><span className="h-24px">속성코드</span></th>
                                        <th className="v-middle tableHead fixedHeader t-36px"><span className="h-24px">속성명</span></th>
                                        <th className="v-middle tableHead fixedHeader t-36px"><span className="h-24px">속성값</span></th>
                                    </tr>
                                </thead>
                                <tbody className="tableBody">
                                    {
                                        form.model.attributes && form.model.attributes.length > 0 ?
                                        (
                                            form.model.attributes.map((data, index) => {
                                                return(
                                                    <tr key={index}>
                                                        <td>{index+1}</td>
                                                    <td>{data.code}</td>
                                                    <td>{data.name}</td>
                                                    <td>
                                                        {
                                                            data.valueType === 'ENUMERATION' ?
                                                            (data.availableValues !== undefined && data.availableValues.map((value, index) => {
                                                                if(data.availableValues.length-1 === index) {
                                                                    return value;
                                                                } else {
                                                                    return value+=', ';
                                                                }
                                                            })) :
                                                            (data.bottomValue +' ~ '+ data.topValue)
                                                        }
                                                    </td>
                                                    </tr>
                                                )
                                            })
                                        )
                                        :
                                        (<tr><td colSpan="4">조회 된 데이터가 없습니다.</td></tr>)
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div className="footer_btn h-10">
                    <button className="w-160px h-30px addBtn mr-10" onClick={onSubmitHandler}>저장</button>
                    { registPage ? "" : <button className="w-160px h-30px removeBtn mr-10" onClick={onRemoveHandler}>삭제</button>}
                    <button className="w-160px h-30px cancelBtn" onClick={onCancelHandler}>취소</button>
                </div>
            </div>
        </div>
    )
}

export default withRouter(ToolsDeviceRegist);

const modalColumnList = [
    {
        code: 'modelId',
        title: '모델ID',
        width: '20',
    },
    {
        code: 'name',
        title: '모델명',
        width: '20',
    },
    {
        code: 'type.name',
        title: '타입명',
        width: '20',
    },
    {
        code: 'manufacturer.name',
        title: '제조사명',
        width: '20',
    },
]

export const ModalContent = (props) => {

    const [firstCheckedData, setFirstCheckedData] = useState([]);
    const [modelList, setModelList] = useState([]);
    const [modelCheckedItem, setModelCheckedItem] = useState([]);

    useEffect(() => {
        Net.getToolsModelList(
            {
                currentPage: '', 
                rowPerPage: '', 
                searchText: '', 
                manufacturer: {
                    code: '',
                },
                type: {
                    code: '',
                },
            }, (response) => {
            if(response.status === 200) {
                

                const rowIdxList = [];
                response.data.content.map((data, index) => {
                    const modelId = data.modelId;

                    if(props.formData.model.modelId === modelId) {
                        rowIdxList.push('row_'+index);
                    }
                    // switch (props.modalState.clickedBtnName) {
                    //     case 'operations':
                    //         if(operationsCodeList.indexOf(code) != -1) {
                    //             rowIdxList.push('row_'+index);
                    //         }
                    //         break;
                    //     case 'attributes':
                    //         if(attributesCodeList.indexOf(code) != -1) {
                    //             rowIdxList.push('row_'+index);
                    //         }
                    //         break;
                    
                    //     default:
                    //         break;
                    // }
                });

                setModelList(response.data.content);
                setFirstCheckedData(rowIdxList);
            }
        });
    }, [])

    useEffect(() => {
        if(modelCheckedItem.length > 0) {
            const sendData = [];
            const node = document.querySelector(`#${modelCheckedItem[0]}`);
            const nodeTr = node.closest('tr');
            const modelIdValue = nodeTr.querySelector('td[name=modelId]').textContent;
            modelList.map(data => {
                if(data.modelId === modelIdValue) {
                    sendData.push(data);
                }
            });
            props.sendData(sendData);
        }
    }, [modelCheckedItem])

    return (
        <CustomTable 
            className="w-100 h-100 overflowYscroll"
            columnList = {modalColumnList}
            contentData = {modelList}
            radio = {true}
            checkedList = { (checkedList) => {setModelCheckedItem(checkedList)} }
            firstCheckedData = {firstCheckedData}
            rowClickEvt={false}
            paging = {false}
        />
    )
}