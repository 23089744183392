import React, {Fragment, useEffect, useState} from 'react';
import {CustomPagination, CustomTable, SearchInputBar, BasicModal} from 'components/molecules';
import store from 'scripts/store';
import Net from 'scripts/net';
import Common from 'scripts/common';
import {useHistory} from "react-router-dom";
import classNames from "classnames";
import {usePageAuth , useDidMountEffect} from "scripts/hooks";

import {Checkbox} from "@material-ui/core";

const columnList = [
    {
        code: 'inviteId',
        title: 'inviteId',
        width: '8',
        hidden: true,
    },
    {
        code: 'site.name',
        title: '현장명',
        width: '7',
    },
    {
        code: 'place.name.dong',
        title: '동',
        width: '4',
    },    {
        code: 'place.name.ho',
        title: '호',
        width: '4',
    },
    {
        code: 'username',
        title: '사용자명',
        width: '6',
    },
    {
        code: 'inviteName',
        title: '초대자',
        width: '6',
    },
    {
        code: 'title',
        title: '제목',
        width: '6',
    },
    {
        code: 'createdDatetime',
        title: '초대 전송 일시',
        width: '8',
    },
    {
        code: 'startDatetime',
        title: '초대 시작 일시',
        width: '8',
    },
];

export const FreePassVisitorList = (props) => {
    // useHistory 선언
    const history = useHistory();
    const user = props.userInfo;
    const [contentData, setContentData] = useState([]);
    const [checkedList, setCheckedList] = useState([]);
    const [modalState, setModalState] = useState({
        open: false,
        data: [],
        clickedBtnName: '',
    });
    const [searchCategory, setSearchCategory] = useState([
        [
            [
                {code: '', name: '고객사 선택'}
            ]
        ],
        [
            [
                {code: '', name: '현장명 선택'}
            ]
        ],
    ]);

    const [loading, setLoading] = useState(false);
    const [visitorDetail, setVisitorDetail] = useState([]);
    const [visitorData, setVisitorData] = useState([]);
    const [clickedRowIdx, setClickedRowIdx] = useState(null);
    const programAuth = usePageAuth(props.userInfo.authList[0], sessionStorage.getItem('menuId'));

    const [pageState, setPageState] = useState({
        currentPage: 0,
        rowPerPage: 10,
        totalElementsCnt: 0,
        drawElementsCnt: 0,
        searchText: '',
        searchText2: '',
        searchText3: '',
        searchCategory1: user.menuGroup === 'ADMIN' ? '' : user.customer && user.customer.code,
        searchCategory2: user.menuGroup === 'OPERATOR' ? user.site && user.site.code: '',
        searchStartDate: '',
        searchEndDate: '',
        boolWholePeriod : false
    });

    useEffect(() => {
        store.openedPage = 'FreePass 초대/방문 목록';
        getCategoryList();
    }, [])

    useEffect(() => {
        setContentData([]);
        setTimeout(() => {
            loadData();
        }, 10)
    }, [pageState.currentPage,
        pageState.searchCategory2,
        pageState.searchText,
        pageState.searchText2,
        pageState.searchText3,
        pageState.searchStartDate,
        pageState.searchEndDate,
        pageState.boolWholePeriod
    ]);



    const loadData = () => {
        setLoading(true);
        Net.getFreePassInviteList(
            {
                currentPage: pageState.currentPage,
                rowPerPage: pageState.rowPerPage,
                searchText: pageState.searchText,
                searchText2: pageState.searchText2,
                searchText3: pageState.searchText3,
                searchCategory1: pageState.searchCategory1,
                siteCode: pageState.searchCategory2,
                searchStartDate: pageState.searchStartDate,
                searchEndDate: pageState.searchEndDate,
                boolWholePeriod : pageState.boolWholePeriod
            }, (response) => {
                setLoading(false);
                if (response.status === 200) {
                    response.data.content.forEach((item) => {
                        item.place.name = (item.place.name.split('-'));
                        item.place.name.forEach((item2, index) => {
                            if (index === 0)
                                item.place.name.dong = item2.replace(/(^0+)/, "");
                            else if (index === 1)
                                item.place.name.ho = item2.replace(/(^0+)/, "");
                        })
                    })
                    setContentData(response.data.content);
                    setPageState({
                        ...pageState,
                        totalElementsCnt: response.data.totalElements,
                        drawElementsCnt: response.data.numberOfElements,
                    });
                } else {
                    setContentData([]);
                    setPageState({
                        ...pageState,
                        totalElementsCnt: 0,
                        drawElementsCnt: 0,
                    });
                }
                setClickedRowIdx(null);
                setVisitorData([]);
            });
    }

    const getCategoryList = () => {
        if (user.menuGroup === 'ADMIN') {
            Common.getCustomerList((response1) => {
                Common.getSitesList(null, (response2) => {
                    setSearchCategory([response1, response2]);
                });
            });
        } else if (user.menuGroup === 'MANAGER') {
            Common.getSitesList(pageState.searchCategory1, (response2) => {
                setSearchCategory([response2]);
            });
        }
    }

    const getRelateCategory = (selectedCategory) => {
        if (selectedCategory === "reset") selectedCategory = "";
        Common.getSitesList(selectedCategory, (response2) => {
            setSearchCategory([
                searchCategory[0],
                response2
            ]);
        });
    }


    const onClickRow = (e) => {
        Net.getFreePassInviteDetail(e.inviteId, (response) => {
            setVisitorData(response.data.length > 0 ? response.data[0].visitor : []);
        });
        setClickedRowIdx(null);
    }

    const onMovePage = (e) => {
        setPageState({
            ...pageState,
            currentPage: e - 1
        });
    }

    const onSubmitHandler = (e) => {
        if (user.menuGroup === 'ADMIN') {
            setPageState({
                ...pageState,
                currentPage: 0,
                searchCategory1: e.selectedCategory[0],
                searchCategory2: e.selectedCategory[1],
                searchText: e.searchText,
                searchText2: e.searchText2,
                searchText3 : e.searchText3
            });

        } else if (user.menuGroup === 'MANAGER') {
            setPageState({
                ...pageState,
                currentPage: 0,
                searchCategory1: pageState.searchCategory1,
                searchCategory2: e.selectedCategory[0],
                searchText: e.searchText,
                searchText2: e.searchText2,
                searchText3 : e.searchText3
            });
        } else{
            setPageState({
                ...pageState,
                currentPage: 0,
                searchText: e.searchText,
                searchText2: e.searchText2,
                searchText3 : e.searchText3
            });
        }
    }

    const removeList = () => {
        const result = window.confirm('삭제하시겠습니까?');
        const removeList = [];
        checkedList.map((id) => {
            const node = document.querySelector(`#${id}`);
            const nodeTr = node.closest('tr');
            const inviteId = nodeTr.querySelector('td[name=inviteId]').textContent;
            removeList.push({inviteId: inviteId});
        });
        if (result) {
            Net.removeFreePassInviteList(removeList, (response) => {
                if (response) {
                    loadData();
                }
            });
        }
    }


    const searchDateHandler = (startDate, endDate) => {
        startDate = startDate.getFullYear() + '-' + ('0' + (Number(startDate.getMonth()) + 1)).slice(-2) + '-' + ('0' + startDate.getDate()).slice(-2);
        endDate = endDate.getFullYear() + '-' + ('0' + (Number(endDate.getMonth()) + 1)).slice(-2) + '-' + ('0' + endDate.getDate()).slice(-2);

        if (!pageState.searchStartDate && !pageState.searchEndDate) {
            pageState.searchStartDate = startDate;
            pageState.searchEndDate = endDate;
        } else {
            setPageState({
                ...pageState,
                searchStartDate: startDate,
                searchEndDate: endDate,
            });
        }
    }


    const removeCar = (list) => {
        let result = window.confirm('등록된 방문 차량을 삭제 하시겠습니까? \n차량 번호 : '+list.carNumber);
        if(result) {
            Net.deleteVisitorCar({visitorId : list.id}, (response) =>{
                if(response.status === 200){
                    onClickRow(list.inviteId);
                } else if (response.status === 404 ){
                    alert('주차 관제 시스템 오류... 잠시 후 시도해주세요.');
                } else {
                    alert('방문 차량 취소에 실패하였습니다.');
                }
            })
        }

    }



    return (
        <div className="w-100 h-100">
            <div className="row col-12 h-7 ">
                <div className="col-12 mt-2">
                    <SearchInputBar
                        className="w-100 searchInput-wrapper-nodp "
                        searchCategory={user.menuGroup === 'ADMIN' || user.menuGroup === 'MANAGER'}
                        searchCategoryGroup={searchCategory}
                        searchBtn={true}
                        searchBtnTitle="검색"
                        searchText={true}
                        searchPlaceHolder={"사용자명"}
                        searchText2={true}
                        searchPlaceHolder2={"동"}
                        searchText3={true}
                        searchPlaceHolder3={"호"}
                        dynamicSearch={false}
                        smSearchText={true}
                        smSearchText2={true}
                        smSearchText3={true}
                        relateCategoryHandler={user.menuGroup === 'ADMIN' && getRelateCategory}
                        searchDate={searchDateHandler}
                        onSubmit={onSubmitHandler}
                        datetype={"test"}
                        refreshBtn={true}
                        refreshHandler={loadData}
                    />
                </div>
            </div>
            <div className="w-100 h-1px bgC-efefef"/>
            <div className="flex">
                <div className="col-5 ml-10">
                    <p className="totalCnt">Total {pageState.totalElementsCnt}</p>
                </div>
                <div className="col-5 mr-15 mt-10">
                    <span className="text-muted mt-2 ml-3">전체 기간</span>
                    <Checkbox
                        checked={pageState.boolWholePeriod}
                        onChange={() => setPageState({
                            ...pageState,
                            boolWholePeriod: !pageState.boolWholePeriod
                        })}
                        disabled={loading}
                        size="small"
                    />
                    {
                        programAuth.deleteAuth && checkedList.length > 0 &&
                        <button
                            className="removeBtn w-90px h-30px ms-3"
                            onClick={removeList}
                        >삭제</button>
                    }
                </div>
            </div>
            <div className="flex">
                <div className="w-60 h-93 overflowYscrollView">
                    <CustomTable
                        columnList={columnList}
                        contentData={contentData}
                        paging={true}
                        checkbox={programAuth.deleteAuth}
                        checkedList={(checkedList) => {
                            setCheckedList(checkedList)
                        }}
                        totalElementsCnt={pageState.totalElementsCnt}
                        rowPerPage={pageState.rowPerPage}
                        currentPage={pageState.currentPage}
                        drawElementsCnt={pageState.drawElementsCnt}
                        pagination={onMovePage}
                        rowClickEvt={true}
                        onClickRow={onClickRow}
                        loading={loading}
                        // sort = {true}
                    />
                </div>
                <div className="w-1 h-50vh flex align-items-center justify-content-center">
                </div>
                <div className="w-37 h-93 " style={{display: "block"}}>
                    <div className="row">
                        <div className="col-6"></div>
                        <div className="col-3">
                        </div>
                        <div className="col-3">
                        </div>
                    </div>

                    <div className="w-100 h-60vh">
                        <div className="w-100 h-60vh overFlowYscrollView">
                            <table className="mainTable ">
                                <colgroup>
                                    <col width="25%"/>
                                    <col width="35%"/>
                                    <col width="25%"/>
                                    <col width="15%"/>
                                </colgroup>
                                <thead>
                                <tr>
                                    <th className="tableHead fixedHeader t-0px" colSpan="4">방문자 [방문차량]</th>
                                </tr>
                                <tr>
                                    <th className="v-middle tableHead fixedHeader t-36px"><span className="w-24px">방문자명</span></th>
                                    <th className="v-middle tableHead fixedHeader t-36px"><span className="w-24px">전화번호</span></th>
                                    <th className="v-middle tableHead fixedHeader t-36px"><span className="w-24px">차 번호</span></th>
                                    <th className="v-middle tableHead fixedHeader t-36px"><span className="w-12px">차량 삭제</span></th>

                                </tr>
                                </thead>
                                <tbody className="tableBody">
                                {
                                    visitorData.length > 0 ?
                                        (
                                            <Fragment>
                                                {
                                                    visitorData.map((list,index) => {
                                                        return (
                                                            <tr key={index+'visitor'}
                                                                onClick={()=>{
                                                                    setClickedRowIdx(index);
                                                                    setVisitorDetail(list);
                                                                }}
                                                                className={classNames({'clicked': index===clickedRowIdx})}
                                                            >
                                                                <td key={list.visitorId+' name'} style={{whiteSpace: "initial", borderBottom: "none"}}>{list.name}</td>
                                                                <td key={list.visitorId+' phoneNumber'} style={{whiteSpace: "initial", borderBottom: "none"}}>{list.phoneNumber}</td>
                                                                <td key={list.visitorId+' carNumber'} style={{whiteSpace: "initial", borderBottom: "none"}}>{list.carNumber}</td>
                                                                <td key={list.visitorId+' remove'} style={{whiteSpace: "initial", borderBottom: "none"}}>
                                                                    {
                                                                        list.carNumber ?
                                                                            <button className="removeBtn" onClick={()=>removeCar(list)}>삭제</button>
                                                                        : <></>
                                                                    }
                                                                </td>
                                                            </tr>
                                                        )
                                                    })
                                                }

                                            </Fragment>
                                        ) : (
                                            <tr>
                                                <td colSpan="4">조회 된 데이터가 없습니다.</td>
                                            </tr>
                                        )
                                }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
