import React, {useEffect, useState} from 'react';
import {CustomTable, SearchInputBar, BasicModal} from 'components/molecules';
import store from 'scripts/store';
import Net from 'scripts/net';
import Common from 'scripts/common';
import {usePageAuth} from 'scripts/hooks';
import classNames from "classnames";
import {Checkbox} from "@material-ui/core";
import {ApplianceUserDeviceModal} from "./modal/ApplianceUserDeviceModal";

const columnList = [
    {
        code: 'siteName',
        title: '현장',
        width: '8',
    },
    {
        code: 'username',
        title: '사용자ID',
        width: '8',
    },
    {
        code: 'appliances',
        title: '서비스',
        width: '5',
    },
    {
        code: 'createdDatetime',
        title: '연동 일시',
        width: '10',
    },
    {
        code: 'accessToken',
        title: 'AccessToken',
        width: '15',
    },
    {
        code: 'refreshToken',
        title: 'RefreshToken',
        width: '15',
    },
    {
        code: '',
        title: '디바이스',
        width: '5',
        tdClickEvent: true,
    }
];

export const ApplianceUserList = (props) => {

    const user = props.userInfo;
    const [contentData, setContentData] = useState([]);
    const [searchCategory, setSearchCategory] = useState([
        [
            [
                {code: '', name: '고객사 선택'}
            ]
        ],
        [
            [
                {code: '', name: '현장명 선택'}
            ]
        ],
    ]);

    const [pageState, setPageState] = useState({
        currentPage: 1,
        rowPerPage: 10,
        totalElementsCnt: 0,
        drawElementsCnt: 0,
        searchText: '',
        searchCategory1: user.menuGroup === 'ADMIN' ? '' : user.customer && user.customer.code,
        searchCategory2: user.menuGroup === 'OPERATOR' ? user.site && user.site.code : '',
    });

    const [modalState, setModalState] = useState({
        open: false,
        data: [],
        clickedBtnName: '',
    });

    const [loading, setLoading] = useState(false);

    const programAuth = usePageAuth(props.userInfo.authList[0], sessionStorage.getItem('menuId'));

    useEffect(() => {
        store.openedPage = 'LG ThinQ 인증 사용자 목록';
        getCategoryList('');
    }, [])

    useEffect(() => {
        loadData()
    }, [pageState.currentPage,
        pageState.searchCategory2,
        pageState.searchText,
    ]);


    const loadData = () => {
        setLoading(true);
        Net.getLgApplianceUserList(
            {
                currentPage: pageState.currentPage-1,
                rowPerPage: pageState.rowPerPage,
                searchText: pageState.searchText,
                searchCategory1: pageState.searchCategory1,
                searchCategory2: pageState.searchCategory2,
            }, (response) => {
                setLoading(false);
                if (response.status === 200) {
                    setContentData(response.data.content.map((items)=>{
                        items.siteName = window.SITE_LIST ? (window.SITE_LIST[items.siteCode].hasOwnProperty('name') ? window.SITE_LIST[items.siteCode].name : items.siteCode) :items.siteCode;
                        return items;
                    }));
                    setPageState({
                        ...pageState,
                        totalElementsCnt: response.data.totalElements,
                        drawElementsCnt: response.data.numberOfElements,
                    });
                } else {
                    setContentData([]);
                    setPageState({
                        ...pageState,
                        totalElementsCnt: 0,
                        drawElementsCnt: 0,
                        currentPage: 1,
                    });
                }
            });
    }


    const getCategoryList = () => {
        if (user.menuGroup === 'ADMIN') {
            Common.getCustomerList((response1) => {
                Common.getSitesList(null, (response2) => {
                    setSearchCategory([response1, response2]);
                });
            });
        } else if (user.menuGroup === 'MANAGER') {
            Common.getSitesList(pageState.searchCategory1, (response2) => {
                setSearchCategory([response2]);
            });
        }
    }

    const getRelateCategory = (selectedCategory) => {
        if (selectedCategory === "reset") selectedCategory = "";
        Common.getSitesList(selectedCategory, (response2) => {
            setSearchCategory([
                searchCategory[0], // 고객사
                response2,         // 현장
            ]);
        });
    }


    const onClickModalOpen = (type, e) => {
        setModalState({
            ...modalState,
            open: !modalState.open,
            username: e.username,
            clickedBtnName: type
        });
    }

    const tdOnClickEvent = (title, e) => {
        onClickModalOpen(title, e);
    }

    const onClickRow = (e) => {

    }

    const onMovePage = (e) => {
        setPageState({
            ...pageState,
            currentPage: e
        });
    }

    const onSubmitHandler = (e) => {
        if (user.menuGroup === 'ADMIN') {
            if(e.selectedCategory[0] !== '' && e.selectedCategory[1] === ''){
                alert("현장을 선택해주세요.");
                return false;
            }
            setPageState({
                ...pageState,
                currentPage: 1,
                searchCategory1: e.selectedCategory[0],
                searchCategory2: e.selectedCategory[1],
                searchText: e.searchText,
            });
        } else if (user.menuGroup === 'MANAGER') {
            if(e.selectedCategory[0] === ''){
                alert("현장을 선택해주세요.");
                return false;
            }
            setPageState({
                ...pageState,
                currentPage: 1,
                searchCategory1: pageState.searchCategory1,
                searchCategory2: e.selectedCategory[0],
                searchText: e.searchText,
            });
        } else {
            setPageState({
                ...pageState,
                currentPage: 1,
                searchCategory1: pageState.searchCategory1,
                searchCategory2: pageState.searchCategory2,
                searchText: e.searchText,
            });
        }
    }


    return (
        <div className="w-100 h-100">
            <BasicModal
                className="w-80 h-93"
                title={`${modalState.clickedBtnName} 목록`}
                content={
                    <ModalContent
                        modalType={modalState.clickedBtnName}
                        propsData={modalState}
                        modalClose={(e) => setModalState({
                            ...modalState,
                            open: e
                        })}
                    />
                }
                modalOpen={modalState.open}
                modalClose={() => setModalState({
                    ...modalState,
                    open: false
                })}
                sendData={modalState.data}
            />
            <div className="w-100 h-7 display_table">
                <SearchInputBar
                    className="w-100"
                    role={user.menuGroup}
                    searchCategory={user.menuGroup === 'ADMIN' || user.menuGroup === 'MANAGER'}
                    searchCategoryGroup={searchCategory}
                    searchText={true}
                    searchPlaceHolder="사용자아이디 입력하세요."
                    searchBtn={true}
                    searchBtnTitle="검색"
                    dynamicSearch={false}
                    relateCategoryHandler={getRelateCategory}
                    onSubmit={onSubmitHandler}
                    refreshBtn={true}
                    refreshHandler={loadData}
                />
            </div>
            <div className="w-100 h-1px bgC-efefef"/>
            <div className="w-100 h-100">
                <div className="row">
                    <p className={classNames("totalCnt col-2")}>Total {pageState.totalElementsCnt}</p>
                </div>
                <CustomTable
                    columnList={columnList}
                    contentData={contentData}
                    checkbox={programAuth.deleteAuth}
                    paging={true}
                    totalElementsCnt={pageState.totalElementsCnt}
                    rowPerPage={pageState.rowPerPage}
                    currentPage={pageState.currentPage - 1}
                    drawElementsCnt={pageState.drawElementsCnt}
                    loading={loading}
                    pagination={onMovePage}
                    rowClickEvt={true}
                    onClickRow={onClickRow}
                    tdClickEvt={tdOnClickEvent}
                    // sort = {true}
                />
            </div>
        </div>
    )
}

export const ModalContent = (props) => {
    switch (props.modalType) {
        case "디바이스" :
            return <ApplianceUserDeviceModal propsData={props.propsData}/>
            break;
    }
}
