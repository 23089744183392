import React, {useState, useEffect} from 'react'
import * as XLSX from "xlsx";
import {useDidMountEffect} from "../../../scripts/hooks";

export const ExcelUpload = (props) => {
    const {list, inputName, disabled} = props;
    const [imgFileName, setImgFileName] = useState("");
    const [file, setFile] = useState();


    useDidMountEffect(()=>{
        const reader = new FileReader();
        reader.onload = (evt) => {
            // evt = on_file_select event
            /* Parse data */
            const bstr = evt.target.result;
            const wb = XLSX.read(bstr, { type: "binary" });
            /* Get first worksheet */
            const wsname = wb.SheetNames[0];
            const ws = wb.Sheets[wsname];
            /* Convert array of arrays */
            const data = XLSX.utils.sheet_to_csv(ws, { header: 1 });
            /* Update state */
            const temp = wb.SheetNames.map((sheetName)=>{
                return XLSX.utils.sheet_to_json(wb.Sheets[sheetName]);
            })
            list(temp[0]); // shows data in json format
        };

        reader.readAsBinaryString(file);
    },[file]);


    const onChangeImg = (e) => {
        e.stopPropagation();
        e.preventDefault();

        setFile(e.target.files[0]);
    }

    return (
        <>
         <div className="floatR mt-2">
            <div className="flex fileArea">
                <input className="upload-name" value={imgFileName} disabled="disabled"/>
                <label htmlFor={inputName ? inputName : "ex_filename"}></label>
                <input
                    type="file"
                    id={inputName ? inputName : "ex_filename"}
                    className="upload-hidden"
                    onChange={onChangeImg}
                    accept=".xlsx"
                    disabled={disabled}
                />
            </div>
        </div>
        </>
    )
}
