import React, {Fragment, useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom'
import {CustomTable, SearchInputBar, CustomCheckbox} from 'components/molecules';
import store from 'scripts/store';
import Net from 'scripts/net';
import Common from 'scripts/common';
import {usePageAuth, useDidMountEffect } from "scripts/hooks";
import {Checkbox} from "@material-ui/core";
import {toast} from "react-toastify";
import {CustomSelectBox} from "../../../components/molecules";

const columnList = [
    {
        code: 'id',
        title: 'automationId',
        width: '8',
        hidden: true,
    },
    {
        code: 'placeName',
        title: '동-호',
        width: '3',
    },
    {
        code: 'username',
        title: '사용자',
        width: '8',
    },
    {
        code: 'name',
        title: '자동화 이름',
        width: '6',
    },
    {
        code: 'description',
        title: '자동화 설명명',
       width: '6',
    },
    {
        code: 'enabled',
        title: '활성화 여부',
        width: '3',
    },
    {
        code: 'periodicCondition.time',
        title: '시작시간',
        width: '7',
    },
    {
        code: 'avgsignuser',
        title: '즉시실행',
        width: '8',
        tdClickEvent: true,
    },

];

export const AutomationTest = (props) => {

    const history = useHistory();
    const user = props.userInfo;
    const programAuth = usePageAuth(props.userInfo.authList[0], sessionStorage.getItem('menuId'));
    const [contentData, setContentData] = useState([]);
    const [checkedList, setCheckedList] = useState([]);
    const [searchCategory, setSearchCategory] = useState([
        [
            {code: '', name: '고객사 선택'}
        ],
        [
            {code: '', name: '현장명 선택'}
        ],
        [
            {code: '', name: '동 선택'}
        ],
    ]);

    const [activation, setActivation] = useState([
        {
            code: '',
            name: '활성화 여부'
        },
        {
            code : 'true',
            name : '활성화'
        },
        {
            code : 'false',
            name : '비활성화'
        }
    ]);

    const [form, setForm] = useState({
        activation : {
            code:'',
            name:'',
        },
        startTime : new Date().getHours()+':'+new Date().getMinutes(),
    });
    const [checkState1, setCheckState1] = useState(false);
    const [checkState2, setCheckState2] = useState(false);

    const [pageState, setPageState] = useState({
        searchCategory1: '',
        searchCategory2: '',
        searchCategory3: '',
        searchText: '',
        searchText2: '',
        onlyTemporary: true,
    });

    useDidMountEffect(async () => {
        await loadData();
    }, [pageState]);

    useEffect(() => {
        store.openedPage = '자동화 테스트';
        getCategoryList('');
    }, [])
    const loadData = async () => {
        const response = await Net.getAutomationTest(pageState.searchCategory2,
            pageState.searchCategory3,
            pageState.searchText,
            pageState.onlyTemporary,
            pageState.searchText2)

        setContentData(response.data)
    }

    const getCategoryList = () => {
        Common.getCustomerList((response)=>{
            setSearchCategory([response,searchCategory[1],searchCategory[2]]);
        });
    }

    const getRelateCategory = (selectedCategory) => {
        if (selectedCategory === "reset") selectedCategory = "";
        Common.getSitesList(selectedCategory, (response2) => {
            setSearchCategory([
                searchCategory[0],
                response2,
                searchCategory[2]
            ]);
        });
    }

    const getRelateSiteCategory = (selectedCategory) => {
        if (selectedCategory === "reset") selectedCategory = "";
        Common.getDongListBySiteCode(selectedCategory, response => {
            setSearchCategory([
                searchCategory[0],
                searchCategory[1],
                response.data
            ])
        });
    }

    const onSubmitHandler = async (e) => {
        if(e.selectedCategory[0] === ''){
            alert("고객사를 선택해주세요.");
            return false;
        }
        else if(e.selectedCategory[1] === ''){
            alert("현장을 선택해주세요.");
            return false;
        }
        else if(e.selectedCategory[2] === ''){
            alert("동을 선택해주세요.");
            return false;
        }
        setPageState({
            searchCategory1 : e.selectedCategory[0],
            searchCategory2 : e.selectedCategory[1],
            searchCategory3 : e.selectedCategory[2],
            searchText : e.searchText,
            searchText2 : e.searchText2,
            onlyTemporary: pageState.onlyTemporary
        })
    }

    const selectBoxHandler = (name, value) => {
        switch (name) {
            case 'activation':
                setForm({
                    ...form,
                    activation: {
                        code: value.code,
                        name: value.name
                    }
                });
                break;
            default:
                break;
        }
    }

    const onChangeHandler = (value) => {
        setForm({
            ...form,
            startTime: value,
        })
    }


    const onClickAdd = () =>{
        history.push('/monitoring/automationtest/regist');
    }

    const removeList = async () => {
        const result = window.confirm('삭제하시겠습니까?');
        const removeList = [];
        checkedList.map((id) => {
            const node = document.querySelector(`#${id}`);
            const nodeTr = node.closest('tr');
            const automationId = nodeTr.querySelector('td[name=id]').textContent;
            removeList.push(automationId);
        });
        if(result){
            const response = await Net.removeAutomation(removeList);
            if(response.status === 200) {
                await loadData();
            } else {
                alert("오류" + response.stringify());
            }
        }

    }

    const updateList = async () => {
        const result = window.confirm('적용 하시겠습니까? ');
        let updateList = [];
        let automationData = {};
        checkedList.map((id)=> {
            const node = document.querySelector(`#${id}`);
            const nodeTr = node.closest('tr');
            const automationId = nodeTr.querySelector('td[name=id]').textContent;
            updateList.push(automationId);
        });
        if(result){
            if(!checkState1 && !checkState2){
                alert('최소 1개의 체크박스를 선택해 주세요.');
                return ;
            }
            if(checkState1){
                automationData = {
                    enable : form.activation.code,
                }
            }
            if(checkState2){
                automationData = {
                    ...automationData,
                    time : form.startTime
                }
            }
            const response = await Net.updateAutomation(updateList, automationData);
            if(response.status===200){
                await loadData();
            }else{
                alert("오류" + response.stringify());
            }
        }
    }

    const tdOnClickEvent = async (e, item) => {
        const result = window.confirm('즉시 실행 하시겠습니까?');
        if (result) {
            const response = await Net.postExecutorAutomation(item.id);
            if(response.status === 202 ){
                alert("정상적으로 실행되었습니다.")
            } else {
                alert("오류 : "+response.stringify());
            }
        }
    }



    return (
        <div className="w-100 h-100">
            <div className="w-100 h-1px bgC-efefef"/>
            <div className="h-93 row">
                <h5 className="floatL mt-1p mb-1p ml-2p">입사점 자동화 테스트</h5>
                <SearchInputBar
                    className="col-9"
                    searchCategory={user.menuGroup === 'ADMIN' || user.menuGroup === 'MANAGER'}
                    searchCategoryGroup={searchCategory}
                    searchText={true}
                    searchPlaceHolder="호"
                    searchText2={true}
                    searchPlaceHolder2="자동화 명"
                    searchBtn={true}
                    searchBtnTitle="검색"
                    dynamicSearch={false}
                    relateCategoryHandler={getRelateCategory}
                    relateSiteCategoryHandler={getRelateSiteCategory}
                    onSubmit={onSubmitHandler}
                />
                <div className="col-3">
                    <span className="text-muted mt-3"> 임시 사용자</span>
                    <Checkbox
                        disabled={true}
                        checked={pageState.onlyTemporary || false}
                        onChange={()=> setPageState({
                                ...pageState,
                                onlyTemporary: !pageState.onlyTemporary
                            })}
                    />

                    <button className="floatR w-120px h-30px mr-30 addBtn"
                            onClick={onClickAdd}
                    > 신규 일괄 생성 </button>
                </div>
                <div className="w-100 mt-2">
                    <div className="row">
                        <div className="col-3">
                            <h5 className="mt-3 ml-25 ">선택 자동화 총 갯수 :{ checkedList.length } </h5>
                        </div>
                        <div className="col-9 d-flex justify-content-end">
                            <CustomSelectBox
                                name="activation"
                                className="mt-2 mr-3 "
                                categoryList={activation}
                                value={form.activation.code}
                                onChangeHandler={(value, name) => selectBoxHandler('activation', {
                                    code: (value === 'true'),
                                    name: name
                                })}
                                callbackCodeName="Y"
                            />
                            <Checkbox
                                className={"mr-1"}
                                checked={checkState1 || false}
                                onChange={()=> setCheckState1(!checkState1)}
                            />

                            <h5 className="mt-3 mr-3 ml-20"> 시작 시간 : </h5>
                            <input
                                type="time"
                                id="startTime"
                                value={form.startTime}
                                onChange={(e) => onChangeHandler(e.target.value)}
                            />
                            <Checkbox
                                className={"mr-5"}
                                checked={checkState2 || false}
                                onChange={()=> setCheckState2(!checkState2)}
                            />

                            <button
                                onClick={updateList}
                                className="addBtn w-90px h-30px mt-7 ml-30 mr-50 mb-2"
                            >적용
                            </button>

                            {
                                <button
                                    className="cancelBtn w-90px h-30px mt-7 mr-30 mb-2"
                                    onClick={removeList}
                                >삭제</button>
                            }
                        </div>
                    </div>

                    <CustomTable
                        columnList={columnList}
                        contentData={contentData}
                        checkbox={true}
                        checkedList={(checkedList) => {
                            setCheckedList(checkedList)
                        }}
                        tdClickEvt={tdOnClickEvent}
                    />

                </div>
            </div>
        </div>
    );
}
