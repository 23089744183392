import React, {Fragment, useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {CustomTable, SearchInputBar} from 'components/molecules';
import store from 'scripts/store';
import Net from 'scripts/net';

const columnList = [
    {
        code: 'code',
        title: '',
        width: '',
        hidden: true,
    },
    {
        code: 'name',
        title: '현장명',
        width: '15',
    },
    {
        code: 'customer.name',
        title: '고객사명',
        width: '15',
    },
    {
        code: 'region.name',
        title: '지역명',
        width: '15',
    },
    {
        code: 'manufacturer.name',
        title: '홈넷사',
        width: '20',
    },
];

export const NotificationManage = (props) => {

    // useHistory 선언
    const history = useHistory();
    const user = props.userInfo;

    const [contentData, setContentData] = useState([]);
    const [checkedList, setCheckedList] = useState([]);
    const [notificationDetail, setNotificationDetail] = useState([]);
    const [notificationList, setNotificationList] = useState([]);

    const [searchCategoryGroup, setSearchCategoryGroup] = useState([
        [
            [
                {code: '', name: '고객사 선택'}
            ]
        ],
    ]);

    const [pageState, setPageState] = useState({
        currentPage: 1,
        rowPerPage: 13,
        totalElementsCnt: 0,
        drawElementsCnt: 0,
        searchText: '',
        customerCode: user.menuGroup === 'ADMIN' ? '' : user.customer && user.customer.code,
    });

    const [programAuth, setProgramAuth] = useState({});

    useEffect(() => {
        store.openedPage = '알림 설정';
        getCustomerList((response1) => {
            setSearchCategoryGroup([response1],);
        });
        pageAuthInfo();
    }, []);

    useEffect(() => {
        loadData();
    }, [pageState.currentPage, pageState.searchText, pageState.customerCode]);

    const pageAuthInfo = () => {
        const authGroupCode = props.userInfo.authList[0];
        const menuId = sessionStorage.getItem('menuId');

        Net.getAuthGroupMenuList(menuId, (response) => {
            if (response.status === 200) {
                response.data.forEach(item => {
                    if (item.authGroup === authGroupCode && item.authGroupMenu) {
                        setProgramAuth({
                            searchAuth: Boolean(item.authGroupMenu.searchAuth),
                            saveAuth: Boolean(item.authGroupMenu.saveAuth),
                            excelAuth: Boolean(item.authGroupMenu.excelAuth),
                            deleteAuth: Boolean(item.authGroupMenu.deleteAuth),
                        });
                    }
                });
            }
        });
    }

    const loadData = () => {
        Net.getSitesList(pageState, (response) => {
            if (response.status === 200) {
                const manufacturerCodeArray = [];

                response.data.content.map((list) => {
                    const manufacturerCode = list.manufacturerCode;
                    if (manufacturerCode)
                        manufacturerCodeArray.push(manufacturerCode);
                });
                console.log(response.data.content , manufacturerCodeArray)
                if (manufacturerCodeArray.length > 0) {
                    getManufacturerDataList(manufacturerCodeArray, (response2) => {
                        const newContentData = [];
                        response.data.content.map(list => {
                            const obj = list;
                            const manufacturerCode = list.manufacturerCode;
                            if (manufacturerCode) {
                                response2.map(data => {
                                    if (manufacturerCode === data.code) {
                                        obj.manufacturer = {
                                            name: data.name,
                                        }
                                    }
                                });
                            }
                            newContentData.push(obj);
                        });
                        setContentData(
                            newContentData
                        )
                    });
                } else {
                    setContentData(response.data.content);
                }

                setPageState({
                    ...pageState,
                    totalElementsCnt: response.data.totalElements,
                    drawElementsCnt: response.data.numberOfElements,
                });

            } else if (response.status === 204) {
                setContentData([]);
                setPageState({
                    ...pageState,
                    totalElementsCnt: 0,
                    drawElementsCnt: 0,
                });
            }
        });
    }

    const getManufacturerDataList = (manufacturerCodeArray, callback) => {
        const manufacturerDataArray = [];
        manufacturerCodeArray.map((code, index) => {
            Net.getToolsManufacturerOne(code, (response) => {
                const manufacturerDataObj = {};
                if (response.status === 200) {
                    manufacturerDataObj.code = code;
                    manufacturerDataObj.name = response.data.name;

                    manufacturerDataArray.push(manufacturerDataObj);

                    if (manufacturerCodeArray.length === (index + 1)) {
                        callback(manufacturerDataArray);
                    }
                }
            });
        })
    }

    const getCustomerList = (callback) => {
        Net.getCustomerList({currentPage: '', rowPerPage: '', searchText: ''}, (response) => {
            if (response.status === 200) {
                let newArray = searchCategoryGroup[0][0];
                response.data.content.map(items => {
                    newArray = [
                        ...newArray,
                        {
                            code: items.code,
                            name: items.name
                        }
                    ]
                });
                callback(newArray);
            }
        });
    }

    const onMovePage = (e) => {
        setPageState({
            ...pageState,
            currentPage: e
        });
    }

    const onSubmitHandler = (e) => {
        setPageState({
            ...pageState,
            currentPage: 1,
            searchText: e.searchText,
            customerCode: e.selectedCategory[0],
        });
    }

    const ableUse = (index) =>{
        const array = notificationDetail.map((list, i)=>{
            if(i === index){
                list.enabled = !list.enabled;
            }
            return list;
        });
        Net.updateNotificationList({...notificationList,notificationCategories: array}, (response)=>{
            if (response.status === 200) {
                setNotificationList(response.data)
            }
        })
        setNotificationDetail(array);
    }


    const makeListToDom = (array) =>{
        const dom = <Fragment>
            {
                array.map((list,index) => {
                    return (
                        <tr key={index}>
                            <td style={{whiteSpace:"initial", borderBottom: "none"}}>{list.categoryCode}</td>
                            <td style={{whiteSpace:"initial", borderBottom: "none"}}>{list.categoryName}</td>
                            <td style={{whiteSpace:"initial", borderBottom: "none"}}>
                                {list.enabled ? <button
                                        className="addBtn w-50px h-22px"
                                        onClick={()=> {ableUse(index)}}
                                    >사용</button>
                                    : <button
                                        className="cancelBtn w-70px h-22px"
                                        onClick={()=> {ableUse(index)}}
                                    >미사용</button>}
                            </td>
                        </tr>
                    )
                })
            }
        </Fragment>

        return dom;
    }

    const onClickRow = (e) => {
        setNotificationDetail([]);
        let tempNotificationList = {};

        setTimeout(()=>{
            Net.getNotificationOne(e.code, (response) => {
                if (response.status === 200) {
                    setNotificationDetail(response.data.notificationCategories);
                    setNotificationList(response.data);
                }else{
                    Net.getNotificationCategories((response2)=>{
                        if(response2.status===200){
                            const tempNotificationDetail = response2.data.map( data =>{
                                return {categoryCode:data.name,categoryName:data.description,enabled : false}
                            })
                            tempNotificationList = {customerCode:e.customer.code, customerName:e.customer.name, siteCode:e.code, siteName:e.name, notificationCategories:tempNotificationDetail}
                            setNotificationDetail(tempNotificationDetail);
                            setNotificationList(tempNotificationList);
                        }
                    })
                }
            });
        },10);

    }

    return (
        <div className="w-100 h-100">
            <div className="w-100 h-7 display_table">
                <SearchInputBar
                    className="w-100"
                    searchCategory={user.menuGroup === 'ADMIN'}
                    searchCategoryGroup={searchCategoryGroup}
                    searchText={true}
                    searchPlaceHolder="현장명을 입력해 주세요."
                    searchBtn={true}
                    searchBtnTitle="검색"
                    dynamicSearch={false}
                    onSubmit={onSubmitHandler}
                />
            </div>
            <div className="w-100 h-1px bgC-efefef"/>
            <div className="flex h-93">
                <div className="h-100 w-50  overflowYscroll">
                    <p className="totalCnt">Total {pageState.totalElementsCnt}</p>
                    <CustomTable
                        columnList={columnList}
                        contentData={contentData}
                        checkbox={programAuth.deleteAuth}
                        checkedList={(checkedList) => {
                            setCheckedList(checkedList)
                        }}
                        paging={true}
                        totalElementsCnt={pageState.totalElementsCnt}
                        rowPerPage={pageState.rowPerPage}
                        currentPage={pageState.currentPage - 1}
                        drawElementsCnt={pageState.drawElementsCnt}
                        pagination={onMovePage}
                        rowClickEvt={true}
                        onClickRow={onClickRow}
                        // sort = {true}
                    />
                </div>
                <div className="w-50 h-100">
                    <div className="flex">
                        <p className="body_content_subtitle">내용</p>

                    </div>
                    <div className="w-100 h-85 overFlowYscrollView">
                        <table className="mainTable">
                            <colgroup>
                                <col width="30%"/>
                                <col width="40%"/>
                                <col width="30%"/>
                            </colgroup>
                            <thead className="tableHead">
                            <tr>
                                <th className="tableHead fixedHeader t-0px borderC-eb878c" colSpan="4">알림 목록</th>
                            </tr>
                            <tr>
                                <th className="v-middle tableHead fixedHeader t-36px"><span className="w-24px">알림유형 코드</span></th>
                                <th className="v-middle tableHead fixedHeader t-36px"><span className="w-48px">알림 유형</span></th>
                                <th className="v-middle tableHead fixedHeader t-36px"><span className="w-24px">사용 여부</span></th>
                            </tr>
                            </thead>
                            <tbody className="tableBody  h-60vh">
                            {
                                notificationDetail.length > 0 ?
                                    (
                                        makeListToDom(notificationDetail)
                                    ) : (
                                        <tr>
                                            <td colSpan="4">조회 된 데이터가 없습니다.</td>
                                        </tr>
                                    )
                            }

                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )
}
