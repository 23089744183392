import React, {useEffect, useState} from "react";
import Net from 'scripts/net';
import Common from 'scripts/common';
import { CustomTextField, CustomSelectBox, QuillEditor  } from 'components/molecules';

export const ApartmentRegistModal = (props) => {
    const [form, setForm] = useState({
        customer : { code : '', name : ''},
        site : {
            code : '',
            name : ''
        },
        inappcomcd : '',
        connection : '',
        otp : '',
        payNumber : '',
        seedKey: '',
        comId: '',
        usable: true,
    });
    const [registPage, setRegistPage] = useState(true);

    const [customerList, setCustomerList] = useState([{
        code: '',
        name: '고객사명 선택'
    }]);
    const [siteList, setSiteList] = useState([{
        code: '',
        name: '현장명 선택'
    }]);

    useEffect(() => {
        if(props.data.detailContent){
            setForm({
                ...form,
                site : props.data.detailContent.site,
                inappcomcd : props.data.detailContent.inappcomcd,
                connection : props.data.detailContent.connection,
                otp : props.data.detailContent.otp,
                payNumber : props.data.detailContent.payNumber,
                seedKey: props.data.detailContent.seedKey,
                comId: props.data.detailContent.comId,
            });
            setRegistPage(false);
        }
        getCustomerList((response) => {
            setCustomerList(response);
        });
    }, [])

    useEffect(()=>{
        console.log(form)
    },[form])

    useEffect(() => {
        if(form.customer.code !== '') {
            getSiteList(form.customer.code, (response) => {
                setSiteList(response);
            })
        } else {
            setSiteList([
                {
                    code: '',
                    name: '현장명 선택'
                }
            ])
        }
    }, [form.customer]);

    const getCustomerList = (callback) => {
        Common.getCustomerList((response) => {
            callback(response);
        })
    }

    const getSiteList = (code, callback) => {
        Common.getSitesList(code, response => {
            callback(response);
        })
    }


    const textFieldHandler = (name, value) => {
        //if(name === 'code') setDuplicateChk(false);
        const nextForm = {
            ...form,
            [name]: value
        }
        setForm(nextForm);
    }

    const selectBoxHandler = (name, value) => {
        switch (name) {
            case 'customer.code':
                setForm({
                    ...form,
                    customer: {
                        code: value.code,
                        name: value.name
                    },
                    site: {
                        code: "",
                        name: ""
                    }
                });
                break;
            case 'site.code':
                setForm({
                    ...form,
                    site: {
                        code: value.code,
                        name: value.name
                    }
                });
                break;
            default:
                break;
        }
    }

    const onRemoveHandler = async () => {
        const result = window.confirm('삭제하시겠습니까?');
        if(result) {
            const response = await Net.removeApartmentList([{id : form.site.code}]);
            if(response.status === 200){
                props.modalClose(false);
            } else{
                alert('데이터 오류 , 관리자 문의')
                props.modalClose(false);
            }
        }
    }

    const onSubmitHandler = async () => {
        let result = window.confirm('저장하시겠습니까?');
        if(result) {
            if( !form.site.code ) {
                alert('현장을 선택해 주세요.');
                document.getElementsByName('category')[0].focus();
                return false;
            }
            if( !form.inappcomcd ) {
                alert('업체코드를 입력해 주세요.');
                document.getElementsByName('inappcomcd')[0].focus();
                return false;
            }
            if( !form.connection ) {
                alert('연동정보를 입력해 주세요.');
                document.getElementsByName('connection')[0].focus();
                return false;
            }
            if( !form.seedKey ) {
                alert('SeedKey를 입력해 주세요.');
                document.getElementsByName('seedKey')[0].focus();
                return false;
            }
            if( !form.otp ) {
                alert('OTP를 입력해 주세요.');
                document.getElementsByName('otp')[0].focus();
                return false;
            }
            if( !form.payNumber ) {
                alert('납부코드를 입력해 주세요.');
                document.getElementsByName('payNumber')[0].focus();
                return false;
            }

            if( !form.comId ) {
                alert('comId를 입력해 주세요.');
                document.getElementsByName('comId')[0].focus();
                return false;
            }
            if(registPage) {
                const response = await Net.addApartmentList(form);
                if(response.status === 200 || response.status === 201){
                    props.modalClose(false);
                } else {
                    alert('서버 통신 오류, 관리자 문의')
                }
            } else {
                const response = await Net.updateApartmentList(form);
                if(response.status === 200){
                    props.modalClose(false);
                } else {
                    alert('서버 통신 오류, 관리자 문의')
                }
            }
        }
    };

    const handleClose = () => {
        props.modalClose(false);
    };

    return (
        <div className="h-100">
            <div className="h-80">
                {
                    registPage ? (
                        <div className="flex mb-12 w-90">
                            <>
                                <label className="label_title">고객사 *</label></>
                            <CustomSelectBox
                                name="customer.code"
                                className="d-block"
                                categoryList={customerList}
                                value={form.customer.code}
                                onChangeHandler={(value, name) => selectBoxHandler('customer.code', {
                                    code: value,
                                    name: name
                                })}
                                callbackCodeName="Y"
                            />
                        </div>
                    ) : <></>
                }
                {
                    !registPage ? <>
                        <div className="flex mb-12 w-90">
                            <label className="label_summary"> 현장 : {form.site.name}</label>
                        </div>
                    </> : (
                        <div className="flex mb-3 mt-2 w-90">
                            <label className="label_title">현장 *</label>
                            {
                                <CustomSelectBox
                                    name="site.code"
                                    categoryList={siteList}
                                    value={form.site.code}
                                    onChangeHandler={(value, name) => selectBoxHandler('site.code', {
                                        code: value,
                                        name: name
                                    })}
                                    callbackCodeName="Y"
                                />
                            }
                        </div>
                    )
                }
                <div className="flex mb-12 w-100">
                    <CustomTextField
                        className="w-90 h-30px"
                        title="업체코드"
                        name="inappcomcd"
                        required={true}
                        placeholder=""
                        customOnChange={textFieldHandler}
                        value={form}
                    />
                </div>
                <div className="flex mb-12 w-100">
                    <CustomTextField
                        className="w-90 h-30px"
                        title="연동정보"
                        name="connection"
                        required={true}
                        disabled={false}
                        placeholder=""
                        customOnChange={textFieldHandler}
                        value={form}
                    />
                </div>
                <div className="flex mb-12 w-100">
                    <CustomTextField
                        className="w-90 h-30px"
                        title="SeedKey"
                        name="seedKey"
                        required={true}
                        disabled={false}
                        placeholder=""
                        customOnChange={textFieldHandler}
                        value={form}
                    />
                </div>
                <div className="flex mb-12 w-100">
                    <CustomTextField
                        className="w-90 h-30px"
                        title="OTP"
                        name="otp"
                        required={true}
                        disabled={false}
                        placeholder=""
                        customOnChange={textFieldHandler}
                        value={form}
                    />
                </div>
                <div className="flex mb-12 w-100">
                    <CustomTextField
                        className="w-90 h-30px"
                        title="납부코드"
                        name="payNumber"
                        required={true}
                        disabled={false}
                        placeholder=""
                        customOnChange={textFieldHandler}
                        value={form}
                    />
                </div>
                <div className="flex mb-12 w-100">
                    <CustomTextField
                        className="w-90 h-30px"
                        title="comID"
                        name="comId"
                        required={true}
                        disabled={false}
                        placeholder=""
                        customOnChange={textFieldHandler}
                        value={form}
                    />
                </div>
            </div>

            <div className="footer_btn h-10 mt-50 mb-30">
                <div className="">
                    <button className="w-80px h-30px addBtn mr-10" onClick={onSubmitHandler}>저장</button>
                    { registPage ? "" : <button className="w-80px h-30px removeBtn mr-10" onClick={onRemoveHandler}>삭제</button>}
                    <button className="w-80px h-30px cancelBtn" onClick={handleClose}>취소</button>
                </div>
            </div>
        </div>
    )
}
