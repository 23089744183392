import React, { useEffect, useState} from 'react';
import logo from 'pages/ServicePage/Visitor/image/logoHanulchaeWhite@3x.png';
import './GuideMain.scss';
import {useHistory} from "react-router-dom";
import Lottie from "react-lottie";
import animationData from "./Lottie/LottieJs/lf_house";
import alertAnimationData from "./Lottie/LottieJs/lf_alert";
import { AlertModal } from 'components/molecules';


export const GuideMain = () => {

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    };

    const [modalState, setModalState] = useState({
        open: false
    });

    useEffect(()=>{
        /*

        * */
    },[]);

    const nextPage = ()=>{
        setModalState({
            open: !modalState.open,
        })
    }


    return (
        <div className="page">
            <div className="text-center guideMain">
                <AlertModal
                    className="w-100"
                    content={<ModalContent
                        modalClose={(e) => setModalState({
                            ...modalState,
                            open: e
                        })
                        }
                    />}
                    modalOpen={modalState.open}
                    modalClose={(e) => setModalState({
                        ...modalState,
                        open: e
                    })}
                />
                <div className="cardTop">
                    <div className="d-table w-100" >
                        <div className="col-left">
                        <img src={logo}
                             className="hanulche"
                             alt="logo"/>
                        </div>


                        <div className="text-header">
                            <a href={"http://m.site.naver.com/10ZKb"} target='_blank' aria-label={`앱 다운로드`}>
                                 <button className="blue-button floatR">앱 다운로드</button>
                            </a>
                        </div>
                    </div>

                    <div className="main-area mt-5">
                        <p className="white-header"> 홈 네트워크 인증</p>
                        <div className="main-text-area mt-3">
                            <p className="grey-text mt-2"> 시작하기 어려웠던 스마트하늘채,</p>
                            <p className="grey-text mt-2"> 온라인 가이드와</p>
                            <p className="grey-text mt-2"> 함께 해보세요.</p>
                        </div>

                        <div className="mt-5">
                            <Lottie
                                options={defaultOptions}
                                height={"auto"}
                                width={"100%"}
                                isStopped={false}
                            />
                        </div>
                        <div className="mt-4 footer-fixed me-2">
                            <button
                                onClick={nextPage}
                                className="blue-square-button hoverbtn"> 자세히 알아보기 </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export const ModalContent = (props) => {
    const history = useHistory();

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: alertAnimationData,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    };

    useEffect(() => {
        async function mounted(){

        }
        mounted().then(r => console.log("mounted"));
    }, [])


    const handleClose = () => {
        history.push('/service/guide/site');
    };

    return (
        <div className="h-100">
            <div className="modal_main mt-5 me-3 ms-3">
                <p className="modal-black-header mt-2"> 모든 하늘채 아파트가 </p>
                <p className="modal-black-header mt-2"> 스마트하늘채를 지원하지는 않아요</p>
                <div className="mt-3">
                    <p className="modal-grey-text mt-2">목록을 확인 해주세요.</p>
                </div>
                <div className="mb-5">
                    <Lottie
                        options={defaultOptions}
                        height={200}
                        width={200}
                        speed={3}
                        isStopped={false}
                    />
                </div>
                <div className="mt-2 footer-fixed">
                    <button
                        onClick={handleClose}
                        className="blue-square-button hoverbtn"> 확인 </button>
                </div>
            </div>


        </div>
    )
}
