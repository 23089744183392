import React from 'react'
import {Switch, Route} from 'react-router-dom'
import {NoticeList, NoticeRegist} from './Notice';
import {FeedList, FeedRegist} from './Feed';
import {FaqList, FaqRegist} from './Faq';
import {CodeTypeList} from './CodeType';
import {CodeList} from './Code';
import {QnaList, QnaReply} from './Qna';
import {ReservationList, ReservationRegist} from './Reservation';
import {VoteList, VoteRegist} from './Vote';
import VisitingcarsList from "./Visitingcars/VisitingcarsList";
import { BannerList, BannerRegist} from "./Banner";
import NotFound from "../../components/templates/NotFound";
import {O2OList, O2ORegist} from "./O2OService";
import {BoardMasterList} from './BoardMaster'
import {BoardList, DetailBoardList} from './Board'
import {ApartmentList} from "./Apartment";
import {ReportList} from './Report';
import {BlockList} from './Block';

export const Community = ({userInfo}) => {
    return (
        <Switch>
            <Route path={"/community/notice/list"} exact children={<NoticeList userInfo={userInfo}/>}/>
            <Route path={"/community/notice/regist"} exact children={<NoticeRegist/>}/>
            <Route path={"/community/notice/regist/:noticeId"} children={<NoticeRegist/>}/>
            <Route path={"/community/feed/list"} exact children={<FeedList userInfo={userInfo}/>}/>
            <Route path={"/community/feed/regist"} exact children={<FeedRegist userInfo={userInfo}/>}/>
            <Route path={"/community/feed/regist/:feedId"} exact children={<FeedRegist userInfo={userInfo}/>}/>
            <Route path={"/community/faq/list"} exact children={<FaqList userInfo={userInfo}/>}/>
            <Route path={"/community/faq/regist"} exact children={<FaqRegist/>}/>
            <Route path={"/community/faq/regist/:faqId"} children={<FaqRegist/>}/>
            <Route path={"/community/codeType/list"} exact children={<CodeTypeList userInfo={userInfo}/>}/>
            <Route path={"/community/code/list"} exact children={<CodeList userInfo={userInfo}/>}/>
            <Route path={"/community/qna/list"} exact children={<QnaList userInfo={userInfo}/>}/>
            <Route path={"/community/qna/reply/:questionId"} children={<QnaReply userInfo={userInfo}/>}/>
            <Route path={"/community/reservation/list"} exact children={<ReservationList userInfo={userInfo}/>}/>
            <Route path={"/community/reservation/regist"} exact children={<ReservationRegist/>}/>
            <Route path={"/community/reservation/regist/:itemId"} children={<ReservationRegist/>}/>
            <Route path={"/community/visitingcars/list"} exact children={<VisitingcarsList userInfo={userInfo}/>}/>
            <Route path={"/community/vote/list"} exact children={<VoteList userInfo={userInfo}/>}/>
            <Route path={"/community/vote/regist"} exact children={<VoteRegist/>}/>
            <Route path={"/community/vote/regist/:voteId"} children={<VoteRegist/>}/>
            <Route path={"/community/banner/list"} exact children={<BannerList userInfo={userInfo}/>}/>
            <Route path={"/community/banner/regist"} exact children={<BannerRegist/>}/>
            <Route path={"/community/banner/regist/:bannerId"} children={<BannerRegist/>}/>
            <Route path={"/community/o2o/list"} exact children={<O2OList userInfo={userInfo}/>}/>
            <Route path={"/community/o2o/regist"} exact children={<O2ORegist/>}/>
            <Route path={"/community/o2o/regist/:o2oId"} children={<O2ORegist/>}/>
            <Route path={"/community/boardMaster/list"} exact children={<BoardMasterList userInfo={userInfo}/>}/>
            <Route path={"/community/board/list"} exact children={<BoardList userInfo={userInfo}/>}/>
            <Route path={"/community/board/list/:category"} children={<DetailBoardList userInfo={userInfo}/>}/>
            <Route path={"/community/apartment/list"} exact children={<ApartmentList userInfo={userInfo}/>}/>
            <Route path={"/community/report/list"} exact children={<ReportList userInfo={userInfo}/>}/>
            <Route path={"/community/block/list"} exact children={<BlockList userInfo={userInfo}/>}/>

            <Route path={"*"}>
                <NotFound/>
            </Route>
        </Switch>
    )
}
