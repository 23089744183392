import React, { Fragment, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom'
import { CustomTable, SearchInputBar,CustomExcelDownload } from 'components/molecules';
import store from 'scripts/store';
import Net from 'scripts/net';
import {usePageAuth } from "scripts/hooks";
import Zoom from "react-medium-image-zoom";

const columnList = [
    {
        code: 'questionId',
        title: 'questionId',
        width: '8',
        hidden: true,
    },
    {
        code: 'classificationKOR',
        title: '구분',
        width: '4',
    },
    {
        code: 'title',
        title: '제목',
        width: '10',
    },
    {
        code: 'createdDatetime',
        title: '등록일자',
        width: '9',
    },
    {
        code: 'qnaFinished',
        title: '처리여부',
        width: '5',
    },
    {
        code: 'creator.username',
        title: '사용자',
        width: '7',
    },
    {
        code: 'place.name',
        title: '장소',
        width: '6',
    },
    {
        code: 'site.name',
        title: '현장명',
        width: '6',
    },
    {
        code: 'customer.name',
        title: '고객사',
        width: '5',
    },
];

const excelColumnList = [
    {
        code: 'customer.name',
        title: '고객사',
        width: '10',
    },
    {
        code: 'site.name',
        title: '현장',
        width: '10',
    },
    {
        code: 'classificationName',
        title: '구분',
        width: '10',
    },
    {
        code: 'creator.username',
        title: '질문자',
        width: '10',
    },
    {
        code: 'dong',
        title: '동',
        width: '10',
    },
    {
        code: 'ho',
        title: '호',
        width: '10',
    },
    {
        code: 'createdDatetime',
        title: '등록 일시',
        width: '10',
    },
    {
        code: 'title',
        title: '제목',
        width: '10',
    },
    {
        code: 'contents',
        title: '내용',
        width: '10',
    },
];

export const QnaList = (props) => {
    // useHistory 선언
    const history = useHistory();

    const user = props.userInfo;
    const [contentData, setContentData] = useState([]);
    const [checkedList, setCheckedList] = useState([]);
    const [contentData4Excel, setContentData4Excel] = useState([]);
    const [searchCategory, setSearchCategory] = useState([
        [
            [
                {code: '', name: '고객사 선택'}
            ]
        ],
        [
            [
                {code: '', name: '현장명 선택'}
            ]
        ],
        [
            [
                {code: '', name: '구분 선택'}
            ]
        ],
    ]);

    const [qnaDetail, setQnaDetail] = useState([]);
    const [loading, setLoading] = useState(false);
    const [pageState, setPageState] = useState({
        currentPage: 0,
        rowPerPage: 13,
        totalElementsCnt: 0,
        drawElementsCnt: 0,
        searchCategory1: user.menuGroup === 'ADMIN' ? '' : user.customer && user.customer.code,
        searchCategory2: user.menuGroup === 'OPERATOR' ? user.site && user.site.code: '',
        searchCategory3: '',
        searchText: '',
    });

    const programAuth = usePageAuth(props.userInfo.authList[0], sessionStorage.getItem('menuId'));

    useEffect(() => {
        store.openedPage = 'QnA 목록';
        getCategoryList('');
    }, [])

    useEffect(() => {
        loadData();
    }, [pageState.currentPage,
        pageState.searchCategory1,
        pageState.searchCategory2,
        pageState.searchCategory3,
        pageState.searchText,
    ]);

    useEffect(()=>{
    },[contentData4Excel])

    const loadData = () => {
        setLoading(true);
        getClassificationList(response =>{
            const codeList = response.data;
            Net.getQnaList(
                {
                    currentPage: pageState.currentPage,
                    rowPerPage: pageState.rowPerPage,
                    searchCategory1: pageState.searchCategory1,
                    searchCategory2: pageState.searchCategory2,
                    searchCategory3: pageState.searchCategory3,
                    searchText: pageState.searchText
                }, (response) => {
                    setLoading(false);
                    if (response.status === 200) {
                        if (response.data.totalElements !== 0 && response.data.numberOfElements === 0) {
                            setPageState({
                                ...pageState,
                                currentPage: 0
                            });
                            return false;
                        }

                        setContentData(response.data.content);
                        response.data.content.forEach((item) => {
                            item.qnaFinished = item.finished;
                            codeList.forEach(cItem => {
                                if (item.classification === cItem.code) {
                                    item.classificationKOR = cItem.name;
                                }
                            })
                        })
                        setPageState({
                            ...pageState,
                            totalElementsCnt: response.data.totalElements,
                            drawElementsCnt: response.data.numberOfElements,
                        });
                    } else {
                        setContentData([]);
                        setPageState({
                            ...pageState,
                            totalElementsCnt: 0,
                            drawElementsCnt: 0,
                        });
                    }
                    setQnaDetail([]);
                });

            const copiedPageState = JSON.parse(JSON.stringify(pageState));
            copiedPageState.rowPerPage = 999999;
            copiedPageState.currentPage = 0;
            Net.getQnaList(copiedPageState, (response) => {
                if (response.status === 200) {
                    if (response.data.content) {
                        setContentData4Excel(response.data.content.map(x=>{
                            x.dong = x.place.name.split("-")[0];
                            x.ho = x.place.name.split("-")[1];
                            return x;
                        }));
                    } else {
                        setContentData4Excel([]);
                    }
                }
            });
        });


    }

    const getClassificationList = (callback) => {
        Net.getCommunityCode(
            "QNA_CLASSIFICATION", (response) => {
                if(response.status === 200) {
                    let newArray = [{code: '', name: "구분 선택"}];
                    response.data.forEach(items => {
                        if( items.usable ){
                            newArray = [
                                ...newArray,
                                {
                                    code: items.code,
                                    name: items.name
                                }
                            ]
                        }
                    })
                    callback({status: 200, data: newArray});
                }
            }
        )
    }

    const getCategoryList = () => {
        let response3 = [];
        getClassificationList(response => {
            if (response.status === 200) {
                response3 = response.data;
                if (user.menuGroup === 'ADMIN') {
                    getCustomerList((response1) => {
                        getSitesList(null, (response2) => {
                            setSearchCategory([response1, response2, response3]);
                        });
                    });
                } else if (user.menuGroup === 'MANAGER') {
                    getSitesList(pageState.searchCategory1, (response2) => {
                        setSearchCategory([response2, response3]);
                    });
                } else {
                    setSearchCategory([response3]);
                }
            }
        });
    }

    const getRelateCategory = (selectedCategory) => {
        if(selectedCategory === "reset") selectedCategory = "";
        getSitesList(selectedCategory, (response2) => {
            setSearchCategory([
                searchCategory[0],
                response2,
                searchCategory[2]
            ]);
        });
    }

    const getCustomerList = (callback) => {
        Net.getCustomerList(
            {currentPage: '', rowPerPage: '', searchText: ''}, (response) => {
            if(response.status === 200) {
                let newArray = searchCategory[0][0];
                response.data.content.forEach(items => {
                    newArray = [
                        ...newArray,
                        {
                            code: items.code,
                            name: items.name
                        }
                    ]
                });
                callback(newArray);
            }
        });
    }

    const getSitesList = (selectedCategory, callback) => {
        if(selectedCategory){
            Net.getSitesList(
                {currentPage: '', rowPerPage: '', searchText: '', customerCode: selectedCategory?selectedCategory:false}, (response) => {
                 if(response.status === 200) {
                     let newArray =  [{code:'',name:"현장명 선택"}];
                     if(response.data.content.length > 0) {
                         response.data.content.forEach(items => {
                             newArray = [
                                 ...newArray,
                                 {
                                     code: items.code,
                                     name: items.name
                                 }
                             ]

                         })
                     }
                    callback(newArray);
                 } else {
                     let newArray = [{code:'',name:"현장명 선택"}];
                     callback(newArray);
                 }
            });
        } else {
            let newArray = [{code:'',name:"현장명 선택"}];
            callback(newArray);
        }
    }

    const onClickRow = (e) => {
        Net.getQnaOne(e.questionId, (response) => {
            if(response.status === 200) {
                setQnaDetail(response.data);
            } else {
                setQnaDetail([]);
            }
        });
    }

    const onMovePage = (e) => {
        setPageState({
            ...pageState,
            currentPage: e-1
        });
    }

    const removeList = () => {
        const result = window.confirm('삭제하시겠습니까?');
        const removeCodeList = [];
        checkedList.map((id) => {
            const node = document.querySelector(`#${id}`);
            const nodeTr = node.closest('tr');
            const codeValue = nodeTr.querySelector('td[name=questionId]').textContent;
            removeCodeList.push(codeValue);
        });
        if(result) {
            Net.removeQnaList(removeCodeList, (response) => {
                setCheckedList([]);
                if(response) {
                    loadData();
                }
            });
        }
    }

    const onSubmitHandler = (e) => {
        if (user.menuGroup === 'ADMIN') {
            setPageState({
                ...pageState,
                currentPage: 0,
                searchCategory1: e.selectedCategory[0],
                searchCategory2: e.selectedCategory[1],
                searchCategory3: e.selectedCategory[2],
                searchText: e.searchText,

            });

        } else if (user.menuGroup === 'MANAGER') {
            setPageState({
                ...pageState,
                currentPage: 0,
                searchCategory1: pageState.searchCategory1,
                searchCategory2: e.selectedCategory[0],
                searchCategory3: e.selectedCategory[1],
                searchText: e.searchText,

            });
        }else{
            setPageState({
                ...pageState,
                currentPage: 0,
                searchText: e.searchText,
                searchCategory1: pageState.searchCategory1,
                searchCategory2: pageState.searchCategory2,
                searchCategory3: e.selectedCategory[0],

            });
        }
    }

    const goModifypage = () => {
        history.push(`/community/qna/reply/${qnaDetail.questionId}`);
    }

    return (
        <div className="w-100 h-100">
            <div className="w-100 h-7 display_table">
                <SearchInputBar
                    className="w-100"
                    searchCategory={true}
                    searchCategoryGroup={searchCategory}
                    searchText={true}
                    searchPlaceHolder="제목을 입력하세요"
                    searchBtn={true}
                    searchBtnTitle="검색"
                    dynamicSearch={false}
                    relateCategoryHandler={user.menuGroup === 'ADMIN' && getRelateCategory}
                    onSubmit={onSubmitHandler}
                    refreshBtn={true}
                    refreshHandler={loadData}
                />
            </div>
            <div className="w-100 h-1px bgC-efefef" />
            <div className="flex h-93">
                <div className="w-70 h-100">
                    <div className="row">
                        <p className="w-12 totalCnt">Total {pageState.totalElementsCnt}</p>
                        <div className="w-88 floatR mt-15">
                        {
                            programAuth.deleteAuth && checkedList.length > 0 &&
                            <button
                                className="removeBtn  w-90px h-30px floatR mt-auto mb-auto ml-auto"
                                onClick={removeList}
                            >삭제</button>
                        }
                        {
                            <CustomExcelDownload
                                className="downloadBtn w-90px h-30px "
                                classNameDiv="floatR mr-10 mt-auto mb-auto ml-auto"
                                sheetTitle="QA_list"
                                columnList={excelColumnList}
                                contentData4Excel={contentData4Excel}
                            />
                        }
                        </div>
                    </div>
                    <CustomTable
                        columnList = {columnList}
                        contentData = {contentData}
                        checkbox = {programAuth.deleteAuth}
                        checkedList = {(checkedList) => {setCheckedList(checkedList)} }
                        paging = {true}
                        totalElementsCnt = {pageState.totalElementsCnt}
                        rowPerPage = {pageState.rowPerPage}
                        currentPage = {pageState.currentPage}
                        drawElementsCnt = {pageState.drawElementsCnt}
                        pagination = {onMovePage}
                        rowClickEvt = {true}
                        onClickRow = {onClickRow}
                        loading={loading}
                    />
                </div>
                <div className="w-30 h-100">
                    <div className="flex">
                        <p className="body_content_subtitle">내용</p>
                        {
                            qnaDetail.contents ?
                            (
                                <button
                                className="addBtn w-90px h-30px  mr-20 mt-auto mb-auto ml-auto"
                                onClick={goModifypage}
                                >답변</button>
                            ) : ""
                        }


                    </div>
                    <div className="w-100 h-100">
                        <table className="mainTable">
                            <thead className="tableHead">
                                <tr>
                                    <th>내용</th>
                                </tr>
                            </thead>
                            <tbody className="tableBody">
                                {
                                    qnaDetail.contents ?
                                    (
                                        <Fragment>

                                            <tr>
                                                <td style={{padding:"10px", overflow:"unset", whiteSpace:"initial"}}>
                                                    <div className="overFlowYscrollView h-60vh">
                                                        <div dangerouslySetInnerHTML={{__html: qnaDetail.contents}}></div>
                                                        {
                                                            qnaDetail.imageDataUrl &&
                                                            <div className="mt-10">
                                                                {
                                                                    qnaDetail.imageDataUrl.map( (img, i) => {
                                                                        return (
                                                                            <div key={i} className="mb-5">
                                                                                <Zoom>
                                                                                    <img src={img} alt="complex=Img" width="300px"/>
                                                                                </Zoom>
                                                                            </div>
                                                                        )
                                                                    })
                                                                }
                                                            </div>
                                                        }
                                                    </div>

                                                </td>
                                            </tr>

                                        </Fragment>


                                    ) : (
                                        <tr><td>조회 된 데이터가 없습니다.</td></tr>
                                    )
                                }

                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
}
