import React, {Fragment, useEffect, useState} from 'react';
import {CustomPagination, CustomTable, SearchInputBar} from 'components/molecules';
import store from 'scripts/store';
import Net from 'scripts/net';
import Common from 'scripts/common';
import {toast} from "react-toastify";
import {usePageAuth , useDidMountEffect} from "scripts/hooks";
import ReactApexChart from "react-apexcharts";

const columnList = [
    {
        code: 'siteName',
        title: '현장',
        width: '5',
    },
    {
        code: 'totalHouseHold',
        title: '총 세대수',
        width: '6',
        number : true

    },
    {
        code: 'homenetHouseHold',
        title: '홈넷 연동 세대수',
        width: '6',
        number : true

    },
    {
        code: 'totalControl',
        title: '전체 제어 건수',
        width: '6',
        number : true

    },
];

const detailColumnList = [
    {
        code: 'deviceType',
        title: '구분',
        width: '8',
    },
    {
        code: 'total',
        title: '건수',
        width: '8',
        number : true

    },
    {
        code: 'percent',
        title: '비율(%)',
        width: '8',
        number : true

    }
]

export const UsedStatusByDevice = (props) => {
    // useHistory 선언
    const user = props.userInfo;
    const [contentData, setContentData] = useState([]);
    const [detailContentData, setDetailContentData] = useState([]);
    const [selectedSiteName, setSelectedSiteName] = useState('');
    const [selectedSiteCode, setSelectedSiteCode] = useState('');
    const [totalHousehold, setTotalHousehold] = useState('');
    const [homenetHouses, setHomenetHouses] = useState('');
    const [searchCategory, setSearchCategory] = useState([
        [
            [
                {code: '', name: '고객사 선택'}
            ]
        ],
        [
            [
                {code: '', name: '현장명 선택'}
            ]
        ],
        [
            [
                {code: '', name: '요청 타입 선택'},
                {code: 'mobile', name: '모바일'},
                {code: 'automation', name: '자동화 모드'},
                {code: 'kt', name: 'KT'},
                {code: 'lguplus', name: 'LG U+'},
                {code: 'skt', name: 'SKT'},
                {code: 'clova', name: '클로바'},
                {code: 'google', name: '구글'},
                {code: 'samsung', name: '삼성'},
            ]
        ],
    ]);
    const programAuth = usePageAuth(props.userInfo.authList[0], sessionStorage.getItem('menuId'));
    const [pageState, setPageState] = useState({
        searchCategory1: user.menuGroup === ('ADMIN'|| 'TESTONLY') ? '' : user.customer && user.customer.code,
        searchCategory2: user.menuGroup === 'OPERATOR' ? user.site && user.site.code: '',
        searchCategory3: '',
        searchStartDate: '',
        searchEndDate: '',
        searchText : ''
    });
    const [totalControl, setTotalControl] = useState(0);
    const [chartOption, setChartOption] = useState({
        chart: {
            type: 'pie',
            toolbar: {
                show: true
            },
            zoom: {
                enabled: true
            },
            responsive: [{
                breakpoint: 480,
                options: {
                    chart: {
                        width: 200
                    },
                    legend: {
                        position: 'bottom'
                    }
                }
            }],
        },
        labels: ['Team A', 'Team B', 'Team C', 'Team D', 'Team E'],

    },);
    const [chartSeries, setChartSeries] = useState([12,15,55,23,21]);

    useEffect(() => {
        store.openedPage = '기기별 사용 현황';
        getCategoryList('');
    }, [])

    useEffect(() => {
        if(pageState.searchCategory2 !== ''){
            setContentData([]);
            setTotalControl(0);
            setTimeout(() => {
                loadData();
            }, 10)
        }
    }, [pageState.searchCategory1,
        pageState.searchCategory2,
        pageState.searchCategory3,
        pageState.searchStartDate,
        pageState.searchEndDate,
        pageState.searchText,
    ]);

    useEffect(()=>{
        setSelectedSiteCode(pageState.searchCategory2);
        if(pageState.searchCategory2 !== ''){
            setSelectedSiteName(window.SITE_LIST ? window.SITE_LIST[pageState.searchCategory2].name : pageState.searchCategory2);
            setTotalHousehold(window.SITE_LIST ? window.SITE_LIST[pageState.searchCategory2].household : 0);
        }
        Net.getHomeNetHouseCounts(pageState.searchCategory2, response => {
            setHomenetHouses(response);
        });
    },[pageState.searchCategory2])

    useEffect(()=>{
        /*
        *  총 세대수 , 홈넷 연동 세대 수 테이블에 넣기
        * */
        const tempContentData = {
            siteName : selectedSiteName,
            totalHouseHold : totalHousehold,
            homenetHouseHold : homenetHouses,
            totalControl : totalControl
        };
        setContentData([tempContentData]);
    },[homenetHouses, totalControl]);

    useEffect(()=>{
        // 파이 차트 그리기기
        let labels = detailContentData.map(item => {
            return item.deviceType;
        });
        let series = detailContentData.map(item => {
            return item.total;
        })

        setChartOption({
            ...chartOption,
            labels: labels
        });
        setChartSeries(series);
    },[detailContentData])

    const loadData = () => {
        Net.RequestLogDeviceType(
            {
                searchCategory1: pageState.searchCategory1,
                searchCategory2: pageState.searchCategory2,
                searchCategory3: pageState.searchCategory3,
                searchStartDate: pageState.searchStartDate,
                searchEndDate: pageState.searchEndDate,
                searchText: pageState.searchText
            }, (response) => {
                if (response.status === 200) {
                    /*
                    * 1. 전체 제어 건수 구하기
                    * 2. 각 제어들의 퍼센테이지 구하기 ( setting Pie & 아래 테이블 )
                    * 3. 현장값으로 총 세대수 및 홈넷 연동 세대수 구하기
                    * 4. 고객사 명 과 현장 명 가져오기
                    * 5. 테이블 세팅하기
                    * */
                    let tempTotalControl = 0;
                    response.data.forEach( item => {
                        tempTotalControl += item.total;
                    });
                    setDetailContentData(response.data.map(item => {
                        item.percent = (item.total/tempTotalControl * 100).toFixed(1)+ '%';
                        return item;
                    }).sort(function(a,b){
                        return b.total - a.total;
                    }));
                    setTotalControl(tempTotalControl);

                } else {
                    setContentData([]);
                }
            });
    }

    const getCategoryList = () => {
        if (user.menuGroup === 'ADMIN'|| 'TESTONLY') {
            Common.getCustomerList((response1) => {
                Common.getSitesList(null, (response2) => {
                    setSearchCategory([response1, response2, ...searchCategory[2]]);
                });
            });
        }else if (user.menuGroup === 'MANAGER') {
            Common.getSitesList(pageState.searchCategory1, (response2) => {
                setSearchCategory([response2, ...searchCategory[2]]);
            });
        }
    }

    const getRelateCategory = (selectedCategory) => {
        if (selectedCategory === "reset") selectedCategory = "";
        Common.getSitesList(selectedCategory, (response2) => {
            setSearchCategory([
                searchCategory[0],
                response2,
                searchCategory[2]
            ]);
        });
    }

    const onSubmitHandler = (e) =>{
        if (user.menuGroup === 'ADMIN'|| 'TESTONLY') {
            if(e.selectedCategory[0] === '') {
                alert('고객사명은 필수값입니다.');
                return false;
            }
            if(e.selectedCategory[1] === '') {
                alert('현장명은 필수값입니다.');
                return false;
            }
            setPageState({
                ...pageState,
                searchCategory1: e.selectedCategory[0],
                searchCategory2: e.selectedCategory[1],
                searchCategory3: e.selectedCategory[2],
                searchText: e.searchText
            });
        } else if (user.menuGroup === 'MANAGER'){
            if(e.selectedCategory[0] === '') {
                alert('현장명은 필수값입니다.');
                return false;
            }
            setPageState({
                ...pageState,
                searchCategory1: pageState.searchCategory1,
                searchCategory2: e.selectedCategory[0],
                searchCategory3: e.selectedCategory[1],
                searchText: e.searchText
            });
        }
    }

    const searchDateHandler = (startDate, endDate) => {
        startDate = startDate.getFullYear() + '-' + ('0' + (Number(startDate.getMonth()) + 1)).slice(-2) + '-' + ('0' + startDate.getDate()).slice(-2) + ' 00:00';
        endDate = endDate.getFullYear() + '-' + ('0' + (Number(endDate.getMonth()) + 1)).slice(-2) + '-' + ('0' + endDate.getDate()).slice(-2)+ ' 23:59';

        if (!pageState.searchStartDate && !pageState.searchEndDate) {
            pageState.searchStartDate = startDate;
            pageState.searchEndDate = endDate;
        } else {
            setPageState({
                ...pageState,
                searchStartDate: startDate,
                searchEndDate: endDate,
            });
        }
    }

    return (
        <div className="w-100 h-100">
            <div className="w-100 h-7 display_table">
                <SearchInputBar
                    className="w-100"
                    searchCategory={user.menuGroup === ('ADMIN'|| 'TESTONLY') || user.menuGroup === 'MANAGER'}
                    searchCategoryGroup={searchCategory}
                    searchBtn={true}
                    searchBtnTitle="검색"
                    searchText={true}
                    searchPlaceHolder="시간 입력 ex) 0,9,15"
                    dynamicSearch={false}
                    relateCategoryHandler={user.menuGroup === ('ADMIN'|| 'TESTONLY') && getRelateCategory}
                    searchDate={searchDateHandler}
                    onSubmit={onSubmitHandler}
                    datetype={"week"}
                />
            </div>
            <div className="w-100 h-1px bgC-efefef"/>
            <div className="w-100 h-10 d-block" >
                <div className="h-93 overflowYscrollView">
                    <CustomTable
                        columnList={columnList}
                        contentData={contentData}
                        totalElementsCnt={pageState.totalElementsCnt}
                        rowPerPage={pageState.rowPerPage}
                        currentPage={pageState.currentPage}
                        drawElementsCnt={pageState.drawElementsCnt}
                    />
                </div>
            </div>
            <div className="w-100 d-block">
                {selectedSiteName ? <> <h5 className="ml-30 mb-3 mt-3"> 현장 : {selectedSiteName}  </h5>
                    </>
                    : <><h5 className="ml-30 mb-3 mt-3"> 현장을 선택해 주세요.  </h5></>}
            </div>
            <div className="flex">
                <div className="w-60 h-93 justify-content-center flex">
                    <div className="col-8">
                        <ReactApexChart options={chartOption} series={chartSeries} type="pie"/>
                    </div>
                </div>
                <div className="w-39 h-93" >
                    <div className="w-100 h-93 overflowYscrollView">
                        <CustomTable
                            columnList={detailColumnList}
                            contentData={detailContentData}
                            sort={true}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}
